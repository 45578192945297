import { Selector, useSelector } from "state-manager";
import * as GraphqlPlayground from "state-manager/states/Ready/states/GraphqlPlayground";
import { GraphiQL } from "graphiql";
import "graphiql/graphiql.css";
import * as Fp from "fp-ts/function";
import { useMemo } from "react";
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import { UserAccessToken } from "types";
import { useGlobalEnv } from "@Hooks/useGlobalEnv";
import schema from "ds/graphql.schema.json";
import { GraphQLSchema } from "graphql/index";

export interface ContentProps {
  selector: Selector<{
    accessToken: UserAccessToken | undefined;
  }>;
  dispatch: (a: GraphqlPlayground.Action) => void;
}

export function Content(p: ContentProps) {
  const { graphQlClientUri } = useGlobalEnv();
  const token = useSelector(Fp.flow(p.selector, (v) => v.accessToken));
  const fetcher = useMemo(
    () =>
      createGraphiQLFetcher({
        url: graphQlClientUri,
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
    [token, graphQlClientUri],
  );

  return (
    <GraphiQL fetcher={fetcher} schema={schema as unknown as GraphQLSchema} />
  );
}

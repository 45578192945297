import React from "react";
import { EnumCellProps, WithClassname } from "@jsonforms/core";

import { MenuItem, Select as MuiSelect } from "@mui/material";
import { WithInputProps, WithSelectProps } from "@jsonforms/material-renderers";
import { mergeWith } from "rambda";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export namespace Select {
  export interface Props
    extends EnumCellProps,
      WithClassname,
      WithInputProps,
      WithSelectProps {}
}

export function Select(props: Select.Props) {
  const {
    data,
    className,
    id,
    enabled,
    uischema,
    path,
    handleChange,
    options,
    config,
    label,
    multiple,
  } = props;
  const opts = merge(config ?? {}, uischema.options) as Partial<{
    focus: boolean;
    placeholder: string;
  }>;

  return (
    <MuiSelect
      className={className}
      id={id}
      label={label}
      disabled={!enabled}
      autoFocus={opts.focus}
      value={data ?? "1"}
      onChange={(ev) => handleChange(path, ev.target.value || undefined)}
      fullWidth={true}
      multiple={multiple || false}
    >
      <MenuItem
        value="1"
        disabled
        sx={{
          ".MuiMenu-list > &": {
            display: "none",
          },
        }}
      >
        <Typography sx={(p) => ({ color: p.palette.text.disabled })}>
          {opts.placeholder}
        </Typography>
      </MenuItem>
      {options?.map((optionValue) => (
        <MenuItem value={optionValue.value} key={optionValue.value}>
          {optionValue.label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

const Placeholder = styled(MenuItem)``;

const merge = mergeWith((a, b) => b ?? a);

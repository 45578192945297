import { mergeChanges } from "utils/object";
import { FiltersConfigBase } from "../types/filters";
import { Criteria, CriteriaChange } from "../types/criteria";

export const mergeCriteriaChanges = <
  Columns,
  FiltersConfig extends FiltersConfigBase,
>(
  criteria: Criteria<Columns, FiltersConfig>,
  changes: CriteriaChange<Columns, FiltersConfig>,
): Criteria<Columns, FiltersConfig> => ({
  ...criteria,
  filters: {
    ...criteria.filters,

    predefined: mergeChanges(
      criteria.filters?.predefined || {},
      changes.filters?.predefined || {},
    ) as Exclude<
      Criteria<Columns, FiltersConfig>["filters"],
      undefined
    >["predefined"],

    custom: mergeChanges(
      criteria.filters?.custom || {},
      changes.filters?.custom || {},
    ) as Exclude<
      Criteria<Columns, FiltersConfig>["filters"],
      undefined
    >["custom"],
  },
  orderBy: changes.orderBy ?? criteria.orderBy,
});

import { DataType } from "types/src/DataType/DataType";
import { silentUnreachableError } from "utils/exceptions";
import { strictGuard } from "utils/strictGuard";
import { Typed } from "utils/Typed";
import { Collections } from "../states/Collections";
import { Customers } from "../states/Customers";
import { Suppliers } from "../states/Suppliers";
import { Repositories } from "../states/Repositories";
import { InventoryItems } from "../states/InventoryItems";
import { ItemMovements } from "../states/ItemMovements";
import { RepositoryMovements } from "../states/RepositoryMovements";
import { PickingOrders } from "../states/PickingOrder";
import { pickingOrderState } from "../utils";
import { Inbounds } from "../states/Inbounds";
import { ItemSets } from "../states/ItemSets";
import { StocksListing } from "../states/Stocks";

// region Actions
export type Actions =
  | Typed.GetTypes<typeof actions>
  | Suppliers.Actions
  | Collections.Actions
  | Customers.Actions
  | Repositories.Actions
  | InventoryItems.Actions
  | ItemMovements.Actions
  | RepositoryMovements.Actions
  | Inbounds.Actions
  | ItemSets.Actions
  | PickingOrders.Actions
  | StocksListing.Actions;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (_isActions(a)) return true;
  if (StocksListing.instance.isAction(a)) return true;
  if (Collections.instance.isAction(a)) return true;
  if (Customers.instance.isAction(a)) return true;
  if (Suppliers.instance.isAction(a)) return true;
  if (Repositories.instance.isAction(a)) return true;
  if (InventoryItems.instance.isAction(a)) return true;
  if (ItemMovements.instance.isAction(a)) return true;
  if (RepositoryMovements.instance.isAction(a)) return true;
  if (Inbounds.instance.isAction(a)) return true;
  if (ItemSets.instance.isAction(a)) return true;
  if (pickingOrderState.isAction(a)) return true;

  silentUnreachableError(a);
  return false;
});
// endregion

export const actions = Typed.builder
  .add("loadFail", (p: { type: "unknown" }) => p)
  .add("loadSuccess", (p: { dataTypes: DataType[] }) => p)
  .add("dataTypesUpdated", (p: DataType[]) => p)
  .finish()("Ready:DataManager");

const _isActions = Typed.getGuard(actions);

import { CodeEditor as CodeEditor_ } from "ui/packages/json-schema-form-builder/Right/CodeEditor";
import { useMemo } from "react";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Value } from "types/src/jsonSchema/value";
import { schemaToCode } from "./utils/format";

export namespace CodeEditor {
  export type Props = {
    value$: BehaviorValue<Value>;
  };
}

export const CodeEditor = ({ value$ }: CodeEditor.Props) => {
  const schema = useBehaviorValue(
    useMemo(() => value$.map((v) => v.dataSchema), [value$]),
  );
  const code = useMemo(() => schemaToCode(schema), [schema]);

  return <CodeEditor_ {...{ code }} />;
};

import { Selector, useSelector } from "state-manager";
import * as Ready from "state-manager/states/Ready";
import { DataTypesListing as Listing } from "state-manager/states/Ready/states/DataTypesListing";
import { DataTypeCreate as Create } from "state-manager/states/Ready/states/DataTypeCreate";
import { DataTypeEdit as Edit } from "state-manager/states/Ready/states/DataTypeEdit";
import { ReactElement } from "react";

import { flow } from "fp-ts/function";
import { unreachableError } from "utils/exceptions";
import * as ListingContainer from "./Listing";
import * as CreateContainer from "./Create";
import * as EditContainer from "./Edit";

export interface ContentProps {
  selector: Selector<Listing.State | Create.State | Edit.State>;
  dispatch: (
    a:
      | Listing.Actions
      | Create.Actions
      | Edit.Actions
      | Ready.GoToDataTypesEdit,
  ) => void;
}

export function Content(p: ContentProps): ReactElement | null {
  const data = useSelector(
    flow(p.selector, (s) => {
      if (Listing.instance.isState(s)) {
        return {
          type: "listing",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;
      }
      if (Create.instance.isState(s)) {
        return {
          type: "create",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;
      }
      if (Edit.instance.isState(s)) {
        return {
          type: "edit",
          selector: flow(p.selector, (st) => st as typeof s),
        } as const;
      }

      unreachableError(s);
      return { type: "none" } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (data.type) {
    case "listing":
      return (
        <ListingContainer.Content
          selector={data.selector}
          dispatch={p.dispatch}
        />
      );
    case "create":
      return (
        <CreateContainer.Content
          selector={data.selector}
          dispatch={p.dispatch}
        />
      );
    case "edit":
      return (
        <EditContainer.Content selector={data.selector} dispatch={p.dispatch} />
      );
    case "none":
      return null;
  }
}

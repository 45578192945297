import React from "react";
import { createSvgIcon } from "@mui/material";

export const Hash = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <path
      d="M6.33328 2L4.33328 14M11.6666 2L9.6666 14M13.6666 5.33333H2.33329M13 10.6667H1.66663"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "Hash",
);

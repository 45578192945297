import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError, unknownError } from "ds";
import { getCustomer, updateCustomer, deleteCustomers } from "ds/Customers";
import { getDataType } from "ds/DataTypes";
import { flow, pipe } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Epic } from "../../../../../../../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { schemaFieldsState } from "./utils";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) => {
  const fieldsSchema$ = schemaFieldsState.epic(
    state$.pipe(
      filter(State.isLoaded),
      map((s) => s.payload.schema),
    ),
    pyckAdminClient$,
  );

  const loading$ = state$.pipe(
    filter(State.isLoading),
    map((s) => s.payload.customerId),
    withLatestFrom(pyckAdminClient$),
    switchMap(([id, client]) =>
      from(getCustomer(client, id)).pipe(
        switchMap((v) =>
          pipe(
            v,
            E.mapLeft(() => of(Actions.loadFail())),
            E.map((c) =>
              from(getDataType(client, c.dataTypeId)).pipe(
                map(
                  flow(
                    E.map((dt) =>
                      Actions.loadSuccess({
                        customer: c,
                        schema: dt.schema,
                        ui: dt.frontendSchema,
                      }),
                    ),
                    E.getOrElse<DsError, Actions.Actions>((e) =>
                      Actions.loadFail(),
                    ),
                  ),
                ),
              ),
            ),
            E.getOrElse<
              Observable<Actions.Actions>,
              Observable<Actions.Actions>
            >((e) => e),
          ),
        ),
      ),
    ),
  );

  const update$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isSaving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(
        updateCustomer(client, {
          id: s.customerId,
          dataTypeId: s.dataTypeId,
          fields: s.schema.payload.values,
        }),
      ).pipe(
        map(
          flow(
            E.map(Actions.saveSuccess),
            E.getOrElse<DsError, Actions.Actions>(Actions.saveError),
          ),
        ),
        catchError(() => of(Actions.saveError())),
      ),
    ),
  );

  const remove$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isRemoving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    mergeMap(([s, client]) =>
      from(deleteCustomers(client, [s.customerId])).pipe(
        map(
          flow(E.map(Actions.removeSuccess), E.getOrElseW(Actions.removeFail)),
        ),
        catchError(() => of(Actions.removeFail(unknownError()))),
      ),
    ),
  );

  return merge(loading$, update$, fieldsSchema$, remove$);
};

import { gql } from "@apollo/client";
import {
  ItemSet,
  ItemSetCreate,
  ItemSetId,
  ItemSetUpdate,
} from "types/src/ItemSets/ItemSet";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { isT } from "fp-utilities";
import { Client, DsError, notFoundError } from "../../index";

import { QueryResponse } from "../../type/QueryResponse";
import { itemSetFragment } from "../../fragments/ItemSet";
import {
  itemSetCreateToCreateInventoryItemSetInput,
  itemSetFragmentToItemSet,
  itemSetUpdateToUpdateInventoryItemSetInput,
} from "../../transformers/ItemSets";
import {
  CreateItemSetMutation,
  CreateItemSetMutationVariables,
  RemoveItemSetMutation,
  RemoveItemSetMutationVariables,
  UpdateItemSetMutation,
  UpdateItemSetMutationVariables,
} from "../../generated/graphql";

export const createItemSet = (
  client: Client,
  vars: ItemSetCreate,
): Promise<QueryResponse<ItemSet>> => {
  const mutation = gql`
    ${itemSetFragment}
    mutation CreateItemSet($input: CreateInventoryItemSetInput!) {
      createInventoryItemSet(input: $input) {
        inventoryItemSet {
          ...ItemSetFragment
        }
      }
    }
  `;

  return client
    .mutate<CreateItemSetMutation, CreateItemSetMutationVariables>({
      mutation,
      variables: {
        input: itemSetCreateToCreateInventoryItemSetInput(vars),
      },
    })
    .then(
      flow(
        E.map((v) => v.createInventoryItemSet?.inventoryItemSet),
        E.filterOrElseW(isT, notFoundError),
        E.map(itemSetFragmentToItemSet),
      ),
    );
};

export const updateItemSet = (
  client: Client,
  vars: ItemSetUpdate,
): Promise<QueryResponse<ItemSet>> => {
  const mutation = gql`
    ${itemSetFragment}
    mutation UpdateItemSet($id: ID!, $input: UpdateInventoryItemSetInput!) {
      updateInventoryItemSet(id: $id, input: $input) {
        inventoryItemSet {
          ...ItemSetFragment
        }
      }
    }
  `;

  return client
    .mutate<UpdateItemSetMutation, UpdateItemSetMutationVariables>({
      mutation,
      variables: {
        id: vars.id,
        input: itemSetUpdateToUpdateInventoryItemSetInput(vars),
      },
    })
    .then(
      flow(
        E.map((v) => v.updateInventoryItemSet?.inventoryItemSet),
        E.filterOrElseW(isT, notFoundError),
        E.map(itemSetFragmentToItemSet),
      ),
    );
};

export const removeItemSet = (
  client: Client,
  id: ItemSetId,
): Promise<QueryResponse<ItemSetId>> => {
  const mutation = gql`
    mutation RemoveItemSet($id: ID!) {
      deleteInventoryItemSet(id: $id) {
        deletedID
      }
    }
  `;

  return client
    .mutate<RemoveItemSetMutation, RemoveItemSetMutationVariables>({
      mutation,
      variables: {
        id,
      },
    })
    .then(E.map((node) => node.deleteInventoryItemSet.deletedID as ItemSetId));
};

export const removeItemSets = (
  client: Client,
  ids: ItemSetId[],
): Promise<E.Either<DsError, void>> => {
  const template = `
    itemSet_id: deleteItemSet(id: "[ITEM_SET_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template.replace("[ITEM_SET_ID]", id).replace("itemSet_id", `itemSet_${i}`),
  );
  const query = `
        mutation DeleteItemSets {
            ${fakeMutation.join("\n")}
        }
      `;

  return client
    .mutate({
      mutation: gql(query),
    })
    .then(E.map(() => undefined));
};

import { silentUnreachableError, unreachableError } from "utils/exceptions";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import * as Auth from "./states/Auth";
import * as Ready from "./states/Ready";
import * as Notifications from "./states/Notifications";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (Auth.isAction(a)) {
    return Fp.pipe(
      O.some(s.content),
      O.filter(Auth.isState),
      O.map((s) => Auth.reducer(s, a)),
      O.map(
        E.orElseW((e) => {
          if (Auth.exits.authenticated.is(e)) {
            return Fp.pipe(
              Ready.reducer(
                Ready.init({
                  user: E.right(e.payload.user),
                  organizations: {
                    ids: e.payload.user.orgs.map(({ id }) => id),
                    entities: Object.fromEntries(
                      e.payload.user.orgs.map((org) => [org.id, org]),
                    ),
                    isActive: false,
                  },
                  openAI: e.payload.user.openAI,
                  orgId: e.payload.user.orgId,
                  userSettings: e.payload.userSettings,
                }),
                e.payload.goTo,
              ),
              E.orElseW((e) => {
                if (Ready.exits.logout.is(e)) {
                  return E.right(Auth.init({ goTo: e.payload }));
                }

                silentUnreachableError(e);
                return E.left(e);
              }),
            );
          }

          silentUnreachableError(e);
          return E.left(e);
        }),
      ),
      O.chain(O.fromEither),
      O.map((content) => ({ ...s, content })),
      O.getOrElse(() => s),
    );
  }

  if (Ready.isActions(a)) {
    return Ready.isState(s.content)
      ? {
          ...s,
          content: Fp.pipe(
            Ready.reducer(s.content, a),
            E.orElseW((e) => {
              if (Ready.exits.logout.is(e)) {
                return E.right(Auth.states.login.create({ goTo: e.payload }));
              }

              silentUnreachableError(e);
              return E.left(e);
            }),
            E.getOrElse(() => s.content),
          ),
        }
      : s;
  }

  if (Notifications.isActions(a)) {
    return Notifications.isState(s.notifications)
      ? { ...s, notifications: Notifications.reducer(s.notifications, a) }
      : s;
  }

  unreachableError(a);
  return s;
}

import React from "react";
import { createSvgIcon } from "@mui/material";

export const Text = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <path
      d="M2.66675 4.66602C2.66675 4.04476 2.66675 3.73413 2.76824 3.4891C2.90357 3.1624 3.16313 2.90283 3.48984 2.76751C3.73486 2.66602 4.04549 2.66602 4.66675 2.66602H11.3334C11.9547 2.66602 12.2653 2.66602 12.5103 2.76751C12.837 2.90283 13.0966 3.1624 13.2319 3.4891C13.3334 3.73413 13.3334 4.04476 13.3334 4.66602M6.00008 13.3327H10.0001M8.00008 2.66602V13.3327"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "Text",
);

import { gql } from "@apollo/client";
import { DataTypeId } from "types/src/DataType/DataType";
import { ItemSet, ItemSetId } from "types/src/ItemSets/ItemSet";
import { isT } from "fp-utilities";
import { Cursor } from "types";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { ISODate } from "types/src/date/ISODate";
import { pipe } from "fp-ts/function";
import {
  GetItemSetsQuery,
  GetItemSetsQueryVariables,
  InventoryItemSetOrderField,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResult,
} from "../../type/QueryResponse";
import { notFoundError } from "../../type/DsError";
import { Where } from "../../type/Where";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";
import { Query } from "../../type/Query";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import { itemSetFragment } from "../../fragments/ItemSet";
import { itemSetFragmentToItemSet } from "../../transformers/ItemSets";
import * as InvetoryItems from "../InboundItems/queries";

export type WhereSchema = Where<{
  data: Where.Map;
  createdAt: Where.Ord<ISODate>;
  dataType: Where.EqNil<DataTypeId>;
  hasItems: Where.Value<boolean>;
  hasItemsWith: Where.WithWhereList<InvetoryItems.WhereSchema>;
  id: Where.EqIn<ItemSetId>;
  sku: Where.Text;
  updatedAt: Where.Ord<ISODate>;
  dataTypeSlug: Where.Text;
}>;

export const whereSchema = Where.create<WhereSchema>({
  id: Where.eqIn<ItemSetId>(),
  createdAt: Where.ord<ISODate>(),
  updatedAt: Where.ord<ISODate>(),
  data: Where.map("Data"),
  sku: Where.text(),
  dataType: Where.eqNil<DataTypeId>("dataTypeID"),
  dataTypeSlug: Where.text(),
  hasItems: Where.value<boolean>(),
  hasItemsWith: Where.withWhereList(
    (): InvetoryItems.WhereSchema => InvetoryItems.whereSchema,
  ),
});

export interface GetItemSetsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
  where?: Where.GetType<typeof whereSchema>;
}

export function getItemSetsQuery(
  vars: GetItemSetsVars,
): Query<
  GetItemSetsQueryVariables,
  GetItemSetsQuery,
  PaginatedQueryResult<ItemSet>
> {
  const query = gql`
    ${pageInfoFragment}
    ${itemSetFragment}

    query GetItemSets(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: InventoryItemSetWhereInput
      $orderBy: InventoryItemSetOrder
    ) {
      inventoryItemSets(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...ItemSetFragment
          }
        }
      }
    }
  `;

  return Query.create(
    {
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: Fp.pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toUndefined,
        ),
        orderBy: pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: InventoryItemSetOrderField.CreatedAt,
              updatedAt: InventoryItemSetOrderField.UpdatedAt,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    },
    Fp.flow(
      E.map((v) => v.inventoryItemSets),
      E.map(getPaginatedQueryResult(itemSetFragmentToItemSet)),
    ),
  );
}

export function getItemSetQuery(id: ItemSetId) {
  return Query.map(
    Fp.flow(
      E.map((v) => v.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
    getItemSetsQuery({ where: { and: [{ id: { eq: id } }] } }),
  );
}

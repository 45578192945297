import { Typed } from "utils/Typed";
import * as Exits from "./Exits";

export const states = Typed.builder
  .add("idle", (p: { goTo: Exits.Payload["goTo"] }) => p)
  .add("login", (p: { goTo: Exits.Payload["goTo"] }) => p)
  .add("authConfirmation")
  .add("authentication", (p: { goTo: Exits.Payload["goTo"] }) => p)
  .add("authError", (p: { goTo: Exits.Payload["goTo"] }) => p)
  .finish()("Auth:State");

export type State = Typed.GetTypes<typeof states>;

export const isState = Typed.getGuard(states);
export const init = states.idle.create;

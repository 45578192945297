import { UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { ElementTypes } from "../../../types/element";
import { useBehaviorOptionValue } from "../../../hooks/useBehaviorOptionValue";
import { useControlTypeAndSchemasById } from "../../../hooks/control/typeAndSchemasById";
import { Inner } from "./Inner";

const elementType = UiSchemaElementType.control;
type Component = ElementTypes[typeof elementType]["RenderInEditor"];

export const Control: Component = ({ id }) => {
  const typeAndSchemas$ = useBehaviorOptionValue({
    option$: useControlTypeAndSchemasById(id),
  });

  return typeAndSchemas$ ? <Inner {...{ typeAndSchemas$ }} /> : null;
};

import React from "react";
import { Box, InputAdornment, SxProps } from "@mui/material";
import { alpha, Theme } from "@mui/material/styles";
import { styleButtonBorderColor } from "@layouts/ListingTable/utils/styles";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTranslation } from "i18n";
import {
  FilterPropsCustom,
  FilterTypeNumberRange,
} from "../../../types/filters";
import { RemoveButton } from "../common/RemoveButton";

export const NumberRange = ({
  config: { options },
  value,
  onChange,
  onRemove,
}: FilterPropsCustom<FilterTypeNumberRange>) => {
  const { t } = useTranslation();

  const { start, end } = value || {};

  // todo: strict validation on start/end change

  return (
    <Box sx={sxs.wrapper}>
      <Box sx={sxs.labelWrapper}>
        <Typography variant="body2" sx={sxs.label}>
          {options.label}
        </Typography>
        <RemoveButton onClick={onRemove}></RemoveButton>
      </Box>
      <OutlinedInput
        sx={sxs.inputFrom}
        startAdornment={
          <InputAdornment position="start">
            <Typography variant="body2" sx={sxs.inputPrefix}>
              {t("From")}
            </Typography>
          </InputAdornment>
        }
        value={start}
        onChange={({ target: { value } }) =>
          onChange({ start: Number(value) || undefined, end })
        }
      />
      <OutlinedInput
        sx={sxs.inputTo}
        startAdornment={
          <InputAdornment position="start">
            <Typography variant="body2" sx={sxs.inputPrefix}>
              {t("To")}
            </Typography>
          </InputAdornment>
        }
        value={end}
        onChange={({ target: { value } }) =>
          onChange({ start, end: Number(value) || undefined })
        }
      />
    </Box>
  );
};

const sxInput: SxProps<Theme> = (theme) => ({
  width: theme.spacing(30),

  boxShadow: 0,
  borderRadius: 0,
  border: 0,
  position: "relative",
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 0,
    border: `1px solid ${styleButtonBorderColor(theme)}`,
    margin: 0,
    position: "absolute",
    top: "-5.9px",
    left: "-1px",
    right: "-1px",
    bottom: "-1px",
    height: "unset",
  },
  "&:not(:hover):not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: "transparent",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    top: "-5px",
  },
  "& .MuiInputBase-input": {
    ...theme.typography.body2,
    padding: 0,
  },
  "&:hover": {
    zIndex: 1,
  },
  "&.Mui-focused": {
    zIndex: 2,
  },
});

const sxs = {
  wrapper: (theme) => ({
    display: "flex",
    borderRadius: 2,
    border: `1px solid ${styleButtonBorderColor(theme)}`,
    height: theme.spacing(10),
    boxSizing: "border-box",
  }),
  labelWrapper: (theme) => ({
    py: `calc(${theme.spacing(2.5)} - 1px)`,
    px: 3.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 1,
    height: theme.spacing(5),
  }),
  label: (theme) => ({
    lineHeight: theme.spacing(5),
  }),
  inputPrefix: (theme) => ({
    color: alpha(theme.palette.common.black, 0.2),
  }),
  inputFrom: (theme) => sxInput(theme),
  inputTo: (theme) => {
    const sxBase = sxInput(theme);

    return {
      ...sxBase,
      borderRadius: theme.spacing(0, 2, 2, 0),
      "& .MuiOutlinedInput-notchedOutline": {
        // @ts-expect-error fixme
        ...(sxBase["& .MuiOutlinedInput-notchedOutline"] || {}),
        borderRadius: theme.spacing(0, 2, 2, 0),
        left: 0,
      },
    };
  },
} satisfies Record<string, SxProps<Theme>>;

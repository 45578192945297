import { ReactElement, ReactNode } from "react";

import { useTranslation } from "i18n";
import { ConfirmationModal } from "@layouts/Dialogs/ConfirmationModal";
import { CheckCircle } from "icons";

export interface ExecuteModalProps {
  title: ReactNode;
  children?: ReactNode;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  isLoading?: boolean;
}

export function ExecuteModal(p: ExecuteModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      title={p.title}
      confirmButtonText={t("Execute")}
      confirmButtonColor={"primary"}
      onConfirm={p.onConfirm}
      onCancel={p.onCancel}
      icon={<CheckCircle />}
      iconColor={"primary"}
      isLoading={p.isLoading}
    >
      {p.children}
    </ConfirmationModal>
  );
}

import * as Fp from "fp-ts/function";
import { Value, JsonFormSchema } from "types/src/jsonSchema/value";
import { uiSchemaRemoveRedundantGroups } from "../uiSchema/removeRedundantGroups";

type Fixer = (value: Value) => Value;

// Call only on structural changes (add, move, delete) - it's compute intensive
export const fixValue: Fixer = (value) =>
  Fp.pipe(
    value,
    normalizeUiSchema,
    removeOrphanElements,
    removeRedundantGroups,
    fixIds,
  );

const normalizeUiSchema: Fixer = ({ dataSchema, uiSchema }) => ({
  dataSchema,
  uiSchema: JsonFormSchema.normalizeUiSchema(dataSchema, uiSchema),
});

const removeOrphanElements: Fixer = (value) =>
  // todo: uiSchema element.scope must point to existing element in dataSchema
  //       remove orphan elements from both schema
  value;

const removeRedundantGroups = ({ dataSchema, uiSchema }: Value): Value => ({
  dataSchema,
  uiSchema: uiSchemaRemoveRedundantGroups(uiSchema),
});

const fixIds = (value: Value): Value =>
  // todo: generate missing ids (element options.id)
  value;

import { Box, Button as Button_ } from "@mui/material";
import React from "react";
import { useTranslation } from "i18n";
import { ColumnsSpaced, RowsSpaced, Copy, PlusCrop, Trash } from "icons";
import Close from "@mui/icons-material/CloseRounded";
import { Sxs } from "../../../types/styles";

export namespace Actions {
  export type Props = {
    onGroupVertically?: () => void;
    onGroupHorizontally?: () => void;
    onMakeAsLegend?: () => void;
    onClone?: () => void;
    onDelete: () => void;
    onClose: () => void;
  };
}

export const Actions = ({
  onGroupVertically,
  onGroupHorizontally,
  onMakeAsLegend,
  onClone,
  onDelete,
  onClose,
}: Actions.Props) => {
  const { t } = useTranslation();
  return (
    <Box sx={sxs.root}>
      {onGroupVertically && (
        <Button Icon={ColumnsSpaced} onClick={onGroupVertically}>
          {t("Group vertically")}
        </Button>
      )}
      <Button Icon={RowsSpaced} onClick={onGroupHorizontally}>
        {t("Group horizontally")}
      </Button>
      <Button Icon={PlusCrop} onClick={onMakeAsLegend}>
        {t("Make as legend")}
      </Button>
      <Button Icon={Copy} onClick={onClone} />
      <Button Icon={Trash} onClick={onDelete} />
      <Button_
        startIcon={<Close />}
        onClick={onClose}
        sx={sxs.button}
        className="no-children delete"
      />
    </Box>
  );
};

const Button = ({
  Icon,
  children,
  onClick,
}: React.PropsWithChildren<{
  Icon?: React.FC;
  onClick?: () => void;
}>) => (
  <Button_
    variant="outlined"
    size="small"
    startIcon={Icon ? <Icon /> : undefined}
    onClick={onClick}
    disabled={!onClick}
    sx={sxs.button}
    className={!children ? "no-children" : undefined}
  >
    {children}
  </Button_>
);

const sxs = {
  root: (theme) => ({
    padding: 4,

    display: "flex",
    gap: 4,
  }),
  button: (theme) => ({
    minWidth: "unset",
    lineHeight: theme.spacing(5),

    ".MuiButton-icon": {
      color: theme.palette.action.active,
    },
    ".MuiButton-icon svg": {
      height: theme.spacing(5),
      width: theme.spacing(5),
    },
    "&.no-children .MuiButton-icon": {
      margin: 0,
    },
    "&.delete": {
      padding: 0,
      alignSelf: "center",

      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  }),
} satisfies Sxs;

import { JsonForms } from "@jsonforms/react";
import {
  materialCells,
  materialAllOfControlTester,
  MaterialAllOfRenderer,
  materialAnyOfControlTester,
  MaterialAnyOfRenderer,
  MaterialArrayControlRenderer,
  materialArrayControlTester,
  MaterialEnumArrayRenderer,
  materialEnumArrayRendererTester,
  materialObjectControlTester,
  MaterialObjectRenderer,
  materialOneOfControlTester,
  MaterialOneOfRenderer,
  MaterialAnyOfStringOrEnumControl,
  materialAnyOfStringOrEnumControlTester,
  MaterialBooleanControl,
  materialBooleanControlTester,
  MaterialOneOfRadioGroupControl,
  materialOneOfRadioGroupControlTester,
  MaterialRadioGroupControl,
  materialRadioGroupControlTester,
  MaterialArrayLayout,
  materialArrayLayoutTester,
  MaterialCategorizationLayout,
  materialCategorizationTester,
  MaterialGroupLayout,
  materialGroupTester,
  MaterialLabelRenderer,
  materialLabelRendererTester,
  MaterialListWithDetailRenderer,
  materialListWithDetailTester,
} from "@jsonforms/material-renderers";
import { JsonFormSchema } from "types/src/jsonSchema/value";
import { ControlStateProvider } from "@layouts/JsonForm/contexts/ControlState";
import * as Text from "./renderers/Text";
import * as Textarea from "./renderers/Textarea";
import * as OneOf from "./renderers/OneOf";
import * as Enum from "./renderers/Enum";
import * as Number from "./renderers/Number";
import * as Date from "./renderers/Date";
import * as DateTime from "./renderers/DateTime";
import * as Time from "./renderers/Time";
import * as VerticalLayout from "./renderers/VerticalLayout";
import * as HorizontalLayout from "./renderers/HorizontalLayout";
import * as Unknown from "./renderers/Unknown";

export namespace JsonForm {
  export interface ErrorObject<
    K extends string = string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    P = Record<string, any>,
    S = unknown,
  > {
    keyword: K;
    instancePath: string;
    schemaPath: string;
    params: P;
    propertyName?: string;
    message?: string;
    schema?: S;
    data?: unknown;
  }

  export interface Value {
    data: object | undefined;
    errors: ErrorObject[];
  }

  export interface Props {
    schema: JsonFormSchema;
    value: object;
    onChange?: (value: Value) => void;
    submitted: boolean;
  }
}

export function JsonForm(p: JsonForm.Props) {
  return (
    <ControlStateProvider submitted={p.submitted}>
      <JsonForms
        schema={p.schema.schema}
        uischema={p.schema.uiSchema}
        renderers={renderers}
        cells={materialCells}
        onChange={p.onChange}
        data={p.value}
      />
    </ControlStateProvider>
  );
}

const renderers = [
  {
    tester: materialArrayControlTester,
    renderer: MaterialArrayControlRenderer,
  },
  { tester: materialBooleanControlTester, renderer: MaterialBooleanControl },
  { tester: materialObjectControlTester, renderer: MaterialObjectRenderer },
  { tester: materialAllOfControlTester, renderer: MaterialAllOfRenderer },
  { tester: materialAnyOfControlTester, renderer: MaterialAnyOfRenderer },
  { tester: materialOneOfControlTester, renderer: MaterialOneOfRenderer },
  {
    tester: materialRadioGroupControlTester,
    renderer: MaterialRadioGroupControl,
  },
  {
    tester: materialOneOfRadioGroupControlTester,
    renderer: MaterialOneOfRadioGroupControl,
  },
  // layouts
  { tester: materialGroupTester, renderer: MaterialGroupLayout },
  {
    tester: materialCategorizationTester,
    renderer: MaterialCategorizationLayout,
  },
  { tester: materialArrayLayoutTester, renderer: MaterialArrayLayout },
  // additional
  { tester: materialLabelRendererTester, renderer: MaterialLabelRenderer },
  {
    tester: materialListWithDetailTester,
    renderer: MaterialListWithDetailRenderer,
  },
  {
    tester: materialAnyOfStringOrEnumControlTester,
    renderer: MaterialAnyOfStringOrEnumControl,
  },
  {
    tester: materialEnumArrayRendererTester,
    renderer: MaterialEnumArrayRenderer,
  },
  Text.entry,
  Textarea.entry,
  OneOf.entry,
  Enum.entry,
  Number.entry,
  Date.entry,
  DateTime.entry,
  Time.entry,
  VerticalLayout.entry,
  HorizontalLayout.entry,
  Unknown.entry,
];

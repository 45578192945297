import * as Fp from "fp-ts/function";
import { ItemSetItems } from "@Containers/ItemSetsItems";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { Selector } from "state-manager";
import * as O from "fp-ts/Option";

import { useTranslation } from "i18n";
import * as SchemaFields from "state-manager/generic-states/SchemaFields";
import { Input } from "@Containers/Form/FormValue/Input";
import * as FormValue from "types/src/FormValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { MaybeSchemaFieldsJsonEditor } from "@Containers/Form/MaybeSchemaFieldsJsonEditor";
import { ItemSetItem } from "../../../../../packages/state-manager/src/generic-states/ItemSetItem";

export interface ItemSetFormProps<Id extends string> {
  itemsInstance: ItemSetItem.Store;
  schemaInstance: SchemaFields.Store;
  selector: Selector<{
    schema: O.Option<SchemaFields.State>;
    items: Array<[Id, ItemSetItem.State]>;
    sku: FormValue.Value<unknown, NoEmptyString, string | undefined>;
  }>;
  dispatch: {
    setSku: (sku: string) => void;
    schema: (a: SchemaFields.Actions) => void;
    items: (id: Id, a: ItemSetItem.Actions) => void;
    onAdd: () => void;
    onRemove: (id: Id) => void;
  };
}

export function ItemSetForm<Id extends string>(p: ItemSetFormProps<Id>) {
  const { t } = useTranslation();
  const itemsDispatch = p.dispatch.items;
  const schema = p.schemaInstance;

  return (
    <FormWrapper>
      <Input
        label={t("Sku")}
        value={Fp.flow(p.selector, (v) => v.sku)}
        onChange={p.dispatch.setSku}
      />

      <MaybeSchemaFieldsJsonEditor
        value$={Fp.flow(
          p.selector,
          (v) => v.schema,
          O.map((v) => ({
            schema: v.payload.schema,
            uiSchema: v.payload.uiSchema,
            values: v.payload.values,
            submitted: schema.states.invalid.is(v) || schema.states.valid.is(v),
          })),
        )}
        onChange={Fp.flow(
          (v) => v ?? {},
          p.schemaInstance.actions.onChange.create,
          p.dispatch.schema,
        )}
      />

      <ItemSetItems
        constructor={p.itemsInstance}
        label={t("Items")}
        value$={Fp.flow(p.selector, (s) => s.items)}
        onAdd={p.dispatch.onAdd}
        onRemove={p.dispatch.onRemove}
        dispatch={(id, a) => itemsDispatch(id, a)}
      />
    </FormWrapper>
  );
}

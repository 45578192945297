import * as O from "fp-ts/Option";
import { DateRange } from "types/src/date/DateRange";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { CustomerId } from "types/src/Customers/Customer";
import { ISODate } from "types/src/date/ISODate";
import * as Rx from "rxjs";
import { Client, isNotFoundError } from "ds";
import * as Customers from "ds/Customers";
import { getDataType, getDataTypes } from "ds/DataTypes";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { Tuple } from "types/src/Tuple";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingWithDataType } from "../../../../../../../../generic-states/ListingWithDataType";

const prefix = "Ready:DataManager:Customers:Listing";

function createState() {
  const state = ListingWithDataType.createState<
    CustomersListing.Filters,
    CustomersListing.Item,
    "createdAt" | "updatedAt",
    { id: DataTypeId }
  >(prefix, {
    defaultFilters: {},
  });

  const epic: Epic<
    ListingWithDataType.GetActions<typeof state>,
    ListingWithDataType.GetState<typeof state>,
    CustomersListing.Deps
  > = (state$, deps) => {
    return state.epic(state$, {
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(Customers.getCustomers(client, getFetchVars(s))),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: { in: [DataTypeEntity.Customer] },
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;
                    if (E.isLeft(v.dataTypes)) return v.dataTypes;

                    return E.right({
                      items: v.items.right,
                      dataTypes: v.dataTypes.right,
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      id: i.id,
                      createdAt: i.createdAt,
                      updatedAt: O.fromNullable(i.updatedAt),
                      dataType: O.fromNullable(
                        r.dataTypes.items.find((v) => v.id === i.dataTypeId),
                      ),
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(Customers.deleteCustomers(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
      fetchDataType: (id) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            return Rx.from(getDataType(client, id)).pipe(
              Rx.map(E.map(O.some)),
              Rx.map(
                E.orElse((e) => {
                  if (isNotFoundError(e)) return E.right(O.none);

                  return E.left(e);
                }),
              ),
            );
          }),
        );
      },
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
    });
  };

  return { ...state, epic };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): Customers.GetCustomersVars {
    const fields = s.payload.filters.payload.fields;
    const where: Customers.GetCustomersVars["where"] = {
      dataType: {
        in: [s.payload.id],
        isNil: Fp.pipe(
          fields.status,
          O.fromNullable,
          O.map((v) => v === "orphan"),
          O.toUndefined,
        ),
      },
      and: [
        {
          createdAt: {
            gte: fields.createdAt?.[0],
            lte: fields.createdAt?.[1],
          },
          updatedAt: {
            gte: fields.updatedAt?.[0],
            lte: fields.updatedAt?.[1],
          },
        },
      ],
      or: [
        {
          id: {
            eq: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.map(CustomerId.fromString),
              O.toUndefined,
            ),
          },
        },
        {
          data: {
            contains: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.chain(NoEmptyString.fromString),
              O.map(Tuple.create("")),
              O.toUndefined,
            ),
          },
        },
      ],
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
}

export namespace CustomersListing {
  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: (
      id: DataTypeId,
    ) => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (id: DataTypeId, v: Record<string, boolean>) => void;
  }

  export type Filters = Partial<{
    createdAt: DateRange;
    updatedAt: DateRange;
    search: string;
    status: "active" | "orphan";
  }>;

  export interface Item {
    id: CustomerId;
    createdAt: ISODate;
    updatedAt: O.Option<ISODate>;
    dataType: O.Option<{ id: DataTypeId; name: string }>;
  }

  export const instance = createState();

  export type State = ListingWithDataType.GetState<typeof instance>;
  export type Actions = ListingWithDataType.GetActions<typeof instance>;
  export type Exits = ListingWithDataType.GetExits<typeof instance>;
}

import { Box, Button, ButtonGroup, ButtonProps, useTheme } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "i18n";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Theme } from "@mui/material/styles";
import { Sxs } from "../../../../types/styles";

export function Header({
  previewMode,
  onPreviewModeChange,
  onCopy,
  onReset,
}: Header.Props) {
  const { t } = useTranslation();
  const copyButtonProps = useCopyButtonProps({ onCopy });

  return (
    <Box sx={sxs.root}>
      <Box>
        <ButtonGroup variant="outlined" disableRipple>
          <Button
            disabled={previewMode === Header.PreviewMode.form}
            onClick={() => onPreviewModeChange(Header.PreviewMode.form)}
          >
            {t("Form Preview")}
          </Button>
          <Button
            disabled={previewMode === Header.PreviewMode.schema}
            onClick={() => onPreviewModeChange(Header.PreviewMode.schema)}
          >
            {t("JSON Schema")}
          </Button>
        </ButtonGroup>
      </Box>
      <Box>
        {previewMode === Header.PreviewMode.form && (
          <Button
            variant="text"
            disabled={!onReset}
            onClick={onReset}
            sx={sxs.resetBtn}
          >
            {t("Reset all")}
          </Button>
        )}
        {previewMode === Header.PreviewMode.schema && (
          <Button variant="text" {...copyButtonProps}>
            {t("Copy")}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export namespace Header {
  export enum PreviewMode {
    form = "form",
    schema = "schema",
  }

  export type Props = {
    previewMode: PreviewMode;
    onPreviewModeChange: (mode: PreviewMode) => void;
    onCopy: () => void;
    onReset?: () => void;
  };
}

const sxs = {
  root: (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 6,
  }),
  copyBtn: (theme) => ({
    color: theme.palette.text.secondary,
  }),
  resetBtn: (theme) => ({
    color: /*todo: set in Theme*/ "#5F2120",
  }),
} satisfies Sxs;

const useCopyButtonProps = ({
  onCopy,
}: Pick<Header.Props, "onCopy">): Pick<ButtonProps, "sx" | "onClick"> => {
  const theme = useTheme();
  const [sx, setSx] = useState<SystemStyleObject<Theme>>(() =>
    sxs.copyBtn(theme),
  );
  const feedbackTimeout = useRef<ReturnType<typeof setTimeout>>();

  const onClick = useCallback(() => {
    onCopy();
    setSx({
      ...sxs.copyBtn(theme),
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.light,
    });

    clearTimeout(feedbackTimeout.current);
    feedbackTimeout.current = setTimeout(() => setSx(sxs.copyBtn(theme)), 1000);
  }, [onCopy, theme]);

  return { sx, onClick };
};

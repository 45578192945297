import { useTranslation } from "i18n";
import { Box } from "@mui/material";
import { Sxs } from "../../../../../types/styles";
import {
  CellType as Type,
  ColumnConfigBase,
  GetColumnSubType,
} from "../../../types/columns";
import { DataEntryBase } from "../../../types/data";
import { styleSecondaryTextColor } from "../../../utils/styles";
import { formatAsTimeDate } from "../../../utils/date";

export namespace TimeDate {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnConfig extends ColumnConfigBase<DataEntry>,
  > = ReturnType<
    GetColumnSubType<DataEntry, ColumnConfig, Type.timeDate>["renderProps"]
  >;
}

export const TimeDate = <
  DataEntry extends DataEntryBase,
  ColumnConfig extends ColumnConfigBase<DataEntry>,
>({
  date,
}: TimeDate.Props<DataEntry, ColumnConfig>) => {
  const { language } = useTranslation();

  return (
    <Box sx={sxs.wrapper}>{date ? formatAsTimeDate(date, language) : "-"}</Box>
  );
};

const sxs = {
  wrapper: (theme) => ({
    ...theme.typography.body2,
    whiteSpace: "nowrap",
    color: styleSecondaryTextColor(theme),
  }),
} satisfies Sxs;

import { useDispatch, useSelector } from "state-manager";
import * as Auth from "state-manager/states/Auth";

import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";
import * as BPMNPreview from "state-manager/states/Ready/states/BPMNPreview";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";
import * as GraphqlPlayground from "state-manager/states/Ready/states/GraphqlPlayground";
import * as Temporal from "state-manager/states/Ready/states/Temporal";

import { Customers } from "state-manager/states/Ready/states/DataManager/states/Customers";
import { Suppliers } from "state-manager/states/Ready/states/DataManager/states/Suppliers";
import { Repositories } from "state-manager/states/Ready/states/DataManager/states/Repositories";
import { InventoryItems } from "state-manager/states/Ready/states/DataManager/states/InventoryItems";
import { ItemMovements } from "state-manager/states/Ready/states/DataManager/states/ItemMovements";
import { RepositoryMovements } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements";
import { StocksListing } from "state-manager/states/Ready/states/DataManager/states/Stocks";

import { silentUnreachableError, unreachableError } from "utils/exceptions";
import * as Ready from "state-manager/states/Ready";
import { isSigningOut } from "state-manager/states/Ready";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { flow } from "fp-ts/function";
import { DataTypesListing } from "../../../../packages/state-manager/src/states/Ready/states/DataTypesListing";
import { DataTypeCreate } from "../../../../packages/state-manager/src/states/Ready/states/DataTypeCreate";
import { DataTypeEdit } from "../../../../packages/state-manager/src/states/Ready/states/DataTypeEdit";
import { Inbounds } from "../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Inbounds";
import { ItemSets } from "../../../../packages/state-manager/src/states/Ready/states/DataManager/states/ItemSets";
import { Collections } from "../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Collections";
import * as Router from "@/router";

export function RouterDispatcher() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const statePath = useSelector(
    flow(
      (s) => s.content,
      (s): Router.Route | undefined => {
        if (Auth.isState(s)) {
          if (Auth.states.idle.is(s)) return undefined;
          if (Auth.states.authConfirmation.is(s)) return "/callback";
          if (Auth.states.login.is(s)) return "/login";
          if (Auth.states.authError.is(s)) return "/login";
          if (Auth.states.authentication.is(s)) return "/login";
        }

        if (Ready.isState(s)) {
          const subState = s.payload.subState;

          if (isSigningOut(subState)) return "/login";

          if (DataManager.isState(subState)) {
            const dmSubState = subState.payload.subState;

            if (Collections.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (Collections.instance.subStates.listing.isState(listing)) {
                return Router.routes["/collections/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (Collections.instance.subStates.view.isState(listing)) {
                return Router.routes["/collections/view/:id"].create({
                  id: listing.payload.id,
                });
              }

              if (Collections.instance.subStates.listingAll.isState(listing)) {
                return Router.routes["/collections"].create();
              }

              silentUnreachableError(listing);
              return undefined;
            }

            if (Customers.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (Customers.instance.subStates.listing.isState(listing)) {
                return Router.routes["/customers/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (Customers.instance.subStates.listingAll.isState(listing)) {
                return Router.routes["/customers"].create();
              }

              return undefined;
            }

            if (Inbounds.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (Inbounds.instance.subStates.listing.isState(listing)) {
                return Router.routes["/inbounds/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (Inbounds.instance.subStates.listingAll.isState(listing)) {
                return Router.routes["/inbounds"].create();
              }

              return undefined;
            }

            if (ItemSets.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (ItemSets.instance.subStates.listing.isState(listing)) {
                return Router.routes["/item-sets/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (ItemSets.instance.subStates.listingAll.isState(listing)) {
                return Router.routes["/item-sets"].create();
              }

              return undefined;
            }

            if (InventoryItems.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (InventoryItems.instance.subStates.listing.isState(listing)) {
                return Router.routes["/inventory-items/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (
                InventoryItems.instance.subStates.listingAll.isState(listing)
              ) {
                return Router.routes["/inventory-items"].create();
              }

              return undefined;
            }

            if (ItemMovements.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (ItemMovements.instance.subStates.listing.isState(listing)) {
                return Router.routes["/item-movements/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (
                ItemMovements.instance.subStates.listingAll.isState(listing)
              ) {
                return Router.routes["/item-movements"].create();
              }

              return undefined;
            }

            if (DataManager.pickingOrderState.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (
                DataManager.pickingOrderState.subStates.listing.isState(listing)
              ) {
                return Router.routes["/picking-orders/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (
                DataManager.pickingOrderState.subStates.listingAll.isState(
                  listing,
                )
              ) {
                return Router.routes["/picking-orders"].create();
              }

              return Router.routes["/picking-orders"].create();
            }

            if (Suppliers.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (Suppliers.instance.subStates.listing.isState(listing)) {
                return Router.routes["/suppliers/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (Suppliers.instance.subStates.listingAll.isState(listing)) {
                return Router.routes["/suppliers"].create();
              }

              return undefined;
            }

            if (Repositories.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (Repositories.instance.subStates.listing.isState(listing)) {
                return Router.routes["/repositories/:dataTypeId"].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (Repositories.instance.subStates.listingAll.isState(listing)) {
                return Router.routes["/repositories"].create();
              }

              return undefined;
            }

            if (RepositoryMovements.instance.isState(dmSubState)) {
              const listing = dmSubState.payload.listing;

              if (
                RepositoryMovements.instance.subStates.listing.isState(listing)
              ) {
                return Router.routes[
                  "/repository-movements/:dataTypeId"
                ].create({
                  dataTypeId: listing.payload.id,
                });
              }

              if (
                RepositoryMovements.instance.subStates.listingAll.isState(
                  listing,
                )
              ) {
                return Router.routes["/repository-movements"].create();
              }

              return undefined;
            }

            if (StocksListing.instance.isState(dmSubState)) {
              return Router.routes["/stocks"].create();
            }

            silentUnreachableError(dmSubState);
            return undefined;
          }

          if (DataTypesListing.instance.isState(subState))
            return Router.routes["/data-types"].create();
          if (DataTypeCreate.instance.isState(subState))
            return Router.routes["/data-types/add"].create();
          if (DataTypeEdit.instance.isState(subState))
            return Router.routes["/data-types/:id"].create({
              id: subState.payload.id,
            });

          if (BuilderPreview.isState(subState)) return "/warehouse-builder";
          if (BPMNPreview.isState(subState)) return "/bpmn-builder";
          if (ZitadelPreview.isState(subState)) return "/zitadel-app";
          if (GraphqlPlayground.instance.isState(subState))
            return "/graphql-playground";
          if (Temporal.instance.isState(subState)) return "/temporal";

          unreachableError(subState);
          return undefined;
        }

        unreachableError(s);
        return undefined;
      },
    ),
  );

  useEffect(
    () => {
      if (statePath && statePath !== location.pathname) {
        const t = requestAnimationFrame(() => navigate(statePath));

        return () => cancelAnimationFrame(t);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statePath && statePath !== location.pathname],
  );

  useEffect(
    () => {
      if (location.pathname !== statePath) {
        const action = Router.getRedirectAction(location.pathname);

        if (action === undefined) return;

        dispatch(action);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
  );

  return null;
}

import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import { mergeDeepRight } from "rambda";
import * as Ready from "./types/State";
import * as Actions from "./types/Actions";
import * as Exits from "./types/Exits";
import * as DataManager from "./states/DataManager";
import * as BuilderPreview from "./states/BuilderPreview";
import * as BPMNPreview from "./states/BPMNPreview";
import * as ZitadelPreview from "./states/ZitadelPreview";
import * as GraphqlPlayground from "./states/GraphqlPlayground";
import * as Temporal from "./states/Temporal";

import { Customers } from "./states/DataManager/states/Customers";

import { pickingOrderState } from "./states/DataManager/utils";
import { InventoryItems } from "./states/DataManager/states/InventoryItems";
import { ItemMovements } from "./states/DataManager/states/ItemMovements";
import { Repositories } from "./states/DataManager/states/Repositories";
import { RepositoryMovements } from "./states/DataManager/states/RepositoryMovements";
import { Suppliers } from "./states/DataManager/states/Suppliers";
import { DataTypesListing } from "./states/DataTypesListing";
import { DataTypeCreate } from "./states/DataTypeCreate";
import { DataTypeEdit } from "./states/DataTypeEdit";
import { Collections } from "./states/DataManager/states/Collections";
import { Inbounds } from "./states/DataManager/states/Inbounds";
import { ItemSets } from "./states/DataManager/states/ItemSets";
import { StocksListing } from "./states/DataManager/states/Stocks";

export function reducer(
  s: Ready.State,
  a: Actions.Actions,
): E.Either<Exits.Exits, Ready.State> {
  if (DataManager.isActions(a)) {
    if (DataManager.isState(s.payload.subState)) {
      const subState = DataManager.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? E.right(
            Ready.state({
              user: s.payload.user,
              organizations: {
                isActive: s.payload.organizations.isActive,
                ids: s.payload.organizations.ids,
                entities: s.payload.organizations.entities,
              },
              orgId: s.payload.orgId,
              openAI: s.payload.openAI,
              userSettings: s.payload.userSettings,
              subState,
            }),
          )
        : E.right(s);
    }

    return E.right(s);
  }

  if (BuilderPreview.isActions(a)) {
    if (BuilderPreview.isState(s.payload.subState)) {
      const subState = BuilderPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? E.right(
            Ready.state({
              user: s.payload.user,
              organizations: {
                isActive: s.payload.organizations.isActive,
                ids: s.payload.organizations.ids,
                entities: s.payload.organizations.entities,
              },
              orgId: s.payload.orgId,
              openAI: s.payload.openAI,
              userSettings: s.payload.userSettings,
              subState,
            }),
          )
        : E.right(s);
    }
    return E.right(s);
  }

  if (BPMNPreview.isActions(a)) {
    if (BPMNPreview.isState(s.payload.subState)) {
      const subState = BPMNPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? E.right(
            Ready.state({
              user: s.payload.user,
              organizations: {
                isActive: s.payload.organizations.isActive,
                ids: s.payload.organizations.ids,
                entities: s.payload.organizations.entities,
              },
              orgId: s.payload.orgId,
              openAI: s.payload.openAI,
              userSettings: s.payload.userSettings,
              subState,
            }),
          )
        : E.right(s);
    }
    return E.right(s);
  }

  if (ZitadelPreview.isActions(a)) {
    if (ZitadelPreview.isState(s.payload.subState)) {
      const subState = ZitadelPreview.reducer(s.payload.subState, a);

      return s.payload.subState !== subState
        ? E.right(
            Ready.state({
              user: s.payload.user,
              organizations: {
                isActive: s.payload.organizations.isActive,
                ids: s.payload.organizations.ids,
                entities: s.payload.organizations.entities,
              },
              orgId: s.payload.orgId,
              openAI: s.payload.openAI,
              userSettings: s.payload.userSettings,
              subState,
            }),
          )
        : E.right(s);
    }
    return E.right(s);
  }

  if (DataTypesListing.instance.isAction(a)) {
    if (DataTypesListing.instance.isState(s.payload.subState)) {
      const subState = Fp.pipe(
        DataTypesListing.instance.reducer(s.payload.subState, a),
        E.getOrElse((e) => {
          if (DataTypesListing.instance.exits.create.is(e)) {
            return DataTypeCreate.instance.init();
          }

          silentUnreachableError(e);
          return s.payload.subState;
        }),
      );

      return s.payload.subState !== subState
        ? E.right(
            Ready.state({
              user: s.payload.user,
              organizations: {
                isActive: s.payload.organizations.isActive,
                ids: s.payload.organizations.ids,
                entities: s.payload.organizations.entities,
              },
              orgId: s.payload.orgId,
              openAI: s.payload.openAI,
              userSettings: s.payload.userSettings,
              subState,
            }),
          )
        : E.right(s);
    }
    return E.right(s);
  }

  if (DataTypeCreate.instance.isAction(a)) {
    if (DataTypeCreate.instance.isState(s.payload.subState)) {
      const subState = Fp.pipe(
        DataTypeCreate.instance.reducer(s.payload.subState, a),
        E.getOrElse((e) => {
          if (
            DataTypeCreate.instance.exits.created.is(e) ||
            DataTypeCreate.instance.exits.canceled.is(e)
          )
            return DataTypesListing.instance.init({});

          silentUnreachableError(e);
          return s.payload.subState;
        }),
      );

      return s.payload.subState !== subState
        ? E.right(
            Ready.state({
              user: s.payload.user,
              organizations: {
                isActive: s.payload.organizations.isActive,
                ids: s.payload.organizations.ids,
                entities: s.payload.organizations.entities,
              },
              orgId: s.payload.orgId,
              openAI: s.payload.openAI,
              userSettings: s.payload.userSettings,
              subState,
            }),
          )
        : E.right(s);
    }
    return E.right(s);
  }

  if (DataTypeEdit.instance.isAction(a)) {
    if (DataTypeEdit.instance.isState(s.payload.subState)) {
      const subState = Fp.pipe(
        DataTypeEdit.instance.reducer(s.payload.subState, a),
        E.getOrElse((e) => {
          if (
            DataTypeEdit.instance.exits.removed.is(e) ||
            DataTypeEdit.instance.exits.saved.is(e) ||
            DataTypeEdit.instance.exits.canceled.is(e)
          )
            return DataTypesListing.instance.init({});

          silentUnreachableError(e);
          return s.payload.subState;
        }),
      );

      return s.payload.subState !== subState
        ? E.right(
            Ready.state({
              user: s.payload.user,
              organizations: {
                isActive: s.payload.organizations.isActive,
                ids: s.payload.organizations.ids,
                entities: s.payload.organizations.entities,
              },
              orgId: s.payload.orgId,
              openAI: s.payload.openAI,
              userSettings: s.payload.userSettings,
              subState,
            }),
          )
        : E.right(s);
    }
    return E.right(s);
  }

  if (a.type === "Ready:SignOut") {
    if (Ready.isSigningOut(s.payload.subState)) return E.right(s);

    return E.right(
      Ready.state({
        user: s.payload.user,
        organizations: {
          isActive: s.payload.organizations.isActive,
          ids: s.payload.organizations.ids,
          entities: s.payload.organizations.entities,
        },
        orgId: s.payload.orgId,
        openAI: s.payload.openAI,
        userSettings: s.payload.userSettings,
        subState: Ready.signingOut({}),
      }),
    );
  }

  if (a.type === "Ready:SignedOut") {
    if (!Ready.isSigningOut(s.payload.subState)) return E.right(s);

    return E.left(Exits.exits.logout.create(Actions.goToCustomers()));
  }

  if (a.type === "Ready:Reauthorize") {
    if (E.isLeft(s.payload.user)) return E.right(s);

    return E.right({
      ...s,
      payload: {
        ...s.payload,
        user: E.left({
          id: s.payload.user.right.id,
          avatar: s.payload.user.right.avatar,
          username: s.payload.user.right.username,
          email: s.payload.user.right.email,
        }),
      },
    });
  }

  if (a.type === "Ready:ReauthorizeFail") {
    if (!E.isLeft(s.payload.user)) return E.right(s);

    return E.left(Exits.exits.logout.create(Actions.goToCustomers()));
  }

  if (a.type === "Ready:ReauthorizeSuccess") {
    if (!E.isLeft(s.payload.user)) return E.right(s);

    return E.right({
      ...s,
      payload: {
        ...s.payload,
        user: E.right({
          id: a.payload.id,
          avatar: a.payload.avatar,
          username: a.payload.username,
          email: a.payload.email,
          accessToken: a.payload.accessToken,
        }),
      },
    });
  }

  if (Actions.isGoto(a)) {
    if (Ready.isSigningOut(s.payload.subState)) return E.right(s);

    const getSubState = (
      a: Actions.Goto,
    ): Ready.State["payload"]["subState"] => {
      const getDataManagerState = (
        subState: DataManager.State["payload"]["subState"],
      ): DataManager.State => {
        if (DataManager.isState(s.payload.subState))
          return {
            ...s.payload.subState,
            payload: {
              ...s.payload.subState.payload,
              subState,
            },
          } as DataManager.State;
        return {
          ...DataManager.init(),
          payload: {
            ...DataManager.init().payload,
            subState,
          },
        } as DataManager.State;
      };

      switch (a.type) {
        case "Ready:GoTo:GraphQlPlayground":
          return Fp.pipe(
            s.payload.subState,
            O.of,
            O.filter((s) => !GraphqlPlayground.instance.isState(s)),
            O.map(() => GraphqlPlayground.instance.init()),
            O.getOrElseW(() => s.payload.subState),
          );
        case "Ready:GoTo:Temporal":
          return Fp.pipe(
            s.payload.subState,
            O.of,
            O.filter((s) => !Temporal.instance.isState(s)),
            O.map(() => Temporal.instance.init()),
            O.getOrElseW(() => s.payload.subState),
          );
        case "Ready:GoTo:DataTypes:Create":
          return Fp.pipe(
            s.payload.subState,
            O.of,
            O.filter((s) => !DataTypeCreate.instance.isState(s)),
            O.map(() => DataTypeCreate.instance.init()),
            O.getOrElseW(() => s.payload.subState),
          );
        case "Ready:GoTo:DataTypes:Edit":
          return Fp.pipe(
            s.payload.subState,
            O.of,
            O.filter((s) => !DataTypeEdit.instance.isState(s)),
            O.map(() => DataTypeEdit.instance.init(a.payload)),
            O.getOrElseW(() => s.payload.subState),
          );

        case "Ready:GoTo:Collections":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Collections.instance.isState),
              O.getOrElse(Collections.instance.init),
              (s) =>
                Collections.instance.reducer(
                  s,
                  Collections.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:Collections:View":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Collections.instance.isState),
              O.getOrElse(Collections.instance.init),
              (s) =>
                Collections.instance.reducer(
                  s,
                  Collections.instance.actions.goToView.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Collections:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Collections.instance.isState),
              O.getOrElse(Collections.instance.init),
              (s) =>
                Collections.instance.reducer(
                  s,
                  Collections.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:Customers":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Customers.instance.isState),
              O.getOrElse(Customers.instance.init),
              (s) =>
                Customers.instance.reducer(
                  s,
                  Customers.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:Customers:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Customers.instance.isState),
              O.getOrElse(Customers.instance.init),
              (s) =>
                Customers.instance.reducer(
                  s,
                  Customers.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Customers:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Customers.instance.isState),
              O.getOrElse(Customers.instance.init),
              (s) =>
                Customers.instance.reducer(
                  s,
                  Customers.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Customers:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Customers.instance.isState),
              O.getOrElse(Customers.instance.init),
              (s) =>
                Customers.instance.reducer(
                  s,
                  Customers.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:Inbounds":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Inbounds.instance.isState),
              O.getOrElse(Inbounds.instance.init),
              (s) =>
                Inbounds.instance.reducer(
                  s,
                  Inbounds.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:Inbounds:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Inbounds.instance.isState),
              O.getOrElse(Inbounds.instance.init),
              (s) =>
                Inbounds.instance.reducer(
                  s,
                  Inbounds.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Inbounds:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Inbounds.instance.isState),
              O.getOrElse(Inbounds.instance.init),
              (s) =>
                Inbounds.instance.reducer(
                  s,
                  Inbounds.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Inbounds:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Inbounds.instance.isState),
              O.getOrElse(Inbounds.instance.init),
              (s) =>
                Inbounds.instance.reducer(
                  s,
                  Inbounds.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:ItemSets":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemSets.instance.isState),
              O.getOrElse(ItemSets.instance.init),
              (s) =>
                ItemSets.instance.reducer(
                  s,
                  ItemSets.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:ItemSets:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemSets.instance.isState),
              O.getOrElse(ItemSets.instance.init),
              (s) =>
                ItemSets.instance.reducer(
                  s,
                  ItemSets.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:ItemSets:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemSets.instance.isState),
              O.getOrElse(ItemSets.instance.init),
              (s) =>
                ItemSets.instance.reducer(
                  s,
                  ItemSets.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:ItemSets:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemSets.instance.isState),
              O.getOrElse(ItemSets.instance.init),
              (s) =>
                ItemSets.instance.reducer(
                  s,
                  ItemSets.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:Suppliers":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Suppliers.instance.isState),
              O.getOrElse(Suppliers.instance.init),
              (s) =>
                Suppliers.instance.reducer(
                  s,
                  Suppliers.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:Suppliers:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Suppliers.instance.isState),
              O.getOrElse(Suppliers.instance.init),
              (s) =>
                Suppliers.instance.reducer(
                  s,
                  Suppliers.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Suppliers:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Suppliers.instance.isState),
              O.getOrElse(Suppliers.instance.init),
              (s) =>
                Suppliers.instance.reducer(
                  s,
                  Suppliers.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Suppliers:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Suppliers.instance.isState),
              O.getOrElse(Suppliers.instance.init),
              (s) =>
                Suppliers.instance.reducer(
                  s,
                  Suppliers.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:WarehouseBuilder":
          return BuilderPreview.init();
        case "Ready:GoTo:BPMNBuilder":
          return BPMNPreview.idle({});
        case "Ready:GoTo:ZitadelApp":
          return ZitadelPreview.idle({});

        case "Ready:GoTo:Repositories":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Repositories.instance.isState),
              O.getOrElse(Repositories.instance.init),
              (s) =>
                Repositories.instance.reducer(
                  s,
                  Repositories.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:Repositories:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Repositories.instance.isState),
              O.getOrElse(Repositories.instance.init),
              (s) =>
                Repositories.instance.reducer(
                  s,
                  Repositories.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Repositories:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Repositories.instance.isState),
              O.getOrElse(Repositories.instance.init),
              (s) =>
                Repositories.instance.reducer(
                  s,
                  Repositories.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Repositories:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(Repositories.instance.isState),
              O.getOrElse(Repositories.instance.init),
              (s) =>
                Repositories.instance.reducer(
                  s,
                  Repositories.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:RepositoryMovements":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(RepositoryMovements.instance.isState),
              O.getOrElse(RepositoryMovements.instance.init),
              (s) =>
                RepositoryMovements.instance.reducer(
                  s,
                  RepositoryMovements.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:RepositoryMovements:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(RepositoryMovements.instance.isState),
              O.getOrElse(RepositoryMovements.instance.init),
              (s) =>
                RepositoryMovements.instance.reducer(
                  s,
                  RepositoryMovements.instance.actions.goToCreate.create(
                    a.payload,
                  ),
                ),
            ),
          );
        case "Ready:GoTo:RepositoryMovements:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(RepositoryMovements.instance.isState),
              O.getOrElse(RepositoryMovements.instance.init),
              (s) =>
                RepositoryMovements.instance.reducer(
                  s,
                  RepositoryMovements.instance.actions.goToEdit.create(
                    a.payload,
                  ),
                ),
            ),
          );
        case "Ready:GoTo:RepositoryMovements:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(RepositoryMovements.instance.isState),
              O.getOrElse(RepositoryMovements.instance.init),
              (s) =>
                RepositoryMovements.instance.reducer(
                  s,
                  RepositoryMovements.instance.actions.goToListing.create(
                    a.payload,
                  ),
                ),
            ),
          );

        case "Ready:GoTo:InventoryItems":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(InventoryItems.instance.isState),
              O.getOrElse(InventoryItems.instance.init),
              (s) =>
                InventoryItems.instance.reducer(
                  s,
                  InventoryItems.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:InventoryItems:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(InventoryItems.instance.isState),
              O.getOrElse(InventoryItems.instance.init),
              (s) =>
                InventoryItems.instance.reducer(
                  s,
                  InventoryItems.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:InventoryItems:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(InventoryItems.instance.isState),
              O.getOrElse(InventoryItems.instance.init),
              (s) =>
                InventoryItems.instance.reducer(
                  s,
                  InventoryItems.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:InventoryItems:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(InventoryItems.instance.isState),
              O.getOrElse(InventoryItems.instance.init),
              (s) =>
                InventoryItems.instance.reducer(
                  s,
                  InventoryItems.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:ItemMovements":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemMovements.instance.isState),
              O.getOrElse(ItemMovements.instance.init),
              (s) =>
                ItemMovements.instance.reducer(
                  s,
                  ItemMovements.instance.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:ItemMovements:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemMovements.instance.isState),
              O.getOrElse(ItemMovements.instance.init),
              (s) =>
                ItemMovements.instance.reducer(
                  s,
                  ItemMovements.instance.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:ItemMovements:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemMovements.instance.isState),
              O.getOrElse(ItemMovements.instance.init),
              (s) =>
                ItemMovements.instance.reducer(
                  s,
                  ItemMovements.instance.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:ItemMovements:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(ItemMovements.instance.isState),
              O.getOrElse(ItemMovements.instance.init),
              (s) =>
                ItemMovements.instance.reducer(
                  s,
                  ItemMovements.instance.actions.goToListing.create(a.payload),
                ),
            ),
          );

        case "Ready:GoTo:PickingOrders":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(pickingOrderState.isState),
              O.getOrElse(pickingOrderState.init),
              (s) =>
                pickingOrderState.reducer(
                  s,
                  pickingOrderState.actions.goToListingAll.create(),
                ),
            ),
          );
        case "Ready:GoTo:PickingOrders:Create":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(pickingOrderState.isState),
              O.getOrElse(pickingOrderState.init),
              (s) =>
                pickingOrderState.reducer(
                  s,
                  pickingOrderState.actions.goToCreate.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:PickingOrders:Edit":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(pickingOrderState.isState),
              O.getOrElse(pickingOrderState.init),
              (s) =>
                pickingOrderState.reducer(
                  s,
                  pickingOrderState.actions.goToEdit.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:PickingOrders:DataType":
          return getDataManagerState(
            Fp.pipe(
              s.payload.subState,
              O.of,
              O.filter(DataManager.isState),
              O.map((s) => s.payload.subState),
              O.filter(pickingOrderState.isState),
              O.getOrElse(pickingOrderState.init),
              (s) =>
                pickingOrderState.reducer(
                  s,
                  pickingOrderState.actions.goToListing.create(a.payload),
                ),
            ),
          );
        case "Ready:GoTo:Stocks:Listing":
          return getDataManagerState(StocksListing.instance.init());

        case "Ready:GoTo:Datatypes:Listing": {
          return DataTypesListing.instance.init({});
        }
      }
    };

    return E.right(
      Ready.state({
        ...s.payload,
        organizations: {
          ...s.payload.organizations,
          isActive: false,
        },
        subState: getSubState(a),
      }),
    );
  }

  if (a.type === "Ready:ChangeActive:Organizations") {
    return E.right({
      ...s,
      payload: {
        ...s.payload,
        organizations: {
          ...s.payload.organizations,
          isActive: a.payload,
        },
      },
    });
  }

  if (a.type === "Ready:ChangeOrgId:Organizations") {
    return E.right({
      ...s,
      payload: {
        ...s.payload,
        orgId: a.payload,
        subState: DataManager.init(),
      },
    });
  }
  if (a.type === "Ready:SetUserSettings") {
    return E.right({
      ...s,
      payload: {
        ...s.payload,
        userSettings: mergeDeepRight(s.payload.userSettings, a.payload),
      },
    });
  }

  silentUnreachableError(a);
  return E.right(s);
}

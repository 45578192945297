import { silentUnreachableError } from "utils/exceptions";
import * as FormValue from "types/src/FormValue";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import * as Fp from "fp-ts/function";
import { pipe } from "fp-ts/function";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Typed } from "utils/Typed";
import { isT } from "fp-utilities";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import * as Exits from "./types/Exits";
import { schemaFieldsState } from "./utils";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          submitted: s.payload.submitted,
          dataTypeId: s.payload.dataTypeId,
          schema: Fp.pipe(
            schemaFieldsState.reducer(s.payload.schema, a),
            E.getOrElse(() => s.payload.schema),
          ),
          sku: s.payload.sku,
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:InventoryItems:Create:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(
          State.loadError({
            dataTypeId: s.payload.dataTypeId,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Create:LoadSuccess": {
      if (State.isLoading(s)) {
        return E.right(
          State.ready({
            submitted: false,
            dataTypeId: s.payload.dataTypeId,
            sku: FormValue.initial(undefined),
            schema: schemaFieldsState.states.init.create({
              schema: a.payload,
              uiSchema: undefined,
              values: {},
            }),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Create:SetSku": {
      if (State.isReady(s)) {
        return E.right(
          State.ready({
            submitted: s.payload.submitted,
            dataTypeId: s.payload.dataTypeId,
            schema: s.payload.schema,
            sku: pipe(
              a.payload,
              O.fromNullable,
              O.chain(O.fromPredicate(NoEmptyString.isNoEmptyString)),
              O.map(FormValue.valid),
              O.getOrElseW(() =>
                FormValue.invalid("required" as const, a.payload),
              ),
            ),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Create:Submit": {
      if (State.isReady(s)) {
        const schema = Fp.pipe(
          schemaFieldsState.reducer(
            s.payload.schema,
            schemaFieldsState.actions.submit.create(),
          ),
          E.getOrElse(() => s.payload.schema),
        );
        const sku = Fp.pipe(
          s.payload.sku,
          E.fromPredicate(FormValue.isInitial, (v) => v),
          E.map((v) => v.value),
          E.filterOrElseW(isT, FormValue.invalid("required" as const)),
          E.filterOrElseW(
            NoEmptyString.isNoEmptyString,
            FormValue.invalid("required" as const),
          ),
          E.map(FormValue.valid),
          E.toUnion,
        );

        if (
          FormValue.isValid(sku) &&
          schemaFieldsState.states.valid.is(schema)
        ) {
          return E.right(
            State.saving({
              submitted: true,
              dataTypeId: s.payload.dataTypeId,
              schema: schema,
              sku: sku,
            }),
          );
        } else {
          return E.right(
            State.ready({
              submitted: true,
              dataTypeId: s.payload.dataTypeId,
              schema: schema,
              sku: sku,
            }),
          );
        }
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Create:SaveError": {
      if (State.isSaving(s)) {
        return E.right(
          State.ready({
            submitted: s.payload.submitted,
            dataTypeId: s.payload.dataTypeId,
            schema: s.payload.schema,
            sku: s.payload.sku,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Create:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.left(Exits.exits.created.create(a.payload));
      }

      return E.right(s);
    }
    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}

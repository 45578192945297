import { useCallback } from "react";
import { DndContextProps } from "@dnd-kit/core";
import { pick } from "utils/object";
import { findClosestElement } from "../utils/findClosestElement";
import { FormEditor } from "../index";
import { ElementRefsContext } from "../elementRefs/Context";

type Props = {
  elementRefs: ElementRefsContext.Value;
  handler: FormEditor.Props["onDragEnd"];
};

export const useOnDragEnd = ({ elementRefs, handler }: Props) =>
  useCallback<Required<DndContextProps>["onDragEnd"]>(
    (event) => {
      if (
        !(
          event.active.id &&
          event.active.data.current &&
          event.over &&
          event.over.data.current
        )
      ) {
        return;
      }

      const draggableRef = elementRefs.refs[event.active.id];
      if (!draggableRef) {
        return;
      }

      const droppable = {
          id: event.over.id.toString(),
          meta: event.over.data.current as FormEditor.DndMeta.Droppable,
        },
        draggable = {
          id: event.active.id.toString(),
          meta: event.active.data.current as FormEditor.DndMeta.Draggable,
        },
        closest = findClosestElement(event.active.id, {
          [event.active.id]: draggableRef,
          ...pick(droppable.meta.childIds, elementRefs.refs),
        });

      handler({ droppable, draggable, closest });
    },
    [elementRefs, handler],
  );

import { Header } from "ui/packages/json-schema-form-builder/Right/Header";

export enum PreviewMode {
  form = Header.PreviewMode.form,
  schema = Header.PreviewMode.schema,
}

export type UiState = {
  previewMode: PreviewMode;
  previewFormValues: Record<string, unknown>;
  editing:
    | undefined
    | {
        // made sub-object in case will be some `confirmDialog: bool` before delete
        selectedElements: Record</*id*/ string, boolean>;
      };
  expandedElements: Record</*id*/ string, boolean>;
};

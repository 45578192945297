import { silentUnreachableError } from "utils/exceptions";
import * as FormValue from "types/src/FormValue";
import { isNoEmptyString } from "types/src/NoEmptyString";
import { Sku } from "types/src/Sku";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { createSchemaFieldsState } from "./utils";

export const reducer = (p: string) => {
  const schemaFields = createSchemaFieldsState(p);

  return (s: State.State, a: Actions.Actions): State.State => {
    if (schemaFields.isActions(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          fields: Fp.pipe(
            schemaFields.reducer(s.payload.fields, a),
            E.getOrElse(() => s.payload.fields),
          ),
        },
      } as State.State;
    }

    if (Actions.isSetDataType(p)(a)) {
      if (s.payload.dataTypeId === a.payload) return s;

      const dataType = s.payload.dataTypes.find((v) => v.id === a.payload);

      if (dataType === undefined) return s;

      return State.ready(p)({
        ...s.payload,
        dataTypeId: dataType.id,
        fields: schemaFields.states.init.create({
          schema: dataType.schema,
          uiSchema: dataType.ui,
          values: {},
        }),
      });
    }

    if (Actions.isSetSku(p)(a)) {
      return State.ready(p)({
        ...s.payload,
        sku: FormValue.initial(a.payload),
      });
    }

    if (Actions.isSetQuantity(p)(a)) {
      return State.ready(p)({
        ...s.payload,
        quantity: FormValue.initial(a.payload),
      });
    }

    if (Actions.isSubmit(p)(a)) {
      const skuValue = s.payload.sku.value;
      const sku = !skuValue
        ? FormValue.invalid("required" as const, skuValue)
        : !isNoEmptyString(skuValue)
        ? FormValue.invalid("invalid" as const, skuValue)
        : FormValue.valid(skuValue as Sku);

      const qVal = s.payload.quantity.value;
      const quantity = !qVal
        ? FormValue.invalid("required" as const, qVal)
        : qVal <= 0
        ? FormValue.invalid("invalid" as const, qVal)
        : FormValue.valid(qVal);

      const fields = Fp.pipe(
        schemaFields.reducer(
          s.payload.fields,
          schemaFields.actions.submit.create(),
        ),
        E.getOrElse(() => s.payload.fields),
      );

      if (
        FormValue.isValid(sku) &&
        FormValue.isValid(quantity) &&
        schemaFields.states.valid.is(fields)
      )
        return State.valid(p)({
          ...s.payload,
          sku,
          quantity,
          fields,
        });

      return State.ready(p)({
        ...s.payload,
        sku,
        quantity,
        fields,
      });
    }

    silentUnreachableError(a);
    return s;
  };
};

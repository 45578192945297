import { gql } from "@apollo/client";
import {
  Inbound,
  InboundCreate,
  InboundId,
  InboundUpdate,
} from "types/src/Inbounds/Inbound";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { isT } from "fp-utilities";
import { Client, DsError, notFoundError } from "../../index";

import { QueryResponse } from "../../type/QueryResponse";
import { inboundFragment } from "../../fragments/Inbound";
import {
  inboundCreateToApiInboundCreate,
  inboundFragmentToInbound,
  inboundUpdateToApiInboundUpdate,
} from "../../transformers/Inbound";
import {
  CreateInboundMutation,
  CreateInboundMutationVariables,
  RemoveInboundMutation,
  RemoveInboundMutationVariables,
  UpdateInboundMutation,
  UpdateInboundMutationVariables,
} from "../../generated/graphql";

export const createInbound = (
  client: Client,
  vars: InboundCreate,
): Promise<QueryResponse<Inbound>> => {
  const mutation = gql`
    ${inboundFragment}
    mutation CreateInbound($input: CreateReceivingInboundWithItemsInput!) {
      createReceivingInbound(input: $input) {
        receivingInbound {
          ...InboundFragment
        }
      }
    }
  `;

  return client
    .mutate<CreateInboundMutation, CreateInboundMutationVariables>({
      mutation,
      variables: {
        input: inboundCreateToApiInboundCreate(vars),
      },
    })
    .then(
      flow(
        E.map((v) => v.createReceivingInbound?.receivingInbound),
        E.filterOrElseW(isT, notFoundError),
        E.map(inboundFragmentToInbound),
      ),
    );
};

export const updateInbound = (
  client: Client,
  vars: InboundUpdate,
): Promise<QueryResponse<Inbound>> => {
  const mutation = gql`
    ${inboundFragment}
    mutation UpdateInbound($id: ID!, $input: UpdateReceivingInboundInput!) {
      updateReceivingInbound(id: $id, input: $input) {
        receivingInbound {
          ...InboundFragment
        }
      }
    }
  `;

  return client
    .mutate<UpdateInboundMutation, UpdateInboundMutationVariables>({
      mutation,
      variables: {
        id: vars.id,
        input: inboundUpdateToApiInboundUpdate(vars),
      },
    })
    .then(
      flow(
        E.map((v) => v.updateReceivingInbound?.receivingInbound),
        E.filterOrElseW(isT, notFoundError),
        E.map(inboundFragmentToInbound),
      ),
    );
};

export const removeInbound = (
  client: Client,
  id: InboundId,
): Promise<QueryResponse<InboundId>> => {
  const mutation = gql`
    mutation RemoveInbound($id: ID!) {
      deleteReceivingInbound(id: $id) {
        deletedID
      }
    }
  `;

  return client
    .mutate<RemoveInboundMutation, RemoveInboundMutationVariables>({
      mutation,
      variables: {
        id,
      },
    })
    .then(E.map((node) => node.deleteReceivingInbound.deletedID as InboundId));
};

export const removeInbounds = (
  client: Client,
  ids: InboundId[],
): Promise<E.Either<DsError, void>> => {
  const template = `
    inbound_id: deleteInbound(id: "[INBOUND_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template.replace("[INBOUND_ID]", id).replace("inbound_id", `inbound_${i}`),
  );
  const query = `
        mutation DeleteInbounds {
            ${fakeMutation.join("\n")}
        }
      `;

  return client
    .mutate({
      mutation: gql(query),
    })
    .then(E.map(() => undefined));
};

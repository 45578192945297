import * as StateModule from "./types/State";
import * as ExitsModule from "./types/Exits";
import * as ActionsModule from "./types/Actions";

import { reducer } from "./reducer";
import { epic } from "./epic";
import {
  createCustomerSearchState,
  createPickingOrderItemsState,
  createSchemaFieldsState,
} from "./utils";

export const createState = (p: string) => ({
  states: {
    loading: {
      create: StateModule.loading(p),
      is: StateModule.isLoading(p),
    },
    loadError: {
      create: StateModule.loadError(p),
      is: StateModule.isLoadError(p),
    },
    ready: {
      create: StateModule.ready(p),
      is: StateModule.isReady(p),
    },
    saving: {
      create: StateModule.saving(p),
      is: StateModule.isSaving(p),
    },
    removeConfirmation: {
      create: StateModule.removeConfirmation(p),
      is: StateModule.isRemoveConfirmation(p),
    },
    removing: {
      create: StateModule.removing(p),
      is: StateModule.isRemoving(p),
    },
  },
  actions: {
    loadFail: {
      create: ActionsModule.loadFail(p),
      is: ActionsModule.isLoadFail(p),
    },
    loadSuccess: {
      create: ActionsModule.loadSuccess(p),
      is: ActionsModule.isLoadSuccess(p),
    },
    submit: {
      create: ActionsModule.submit(p),
      is: ActionsModule.isSubmit(p),
    },
    remove: {
      create: ActionsModule.remove(p),
      is: ActionsModule.isRemove(p),
    },
    removeConfirm: {
      create: ActionsModule.removeConfirm(p),
      is: ActionsModule.isRemoveConfirm(p),
    },
    removeDecline: {
      create: ActionsModule.removeDecline(p),
      is: ActionsModule.isRemoveDecline(p),
    },
    removeFail: {
      create: ActionsModule.removeFail(p),
      is: ActionsModule.removeFail(p),
    },
    removeSuccess: {
      create: ActionsModule.removeSuccess(p),
      is: ActionsModule.isRemoveSuccess(p),
    },
  },
  exits: ExitsModule.exits(`${p}:exits`),
  isActions: ActionsModule.isActions(p),
  isState: StateModule.isState(p),
  init: StateModule.loading(p),
  reducer: reducer(p),
  epic: epic(p),
  schemaFieldsState: createSchemaFieldsState(p),
  pickingOrderItemsState: createPickingOrderItemsState(p),
  customerSearchState: createCustomerSearchState(p),
});

export type State = StateModule.State;
export type Actions = ActionsModule.Actions;

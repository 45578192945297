import { gql } from "@apollo/client";
import {
  InboundItem,
  InboundItemCreate,
  InboundItemId,
  InboundItemUpdate,
} from "types/src/Inbounds/InboundItem";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import { isT } from "fp-utilities";
import { Client, DsError, notFoundError } from "../../index";

import { QueryResponse } from "../../type/QueryResponse";
import { inboundFragment } from "../../fragments/Inbound";

import { inboundItemFragment } from "../../fragments/InboundItem";
import {
  inboundItemCreateToApiInboundItemCreate,
  inboundItemFragmentToInboundItem,
  inboundItemUpdateToApiInboundItemUpdate,
} from "../../transformers/InboundItem";
import {
  CreateInboundItemMutation,
  CreateInboundItemMutationVariables,
  RemoveInboundItemMutation,
  RemoveInboundItemMutationVariables,
  UpdateInboundItemMutation,
  UpdateInboundItemMutationVariables,
} from "../../generated/graphql";

export const createInboundItem = (
  client: Client,
  vars: InboundItemCreate,
): Promise<QueryResponse<InboundItem>> => {
  const mutation = gql`
    ${inboundItemFragment}
    mutation CreateInboundItem($input: CreateReceivingInboundItemInput!) {
      createReceivingInboundItem(input: $input) {
        receivingInboundItem {
          ...InboundItemFragment
        }
      }
    }
  `;

  return client
    .mutate<CreateInboundItemMutation, CreateInboundItemMutationVariables>({
      mutation,
      variables: {
        input: inboundItemCreateToApiInboundItemCreate(vars),
      },
    })
    .then(
      flow(
        E.map((v) => v.createReceivingInboundItem?.receivingInboundItem),
        E.filterOrElseW(isT, notFoundError),
        E.map(inboundItemFragmentToInboundItem),
      ),
    );
};

export const updateInboundItem = (
  client: Client,
  vars: InboundItemUpdate,
): Promise<QueryResponse<InboundItem>> => {
  const mutation = gql`
    ${inboundFragment}
    mutation UpdateInboundItem(
      $id: ID!
      $input: UpdateReceivingInboundItemInput!
    ) {
      updateReceivingInboundItem(id: $id, input: $input) {
        receivingInboundItem {
          ...InboundItemFragment
        }
      }
    }
  `;

  return client
    .mutate<UpdateInboundItemMutation, UpdateInboundItemMutationVariables>({
      mutation,
      variables: {
        id: vars.id,
        input: inboundItemUpdateToApiInboundItemUpdate(vars),
      },
    })
    .then(
      flow(
        E.map((v) => v.updateReceivingInboundItem?.receivingInboundItem),
        E.filterOrElseW(isT, notFoundError),
        E.map(inboundItemFragmentToInboundItem),
      ),
    );
};

export const removeInboundItem = (
  client: Client,
  id: InboundItemId,
): Promise<QueryResponse<InboundItemId>> => {
  const mutation = gql`
    mutation RemoveInboundItem($id: ID!) {
      deleteReceivingInboundItem(id: $id) {
        deletedID
      }
    }
  `;

  return client
    .mutate<RemoveInboundItemMutation, RemoveInboundItemMutationVariables>({
      mutation,
      variables: {
        id,
      },
    })
    .then(
      E.map(
        (node) => node.deleteReceivingInboundItem.deletedID as InboundItemId,
      ),
    );
};

export const removeInbounds = (
  client: Client,
  ids: InboundItemId[],
): Promise<E.Either<DsError, void>> => {
  const template = `
    inbound_item_id: deleteInboundItem(id: "[INBOUND_ITEM_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[INBOUND_ITEM_ID]", id)
      .replace("inbound_item_id", `inbound_item_${i}`),
  );
  const query = `
        mutation DeleteInboundItems {
            ${fakeMutation.join("\n")}
        }
      `;

  return client
    .mutate({
      mutation: gql(query),
    })
    .then(E.map(() => undefined));
};

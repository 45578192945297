import { Selector, SelectorValue, useSelector } from "state-manager";
import * as O from "fp-ts/Option";
import {
  SchemaFieldsJsonEditor,
  SchemaFieldsJsonEditorProps,
} from "@Containers/SchemaFieldsJsonEditor";
import * as Fp from "fp-ts/function";

export function MaybeSchemaFieldsJsonEditor(p: {
  value$: Selector<
    O.Option<SelectorValue<SchemaFieldsJsonEditorProps["value$"]>>
  >;
  onChange: SchemaFieldsJsonEditorProps["onChange"];
}) {
  const value = useSelector(
    Fp.flow(
      p.value$,
      O.map((v) =>
        Fp.flow(
          p.value$,
          (i) => i as O.Some<typeof v>,
          (v) => v.value,
        ),
      ),
    ),
    (a, b) => a._tag === b._tag,
  );

  if (O.isNone(value)) return null;

  return <SchemaFieldsJsonEditor value$={value.value} onChange={p.onChange} />;
}

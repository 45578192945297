import { DataTypeId } from "../DataType/DataType";
import { NoEmptyString } from "../NoEmptyString";
import { UUID } from "../UUID";
import { ISODate } from "../date/ISODate";
import { DataSchemaValue } from "../jsonSchema/DataSchemaValue";

// region SupplierId
declare const _repositoryId: unique symbol;

export type RepositoryId = UUID & { [_repositoryId]: "RepositoryId" };

export namespace RepositoryId {
  export const fromString = UUID.fromString<RepositoryId>;
}
// endregion

export interface Repository {
  id: RepositoryId;
  dataTypeId: DataTypeId;
  fields: DataSchemaValue;
  createdAt: ISODate;
  updatedAt: ISODate | undefined;
  parentId: RepositoryId | undefined;
  name: NoEmptyString;
  type: "static" | "dynamic";
  isVirtual: boolean;
}

export type RequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;

export type Repo = Pick<Repository, "id" | "name" | "parentId"> & {
  data?: {
    position: [number, number, number];
    size: {
      width: number;
      length: number;
      height: number | null;
    };
  };
};

export type RepoWithMeta = RequiredFields<Repo, "data">;

import React from "react";
import { Box } from "@mui/material";
import { Sxs } from "../../../../../types/styles";
import { Label } from "./Label";

export namespace InputWithLabel {
  export type Props = React.PropsWithChildren<{
    label: string;
    id?: string;
  }>;
}

export const InputWithLabel = ({
  label,
  id,
  children,
}: InputWithLabel.Props) => (
  <Box>
    <Label sx={sxs.label} htmlFor={id}>
      {label}
    </Label>
    <Box>{children}</Box>
  </Box>
);

const sxs = {
  label: (theme) => ({
    marginBottom: theme.spacing(1.5),
  }),
} satisfies Sxs;

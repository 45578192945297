import * as Rx from "rxjs";
import {
  getClient,
  Client,
  isUnauthorizedError,
  DsError,
  unknownError,
} from "ds";
import { getClient as getOpenAIClient } from "open-ai-ds";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import {
  UserAccessToken,
  OrgId,
  OpenAIApiKey,
  OpenAIOrgKey,
  UserId,
} from "types";
import * as Obj from "utils/object";
import { DataSource } from "../../types/DataSource";
import { Epic, mergeByGuard } from "../../types/RootEpic";
import { UserSettings } from "../../types/UserSettings";
import * as DataManager from "./states/DataManager";
import * as BuilderPreview from "./states/BuilderPreview";
import * as BPMNPreview from "./states/BPMNPreview";
import * as GraphqlPlayground from "./states/GraphqlPlayground";
import * as Temporal from "./states/Temporal";
import { isSigningOut, SigningOut, State } from "./types/State";
import * as ZitadelPreview from "./states/ZitadelPreview";
import * as Actions from "./types/Actions";
import { DataTypesListing } from "./states/DataTypesListing";
import { DataTypeCreate } from "./states/DataTypeCreate";
import { DataTypeEdit } from "./states/DataTypeEdit";

interface Deps {
  userManager: DataSource["userManager"];
  clientUri: string;
  userSettings: {
    get: (id: UserId) => Rx.Observable<object>;
    set: (id: UserId, settings: UserSettings) => void;
  };
}

export const epic: Epic<Actions.Actions, State, Deps> = (state$, clients) => {
  const handler = <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Fn extends (client: Client) => Rx.Observable<E.Either<DsError, any>>,
  >(
    fn: Fn,
  ): ReturnType<Fn> => {
    const _client$ = client$.pipe(
      Rx.filter(O.isSome),
      Rx.map((c) => c.value),
    );

    return _client$.pipe(
      Rx.switchMap((client) => {
        return Rx.from(fn(client)).pipe(
          Rx.catchError(() => Rx.of(E.left(unknownError()))),
          Rx.switchMap((res) => {
            if (E.isLeft(res) && isUnauthorizedError(res.left)) {
              logger$.next(Actions.reauthorize());
              return handler(fn);
            }

            return Rx.of(res);
          }),
        );
      }),
    ) as ReturnType<Fn>;
  };

  const queryHandler = <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Fn extends (client: Client) => Promise<E.Either<DsError, any>>,
  >(
    fn: Fn,
  ): ReturnType<Fn> => {
    return Rx.firstValueFrom(handler((c) => Rx.from(fn(c)))) as ReturnType<Fn>;
  };

  const logger$ = new Rx.Subject<Actions.Actions>();

  const client$: Rx.Observable<O.Option<Client>> = state$.pipe(
    Rx.map((s) =>
      E.isRight(s.payload.user)
        ? E.right({
            accessToken: s.payload.user.right.accessToken,
            orgId: s.payload.orgId,
          })
        : E.left("refresh" as const),
    ),
    Rx.map(O.fromEither),
    Rx.distinctUntilChanged(
      O.getEq<{ accessToken: UserAccessToken; orgId: OrgId }>({
        equals: (a, b) =>
          a.accessToken === b.accessToken && a.orgId === b.orgId,
      }).equals,
    ),
    Rx.map(O.map((u) => getClient(clients.clientUri, u.accessToken, u.orgId))),
    Rx.shareReplay(1),
  );

  const orgIdSetter$ = state$.pipe(
    Rx.map((s) => s.payload.orgId),
    Rx.pairwise(),
    Rx.filter(([a, b]) => a !== b),
    Rx.switchMap(([, v]) => Rx.from(clients.userManager.setActiveOrgId(v))),
    Rx.switchMap(() => Rx.NEVER),
  );

  const pyckAdminClient$ = Rx.of<Client>({
    query: (o) => Rx.firstValueFrom(handler((c) => Rx.from(c.query(o)))),
    mutate: (o) => Rx.firstValueFrom(handler((c) => Rx.from(c.mutate(o)))),
    fetchQuery: (q) =>
      Rx.firstValueFrom(handler((c) => Rx.from(c.fetchQuery(q)))),
    doMutation: (q) =>
      Rx.firstValueFrom(handler((c) => Rx.from(c.doMutation(q)))),
    watchQuery: (q) => handler((c) => c.watchQuery(q)),
  });

  const openAIClient$ = state$.pipe(
    Rx.map((s) => s.payload.openAI),
    Rx.filter((s): s is { apiKey: OpenAIApiKey; orgKey: OpenAIOrgKey } =>
      Boolean(s?.apiKey && s?.orgKey),
    ),
    Rx.switchMap((v) => {
      return Rx.of(getOpenAIClient(v.apiKey, v.orgKey));
    }),
  );

  const reauthorize$ = state$.pipe(
    Rx.map((s) => s.payload.user),
    Rx.distinctUntilKeyChanged("_tag"),
    Rx.filter(E.isLeft),
    Rx.switchMap(() => {
      return Rx.from(clients.userManager.signinSilent()).pipe(
        Rx.map(O.map(Actions.reauthorizeSuccess)),
        Rx.catchError(() => Rx.of(O.none)),
        Rx.map(O.getOrElseW(Actions.reauthorizeFail)),
      );
    }),
  );

  const signOutEpic: Epic<Actions.SignedOut, SigningOut> = (state$) =>
    state$.pipe(
      Rx.switchMap(() => {
        return Rx.from(clients.userManager.signOut()).pipe(
          Rx.map(() => Actions.signedOut()),
          Rx.catchError(() => Rx.of(Actions.signedOut())),
        );
      }),
    );

  const subStatesEpic = mergeByGuard([
    [isSigningOut, signOutEpic],
    [DataManager.isState, DataManager.epic],
    [BuilderPreview.isState, BuilderPreview.epic],
    [BPMNPreview.isState, BPMNPreview.epic],
    [ZitadelPreview.isState, ZitadelPreview.epic],
    [DataTypesListing.instance.isState, DataTypesListing.instance.epic],
    [DataTypeCreate.instance.isState, DataTypeCreate.instance.epic],
    [DataTypeEdit.instance.isState, DataTypeEdit.instance.epic],
    [GraphqlPlayground.instance.isState, GraphqlPlayground.instance.epic],
    [Temporal.instance.isState, Temporal.instance.epic],
  ]);

  const dispatcher$ = new Rx.Subject<Actions.Actions>();
  const updateUserSettings$ = state$.pipe(
    Rx.map((s) => ({
      id: E.toUnion(s.payload.user).id,
      settings: s.payload.userSettings,
    })),
    Rx.distinctUntilChanged(Obj.isDeepEqual),
    Rx.skip(1),
    Rx.tap(({ id, settings }) =>
      clients.userSettings.set(id, UserSettings.create(settings)),
    ),
    Rx.mergeMap(() => Rx.NEVER),
  );

  return Rx.merge(
    subStatesEpic(state$.pipe(Rx.map((s) => s.payload.subState)), {
      pyckAdminClient$,
      openAIClient$,
      getListingVisibleColumns: (d) => {
        return state$.pipe(
          Rx.map(
            Fp.flow(
              (v) => v.payload.userSettings.listingVisibleColumns[d],
              O.fromNullable,
              O.getOrElse(() => ({})),
            ),
          ),
        );
      },
      setListingVisibleColumns: (d, v) => {
        dispatcher$.next(
          Actions.setUserSettings({ listingVisibleColumns: { [d]: v } }),
        );
      },
    }),
    logger$,
    reauthorize$,
    orgIdSetter$,
    dispatcher$,
    updateUserSettings$,
  );
};

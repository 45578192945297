import { BehaviorValue } from "rx-addons/BehaviorValue";
import * as Rx from "rxjs";
import { PreviewMode, UiState } from "../../types/uiState";

export const defaultUiState = (): UiState => ({
  previewMode: PreviewMode.form,
  previewFormValues: {},
  editing: undefined,
  expandedElements: {},
});

export const defaultUiStateEditing = (): Exclude<
  UiState["editing"],
  undefined
> => ({
  selectedElements: {},
});

export const defaultUiStateBehavior = (): BehaviorValue<UiState> =>
  new BehaviorValue<UiState>(defaultUiState(), Rx.NEVER);

import { CustomerId } from "types/src/Customers/Customer";
import { ISODate } from "types/src/date/ISODate";
import { Option } from "fp-ts/Option";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import * as O from "fp-ts/Option";
import { Client } from "ds";
import * as Rx from "rxjs";
import { deleteCustomers, getCustomers, GetCustomersVars } from "ds/Customers";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { getDataTypes } from "ds/DataTypes";
import { Typed } from "utils/Typed";
import { strictGuard } from "utils/strictGuard";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Tuple } from "types/src/Tuple";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingState } from "../../../../../../../../generic-states/Listing";
import { Loading } from "../../../../../../../../generic-states/Loading";

const prefix = "Ready:DataManager:Customers:ListingAll" as const;

const createListingState = () => {
  const dataTypesState = Loading.createState<
    Array<{ id: DataTypeId; name: string }>,
    undefined
  >(`${prefix}:data-types`, { equals: (a, b) => a === b });
  const state = ListingState.createState<
    CustomersListingAll.Filters,
    CustomersListingAll.Item,
    "createdAt" | "updatedAt",
    {
      dataTypes: Loading.GetState<typeof dataTypesState>;
    }
  >(prefix, {
    defaultFilters: {},
  });

  type St = ListingState.GetState<typeof state>;
  type Ac =
    | ListingState.GetActions<typeof state>
    | Loading.GetActions<typeof dataTypesState>;
  type Exits = ListingState.GetExits<typeof state>;

  const epic: Epic<Ac, St, CustomersListingAll.Deps> = (state$, deps) => {
    const listing$ = state.epic(state$, {
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(getCustomers(client, getFetchVars(s))),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: { in: [DataTypeEntity.Customer] },
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;
                    if (E.isLeft(v.dataTypes)) return v.dataTypes;

                    return E.right({
                      items: v.items.right,
                      dataTypes: v.dataTypes.right,
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      id: i.id,
                      createdAt: i.createdAt,
                      updatedAt: O.fromNullable(i.updatedAt),
                      dataType: O.fromNullable(
                        r.dataTypes.items.find((v) => v.id === i.dataTypeId),
                      ),
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(deleteCustomers(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
    });

    const dataTypes$ = dataTypesState.epic(
      state$.pipe(Rx.map((v) => v.payload.dataTypes)),
      {
        get: () => {
          return deps.pyckAdminClient$.pipe(
            Rx.switchMap((client) => {
              return Rx.from(
                getDataTypes(client, {
                  where: { entity: { in: [DataTypeEntity.Customer] } },
                }),
              ).pipe(
                Rx.map(
                  E.map((r) =>
                    r.items.map((i) => ({ id: i.id, name: i.name })),
                  ),
                ),
              );
            }),
          );
        },
      },
    );

    return Rx.merge(listing$, dataTypes$);
  };

  const reducer = (s: St, a: Ac): E.Either<Exits, St> => {
    if (dataTypesState.isAction(a)) {
      return Fp.pipe(
        dataTypesState.reducer(s.payload.dataTypes, a),
        E.map(
          (dataTypes) =>
            ({ ...s, payload: { ...s.payload, dataTypes } }) as typeof s,
        ),
      );
    }

    return state.reducer(s, a);
  };

  return {
    ...state,
    isAction: strictGuard(
      (a: Ac): a is Ac => state.isAction(a) || dataTypesState.isAction(a),
    ),
    reducer,
    epic,
    init: (): St =>
      state.init({
        dataTypes: dataTypesState.init(undefined),
      }),
    dataTypes: dataTypesState,
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetCustomersVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetCustomersVars["where"] = {
      dataType: {
        isNil: Fp.pipe(
          fields.status,
          O.fromNullable,
          O.map((v) => v === "orphan"),
          O.toUndefined,
        ),
      },
      and: [
        {
          createdAt: Fp.pipe(
            fields.createdAt,
            O.fromNullable,
            O.chain(Fp.flow(DateRange.from, O.fromNullable)),
            O.map((v) => ({ gte: v })),
            O.toUndefined,
          ),
          updatedAt: Fp.pipe(
            fields.updatedAt,
            O.fromNullable,
            O.chain(Fp.flow(DateRange.from, O.fromNullable)),
            O.map((v) => ({ gte: v })),
            O.toUndefined,
          ),
        },
        {
          createdAt: Fp.pipe(
            fields.createdAt,
            O.fromNullable,
            O.chain(Fp.flow(DateRange.to, O.fromNullable)),
            O.map((v) => ({ lte: v })),
            O.toUndefined,
          ),
          updatedAt: Fp.pipe(
            fields.updatedAt,
            O.fromNullable,
            O.chain(Fp.flow(DateRange.to, O.fromNullable)),
            O.map((v) => ({ lte: v })),
            O.toUndefined,
          ),
        },
      ],
      or: [
        {
          data: {
            contains: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.chain(NoEmptyString.fromString),
              O.map(Tuple.create("")),
              O.toUndefined,
            ),
          },
        },
        {
          id: {
            eq: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.map(CustomerId.fromString),
              O.toUndefined,
            ),
          },
        },
      ],
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
};

export namespace CustomersListingAll {
  export const instance = createListingState();

  export type State = ListingState.GetState<typeof instance>;
  export type Actions = ListingState.GetActions<typeof instance>;
  export type Exits = ListingState.GetExits<typeof instance>;

  export interface Item {
    id: CustomerId;
    createdAt: ISODate;
    updatedAt: Option<ISODate>;
    dataType: Option<{ id: DataTypeId; name: string }>;
  }

  export type Filters = Partial<{
    createdAt: DateRange;
    updatedAt: DateRange;
    search: string;
    dataTypes: DataTypeId[];
    status: "active" | "orphan";
  }>;

  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: () => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (v: Record<string, boolean>) => void;
  }
}

import { Typed } from "utils/Typed";
import { User } from "types";
import * as Ready from "../../Ready";
import { UserSettings } from "../../../types/UserSettings";

export interface Payload {
  user: User;
  userSettings: UserSettings["payload"];
  goTo: Ready.Goto;
}

export const exits = Typed.builder
  .add("authenticated", (p: Payload) => p)
  .finish()("Auth");

export type Exits = Typed.GetTypes<typeof exits>;

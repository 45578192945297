import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import * as Fp from "fp-ts/function";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { schemaFieldsState } from "./utils";
import * as Exits from "./types/Exits";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          id: s.payload.id,
          dataTypeId: s.payload.dataTypeId,
          schema: Fp.pipe(
            schemaFieldsState.reducer(s.payload.schema, a),
            E.getOrElse(() => s.payload.schema),
          ),
          submitted: s.payload.submitted,
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:Suppliers:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(State.loadError(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        return E.right(
          State.ready({
            id: a.payload.item.id,
            dataTypeId: a.payload.item.dataTypeId,
            schema: schemaFieldsState.init({
              schema: a.payload.schema,
              uiSchema: a.payload.ui,
              values: a.payload.item.fields,
            }),
            submitted: false,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Edit:Submit": {
      if (State.isReady(s)) {
        const schema = Fp.pipe(
          schemaFieldsState.reducer(
            s.payload.schema,
            schemaFieldsState.actions.submit.create(),
          ),
          E.getOrElse(() => s.payload.schema),
        );
        return schemaFieldsState.states.valid.is(schema)
          ? E.right(State.saving({ ...s.payload, schema, submitted: true }))
          : E.right(State.ready({ ...s.payload, schema, submitted: true }));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Edit:SaveError": {
      if (State.isSaving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.left(Exits.exits.saved.create(a.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Edit:Remove": {
      if (State.isReady(s) || State.isSaving(s)) {
        return E.right(State.removeConfirmation(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Suppliers:Edit:RemoveDecline": {
      if (State.isRemoveConfirmation(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Suppliers:Edit:RemoveConfirm": {
      if (State.isRemoveConfirmation(s)) {
        return E.right(State.removing(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Suppliers:Edit:RemoveFail": {
      if (State.isRemoving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Suppliers:Edit:RemoveSuccess": {
      if (State.isRemoving(s)) {
        return E.left(Exits.exits.removed.create(s.payload.id));
      }

      return E.right(s);
    }
    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}

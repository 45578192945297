import { Value } from "types/src/jsonSchema/value";
import { BehaviorValueRef } from "react-rx/behaviorValue";
import { useCallback } from "react";
import {
  UiSchemaElementTypeHorizontalLayout as HorizontalLayout,
  UiSchemaElementTypeVerticalLayout as VerticalLayout,
} from "types/src/jsonSchema/uiSchema";
import * as Fp from "fp-ts/function";
import { uiEditingGetSelectedIds } from "../../utils/ui/editing";
import { useClearPositionCaches } from "../useClearPositionCaches";
import { UiState } from "../../types/uiState";
import { controlGroupByIds } from "../../utils/control/group";
import { fixValue } from "../../utils/value/fix";

export const useControlGroupSelected = ({
  valueRef,
  onValueChange: _onValueChange,
  uiRef,
  makeParent,
}: {
  valueRef: BehaviorValueRef<Value>;
  onValueChange: (value: Value) => void;

  uiRef: BehaviorValueRef<UiState>;
  onUiChange: (ui: Partial<UiState>) => void;

  makeParent: (p: {
    existingIds: Record<string, unknown>;
  }) => VerticalLayout | HorizontalLayout;
}) => {
  const onValueChange = useClearPositionCaches(_onValueChange);

  return useCallback(() => {
    onValueChange(
      Fp.pipe(
        valueRef.current,
        (value) =>
          controlGroupByIds({
            ids: uiEditingGetSelectedIds(uiRef.current),
            makeParent,
            value,
          }),
        fixValue,
      ),
    );
  }, [onValueChange, uiRef, makeParent, valueRef]);
};

import { useContext, useMemo } from "react";
import { Content as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor/elementTypes/Control/Content";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue } from "react-rx/behaviorValue";
import * as Rx from "rxjs";
import { isDeepEqual } from "utils/object";
import { ControlFormData, ControlValues } from "../types";
import { ControlTypesContext } from "../../../../../contexts/ControlTypes";
import { ControlType } from "../../../../../types/control/type";

type Props = {
  formData$: BehaviorValue<ControlFormData>;
};

export const useGeneralFormData = ({
  formData$,
}: Props): Pick<UI.Props<string>, "formData$"> => {
  const choices = useChoices({
    values$: useMemo(() => formData$.map(({ values }) => values), [formData$]),
  });

  const generalFormData$ = useMemo<BehaviorValue<UI.GeneralForm.Data>>(
    () =>
      formData$.map(({ values, errors }) => ({
        values,
        errors,
        types: choices.types,
        formats: choices.formats,
      })),
    [formData$, choices.types, choices.formats],
  );

  return { formData$: generalFormData$ };
};

const useChoices = ({ values$ }: { values$: BehaviorValue<ControlValues> }) => {
  const controlTypes = useContext(ControlTypesContext);

  const types = useMemo<UI.GeneralForm.Data["types"]>(
    () =>
      Object.entries(controlTypes).map(([id, { label }]) => ({ id, label })),
    [controlTypes],
  );

  const formats = useBehaviorValue<UI.GeneralForm.Data["formats"]>(
    useMemo(
      () =>
        values$
          .map(({ type }) =>
            Object.entries(controlTypes[type as ControlType].formats || {}).map(
              ([id, { label }]) => ({
                id,
                label,
              }),
            ),
          )
          .pipe(Rx.distinctUntilChanged(isDeepEqual)),
      [controlTypes, values$],
    ),
  );

  return { types, formats };
};

import { Box } from "@mui/material";
import React from "react";
import { Sxs } from "../../../../../types/styles";

export const Divider = () => {
  return <Box sx={sxs.root}></Box>;
};

const sxs = {
  root: (theme) => ({
    margin: theme.spacing(4, 0),
    height: "1px",
    backgroundColor: theme.palette.divider,
  }),
} satisfies Sxs;

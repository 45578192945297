import { gql } from "@apollo/client";
import { inventoryItemFragment } from "./InventoryItem";
import { repositoryFragment } from "./Repository";

export const itemMovementFragment = gql`
  ${inventoryItemFragment}
  ${repositoryFragment}

  fragment ItemMovementFragment on ItemMovement {
    id
    dataTypeID
    data
    createdAt
    updatedAt
    collectionID
    orderID
    item {
      ...InventoryItemFragment
    }
    from {
      ...RepositoryFragment
    }
    to {
      ...RepositoryFragment
    }
    quantity
    executed
    executedAt
    handler
    position
  }
`;

import React from "react";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import { useTranslation } from "i18n";
import { styleSecondaryTextColor } from "@layouts/ListingTable/utils/styles";
import { usePopoverController } from "@layouts/ListingTable/Popover/hooks/usePopoverController";
import { Box } from "@mui/material";
import { Popover } from "@layouts/ListingTable/Popover";
import { FilterButton } from "../../../FilterButton";
import {
  FilterTypeDateRange,
  FilterPropsPredefined,
} from "../../../types/filters";
import { formatAsDateYear } from "../../../utils/date";
import { DateRangePicker } from "../../../DateRangePicker";

export const DateRange = ({
  config: { options },
  value,
  onChange,
}: FilterPropsPredefined<FilterTypeDateRange>) => {
  const { language } = useTranslation();
  const { openPopover, popoverOpen, popoverProps } = usePopoverController();

  const { min, max } = options || {};
  const { start, end } = value || {};
  const hasValue = !!(start || end);

  return (
    <>
      <FilterButton Icon={CalendarTodayRoundedIcon} onClick={openPopover}>
        {hasValue && (
          <span>
            {start ? formatAsDateYear(start, language) : noDate}
            {" – "}
            {end ? formatAsDateYear(end, language) : noDate}
          </span>
        )}
      </FilterButton>
      {popoverOpen !== undefined && (
        <Popover {...popoverStaticProps} {...popoverProps}>
          <DateRangePicker {...{ value, onChange, min, max }} />
        </Popover>
      )}
    </>
  );
};

const noDate = (
  <Box
    component="span"
    sx={(theme) => ({ color: styleSecondaryTextColor(theme) })}
  >
    ···
  </Box>
);

const popoverStaticProps: Partial<Popover.Props> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
};

import { ReactElement } from "react";
import { Selector } from "state-manager";
import { IconButton } from "ui/components/IconButton";
import { TrashIcon } from "icons/Trash";
import { Inline } from "ui/components/Inline";

import { Input } from "@Containers/Form/FormValue/Input";
import { flow } from "fp-ts/function";
import { Number } from "@Containers/Form/FormValue/Number";
import { GearIcon } from "icons/Gear";
import { InboundItem } from "state-manager/generic-states/InboundItem";
import { useTranslation } from "i18n";

export interface ItemProps {
  selector: Selector<InboundItem.State>;
  onRemove: () => void;
  instance: InboundItem.Store;
  dispatch: (a: InboundItem.Actions) => void;
  onAdvanced: () => void;
}

export function Item(p: ItemProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Inline gap={"m"}>
      <Input
        value={flow(p.selector, (v) => v.payload.sku)}
        onChange={(v) => p.dispatch(p.instance.actions.setSku.create(v))}
        placeholder={t("Sku")}
      />
      <Number
        value={flow(p.selector, (v) => v.payload.quantity)}
        onChange={(v) => p.dispatch(p.instance.actions.setQuantity.create(v))}
        min={0}
        placeholder={t("Quantity")}
      />
      <Inline>
        <IconButton onClick={p.onRemove}>
          <TrashIcon />
        </IconButton>
        <IconButton onClick={p.onAdvanced}>
          <GearIcon />
        </IconButton>
      </Inline>
    </Inline>
  );
}

import { UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { HorizontalLayout as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor/elementTypes/HorizontalLayout";
import React from "react";
import { useElementUiSchemaFromPath$ } from "../../../../hooks/element/uiSchema";
import { useBehaviorOptionValue } from "../../../../hooks/useBehaviorOptionValue";
import { Inner } from "./Inner";

export namespace ListLayout {
  export type SupportedElementTypes =
    | UiSchemaElementType.verticalLayout
    | UiSchemaElementType.horizontalLayout;

  export type UiCommonProps = Pick<UI.Props, "id" | "dndMeta" | "children">;

  export type Props<
    ElementType extends SupportedElementTypes,
    UiProps extends UiCommonProps,
  > = {
    elementType: ElementType;
    uiSchemaPathEncoded: string;
    UI: React.FC<Omit<UiProps, keyof UiCommonProps> & UiCommonProps>;
    uiProps: Omit<UiProps, keyof UiCommonProps>;
  };
}

export const ListLayout = <
  ElementType extends ListLayout.SupportedElementTypes,
  UiProps extends ListLayout.UiCommonProps,
>({
  elementType,
  uiSchemaPathEncoded,
  UI,
  uiProps,
}: ListLayout.Props<ElementType, UiProps>) => {
  const uiSchema$ = useBehaviorOptionValue({
    option$: useElementUiSchemaFromPath$({
      pathEncoded: uiSchemaPathEncoded,
      elementType,
    }),
  });

  return (
    uiSchema$ && (
      <Inner
        {...{ elementType, uiSchemaPathEncoded, uiSchema$, UI, uiProps }}
      />
    )
  );
};

import React from "react";
import {
  ControlProps,
  rankWith,
  JsonFormsRendererRegistryEntry,
  isDateControl,
} from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";

import { Control } from "../components/Control";
import { InputDate } from "../components/InputDate";

const MaterialControl = React.memo((props: ControlProps) => (
  <Control {...props} input={InputDate} />
));

export const entry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(4, isDateControl),
  renderer: withJsonFormsControlProps(MaterialControl),
};

import { Selector } from "state-manager";
import * as Temporal from "state-manager/states/Ready/states/Temporal";

export interface HeaderProps {
  selector: Selector<Temporal.State>;
  dispatch: (a: Temporal.Action) => void;
}

export function Header(p: HeaderProps) {
  return null;
}

import { useTranslation } from "i18n";
import { ListingTable } from "ui/layouts/ListingTable";
import { EntityListing } from "@Containers/Listing/EntityListing";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";

export const typeMap = {
  isVirtual: EntityListing.Filters.Type.select,
} satisfies EntityListing.Filters.ListingTypeMapBase;

export const stateToConfig = (
  state: {},
  { t }: Pick<ReturnType<typeof useTranslation>, "t">,
) => ({
  type: {
    type: typeMap["isVirtual"],
    options: {
      label: t("Virtual"),
      choices: [
        { id: "real", label: t("Real") },
        { id: "virtual", label: t("Virtual") },
      ],
    },
  } satisfies ListingTable.CustomFilterConfig,
});

export const converters: EntityListing.Filters.ListingConvertersBase<
  { isVirtual?: boolean },
  typeof typeMap
> = {
  isVirtual: {
    toListing: Fp.flow(
      O.fromNullable,
      O.map((v) => (v ? "virtual" : "real")),
      O.map((id) => ({ id })),
      O.toUndefined,
    ),
    toState: Fp.flow(
      O.fromNullable,
      O.map((v) => v.id),
      O.map((v) => v === "virtual"),
      O.toUndefined,
    ),
  },
};

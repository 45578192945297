import { Typed } from "utils/Typed";
import { Client, DsError } from "ds";
import { GetGuardType } from "types/src/Utils";
import { ItemSet, ItemSetId } from "types/src/ItemSets/ItemSet";
import { DataType, DataTypeId } from "types/src/DataType/DataType";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import * as Rx from "rxjs";
import * as E from "fp-ts/Either";
import * as ItemSetsApi from "ds/ItemSets";
import * as DataTypesApi from "ds/DataTypes";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { isOneOf } from "utils/isOneOf";
import { silentUnreachableError } from "utils/exceptions";
import { Tuple } from "types/src/Tuple";
import { isT } from "fp-utilities";
import {
  InventoryItem,
  InventoryItemId,
} from "types/src/InventoryItems/InventoryItem";
import * as FormValue from "types/src/FormValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import * as InventoryItemsApi from "ds/InventoryItems";
import { sequenceT } from "fp-ts/Apply";
import { uniqBy } from "rambda";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import { ItemSetItem as ItemSetItemState } from "../../../../../../../../generic-states/ItemSetItem";
import { Epic } from "../../../../../../../../types/RootEpic";
import { NewItemId } from "./types/NewItemId";
import { isItemId, nextItemId } from "./utils";

function createStore<P extends string>(p: P) {
  interface Ready {
    id: ItemSetId;
    dataTypeId: DataTypeId | undefined;
    schema: O.Option<GetGuardType<typeof schema.isState>>;
    items: Array<[ItemSetEdit.ItemId, GetGuardType<typeof items.isState>]>;
    sku: FormValue.Value<
      "required" | "invalid",
      NoEmptyString,
      string | undefined
    >;
    _toRemove: InventoryItemId[];
  }

  interface Saving extends Ready {
    schema: O.Option<GetGuardType<typeof schema.states.valid.is>>;
    items: Array<[NewItemId, Typed.GetType<typeof items.states.valid>]>;
    sku: FormValue.Valid<NoEmptyString>;
  }

  const schema = SchemaFields.createState(`${p}:SchemaFields`);
  const items = ItemSetItemState.createState(`${p}:items`);
  const states = Typed.builder
    .add("loading", (p: { id: ItemSetId }) => p)
    .add("loadError", (p: { id: ItemSetId; error: DsError }) => p)
    .add("ready", (p: Ready) => p)
    .add("removeConfirm", (p: Ready) => p)
    .add("removing", (p: Ready) => p)
    .add("saving", (p: Saving) => p)
    .finish()(`${p}:states`);
  const actions = Typed.builder
    .add("loadError", (p: DsError) => p)
    .add("saveError", (p: DsError) => p)
    .add("saveSuccess", (p: ItemSet) => p)
    .add("setSku", (p: string) => p)
    .add("submit")
    .add("remove")
    .add("removeConfirm")
    .add("removeDecline")
    .add("removeSuccess")
    .add("removeError", (p: DsError) => p)
    .add("addItem")
    .add("removeItem", (p: ItemSetEdit.ItemId) => p)
    .add("schema", (p: GetGuardType<typeof schema.isActions>) => p)
    .add(
      "loadSuccess",
      (p: {
        item: ItemSet;
        schema: DataSchema | undefined;
        uiSchema: UiSchema | undefined;
      }) => p,
    )
    .add(
      "items",
      (
        id: ItemSetEdit.ItemId,
        action: GetGuardType<typeof items.isAction>,
      ) => ({
        id,
        action,
      }),
    )

    .finish()(`${p}:actions`);

  const exits = Typed.builder
    .add("saved", (p: ItemSet) => p)
    .add("removed", (p: ItemSetId) => p)
    .finish()(`${p}:exits`);

  type State = Typed.GetTypes<typeof states>;
  type Actions = Typed.GetTypes<typeof actions>;
  type Exits = Typed.GetTypes<typeof exits>;

  const isEditable = isOneOf([states.ready.is, states.saving.is]);

  const epic: Epic<
    Actions,
    State,
    {
      pyckAdminClient$: Rx.Observable<Client>;
    }
  > = (s$, d) => {
    const loading$ = Rx.combineLatest([
      d.pyckAdminClient$,
      s$.pipe(
        Rx.distinctUntilChanged(states.loading.is),
        Rx.filter(states.loading.is),
      ),
    ]).pipe(
      Rx.switchMap(([client, s]) => {
        return Rx.from(
          client.fetchQuery(ItemSetsApi.getItemSetQuery(s.payload.id)),
        ).pipe(
          Rx.switchMap(
            (
              item,
            ): Rx.Observable<
              E.Either<DsError, Tuple<ItemSet, DataType | undefined>>
            > => {
              if (E.isLeft(item)) return Rx.of(item);

              const preTuple = Tuple.create<ItemSet, DataType | undefined>(
                item.right,
              );

              if (!item.right.dataTypeId)
                return Rx.of(E.right(preTuple(undefined)));

              return Rx.from(
                DataTypesApi.getDataType(client, item.right.dataTypeId),
              ).pipe(
                Rx.map(
                  Fp.flow(
                    E.mapLeft(() => preTuple(undefined)),
                    E.map(preTuple),
                    E.toUnion,
                    E.right,
                  ),
                ),
              );
            },
          ),
        );
      }),
      Rx.map((item) => {
        return Fp.pipe(
          item,
          E.map(([item, dataType]) =>
            actions.loadSuccess.create({
              item,
              schema: dataType?.schema,
              uiSchema: dataType?.frontendSchema,
            }),
          ),
        );
      }),
      Rx.map(E.getOrElseW(actions.loadError.create)),
    );

    const remove$ = Rx.combineLatest([
      d.pyckAdminClient$,
      s$.pipe(
        Rx.distinctUntilChanged(states.removing.is),
        Rx.filter(states.removing.is),
      ),
    ]).pipe(
      Rx.switchMap(([client, s]) =>
        Rx.from(ItemSetsApi.removeItemSet(client, s.payload.id)),
      ),
      Rx.map(
        Fp.flow(
          E.map(actions.removeSuccess.create),
          E.getOrElseW(actions.removeError.create),
        ),
      ),
    );

    const update$ = Rx.combineLatest([
      d.pyckAdminClient$,
      s$.pipe(
        Rx.distinctUntilChanged(states.saving.is),
        Rx.filter(states.saving.is),
      ),
    ]).pipe(
      Rx.switchMap(([client, s]) => {
        return Rx.from(
          ItemSetsApi.updateItemSet(client, {
            id: s.payload.id,
            fields: Fp.pipe(
              s.payload.schema,
              O.map((v) => v.payload.values),
              O.toUndefined,
            ),
            addItems: Fp.pipe(s.payload.items, (vs) =>
              vs
                .filter(([id]) => isItemId(id))
                .map(([, p]) => p.payload.itemId.value),
            ),
            removeItems: s.payload._toRemove,
            sku: s.payload.sku.value,
            dataTypeId: s.payload.dataTypeId,
          }),
        ).pipe(
          Rx.map(
            Fp.flow(
              E.map(actions.saveSuccess.create),
              E.mapLeft(actions.saveError.create),
              E.toUnion,
            ),
          ),
        );
      }),
    );

    const schema$ = schema
      .epic(
        s$.pipe(
          Rx.filter(isEditable),
          Rx.map((v) => v.payload.schema),
          Rx.filter(O.isSome),
          Rx.map((v) => v.value),
        ),
        {},
      )
      .pipe(
        Rx.map((a) => (schema.isActions(a) ? actions.schema.create(a) : a)),
      );

    const items$ = s$.pipe(
      Rx.filter(isEditable),
      Rx.map((s) => s.payload.items),
      Rx.map((is) => is.map(([id]) => id)),
      Rx.mergeMap((ids) => Rx.from(ids)),
      Rx.distinct(),
      Rx.mergeMap((id) => {
        return items
          .epic(
            s$.pipe(
              Rx.filter(isEditable),
              Rx.map((s) => s.payload.items.find((v) => v[0] === id)),
              Rx.filter(isT),
              Rx.map(([, v]) => v),
            ),
            {
              get: (q) =>
                d.pyckAdminClient$.pipe(
                  Rx.switchMap((c) => {
                    return Rx.forkJoin({
                      item: q.id
                        ? Rx.from(
                            InventoryItemsApi.getInventoryItems(c, {
                              where: { id: { eq: q.id } },
                            }),
                          ).pipe(Rx.map(Fp.flow(E.map((r) => r.items))))
                        : Rx.of(E.right<DsError, InventoryItem[]>([])),
                      items: Rx.from(
                        InventoryItemsApi.getInventoryItems(c, {
                          where: {
                            or: [
                              {
                                data: {
                                  contains: Fp.pipe(
                                    q.search,
                                    O.fromNullable,
                                    O.map(Tuple.create("")),
                                    O.toUndefined,
                                  ),
                                },
                              },
                              { sku: { containsFold: q.search } },
                            ],
                          },
                        }),
                      ).pipe(Rx.map(Fp.flow(E.map((r) => r.items)))),
                    });
                  }),
                  Rx.map(
                    Fp.flow(
                      ({ items, item }) => sequenceT(E.Apply)(items, item),
                      E.map((v) =>
                        v.flat().map((i) => ({ id: i.id, name: i.sku })),
                      ),
                      E.map(uniqBy((v) => v.id)),
                    ),
                  ),
                ),
            },
          )
          .pipe(
            Rx.map((a) =>
              items.isAction(a) ? actions.items.create(id, a) : a,
            ),
          );
      }),
    );

    return Rx.merge(loading$, update$, schema$, items$, remove$);
  };

  return {
    states,
    actions,
    exits,
    init: (id: ItemSetId) => states.loading.create({ id }),
    isState: Typed.getGuard(states),
    isAction: Typed.getGuard(actions),
    isExit: Typed.getGuard(exits),
    reducer,
    epic,
    subStates: {
      schema,
      items,
    },
  };

  function reducer(s: State, a: Actions): E.Either<Exits, State> {
    if (actions.loadError.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.loading.is),
        O.map((s) =>
          states.loadError.create({ ...s.payload, error: a.payload }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.loadSuccess.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.loading.is),
        O.map((s) =>
          states.ready.create({
            ...s.payload,
            dataTypeId: a.payload.item.dataTypeId,
            sku: FormValue.initial(a.payload.item.sku),
            schema: Fp.pipe(
              a.payload.schema,
              O.fromNullable,
              O.map((s) =>
                schema.init({
                  schema: s,
                  uiSchema: a.payload.uiSchema,
                  values: a.payload.item.fields,
                }),
              ),
            ),
            items: a.payload.item.items.map((i) =>
              Tuple.create(i.id, items.init(i.id)),
            ),
            _toRemove: [],
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.setSku.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.filter(
          (v) =>
            (v.payload.sku.value || undefined) !== (a.payload || undefined),
        ),
        O.map((s) =>
          states.ready.create({
            ...s.payload,
            sku: Fp.pipe(
              a.payload || undefined,
              E.fromNullable(FormValue.invalid("required" as const, a.payload)),
              E.filterOrElseW(
                NoEmptyString.isNoEmptyString,
                FormValue.invalid("invalid" as const),
              ),
              E.map(FormValue.valid),
              E.toUnion,
            ),
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.addItem.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.map((s) =>
          states.ready.create({
            ...s.payload,
            items: [
              ...s.payload.items,
              [
                nextItemId(
                  s.payload.items.map((v) => Tuple.fst(v)).filter(isItemId),
                ),
                items.init(),
              ],
            ],
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeItem.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.map((s) =>
          states.ready.create({
            ...s.payload,
            items: s.payload.items.filter(([k]) => k !== a.payload),
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.schema.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.map((s) =>
          states.ready.create({
            ...s.payload,
            schema: Fp.pipe(
              s.payload.schema as O.Option<GetGuardType<typeof schema.isState>>,
              O.map((v) =>
                Fp.pipe(
                  schema.reducer(v, a.payload),
                  E.getOrElseW((e) => {
                    silentUnreachableError(e);
                    return v;
                  }),
                ),
              ),
            ),
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.items.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.map((s) =>
          Fp.pipe(
            s.payload.items,
            O.of,
            O.map((vs) =>
              vs.map(
                (item) =>
                  Fp.pipe(
                    item,
                    O.fromPredicate((v) => v[0] === a.payload.id),
                    O.map((v) => v[1]),
                    O.map((v) =>
                      Fp.pipe(
                        items.reducer(v, a.payload.action),
                        E.getOrElse((e) => {
                          silentUnreachableError(e);
                          return v;
                        }),
                      ),
                    ),
                    O.map((v) => Tuple.create(item[0], v)),
                    O.getOrElse(() => item),
                  ),
                vs,
              ),
            ),
            O.map((v) => states.ready.create({ ...s.payload, items: v })),
            O.getOrElse(() => s),
          ),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.submit.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.ready.is),
        O.map((s) => {
          const _schema = Fp.pipe(
            s.payload.schema,
            O.map((s) =>
              Fp.pipe(
                schema.reducer(s, schema.actions.submit.create()),
                E.getOrElse((e) => {
                  silentUnreachableError(e);
                  return s;
                }),
              ),
            ),
          );
          const _items = Fp.pipe(s.payload.items, (is) =>
            is.map(([id, s]) =>
              Fp.pipe(
                items.reducer(s, items.actions.submit.create()),
                E.getOrElse((e) => {
                  silentUnreachableError(e);
                  return s;
                }),
                Tuple.create(id),
              ),
            ),
          );
          const sku = FormValue.isInitial(s.payload.sku)
            ? Fp.pipe(
                s.payload.sku.value || undefined,
                E.fromNullable(
                  FormValue.invalid("required" as const, undefined),
                ),
                E.chainW(
                  E.fromPredicate(
                    NoEmptyString.isNoEmptyString,
                    FormValue.invalid("invalid" as const),
                  ),
                ),
                E.map(FormValue.valid),
                E.toUnion,
                (v) => v,
              )
            : s.payload.sku;

          if (
            FormValue.isValid(sku) &&
            toOptionGuard(schema.states.valid.is)(_schema) &&
            _items.every(
              (v): v is [NewItemId, Typed.GetType<typeof items.states.valid>] =>
                items.states.valid.is(v[1]),
            )
          ) {
            return states.saving.create({
              ...s.payload,
              sku,
              schema: _schema,
              items: _items,
            });
          }

          return states.ready.create({
            ...s.payload,
            schema: _schema,
            items: _items,
          });
        }),
        O.getOrElseW(() => s),
        E.right,
      );
    }

    if (actions.remove.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.ready.is),
        O.map((s) => states.removeConfirm.create(s.payload)),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeConfirm.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.removeConfirm.is),
        O.map((s) => states.removing.create(s.payload)),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeDecline.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.removeConfirm.is),
        O.map((s) => states.ready.create(s.payload)),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeError.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.removing.is),
        O.map((s) => states.ready.create(s.payload)),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeSuccess.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.removing.is),
        O.map((s) => exits.removed.create(s.payload.id)),
        O.map(E.left),
        O.getOrElse(() => E.right(s)),
      );
    }

    if (actions.saveError.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.saving.is),
        O.map((s) => states.ready.create(s.payload)),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.saveSuccess.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.saving.is),
        O.map(() => exits.saved.create(a.payload)),
        O.map(E.left),
        O.getOrElseW(() => E.right(s)),
      );
    }

    silentUnreachableError(a);
    return E.right(s);
  }
}

export namespace ItemSetEdit {
  export const instance = createStore("Ready:DataManager:ItemSets:Edit");

  export type State = GetGuardType<typeof instance.isState>;
  export type Actions = GetGuardType<typeof instance.isAction>;
  export type Exits = GetGuardType<typeof instance.isExit>;

  export type ItemId = NewItemId | InventoryItemId;
}

const toOptionGuard =
  <A, B extends A>(p: (a: A) => a is B) =>
  (v: O.Option<A>): v is O.Option<B> =>
    Fp.pipe(
      v,
      O.map(p),
      O.getOrElse(() => true),
    );

import { silentUnreachableError } from "utils/exceptions";
import { Repository, RepositoryId } from "types/src/Repositories/Repository";
import { DataTypeId } from "types/src/DataType/DataType";
import { RepositoryMovement } from "types/src/RepositoryMovements/RepositoryMovement";
import { strictGuard } from "utils/strictGuard";
import { DsError } from "ds";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { SchemaFieldsActions, schemaFieldsState } from "../utils";

// region Actions
export type Actions =
  | LoadSuccess
  | LoadFail
  | Submit
  | SaveError
  | SaveSuccess
  | RepositoriesSearchResult
  | RepositoriesFromSearchResult
  | RepositoriesToSearchResult
  | SchemaFieldsActions
  | SetRepository
  | SetFrom
  | SetTo
  | SearchRepository
  | SearchFrom
  | SearchTo
  | SetHandler
  | Remove
  | RemoveConfirm
  | RemoveDecline
  | RemoveFail
  | RemoveSuccess;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (schemaFieldsState.isActions(a)) return true;

  switch (a.type) {
    case "Ready:DataManager:RepositoryMovements:Edit:LoadFail":
    case "Ready:DataManager:RepositoryMovements:Edit:LoadSuccess":
    case "Ready:DataManager:RepositoryMovements:Edit:SaveError":
    case "Ready:DataManager:RepositoryMovements:Edit:SaveSuccess":
    case "Ready:DataManager:RepositoryMovements:Edit:Submit":
    case "Ready:DataManager:RepositoryMovements:Edit:RepositoriesSearchResult":
    case "Ready:DataManager:RepositoryMovements:Edit:RepositoriesFromSearchResult":
    case "Ready:DataManager:RepositoryMovements:Edit:RepositoriesToSearchResult":
    case "Ready:DataManager:RepositoryMovements:Edit:SetFrom":
    case "Ready:DataManager:RepositoryMovements:Edit:SetRepository":
    case "Ready:DataManager:RepositoryMovements:Edit:SetTo":
    case "Ready:DataManager:RepositoryMovements:Edit:SearchFrom":
    case "Ready:DataManager:RepositoryMovements:Edit:SearchTo":
    case "Ready:DataManager:RepositoryMovements:Edit:SearchRepository":
    case "Ready:DataManager:RepositoryMovements:Edit:SetHandler":
    case "Ready:DataManager:RepositoryMovements:Edit:Remove":
    case "Ready:DataManager:RepositoryMovements:Edit:RemoveConfirm":
    case "Ready:DataManager:RepositoryMovements:Edit:RemoveDecline":
    case "Ready:DataManager:RepositoryMovements:Edit:RemoveFail":
    case "Ready:DataManager:RepositoryMovements:Edit:RemoveSuccess":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:RepositoryMovements:Edit:LoadFail";
}

export const loadFail = (): LoadFail => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:LoadFail",
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:RepositoryMovements:Edit:LoadSuccess";
  payload: {
    schema: DataSchema;
    ui: UiSchema;
    movement: RepositoryMovement;
    repositories: Repository[];
    dataTypeId: DataTypeId;
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:LoadSuccess",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:RepositoryMovements:Edit:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:Submit",
});
// endregion

// region SaveError
export interface SaveError {
  type: "Ready:DataManager:RepositoryMovements:Edit:SaveError";
}

export const saveError = (): SaveError => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:SaveError",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:RepositoryMovements:Edit:SaveSuccess";
  payload: RepositoryMovement;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:SaveSuccess",
  payload,
});
// endregion

// region RepositoriesSearchResult
export interface RepositoriesSearchResult {
  type: "Ready:DataManager:RepositoryMovements:Edit:RepositoriesSearchResult";
  payload: Repository[];
}

export const repositoriesSearchResult = (
  payload: RepositoriesSearchResult["payload"],
): RepositoriesSearchResult => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:RepositoriesSearchResult",
  payload,
});
// endregion

// region RepositoriesFromSearchResult
export interface RepositoriesFromSearchResult {
  type: "Ready:DataManager:RepositoryMovements:Edit:RepositoriesFromSearchResult";
  payload: Repository[];
}

export const repositoriesFromSearchResult = (
  payload: RepositoriesFromSearchResult["payload"],
): RepositoriesFromSearchResult => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:RepositoriesFromSearchResult",
  payload,
});
// endregion

// region RepositoriesToSearchResult
export interface RepositoriesToSearchResult {
  type: "Ready:DataManager:RepositoryMovements:Edit:RepositoriesToSearchResult";
  payload: Repository[];
}

export const repositoriesToSearchResult = (
  payload: RepositoriesToSearchResult["payload"],
): RepositoriesToSearchResult => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:RepositoriesToSearchResult",
  payload,
});
// endregion

// region SetRepository
export interface SetRepository {
  type: "Ready:DataManager:RepositoryMovements:Edit:SetRepository";
  payload: RepositoryId;
}

export const setRepository = (
  payload: SetRepository["payload"],
): SetRepository => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:SetRepository",
  payload,
});
// endregion

// region SetFrom
export interface SetFrom {
  type: "Ready:DataManager:RepositoryMovements:Edit:SetFrom";
  payload: RepositoryId;
}

export const setFrom = (payload: SetFrom["payload"]): SetFrom => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:SetFrom",
  payload,
});
// endregion

// region SetTo
export interface SetTo {
  type: "Ready:DataManager:RepositoryMovements:Edit:SetTo";
  payload: RepositoryId;
}

export const setTo = (payload: SetTo["payload"]): SetTo => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:SetTo",
  payload,
});
// endregion

// region SearchRepository
export interface SearchRepository {
  type: "Ready:DataManager:RepositoryMovements:Edit:SearchRepository";
  payload: string;
}

export const searchRepository = (
  payload: SearchRepository["payload"],
): SearchRepository => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:SearchRepository",
  payload,
});
// endregion

// region SearchFrom
export interface SearchFrom {
  type: "Ready:DataManager:RepositoryMovements:Edit:SearchFrom";
  payload: string;
}

export const searchFrom = (payload: SearchFrom["payload"]): SearchFrom => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:SearchFrom",
  payload,
});
// endregion

// region SearchTo
export interface SearchTo {
  type: "Ready:DataManager:RepositoryMovements:Edit:SearchTo";
  payload: string;
}

export const searchTo = (payload: SearchTo["payload"]): SearchTo => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:SearchTo",
  payload,
});
// endregion

// region SetHandler
export interface SetHandler {
  type: "Ready:DataManager:RepositoryMovements:Edit:SetHandler";
  payload: string;
}

export const setHandler = (payload: SetHandler["payload"]): SetHandler => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:SetHandler",
  payload,
});
// endregion

// region Remove
export interface Remove {
  type: "Ready:DataManager:RepositoryMovements:Edit:Remove";
}

export const remove = (): Remove => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:Remove",
});

export const isRemove = (s: Actions): s is Remove =>
  s.type === "Ready:DataManager:RepositoryMovements:Edit:Remove";
// endregion

// region RemoveConfirm
export interface RemoveConfirm {
  type: "Ready:DataManager:RepositoryMovements:Edit:RemoveConfirm";
}

export const removeConfirm = (): RemoveConfirm => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:RemoveConfirm",
});

export const isRemoveConfirm = (s: Actions): s is RemoveConfirm =>
  s.type === "Ready:DataManager:RepositoryMovements:Edit:RemoveConfirm";
// endregion

// region RemoveDecline
export interface RemoveDecline {
  type: "Ready:DataManager:RepositoryMovements:Edit:RemoveDecline";
}

export const removeDecline = (): RemoveDecline => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:RemoveDecline",
});

export const isRemoveDecline = (s: Actions): s is RemoveDecline =>
  s.type === "Ready:DataManager:RepositoryMovements:Edit:RemoveDecline";
// endregion

// region RemoveFail
export interface RemoveFail {
  type: "Ready:DataManager:RepositoryMovements:Edit:RemoveFail";
  payload: DsError;
}

export const removeFail = (payload: RemoveFail["payload"]): RemoveFail => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:RemoveFail",
  payload,
});

export const isRemoveFail = (s: Actions): s is RemoveFail =>
  s.type === "Ready:DataManager:RepositoryMovements:Edit:RemoveFail";
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: "Ready:DataManager:RepositoryMovements:Edit:RemoveSuccess";
}

export const removeSuccess = (): RemoveSuccess => ({
  type: "Ready:DataManager:RepositoryMovements:Edit:RemoveSuccess",
});

export const isRemoveSuccess = (s: Actions): s is RemoveSuccess =>
  s.type === "Ready:DataManager:RepositoryMovements:Edit:RemoveSuccess";
// endregion

import { createContext } from "react";

export namespace ClipboardContext {
  export type Value = {
    copyToClipboard: (text: string) => void;
  };
}

export const defaultValue: ClipboardContext.Value = {
  copyToClipboard: (text: string) => navigator.clipboard.writeText(text),
};

export const ClipboardContext =
  createContext<ClipboardContext.Value>(defaultValue);

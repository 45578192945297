import { Box, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";
import { useTranslation } from "i18n";
import { Close, Search } from "icons";

export function NotFound() {
  const { t } = useTranslation();

  return (
    <StateBase
      icon={<Search />}
      title={t("Nothing found")}
      subtitle={t("Check spelling or try to search something else.")}
    />
  );
}

export function Error() {
  const { t } = useTranslation();

  return (
    <StateBase
      icon={<Close />}
      title={t("Error")}
      subtitle={t("Something went wrong, try again later.")}
    />
  );
}
export function Loading() {
  const { t } = useTranslation();

  return (
    <StateBase
      icon={<CircularProgress size={24} />}
      title={t("Loading")}
      subtitle={t("Results are loading, please wait.")}
    />
  );
}

namespace StateBase {
  export interface Props {
    icon: ReactNode;
    title: ReactNode;
    subtitle: ReactNode;
  }
}

function StateBase(p: StateBase.Props) {
  return (
    <Box
      sx={{
        px: 4,
        py: 11,
        display: "flex",
        gap: 2,
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "195px",
      }}
    >
      <Box sx={(p) => ({ color: p.palette.action.active })}>{p.icon}</Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Typography variant={"subtitle2"}>{p.title}</Typography>
        <Typography
          variant={"subtitle2"}
          sx={(p) => ({ color: p.palette.text.secondary, textAlign: "center" })}
        >
          {p.subtitle}
        </Typography>
      </Box>
    </Box>
  );
}

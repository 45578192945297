import { DataTypeId } from "../DataType/DataType";
import { UUID } from "../UUID";
import { DataSchemaValue } from "../jsonSchema/DataSchemaValue";
import { ISODate } from "../date/ISODate";
import { Sku } from "../Sku";
import {
  InventoryItem,
  InventoryItemId,
} from "../InventoryItems/InventoryItem";
import { NoEmptyString } from "../NoEmptyString";

// region ItemSetId
declare const _itemSetId: unique symbol;

export type ItemSetId = UUID & { [_itemSetId]: "ItemSetId" };

export namespace ItemSetId {
  export const fromString = UUID.fromString<ItemSetId>;
}
// endregion

export interface ItemSet {
  id: ItemSetId;
  dataTypeId: DataTypeId | undefined;
  fields: DataSchemaValue | undefined;
  createdAt: ISODate;
  updatedAt: ISODate | undefined;
  sku: Sku;
  items: InventoryItem[];
}

export interface ItemSetCreate {
  dataTypeId: DataTypeId | undefined;
  fields: DataSchemaValue | undefined;
  sku: NoEmptyString;
  items: InventoryItemId[];
}

export interface ItemSetUpdate {
  id: ItemSetId;
  dataTypeId?: DataTypeId | null;
  fields?: DataSchemaValue | null;
  sku?: NoEmptyString;
  addItems?: InventoryItemId[];
  removeItems?: InventoryItemId[] | "all";
}

import { Selector, useSelector } from "state-manager";
import * as Edit from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Edit";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { SkuInput } from "@Containers/Form/predefined/SkuInput";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { SchemaFieldsJsonEditor } from "@Containers/SchemaFieldsJsonEditor";
import { useTranslation } from "i18n";
import { DeleteModal } from "ui/layouts/Dialogs/DeleteModal";

export interface ContentProps {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const r = useSelector(
    flow(selector, (s) => {
      switch (s.type) {
        case "Ready:DataManager:InventoryItems:Edit:Loading":
        case "Ready:DataManager:InventoryItems:Edit:LoadError":
          return {
            type: "loading",
          } as const;
        case "Ready:DataManager:InventoryItems:Edit:Ready":
        case "Ready:DataManager:InventoryItems:Edit:Saving":
        case "Ready:DataManager:InventoryItems:Edit:RemoveConfirmation":
        case "Ready:DataManager:InventoryItems:Edit:Removing": {
          return {
            type: "ready",
            schemaSelector: flow(
              selector,
              (s) => s as Edit.Ready,
              (s) => ({
                schema: s.payload.schema.payload.schema,
                uiSchema: s.payload.schema.payload.uiSchema,
                values: s.payload.schema.payload.values,
                submitted: s.payload.submitted,
              }),
            ),
            skuSelector: flow(
              selector,
              (s) => s as Edit.Ready,
              (s) => s.payload.sku,
            ),
          } as const;
        }

        default: {
          silentUnreachableError(s);
          return {
            type: "loading",
          } as const;
        }
      }
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <>
          <RemoveConfirmHandler selector={selector} dispatch={dispatch} />
          <FormWrapper>
            <SkuInput
              value$={r.skuSelector}
              onChange={flow(Edit.setSku, dispatch)}
            />
            <SchemaFieldsJsonEditor
              value$={r.schemaSelector}
              onChange={flow(
                (v) => v ?? {},
                Edit.schemaFieldsState.actions.onChange.create,
                dispatch,
              )}
            />
          </FormWrapper>
        </>
      );
  }
}
function RemoveConfirmHandler(p: {
  selector: Selector<Edit.State>;
  dispatch: (a: Edit.Actions) => void;
}) {
  const { t } = useTranslation();
  const show = useSelector(flow(p.selector, Edit.isRemoveConfirmation));

  return show ? (
    <DeleteModal
      title={t("Delete item")}
      onConfirm={() => p.dispatch(Edit.removeConfirm())}
      onCancel={() => p.dispatch(Edit.removeDecline())}
    >
      {t("Do you really want to delete this item?")}
      <br />
      {t("This action cannot be undone.")}
    </DeleteModal>
  ) : null;
}

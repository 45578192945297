import {
  ItemSet,
  ItemSetCreate,
  ItemSetId,
  ItemSetUpdate,
} from "types/src/ItemSets/ItemSet";
import { DataSchemaValue } from "types/src/jsonSchema/DataSchemaValue";
import { DataTypeId } from "types/src/DataType/DataType";
import { Sku } from "types/src/Sku";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import {
  CreateInventoryItemSetInput,
  ItemSetFragmentFragment,
  UpdateInventoryItemSetInput,
} from "../generated/graphql";
import { inventoryItemFragmentToInventoryItem } from "./InventoryItems";

export function itemSetFragmentToItemSet(v: ItemSetFragmentFragment): ItemSet {
  return {
    id: v.id as ItemSetId,
    items: v.items?.map(inventoryItemFragmentToInventoryItem) ?? [],
    fields: (v.data as DataSchemaValue) ?? undefined,
    dataTypeId: (v.dataTypeID as DataTypeId) ?? undefined,
    sku: v.sku as Sku,
    updatedAt: v.updatedAt ?? undefined,
    createdAt: v.createdAt,
  };
}

export function itemSetCreateToCreateInventoryItemSetInput(
  v: ItemSetCreate,
): CreateInventoryItemSetInput {
  return {
    data: v.fields,
    dataTypeID: v.dataTypeId,
    itemIDs: v.items,
    sku: v.sku,
  };
}

export function itemSetUpdateToUpdateInventoryItemSetInput(
  v: ItemSetUpdate,
): UpdateInventoryItemSetInput {
  return {
    data: v.fields ?? undefined,
    dataTypeID: v.dataTypeId ?? undefined,
    sku: v.sku,
    addItemIDs: v.addItems,
    clearData: v.fields === null,
    clearDataTypeID: v.dataTypeId === null,
    clearItems: v.removeItems === "all",
    removeItemIDs: Fp.pipe(
      v.removeItems,
      O.fromPredicate(Array.isArray),
      O.toUndefined,
    ),
  };
}

import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { isDeepEqual } from "utils/object";
import { flow } from "fp-ts/function";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema, UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { JsonForm } from "ui/layouts/JsonForm/JsonForm";
import { JsonFormSchema } from "types/src/jsonSchema/value";
import { DataSchemaValue } from "types/src/jsonSchema/DataSchemaValue";

export interface SchemaFieldsJsonEditorProps {
  value$: Selector<{
    uiSchema: UiSchema | undefined;
    schema: DataSchema;
    values: DataSchemaValue | undefined;
    submitted: boolean;
  }>;
  onChange: (v: DataSchemaValue | undefined) => void;
}

export function SchemaFieldsJsonEditor(
  p: SchemaFieldsJsonEditorProps,
): ReactElement {
  const schema = useSelector(
    flow(
      p.value$,
      (v) => ({
        schema: v.schema,
        ui: v.uiSchema,
        value: v.values,
      }),
      (v) =>
        JsonFormSchema.create(
          v.schema,
          v.ui ?? { type: UiSchemaElementType.verticalLayout, elements: [] },
        ),
    ),
    JsonFormSchema.equals,
  );
  const value = useSelector(
    flow(p.value$, (v) => v.values ?? {}),
    isDeepEqual,
  );
  const submitted = useSelector(
    flow(p.value$, (v) => v.submitted),
    isDeepEqual,
  );

  return (
    <JsonForm
      schema={schema}
      value={value}
      onChange={flow((v) => v.data as DataSchemaValue, p.onChange)}
      submitted={submitted}
    />
  );
}

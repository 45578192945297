import { gql } from "@apollo/client";
import {
  RepositoryMovement,
  RepositoryMovementId,
} from "types/src/RepositoryMovements/RepositoryMovement";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Cursor } from "types";
import { isT } from "fp-utilities";
import { DataTypeId } from "types/src/DataType/DataType";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { ISODate } from "types/src/date/ISODate";
import { RepositoryId } from "types/src/Repositories/Repository";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { CollectionId } from "types/src/Collections/Collection";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import { Client, notFoundError, QueryResponse } from "../../index";
import { repositoryMovementFragment } from "../../fragments/RepositoryMovement";
import {
  GetRepositoryMovementsQuery,
  GetRepositoryMovementsQueryVariables,
  RepositoryMovementOrderField,
} from "../../generated/graphql";
import { repositoryMovementFragmentToRepositoryMovement } from "../../transformers/RepositoryMovements";
import {
  getPaginatedQueryResult,
  PaginatedQueryResponse,
} from "../../type/QueryResponse";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";
import { Where } from "../../type/Where";
import * as Repositories from "../../type/Repositories";

export type WhereSchema = Where<{
  data: Where.Map;
  createdAt: Where.Ord<ISODate>;
  dataType: Where.EqNil<DataTypeId>;
  executed: Where.Value<boolean>;
  executedAt: Where.Ord<ISODate>;
  from: Where.EqNil<RepositoryId>;
  handler: Where.Text;
  hasFrom: Where.Value<boolean>;
  hasFromWith: Where.WithWhereList<Repositories.WhereSchema>;
  hasRepository: Where.Value<boolean>;
  hasRepositoryWith: Where.WithWhereList<Repositories.WhereSchema>;
  hasTo: Where.Value<boolean>;
  hasToWith: Where.WithWhereList<Repositories.WhereSchema>;
  to: Where.EqNil<RepositoryId>;
  id: Where.EqIn<RepositoryMovementId>;
  order: Where.EqNil<PickingOrderId>;
  position: Where.Ord<number>;
  repository: Where.EqIn<RepositoryId>;
  updatedAt: Where.Ord<ISODate>;
  collection: Where.EqNil<CollectionId>;
}>;

export const whereSchema = Where.create<WhereSchema>({
  data: Where.map("Data"),
  createdAt: Where.ord(),
  dataType: Where.eqNil("dataTypeID"),
  executed: Where.value(),
  executedAt: Where.ord(),
  from: Where.eqNil("fromID"),
  handler: Where.text(),
  hasFrom: Where.value(),
  hasFromWith: Where.withWhereList(
    (): Repositories.WhereSchema => Repositories.whereSchema,
  ),
  hasRepository: Where.value(),
  hasRepositoryWith: Where.withWhereList(() => Repositories.whereSchema),
  hasTo: Where.value(),
  hasToWith: Where.withWhereList(
    (): Repositories.WhereSchema => Repositories.whereSchema,
  ),
  to: Where.eqNil("toID"),
  id: Where.eqIn(),
  order: Where.eqNil("orderID"),
  position: Where.ord(),
  repository: Where.eqIn("repositoryID"),
  updatedAt: Where.ord(),
  collection: Where.eqNil("collectionID"),
});

export interface GetRepositoryMovementsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: Where.GetType<WhereSchema>;
  orderBy?: {
    by:
      | "createdAt"
      | "updatedAt"
      | "collectionId"
      | "executed"
      | "executedAt"
      | "handler";
    direction: "asc" | "desc";
  };
}
export function getRepositoryMovements(
  client: Client,
  vars: GetRepositoryMovementsVars,
): Promise<PaginatedQueryResponse<RepositoryMovement>> {
  const query = gql`
    ${pageInfoFragment}
    ${repositoryMovementFragment}

    query GetRepositoryMovements(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: RepositoryMovementWhereInput
      $orderBy: RepositoryMovementOrder
    ) {
      repositoryMovements(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...RepositoryMovementFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetRepositoryMovementsQuery, GetRepositoryMovementsQueryVariables>({
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: Fp.pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toUndefined,
        ),
        orderBy: Fp.pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: RepositoryMovementOrderField.CreatedAt,
              updatedAt: RepositoryMovementOrderField.UpdatedAt,
              collectionId: RepositoryMovementOrderField.CollectionId,
              executed: RepositoryMovementOrderField.Executed,
              executedAt: RepositoryMovementOrderField.ExecutedAt,
              handler: RepositoryMovementOrderField.Handler,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    })
    .then(
      Fp.flow(
        E.map((r) => r.repositoryMovements),
        E.map(
          getPaginatedQueryResult(
            repositoryMovementFragmentToRepositoryMovement,
          ),
        ),
      ),
    );
}

export type GetItemMovementQueryResult = RepositoryMovement;
export function getRepositoryMovement(
  client: Client,
  id: RepositoryMovementId,
): Promise<QueryResponse<GetItemMovementQueryResult>> {
  return getRepositoryMovements(client, { where: { id: { eq: id } } }).then(
    Fp.flow(
      E.map((r) => r.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}

import { useContext, useMemo } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValueRef } from "react-rx/behaviorValue";
import { ControlSchemas } from "../../../../../types/control/schema";
import { ValueContext } from "../../../../../contexts/Value";
import { getAllElementNames } from "../../../../../utils/dataSchema/getAllElementNames";
import { scopeToName } from "../../../../../utils/uiSchema/scope";

export const useRestrictedNames = ({
  controlSchemas$,
}: {
  controlSchemas$: BehaviorValue<Pick<ControlSchemas, "uiSchema">>;
}): BehaviorValue<Record<string, true>> => {
  const { value$ } = useContext(ValueContext);
  const scopeRef = useBehaviorValueRef(
    useMemo(
      () => controlSchemas$.map(({ uiSchema: { scope } }) => scope),
      [controlSchemas$],
    ),
  );

  return useMemo(
    () =>
      value$.map(({ dataSchema }) => {
        const restrictedNames = getAllElementNames(dataSchema);

        delete restrictedNames[scopeToName(scopeRef.current)]; // current name is allowed

        return restrictedNames;
      }),
    [value$, scopeRef],
  );
};

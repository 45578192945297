import { DataTypeId } from "../DataType/DataType";
import { ISODate } from "../date/ISODate";
import { UUID } from "../UUID";
import { DataSchemaValue } from "../jsonSchema/DataSchemaValue";

// region CustomerId
declare const _customerId: unique symbol;

export type CustomerId = UUID & { [_customerId]: "CustomerId" };

export namespace CustomerId {
  export const fromString = UUID.fromString<CustomerId>;
}
// endregion

export interface Customer {
  id: CustomerId;
  dataTypeId: DataTypeId;
  fields: DataSchemaValue;
  createdAt: ISODate;
  updatedAt: ISODate | undefined;
  deletedAt: ISODate | undefined;
}

import React, { useEffect, useRef, useState, useTransition } from "react";
import { from, Subscription, tap } from "rxjs";
import i18nInit, { I18nInitProps } from "../init";

export type LoadingProps = { error?: boolean };

export type InitFromFilesSuspenseProps = React.PropsWithChildren<
  {
    Loading: React.FC<LoadingProps>;
  } & Pick<I18nInitProps, "lang">
>;

type LoadingState = {
  inProgress: boolean;
  props: LoadingProps;
};

const InitSuspense: React.FC<InitFromFilesSuspenseProps> = ({
  Loading,
  children,
  lang,
}) => {
  const [, startTransition] = useTransition();
  const [loading, setLoading] = useState<LoadingState>({
    inProgress: true,
    props: {},
  });

  const initRef = useRef<Subscription>();
  useEffect(() => {
    initRef.current = from(i18nInit({ lang }))
      .pipe(
        tap({
          next: () =>
            startTransition(() =>
              setLoading((state) => ({ ...state, inProgress: false })),
            ),
          error: () =>
            startTransition(() =>
              setLoading((state) => ({ ...state, props: { error: true } })),
            ),
        }),
      )
      .subscribe();

    setLoading((state) => ({ ...state, inProgress: true }));

    return () => initRef.current?.unsubscribe();
  }, [lang]);

  return loading.inProgress
    ? React.createElement(Loading, loading.props)
    : children;
};

export default InitSuspense;

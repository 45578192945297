import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Value } from "types/src/jsonSchema/value";
import { UiState } from "../types/uiState";
import { Header } from "./Header";
import { Content } from "./Content";

export namespace Right {
  export type Props = {
    value$: BehaviorValue<Value>;
    ui$: BehaviorValue<UiState>;
    onUiChange: (ui: Partial<UiState>) => void;
  };
}

export const Right = ({ value$, ui$, onUiChange }: Right.Props) => (
  <>
    <Header {...{ value$, ui$, onUiChange }} />
    <Content {...{ value$, ui$, onUiChange }} />
  </>
);

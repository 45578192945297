import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "i18n";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/AddRounded";
import { Sxs } from "../../../../types/styles";

export namespace Header {
  export type Props = {
    isEditMode: boolean;
    onEditModeChange: (isEditMode: boolean) => void;
    onAddField: () => void;
  };
}

export const Header = ({
  isEditMode,
  onEditModeChange,
  onAddField,
}: Header.Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={sxs.root}>
      <Box>
        <Typography variant="subtitle1">{t("Schema configuration")}</Typography>
      </Box>
      <Box sx={sxs.actions}>
        <Button
          variant="outlined"
          onClick={() => onEditModeChange(!isEditMode)}
        >
          {isEditMode ? t("Close editing") : t("Edit")}
        </Button>
        <Button
          variant="contained"
          onClick={onAddField}
          startIcon={<AddIcon />}
        >
          {t("Add new field")}
        </Button>
      </Box>
    </Box>
  );
};

const sxs = {
  root: (theme) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 4,
  }),
  actions: (theme) => ({
    display: "flex",
    gap: theme.spacing(4),
  }),
} satisfies Sxs;

import { Value } from "types/src/jsonSchema/value";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import {
  UiSchemaElementPath,
  UiSchemaElementType,
  UiSchemaElementTypeControl,
} from "types/src/jsonSchema/uiSchema";
import * as R from "rambda";
import * as Fp from "fp-ts/function";
import { ControlType } from "../../types/control/type";
import { ControlTypeValues } from "../../types/control/values";
import {
  replaceNameInScope,
  scopeToName,
  scopeToPath,
  scopeToRequiredNamesPath,
} from "../uiSchema/scope";
import { ControlTypeConfig } from "../../types/control";
import { updateRequiredByScope } from "../dataSchema/updateRequired";

type Params<Type extends ControlType> = {
  type: ControlTypeConfig<Type>;
  elementId: string;
  elementUiPath: UiSchemaElementPath;
  scope: string; // data schema
};

export const mergeControlValuesIntoSchemas = <Type extends ControlType>(
  values: ControlTypeValues<Type> & { name: string },
  { dataSchema: _dataSchema, uiSchema }: Value,
  { type, elementId, elementUiPath, scope: _scope }: Params<Type>,
): Value => {
  const { dataSchema, scope } =
    values.name !== scopeToName(_scope)
      ? rename({
          dataSchema: _dataSchema,
          scope: _scope,
          newName: values.name,
        })
      : { dataSchema: _dataSchema, scope: _scope };

  const {
    dataSchema: controlDataSchema,
    dataSchemaRequired,
    uiSchema: _controlUiSchema,
  } = type.values.toSchema(values);

  const controlUiSchema: UiSchemaElementTypeControl = {
    ..._controlUiSchema,
    type: UiSchemaElementType.control,
    scope,
    options: {
      ..._controlUiSchema.options,
      id: elementId, // fixme: better way to handle/preserve id?
    },
  };

  return {
    dataSchema: Fp.pipe(
      dataSchema,
      R.assocPath<DataSchema>(scopeToPath(scope), controlDataSchema),
      (dataSchema) =>
        updateRequiredByScope(scope, dataSchemaRequired, dataSchema),
    ),
    uiSchema: R.assocPath(elementUiPath, controlUiSchema, uiSchema),
  };
};

const rename = ({
  dataSchema,
  scope,
  newName,
}: {
  dataSchema: DataSchema;
  scope: string;
  newName: string;
}): {
  dataSchema: DataSchema;
  scope: string;
} => {
  const dataSchemaPath = scopeToPath(scope),
    dataSchemaElement = R.path(dataSchemaPath, dataSchema);

  const name = scopeToName(scope),
    requiredNamesPath = scopeToRequiredNamesPath(scope),
    requiredNames = R.path<string[]>(requiredNamesPath, dataSchema) ?? [];

  const newScope = replaceNameInScope(scope, newName),
    newRequiredNames: string[] = [
      ...requiredNames.filter((n) => n !== name),
      ...(requiredNames.includes(name) ? [scopeToName(newScope)] : []),
    ],
    newDataSchema: DataSchema = Fp.pipe(
      dataSchema,
      R.dissocPath<DataSchema>(dataSchemaPath),
      R.assocPath<DataSchema>(scopeToPath(newScope), dataSchemaElement),
      R.assocPath<DataSchema>(requiredNamesPath, newRequiredNames),
    );

  // todo: update all UiSchema elements that had the same `scope`

  return { dataSchema: newDataSchema, scope: newScope };
};

// region StockId
import { ItemMovementId } from "../ItemMovements/ItemMovement";
import { Repository } from "../Repositories/Repository";
import { InventoryItem } from "../InventoryItems/InventoryItem";
import { ISODate } from "../date/ISODate";
import { UUID } from "../UUID";

declare const _stockId: unique symbol;

export type StockId = UUID & { [_stockId]: "StockId" };

export namespace StockId {
  export const fromString = UUID.fromString<StockId>;
}
// endregion

export interface Stock {
  id: StockId;
  createdAt: ISODate;
  updatedAt: ISODate | undefined;
  incomingStock: number;
  ownIncomingStock: number;
  movementId: ItemMovementId;
  outgoingStock: number;
  ownOutgoingStock: number;
  quantity: number;
  ownQuantity: number;
  repository: Repository;
  item: InventoryItem;
}

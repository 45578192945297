import { createState } from "../SchemaFields";

export const createSchemaFieldsState = (p: string) =>
  createState(`${p}:Fields`);

export type SchemaFieldsStatesMap = {
  [k in keyof ReturnType<typeof createState>["states"]]: ReturnType<
    ReturnType<typeof createState>["states"][k]["create"]
  >;
};

export type SchemaFieldsState =
  SchemaFieldsStatesMap[keyof SchemaFieldsStatesMap];

export type SchemaFieldsActionsMap = {
  [k in keyof ReturnType<typeof createState>["actions"]]: ReturnType<
    ReturnType<typeof createState>["actions"][k]["create"]
  >;
};

export type SchemaFieldsAction =
  SchemaFieldsActionsMap[keyof SchemaFieldsActionsMap];

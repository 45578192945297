import { DataTypeId } from "../DataType/DataType";
import { Sku } from "../Sku";
import { UUID } from "../UUID";
import { ISODate } from "../date/ISODate";
import { DataSchemaValue } from "../jsonSchema/DataSchemaValue";

// region CustomerId
declare const _inventoryItemId: unique symbol;

export type InventoryItemId = UUID & {
  [_inventoryItemId]: "InventoryItemId";
};

export namespace InventoryItemId {
  export const fromString = UUID.fromString<InventoryItemId>;
}
// endregion

export interface InventoryItem {
  id: InventoryItemId;
  dataTypeId: DataTypeId;
  fields: DataSchemaValue;
  createdAt: ISODate;
  updatedAt: ISODate | undefined;
  sku: Sku;
}

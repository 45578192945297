import { Typed } from "utils/Typed";
import { DataTypeId } from "types/src/DataType/DataType";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import * as FormValue from "types/src/FormValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { GetGuardType } from "types/src/Utils";
import * as Rx from "rxjs";
import { silentUnreachableError } from "utils/exceptions";
import { DataSchemaValue } from "types/src/jsonSchema/DataSchemaValue";
import { Epic } from "../../types/RootEpic";
import * as SchemaFields from "../SchemaFields";

interface DataType {
  id: DataTypeId;
  name: string;
  schema: DataSchema;
  uiSchema: UiSchema | undefined;
}

export namespace InboundItem {
  export function createState(p: string) {
    interface Ready {
      dataTypes: Array<DataType>;
      dataTypeId: O.Option<DataTypeId>;
      fields: O.Option<GetGuardType<typeof schemaFields.isState>>;
      quantity: FormValue.Value<
        "required" | "invalid",
        number,
        number | undefined
      >;
      sku: FormValue.Value<
        "required" | "invalid",
        NoEmptyString,
        string | undefined
      >;
    }

    interface Submitted extends Ready {
      fields: O.Option<
        Typed.GetType<
          typeof schemaFields.states.valid | typeof schemaFields.states.invalid
        >
      >;
      quantity: FormValue.SubmittedValue<Ready["quantity"]>;
      sku: FormValue.SubmittedValue<Ready["sku"]>;
    }

    interface Valid extends Ready {
      fields: O.Option<Typed.GetType<typeof schemaFields.states.valid>>;
      quantity: FormValue.ValidValue<Ready["quantity"]>;
      sku: FormValue.ValidValue<Ready["sku"]>;
    }

    const schemaFields = SchemaFields.createState(`${p}:schemaFields`);
    const states = Typed.builder
      .add("ready", (p: Ready) => p)
      .add("submitted", (p: Submitted) => p)
      .add("valid", (p: Valid) => p)
      .finish()(`${p}:states`);
    const actions = Typed.builder
      .add("setDataType", (p: DataTypeId | undefined) => p)
      .add("setQuantity", (p: number | undefined) => p)
      .add("setSku", (p: string | undefined) => p)
      .add("fields", (p: SchemaActions) => p)
      .add("submit")
      .finish()(`${p}:actions`);
    const exits = Typed.builder.finish()(`${p}:exits`);

    type State = Typed.GetTypes<typeof states>;
    type Actions = Typed.GetTypes<typeof actions>;
    type Exits = Typed.GetTypes<typeof exits>;
    type SchemaActions = GetGuardType<typeof schemaFields.isActions>;
    type SchemaState = GetGuardType<typeof schemaFields.isState>;

    const epic: Epic<Actions, State> = (s$) => {
      return schemaFields
        .epic(
          s$.pipe(
            Rx.map((s) => s.payload.fields),
            Rx.filter(O.isSome),
            Rx.map((v) => v.value),
          ),
          undefined,
        )
        .pipe(
          Rx.map(
            Fp.flow(
              E.fromPredicate(
                schemaFields.isActions,
                (a) => a as Exclude<typeof a, SchemaActions>,
              ),
              E.map(actions.fields.create),
              E.getOrElseW((a) => a),
            ),
          ),
        );
    };

    return {
      states,
      isState: Typed.getGuard(states),
      actions,
      isAction: Typed.getGuard(actions),
      exits,
      isExit: Typed.getGuard(exits),
      epic,
      reducer,
      subStates: {
        fields: schemaFields,
      },
      init: (p: {
        dataTypes: DataType[];
        dataTypeId?: DataTypeId;
        fields?: DataSchemaValue;
        quantity?: number;
        sku?: string;
      }) =>
        states.ready.create({
          dataTypes: p.dataTypes,
          dataTypeId: O.fromNullable(p.dataTypeId),
          fields: Fp.pipe(
            O.fromNullable(p.dataTypeId),
            O.map((id) => p.dataTypes.find((d) => d.id === id)),
            O.chain(O.fromNullable),
            O.map((d) =>
              schemaFields.init({
                schema: d.schema,
                uiSchema: d.uiSchema,
                values: p.fields,
              }),
            ),
          ),
          quantity: FormValue.initial(p.quantity),
          sku: FormValue.initial(p.sku),
        }),
    };

    function reducer(s: State, a: Actions): E.Either<Exits, State> {
      if (actions.setDataType.is(a)) {
        if (a.payload === O.toUndefined(s.payload.dataTypeId))
          return E.right(s);

        if (a.payload === undefined)
          return E.right(
            states.ready.create({
              ...s.payload,
              dataTypeId: O.none,
              fields: O.none,
            }),
          );

        return Fp.pipe(
          s.payload.dataTypes.find((d) => d.id === a.payload),
          O.fromNullable,
          O.map((d) =>
            states.ready.create({
              ...s.payload,
              dataTypeId: O.some(d.id),
              fields: O.some(
                schemaFields.init({
                  schema: d.schema,
                  uiSchema: d.uiSchema,
                  values: undefined,
                }),
              ),
            }),
          ),
          O.getOrElse(() => s),
          E.right,
        );
      }

      if (actions.fields.is(a)) {
        return Fp.pipe(
          s.payload.fields as O.Option<SchemaState>,
          O.chain((s) =>
            Fp.pipe(
              schemaFields.reducer(s, a.payload),
              E.getOrElseW((e) => {
                silentUnreachableError(e);
                return undefined;
              }),
              O.fromNullable,
            ),
          ),
          O.map((v) => {
            if (states.valid.is(s) && schemaFields.states.valid.is(v))
              return states.valid.create({ ...s.payload, fields: O.some(v) });

            if (
              states.submitted.is(s) &&
              (schemaFields.states.valid.is(v) ||
                schemaFields.states.invalid.is(v))
            )
              return states.submitted.create({
                ...s.payload,
                fields: O.some(v),
              });

            return states.ready.create({ ...s.payload, fields: O.some(v) });
          }),
          O.getOrElse(() => s),
          E.right,
        );
      }

      if (actions.setSku.is(a)) {
        const sku = validateSku(a.payload);

        if (states.submitted.is(s))
          return E.right(states.submitted.create({ ...s.payload, sku }));

        return E.right(states.ready.create({ ...s.payload, sku }));
      }

      if (actions.setQuantity.is(a)) {
        const quantity = validateQuantity(a.payload);

        if (states.submitted.is(s))
          return E.right(states.submitted.create({ ...s.payload, quantity }));

        return E.right(states.ready.create({ ...s.payload, quantity }));
      }

      if (actions.submit.is(a)) {
        if (states.ready.is(s)) {
          const quantity = FormValue.isInitial(s.payload.quantity)
            ? validateQuantity(s.payload.quantity.value)
            : s.payload.quantity;
          const sku = FormValue.isInitial(s.payload.sku)
            ? validateSku(s.payload.sku.value)
            : s.payload.sku;
          const fields = Fp.pipe(
            s.payload.fields,
            O.map((s) => {
              return Fp.pipe(
                schemaFields.reducer(s, schemaFields.actions.submit.create()),
                E.getOrElse((e) => {
                  silentUnreachableError(e);
                  return s;
                }),
              );
            }),
          );

          return FormValue.isValid(sku) &&
            FormValue.isValid(quantity) &&
            toOptionGuard(schemaFields.states.valid.is)(fields)
            ? E.right(
                states.valid.create({
                  ...s.payload,
                  fields,
                  sku,
                  quantity,
                }),
              )
            : E.right(
                states.submitted.create({
                  ...s.payload,
                  fields: fields as O.Option<
                    Typed.GetType<typeof schemaFields.states.invalid>
                  >,
                  sku,
                  quantity,
                }),
              );
        }

        return E.right(s);
      }

      silentUnreachableError(a);
      return E.right(s);
    }
  }

  export type Store = ReturnType<typeof createState>;
  export type State = GetGuardType<ReturnType<typeof createState>["isState"]>;
  export type Actions = GetGuardType<
    ReturnType<typeof createState>["isAction"]
  >;
}

const validateSku = validateFormValue(NoEmptyString.isNoEmptyString);
const validateQuantity = validateFormValue(
  (v: number): v is number => Number.isInteger(v) && v > 0,
);

function validateFormValue<V extends I, I>(
  p: (v: I) => v is V,
): (
  v: I | undefined,
) => FormValue.SubmittedValue<
  FormValue.Value<"required" | "invalid", V, I | undefined>
> {
  return Fp.flow(
    E.fromNullable(FormValue.invalid("required" as const, undefined)),
    E.chainW(E.fromPredicate(p, FormValue.invalid("invalid" as const))),
    E.map(FormValue.valid),
    E.toUnion,
  );
}

const toOptionGuard =
  <A, B extends A>(p: (a: A) => a is B) =>
  (v: O.Option<A>): v is O.Option<B> =>
    Fp.pipe(
      v,
      O.map(p),
      O.getOrElse(() => true),
    );

import { Typed } from "utils/Typed";
import { createState } from "../../../../../../../../generic-states/SchemaFields";

export const schemaFieldsState = createState(
  "Ready:DataManager:InventoryItems:Edit:SchemaFields",
);

export type SchemaFieldsActionsMap = {
  [k in keyof typeof schemaFieldsState.actions]: Typed.GetType<
    (typeof schemaFieldsState.actions)[k]
  >;
};
export type SchemaFieldsActions =
  SchemaFieldsActionsMap[keyof SchemaFieldsActionsMap];

export type SchemaFieldsStatesMap = {
  [k in keyof typeof schemaFieldsState.states]: Typed.GetType<
    (typeof schemaFieldsState.states)[k]
  >;
};
export type SchemaFieldsState =
  SchemaFieldsStatesMap[keyof SchemaFieldsStatesMap];

import { User, UserId } from "types";
import * as Rx from "rxjs";
import { unreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import { Epic } from "../../types/RootEpic";
import { UserSettings } from "../../types/UserSettings";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

interface Deps {
  getUser(): Rx.Observable<O.Option<User>>;
  getUserSettings(id: UserId): Rx.Observable<object>;
  signIn(): Rx.Observable<User>;
  signInConfirm(): Rx.Observable<void>;
}

export const epic: Epic<Actions.Actions, State.State, Deps> = (
  state$,
  deps,
) => {
  return state$.pipe(
    Rx.switchMap((state) => {
      if (State.states.login.is(state)) return Rx.NEVER;
      if (State.states.authError.is(state)) return Rx.NEVER;

      if (State.states.idle.is(state)) {
        return Rx.from(deps.getUser()).pipe(
          Rx.switchMap((r) => {
            if (O.isNone(r)) {
              return Rx.of(Actions.actions.userPreloadFail.create());
            }

            return getSettings(r.value.id).pipe(
              Rx.map((settings) =>
                Actions.actions.userPreloadSuccess.create({
                  settings,
                  user: r.value,
                }),
              ),
            );
          }),
          Rx.catchError(() => Rx.of(Actions.actions.userPreloadFail.create())),
        );
      }

      if (State.states.authentication.is(state)) {
        return Rx.from(deps.signIn()).pipe(
          Rx.switchMap((user) => {
            return getSettings(user.id).pipe(
              Rx.map((settings) =>
                Actions.actions.signInSuccess.create({ settings, user }),
              ),
            );
          }),
          Rx.catchError(() => Rx.of(Actions.actions.signInFail.create())),
        );
      }

      if (State.states.authConfirmation.is(state)) {
        deps.signInConfirm();
        return Rx.NEVER;
      }

      unreachableError(state);
      return Rx.NEVER;
    }),
  );

  function getSettings(id: UserId) {
    return Rx.from(deps.getUserSettings(id)).pipe(
      Rx.map(
        Fp.flow(
          UserSettings.fromObject,
          O.getOrElse(() =>
            UserSettings.create({
              listingVisibleColumns: {},
            }),
          ),
          (v) => v.payload,
        ),
      ),
    );
  }
};

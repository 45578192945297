import { ActionsBar as UI } from "ui/packages/json-schema-form-builder/ActionsBar";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Value } from "types/src/jsonSchema/value";
import { useBehaviorValueRef } from "react-rx/behaviorValue";
import {
  UiSchemaElementType,
  UiSchemaElementTypeHorizontalLayout as HorizontalLayout,
  UiSchemaElementTypeVerticalLayout as VerticalLayout,
} from "types/src/jsonSchema/uiSchema";
import { randomId } from "utils/randomId";
import { UiState } from "../types/uiState";
import { useControlDeleteSelected } from "../hooks/control/delete";
import { useControlCloneSelected } from "../hooks/control/clone";
import { useControlGroupSelected } from "../hooks/control/group";
import { useSelectedCount } from "./hooks/useSelectedCount";
import { useCanGroup } from "./hooks/useCanGroup";

export namespace ActionsBar {
  export type Props = {
    value$: BehaviorValue<Value>;
    onValueChange: (value: Value) => void;
    ui$: BehaviorValue<UiState>;
    onUiChange: (ui: Partial<UiState>) => void;
  };
}

export const ActionsBar = ({
  ui$,
  onUiChange,
  value$,
  onValueChange,
}: ActionsBar.Props) => {
  const selectedCount = useSelectedCount({ ui$ });

  const valueRef = useBehaviorValueRef(value$);
  const uiRef = useBehaviorValueRef(ui$);

  const onClone = useControlCloneSelected({
    valueRef,
    onValueChange,
    uiRef,
  });
  const onDelete = useControlDeleteSelected({
    valueRef,
    onValueChange,
    uiRef,
    onUiChange,
  });

  const canGroup = useCanGroup({ ui$, valueRef });

  const onGroupVertically = useControlGroupSelected({
    valueRef,
    onValueChange,
    uiRef,
    onUiChange,
    makeParent: makeVerticalLayout,
  });
  const onGroupHorizontally = useControlGroupSelected({
    valueRef,
    onValueChange,
    uiRef,
    onUiChange,
    makeParent: makeHorizontalLayout,
  });

  const onClose = () => onUiChange({ editing: undefined });

  return (
    <UI
      {...{
        selectedCount,
        /**
         * Will enable when we'll allow nested groups.
         *
         * By default everything is vertical, makes sens only in Horizontal Layout:
         * `Horizontal { Textarea, Textarea, Vertical { Input, Input } }`
         */
        onGroupVertically: undefined,
        onGroupHorizontally: canGroup ? onGroupHorizontally : undefined,
        onClone,
        onDelete,
        onClose,
      }}
    />
  );
};

const makeVerticalLayout = ({
  existingIds,
}: {
  existingIds: Record<string, unknown>;
}): VerticalLayout => ({
  type: UiSchemaElementType.verticalLayout,
  options: { id: randomId({ existingIds }) },
  elements: [],
});

const makeHorizontalLayout = ({
  existingIds,
}: {
  existingIds: Record<string, unknown>;
}): HorizontalLayout => ({
  type: UiSchemaElementType.horizontalLayout,
  options: { id: randomId({ existingIds }) },
  elements: [],
});

import { randomId } from "utils/randomId";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import {
  UiSchemaElementType,
  UiSchemaElementTypeControl,
} from "types/src/jsonSchema/uiSchema";
import { getAllElementNames } from "../dataSchema/getAllElementNames";
import { ControlDataSchema, ControlSchemas } from "../../types/control/schema";

type Props = {
  dataSchema: DataSchema;
};

export const makeControl = (p: Props): ControlSchemas => {
  const name = makeControlName(getAllElementNames(p.dataSchema)),
    dataSchema: ControlDataSchema = {
      type: "string",
    },
    uiSchema: UiSchemaElementTypeControl = {
      type: UiSchemaElementType.control,
      scope: `#/properties/${name}`,
    };

  return { dataSchema, uiSchema, dataSchemaRequired: false };
};

export const makeControlName = (existingNames: Record<string, unknown>) =>
  randomId({
    prefix: "input_",
    length: 7,
    existingIds: existingNames,
  });

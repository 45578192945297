import { DsError } from "ds";
import { DataTypeId } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import { isOneOf } from "utils/isOneOf";
import { v4 as uuidv4 } from "uuid";
import { NoEmptyArr } from "types/src/NoEmptyArr";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import {
  CustomerSearchState,
  CustomerSearchStatesMap,
  PickingOrderItemsState,
  PickingOrderItemsStatesMap,
  SchemaFieldsState,
  SchemaFieldsStatesMap,
} from "../utils";

export type State = Loading | LoadError | Ready | Saving;

export const isState = (p: string) =>
  strictGuard(isOneOf([isLoading(p), isLoadError(p), isReady(p), isSaving(p)]));

// region Loading
export interface LoadingPayload {
  dataTypeId: DataTypeId;
}

export interface Loading {
  type: `${string}:Loading`;
  payload: LoadingPayload;
}

export const loading =
  (p: string) =>
  (payload: Loading["payload"]): Loading => ({
    type: `${p}:Loading`,
    payload,
  });

export const isLoading =
  (p: string) =>
  (s: State): s is Loading =>
    s.type === `${p}:Loading`;
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  error: DsError;
}

export interface LoadError {
  type: `${string}:LoadError`;
  payload: LoadErrorPayload;
}

export const loadError =
  (p: string) =>
  (payload: LoadError["payload"]): LoadError => ({
    type: `${p}:LoadError`,
    payload,
  });

export const isLoadError =
  (p: string) =>
  (s: State): s is LoadError =>
    s.type === `${p}:LoadError`;
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  customer: CustomerSearchState;
  dataTypes: NoEmptyArr<{
    title: string;
    id: DataTypeId;
    schema: DataSchema;
    ui: UiSchema;
  }>;
  fields: SchemaFieldsState;
  items: PickingOrderItemsState;
  submitted: boolean;
}

export interface Ready {
  type: `${string}:Ready`;
  payload: ReadyPayload;
}

export const ready =
  (p: string) =>
  (payload: Ready["payload"]): Ready => ({
    type: `${p}:Ready`,
    payload,
  });

export const isReady =
  (p: string) =>
  (s: State): s is Ready =>
    s.type === `${p}:Ready`;
// endregion

// region Saving
export interface SavingPayload extends ReadyPayload {
  customer: CustomerSearchStatesMap["selected"];
  fields: SchemaFieldsStatesMap["valid"];
  items: PickingOrderItemsStatesMap["valid"];
}

export interface Saving {
  type: `${string}:Saving`;
  payload: SavingPayload;
}

export const saving =
  (p: string) =>
  (payload: Saving["payload"]): Saving => ({
    type: `${p}:Saving`,
    payload,
  });

export const isSaving =
  (p: string) =>
  (s: State): s is Saving =>
    s.type === `${p}:Saving`;
// endregion

// region ItemId
declare const _itemId: unique symbol;

export type ItemId = string & { [_itemId]: "ItemId" };

export const createItemId = (): ItemId => uuidv4() as ItemId;
// endregion

import React, { useContext } from "react";
import {
  UiSchemaElement,
  UiSchemaElementType,
} from "types/src/jsonSchema/uiSchema";
import { getId } from "../utils/uiSchema";
import { ElementTypesContext } from "../contexts/ElementTypes";
import {
  uiElementPathDecode,
  uiElementPathEncode,
} from "../utils/uiSchema/elementPath";

export namespace Elements {
  export type Props = {
    elements: UiSchemaElement[];
    parentUiSchemaPathEncoded: string;
  };
}

export const Elements: React.FC<Elements.Props> = ({
  elements,
  parentUiSchemaPathEncoded: parentPathEncoded,
}) => {
  const { elementTypes } = useContext(ElementTypesContext),
    { path: parentPath } = uiElementPathDecode(parentPathEncoded);

  return (
    <>
      {elements.map((el, index): React.ReactNode => {
        const id = getId(el);

        if (el.type === UiSchemaElementType.control) {
          const { RenderInEditor } = elementTypes[el.type];
          return <RenderInEditor key={id} id={id} />;
        }

        const { RenderInEditor } = elementTypes[el.type];
        return (
          <RenderInEditor
            key={id}
            /**
             * fixme: Vertical/HorizontalLayout also has `options.id`
             *    maybe migrate all to render by `id` approach (as Control)
             *    maybe that will cause less UI shaking on drag-n-drop
             */
            uiSchemaPathEncoded={uiElementPathEncode({
              path: [...parentPath, "elements", index],
              id,
            })}
          />
        );
      })}
    </>
  );
};

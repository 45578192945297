import { UiSchemaElementPath } from "types/src/jsonSchema/uiSchema";
import { Value } from "types/src/jsonSchema/value";
import * as Fp from "fp-ts/function";
import * as R from "rambda";
import { ControlSchemas } from "../../types/control/schema";
import { scopeToPath } from "../uiSchema/scope";
import { updateRequiredByScope } from "../dataSchema/updateRequired";
import { uiSchemaInsertAtPath } from "../uiSchema/insertAtPath";

export const controlInsertAtPath = ({
  control,
  uiPath,
  value,
  before,
}: {
  control: ControlSchemas;
  uiPath: UiSchemaElementPath;
  value: Value;
  before?: boolean;
}): Value => ({
  ...value,
  dataSchema: Fp.pipe(
    value.dataSchema,
    (dataSchema) =>
      R.assocPath<Value["dataSchema"]>(
        scopeToPath(control.uiSchema.scope),
        control.dataSchema,
        dataSchema,
      ),
    (dataSchema) =>
      updateRequiredByScope(
        control.uiSchema.scope,
        control.dataSchemaRequired,
        dataSchema,
      ),
  ),
  uiSchema: uiSchemaInsertAtPath({
    uiPath,
    element: control.uiSchema,
    uiSchema: value.uiSchema,
    before,
  }),
});

import { alpha, Theme } from "@mui/material/styles";

export const styleTableBorderColor = (theme: Theme) => theme.palette.divider;

export const styleButtonBorderColor = (theme: Theme) =>
  alpha(theme.palette.common.black, 0.23);

export const styleSecondaryTextColor = (theme: Theme) =>
  alpha(theme.palette.common.black, 0.5);

export const stylesIcon = (theme: Theme) => ({
  color: theme.palette.action.active,
  width: theme.spacing(5),
  height: theme.spacing(5),
});

export const stylesPopper = (theme: Theme) => ({
  borderRadius: 2,
  border: `1px solid ${styleTableBorderColor(theme)}`,

  // fixme: change theme to be like in Figma:
  //        `0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)`
  boxShadow: theme.shadows[24],
});

export const styleCustomFilterInputWidth = (theme: Theme) =>
  theme.spacing(53.5);

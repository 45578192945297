import { Selector, useSelector } from "state-manager";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { Customers } from "state-manager/states/Ready/states/DataManager/states/Customers";
import { Suppliers } from "state-manager/states/Ready/states/DataManager/states/Suppliers";
import { Repositories } from "state-manager/states/Ready/states/DataManager/states/Repositories";
import { InventoryItems } from "state-manager/states/Ready/states/DataManager/states/InventoryItems";
import { ItemMovements } from "state-manager/states/Ready/states/DataManager/states/ItemMovements";
import { RepositoryMovements } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements";
import { StocksListing } from "state-manager/states/Ready/states/DataManager/states/Stocks";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";

import { ReactElement } from "react";
import { DataTypeId } from "types/src/DataType/DataType";
import { Collections } from "../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Collections";
import { Inbounds } from "../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Inbounds";
import { ItemSets } from "../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/ItemSets";
import * as CustomersContainer from "./Customers";
import * as SuppliersContainer from "./Suppliers";
import * as RepositoriesContainer from "./Repositories";
import * as InventoryItemsContainer from "./InventoryItems";
import * as ItemMovementsContainer from "./ItemMovements";
import * as RepositoryMovementsContainer from "./RepositoryMovements";
import * as PickingOrders from "./PickingOrders";
import * as ItemSetsContainer from "./ItemSets";
import * as InboundsContainer from "./Inbounds";
import * as CollectionsContainer from "./Collections";
import * as StocksContainer from "./Stocks";

export interface ContentProps {
  selector: Selector<DataManager.State>;
  dispatch: (a: DataManager.Actions) => void;
  onType: (id: DataTypeId) => void;
}

export function Content(p: ContentProps): ReactElement | null {
  const r = useSelector(
    flow(
      p.selector,
      (s) => s.payload.subState,
      (s) => {
        if (Collections.instance.isState(s)) {
          return {
            type: "Collections",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (Customers.instance.isState(s)) {
          return {
            type: "Customers",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (Inbounds.instance.isState(s)) {
          return {
            type: "Inbounds",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (ItemSets.instance.isState(s)) {
          return {
            type: "ItemSets",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (Suppliers.instance.isState(s)) {
          return {
            type: "Suppliers",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (Repositories.instance.isState(s)) {
          return {
            type: "Repositories",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (InventoryItems.instance.isState(s)) {
          return {
            type: "InventoryItems",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (ItemMovements.instance.isState(s)) {
          return {
            type: "ItemMovements",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (RepositoryMovements.instance.isState(s)) {
          return {
            type: "RepositoryMovements",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (DataManager.pickingOrderState.isState(s)) {
          return {
            type: "PickingOrders",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (StocksListing.instance.isState(s)) {
          return {
            type: "StocksListing",
            selector: flow(
              p.selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }

        silentUnreachableError(s);
        return { type: "unknown" } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "StocksListing":
      return (
        <StocksContainer.Content selector={r.selector} dispatch={p.dispatch} />
      );
    case "Collections":
      return (
        <CollectionsContainer.Content
          selector={r.selector}
          dispatch={p.dispatch}
          onType={p.onType}
        />
      );
    case "ItemSets":
      return (
        <ItemSetsContainer.Content
          selector={r.selector}
          dispatch={p.dispatch}
          onType={p.onType}
        />
      );
    case "Inbounds":
      return (
        <InboundsContainer.Content
          selector={r.selector}
          dispatch={p.dispatch}
          onType={p.onType}
        />
      );
    case "Customers":
      return (
        <CustomersContainer.Content
          selector={r.selector}
          dispatch={p.dispatch}
          onType={p.onType}
        />
      );
    case "Suppliers":
      return (
        <SuppliersContainer.Content
          selector={r.selector}
          dispatch={p.dispatch}
          onType={p.onType}
        />
      );
    case "Repositories":
      return (
        <RepositoriesContainer.Content
          selector={r.selector}
          dispatch={p.dispatch}
          onType={p.onType}
        />
      );
    case "InventoryItems":
      return (
        <InventoryItemsContainer.Content
          selector={r.selector}
          dispatch={p.dispatch}
          onType={p.onType}
        />
      );
    case "ItemMovements":
      return (
        <ItemMovementsContainer.Content
          selector={r.selector}
          dispatch={p.dispatch}
          onType={p.onType}
        />
      );
    case "RepositoryMovements":
      return (
        <RepositoryMovementsContainer.Content
          selector={r.selector}
          dispatch={p.dispatch}
          onType={p.onType}
        />
      );
    case "PickingOrders":
      return (
        <PickingOrders.Content
          selector={r.selector}
          dispatch={p.dispatch}
          onType={p.onType}
        />
      );
    case "unknown":
      return null;
  }
}

import { UUID } from "../UUID";
import { ISODate } from "../date/ISODate";
import { DataTypeId } from "../DataType/DataType";
import { DataSchemaValue } from "../jsonSchema/DataSchemaValue";
import { SupplierId } from "../Supplier/Supplier";
import { NoEmptyString } from "../NoEmptyString";
import { InboundItem, InboundItemId } from "./InboundItem";

// region InboundId
declare const _inboundId: unique symbol;

export type InboundId = UUID & { [_inboundId]: "InboundId" };

export namespace InboundId {
  export const fromString = UUID.fromString<InboundId>;
}
// endregion

export interface Inbound {
  id: InboundId;
  createdAt: ISODate;
  updatedAt: ISODate | undefined;
  dataTypeId: DataTypeId | undefined;
  fields: DataSchemaValue;
  items: InboundItem[];
  supplierId: SupplierId;
}

export interface InboundCreate {
  fields: DataSchemaValue | undefined;
  dataTypeId: DataTypeId | undefined;
  items: InboundItemsCreate[];
  supplierID: UUID;
}

export interface InboundItemsCreate {
  fields: DataSchemaValue | undefined;
  dataTypeId: DataTypeId | undefined;
  quantity: number;
  sku: NoEmptyString;
}

export interface InboundUpdate {
  id: InboundId;
  addInboundItemIds?: InboundItemId[];
  fields?: DataSchemaValue | null;
  dataTypeId?: DataTypeId | null;
  removeInboundItemIds?: InboundItemId[] | null | "all";
  supplierId?: SupplierId;
}

import { gql } from "@apollo/client";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Stock, StockId } from "types/src/Stocks/Stock";
import { isT } from "fp-utilities";
import { Client, notFoundError, QueryResponse } from "../../index";
import { stockFragment } from "../../fragments/Stock";
import {
  GetStocksQuery,
  GetStocksQueryVariables,
} from "../../generated/graphql";
import { GetStocksVars, getStocksVarsToApiVars } from "../../type/Stocks";
import { stockFragmentToStock } from "../../transformers/Stocks";
import {
  getPaginatedQueryResult,
  PaginatedQueryResponse,
} from "../../type/QueryResponse";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";

export type { GetStocksVars } from "../../type/Stocks";

export function getStocks(
  client: Client,
  vars: GetStocksVars,
): Promise<PaginatedQueryResponse<Stock>> {
  const query = gql`
    ${pageInfoFragment}
    ${stockFragment}

    query GetStocks(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: StockWhereInput
      $orderBy: StockOrder
    ) {
      stocks(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...StockFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetStocksQuery, GetStocksQueryVariables>({
      query,
      variables: getStocksVarsToApiVars(vars),
      options: { includeDeleted: true },
    })
    .then(
      E.map(
        flow((v) => v.stocks, getPaginatedQueryResult(stockFragmentToStock)),
      ),
    );
}

export function getStock(
  client: Client,
  id: StockId,
): Promise<QueryResponse<Stock>> {
  return getStocks(client, { where: { id: { eq: id } }, first: 1 }).then(
    flow(
      E.map((v) => v.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}

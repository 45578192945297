import { FormEditor as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor";
import { useCallback } from "react";
import { Value } from "types/src/jsonSchema/value";
import { BehaviorValueRef } from "react-rx/behaviorValue";
import { movedElements } from "../../utils/uiSchema/movedElements";
import { useClearPositionCaches } from "../../hooks/useClearPositionCaches";
import { fixValue } from "../../utils/value/fix";

type Props = {
  valueRef: BehaviorValueRef<Value>;
  onValueChange: (value: Value) => void;
};

export const useOnDragEnd = ({
  valueRef,
  onValueChange: _onValueChange,
}: Props) => {
  const onValueChange = useClearPositionCaches(_onValueChange);

  return useCallback<UI.Props["onDragEnd"]>(
    ({ droppable, draggable, closest }) => {
      const uiSchema = movedElements({
        uiSchema: valueRef.current.uiSchema,
        droppable,
        draggable,
        closest,
      });
      if (uiSchema) {
        onValueChange(fixValue({ ...valueRef.current, uiSchema }));
      }
    },
    [valueRef, onValueChange],
  );
};

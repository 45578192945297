export const scopeToName = (scope: string): string =>
  scope.split("/").pop() ?? /*will never happen*/ "";

export const scopeToPath = (scope: string): string[] =>
  scope.replace(/^#\//, "").split("/");

export const scopeToRequiredNamesPath = (scope: string): string[] => {
  const path = scopeToPath(scope);
  return [
    ...path.slice(0, path.length - /*[propName]*/ 1 - /*"properties"*/ 1),
    "required",
  ];
};

export const replaceNameInScope = (scope: string, newName: string): string =>
  scope.replace(/[^\\/]+$/, newName);

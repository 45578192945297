import * as R from "rambda";
import { UiSchemaElementTypeControl } from "types/src/jsonSchema/uiSchema";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import {
  scopeToName,
  scopeToPath,
  scopeToRequiredNamesPath,
} from "../uiSchema/scope";
import { ControlSchemas } from "../../types/control/schema";

export const controlDataSchemaFromUI = <
  ElementDataSchema extends object = object,
>(
  uiSchema: UiSchemaElementTypeControl,
  dataSchema: DataSchema,
): undefined | Pick<ControlSchemas, "dataSchema" | "dataSchemaRequired"> => {
  const controlDataSchema = R.path<ElementDataSchema>(
    scopeToPath(uiSchema.scope),
    dataSchema,
  );

  if (!controlDataSchema) {
    return undefined;
  }

  return {
    dataSchema: controlDataSchema,
    dataSchemaRequired: controlIsRequired(dataSchema, uiSchema.scope),
  };
};

export const controlIsRequired = (
  dataSchema: DataSchema,
  scope: string,
): boolean => {
  const requiredProps =
    R.path<string[]>(scopeToRequiredNamesPath(scope), dataSchema) ?? [];

  return Array.isArray(requiredProps)
    ? requiredProps.includes(scopeToName(scope))
    : false;
};

import { useTranslation } from "i18n";
import { ControlTypeRegistry } from "../../../types/control";
import { ControlType } from "../../../types/control/type";
import { makeTypeString } from "./typeString";
import { makeTypeNumber } from "./typeNumber";
import { makeTypeDate } from "./typeDate";
import { makeTypeBoolean } from "./typeBoolean";

type Props = Pick<ReturnType<typeof useTranslation>, "t">;

export const makeControlTypes = ({ t }: Props): ControlTypeRegistry => ({
  [ControlType.string]: makeTypeString({ t }),
  [ControlType.number]: makeTypeNumber({ t }),
  [ControlType.date]: makeTypeDate({ t }),
  [ControlType.boolean]: makeTypeBoolean({ t }),
});

import { Box } from "@mui/material";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import React, { useCallback } from "react";
import { Sxs } from "../../../../../../types/styles";
import { useElementRefs } from "../../elementRefs/hooks";
import { DndMeta } from "../../types";
import { Header } from "./Header";
import { Footer } from "./Footer";

export function Control({
  id,
  name,
  type,
  dndMeta,
  editing,
  expanded,
  onExpandedChange,
  onReset,
  onDelete,
  children /* used only when `expanded==true` */,
}: Control.Props) {
  const {
    isDragging,
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    attributes,
    transform,
  } = useDraggable({ id, data: dndMeta });

  const elementRef = useElementRefs({ id, meta: dndMeta });
  const setRefs = useCallback(
    (el: HTMLElement | null) => {
      elementRef.set(el);
      setNodeRef(el);
    },
    [elementRef, setNodeRef],
  );

  const style = isDragging
    ? ({
        transform: CSS.Translate.toString(transform),
        zIndex: 100,
      } satisfies React.CSSProperties)
    : undefined;

  return (
    <Box ref={setRefs} sx={sxs.root} style={style}>
      <Header
        {...{
          name,
          type,
          editing,
          expanded,
          onExpandedChange,
          dndHandleProps: {
            ref: setActivatorNodeRef,
            ...listeners,
            ...attributes,
          },
        }}
      />
      {expanded && (
        <Box sx={sxs.expanded}>
          {/* <Content /> */ children}
          <Footer {...{ onDelete, onReset }} />
        </Box>
      )}
    </Box>
  );
}

export namespace Control {
  export type Props = React.PropsWithChildren<{
    id: string;
    name: string;
    type: string;
    dndMeta: DndMeta.Draggable;
    editing:
      | undefined
      | {
          selected: boolean;
          onSelectedChange: (selected: boolean) => void;
        };

    expanded: boolean;
    onExpandedChange: (expanded: boolean) => void;

    onReset: () => void;
    onDelete: () => void;
  }>;
}

const sxs = {
  root: (theme) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
  }),
  expanded: (theme) => ({
    backgroundColor: theme.palette.surface.primary,
    pt: 4,
    borderRadius: theme.spacing(0, 0, 2, 2),
  }),
} satisfies Sxs;

import { Selector } from "state-manager";
import { StocksListing as Main } from "state-manager/states/Ready/states/DataManager/states/Stocks";
import { useStateBehavior } from "@Hooks/useStateBehavior";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { StocksListing } from "@Containers/Listing/StocksListing";
import { match } from "fp-utilities";
import * as Obj from "utils/object";
import { SymmetricTuple, Tuple } from "types/src/Tuple";
import { ISODate } from "types/src/date/ISODate";
import { RepositoryId } from "types/src/Repositories/Repository";

export namespace Content {
  export interface Props {
    selector: Selector<Main.State>;
    dispatch: (a: Main.Actions) => void;
  }
}

export function Content(p: Content.Props) {
  const state$ = useStateBehavior().map(p.selector);
  const data$ = state$.map(
    Fp.flow((s) => {
      if (
        Main.instance.states.loading.is(s) ||
        Main.instance.states.loadError.is(s)
      )
        return {
          entries: [],
          total: 0,
          pagination: {
            hasNext: false,
            hasPrev: false,
          },
        };

      return {
        entries: s.payload.items,
        total: s.payload.total,
        pagination: {
          hasPrev: s.payload.pageInfo.hasPreviousPage,
          hasNext: s.payload.pageInfo.hasNextPage,
        },
      };
    }),
  );

  return (
    <StocksListing
      state$={state$.map(
        match(
          [Main.instance.states.loading.is, () => "loading"],
          [Main.instance.states.loadError.is, () => "loading"],
          [Main.instance.states.ready.is, () => "ready"],
          [Main.instance.states.fetching.is, () => "fetching"],
        ),
      )}
      data$={data$}
      //region Filters
      filtersConfig$={state$.map((s) => {
        return {
          repository: {
            choices: Fp.pipe(
              s.payload.repositories,
              Main.instance.subStates.repositories.selectors.getData,
              O.map((v) => v.map((i) => ({ id: i.id, label: i.name }))),
              O.toUndefined,
              (v) => v ?? [],
            ),
            onSearch: (q) =>
              p.dispatch(
                Main.instance.subStates.repositories.actions.setQuery.create(q),
              ),
            state: match(
              [
                Main.instance.subStates.repositories.states.ready.is,
                () => "ready" as const,
              ],
              [
                Main.instance.subStates.repositories.states.loading.is,
                () => "loading" as const,
              ],
              [
                Main.instance.subStates.repositories.states.loadError.is,
                () => "error" as const,
              ],
            )(s.payload.repositories),
            search: s.payload.repositories.payload.query,
          },
        };
      })}
      filters$={state$.map(
        Fp.flow(
          (s) => s.payload.filters.payload.fields,
          (s) => ({
            search: s.search,
            createdAt: s.createdAt,
            custom: Fp.pipe(
              {
                repository: Fp.pipe(
                  s.repository,
                  O.fromNullable,
                  O.map((v) => v.map((v) => Tuple.create(v, true))),
                  O.map((v) => Obj.fromEntries(v)),
                  O.map((v) => ({ choices: v })),
                  O.toUndefined,
                ),
                active: Fp.pipe(
                  s.active,
                  O.fromNullable,
                  O.map((v) => (v ? ("active" as const) : ("all" as const))),
                  O.map((v) => ({ id: v })),
                  O.toUndefined,
                ),
                quantity: Fp.pipe(
                  s.quantity,
                  O.fromNullable,
                  O.map((v) => ({ start: v[0], end: v[1] })),
                  O.toUndefined,
                ),
                ownQuantity: Fp.pipe(
                  s.ownQuantity,
                  O.fromNullable,
                  O.map((v) => ({ start: v[0], end: v[1] })),
                  O.toUndefined,
                ),
                incomingStock: Fp.pipe(
                  s.incomingStock,
                  O.fromNullable,
                  O.map((v) => ({ start: v[0], end: v[1] })),
                  O.toUndefined,
                ),
                ownIncomingStock: Fp.pipe(
                  s.ownIncomingStock,
                  O.fromNullable,
                  O.map((v) => ({ start: v[0], end: v[1] })),
                  O.toUndefined,
                ),
                outgoingStock: Fp.pipe(
                  s.outgoingStock,
                  O.fromNullable,
                  O.map((v) => ({ start: v[0], end: v[1] })),
                  O.toUndefined,
                ),
                ownOutgoingStock: Fp.pipe(
                  s.ownOutgoingStock,
                  O.fromNullable,
                  O.map((v) => ({ start: v[0], end: v[1] })),
                  O.toUndefined,
                ),
              },
              (v) => Obj.filter((_, k) => Obj.keys(s).includes(k), v),
              Obj.sortWithKeys(Obj.keys(s)),
            ),
          }),
        ),
      )}
      onFilterChange={(v) => {
        const keys = Obj.keys(v);

        return Fp.pipe(
          v,
          (v) => {
            return {
              search: Fp.pipe(
                v.search,
                O.fromNullable,
                O.map(
                  Fp.flow(
                    O.map((v) => v?.text),
                    O.toUndefined,
                  ),
                ),
              ),
              createdAt: Fp.pipe(
                v.createdAt,
                O.fromNullable,
                O.map(
                  Fp.flow(
                    O.map((v) => SymmetricTuple.create(v?.start, v?.end)),
                    O.map((v) =>
                      SymmetricTuple.map(
                        Fp.flow(
                          O.fromNullable,
                          O.map(ISODate.fromDate),
                          O.toUndefined,
                        ),
                        v,
                      ),
                    ),
                  ),
                ),
                O.toUndefined,
              ),
              repository: Fp.pipe(
                v.repository,
                O.fromNullable,
                O.map(
                  Fp.flow(
                    O.map((v) => v?.choices),
                    O.chain(O.fromNullable),
                    O.map((v) => Obj.filter((v) => v ?? false, v)),
                    O.map((v) => Obj.keys(v) as RepositoryId[]),
                    O.toUndefined,
                  ),
                ),
              ),
              active: Fp.pipe(
                v.active,
                O.fromNullable,
                O.map(
                  Fp.flow(
                    O.map((v) => v?.id),
                    O.chain(O.fromNullable),
                    O.map((v) => v === "active"),
                    O.toUndefined,
                  ),
                ),
              ),
              quantity: Fp.pipe(v.quantity, rangeToTuple),
              ownQuantity: Fp.pipe(v.ownQuantity, rangeToTuple),
              incomingStock: Fp.pipe(v.incomingStock, rangeToTuple),
              ownIncomingStock: Fp.pipe(v.ownIncomingStock, rangeToTuple),
              outgoingStock: Fp.pipe(v.outgoingStock, rangeToTuple),
              ownOutgoingStock: Fp.pipe(v.ownOutgoingStock, rangeToTuple),
            };
          },
          (v) => Obj.filter((_, k) => keys.includes(k), v),
          Obj.sortWithKeys(keys),
          Main.instance.subStates.filters.actions.setValue.create,
          p.dispatch,
        );
      }}
      onOrderChange={Fp.flow(Main.instance.actions.orderBy.create, p.dispatch)}
      onResetFilters={Fp.flow(
        Main.instance.subStates.filters.actions.reset.create,
        p.dispatch,
      )}
      //endregion
      orderBy$={state$.map((v) => O.fromNullable(v.payload.order))}
      onPageChange={Fp.flow(Main.instance.actions.setPage.create, p.dispatch)}
      columnsVisibility$={state$.map(Main.instance.selectors.visibleColumns)}
      onColumnsVisibilityChange={Fp.flow(
        Main.instance.actions.setColumnsVisibility.create,
        p.dispatch,
      )}
    />
  );
}

const rangeToTuple = <T,>(
  v: O.Option<{ start?: T; end?: T } | undefined> | undefined,
) =>
  Fp.pipe(
    v,
    O.fromNullable,
    O.map(
      Fp.flow(
        O.map((v) => SymmetricTuple.create(v?.start, v?.end)),
        O.chain(O.fromNullable),
      ),
    ),
    O.toUndefined,
  );

import * as StateModule from "./types/State";
import * as ActionsModule from "./types/Actions";
import { reducer } from "./reducer";
import { epic } from "./epic";
import { createPickingOrderItemState } from "./utils";

export const createState = <Id extends string>(p: string) => {
  return {
    isState: StateModule.isState<Id>(p),
    isActions: ActionsModule.isActions<Id>(p),
    reducer: reducer<Id>(p),
    epic: epic<Id>(p),
    states: {
      ready: {
        create: StateModule.ready<Id>(p),
        is: StateModule.isReady<Id>(p),
      },
      valid: {
        create: StateModule.valid<Id>(p),
        is: StateModule.isValid<Id>(p),
      },
    },
    init: StateModule.init<Id>(p),
    actions: {
      addNew: {
        create: ActionsModule.addNew(p),
        is: ActionsModule.isAddNew<Id>(p),
      },
      remove: {
        create: ActionsModule.remove<Id>(p),
        is: ActionsModule.isRemove<Id>(p),
      },
      submit: {
        create: ActionsModule.submit(p),
        is: ActionsModule.isSubmit<Id>(p),
      },
    },
    createPickingOrderItemState: createPickingOrderItemState<Id>(p),
  };
};

export type { State } from "./types/State";
export type { Actions } from "./types/Actions";

export type StateConstructor<Id extends string> = ReturnType<
  typeof createState<Id>
>;

export type { NewItemId } from "./types/NewItemId";

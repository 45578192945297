import { BehaviorValue } from "rx-addons/BehaviorValue";
import { JsonForm as FormPreview_ } from "ui/layouts/JsonForm/JsonForm";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { FormPreview as UI } from "ui/packages/json-schema-form-builder/Right/FormPreview";
import { useMemo } from "react";
import { JsonFormSchema, Value } from "types/src/jsonSchema/value";
import { UiState } from "../types/uiState";

export namespace FormPreview {
  export type Props = {
    value$: BehaviorValue<Value>;
    ui$: BehaviorValue<UiState>;
    onUiChange: (ui: Partial<UiState>) => void;
  };
}

export const FormPreview = ({ value$, ui$, onUiChange }: FormPreview.Props) => {
  const { dataSchema, uiSchema } = useBehaviorValue(value$);
  const schema = useMemo(
    () => JsonFormSchema.create(dataSchema, uiSchema),
    [dataSchema, uiSchema],
  );
  const formValues = useBehaviorValue(
    useMemo(() => ui$.map((v) => v.previewFormValues), [ui$]),
  );

  return (
    <UI>
      <FormPreview_
        schema={schema}
        value={formValues}
        onChange={({ data /*, errors*/ }) =>
          onUiChange({
            previewFormValues: (data ?? {}) as Record<string, unknown>,
          })
        }
        submitted={false}
      />
    </UI>
  );
};

import {
  UiSchema,
  UiSchemaElementPath,
  UiSchemaElementType,
  UiSchemaElementTypeControl,
} from "types/src/jsonSchema/uiSchema";

import * as R from "rambda";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { findElementPath } from "../uiSchema/findElementPath";
import { ControlPathCache } from "../../types/control/pathCache";
import { ControlDataSchema, ControlSchemas } from "../../types/control/schema";
import { controlDataSchemaFromUI } from "./dataSchema";

type Props = {
  uiSchema: UiSchema;
  pathCache?: ControlPathCache;
  dataSchema?: DataSchema;
};
type Result = Omit<ControlSchemas, "dataSchema" | "dataSchemaRequired"> &
  Partial<Pick<ControlSchemas, "dataSchema" | "dataSchemaRequired">> & {
    uiPath: UiSchemaElementPath;
  };

export function findControlById(
  id: string,
  props: Omit<Props, "dataSchema">,
): Omit<Result, "dataSchema">;
export function findControlById(
  id: string,
  props: Omit<Props, "dataSchema"> & { dataSchema: DataSchema },
): Omit<Result, "dataSchema"> & {
  dataSchema: ControlDataSchema;
  dataSchemaRequired: boolean;
};
export function findControlById(
  id: string,
  props: {
    uiSchema: UiSchema;
    pathCache?: ControlPathCache;
  },
): Omit<Result, "dataSchema">;
export function findControlById(
  id: string,
  p: {
    uiSchema: UiSchema;
    pathCache?: ControlPathCache;
    dataSchema?: DataSchema;
  },
): Result | undefined {
  const uiPath =
    p.pathCache && Object.hasOwn(p.pathCache.current, id)
      ? p.pathCache.current[id]
      : findElementPath({ id, type: UiSchemaElementType.control }, p.uiSchema);
  if (p.pathCache) {
    p.pathCache.current[id] = uiPath;
  }
  if (!uiPath) {
    return undefined;
  }

  const uiSchema = R.path<UiSchemaElementTypeControl>(uiPath, p.uiSchema);
  if (!uiSchema || uiSchema.type !== UiSchemaElementType.control) {
    return undefined;
  }

  const result: Result = { uiPath, uiSchema };

  if (p.dataSchema) {
    const ds = controlDataSchemaFromUI<ControlDataSchema>(
      uiSchema,
      p.dataSchema,
    );
    if (!ds) {
      return undefined;
    }

    result.dataSchema = ds.dataSchema;
    result.dataSchemaRequired = ds.dataSchemaRequired;
  }

  return result;
}

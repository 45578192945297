import * as O from "fp-ts/Option";
import * as Rx from "rxjs";
import { Client } from "ds";
import * as Stocks from "ds/Stocks";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { StockId } from "types/src/Stocks/Stock";
import { DateRange } from "types/src/date/DateRange";
import { Repository, RepositoryId } from "types/src/Repositories/Repository";
import { ISODate } from "types/src/date/ISODate";
import { InventoryItem } from "types/src/InventoryItems/InventoryItem";
import { GetGuardType } from "types/src/Utils";
import { strictGuard } from "utils/strictGuard";
import { isOneOf } from "utils/isOneOf";
import { getRepositories } from "ds/Repositories";
import { isNoEmptyArr } from "types/src/NoEmptyArr";
import { uniqBy } from "rambda";
import { SymmetricTuple } from "types/src/Tuple";
import { Epic } from "../../../../../../types/RootEpic";
import { ListingState } from "../../../../../../generic-states/Listing";
import { Loading } from "../../../../../../generic-states/Loading";

const prefix = "Ready:DataManager:Stocks:Listing" as const;

function createState() {
  const repositories = Loading.createState<
    Array<{ id: RepositoryId; name: string }>,
    string | undefined
  >(`${prefix}:repositories`, { equals: (a, b) => a === b });
  const state = ListingState.createState<
    StocksListing.Filters,
    StocksListing.Item,
    | "createdAt"
    | "updatedAt"
    | "quantity"
    | "incoming"
    | "outgoing"
    | "ownQuantity"
    | "ownIncoming"
    | "ownOutgoing",
    {
      repositories: GetGuardType<typeof repositories.isState>;
    }
  >(prefix, {
    defaultFilters: {},
  });

  const epic: Epic<
    Actions,
    ListingState.GetState<typeof state>,
    StocksListing.Deps
  > = (state$, deps) => {
    const main$ = state.epic(state$, {
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(Stocks.getStocks(client, getFetchVars(s))).pipe(
              Rx.map(
                Fp.flow(
                  E.map((r) => ({
                    items: r.items.map(
                      (i): StocksListing.Item => ({
                        id: i.id as StockId,
                        createdAt: i.createdAt,
                        quantity: i.quantity,
                        item: i.item,
                        repository: i.repository,
                        incomingStock: i.incomingStock,
                        outgoingStock: i.outgoingStock,
                        ownOutgoingStock: i.ownOutgoingStock,
                        ownIncomingStock: i.ownIncomingStock,
                        ownQuantity: i.ownQuantity,
                      }),
                    ),
                    total: r.totalCount,
                    pageInfo: r.pageInfo,
                  })),
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => Rx.of(E.left(ids)),
      getVisibleColumns: () => deps.getVisibleColumns("Stocks"),
      setVisibleColumns: (v) => deps.setVisibleColumns("Stocks", v),
    });

    const repositories$ = repositories.epic(
      state$.pipe(Rx.map((s) => s.payload.repositories)),
      {
        get: (q) =>
          Rx.combineLatest([
            deps.pyckAdminClient$,
            state$.pipe(
              Rx.map((s) => s.payload.filters.payload.fields.repository),
              Rx.distinctUntilChanged(
                (a, b) => a === b || a?.join(",") === b?.join(","),
              ),
            ),
          ]).pipe(
            Rx.switchMap(([client, ids]) => {
              const selected$ = Fp.pipe(
                ids,
                O.fromNullable,
                O.filter(isNoEmptyArr),
                O.map((ids) =>
                  Rx.from(
                    getRepositories(client, { where: { id: { in: ids } } }),
                  ).pipe(
                    Rx.map(
                      Fp.flow(
                        E.map((r) => r.items),
                        E.mapLeft(() => [] as Repository[]),
                        E.toUnion,
                      ),
                    ),
                  ),
                ),
                O.getOrElse(() => Rx.of([] as Repository[])),
              );

              const search$ = Rx.from(
                getRepositories(client, {
                  where: { name: { containsFold: q || undefined } },
                }),
              ).pipe(
                Rx.map(
                  Fp.flow(
                    E.map((r) => r.items),
                    E.mapLeft(() => [] as Repository[]),
                    E.toUnion,
                  ),
                ),
              );

              return Rx.forkJoin([selected$, search$]).pipe(
                Rx.map((vs) =>
                  vs.flat().map((i) => ({ id: i.id, name: i.name })),
                ),
                Rx.map(uniqBy((v) => v.id)),
                Rx.map(E.right),
              );
            }),
          ),
      },
    );

    return Rx.merge(main$, repositories$);
  };

  type State = GetGuardType<typeof state.isState>;
  type Exits = GetGuardType<typeof state.isExit>;
  type Actions =
    | GetGuardType<typeof state.isAction>
    | GetGuardType<typeof repositories.isAction>;

  const reducer = (s: State, a: Actions): E.Either<Exits, State> => {
    if (repositories.isAction(a)) {
      return Fp.pipe(
        repositories.reducer(s.payload.repositories, a),
        E.map(
          (repositories) =>
            ({ ...s, payload: { ...s.payload, repositories } }) as typeof s,
        ),
      );
    }

    return state.reducer(s, a);
  };

  return {
    ...state,
    isAction: strictGuard((a: Actions): a is Actions =>
      isOneOf([state.isAction<Actions>, repositories.isAction<Actions>])(a),
    ),
    init: () =>
      state.init({
        repositories: repositories.init(undefined),
      }),
    epic,
    reducer,
    subStates: {
      ...state.subStates,
      repositories,
    },
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): Stocks.GetStocksVars {
    const fields = s.payload.filters.payload.fields;
    const where: Stocks.GetStocksVars["where"] = {
      time: fields.active ?? true ? ISODate.fromDate(new Date()) : undefined,
      hasRepositoryWith: [
        {
          id: {
            in: fields.repository ?? undefined,
          },
        },
      ],
      and: [
        {
          createdAt: {
            gte: fields.createdAt?.[0],
            lte: fields.createdAt?.[1],
          },
          quantity: {
            gte: fields.quantity?.[0],
            lte: fields.quantity?.[1],
          },
          ownQuantity: {
            gte: fields.ownQuantity?.[0],
            lte: fields.ownQuantity?.[1],
          },
          incomingStock: {
            gte: fields.incomingStock?.[0],
            lte: fields.incomingStock?.[1],
          },
          ownIncomingStock: {
            gte: fields.ownIncomingStock?.[0],
            lte: fields.ownIncomingStock?.[1],
          },
          outgoingStock: {
            gte: fields.outgoingStock?.[0],
            lte: fields.outgoingStock?.[1],
          },
          ownOutgoingStock: {
            gte: fields.ownOutgoingStock?.[0],
            lte: fields.ownOutgoingStock?.[1],
          },
        },
      ],
      or: [
        {
          id: {
            eq: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.map(StockId.fromString),
              O.toUndefined,
            ),
          },
        },
        {
          hasItemWith: [
            {
              sku: {
                containsFold: fields.search ?? undefined,
              },
            },
          ],
        },
        {
          hasRepositoryWith: [
            {
              name: {
                containsFold: fields.search ?? undefined,
              },
            },
          ],
        },
      ],
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
}

export namespace StocksListing {
  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: (id: string) => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (id: string, v: Record<string, boolean>) => void;
  }

  export interface Item {
    id: StockId;
    createdAt: ISODate;
    quantity: number;
    item: InventoryItem;
    repository: Repository;
    incomingStock: number;
    outgoingStock: number;
    ownIncomingStock: number;
    ownOutgoingStock: number;
    ownQuantity: number;
  }

  export type Filters = Partial<{
    createdAt: DateRange;
    search: string;
    repository: RepositoryId[];
    active: boolean;
    quantity: SymmetricTuple<number | undefined>;
    incomingStock: SymmetricTuple<number | undefined>;
    outgoingStock: SymmetricTuple<number | undefined>;
    ownIncomingStock: SymmetricTuple<number | undefined>;
    ownOutgoingStock: SymmetricTuple<number | undefined>;
    ownQuantity: SymmetricTuple<number | undefined>;
  }>;

  export const instance = createState();

  export type State = GetGuardType<typeof instance.isState>;
  export type Actions = GetGuardType<typeof instance.isAction>;
  export type Exits = GetGuardType<typeof instance.isExit>;
}

import { Value } from "types/src/jsonSchema/value";
import {
  UiSchemaElement,
  UiSchemaElementPath,
  UiSchemaElementType,
  UiSchemaElementTypeHorizontalLayout as HorizontalLayout,
  UiSchemaElementTypeVerticalLayout as VerticalLayout,
} from "types/src/jsonSchema/uiSchema";
import * as R from "rambda";
import * as Fp from "fp-ts/function";
import { findElementPath } from "../uiSchema/findElementPath";
import { uiSchemaInsertAtPath } from "../uiSchema/insertAtPath";
import { uiSchemaIds } from "../uiSchema/ids";

export const controlGroupByIds = ({
  ids,
  makeParent,
  value,
}: {
  ids: string[];
  makeParent: (p: {
    existingIds: Record<string, unknown>;
  }) => VerticalLayout | HorizontalLayout;
  value: Value;
}): Value => {
  const paths = ids
    .map((id) =>
      findElementPath(
        { id, type: UiSchemaElementType.control },
        value.uiSchema,
      ),
    )
    .filter(Boolean)
    .map((path) => path as UiSchemaElementPath)
    .sort(sortByIndexAsc);

  if (!paths.length) {
    return value; // maybe log
  }

  const parent: ReturnType<typeof makeParent> = {
    ...makeParent({ existingIds: uiSchemaIds(value.uiSchema) }),
    elements: paths
      .map((path) => R.path<UiSchemaElement>(path, value.uiSchema))
      .filter(
        (element) => !!element && element.type === UiSchemaElementType.control,
      ),
  };

  return {
    ...value,
    uiSchema: Fp.pipe(
      value.uiSchema,
      (uiSchema) =>
        [...paths]
          .reverse() // delete last->first to maintain array indexes
          .reduce((uiSchema, path) => R.dissocPath(path, uiSchema), uiSchema),
      (uiSchema) =>
        uiSchemaInsertAtPath({
          uiPath: paths[0] as UiSchemaElementPath,
          element: parent,
          uiSchema,
          before: true,
        }),
    ),
  };
};

const sortByIndexAsc = (a: UiSchemaElementPath, b: UiSchemaElementPath) => {
  const aIndex = a[1] ?? 0;
  const bIndex = b[1] ?? 0;
  return aIndex < bIndex ? -1 : aIndex === bIndex ? 0 : 1;
};

import { BehaviorValue } from "rx-addons/BehaviorValue";
import {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import * as Rx from "rxjs";
import { Subscription } from "rxjs";

export function useBehaviorReduce<T, R>(
  o$: BehaviorValue<T> | Rx.BehaviorSubject<T>,
  reducer: (acc: R, v: T) => R,
  initial: (v: T) => R,
  eq?: (a: R, b: R) => boolean,
): R {
  const [, startTransition] = useTransition();
  const [value, setValue] = useState<R>(() => initial(o$.getValue()));

  const valueRef = useRef({ value, reducer });
  valueRef.current = { value, reducer };

  const ref = useRef<{
    o$: BehaviorValue<T> | Rx.BehaviorSubject<T>;
    subscription: Rx.Subscription;
  } | null>(null);

  if (ref.current?.o$ !== o$) {
    ref.current?.subscription.unsubscribe();
    ref.current = {
      o$,
      subscription: o$
        .asObservable()
        .pipe(
          Rx.map((v) => reducer(valueRef.current.value, v)),
          Rx.filter(
            (v) =>
              !(
                eq?.(v, valueRef.current.value) ?? v === valueRef.current.value
              ),
          ),
        )
        .subscribe((v) => {
          valueRef.current.value = v;
          startTransition(() => setValue(v));
        }),
    };
  }

  useEffect(() => () => ref.current?.subscription.unsubscribe(), []);

  return value;
}

export function useBehaviorValue<T>(
  behavior: BehaviorValue<T> | Rx.BehaviorSubject<T>,
): T {
  return useBehaviorReduce(
    behavior,
    (_, v) => v,
    (v) => v,
  );
}

export function useBehaviorValueRef<T>(
  value$: BehaviorValue<T> | Rx.BehaviorSubject<T>,
): BehaviorValueRef<T> {
  const valueRef = useRef(value$.value);
  const behaviorRef = useRef({
    value$,
    subscription: undefined as undefined | Subscription,
  });

  if (value$ !== behaviorRef.current.value$) {
    behaviorRef.current.value$ = value$;

    behaviorRef.current.subscription?.unsubscribe();
    behaviorRef.current.subscription = undefined;
  }

  if (!behaviorRef.current.subscription) {
    behaviorRef.current.subscription = behaviorRef.current.value$.subscribe(
      (value) => {
        valueRef.current = value;
      },
    );
  }

  useEffect(
    () => () => {
      behaviorRef.current.subscription?.unsubscribe();
      behaviorRef.current.subscription = undefined;
    },
    [],
  );

  return valueRef;
}
export type BehaviorValueRef<T> = Readonly<MutableRefObject<T>>;

// region useMatchBehavior
// region 1 guard
export function useMatchBehavior<
  T extends U,
  K1 extends string,
  P1 extends T,
  R1,
  U = P1,
>(
  o$: BehaviorValue<T>,
  matches: [[K1, (v: T) => v is P1, (v: P1) => R1]],
): { match: K1; behavior: BehaviorValue<R1> };
// endregion

// region 2 guards
export function useMatchBehavior<
  T extends U,
  K1 extends string,
  K2 extends string,
  P1 extends T,
  P2 extends T,
  R1,
  R2,
  U = P1 | P2,
>(
  o$: BehaviorValue<T>,
  matches: [
    [K1, (v: T) => v is P1, (v: P1) => R1],
    [K2, (v: T) => v is P2, (v: P2) => R2],
  ],
):
  | { match: K1; behavior: BehaviorValue<R1> }
  | { match: K2; behavior: BehaviorValue<R2> };
// endregion

// region 3 guards
export function useMatchBehavior<
  T extends U,
  K1 extends string,
  K2 extends string,
  K3 extends string,
  P1 extends T,
  P2 extends T,
  P3 extends T,
  R1,
  R2,
  R3,
  U = P1 | P2 | P3,
>(
  o$: BehaviorValue<T>,
  matches: [
    [K1, (v: T) => v is P1, (v: P1) => R1],
    [K2, (v: T) => v is P2, (v: P2) => R2],
    [K3, (v: T) => v is P3, (v: P3) => R3],
  ],
):
  | { match: K1; behavior: BehaviorValue<R1> }
  | { match: K2; behavior: BehaviorValue<R2> }
  | { match: K3; behavior: BehaviorValue<R3> };
// endregion

// region 4 guards
export function useMatchBehavior<
  T extends U,
  K1 extends string,
  K2 extends string,
  K3 extends string,
  K4 extends string,
  P1 extends T,
  P2 extends T,
  P3 extends T,
  P4 extends T,
  R1,
  R2,
  R3,
  R4,
  U = P1 | P2 | P3 | P4,
>(
  o$: BehaviorValue<T>,
  matches: [
    [K1, (v: T) => v is P1, (v: P1) => R1],
    [K2, (v: T) => v is P2, (v: P2) => R2],
    [K3, (v: T) => v is P3, (v: P3) => R3],
    [K4, (v: T) => v is P4, (v: P4) => R4],
  ],
):
  | { match: K1; behavior: BehaviorValue<R1> }
  | { match: K2; behavior: BehaviorValue<R2> }
  | { match: K3; behavior: BehaviorValue<R3> }
  | { match: K4; behavior: BehaviorValue<R4> };
// endregion

// region 5 guards
export function useMatchBehavior<
  T extends U,
  K1 extends string,
  K2 extends string,
  K3 extends string,
  K4 extends string,
  K5 extends string,
  P1 extends T,
  P2 extends T,
  P3 extends T,
  P4 extends T,
  P5 extends T,
  R1,
  R2,
  R3,
  R4,
  R5,
  U = P1 | P2 | P3 | P4 | P5,
>(
  o$: BehaviorValue<T>,
  matches: [
    [K1, (v: T) => v is P1, (v: P1) => R1],
    [K2, (v: T) => v is P2, (v: P2) => R2],
    [K3, (v: T) => v is P3, (v: P3) => R3],
    [K4, (v: T) => v is P4, (v: P4) => R4],
    [K5, (v: T) => v is P5, (v: P5) => R5],
  ],
):
  | { match: K1; behavior: BehaviorValue<R1> }
  | { match: K2; behavior: BehaviorValue<R2> }
  | { match: K3; behavior: BehaviorValue<R3> }
  | { match: K4; behavior: BehaviorValue<R4> }
  | { match: K5; behavior: BehaviorValue<R5> };
// endregion

// region 6 guards
export function useMatchBehavior<
  T extends U,
  K1 extends string,
  K2 extends string,
  K3 extends string,
  K4 extends string,
  K5 extends string,
  K6 extends string,
  P1 extends T,
  P2 extends T,
  P3 extends T,
  P4 extends T,
  P5 extends T,
  P6 extends T,
  R1,
  R2,
  R3,
  R4,
  R5,
  R6,
  U = P1 | P2 | P3 | P4 | P5 | P6,
>(
  o$: BehaviorValue<T>,
  matches: [
    [K1, (v: T) => v is P1, (v: P1) => R1],
    [K2, (v: T) => v is P2, (v: P2) => R2],
    [K3, (v: T) => v is P3, (v: P3) => R3],
    [K4, (v: T) => v is P4, (v: P4) => R4],
    [K5, (v: T) => v is P5, (v: P5) => R5],
    [K6, (v: T) => v is P6, (v: P6) => R6],
  ],
):
  | { match: K1; behavior: BehaviorValue<R1> }
  | { match: K2; behavior: BehaviorValue<R2> }
  | { match: K3; behavior: BehaviorValue<R3> }
  | { match: K4; behavior: BehaviorValue<R4> }
  | { match: K5; behavior: BehaviorValue<R5> }
  | { match: K6; behavior: BehaviorValue<R6> };
// endregion

// region n guards
export function useMatchBehavior<
  T extends U,
  K extends string,
  P extends T,
  R,
  U = P,
>(
  o$: BehaviorValue<T>,
  matches: Array<[K, (v: T) => v is P, (v: P) => R]>,
): { match: K; behavior: BehaviorValue<R> } {
  const ref = useRef<{
    o$: BehaviorValue<T>;
    subscription: Rx.Subscription;
  } | null>(null);

  const matchesRef = useRef({ matches });
  matchesRef.current = { matches };

  const getMatch = (v: T): K => {
    return matches.find(([, p]) => p(v))?.[0] as K;
  };

  const [match, setMatch] = useState(() => getMatch(o$.getValue()));
  const matchRef = useRef(match);
  matchRef.current = match;

  const new$ = useMemo(() => {
    const [, fn, _map] = matchesRef.current.matches.find(
      ([k]) => k === match,
    ) as [K, (v: T) => v is P, (v: P) => R];
    return new BehaviorValue(
      _map(o$.getValue() as P),
      o$.asObservable().pipe(Rx.filter(fn), Rx.map(_map)),
    );
  }, [match]);

  if (ref.current?.o$ !== o$) {
    ref.current?.subscription.unsubscribe();
    ref.current = {
      o$,
      subscription: o$
        .asObservable()
        .pipe(
          Rx.map(getMatch),
          Rx.distinctUntilChanged(),
          Rx.filter((v) => v !== matchRef.current),
        )
        .subscribe(setMatch),
    };
  }

  useEffect(() => () => ref.current?.subscription.unsubscribe(), []);

  return {
    match: match,
    behavior: new$,
  } as { match: K; behavior: BehaviorValue<R> };
}
// endregion
// endregion

import { Cursor } from "types";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { StockId } from "types/src/Stocks/Stock";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryId } from "types/src/Repositories/Repository";
import { omitEmpties } from "utils/value";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { GetStocksQueryVariables, StockOrderField } from "../generated/graphql";
import { toApiOrderDirection } from "../transformers/OrderDirection";
import * as InventoryItems from "./InventoryItems";
import * as Repositories from "./Repositories";
import { Where } from "./Where";

export type WhereSchema = Where<{
  createdAt: Where.Ord<ISODate>;
  updatedAt: Where.Ord<ISODate>;
  hasItem: Where.Value<boolean>;
  hasItemWith: Where.WithWhereList<InventoryItems.WhereSchema>;
  hasRepository: Where.Value<boolean>;
  hasRepositoryWith: Where.WithWhereList<Repositories.WhereSchema>;
  id: Where.EqIn<StockId>;
  incomingStock: Where.Ord<number>;
  outgoingStock: Where.Ord<number>;
  quantity: Where.Ord<number>;
  ownIncomingStock: Where.Ord<number>;
  ownOutgoingStock: Where.Ord<number>;
  ownQuantity: Where.Ord<number>;
  item: Where.EqIn<InventoryItemId>;
  movement: Where.EqNil<ItemMovementId>;
  repository: Where.EqNil<RepositoryId>;
  time: Where.Value<ISODate>;
}>;

export const whereSchema = Where.create<WhereSchema>({
  createdAt: Where.ord(),
  updatedAt: Where.ord(),
  hasItem: Where.value(),
  hasItemWith: Where.withWhereList(
    (): InventoryItems.WhereSchema => InventoryItems.whereSchema,
  ),
  hasRepository: Where.value(),
  hasRepositoryWith: Where.withWhereList(() => Repositories.whereSchema),
  id: Where.eqIn(),
  incomingStock: Where.ord(),
  outgoingStock: Where.ord(),
  quantity: Where.ord(),
  ownIncomingStock: Where.ord(),
  ownOutgoingStock: Where.ord(),
  ownQuantity: Where.ord(),
  item: Where.eqIn(),
  movement: Where.eqNil("movementID"),
  repository: Where.eqNil("repositoryID"),
  time: Where.value(),
});

export type GetStocksVarsWhere = Where.GetType<WhereSchema>;

export interface GetStocksVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: GetStocksVarsWhere;
  orderBy?: {
    by:
      | "createdAt"
      | "updatedAt"
      | "incoming"
      | "outgoing"
      | "ownIncoming"
      | "ownOutgoing"
      | "ownQuantity"
      | "quantity";
    direction: "asc" | "desc";
  };
}

export function getStocksVarsToApiVars(
  vars: GetStocksVars,
): GetStocksQueryVariables {
  return (
    omitEmpties({
      first: vars.first,
      last: vars.last,
      after: vars.after,
      before: vars.before,
      where: omitEmpties(
        pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toNullable,
        ),
      ),
      orderBy: pipe(
        vars.orderBy,
        O.fromNullable,
        O.map((v) => ({
          field: pipe(
            v.by,
            (v) =>
              ({
                createdAt: StockOrderField.CreatedAt,
                updatedAt: StockOrderField.UpdatedAt,
                incoming: StockOrderField.IncomingStock,
                outgoing: StockOrderField.OutgoingStock,
                ownIncoming: StockOrderField.OwnIncomingStock,
                ownOutgoing: StockOrderField.OwnOutgoingStock,
                ownQuantity: StockOrderField.OwnQuantity,
                quantity: StockOrderField.Quantity,
              })[v],
          ),
          direction: pipe(v.direction, toApiOrderDirection),
        })),
        O.toUndefined,
      ),
    }) ?? {}
  );
}

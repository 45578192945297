import { UiSchema, UiSchemaElement } from "types/src/jsonSchema/uiSchema";

type Ids = Record<string, true>;

export const uiSchemaIds = (schema: UiSchema): Ids => {
  const findRecursive = (elements: UiSchemaElement[]): Ids =>
    elements.reduce(
      (ids, element) => ({
        ...ids,
        ...(element.options?.id ? { [element.options.id]: true } : {}),
        ...("elements" in element ? findRecursive(element.elements) : {}),
      }),
      {} as Ids,
    );

  return findRecursive([schema]);
};

// fixme: maybe move to `packages/react-rx/`

import { BehaviorValue } from "rx-addons/BehaviorValue";
import * as O from "fp-ts/Option";
import { useEffect, useRef, useState, useTransition } from "react";
import { BehaviorSubject, Subscription } from "rxjs";

/**
 * Emits only Some
 * Is undefined until first Some arrives
 */
export const useBehaviorOptionValue = <Value>({
  option$,
  /**
   * Use `true` with caution,
   *  it may be smother (no rerender "jumps") but may get wrong/old data.
   * fixme: maybe remove this prop
   */
  keepPrevValueOnNone = false,
}: {
  option$: BehaviorValue<O.Option<Value>>;
  keepPrevValueOnNone?: boolean;
}): undefined | BehaviorValue<Value> => {
  const value$ = useRef<{
    subject: BehaviorSubject<Value>;
    value: BehaviorValue<Value>;
  }>();
  const behaviorRef = useRef({
    option$,
    subscription: undefined as undefined | Subscription,
  });

  const [, startTransition] = useTransition();
  const [, rerender] = useState(0);

  if (option$ !== behaviorRef.current.option$) {
    behaviorRef.current.option$ = option$;

    behaviorRef.current.subscription?.unsubscribe();
    behaviorRef.current.subscription = undefined;
  }

  if (!behaviorRef.current.subscription) {
    behaviorRef.current.subscription = behaviorRef.current.option$.subscribe(
      (o) => {
        if (O.isNone(o)) {
          if (keepPrevValueOnNone) {
            return;
          }

          value$.current = undefined;
          startTransition(() => rerender((v) => v + 1));
          return;
        }

        if (value$.current) {
          return value$.current.subject.next(o.value);
        }

        const subject = new BehaviorSubject(o.value),
          value = BehaviorValue.fromBehaviorSubject(subject);

        value$.current = { subject, value };
        startTransition(() => rerender((v) => v + 1));
      },
    );
  }

  useEffect(
    () => () => {
      behaviorRef.current.subscription?.unsubscribe();
      behaviorRef.current.subscription = undefined;
    },
    [],
  );

  return value$.current?.value;
};

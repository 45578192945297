import React from "react";
import {
  HorizontalLayout,
  JsonFormsRendererRegistryEntry,
  LayoutProps,
  rankWith,
  uiTypeIs,
} from "@jsonforms/core";
import { withJsonFormsLayoutProps } from "@jsonforms/react";

import { Layout } from "../components/Layout";

export const HorizontalLayoutRenderer = ({
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  cells,
}: LayoutProps) => {
  const layout = uischema as HorizontalLayout;
  const childProps: Layout.Props = {
    elements: layout.elements,
    schema,
    path,
    enabled,
    direction: "row",
    visible,
  };

  return <Layout {...childProps} renderers={renderers} cells={cells} />;
};

export const entry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(1, uiTypeIs("HorizontalLayout")),
  renderer: withJsonFormsLayoutProps(HorizontalLayoutRenderer),
};

import { gql } from "@apollo/client";
import { Supplier, SupplierId } from "types/src/Supplier/Supplier";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Cursor } from "types";
import { isT } from "fp-utilities";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { ISODate } from "types/src/date/ISODate";
import { DataTypeId } from "types/src/DataType/DataType";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import { supplierFragmentToSupplier } from "../../transformers/Suppliers";
import { Client } from "../../index";
import {
  GetSupplierQuery,
  GetSupplierQueryVariables,
  GetSuppliersQuery,
  GetSuppliersQueryVariables,
  SupplierOrderField,
} from "../../generated/graphql";
import { supplierFragment } from "../../fragments/Supplier";
import {
  getPaginatedQueryResult,
  PaginatedQueryResponse,
  QueryResponse,
} from "../../type/QueryResponse";
import { notFoundError } from "../../type/DsError";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";
import { Where } from "../../type/Where";

type WhereSchema = Where<{
  data: Where.Map;
  createdAt: Where.Ord<ISODate>;
  updatedAt: Where.Ord<ISODate>;
  dataType: Where.EqNil<DataTypeId>;
  id: Where.EqIn<SupplierId>;
}>;

const whereSchema = Where.create<WhereSchema>({
  data: Where.map("Data"),
  createdAt: Where.ord(),
  updatedAt: Where.ord(),
  dataType: Where.eqNil("dataTypeID"),
  id: Where.eqIn(),
});

export interface GetSuppliersVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: Where.GetType<WhereSchema>;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
}
export function getSuppliers(
  client: Client,
  vars: GetSuppliersVars,
): Promise<PaginatedQueryResponse<Supplier>> {
  const query = gql`
    ${pageInfoFragment}
    ${supplierFragment}

    query GetSuppliers(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: SupplierWhereInput
      $orderBy: SupplierOrder
    ) {
      suppliers(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...SupplierFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetSuppliersQuery, GetSuppliersQueryVariables>({
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: Fp.pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toUndefined,
        ),
        orderBy: Fp.pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: SupplierOrderField.CreatedAt,
              updatedAt: SupplierOrderField.UpdatedAt,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    })
    .then(
      Fp.flow(
        E.map((r) => r.suppliers),
        E.map(getPaginatedQueryResult(supplierFragmentToSupplier)),
      ),
    );
}

export type GetSupplierQueryResult = Supplier;
export function getSupplier(
  client: Client,
  id: SupplierId,
): Promise<QueryResponse<GetSupplierQueryResult>> {
  const query = gql`
    ${supplierFragment}

    query GetSupplier($id: ID!) {
      suppliers(where: { id: $id }, first: 1) {
        edges {
          node {
            ...SupplierFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetSupplierQuery, GetSupplierQueryVariables>({
      query,
      variables: {
        id,
      },
    })
    .then(
      Fp.flow(
        E.map((r) => r.suppliers.edges?.[0]?.node),
        E.filterOrElseW(isT, notFoundError),
        E.map(supplierFragmentToSupplier),
      ),
    );
}

import { Box } from "@mui/material";
import React from "react";
import { Sxs } from "../../../types/styles";
import { ActionsBarSetup } from "./ActionsBarSetup";

export namespace Layout {
  export type Props = {
    left: React.ReactElement;
    right: React.ReactElement;
    /*selected items*/ actionsBar: React.ReactElement;
  };
}

export const Layout = ({ left, right, actionsBar }: Layout.Props) => (
  <>
    <Box sx={sxs.root}>
      <Box sx={sxs.left}>{left}</Box>
      <Box sx={sxs.right}>{right}</Box>
    </Box>
    <ActionsBarSetup>{actionsBar}</ActionsBarSetup>
  </>
);

const sxs = {
  root: (theme) => ({
    display: "flex",
    flex: 1,
    "& > div": {
      width: "50%",
    },

    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2,

    // space for ActionsBar to not cover the last element
    marginBottom: theme.spacing(25),
  }),
  left: (theme) => ({
    padding: 6,
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  }),
  right: (theme) => ({
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    backgroundColor: theme.palette.surface.primary,
  }),
} satisfies Sxs;

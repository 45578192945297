import { silentUnreachableError } from "utils/exceptions";
import { DataTypeId } from "types/src/DataType/DataType";
import { CustomerId } from "types/src/Customers/Customer";
import { SupplierId } from "types/src/Supplier/Supplier";
import { RepositoryId } from "types/src/Repositories/Repository";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { OrgId, UserAccessToken, UserId } from "types";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { RepositoryMovementId } from "types/src/RepositoryMovements/RepositoryMovement";
import { strictGuard } from "utils/strictGuard";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { CollectionId } from "types/src/Collections/Collection";
import { InboundId } from "types/src/Inbounds/Inbound";
import { ItemSetId } from "types/src/ItemSets/ItemSet";
import * as DataManager from "../states/DataManager";
import * as BuilderPreview from "../states/BuilderPreview";
import * as BPMNPreview from "../states/BPMNPreview";
import * as ZitadelPreview from "../states/ZitadelPreview";
import * as GraphqlPlayground from "../states/GraphqlPlayground";
import * as Temporal from "../states/Temporal";
import { UserSettings } from "../../../types/UserSettings";
import { DataTypesListing } from "../states/DataTypesListing";
import { DataTypeEdit } from "../states/DataTypeEdit";
import { DataTypeCreate } from "../states/DataTypeCreate";

export type Actions =
  | GraphqlPlayground.Action
  | Temporal.Action
  | DataTypesListing.Actions
  | DataTypeEdit.Actions
  | DataTypeCreate.Actions
  | DataManager.Actions
  | BuilderPreview.Actions
  | BPMNPreview.Actions
  | ZitadelPreview.Actions
  | SignOut
  | OrganizationsActive
  | OrganizationId
  | SignedOut
  | Reauthorize
  | ReauthorizeFail
  | ReauthorizeSuccess
  | SetUserSettings
  | Goto;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (a.type === "Ready:SignOut") return true;
  if (a.type === "Ready:SignedOut") return true;
  if (a.type === "Ready:Reauthorize") return true;
  if (a.type === "Ready:ReauthorizeFail") return true;
  if (a.type === "Ready:ReauthorizeSuccess") return true;
  if (a.type === "Ready:SetUserSettings") return true;
  if (a.type === "Ready:ChangeActive:Organizations") return true;
  if (a.type === "Ready:ChangeOrgId:Organizations") return true;
  if (isGoto(a)) return true;
  if (DataManager.isActions(a)) return true;
  if (BuilderPreview.isActions(a)) return true;
  if (BPMNPreview.isActions(a)) return true;
  if (ZitadelPreview.isActions(a)) return true;
  if (DataTypesListing.instance.isAction(a)) return true;
  if (DataTypeEdit.instance.isAction(a)) return true;
  if (DataTypeCreate.instance.isAction(a)) return true;
  if (GraphqlPlayground.instance.isAction(a)) return true;
  if (Temporal.instance.isAction(a)) return true;

  silentUnreachableError(a);
  return false;
});

// region GoTo
export type Goto =
  | GoToTemporal
  | GoToGraphQlPlayground
  | GoToDataTypesCreate
  | GoToDataTypesEdit
  | GoToCollections
  | GoToCollectionsByDataType
  | GoToCollectionsView
  | GoToCustomers
  | GoToCustomersByDataType
  | GoToCustomersCreate
  | GoToCustomersEdit
  | GoToInbounds
  | GoToInboundsByDataType
  | GoToInboundsCreate
  | GoToInboundsEdit
  | GoToItemSets
  | GoToItemSetsByDataType
  | GoToItemSetsCreate
  | GoToItemSetsEdit
  | GoToSuppliers
  | GoToSuppliersByDataType
  | GoToSuppliersCreate
  | GoToSuppliersEdit
  | GoToWarehouseBuilder
  | GoToBPMNBuilder
  | GoToZitadelApp
  | GoToRepositories
  | GoToRepositoriesByDataType
  | GoToRepositoriesCreate
  | GoToRepositoriesEdit
  | GoToInventoryItems
  | GoToInventoryItemsByDataType
  | GoToInventoryItemsCreate
  | GoToInventoryItemsEdit
  | GoToItemMovements
  | GoToItemMovementsByDataType
  | GoToItemMovementsCreate
  | GoToItemMovementsEdit
  | GoToRepositoryMovements
  | GoToRepositoryMovementsByDataType
  | GoToRepositoryMovementsCreate
  | GoToRepositoryMovementsEdit
  | GoToPickingOrders
  | GoToPickingOrdersByDataType
  | GoToPickingOrdersCreate
  | GoToPickingOrdersEdit
  | GoToStocks
  | GoToDatatypes;

export function isGoto(action: Actions): action is Goto {
  const a = action as Goto;
  switch (a.type) {
    case "Ready:GoTo:Temporal":
    case "Ready:GoTo:GraphQlPlayground":
    case "Ready:GoTo:DataTypes:Create":
    case "Ready:GoTo:DataTypes:Edit":
    case "Ready:GoTo:Collections":
    case "Ready:GoTo:Collections:DataType":
    case "Ready:GoTo:Collections:View":
    case "Ready:GoTo:Customers":
    case "Ready:GoTo:Customers:DataType":
    case "Ready:GoTo:Customers:Create":
    case "Ready:GoTo:Customers:Edit":
    case "Ready:GoTo:Inbounds":
    case "Ready:GoTo:Inbounds:DataType":
    case "Ready:GoTo:Inbounds:Create":
    case "Ready:GoTo:Inbounds:Edit":
    case "Ready:GoTo:ItemSets":
    case "Ready:GoTo:ItemSets:DataType":
    case "Ready:GoTo:ItemSets:Create":
    case "Ready:GoTo:ItemSets:Edit":
    case "Ready:GoTo:Suppliers":
    case "Ready:GoTo:Suppliers:Create":
    case "Ready:GoTo:Suppliers:DataType":
    case "Ready:GoTo:Suppliers:Edit":
    case "Ready:GoTo:WarehouseBuilder":
    case "Ready:GoTo:BPMNBuilder":
    case "Ready:GoTo:ZitadelApp":
    case "Ready:GoTo:Repositories":
    case "Ready:GoTo:Repositories:Create":
    case "Ready:GoTo:Repositories:DataType":
    case "Ready:GoTo:Repositories:Edit":
    case "Ready:GoTo:InventoryItems":
    case "Ready:GoTo:InventoryItems:Create":
    case "Ready:GoTo:InventoryItems:DataType":
    case "Ready:GoTo:InventoryItems:Edit":
    case "Ready:GoTo:ItemMovements":
    case "Ready:GoTo:ItemMovements:Create":
    case "Ready:GoTo:ItemMovements:DataType":
    case "Ready:GoTo:ItemMovements:Edit":
    case "Ready:GoTo:RepositoryMovements":
    case "Ready:GoTo:RepositoryMovements:Create":
    case "Ready:GoTo:RepositoryMovements:DataType":
    case "Ready:GoTo:RepositoryMovements:Edit":
    case "Ready:GoTo:PickingOrders":
    case "Ready:GoTo:PickingOrders:Create":
    case "Ready:GoTo:PickingOrders:DataType":
    case "Ready:GoTo:PickingOrders:Edit":
    case "Ready:GoTo:Stocks:Listing":
    case "Ready:GoTo:Datatypes:Listing":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
}
// endregion

// region GoToGraphQlPlayground
export interface GoToGraphQlPlayground {
  type: "Ready:GoTo:GraphQlPlayground";
}

export const goToGraphQlPlayground = (): GoToGraphQlPlayground => ({
  type: "Ready:GoTo:GraphQlPlayground",
});
// endregion

// region GoToTemporal
export interface GoToTemporal {
  type: "Ready:GoTo:Temporal";
}

export const goToTemporal = (): GoToTemporal => ({
  type: "Ready:GoTo:Temporal",
});
// endregion

// region GoToDataTypesCreate
export interface GoToDataTypesCreate {
  type: "Ready:GoTo:DataTypes:Create";
}

export const goToDataTypesCreate = (): GoToDataTypesCreate => ({
  type: "Ready:GoTo:DataTypes:Create",
});
// endregion

// region GoToDataTypesEdit
export interface GoToDataTypesEdit {
  type: "Ready:GoTo:DataTypes:Edit";
  payload: DataTypeId;
}

export const goToDataTypesEdit = (
  payload: GoToDataTypesEdit["payload"],
): GoToDataTypesEdit => ({
  type: "Ready:GoTo:DataTypes:Edit",
  payload,
});
// endregion

// region GoToCollections
export interface GoToCollections {
  type: "Ready:GoTo:Collections";
}

export const goToCollections = (): GoToCollections => ({
  type: "Ready:GoTo:Collections",
});
// endregion

// region GoToCollectionsByDataType
export interface GoToCollectionsByDataType {
  type: "Ready:GoTo:Collections:DataType";
  payload: DataTypeId;
}

export const goToCollectionsByDataType = (
  payload: GoToCollectionsByDataType["payload"],
): GoToCollectionsByDataType => ({
  type: "Ready:GoTo:Collections:DataType",
  payload,
});
// endregion

// region GoToCollectionsView
export interface GoToCollectionsView {
  type: "Ready:GoTo:Collections:View";
  payload: CollectionId;
}

export const goToCollectionsView = (
  payload: GoToCollectionsView["payload"],
): GoToCollectionsView => ({
  type: "Ready:GoTo:Collections:View",
  payload,
});
// endregion

// region GoToCustomers
export interface GoToCustomers {
  type: "Ready:GoTo:Customers";
}

export const goToCustomers = (): GoToCustomers => ({
  type: "Ready:GoTo:Customers",
});
// endregion

// region GoToCustomersByDataType
export interface GoToCustomersByDataType {
  type: "Ready:GoTo:Customers:DataType";
  payload: DataTypeId;
}

export const goToCustomersByDataType = (
  payload: GoToCustomersByDataType["payload"],
): GoToCustomersByDataType => ({
  type: "Ready:GoTo:Customers:DataType",
  payload,
});
// endregion

// region GoToCustomersCreate
export interface GoToCustomersCreate {
  type: "Ready:GoTo:Customers:Create";
  payload: DataTypeId;
}

export const goToCustomersCreate = (
  payload: GoToCustomersCreate["payload"],
): GoToCustomersCreate => ({
  type: "Ready:GoTo:Customers:Create",
  payload,
});
// endregion

// region GoToCustomersEdit
export interface GoToCustomersEdit {
  type: "Ready:GoTo:Customers:Edit";
  payload: CustomerId;
}

export const goToCustomersEdit = (
  payload: GoToCustomersEdit["payload"],
): GoToCustomersEdit => ({
  type: "Ready:GoTo:Customers:Edit",
  payload,
});
// endregion

// region GoToInbounds
export interface GoToInbounds {
  type: "Ready:GoTo:Inbounds";
}

export const goToInbounds = (): GoToInbounds => ({
  type: "Ready:GoTo:Inbounds",
});
// endregion

// region GoToInboundsByDataType
export interface GoToInboundsByDataType {
  type: "Ready:GoTo:Inbounds:DataType";
  payload: DataTypeId;
}

export const goToInboundsByDataType = (
  payload: GoToInboundsByDataType["payload"],
): GoToInboundsByDataType => ({
  type: "Ready:GoTo:Inbounds:DataType",
  payload,
});
// endregion

// region GoToInboundsCreate
export interface GoToInboundsCreate {
  type: "Ready:GoTo:Inbounds:Create";
  payload: DataTypeId;
}

export const goToInboundsCreate = (
  payload: GoToInboundsCreate["payload"],
): GoToInboundsCreate => ({
  type: "Ready:GoTo:Inbounds:Create",
  payload,
});
// endregion

// region GoToInboundsEdit
export interface GoToInboundsEdit {
  type: "Ready:GoTo:Inbounds:Edit";
  payload: InboundId;
}

export const goToInboundsEdit = (
  payload: GoToInboundsEdit["payload"],
): GoToInboundsEdit => ({
  type: "Ready:GoTo:Inbounds:Edit",
  payload,
});
// endregion

// region GoToItemSets
export interface GoToItemSets {
  type: "Ready:GoTo:ItemSets";
}

export const goToItemSets = (): GoToItemSets => ({
  type: "Ready:GoTo:ItemSets",
});
// endregion

// region GoToItemSetsByDataType
export interface GoToItemSetsByDataType {
  type: "Ready:GoTo:ItemSets:DataType";
  payload: DataTypeId;
}

export const goToItemSetsByDataType = (
  payload: GoToItemSetsByDataType["payload"],
): GoToItemSetsByDataType => ({
  type: "Ready:GoTo:ItemSets:DataType",
  payload,
});
// endregion

// region GoToItemSetsCreate
export interface GoToItemSetsCreate {
  type: "Ready:GoTo:ItemSets:Create";
  payload: DataTypeId;
}

export const goToItemSetsCreate = (
  payload: GoToItemSetsCreate["payload"],
): GoToItemSetsCreate => ({
  type: "Ready:GoTo:ItemSets:Create",
  payload,
});
// endregion

// region GoToItemSetsEdit
export interface GoToItemSetsEdit {
  type: "Ready:GoTo:ItemSets:Edit";
  payload: ItemSetId;
}

export const goToItemSetsEdit = (
  payload: GoToItemSetsEdit["payload"],
): GoToItemSetsEdit => ({
  type: "Ready:GoTo:ItemSets:Edit",
  payload,
});
// endregion

// region GoToSuppliers
export interface GoToSuppliers {
  type: "Ready:GoTo:Suppliers";
}

export const goToSuppliers = (): GoToSuppliers => ({
  type: "Ready:GoTo:Suppliers",
});
// endregion

// region GoToSuppliersByDataType
export interface GoToSuppliersByDataType {
  type: "Ready:GoTo:Suppliers:DataType";
  payload: DataTypeId;
}

export const goToSuppliersByDataType = (
  payload: GoToSuppliersByDataType["payload"],
): GoToSuppliersByDataType => ({
  type: "Ready:GoTo:Suppliers:DataType",
  payload,
});
// endregion

// region GoToSuppliersCreate
export interface GoToSuppliersCreate {
  type: "Ready:GoTo:Suppliers:Create";
  payload: DataTypeId;
}

export const goToSuppliersCreate = (
  payload: GoToSuppliersCreate["payload"],
): GoToSuppliersCreate => ({
  type: "Ready:GoTo:Suppliers:Create",
  payload,
});
// endregion

// region GoToSuppliersEdit
export interface GoToSuppliersEdit {
  type: "Ready:GoTo:Suppliers:Edit";
  payload: SupplierId;
}

export const goToSuppliersEdit = (
  payload: GoToSuppliersEdit["payload"],
): GoToSuppliersEdit => ({
  type: "Ready:GoTo:Suppliers:Edit",
  payload,
});
// endregion

// region GoToWarehouseBuilder
export interface GoToWarehouseBuilder {
  type: "Ready:GoTo:WarehouseBuilder";
}

export const goToWarehouseBuilder = (): GoToWarehouseBuilder => ({
  type: "Ready:GoTo:WarehouseBuilder",
});
// endregion

// region GoToBPMNBuilder
export interface GoToBPMNBuilder {
  type: "Ready:GoTo:BPMNBuilder";
}

export const goToBPMNBuilder = (): GoToBPMNBuilder => ({
  type: "Ready:GoTo:BPMNBuilder",
});
// endregion

// region GoToZitadelApp
export interface GoToZitadelApp {
  type: "Ready:GoTo:ZitadelApp";
}

export const goToZitadelApp = (): GoToZitadelApp => ({
  type: "Ready:GoTo:ZitadelApp",
});
// endregion

// region GoToRepositories
export interface GoToRepositories {
  type: "Ready:GoTo:Repositories";
}

export const goToRepositories = (): GoToRepositories => ({
  type: "Ready:GoTo:Repositories",
});
// endregion

// region GoToRepositoriesByDataType
export interface GoToRepositoriesByDataType {
  type: "Ready:GoTo:Repositories:DataType";
  payload: DataTypeId;
}

export const goToRepositoriesByDataType = (
  payload: GoToRepositoriesByDataType["payload"],
): GoToRepositoriesByDataType => ({
  type: "Ready:GoTo:Repositories:DataType",
  payload,
});
// endregion

// region GoToRepositoriesCreate
export interface GoToRepositoriesCreate {
  type: "Ready:GoTo:Repositories:Create";
  payload: DataTypeId;
}

export const goToRepositoriesCreate = (
  payload: GoToRepositoriesCreate["payload"],
): GoToRepositoriesCreate => ({
  type: "Ready:GoTo:Repositories:Create",
  payload,
});
// endregion

// region GoToRepositoriesEdit
export interface GoToRepositoriesEdit {
  type: "Ready:GoTo:Repositories:Edit";
  payload: RepositoryId;
}

export const goToRepositoriesEdit = (
  payload: GoToRepositoriesEdit["payload"],
): GoToRepositoriesEdit => ({
  type: "Ready:GoTo:Repositories:Edit",
  payload,
});
// endregion

// region GoToInventoryItems
export interface GoToInventoryItems {
  type: "Ready:GoTo:InventoryItems";
}

export const goToInventoryItems = (): GoToInventoryItems => ({
  type: "Ready:GoTo:InventoryItems",
});
// endregion

// region GoToInventoryItemsByDataType
export interface GoToInventoryItemsByDataType {
  type: "Ready:GoTo:InventoryItems:DataType";
  payload: DataTypeId;
}

export const goToInventoryItemsByDataType = (
  payload: GoToInventoryItemsByDataType["payload"],
): GoToInventoryItemsByDataType => ({
  type: "Ready:GoTo:InventoryItems:DataType",
  payload,
});
// endregion

// region GoToInventoryItemsCreate
export interface GoToInventoryItemsCreate {
  type: "Ready:GoTo:InventoryItems:Create";
  payload: DataTypeId;
}

export const goToInventoryItemsCreate = (
  payload: GoToInventoryItemsCreate["payload"],
): GoToInventoryItemsCreate => ({
  type: "Ready:GoTo:InventoryItems:Create",
  payload,
});
// endregion

// region GoToInventoryItemsEdit
export interface GoToInventoryItemsEdit {
  type: "Ready:GoTo:InventoryItems:Edit";
  payload: InventoryItemId;
}

export const goToInventoryItemsEdit = (
  payload: GoToInventoryItemsEdit["payload"],
): GoToInventoryItemsEdit => ({
  type: "Ready:GoTo:InventoryItems:Edit",
  payload,
});
// endregion

// region GoToItemMovements
export interface GoToItemMovements {
  type: "Ready:GoTo:ItemMovements";
}

export const goToItemMovements = (): GoToItemMovements => ({
  type: "Ready:GoTo:ItemMovements",
});
// endregion

// region GoToItemMovementsByDataType
export interface GoToItemMovementsByDataType {
  type: "Ready:GoTo:ItemMovements:DataType";
  payload: DataTypeId;
}

export const goToItemMovementsByDataType = (
  payload: GoToItemMovementsByDataType["payload"],
): GoToItemMovementsByDataType => ({
  type: "Ready:GoTo:ItemMovements:DataType",
  payload,
});
// endregion

// region GoToItemMovementsCreate
export interface GoToItemMovementsCreate {
  type: "Ready:GoTo:ItemMovements:Create";
  payload: DataTypeId;
}

export const goToItemMovementsCreate = (
  payload: GoToItemMovementsCreate["payload"],
): GoToItemMovementsCreate => ({
  type: "Ready:GoTo:ItemMovements:Create",
  payload,
});
// endregion

// region GoToItemMovementsEdit
export interface GoToItemMovementsEdit {
  type: "Ready:GoTo:ItemMovements:Edit";
  payload: ItemMovementId;
}

export const goToItemMovementsEdit = (
  payload: GoToItemMovementsEdit["payload"],
): GoToItemMovementsEdit => ({
  type: "Ready:GoTo:ItemMovements:Edit",
  payload,
});
// endregion

// region GoToRepositoryMovements
export interface GoToRepositoryMovements {
  type: "Ready:GoTo:RepositoryMovements";
}

export const goToRepositoryMovements = (): GoToRepositoryMovements => ({
  type: "Ready:GoTo:RepositoryMovements",
});
// endregion

// region GoToRepositoryMovementsByDataType
export interface GoToRepositoryMovementsByDataType {
  type: "Ready:GoTo:RepositoryMovements:DataType";
  payload: DataTypeId;
}

export const goToRepositoryMovementsByDataType = (
  payload: GoToRepositoryMovementsByDataType["payload"],
): GoToRepositoryMovementsByDataType => ({
  type: "Ready:GoTo:RepositoryMovements:DataType",
  payload,
});
// endregion

// region GoToRepositoryMovementsCreate
export interface GoToRepositoryMovementsCreate {
  type: "Ready:GoTo:RepositoryMovements:Create";
  payload: DataTypeId;
}

export const goToRepositoryMovementsCreate = (
  payload: GoToRepositoryMovementsCreate["payload"],
): GoToRepositoryMovementsCreate => ({
  type: "Ready:GoTo:RepositoryMovements:Create",
  payload,
});
// endregion

// region GoToRepositoryMovementsEdit
export interface GoToRepositoryMovementsEdit {
  type: "Ready:GoTo:RepositoryMovements:Edit";
  payload: RepositoryMovementId;
}

export const goToRepositoryMovementsEdit = (
  payload: GoToRepositoryMovementsEdit["payload"],
): GoToRepositoryMovementsEdit => ({
  type: "Ready:GoTo:RepositoryMovements:Edit",
  payload,
});
// endregion

// region GoToPickingOrders
export interface GoToPickingOrders {
  type: "Ready:GoTo:PickingOrders";
}

export const goToPickingOrders = (): GoToPickingOrders => ({
  type: "Ready:GoTo:PickingOrders",
});
// endregion

// region GoToPickingOrdersByDataType
export interface GoToPickingOrdersByDataType {
  type: "Ready:GoTo:PickingOrders:DataType";
  payload: DataTypeId;
}

export const goToPickingOrdersByDataType = (
  payload: GoToPickingOrdersByDataType["payload"],
): GoToPickingOrdersByDataType => ({
  type: "Ready:GoTo:PickingOrders:DataType",
  payload,
});
// endregion

// region GoToPickingOrdersCreate
export interface GoToPickingOrdersCreate {
  type: "Ready:GoTo:PickingOrders:Create";
  payload: DataTypeId;
}

export const goToPickingOrdersCreate = (
  payload: GoToPickingOrdersCreate["payload"],
): GoToPickingOrdersCreate => ({
  type: "Ready:GoTo:PickingOrders:Create",
  payload,
});
// endregion

// region GoToPickingOrdersEdit
export interface GoToPickingOrdersEdit {
  type: "Ready:GoTo:PickingOrders:Edit";
  payload: PickingOrderId;
}

export const goToPickingOrdersEdit = (
  payload: GoToPickingOrdersEdit["payload"],
): GoToPickingOrdersEdit => ({
  type: "Ready:GoTo:PickingOrders:Edit",
  payload,
});
// endregion

// region GoToStocks
export interface GoToStocks {
  type: "Ready:GoTo:Stocks:Listing";
}

export const goToStocks = (): GoToStocks => ({
  type: "Ready:GoTo:Stocks:Listing",
});
// endregion

// region GoToDatatypes
export interface GoToDatatypes {
  type: "Ready:GoTo:Datatypes:Listing";
}

export const goToDatatypes = (): GoToDatatypes => ({
  type: "Ready:GoTo:Datatypes:Listing",
});
// endregion

// region SignOut
export interface SignOut {
  type: "Ready:SignOut";
}

export const signOut = (): SignOut => ({
  type: "Ready:SignOut",
});
// endregion

// region Organizations
export interface OrganizationsActive {
  type: "Ready:ChangeActive:Organizations";
  payload: boolean;
}

export const changeOrganizationActive = (
  payload: OrganizationsActive["payload"],
): OrganizationsActive => ({
  type: "Ready:ChangeActive:Organizations",
  payload,
});
export interface OrganizationId {
  type: "Ready:ChangeOrgId:Organizations";
  payload: OrgId;
}

export const changeOrganizationId = (
  payload: OrganizationId["payload"],
): OrganizationId => ({
  type: "Ready:ChangeOrgId:Organizations",
  payload,
});
// endregion

// region SignedOut
export interface SignedOut {
  type: "Ready:SignedOut";
}

export const signedOut = (): SignedOut => ({
  type: "Ready:SignedOut",
});
// endregion

// region Reauthorize
export interface Reauthorize {
  type: "Ready:Reauthorize";
}

export const reauthorize = (): Reauthorize => ({
  type: "Ready:Reauthorize",
});
// endregion

// region ReauthorizeFail
export interface ReauthorizeFail {
  type: "Ready:ReauthorizeFail";
}

export const reauthorizeFail = (): ReauthorizeFail => ({
  type: "Ready:ReauthorizeFail",
});
// endregion

// region ReauthorizeSuccess
export interface ReauthorizeSuccess {
  type: "Ready:ReauthorizeSuccess";
  payload: {
    id: UserId;
    avatar?: string;
    username: string;
    email: string;
    accessToken: UserAccessToken;
  };
}

export const reauthorizeSuccess = (
  payload: ReauthorizeSuccess["payload"],
): ReauthorizeSuccess => ({
  type: "Ready:ReauthorizeSuccess",
  payload,
});
// endregion

// region SetUserSettings
export interface SetUserSettings {
  type: "Ready:SetUserSettings";
  payload: Partial<UserSettings["payload"]>;
}

export const setUserSettings = (
  payload: SetUserSettings["payload"],
): SetUserSettings => ({
  type: "Ready:SetUserSettings",
  payload,
});

export const isSetUserSettings = (s: Actions): s is SetUserSettings =>
  s.type === "Ready:SetUserSettings";
// endregion

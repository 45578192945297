import { useTranslation } from "i18n";
import { Select, MenuItem } from "@mui/material";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { useMemo } from "react";
import { useRandomId } from "react-utils/hooks";
import { GeneralFormDataProps } from "../types";
import { InputWithLabel } from "../../../../components/InputWithLabel";
import { sxFullWidth } from "../../../../../../common/sxs";
import { useFormDataProp } from "../../../../hooks/useFormDataProp";

export namespace Format {
  export type Props = GeneralFormDataProps;
}

const formDataProp = "format";

export const Format = ({ formData$, onValuesChange }: Format.Props) => {
  const { t } = useTranslation();
  const { value, error } = useFormDataProp({
    formData$,
    prop: formDataProp,
  });
  const formats = useBehaviorValue(
    useMemo(() => formData$.map((d) => d.formats), [formData$]),
  );
  const randomId = useRandomId();

  return (
    <InputWithLabel label={t("Format")} id={randomId}>
      <Select
        id={randomId}
        sx={sxFullWidth}
        value={value}
        onChange={({ target: { value } }) =>
          onValuesChange({ [formDataProp]: value })
        }
        error={!!error?.length}
      >
        {formats.map(({ id, label }) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </InputWithLabel>
  );
};

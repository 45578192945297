import { useTranslation } from "i18n";
import { Button } from "@components/Button";
import { Box } from "@mui/material";

export interface FooterProps {
  disabled: boolean;
  onSubmit: VoidFunction;
  onCancel: VoidFunction;
  onDelete: VoidFunction;
}

export function Footer(p: FooterProps) {
  const { t } = useTranslation();

  return (
    <Box
      sx={(p) => ({
        display: "grid",
        gridTemplateColumns: "1fr auto auto",
        gap: p.spacing(4),
      })}
    >
      <div>
        <Button color={"secondary"} onClick={p.onDelete} disabled={p.disabled}>
          {t("Delete")}
        </Button>
      </div>
      <Button color={"secondary"} onClick={p.onCancel} disabled={p.disabled}>
        {t("Cancel")}
      </Button>
      <Button color={"primary"} onClick={p.onSubmit} disabled={p.disabled}>
        {t("Save changes")}
      </Button>
    </Box>
  );
}

import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import * as PickingOrderItem from "../../PickingOrderItem/types/Actions";

export type Actions<Id extends string> =
  | AddNew
  | Remove<Id>
  | Submit
  | PickingOrderItem.Actions;

export const isActions = <Id extends string>(p: string) => {
  const _isAdd = isAddNew<Id>(p);
  const _isRemove = isRemove<Id>(p);
  const _isSubmit = isSubmit<Id>(p);
  const _isPickingOrderItemAction = isPickingOrderItemAction(p);

  return strictGuard((a: Actions<Id>): a is Actions<Id> => {
    if (
      _isAdd(a) ||
      _isRemove(a) ||
      _isSubmit(a) ||
      _isPickingOrderItemAction(a)
    )
      return true;

    silentUnreachableError(a);
    return false;
  });
};

// region AddNew
export interface AddNew {
  type: `${string}:AddNew`;
}

export const addNew = (p: string) => (): AddNew => ({
  type: `${p}:AddNew`,
});

export const isAddNew =
  <Id extends string>(p: string) =>
  (s: Actions<Id>): s is AddNew =>
    s.type === `${p}:AddNew`;
// endregion

// region Remove
export interface Remove<Id extends string> {
  type: `${string}:Remove`;
  payload: Id;
}

export const remove =
  <Id extends string>(p: string) =>
  (payload: Remove<Id>["payload"]): Remove<Id> => ({
    type: `${p}:Remove`,
    payload,
  });

export const isRemove =
  <Id extends string>(p: string) =>
  (s: Actions<Id>): s is Remove<Id> =>
    s.type === `${p}:Remove`;
// endregion

// region Submit
export interface Submit {
  type: `${string}:Submit`;
}

export const submit = (p: string) => (): Submit => ({
  type: `${p}:Submit`,
});

export const isSubmit =
  <Id extends string>(p: string) =>
  (s: Actions<Id>): s is Submit =>
    s.type === `${p}:Submit`;
// endregion

export const isPickingOrderItemAction =
  <Id extends string>(p: string) =>
  (a: Actions<Id>): a is PickingOrderItem.Actions => {
    return a.type.startsWith(`${p}:item:`);
  };

export const getItemIdFromPickingOrderAction =
  <Id extends string>(p: string) =>
  (a: PickingOrderItem.Actions): Id =>
    a.type.split(`${p}:item:`)[1]?.split(":")?.[0] as Id;

import { gql } from "@apollo/client";
import { DataTypeId } from "types/src/DataType/DataType";
import { InboundId } from "types/src/Inbounds/Inbound";
import { isT } from "fp-utilities";
import { Cursor } from "types";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { ISODate } from "types/src/date/ISODate";
import { pipe } from "fp-ts/function";
import { InboundItem, InboundItemId } from "types/src/Inbounds/InboundItem";
import {
  GetInboundItemsQuery,
  GetInboundItemsQueryVariables,
  ReceivingInboundItemOrderField,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResult,
} from "../../type/QueryResponse";
import { notFoundError } from "../../type/DsError";
import { Where } from "../../type/Where";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";
import { Query } from "../../type/Query";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import * as Inbounds from "../Inbounds/queries";
import { inboundItemFragment } from "../../fragments/InboundItem";
import { inboundItemFragmentToInboundItem } from "../../transformers/InboundItem";

export type WhereSchema = Where<{
  id: Where.EqIn<InboundItemId>;
  createdAt: Where.Ord<ISODate>;
  updatedAt: Where.Ord<ISODate>;
  data: Where.Map;
  dataType: Where.EqNil<DataTypeId>;
  inbound: Where.Eq<InboundId>;
  dataTypeSlug: Where.Text;
  quantity: Where.Ord<number>;
  sku: Where.Text;
  hasInbound: Where.Value<boolean>;
  hasInboundWith: Where.WithWhereList<Inbounds.WhereSchema>;
}>;
export const whereSchema = Where.create<WhereSchema>({
  id: Where.eqIn(),
  createdAt: Where.ord(),
  updatedAt: Where.ord(),
  data: Where.map("Data"),
  dataType: Where.eqNil("dataTypeID"),
  inbound: Where.eq("inboundID"),
  dataTypeSlug: Where.text("dataTypeSlug"),
  quantity: Where.ord(),
  sku: Where.text(),
  hasInbound: Where.value(),
  hasInboundWith: Where.withWhereList(
    (): Inbounds.WhereSchema => Inbounds.whereSchema,
  ),
});

export interface GetInboundItemsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
  where?: Where.GetType<typeof whereSchema>;
}

export function getInboundItemsQuery(
  vars: GetInboundItemsVars,
): Query<
  GetInboundItemsQueryVariables,
  GetInboundItemsQuery,
  PaginatedQueryResult<InboundItem>
> {
  const query = gql`
    ${pageInfoFragment}
    ${inboundItemFragment}

    query GetInboundItems(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: ReceivingInboundItemWhereInput
      $orderBy: ReceivingInboundItemOrder
    ) {
      receivingInboundItems(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...InboundItemFragment
          }
        }
      }
    }
  `;

  return Query.create(
    {
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: Fp.pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toUndefined,
        ),
        orderBy: pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: ReceivingInboundItemOrderField.CreatedAt,
              updatedAt: ReceivingInboundItemOrderField.UpdatedAt,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    },
    Fp.flow(
      E.map((v) => v.receivingInboundItems),
      E.map(getPaginatedQueryResult(inboundItemFragmentToInboundItem)),
    ),
  );
}

export function getInboundItemQuery(id: InboundItemId) {
  return Query.map(
    Fp.flow(
      E.map((v) => v.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
    getInboundItemsQuery({ where: { and: [{ id: { eq: id } }] } }),
  );
}

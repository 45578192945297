import * as Fp from "fp-ts/function";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { Selector } from "state-manager";
import * as O from "fp-ts/Option";

import { useTranslation } from "i18n";
import * as SchemaFields from "state-manager/generic-states/SchemaFields";
import { InboundItem } from "state-manager/generic-states/InboundItem";
import { InboundItems } from "@Containers/InbondItems";
import { useState } from "react";
import { GlobalSheet } from "ui/components/Sheet";
import { Typography } from "ui/components/Typography";
import { flow } from "fp-ts/function";
import { InboundItemForm } from "@Containers/Form/InboundItemForm";
import { SupplierId } from "types/src/Supplier/Supplier";
import { SearchCombobox } from "@Containers/Form/SearchCombobox";
import { TranslatedStr } from "types/src/TranslatedStr";
import { MaybeSchemaFieldsJsonEditor } from "@Containers/Form/MaybeSchemaFieldsJsonEditor";

export interface InboundFormProps<Id extends string> {
  itemsInstance: InboundItem.Store;
  schemaInstance: SchemaFields.Store;
  selector: Selector<{
    schema: O.Option<SchemaFields.State>;
    items: Array<[Id, InboundItem.State]>;
    supplier: O.Option<SupplierId>;
    suppliers: Array<{ value: SupplierId; label: TranslatedStr }>;
  }>;
  dispatch: {
    schema: (a: SchemaFields.Actions) => void;
    items: (id: Id, a: InboundItem.Actions) => void;
    onSupplierChange: (a: SupplierId | undefined) => void;
    onSupplierSearch: (a: string | undefined) => void;
    onAdd: () => void;
    onRemove: (id: Id) => void;
  };
}

export function InboundForm<Id extends string>(p: InboundFormProps<Id>) {
  const { t } = useTranslation();
  const itemsDispatch = p.dispatch.items;
  const schema = p.schemaInstance;
  const [active, setActive] = useState<Id | undefined>();

  return (
    <>
      <FormWrapper>
        <SearchCombobox<SupplierId>
          onChange={Fp.flow(O.toUndefined, p.dispatch.onSupplierChange)}
          onSearch={p.dispatch.onSupplierSearch}
          selector={Fp.flow(p.selector, (v) => ({
            value: v.supplier,
            options: v.suppliers,
            isLoading: false,
          }))}
          getId={(v) => v}
        />

        <MaybeSchemaFieldsJsonEditor
          value$={Fp.flow(
            p.selector,
            (v) => v.schema,
            O.map((v) => ({
              schema: v.payload.schema,
              uiSchema: v.payload.uiSchema,
              values: v.payload.values,
              submitted:
                schema.states.invalid.is(v) || schema.states.valid.is(v),
            })),
          )}
          onChange={Fp.flow(
            (v) => v ?? {},
            p.schemaInstance.actions.onChange.create,
            p.dispatch.schema,
          )}
        />

        <InboundItems
          label={t("Items")}
          value$={Fp.flow(p.selector, (s) => s.items)}
          onAdd={p.dispatch.onAdd}
          onRemove={p.dispatch.onRemove}
          dispatch={(id, a) => itemsDispatch(id, a)}
          instance={p.itemsInstance}
          onAdvanced={setActive}
        />
      </FormWrapper>
      <GlobalSheet
        isOpen={!!active}
        header={<Typography>{t("Item settings")}</Typography>}
        onClose={() => setActive(undefined)}
      >
        {active && (
          <InboundItemForm
            instance={p.itemsInstance}
            dispatch={(a) => p.dispatch.items(active, a)}
            selector={flow(
              p.selector,
              (v) =>
                v.items.find(([id]) => id === active)?.[1] as InboundItem.State,
            )}
          />
        )}
      </GlobalSheet>
    </>
  );
}

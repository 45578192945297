import { useTranslation } from "i18n";
import { ControlTypeRegistry } from "../../../types/control";
import { ControlType } from "../../../types/control/type";
import { ControlTypeFormats } from "../../../types/control/formats";
import { castStringMaybe } from "../../../utils/cast";
import { pick } from "../../../utils/object";
import { ControlUiSchemaOptionsRegistry } from "../../../types/control/uiSchemaOptions";
import { Validation } from "../../../FormEditor/controlTypes/date/Validation";
import { GeneralOptions } from "../../../FormEditor/controlTypes/date/GeneralOptions";

type Props = Pick<ReturnType<typeof useTranslation>, "t">;

const type = ControlType.date;

const formatsValueToSchema: Record<
  keyof ControlTypeFormats[typeof type],
  string
> = {
  date: "date",
  time: "time",
  dateTime: "date-time",
};
const formatsSchemaToValue: Record<
  string,
  keyof ControlTypeFormats[typeof type]
> = {
  date: "date",
  time: "time",
  "date-time": "dateTime",
};

export const makeTypeDate = ({
  t,
}: Props): ControlTypeRegistry[typeof type] => ({
  type,
  label: t("date"),

  formats: {
    date: { label: t("date") },
    time: { label: t("time") },
    dateTime: { label: t("date-time") },
  },

  detect: ({ dataSchema, uiSchema }) =>
    dataSchema.type === "string" &&
    !!dataSchema.format &&
    ["date", "time", "date-time"].includes(dataSchema.format),

  values: {
    default: {
      type,
      format: "dateTime",
      showLabel: true,
      required: true,
    },
    validate: (v) => ({}),

    fromSchema: ({
      dataSchema,
      dataSchemaRequired,
      uiSchema: { label, options: o = {} },
    }) => ({
      type,

      format: formatsSchemaToValue[dataSchema?.format ?? ""] ?? "date",

      showLabel: !!o.showLabel,
      label: castStringMaybe(label),

      required: dataSchemaRequired,
      unique: !!o.unique,
      readonly: !!o.readonly,
      hidden: !!o.hidden,

      placeholder: castStringMaybe(o.placeholder),
      defaultValue: castStringMaybe(o.defaultValue),
    }),
    toSchema: (v) => ({
      dataSchema: {
        type: "string",
        format: formatsValueToSchema[v.format],
      },
      dataSchemaRequired: !!v.required,
      uiSchema: {
        label: v.label,
        options: {
          ...pick(
            [
              "showLabel",

              "unique",
              "readonly",
              "hidden",

              "placeholder",
              "defaultValue",
            ],
            v,
          ),
        } satisfies ControlUiSchemaOptionsRegistry[typeof type],
      },
    }),
  },

  UI: {
    getAffectingValues: (v) => ({}),
    get: () => ({
      GeneralOptions,
      tabs: {
        validation: {
          label: t("Validation"),
          Content: Validation,
        },
      },
    }),
  },
});

import { User } from "types";
import { Typed } from "utils/Typed";
import { UserSettings } from "../../../types/UserSettings";

interface Payload {
  user: User;
  settings: UserSettings["payload"];
}

export const actions = Typed.builder
  .add("userPreloadFail")
  .add("userPreloadSuccess", (p: Payload) => p)
  .add("signIn")
  .add("signInFail")
  .add("signInSuccess", (p: Payload) => p)
  .finish()("Auth:Actions");

export const isAction = Typed.getGuard(actions);

export type Actions = Typed.GetTypes<typeof actions>;

import { DsError } from "ds";
import { DataTypeId } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import { PickingOrder } from "types/src/PickingOrder/PickingOrder";
import { NoEmptyArr } from "types/src/NoEmptyArr";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import {
  CustomerSearchActions,
  createCustomerSearchState,
  PickingOrderItemsActions,
  SchemaFieldsActions,
  createSchemaFieldsState,
  createPickingOrderItemsState,
} from "../utils";

export type Actions =
  | LoadFail
  | LoadSuccess
  | Submit
  | SaveFail
  | SaveSuccess
  | CustomerSearchActions
  | SchemaFieldsActions
  | PickingOrderItemsActions;

export const isActions = (p: string) => {
  const schemaFieldsState = createSchemaFieldsState(p);
  const customerSearchState = createCustomerSearchState(p);
  const pickingOrderItemsState = createPickingOrderItemsState(p);
  const _isLoadFail = isLoadFail(p);
  const _isLoadSuccess = isLoadSuccess(p);
  const _isSubmit = isSubmit(p);
  const _isSaveFail = isSaveFail(p);
  const _isSaveSuccess = isSaveSuccess(p);

  return strictGuard((a: Actions): a is Actions => {
    if (
      schemaFieldsState.isActions(a) ||
      _isLoadFail(a) ||
      _isLoadSuccess(a) ||
      _isSubmit(a) ||
      _isSaveFail(a) ||
      _isSaveSuccess(a) ||
      customerSearchState.isActions(a) ||
      pickingOrderItemsState.isActions(a)
    ) {
      return true;
    }

    silentUnreachableError(a);
    return false;
  });
};

// region LoadFail
export interface LoadFail {
  type: `${string}:LoadFail`;
  payload: DsError;
}

export const loadFail =
  (p: string) =>
  (payload: LoadFail["payload"]): LoadFail => ({
    type: `${p}:LoadFail`,
    payload,
  });

export const isLoadFail =
  (p: string) =>
  (s: Actions): s is LoadFail =>
    s.type === `${p}:LoadFail`;
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: `${string}:LoadSuccess`;
  payload: {
    dataType: {
      title: string;
      id: DataTypeId;
      schema: DataSchema;
      ui: UiSchema;
    };
    dataTypes: NoEmptyArr<{
      title: string;
      id: DataTypeId;
      schema: DataSchema;
      ui: UiSchema;
      isDefault: boolean;
    }>;
  };
}

export const loadSuccess =
  (p: string) =>
  (payload: LoadSuccess["payload"]): LoadSuccess => ({
    type: `${p}:LoadSuccess`,
    payload,
  });

export const isLoadSuccess =
  (p: string) =>
  (s: Actions): s is LoadSuccess =>
    s.type === `${p}:LoadSuccess`;
// endregion

// region Submit
export interface Submit {
  type: `${string}:Submit`;
}

export const submit = (p: string) => (): Submit => ({
  type: `${p}:Submit`,
});

export const isSubmit =
  (p: string) =>
  (s: Actions): s is Submit =>
    s.type === `${p}:Submit`;
// endregion

// region SaveFail
export interface SaveFail {
  type: `${string}:SaveFail`;
  payload: DsError;
}

export const saveFail =
  (p: string) =>
  (payload: SaveFail["payload"]): SaveFail => ({
    type: `${p}:SaveFail`,
    payload,
  });

export const isSaveFail =
  (p: string) =>
  (s: Actions): s is SaveFail =>
    s.type === `${p}:SaveFail`;
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: `${string}:SaveSuccess`;
  payload: PickingOrder;
}

export const saveSuccess =
  (p: string) =>
  (payload: SaveSuccess["payload"]): SaveSuccess => ({
    type: `${p}:SaveSuccess`,
    payload,
  });

export const isSaveSuccess =
  (p: string) =>
  (s: Actions): s is SaveSuccess =>
    s.type === `${p}:SaveSuccess`;
// endregion

import React from "react";
import Typography from "@mui/material/Typography";
import { Sxs } from "../../../../../types/styles";

export namespace Label {
  export type Props = { children: string; sx?: Sxs[string]; htmlFor?: string };
}

export const Label = ({ sx, htmlFor, children }: Label.Props) => (
  <Typography
    sx={sx ? [sxs.label, sx] : sxs.label}
    component={"label"}
    htmlFor={htmlFor}
  >
    {children}
  </Typography>
);

const sxs = {
  label: (theme) => ({
    display: "block",
    color: theme.palette.text.secondary,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
  }),
} satisfies Sxs;

import { gql } from "@apollo/client";
import { inboundItemFragment } from "./InboundItem";

export const inboundFragment = gql`
  ${inboundItemFragment}

  fragment InboundFragment on ReceivingInbound {
    id
    createdAt
    dataTypeID
    data
    inbounditems {
      ...InboundItemFragment
    }

    orderID
    supplierID
    updatedAt
  }
`;

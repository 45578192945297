import React from "react";
import {
  ControlProps,
  rankWith,
  JsonFormsRendererRegistryEntry,
  isEnumControl,
} from "@jsonforms/core";
import { withJsonFormsEnumProps } from "@jsonforms/react";

import { Control } from "../components/Control";
import { Select } from "../components/Select";

export const entry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(2, isEnumControl),
  renderer: withJsonFormsEnumProps(
    React.memo((props: ControlProps) => <Control {...props} input={Select} />),
  ),
};

import { ISODate } from "types/src/date/ISODate";
import { Option } from "fp-ts/Option";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import * as O from "fp-ts/Option";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Client } from "ds";
import * as Rx from "rxjs";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { getDataTypes } from "ds/DataTypes";
import { Typed } from "utils/Typed";
import {
  deletePickingOrders,
  getPickingOrders,
  GetPickingOrdersVars,
} from "ds/PickingOrder";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { Tuple } from "types/src/Tuple";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingWithDataTypes } from "../../../../../../../../generic-states/ListingWithDataTypes";

const prefix = "Ready:DataManager:PickingOrders:ListingAll";

const createListingState = () => {
  const state = ListingWithDataTypes.createState<
    PickingOrdersListingAll.Filter,
    PickingOrdersListingAll.Item,
    "createdAt" | "updatedAt",
    {}
  >(prefix, {
    defaultFilters: {},
  });

  type St = ListingWithDataTypes.GetState<typeof state>;
  type Ac = ListingWithDataTypes.GetActions<typeof state>;

  const epic: Epic<Ac, St, PickingOrdersListingAll.Deps> = (state$, deps) => {
    return state.epic(state$, {
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(getPickingOrders(client, getFetchVars(s))),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: { in: [DataTypeEntity.Order] },
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;
                    if (E.isLeft(v.dataTypes)) return v.dataTypes;

                    return E.right({
                      items: v.items.right,
                      dataTypes: v.dataTypes.right,
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      id: i.id,
                      createdAt: i.createdAt,
                      updatedAt: O.fromNullable(i.updatedAt),
                      dataType: O.fromNullable(
                        r.dataTypes.items.find((v) => v.id === i.dataTypeId),
                      ),
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(deletePickingOrders(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
      fetchDataTypes: () => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            return Rx.from(
              getDataTypes(client, {
                where: { entity: { in: [DataTypeEntity.Order] } },
              }),
            ).pipe(
              Rx.map(
                E.map((r) => r.items.map((i) => ({ id: i.id, name: i.name }))),
              ),
            );
          }),
        );
      },
    });
  };

  return {
    ...state,
    epic,
    init: (): St => state.init({}),
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetPickingOrdersVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetPickingOrdersVars["where"] = {
      createdAt: {
        gte: fields.createdAt?.[0],
        lte: fields.createdAt?.[1],
      },
      updatedAt: {
        gte: fields.updatedAt?.[0],
        lte: fields.updatedAt?.[1],
      },
      data: {
        contains: Fp.pipe(
          fields.search,
          O.fromNullable,
          O.chain(NoEmptyString.fromString),
          O.map(Tuple.create("")),
          O.toUndefined,
        ),
      },
      dataTypeId: { in: fields.dataTypes },
      hasOrderItems: Fp.pipe(
        fields.status,
        O.fromNullable,
        O.map((v) => v === "active"),
        O.toUndefined,
      ),
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
};

export namespace PickingOrdersListingAll {
  export type Filter = Partial<{
    createdAt: DateRange;
    updatedAt: DateRange;
    search: string;
    dataTypes: DataTypeId[];
    status: "active" | "orphan";
  }>;

  export const instance = createListingState();

  export type State = ListingWithDataTypes.GetState<typeof instance>;
  export type Actions = ListingWithDataTypes.GetActions<typeof instance>;
  export type Exits = ListingWithDataTypes.GetExits<typeof instance>;

  export interface Item {
    id: PickingOrderId;
    createdAt: ISODate;
    updatedAt: Option<ISODate>;
    dataType: Option<{ id: DataTypeId; name: string }>;
  }

  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: () => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (v: Record<string, boolean>) => void;
  }
}

import * as JF from "@jsonforms/core/lib/models/uischema";

export enum UiSchemaElementType {
  verticalLayout = "VerticalLayout",
  horizontalLayout = "HorizontalLayout",
  group = "Group",
  control = "Control",
}

/*assert: matches lib "type"s */ void (UiSchemaElementType as {
  verticalLayout: JF.VerticalLayout["type"];
  horizontalLayout: JF.HorizontalLayout["type"];
  group: JF.GroupLayout["type"];
  control: JF.ControlElement["type"];
});

export type UiSchemaElementOptions /*common*/ = Partial<{
  id: string;

  //region fixme: are these common?
  placeholder: string;
  multi: boolean;
  //endregion

  [k: string]: unknown; // fixme: make strict - add all allowed props/combinations?
}>;

//region UiSchemaElementType*

export type UiSchemaElementTypeControl = Omit<
  JF.ControlElement,
  "type" | "options"
> & {
  type: UiSchemaElementType.control;
  options?: UiSchemaElementOptions;
};

export type UiSchemaElementTypeGroup = Omit<
  JF.GroupLayout,
  "type" | "options" | "elements"
> & {
  type: UiSchemaElementType.group;
  options?: UiSchemaElementOptions;
  elements: Array<UiSchemaElement>;
};

export type UiSchemaElementTypeHorizontalLayout = Omit<
  JF.HorizontalLayout,
  "type" | "options" | "elements"
> & {
  type: UiSchemaElementType.horizontalLayout;
  options?: UiSchemaElementOptions;
  elements: Array<
    Exclude<UiSchemaElement, UiSchemaElementTypeHorizontalLayout>
  >;
};

export type UiSchemaElementTypeVerticalLayout = Omit<
  JF.VerticalLayout,
  "type" | "options" | "elements"
> & {
  type: UiSchemaElementType.verticalLayout;
  options?: UiSchemaElementOptions;
  elements: Array<Exclude<UiSchemaElement, UiSchemaElementTypeVerticalLayout>>;
};

//endregion

export type UiSchemaTypeRegistry = {
  [UiSchemaElementType.control]: UiSchemaElementTypeControl;
  [UiSchemaElementType.group]: UiSchemaElementTypeGroup;
  [UiSchemaElementType.horizontalLayout]: UiSchemaElementTypeHorizontalLayout;
  [UiSchemaElementType.verticalLayout]: UiSchemaElementTypeVerticalLayout;
};

export type UiSchemaElement = UiSchemaTypeRegistry[UiSchemaElementType];

export namespace UiSchemaElement {
  // region traverse
  export function traverse<T>(
    fn: (acc: T, e: UiSchemaElement) => T,
    init: T,
    s: UiSchemaElement,
  ): T;
  export function traverse<T>(
    ...args:
      | [fn: (acc: T, e: UiSchemaElement) => T, init: T, s: UiSchemaElement]
      | [fn: (acc: T, e: UiSchemaElement) => T, init: T]
      | [fn: (acc: T, e: UiSchemaElement) => T]
  ):
    | ((init: T, s: UiSchemaElement) => T)
    | ((init: T) => (s: UiSchemaElement) => T)
    | ((s: UiSchemaElement) => T)
    | T {
    if (args.length === 1)
      return (...args2: [init: T, s: UiSchemaElement]) =>
        traverse(args[0], ...args2);
    if (args.length === 2)
      return (s: UiSchemaElement) => traverse(args[0], args[1], s);

    const [fn, init, s] = args;

    switch (s.type) {
      case UiSchemaElementType.horizontalLayout:
      case UiSchemaElementType.group:
      case UiSchemaElementType.verticalLayout: {
        const acc = fn(init, s);

        return s.elements.reduce((acc, c) => traverse(fn, acc, c), acc);
      }
      case UiSchemaElementType.control:
        return fn(init, s);
    }
  }
  // endregion

  // region map
  export function map(
    fn: (v: UiSchemaElement) => UiSchemaElement,
  ): (schema: UiSchemaElement) => UiSchemaElement;
  export function map(
    fn: (v: UiSchemaElement) => UiSchemaElement,
  ): (schema: UiSchemaElement) => UiSchemaElement;
  export function map(
    fn: (v: UiSchemaElement) => UiSchemaElement,
    schema: UiSchemaElement,
  ): UiSchemaElement;
  export function map(
    ...args:
      | [fn: (v: UiSchemaElement) => UiSchemaElement, schema: UiSchemaElement]
      | [fn: (v: UiSchemaElement) => UiSchemaElement]
  ): ((schema: UiSchemaElement) => UiSchemaElement) | UiSchemaElement {
    if (args.length === 1) return (s: UiSchemaElement) => map(args[0], s);

    const [fn, _schema] = args;

    const schema = fn(_schema);

    switch (schema.type) {
      case UiSchemaElementType.control:
        return schema;
      case UiSchemaElementType.verticalLayout:
      case UiSchemaElementType.group:
      case UiSchemaElementType.horizontalLayout: {
        const elements = schema.elements.map((e) => map(fn, e));

        return { ...schema, elements } as UiSchemaElement;
      }
    }
  }
  // endregion

  // region filter
  export function filter(
    p: (v: UiSchemaElement) => boolean,
  ): <T extends UiSchemaElement>(schema: T) => T | undefined;
  export function filter<T extends UiSchemaElement>(
    p: (v: UiSchemaElement) => boolean,
  ): (schema: T) => T | undefined;
  export function filter<T extends UiSchemaElement>(
    p: (v: UiSchemaElement) => boolean,
    schema: T,
  ): T | undefined;
  export function filter<T extends UiSchemaElement>(
    ...args:
      | [p: (v: UiSchemaElement) => boolean, schema: T]
      | [p: (v: UiSchemaElement) => boolean]
  ): ((schema: T) => T | undefined) | T | undefined {
    if (args.length === 1) return (s: T) => filter(args[0], s);

    const [p, schema] = args;

    if (!p(schema)) return undefined;

    switch (schema.type) {
      case UiSchemaElementType.control:
        return schema;
      case UiSchemaElementType.verticalLayout:
      case UiSchemaElementType.group:
      case UiSchemaElementType.horizontalLayout: {
        const elements = schema.elements
          .map((e) => filter(p, e))
          .filter((e): e is UiSchemaElement => e !== undefined);

        if (elements.length === 0) return undefined;

        return { ...schema, elements };
      }
    }
  }
  // endregion
}

export type UiSchema = UiSchemaTypeRegistry[UiSchemaElementType.verticalLayout];

export namespace UiSchema {
  export function filter(
    p: (v: UiSchemaElement) => boolean,
    schema: UiSchema,
  ): UiSchema {
    return (
      UiSchemaElement.filter(p, schema) ?? {
        type: UiSchemaElementType.verticalLayout,
        elements: [],
      }
    );
  }
}

export type UiSchemaElementPath = Array<"elements" | number>;

import { Selector, useSelector } from "state-manager";
import * as Ready from "state-manager/states/Ready";
import { isSigningOut } from "state-manager/states/Ready";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";
import * as BPMNPreview from "state-manager/states/Ready/states/BPMNPreview";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";
import * as GraphqlPlayground from "state-manager/states/Ready/states/GraphqlPlayground";
import * as Temporal from "state-manager/states/Ready/states/Temporal";
import * as DataManagerComponent from "@Containers/Ready/DataManager";
import * as BuilderPreviewComponent from "@Containers/Ready/BuilderPreview";
import * as BPMNPreviewComponent from "@Containers/Ready/BPMNPreview";
import * as ZitadelPreviewComponent from "@Containers/Ready/ZitadelPreview";
import * as GraphQlPlaygroundComponent from "@Containers/Ready/GraphQlPlayground";
import * as TemporalComponent from "@Containers/Ready/Temporal";
import * as DataTypesComponent from "@Containers/Ready/DataTypes";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";

import { silentUnreachableError } from "utils/exceptions";
import { ReactElement } from "react";
import { Loading } from "ui/layouts/Loading";

import { flow } from "fp-ts/function";
import { DataTypesListing } from "state-manager/states/Ready/states/DataTypesListing";
import { DataTypeCreate } from "state-manager/states/Ready/states/DataTypeCreate";
import { DataTypeEdit } from "state-manager/states/Ready/states/DataTypeEdit";

export interface ReadyContentProps {
  selector: Selector<Ready.State>;
  dispatch: (a: Ready.Actions) => void;
}

export function Content({
  selector,
  dispatch,
}: ReadyContentProps): ReactElement {
  const r = useSelector(
    flow(
      selector,
      (s) => s.payload.subState,
      (s) => {
        if (isSigningOut(s)) return { type: "loading" } as const;
        if (DataManager.isState(s)) {
          return {
            type: "data-manager",
            selector: flow(
              selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (BuilderPreview.isState(s)) {
          return {
            type: "builder-preview",
            selector: flow(
              selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (BPMNPreview.isState(s)) {
          return {
            type: "bpmn-preview",
            selector: flow(
              selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }
        if (
          DataTypesListing.instance.isState(s) ||
          DataTypeCreate.instance.isState(s) ||
          DataTypeEdit.instance.isState(s)
        ) {
          return {
            type: "data-types",
            selector: flow(
              selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }

        if (ZitadelPreview.isState(s)) {
          return {
            type: "zitadel-preview",
            selector: flow(
              selector,
              (s) => s.payload.subState,
              (st) => st as typeof s,
            ),
          } as const;
        }

        if (GraphqlPlayground.instance.isState(s)) {
          return {
            type: "graphql-playground",
            selector: flow(
              selector,
              (s) => s.payload.user,
              E.map((v) => v.accessToken),
              E.getOrElseW(() => undefined),
              (v) => ({ accessToken: v }),
            ),
          } as const;
        }

        if (Temporal.instance.isState(s)) {
          return {
            type: "temporal",
          } as const;
        }

        silentUnreachableError(s);
        return { type: "loading" } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "data-manager":
      return (
        <DataManagerComponent.Content
          selector={r.selector}
          dispatch={dispatch}
          onType={Fp.flow(Ready.goToDataTypesEdit, dispatch)}
        />
      );
    case "builder-preview":
      return (
        <BuilderPreviewComponent.Content
          selector={r.selector}
          dispatch={dispatch}
        />
      );
    case "bpmn-preview":
      return (
        <BPMNPreviewComponent.Content
          selector={r.selector}
          dispatch={dispatch}
        />
      );
    case "data-types":
      return (
        <DataTypesComponent.Content selector={r.selector} dispatch={dispatch} />
      );
    case "zitadel-preview":
      return (
        <ZitadelPreviewComponent.Content
          selector={r.selector}
          dispatch={dispatch}
        />
      );
    case "graphql-playground":
      return (
        <GraphQlPlaygroundComponent.Content
          selector={r.selector}
          dispatch={dispatch}
        />
      );
    case "temporal":
      return <TemporalComponent.Content />;
  }
}

import { CustomerId } from "types/src/Customers/Customer";
import { DsError } from "ds";
import type { GetGuardType } from "types/src/Utils";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import * as PickingOrderItems from "../../../../../../../../generic-states/PickingOrderItems";
import * as ItemSearch from "../../../../../../../../generic-states/ItemSearch";
import { NewItemId } from "../../../../../../../../generic-states/PickingOrderItems";
import type { ItemId } from "./types/State";

export const createSchemaFieldsState = (p: string) =>
  SchemaFields.createState(`${p}:SchemaFields`);

export type SchemaFieldsActionsMap = {
  [k in keyof ReturnType<
    typeof createSchemaFieldsState
  >["actions"]]: ReturnType<
    ReturnType<typeof createSchemaFieldsState>["actions"][k]["create"]
  >;
};
export type SchemaFieldsActions =
  SchemaFieldsActionsMap[keyof SchemaFieldsActionsMap];

export type SchemaFieldsStatesMap = {
  [k in keyof ReturnType<typeof createSchemaFieldsState>["states"]]: ReturnType<
    ReturnType<typeof createSchemaFieldsState>["states"][k]["create"]
  >;
};
export type SchemaFieldsState =
  SchemaFieldsStatesMap[keyof SchemaFieldsStatesMap];

export const createPickingOrderItemsState = (p: string) =>
  PickingOrderItems.createState<ItemId | NewItemId>(`${p}:items`);

export type PickingOrderItemsStatesMap = {
  [k in keyof ReturnType<
    typeof createPickingOrderItemsState
  >["states"]]: ReturnType<
    ReturnType<typeof createPickingOrderItemsState>["states"][k]["create"]
  >;
};

export type PickingOrderItemsState =
  PickingOrderItemsStatesMap[keyof PickingOrderItemsStatesMap];

export type PickingOrderItemsActionsMap = {
  [k in keyof ReturnType<
    typeof createPickingOrderItemsState
  >["actions"]]: ReturnType<
    ReturnType<typeof createPickingOrderItemsState>["actions"][k]["create"]
  >;
};

export type PickingOrderItemsActions = GetGuardType<
  ReturnType<typeof createPickingOrderItemsState>["isActions"]
>;

export const createCustomerSearchState = (p: string) =>
  ItemSearch.createState<DsError, { id: CustomerId; title: string }>(
    `${p}:Customer`,
    (a, b) => a.id === b.id,
  );

export type CustomerSearchStatesMap = {
  [k in keyof ReturnType<
    typeof createCustomerSearchState
  >["states"]]: ReturnType<
    ReturnType<typeof createCustomerSearchState>["states"][k]["create"]
  >;
};
export type CustomerSearchState =
  CustomerSearchStatesMap[keyof CustomerSearchStatesMap];
export type CustomerSearchActions = ReturnType<
  ReturnType<typeof createCustomerSearchState>["actions"][keyof ReturnType<
    typeof createCustomerSearchState
  >["actions"]]["create"]
>;

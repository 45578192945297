import { ISODate } from "types/src/date/ISODate";
import { Option } from "fp-ts/Option";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import * as O from "fp-ts/Option";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Client } from "ds";
import * as Rx from "rxjs";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { getDataTypes } from "ds/DataTypes";
import * as NonEmptyArray from "fp-ts/NonEmptyArray";
import { Typed } from "utils/Typed";
import {
  deleteRepositories,
  getRepositories,
  GetRepositoryVars,
} from "ds/Repositories";
import { RepositoryId } from "types/src/Repositories/Repository";
import { Tuple } from "types/src/Tuple";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { silentUnreachableError } from "utils/exceptions";
import { getInventoryItems } from "ds/InventoryItems";
import { strictGuard } from "utils/strictGuard";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingWithDataTypes } from "../../../../../../../../generic-states/ListingWithDataTypes";
import { Loading } from "../../../../../../../../generic-states/Loading";
import { ListingWithDataType } from "../../../../../../../../generic-states/ListingWithDataType";

const prefix = "Ready:DataManager:Repositories:ListingAll";

const createListingState = () => {
  const itemsState = Loading.createState<
    Array<{ id: InventoryItemId; sku: string }>,
    string | undefined
  >(`${prefix}:items`, { equals: (a, b) => a === b });
  const parentState = Loading.createState<
    Array<{ id: RepositoryId; name: string }>,
    string | undefined
  >(`${prefix}:parent`, { equals: (a, b) => a === b });
  const state = ListingWithDataTypes.createState<
    RepositoriesListingAll.Filter,
    RepositoriesListingAll.Item,
    "createdAt" | "updatedAt" | "name" | "type" | "virtualRepo",
    {
      inventoryItems: Loading.GetState<typeof itemsState>;
      parent: Loading.GetState<typeof parentState>;
    }
  >(prefix, {
    defaultFilters: {},
  });

  type State = ListingWithDataTypes.GetState<typeof state>;
  type Actions =
    | ListingWithDataTypes.GetActions<typeof state>
    | Loading.GetActions<typeof itemsState>
    | Loading.GetActions<typeof parentState>;
  type Exits = ListingWithDataType.GetExits<typeof state>;

  const reducer = (s: State, a: Actions): E.Either<Exits, State> => {
    if (itemsState.isAction(a)) {
      const st = itemsState.reducer(s.payload.inventoryItems, a);

      return E.right({
        ...s,
        payload: {
          ...s.payload,
          inventoryItems: Fp.pipe(
            st,
            E.getOrElseW(() => s.payload.inventoryItems),
          ),
        },
      } as State);
    }

    if (parentState.isAction(a)) {
      const st = parentState.reducer(s.payload.parent, a);

      return E.right({
        ...s,
        payload: {
          ...s.payload,
          parent: Fp.pipe(
            st,
            E.getOrElseW(() => s.payload.parent),
          ),
        },
      } as State);
    }

    if (state.isAction(a)) return state.reducer(s, a);

    silentUnreachableError(a);
    return E.right(s);
  };

  const epic: Epic<Actions, State, RepositoriesListingAll.Deps> = (
    state$,
    deps,
  ) => {
    const main$ = state.epic(state$, {
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(getRepositories(client, getFetchVars(s))),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: { in: [DataTypeEntity.Repository] },
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;
                    if (E.isLeft(v.dataTypes)) return v.dataTypes;

                    return E.right({
                      items: v.items.right,
                      dataTypes: v.dataTypes.right,
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map(
                      (i): RepositoriesListingAll.Item => ({
                        id: i.id,
                        createdAt: i.createdAt,
                        updatedAt: O.fromNullable(i.updatedAt),
                        dataType: O.fromNullable(
                          r.dataTypes.items.find((v) => v.id === i.dataTypeId),
                        ),
                        name: i.name,
                        type: i.type,
                        isVirtual: i.isVirtual,
                      }),
                    ),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(deleteRepositories(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
      fetchDataTypes: () => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            return Rx.from(
              getDataTypes(client, {
                where: { entity: { in: [DataTypeEntity.Repository] } },
              }),
            ).pipe(
              Rx.map(
                E.map((r) => r.items.map((i) => ({ id: i.id, name: i.name }))),
              ),
            );
          }),
        );
      },
    });

    const items$ = itemsState.epic(
      state$.pipe(Rx.map((s) => s.payload.inventoryItems)),
      {
        get: (q) => {
          return deps.pyckAdminClient$.pipe(
            Rx.switchMap((client) => {
              return Rx.from(
                getInventoryItems(client, {
                  where: {
                    or: [
                      { sku: { containsFold: q } },
                      { data: { contains: q ? ["", q] : undefined } },
                    ],
                  },
                }),
              );
            }),
            Rx.map(
              E.map((r) => {
                return r.items.map((v) => ({
                  id: v.id,
                  sku: v.sku,
                }));
              }),
            ),
          );
        },
      },
    );
    const parent$ = parentState.epic(
      state$.pipe(Rx.map((s) => s.payload.parent)),
      {
        get: (q) => {
          return deps.pyckAdminClient$.pipe(
            Rx.switchMap((client) => {
              return Rx.from(
                getRepositories(client, {
                  where: {
                    or: [{ name: { containsFold: q } }],
                  },
                }),
              );
            }),
            Rx.map(
              E.map((r) => {
                return r.items.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              }),
            ),
          );
        },
      },
    );

    return Rx.merge(main$, items$, parent$);
  };

  return {
    ...state,
    isAction: strictGuard(
      (a: Actions): a is Actions =>
        state.isAction(a) || itemsState.isAction(a) || parentState.isAction(a),
    ),
    epic,
    reducer,
    init: (): State => {
      return state.init({
        inventoryItems: itemsState.init(undefined),
        parent: parentState.init(undefined),
      });
    },
    subStates: {
      ...state.subStates,
      items: itemsState,
      parent: parentState,
    },
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetRepositoryVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetRepositoryVars["where"] = {
      and: [
        {
          createdAt: {
            gte: fields.createdAt?.[0],
            lte: fields.createdAt?.[1],
          },
          updatedAt: {
            gte: fields.updatedAt?.[0],
            lte: fields.updatedAt?.[1],
          },
        },
      ],
      name: { containsFold: fields.name },
      dataType: {
        in: Fp.pipe(
          fields.dataTypes,
          O.fromNullable,
          O.chain(NonEmptyArray.fromArray),
          O.toUndefined,
        ),
        isNil: Fp.pipe(
          fields.status,
          O.fromNullable,
          O.map((v) => v === "orphan"),
          O.toUndefined,
        ),
      },
      or: [
        {
          id: {
            eq: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.map(RepositoryId.fromString),
              O.toUndefined,
            ),
          },
        },
        { name: { containsFold: fields.search } },
        {
          data: {
            contains: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.chain(NoEmptyString.fromString),
              O.map(Tuple.create("")),
              O.toUndefined,
            ),
          },
        },
      ],
      virtual: fields.isVirtual,
      type: fields.type,
      hasStocksWith: [{ hasItemWith: [{ id: { in: fields.items } }] }],
      parent: { in: fields.parent },
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
};

export namespace RepositoriesListingAll {
  export type Filter = Partial<{
    createdAt: DateRange;
    updatedAt: DateRange;
    name: string;
    search: string;
    dataTypes: DataTypeId[];
    status: "active" | "orphan";
    type: "dynamic" | "static";
    isVirtual: boolean;
    items: InventoryItemId[];
    parent: RepositoryId[];
  }>;

  export const instance = createListingState();

  export type State = ListingWithDataTypes.GetState<typeof instance>;
  export type Actions = ListingWithDataTypes.GetActions<typeof instance>;
  export type Exits = ListingWithDataTypes.GetExits<typeof instance>;

  export interface Item {
    id: RepositoryId;
    name: NoEmptyString;
    createdAt: ISODate;
    updatedAt: Option<ISODate>;
    dataType: Option<{ id: DataTypeId; name: string }>;
    type: "dynamic" | "static";
    isVirtual: boolean;
  }

  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: () => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (v: Record<string, boolean>) => void;
  }
}

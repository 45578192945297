import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError, notFoundError } from "ds";
import { flow } from "fp-ts/function";
import { isT } from "fp-utilities";
import { createCustomer } from "ds/Customers";
import { getDataType } from "ds/DataTypes";
import * as E from "fp-ts/Either";
import { DataType } from "types/src/DataType/DataType";
import { Epic } from "../../../../../../../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { schemaFieldsState } from "./utils";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) => {
  const fieldsSchema$ = schemaFieldsState.epic(
    state$.pipe(
      filter(State.isLoaded),
      map((s) => s.payload.schema),
    ),
    pyckAdminClient$,
  );

  const loading$ = state$.pipe(
    filter(State.isLoading),
    map((s) => s.payload.dataTypeId),
    distinctUntilChanged(),
    withLatestFrom(pyckAdminClient$),
    switchMap(([dataTypeId, client]) =>
      from(getDataType(client, dataTypeId)).pipe(
        map(
          flow(
            E.filterOrElse<DataType, DsError>(isT, notFoundError),
            E.filterOrElse<DataType, DsError>(
              (v) => v.entity === "customer",
              notFoundError,
            ),
            E.map((v) =>
              Actions.loadSuccess({
                schema: v.schema,
                ui: v.frontendSchema,
              }),
            ),
            E.getOrElseW<DsError, Actions.Actions>(Actions.loadFail),
          ),
        ),
      ),
    ),
  );

  const create$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isSaving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(
        createCustomer(client, {
          dataTypeId: s.dataTypeId,
          fields: s.schema.payload.values,
        }),
      ).pipe(
        map(
          flow(
            E.map(Actions.saveSuccess),
            E.getOrElseW<DsError, Actions.Actions>(Actions.saveError),
          ),
        ),
        catchError(() => of(Actions.saveError())),
      ),
    ),
  );

  return merge(loading$, create$, fieldsSchema$);
};

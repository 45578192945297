import { CircularProgress } from "@mui/material";
import { useTranslation } from "i18n";
import { State } from "./State";

export function Loading() {
  const { t } = useTranslation();
  return (
    <State
      icon={<CircularProgress size={24} />}
      title={t("Fetching results")}
      subtitle={t("Waiting to fetch all results, please wait a few moments")}
    />
  );
}

import {
  PickingOrder,
  PickingOrderId,
} from "types/src/PickingOrder/PickingOrder";
import { Typed } from "utils/Typed";

export const exits = Typed.builder
  .add("saved", (p: PickingOrder) => p)
  .add("removed", (v: PickingOrderId) => v)
  .finish();

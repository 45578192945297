import { Cursor } from "types";
import { omitEmpties } from "utils/value";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { DataTypeId } from "types/src/DataType/DataType";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import {
  GetInventoryItemsQueryVariables,
  InventoryItemOrderField,
} from "../generated/graphql";
import { toApiOrderDirection } from "../transformers/OrderDirection";
import * as ItemMovements from "../entities/ItemMovements";
import { Where } from "./Where";
import * as Stocks from "./Stocks";
import * as Transactions from "./Transactions";

export type WhereSchema = Where<{
  data: Where.Map;
  createdAt: Where.Ord<ISODate>;
  updatedAt: Where.Ord<ISODate>;
  id: Where.EqIn<InventoryItemId>;
  sku: Where.Text;
  dataType: Where.EqNil<DataTypeId>;
  hasMovements: Where.Value<boolean>;
  hasMovementsWith: Where.WithWhereList<ItemMovements.WhereSchema>;
  hasStocks: Where.Value<boolean>;
  hasStocksWith: Where.WithWhereList<Stocks.WhereSchema>;
  hasTransactions: Where.Value<boolean>;
  hasTransactionsWith: Where.WithWhereList<Transactions.WhereSchema>;
}>;

export const whereSchema = Where.create<WhereSchema>({
  data: Where.map("Data"),
  createdAt: Where.ord<ISODate>(),
  updatedAt: Where.ord<ISODate>(),
  id: Where.eqIn<InventoryItemId>(),
  sku: Where.text(),
  dataType: Where.eqNil<DataTypeId>("dataTypeID"),
  hasMovements: Where.value("hasItemMovementItems"),
  hasMovementsWith: Where.withWhereList(
    () => ItemMovements.whereSchema,
    "hasItemMovementItems",
  ),
  hasStocks: Where.value("hasItemStocks"),
  hasStocksWith: Where.withWhereList(
    () => Stocks.whereSchema,
    "hasItemStocksWith",
  ),
  hasTransactions: Where.value("hasItemTransactions"),
  hasTransactionsWith: Where.withWhereList(
    () => Transactions.whereSchema,
    "hasItemTransactionsWith",
  ),
});

export interface GetInventoryItemsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: Where.GetType<WhereSchema>;
  orderBy?: {
    by: "createdAt" | "updatedAt" | "sku";
    direction: "asc" | "desc";
  };
}

export function getInventoryItemsVarsWhereToApiInput(
  vars: GetInventoryItemsVars,
): GetInventoryItemsQueryVariables | undefined {
  return omitEmpties({
    first: vars.first,
    last: vars.last,
    after: vars.after,
    before: vars.before,
    where: pipe(
      vars.where,
      O.fromNullable,
      O.map(Where.toApiWhere(whereSchema)),
      O.toUndefined,
    ),
    orderBy: pipe(
      vars.orderBy,
      O.fromNullable,
      O.map((o) => ({
        direction: toApiOrderDirection(o.direction),
        field: {
          createdAt: InventoryItemOrderField.CreatedAt,
          updatedAt: InventoryItemOrderField.UpdatedAt,
          sku: InventoryItemOrderField.Sku,
        }[o.by],
      })),
      O.toUndefined,
    ),
  });
}

import { useMemo } from "react";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Value } from "types/src/jsonSchema/value";
import { CodeEditor } from "../CodeEditor";
import { PreviewMode, UiState } from "../types/uiState";
import { FormPreview } from "../FormPreview";

export namespace Content {
  export type Props = {
    value$: BehaviorValue<Value>;
    ui$: BehaviorValue<UiState>;
    onUiChange: (ui: Partial<UiState>) => void;
  };
}

export const Content = ({ value$, ui$, onUiChange }: Content.Props) => {
  const previewMode = useBehaviorValue(
    useMemo(() => ui$.map((ui) => ui.previewMode), [ui$]),
  );

  return (
    <>
      {previewMode === PreviewMode.form && (
        <FormPreview {...{ value$, ui$, onUiChange }} />
      )}
      {previewMode === PreviewMode.schema && <CodeEditor {...{ value$ }} />}
    </>
  );
};

import "graphiql/graphiql.css";
import { useGlobalEnv } from "@Hooks/useGlobalEnv";

export interface ContentProps {}

export function Content(p: ContentProps) {
  const { temporalUrl } = useGlobalEnv();

  return <iframe src={temporalUrl} style={{ height: "100%", width: "100%" }} />;
}

import * as E from "fp-ts/Either";
import { silentUnreachableError } from "utils/exceptions";
import * as Obj from "utils/object";
import * as SchemaFields from "../SchemaFields";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { createPickingOrderItemState } from "./utils";
import {
  getItemIdFromPickingOrderAction,
  isPickingOrderItemAction,
} from "./types/Actions";
import { createNewItemId } from "./types/NewItemId";

export const reducer = <Id extends string>(p: string) => {
  const pickingOrderItemState = createPickingOrderItemState<Id>(p);
  const _isPickingOrderItemAction = isPickingOrderItemAction<Id>(p);
  const ready = State.ready<Id>(p);
  const valid = State.valid<Id>(p);
  const isAdd = Actions.isAddNew<Id>(p);
  const isRemove = Actions.isRemove<Id>(p);
  const isSubmit = Actions.isSubmit<Id>(p);

  return (
    s: State.State<Id>,
    a: Actions.Actions<Id>,
  ): E.Either<never, State.State<Id>> => {
    if (_isPickingOrderItemAction(a)) {
      const id = getItemIdFromPickingOrderAction<Id>(p)(a);

      const item = s.payload.items[id];

      if (!item) return E.right(s);

      const state = pickingOrderItemState(id);

      return E.right(
        ready({
          ...s.payload,
          items: {
            ...s.payload.items,
            [id]: state.reducer(item, a),
          },
        }),
      );
    }

    if (isAdd(a)) {
      const newId: Id = createNewItemId() as unknown as Id;
      const dataType =
        s.payload.dataTypes.find((v) => v.isDefault) ?? s.payload.dataTypes[0];

      return E.right(
        ready({
          ...s.payload,
          items: {
            ...s.payload.items,
            [newId]: pickingOrderItemState(newId).init({
              dataTypeId: dataType.id,
              fields: SchemaFields.createState(
                `${p}:item:${newId}:Fields`,
              ).init({
                schema: dataType.schema,
                uiSchema: dataType.ui,
                values: {},
              }),
              dataTypes: s.payload.dataTypes,
              sku: undefined,
              quantity: undefined,
            }),
          },
        }),
      );
    }

    if (isRemove(a)) {
      if (!Object.hasOwn(s.payload.items, a.payload)) return E.right(s);

      const clone = { ...s.payload.items };
      delete clone[a.payload];

      return E.right(
        ready({
          ...s.payload,
          items: clone,
        }),
      );
    }

    if (isSubmit(a)) {
      const items = Obj.map((v, id) => {
        const state = pickingOrderItemState(id);

        return state.reducer(v, state.actions.submit.create());
      }, s.payload.items);

      if (
        Obj.every(
          (v, id) => pickingOrderItemState(id).states.valid.is(v),
          items,
        )
      ) {
        return E.right(valid({ ...s.payload, items }));
      }

      return E.right(ready({ ...s.payload, items }));
    }

    silentUnreachableError(a);
    return E.right(s);
  };
};

import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Value } from "types/src/jsonSchema/value";
import { FormEditor } from "../FormEditor";
import { UiState } from "../types/uiState";
import { Header } from "./Header";

export namespace Left {
  export type Props = {
    value$: BehaviorValue<Value>;
    onValueChange: (value: Value) => void;
    ui$: BehaviorValue<UiState>;
    onUiChange: (ui: Partial<UiState>) => void;
  };
}

export const Left = ({
  value$,
  onValueChange,
  ui$,
  onUiChange,
}: Left.Props) => (
  <>
    <Header {...{ value$, onValueChange, ui$, onUiChange }} />
    <FormEditor {...{ value$, onValueChange }} />
  </>
);

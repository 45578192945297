import { Box } from "@mui/material";
import { createPortal } from "react-dom";
import React from "react";
import { Sxs } from "../../../types/styles";

export const ActionsBarSetup = ({ children }: React.PropsWithChildren) => {
  return createPortal(<Box sx={sxs.root}>{children}</Box>, document.body);
};

const sxs = {
  root: (theme) => ({
    position: "fixed",
    bottom: theme.spacing(12),
    left: "50%",
    transform: "translateX(-50%)",
  }),
} satisfies Sxs;

import React from "react";
import { ControlPathCache } from "../types/control/pathCache";

export namespace ControlPathCacheContext {
  export type Value = ControlPathCache;
}

// Do not forget to reset on items reorder/regroup!
export const ControlPathCacheContext =
  React.createContext<ControlPathCacheContext.Value>({ current: {} });

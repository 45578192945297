import { silentUnreachableError } from "utils/exceptions";
import * as Obj from "utils/object";
import * as E from "fp-ts/Either";
import { mergeChanges } from "utils/object";
import { createStates, createActions, State, Actions } from "./index";

export const createReducer = <Fields extends { [k in string]?: unknown }>({
  actions,
  states,
}: {
  states: ReturnType<typeof createStates<Fields>>;
  actions: ReturnType<typeof createActions<Fields>>;
}) => {
  return (
    s: State<Fields>,
    a: Actions<Fields>,
  ): E.Either<never, State<Fields>> => {
    if (actions.setValue.is(a)) {
      return E.right(
        states.idle.create(mergeChanges(s.payload.fields, a.payload)),
      );
    }

    if (actions.submit.is(a)) {
      if (states.idle.is(s)) {
        return E.right(states.submitted.create(s.payload.fields));
      }

      return E.right(s);
    }
    if (actions.reset.is(a)) {
      if (Obj.keys(s.payload.fields).length !== 0) {
        return E.right(states.idle.create({} as Fields));
      }

      return E.right(s);
    }

    silentUnreachableError(a);
    return E.right(s);
  };
};

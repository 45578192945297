import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import { Typed } from "utils/Typed";
import { Value } from "./Value";

interface Init {
  uiSchema: UiSchema | undefined;
  schema: DataSchema;
  values: Value | undefined;
}

interface Edited extends Init {
  values: Value;
}

export const createStates = Typed.builder
  .add("init", (p: Init) => p)
  .add("edited", (p: Edited) => p)
  .add("invalid", (p: Edited) => p)
  .add("valid", (p: Edited) => p)
  .finish();

import { DataSchema } from "types/src/jsonSchema/dataSchema";
import * as Fp from "fp-ts/function";
import * as R from "rambda";
import { scopeToName, scopeToRequiredNamesPath } from "../uiSchema/scope";

export const updateRequiredByScope = (
  scope: string,
  required: boolean,
  dataSchema: DataSchema,
): DataSchema => {
  const name = scopeToName(scope),
    requiredNamesPath = scopeToRequiredNamesPath(scope),
    requiredNames = Fp.pipe(
      dataSchema,
      (dataSchema) => R.path<string[]>(requiredNamesPath, dataSchema),
      (names) => (names && Array.isArray(names) ? names : []),
    );

  if (required === requiredNames.includes(name)) {
    return dataSchema;
  }

  return R.assocPath<DataSchema>(
    requiredNamesPath,
    [...requiredNames.filter((n) => n !== name), ...(required ? [name] : [])],
    dataSchema,
  );
};

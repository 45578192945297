import React, { useCallback } from "react";
import { useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/material";
import { Sxs } from "../../../../../../types/styles";
import { DndMeta } from "../../types";
import { useElementRefs } from "../../elementRefs/hooks";

export function VerticalLayout({
  id,
  dndMeta,
  children,
}: VerticalLayout.Props) {
  const { setNodeRef } = useDroppable({ id, data: dndMeta });

  const elementRef = useElementRefs({ id, meta: dndMeta });
  const setElementRef = useCallback(
    (el: HTMLElement | null) => elementRef.set(el),
    [elementRef],
  );

  return (
    <Box sx={sxs.root} ref={setElementRef}>
      <Box sx={sxs.elements} ref={setNodeRef}>
        {children}
      </Box>
    </Box>
  );
}

export namespace VerticalLayout {
  export type Props = React.PropsWithChildren<{
    id: string;
    dndMeta: DndMeta.Droppable;
  }>;
}

const sxs = {
  root: (theme) => ({}),
  elements: (theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: 4,
    minHeight: "2rem",
  }),
} satisfies Sxs;

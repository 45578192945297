import { unreachableError } from "utils/exceptions";

import * as E from "fp-ts/Either";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import * as Exits from "./types/Exits";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Exits.Exits, State.State> {
  if (Actions.actions.userPreloadFail.is(a)) {
    return State.states.idle.is(s)
      ? E.right(State.states.login.create(s.payload))
      : E.right(s);
  }
  if (Actions.actions.userPreloadSuccess.is(a)) {
    if (State.states.idle.is(s)) {
      return E.left(
        Exits.exits.authenticated.create({
          user: a.payload.user,
          userSettings: a.payload.settings,
          goTo: s.payload.goTo,
        }),
      );
    }
    return E.right(s);
  }
  if (Actions.actions.signIn.is(a)) {
    return State.states.login.is(s) || State.states.authError.is(s)
      ? E.right(State.states.authentication.create(s.payload))
      : E.right(s);
  }
  if (Actions.actions.signInFail.is(a)) {
    return State.states.authentication.is(s)
      ? E.right(State.states.authError.create(s.payload))
      : E.right(s);
  }
  if (Actions.actions.signInSuccess.is(a)) {
    if (State.states.authentication.is(s)) {
      return E.left(
        Exits.exits.authenticated.create({
          user: a.payload.user,
          userSettings: a.payload.settings,
          goTo: s.payload.goTo,
        }),
      );
    }
    return E.right(s);
  }

  unreachableError(a);
  return E.right(s);
}

import * as Rx from "rxjs";
import { Client, DsError } from "ds";
import { Client as OpenAIClient } from "open-ai-ds";
import { getDataTypes, getDataTypesQuery } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { isOneOf } from "utils/isOneOf";
import * as Epic from "../../../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";

import { Collections } from "./states/Collections";
import { Customers } from "./states/Customers";
import { Suppliers } from "./states/Suppliers";
import { Repositories } from "./states/Repositories";
import { InventoryItems } from "./states/InventoryItems";
import { ItemMovements } from "./states/ItemMovements";
import { RepositoryMovements } from "./states/RepositoryMovements";
import { pickingOrderState } from "./utils";
import { Inbounds } from "./states/Inbounds";
import { ItemSets } from "./states/ItemSets";
import { StocksListing } from "./states/Stocks";

interface Deps {
  pyckAdminClient$: Rx.Observable<Client>;
  openAIClient$: Rx.Observable<OpenAIClient>;
  getListingVisibleColumns: (
    id: string,
  ) => Rx.Observable<Record<string, boolean>>;
  setListingVisibleColumns: (id: string, v: Record<string, boolean>) => void;
}

const loadingEpic: Epic.Epic<
  Actions.Actions,
  State.Loading | State.LoadError,
  Deps
> = (state$, { pyckAdminClient$ }) => {
  return state$.pipe(
    Rx.distinctUntilKeyChanged("type"),
    Rx.filter(State.isLoading),
    Rx.map((s) => s.payload),
    Rx.withLatestFrom(pyckAdminClient$),
    Rx.switchMap(([, client]) => {
      return Rx.from(getDataTypes(client, {})).pipe(
        Rx.map(
          flow(
            E.map((v) =>
              Actions.actions.loadSuccess.create({ dataTypes: v.items }),
            ),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.actions.loadFail.create({ type: "unknown" }),
            ),
          ),
        ),
        Rx.catchError(() =>
          Rx.of(Actions.actions.loadFail.create({ type: "unknown" })),
        ),
      );
    }),
  );
};

const subStatesEpic: Epic.Epic<Actions.Actions, State.Ready, Deps> = (
  state$,
  ds$,
) => {
  return Epic.mergeByGuard([
    [
      StocksListing.instance.isState,
      (s$: Rx.Observable<StocksListing.State>, deps) =>
        StocksListing.instance.epic(s$, {
          pyckAdminClient$: deps.pyckAdminClient$,
          getVisibleColumns: deps.getListingVisibleColumns,
          setVisibleColumns: deps.setListingVisibleColumns,
        }),
    ],
    [ItemSets.instance.isState, ItemSets.instance.epic],
    [Collections.instance.isState, Collections.instance.epic],
    [Customers.instance.isState, Customers.instance.epic],
    [Suppliers.instance.isState, Suppliers.instance.epic],
    [Repositories.instance.isState, Repositories.instance.epic],
    [InventoryItems.instance.isState, InventoryItems.instance.epic],
    [ItemMovements.instance.isState, ItemMovements.instance.epic],
    [RepositoryMovements.instance.isState, RepositoryMovements.instance.epic],
    [Inbounds.instance.isState, Inbounds.instance.epic],
    [pickingOrderState.isState, pickingOrderState.epic],
  ])(state$.pipe(Rx.map((s) => s.payload.subState)), ds$);
};

const watchDataTypes: Epic.Epic<Actions.Actions, State.State, Deps> = (
  state$,
  { pyckAdminClient$ },
) => {
  return state$.pipe(
    Rx.distinctUntilKeyChanged("type"),
    Rx.filter(State.isReady),
    Rx.map((s) => s.payload),
    Rx.withLatestFrom(pyckAdminClient$),
    Rx.switchMap(([, client]) => {
      return client.watchQuery(getDataTypesQuery({})).pipe(
        Rx.map(
          flow(
            E.map((v) => Actions.actions.dataTypesUpdated.create(v.items)),
            E.getOrElse<DsError, Actions.Actions>(() =>
              Actions.actions.loadFail.create({ type: "unknown" }),
            ),
          ),
        ),
        Rx.catchError(() =>
          Rx.of(Actions.actions.loadFail.create({ type: "unknown" })),
        ),
      );
    }),
  );
};

const byGuard = Epic.mergeByGuard([
  [isOneOf([State.isLoading, State.isLoadError]), loadingEpic],
  [State.isReady, subStatesEpic],
]);

export const epic = Epic.merge([watchDataTypes, byGuard]);

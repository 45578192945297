import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";

export type Actions<E, T> =
  | SetQuery
  | SubmitQuery
  | SearchError<E>
  | SearchSuccess<T>
  | SelectItem<T>
  | Clear;

export const isActions = <E, T>(p: string) =>
  strictGuard((a: Actions<E, T>): a is Actions<E, T> => {
    if (isSetQuery(p)(a)) return true;
    if (isSubmitQuery(p)(a)) return true;
    if (isSearchError(p)(a)) return true;
    if (isSearchSuccess(p)(a)) return true;
    if (isSelectItem(p)(a)) return true;
    if (isClear(p)(a)) return true;

    silentUnreachableError(a);
    return false;
  });

// region SetQuery
export interface SetQuery {
  type: `${string}:SetQuery`;
  payload: string;
}

export const setQuery =
  (p: string) =>
  (payload: SetQuery["payload"]): SetQuery => ({
    type: `${p}:SetQuery`,
    payload,
  });

export const isSetQuery =
  (p: string) =>
  <E, T>(a: Actions<E, T>): a is SetQuery =>
    a.type === `${p}:SetQuery`;
// endregion

// region SubmitQuery
export interface SubmitQuery {
  type: `${string}:SubmitQuery`;
}

export const submitQuery = (p: string) => (): SubmitQuery => ({
  type: `${p}:SubmitQuery`,
});

export const isSubmitQuery =
  (p: string) =>
  <E, T>(a: Actions<E, T>): a is SubmitQuery =>
    a.type === `${p}:SubmitQuery`;
// endregion

// region SearchError
export interface SearchError<E> {
  type: `${string}:SearchError`;
  payload: E;
}

export const searchError =
  (p: string) =>
  <E>(payload: SearchError<E>["payload"]): SearchError<E> => ({
    type: `${p}:SearchError`,
    payload,
  });

export const isSearchError =
  (p: string) =>
  <E, T>(a: Actions<E, T>): a is SearchError<E> =>
    a.type === `${p}:SearchError`;
// endregion

// region SearchSuccess
export interface SearchSuccess<T> {
  type: `${string}:SearchSuccess`;
  payload: T[];
}

export const searchSuccess =
  (p: string) =>
  <T>(payload: SearchSuccess<T>["payload"]): SearchSuccess<T> => ({
    type: `${p}:SearchSuccess`,
    payload,
  });

export const isSearchSuccess =
  (p: string) =>
  <E, T>(a: Actions<E, T>): a is SearchSuccess<T> =>
    a.type === `${p}:SearchSuccess`;
// endregion

// region SelectItem
export interface SelectItem<T> {
  type: `${string}:SelectItem`;
  payload: T;
}

export const selectItem =
  (p: string) =>
  <T>(payload: SelectItem<T>["payload"]): SelectItem<T> => ({
    type: `${p}:SelectItem`,
    payload,
  });

export const isSelectItem =
  (p: string) =>
  <E, T>(a: Actions<E, T>): a is SelectItem<T> =>
    a.type === `${p}:SelectItem`;
// endregion

// region Clear
export interface Clear {
  type: `${string}:Clear`;
}

export const clear = (p: string) => (): Clear => ({
  type: `${p}:Clear`,
});

export const isClear =
  (p: string) =>
  <E, T>(a: Actions<E, T>): a is Clear =>
    a.type === `${p}:Clear`;
// endregion

import * as O from "fp-ts/Option";
import { DateRange } from "types/src/date/DateRange";
import {
  DataTypeEntity,
  DataTypeId,
  DataType,
} from "types/src/DataType/DataType";
import { ISODate } from "types/src/date/ISODate";
import { Option } from "fp-ts/Option";
import * as Rx from "rxjs";
import { Client, isNotFoundError } from "ds";
import {
  deleteInventoryItems,
  getInventoryItems,
  GetInventoryItemsVars,
} from "ds/InventoryItems";
import { getDataType, getDataTypes } from "ds/DataTypes";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { Sku } from "types/src/Sku";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Tuple } from "types/src/Tuple";
import { RepositoryId } from "types/src/Repositories/Repository";
import { getRepositories } from "ds/Repositories";
import { strictGuard } from "utils/strictGuard";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingWithDataType } from "../../../../../../../../generic-states/ListingWithDataType";
import { Loading } from "../../../../../../../../generic-states/Loading";

const prefix = "Ready:DataManager:InventoryItems:Listing";

interface Repo {
  id: RepositoryId;
  name: string;
}

function createState() {
  const repositoriesState = Loading.createState<Repo[], string | undefined>(
    `${prefix}:repositories`,
    { equals: (a, b) => a === b },
  );
  const state = ListingWithDataType.createState<
    InventoryItemsListing.Filters,
    InventoryItemsListing.Item,
    "createdAt" | "updatedAt" | "sku",
    {
      id: DataTypeId;
      repositories: Loading.GetState<typeof repositoriesState>;
    }
  >(prefix, {
    defaultFilters: {},
  });

  type Actions =
    | ListingWithDataType.GetActions<typeof state>
    | Loading.GetActions<typeof repositoriesState>;
  type State = ListingWithDataType.GetState<typeof state>;
  type Exits = ListingWithDataType.GetExits<typeof state>;

  const epic: Epic<Actions, State, InventoryItemsListing.Deps> = (
    state$,
    deps,
  ) => {
    const main$ = state.epic(state$, {
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(getInventoryItems(client, getFetchVars(s))),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: { in: [DataTypeEntity.Item] },
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;

                    return E.right({
                      items: v.items.right,
                      dataTypes: Fp.pipe(
                        v.dataTypes,
                        E.map((v) => v.items),
                        E.getOrElse(() => [] as DataType[]),
                      ),
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      id: i.id,
                      createdAt: i.createdAt,
                      updatedAt: O.fromNullable(i.updatedAt),
                      sku: i.sku,
                      dataType: O.fromNullable(
                        r.dataTypes.find((v) => v.id === i.dataTypeId),
                      ),
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(deleteInventoryItems(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
      fetchDataType: (id) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            return Rx.from(getDataType(client, id)).pipe(
              Rx.map(E.map(O.some)),
              Rx.map(
                E.orElse((e) => {
                  if (isNotFoundError(e)) return E.right(O.none);

                  return E.left(e);
                }),
              ),
            );
          }),
        );
      },
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
    });

    const repositories$ = repositoriesState.epic(
      state$.pipe(Rx.map((s) => s.payload.repositories)),
      {
        get: (q) => {
          return deps.pyckAdminClient$.pipe(
            Rx.switchMap((client) => {
              return Rx.from(
                getRepositories(client, {
                  where: {
                    or: [{ name: { containsFold: q } }],
                  },
                }),
              );
            }),
            Rx.map(
              E.map((r) => {
                return r.items.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              }),
            ),
          );
        },
      },
    );

    return Rx.merge(main$, repositories$);
  };

  const reducer = (s: State, a: Actions): E.Either<Exits, State> => {
    if (repositoriesState.isAction(a)) {
      const st = repositoriesState.reducer(s.payload.repositories, a);

      return E.right({
        ...s,
        payload: {
          ...s.payload,
          repositories: Fp.pipe(
            st,
            E.getOrElseW(() => s.payload.repositories),
          ),
        },
      } as State);
    }

    return state.reducer(s, a);
  };

  return {
    ...state,
    isAction: strictGuard(
      (a: Actions): a is Actions =>
        state.isAction(a) || repositoriesState.isAction(a),
    ),
    reducer,
    epic,
    init: (p: { id: DataTypeId }) => {
      return state.init({
        id: p.id,
        repositories: repositoriesState.init(undefined),
      });
    },
    subStates: {
      ...state.subStates,
      repositories: repositoriesState,
    },
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetInventoryItemsVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetInventoryItemsVars["where"] = {
      and: [
        {
          createdAt: {
            gte: fields.createdAt?.[0],
            lte: fields.createdAt?.[1],
          },
          updatedAt: {
            gte: fields.createdAt?.[0],
            lte: fields.createdAt?.[1],
          },
        },
      ],
      or: [
        { sku: { containsFold: fields.search } },
        {
          data: {
            contains: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.chain(NoEmptyString.fromString),
              O.map(Tuple.create("")),
              O.toUndefined,
            ),
          },
        },
      ],
      dataType: {
        in: [s.payload.id],
        isNil: Fp.pipe(
          fields.status,
          O.fromNullable,
          O.map((v) => v === "orphan"),
          O.toUndefined,
        ),
      },
      sku: { containsFold: fields.sku },
      hasStocksWith: [
        {
          hasRepositoryWith: [
            {
              id: {
                in: fields.repositories,
              },
            },
          ],
        },
      ],
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
}

export namespace InventoryItemsListing {
  export type Filters = Partial<{
    createdAt: DateRange;
    updatedAt: DateRange;
    search: string;
    status: "active" | "orphan";
    sku: string;
    repositories: RepositoryId[];
  }>;

  export interface Item {
    id: InventoryItemId;
    createdAt: ISODate;
    updatedAt: Option<ISODate>;
    dataType: O.Option<{ id: DataTypeId; name: string }>;
    sku: Sku;
  }

  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: (
      id: DataTypeId,
    ) => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (id: DataTypeId, v: Record<string, boolean>) => void;
  }

  export const instance = createState();

  export type State = ListingWithDataType.GetState<typeof instance>;
  export type Actions = ListingWithDataType.GetActions<typeof instance>;
  export type Exits = ListingWithDataType.GetExits<typeof instance>;
}

import { RootState } from "state-manager";
import { useMemo } from "react";
import { DataTypeForm, DataTypeFormProps } from "@Containers/Form/DataTypeForm";
import { flow } from "fp-ts/function";
import { DataTypeCreate as Create } from "state-manager/states/Ready/states/DataTypeCreate";

export interface ContentProps {
  selector: (s: RootState) => Create.State;
  dispatch: (a: Create.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const selectors = useMemo((): DataTypeFormProps["selector"] => {
    return flow(selector, (s) => ({
      name: s.payload.name || "",
      description: s.payload.description || "",
      default: s.payload.default,
      entity: s.payload.entity,
      schema: s.payload.schema.payload.schema,
      uiSchema: s.payload.schema.payload.uiSchema,
    }));
  }, [selector]);
  const dispatcher = useMemo<DataTypeFormProps["dispatch"]>(
    () => ({
      setEntity: flow(Create.instance.actions.setEntity.create, dispatch),
      setName: flow(Create.instance.actions.setName.create, dispatch),
      setDescription: flow(
        Create.instance.actions.setDescription.create,
        dispatch,
      ),
      setDefault: flow(Create.instance.actions.setDefault.create, dispatch),
      setSchemas: flow(
        Create.instance.subStates.schema.actions.reset.create,
        dispatch,
      ),
    }),
    [dispatch],
  );

  return <DataTypeForm selector={selectors} dispatch={dispatcher} />;
}

import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import * as E from "fp-ts/Either";
import type { DocumentNode } from "graphql/index";
import { OperationVariables } from "@apollo/client";
import * as Fp from "fp-ts/function";
import * as DsError from "../type/DsError";

export interface FetchOptions {
  includeDeleted?: boolean;
}

export interface Query<TVariables extends OperationVariables, TData, Output> {
  input: {
    query: DocumentNode | TypedDocumentNode<TData, TVariables>;
    variables?: TVariables;
    options?: FetchOptions;
  };
  output: (
    output: E.Either<DsError.DsError, TData>,
  ) => E.Either<DsError.DsError, Output>;
}

export namespace Query {
  export function create<TVariables extends OperationVariables, TData, Output>(
    input: Query<TVariables, TData, Output>["input"],
    output: Query<TVariables, TData, Output>["output"],
  ): Query<TVariables, TData, Output> {
    return { input, output };
  }

  export function map<TVariables extends OperationVariables, TData, Output, B>(
    fn: (v: E.Either<DsError.DsError, Output>) => E.Either<DsError.DsError, B>,
    q: Query<TVariables, TData, Output>,
  ): Query<TVariables, TData, B> {
    return Query.create(q.input, Fp.flow(q.output, fn));
  }
}

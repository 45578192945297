import React from "react";
import {
  showAsRequired,
  ControlProps,
  isDescriptionHidden,
} from "@jsonforms/core";

import { InputLabel, FormControl, FormHelperText } from "@mui/material";
import { useInputVariant } from "@jsonforms/material-renderers";
import { mergeWith } from "rambda";
import { useControlState } from "@layouts/JsonForm/contexts/ControlState";
import { useBehaviorValue } from "react-rx/behaviorValue";

export namespace Control {
  export interface Props extends ControlProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    input: any;
  }
}

export const Control = (props: Control.Props) => {
  const {
    id,
    description,
    errors,
    label,
    uischema,
    visible,
    required,
    config,
    input: InnerComponent,
  } = props;
  const { onFocus, onBlur, touched$ } = useControlState(id);
  const touched = useBehaviorValue(touched$);

  const variant = useInputVariant();
  const isValid = errors.length === 0;
  const appliedUiSchemaOptions = merge(config, uischema.options) as Partial<{
    showUnfocusedDescription: boolean;
    hideRequiredAsterisk: boolean;
    trim: boolean;
  }>;

  const showDescription = !isDescriptionHidden(
    visible,
    description,
    true,
    appliedUiSchemaOptions.showUnfocusedDescription ?? true,
  );

  const errorText = showDescription ? description : !isValid ? errors : null;
  const secondFormHelperText = showDescription && !isValid ? errors : null;

  if (!visible) {
    return null;
  }

  return (
    <FormControl
      fullWidth={!appliedUiSchemaOptions.trim}
      onFocus={onFocus}
      onBlur={onBlur}
      variant={variant}
      id={id}
    >
      <InputLabel
        htmlFor={id + "-input"}
        error={touched && !isValid}
        required={showAsRequired(
          required ?? false,
          appliedUiSchemaOptions.hideRequiredAsterisk ?? false,
        )}
      >
        {label}
      </InputLabel>
      <InnerComponent
        {...props}
        id={id + "-input"}
        isValid={!touched || isValid}
        visible={visible}
      />
      {errorText && touched && (
        <FormHelperText error={!isValid && !showDescription}>
          {errorText}
        </FormHelperText>
      )}
      {secondFormHelperText && (
        <FormHelperText error={!isValid}>{secondFormHelperText}</FormHelperText>
      )}
    </FormControl>
  );
};

const merge = mergeWith((a, b) => b ?? a);

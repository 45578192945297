import { useTranslation } from "i18n";
import { ListingTable } from "ui/layouts/ListingTable";
import { EntityListing } from "@Containers/Listing/EntityListing";

export const typeMap = {
  status: EntityListing.Filters.Type.select,
} satisfies EntityListing.Filters.ListingTypeMapBase;

export const stateToConfig = (
  state: {},
  { t }: Pick<ReturnType<typeof useTranslation>, "t">,
) => ({
  status: {
    type: typeMap["status"],
    options: {
      label: t("Status"),
      choices: [
        { id: "active", label: t("Active") },
        { id: "orphan", label: t("Orphan") },
      ],
    },
  } satisfies ListingTable.CustomFilterConfig,
});

export const converters: EntityListing.Filters.ListingConvertersBase<
  { status?: "active" | "orphan" },
  typeof typeMap
> = {
  status: {
    toListing: (id) => ({ id }),
    toState: (v) => v?.id as "active" | "orphan",
  },
};

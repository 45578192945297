import { Validation as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor/controlTypes/string";
import { useMemo } from "react";
import * as O from "fp-ts/Option";
import { ControlTypeUI } from "../../../types/control/ui";
import { ControlType } from "../../../types/control/type";
import { pick } from "../../../utils/object";
import { useBehaviorOptionValue } from "../../../hooks/useBehaviorOptionValue";

const supportedKeys = [
  "minLength",
  "maxLength",
  "pattern",
  "showError",
  "errorMessage",
] satisfies Array<keyof UI.Values>;

export const Validation: ControlTypeUI<ControlType.string>["tabs"][string]["Content"] =
  ({ formData$: _formData$, onValuesChange }) => {
    const formData$ = useBehaviorOptionValue({
      option$: useMemo(
        () =>
          _formData$.map(({ values, errors }) =>
            values.format === "text"
              ? O.some({
                  values: pick(supportedKeys, values),
                  errors: pick(supportedKeys, errors),
                })
              : O.none,
          ),
        [_formData$],
      ),
    });

    return formData$ ? <UI {...{ formData$, onValuesChange }} /> : null;
  };

import { Customer } from "types/src/Customers/Customer";
import {
  Inbound,
  InboundCreate,
  InboundItemsCreate,
  InboundUpdate,
} from "types/src/Inbounds/Inbound";
import { SupplierId } from "types/src/Supplier/Supplier";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import {
  CreateReceivingInboundItemsInput,
  CreateReceivingInboundWithItemsInput,
  InboundFragmentFragment,
  UpdateReceivingInboundInput,
} from "../generated/graphql";
import { inboundItemFragmentToInboundItem } from "./InboundItem";

export function inboundFragmentToInbound(
  fragment: InboundFragmentFragment,
): Inbound {
  return {
    id: fragment.id as Inbound["id"],
    fields: (fragment.data ?? {}) as Inbound["fields"],
    createdAt: fragment.createdAt,
    updatedAt: fragment.updatedAt ?? undefined,
    dataTypeId: fragment.dataTypeID as Customer["dataTypeId"],
    supplierId: (fragment.supplierID as SupplierId) ?? undefined,
    items: fragment.inbounditems?.map(inboundItemFragmentToInboundItem) ?? [],
  };
}

export function inboundCreateToApiInboundCreate(
  v: InboundCreate,
): CreateReceivingInboundWithItemsInput {
  return {
    data: v.fields,
    dataTypeID: v.dataTypeId,
    supplierID: v.supplierID,
    inboundItems: v.items.map(inboundItemsCreateToApiInboundItemsCreate),
  };
}

export function inboundItemsCreateToApiInboundItemsCreate(
  v: InboundItemsCreate,
): CreateReceivingInboundItemsInput {
  return {
    dataTypeID: v.dataTypeId,
    data: v.fields,
    sku: v.sku,
    quantity: v.quantity,
  };
}

export function inboundUpdateToApiInboundUpdate(
  v: InboundUpdate,
): UpdateReceivingInboundInput {
  return {
    data: v.fields ?? undefined,
    supplierID: v.supplierId,
    dataTypeID: v.dataTypeId ?? undefined,
    addInboundItemIDs: v.addInboundItemIds,
    clearData: v.fields === null,
    clearDataTypeID: v.dataTypeId === null,
    removeInboundItemIDs: Fp.pipe(
      v.removeInboundItemIds,
      O.fromPredicate(Array.isArray),
      O.toUndefined,
    ),
    clearInboundItems: v.removeInboundItemIds === "all",
  };
}

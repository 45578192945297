import { gql } from "@apollo/client";
import { Customer, CustomerId } from "types/src/Customers/Customer";
import { flow, pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Cursor } from "types";
import { isT } from "fp-utilities";
import { DataTypeId } from "types/src/DataType/DataType";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { ISODate } from "types/src/date/ISODate";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import { customerFragmentToCustomer } from "../../transformers/Customers";
import { customerFragment } from "../../fragments/Customer";
import { Client, notFoundError } from "../../index";
import {
  CustomerOrderField,
  GetCustomersQuery,
  GetCustomersQueryVariables,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResponse,
  QueryResponse,
} from "../../type/QueryResponse";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";
import { Where } from "../../type/Where";

const whereSchema = Where.create({
  id: Where.eq<CustomerId>(),
  data: Where.map("Data"),
  createdAt: Where.ord<ISODate>(),
  updatedAt: Where.ord<ISODate>(),
  dataType: Where.eqNil<DataTypeId>("dataTypeID"),
});

export interface GetCustomersVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: Where.GetType<typeof whereSchema>;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
}
export function getCustomers(
  client: Client,
  vars: GetCustomersVars,
): Promise<PaginatedQueryResponse<Customer>> {
  const query = gql`
    ${pageInfoFragment}
    ${customerFragment}

    query GetCustomers(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: CustomerWhereInput
      $orderBy: CustomerOrder
    ) {
      customers(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...CustomerFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetCustomersQuery, GetCustomersQueryVariables>({
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toUndefined,
        ),
        orderBy: pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: CustomerOrderField.CreatedAt,
              updatedAt: CustomerOrderField.UpdatedAt,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    })
    .then(
      E.map(
        flow(
          (r) => r.customers,
          getPaginatedQueryResult(customerFragmentToCustomer),
        ),
      ),
    );
}

export function getCustomer(
  client: Client,
  id: CustomerId,
): Promise<QueryResponse<Customer>> {
  return getCustomers(client, { where: { id: { eq: id } } }).then(
    flow(
      E.map((r) => r.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}

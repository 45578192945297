import { BehaviorValue } from "rx-addons/BehaviorValue";
import { TextField } from "@mui/material";
import { useRandomId } from "react-utils/hooks";
import { useFormDataProp } from "../../hooks/useFormDataProp";
import { InputWithLabel } from "../InputWithLabel";
import { sxFullWidth } from "../../../../common/sxs";

type Value = number;

export const FormDataNumber = <Prop extends string>({
  formData$,
  formDataProp,
  onValuesChange,

  label,
  placeholder,
  min,
  max,
}: {
  formDataProp: Prop;
  formData$: BehaviorValue<{
    values: Partial<Record<Prop, Value>>;
    errors: Partial<Record<Prop, string>>;
  }>;
  onValuesChange: (changes: Partial<Record<Prop, Value>>) => void;

  label: string;
  placeholder?: string;

  min?: number;
  max?: number;
}) => {
  const { value, error } = useFormDataProp({
    formData$,
    prop: formDataProp,
  });
  const randomId = useRandomId();

  return (
    <InputWithLabel label={label} id={randomId}>
      <TextField
        id={randomId}
        sx={sxFullWidth}
        placeholder={placeholder}
        value={value ?? ""}
        onChange={({ target: { value: value_ } }) => {
          const value = Number(value_);
          onValuesChange({
            [formDataProp]: isNaN(value) ? undefined : value,
          } as /*why doesn't work without cast?*/ Record<Prop, Value>);
        }}
        error={!!error?.length}
        type="number"
        slotProps={{ htmlInput: { min, max } }}
      />
    </InputWithLabel>
  );
};

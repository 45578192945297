import { Typed } from "utils/Typed";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { Client, DsError, unknownError } from "ds";
import { strictGuard } from "utils/strictGuard";
import * as E from "fp-ts/Either";
import * as Fp from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { createDataType } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import { UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { Epic } from "../../../../types/RootEpic";
import { DataTypeSchema } from "../../../../generic-states/Schema";

const prefix = "Ready:DataTypes:Create";

const schema = DataTypeSchema.createState(`${prefix}:Schema`);

interface Payload {
  name: string;
  description: string | undefined;
  default: boolean;
  entity: DataTypeEntity;
  schema: Typed.GetTypes<typeof schema.states>;
}
const states = Typed.builder
  .add("ready", (p: Payload) => p)
  .add("saving", (p: Payload) => p)
  .finish()(prefix);

const actions = Typed.builder
  .add("setEntity", (p: DataTypeEntity) => p)
  .add("setName", (p: string) => p)
  .add("setDescription", (p: string) => p)
  .add("setDefault", (p: boolean) => p)
  .add("saveSuccess", (p: DataTypeId) => p)
  .add("saveFail", (p: DsError) => p)
  .add("submit")
  .add("cancel")
  .finish()(`${prefix}:Actions`);

const exits = Typed.builder
  .add("created", (p: DataTypeId) => p)
  .add("canceled")
  .finish()(`${prefix}:Exits`);

export namespace DataTypeCreate {
  export type CreateActions = Typed.GetTypes<typeof actions>;
  export type SchemaActions = Typed.GetTypes<typeof schema.actions>;
  export type Actions = CreateActions | SchemaActions;
  export type State = Typed.GetTypes<typeof states>;
  export type Exits = Typed.GetTypes<typeof exits>;

  const epic: Epic<Actions, State, { pyckAdminClient$: Observable<Client> }> = (
    state$,
    { pyckAdminClient$ },
  ) => {
    return state$.pipe(
      distinctUntilKeyChanged("type"),
      filter(states.saving.is),
      withLatestFrom(pyckAdminClient$),
      switchMap(([s, client]) => {
        const vars: Parameters<typeof createDataType>[1] = {
          name: s.payload.name,
          description: s.payload.description,
          default: s.payload.default,
          entity: s.payload.entity,
          jsonSchema: s.payload.schema.payload.schema,
          frontendSchema: s.payload.schema.payload.uiSchema,
        };

        return from(createDataType(client, vars)).pipe(
          map(
            flow(
              E.map((r) => actions.saveSuccess.create(r.id)),
              E.getOrElseW(actions.saveFail.create),
            ),
          ),
          catchError(() => of(actions.saveFail.create(unknownError()))),
        );
      }),
    );
  };

  export const instance = {
    states,
    actions,
    exits,
    isState: Typed.getGuard(states),
    isAction: strictGuard(
      (a: Actions): a is Actions =>
        Typed.getGuard(actions)(a) || schema.isAction(a),
    ),
    reducer: (s: State, a: Actions): E.Either<Exits, State> => {
      if (schema.isAction(a)) {
        return states.ready.is(s)
          ? E.right(
              states.ready.create({
                ...s.payload,
                schema: Fp.pipe(
                  schema.reducer(s.payload.schema, a),
                  E.getOrElse((e) => {
                    silentUnreachableError(e);
                    return s.payload.schema;
                  }),
                ),
              }),
            )
          : E.right(s);
      }

      if (actions.setEntity.is(a)) {
        return states.ready.is(s)
          ? E.right(states.ready.create({ ...s.payload, entity: a.payload }))
          : E.right(s);
      }

      if (actions.setName.is(a)) {
        return states.ready.is(s)
          ? E.right(states.ready.create({ ...s.payload, name: a.payload }))
          : E.right(s);
      }

      if (actions.setDescription.is(a)) {
        return states.ready.is(s)
          ? E.right(
              states.ready.create({ ...s.payload, description: a.payload }),
            )
          : E.right(s);
      }

      if (actions.setDefault.is(a)) {
        return states.ready.is(s)
          ? E.right(states.ready.create({ ...s.payload, default: a.payload }))
          : E.right(s);
      }

      if (actions.submit.is(a)) {
        return states.ready.is(s)
          ? E.right(states.saving.create(s.payload))
          : E.right(s);
      }

      if (actions.cancel.is(a)) {
        return E.left(exits.canceled.create());
      }

      if (actions.saveSuccess.is(a)) {
        return states.saving.is(s)
          ? E.left(exits.created.create(a.payload))
          : E.right(s);
      }

      if (actions.saveFail.is(a)) {
        return states.saving.is(s)
          ? E.right(states.ready.create(s.payload))
          : E.right(s);
      }

      silentUnreachableError(a);
      return E.right(s);
    },
    epic,
    init: () =>
      states.ready.create({
        name: "",
        description: "",
        default: false,
        schema: schema.init({
          schema: {},
          uiSchema: {
            type: UiSchemaElementType.verticalLayout,
            elements: [],
          },
        }),
        entity: DataTypeEntity.Item,
      }),
    subStates: {
      schema,
    },
  };
}

import React, { useMemo } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue } from "react-rx/behaviorValue";
import TableBody from "@mui/material/TableBody";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { getColumnsOrder } from "@layouts/ListingTable/utils/columns";
import { BulkSelect } from "@layouts/ListingTable/List/Table/BulkSelect";
import { DataEntryBase, EntryAction, ListData } from "../../../types/data";
import { ColumnsConfigBase, ColumnsVisibility } from "../../../types/columns";
import { isActionsColumnVisible } from "../utils";
import { CellType } from "../CellType";
import { EntryActions } from "./EntryActions";

export namespace Body {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  > = {
    columns: ColumnsConfig;
    columnsVisibility$: BehaviorValue<
      Partial<Record<keyof ColumnsConfig, boolean>>
    >;
    onColumnsVisibilityChange?: (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;

    data$: BehaviorValue<ListData<DataEntry>>;
    entryActions?: (entry: DataEntry) => EntryAction[];
    bulkSelect:
      | {
          selected$: BehaviorValue<Array<DataEntry["id"]>>;
          unselectable$: BehaviorValue<Array<DataEntry["id"]>>;
          onSelect: (id: DataEntry["id"]) => void;
        }
      | undefined;
  };
}

export const Body = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
>({
  columns,
  columnsVisibility$,
  onColumnsVisibilityChange,
  data$,
  entryActions,
  bulkSelect,
}: Body.Props<DataEntry, ColumnsConfig>) => {
  const columnsVisibility = useBehaviorValue(columnsVisibility$);
  const entries = useBehaviorValue(
    useMemo(() => data$.map(({ entries }) => entries), [data$]),
  );
  const columnsOrder = useMemo(
    () => getColumnsOrder<DataEntry, ColumnsConfig>(columns, columnsVisibility),
    [columns, columnsVisibility],
  );

  const actionsVisible = isActionsColumnVisible({
    entryActions,
    onColumnsVisibilityChange,
  });

  return (
    <TableBody>
      {entries.map((entry) => {
        const actions = entryActions?.(entry) || [];

        return (
          <TableRow key={entry.id}>
            {bulkSelect && (
              <BulkSelect
                id={entry.id}
                selected$={bulkSelect.selected$}
                disabled$={bulkSelect.unselectable$}
                onSelect={() => bulkSelect.onSelect(entry.id)}
              />
            )}
            {columnsOrder.map((columnId) => {
              const column = columns[columnId];

              return (
                <TableCell key={columnId.toString()} sx={sxCell}>
                  {
                    // fixme: Why `column` is `| undefined`?
                    //   Above is `column: ColumnsConfig[keyof ColumnsConfig]`
                    column && <CellType {...{ column, entry }} />
                  }
                </TableCell>
              );
            })}
            {actionsVisible && (
              <TableCell
                key="_actions"
                // @ts-expect-error, fix later
                sx={[sxCell, { textAlign: "right" }]}
              >
                {!!actions.length && <EntryActions {...{ entry, actions }} />}
              </TableCell>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

const sxCell: SxProps<Theme> = (theme) => ({
  ...theme.typography.body2,
  padding: theme.spacing(3, 6),
});

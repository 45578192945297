import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import * as Fp from "fp-ts/function";
import { Typed } from "utils/Typed";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import * as Exits from "./types/Exits";
import {
  createCustomerSearchState,
  createSchemaFieldsState,
  createPickingOrderItemsState,
} from "./utils";

export const reducer = (p: string) => {
  const exits = Exits.exits(`${p}:exits`);
  const customerSearch = createCustomerSearchState(p);
  const schemaFields = createSchemaFieldsState(p);
  const pickingOrderItemsState = createPickingOrderItemsState(p);
  const isLoadFail = Actions.isLoadFail(p);
  const isLoadSuccess = Actions.isLoadSuccess(p);
  const isSubmit = Actions.isSubmit(p);
  const isSaveFail = Actions.isSaveFail(p);
  const isSaveSuccess = Actions.isSaveSuccess(p);
  const isLoadError = State.isLoadError(p);
  const isLoading = State.isLoading(p);
  const isReady = State.isReady(p);
  const loadError = State.loadError(p);
  const ready = State.ready(p);
  const saving = State.saving(p);
  const isSaving = State.isSaving(p);

  return (
    s: State.State,
    a: Actions.Actions,
  ): E.Either<Typed.GetTypes<typeof exits>, State.State> => {
    if (isLoadFail(a)) {
      if (!isLoading(s)) return E.right(s);

      return E.right(
        loadError({
          ...s.payload,
          error: a.payload,
        }),
      );
    }

    if (isLoadSuccess(a)) {
      if (!isLoading(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          items: pickingOrderItemsState.init({
            items: {},
            dataTypes: a.payload.dataTypes,
          }),
          fields: schemaFields.init({
            schema: a.payload.dataType.schema,
            uiSchema: a.payload.dataType.ui,
            values: {},
          }),
          customer: customerSearch.init(),
          dataTypes: a.payload.dataTypes,
          submitted: false,
        }),
      );
    }

    if (isSubmit(a)) {
      if (!isReady(s)) return E.right(s);

      const items = Fp.pipe(
        pickingOrderItemsState.reducer(
          s.payload.items,
          pickingOrderItemsState.actions.submit.create(),
        ),
        E.getOrElse(() => s.payload.items),
      );
      const customer = s.payload.customer;
      const schema = Fp.pipe(
        schemaFields.reducer(
          s.payload.fields,
          schemaFields.actions.submit.create(),
        ),
        E.getOrElse(() => s.payload.fields),
      );

      if (
        customerSearch.states.selected.is(customer) &&
        pickingOrderItemsState.states.valid.is(items) &&
        schemaFields.states.valid.is(schema)
      ) {
        return E.right(
          saving({
            ...s.payload,
            fields: schema,
            customer,
            items,
          }),
        );
      }

      return E.right(
        ready({
          ...s.payload,
          items,
          customer,
          fields: schema,
          submitted: true,
        }),
      );
    }

    if (isSaveFail(a)) {
      if (!isSaving(s)) return E.right(s);

      return E.right(ready(s.payload));
    }

    if (isSaveSuccess(a)) {
      if (!isSaving(s)) return E.right(s);

      return E.left(exits.created.create(a.payload));
    }

    if (customerSearch.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          customer: customerSearch.reducer(s.payload.customer, a),
        }),
      );
    }

    if (schemaFields.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          fields: Fp.pipe(
            schemaFields.reducer(s.payload.fields, a),
            E.getOrElse(() => s.payload.fields),
          ),
        }),
      );
    }

    if (pickingOrderItemsState.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          items: Fp.pipe(
            pickingOrderItemsState.reducer(s.payload.items, a),
            E.getOrElse(() => s.payload.items),
          ),
        }),
      );
    }

    silentUnreachableError(a);
    return E.right(s);
  };
};

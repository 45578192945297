import React from "react";
import { Typography } from "ui/components/Typography";
import { styled, Theme } from "@mui/material/styles";
import { SxProps } from "@mui/material";

export namespace Title {
  export type Props = {
    title: React.ReactNode;
    parentPage?: React.ReactNode;
    sx?: SxProps<Theme>; // this component is internal
  };
}

export const Title: React.FC<Title.Props> = ({ title, parentPage, sx }) => {
  return (
    <Wrapper sx={sx}>
      {parentPage && (
        <ParentPageTitle variant="subtitle1">{parentPage}</ParentPageTitle>
      )}
      <TitleText>{title}</TitleText>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing(1)};
`;

const TitleText: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Typography variant="h4">{children}</Typography>
);

const ParentPageTitle = styled(Typography)`
  opacity: 0.35;
`;

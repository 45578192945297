import React, { useMemo } from "react";
import { Box, Button, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { usePopoverController } from "@layouts/ListingTable/Popover/hooks/usePopoverController";
import { CheckboxesList } from "@layouts/ListingTable/CheckboxesList";
import { sxsCommon } from "@layouts/ListingTable/filters/custom/common/sxs";
import { Popover } from "@layouts/ListingTable/Popover";
import { ChevronDown } from "@layouts/ListingTable/filters/custom/common/ChevronDown";
import { Search } from "@layouts/ListingTable/filters/custom/common/Search";
import { RemoveButton } from "../common/RemoveButton";
import {
  FilterPropsCustom,
  FilterTypeMultiChoice,
} from "../../../types/filters";
import { Error, Loading, NotFound } from "../common/States";

export const MultiChoice = ({
  config: { options },
  value,
  onChange,
  onRemove,
}: FilterPropsCustom<FilterTypeMultiChoice>) => {
  const { openPopover, popoverOpen, popoverProps } = usePopoverController();
  const labelSelectedChoices = useMemo(
    () =>
      (options.choices || [])
        .filter(({ id }) => value?.choices?.[id])
        .map(({ label }) => label)
        .join(", "),
    [options.choices, value?.choices],
  );

  return (
    <>
      <Button
        disabled={popoverOpen || !options.choices.length}
        sx={sxs.button}
        onClick={openPopover}
      >
        <Box
          component="span"
          sx={labelSelectedChoices.length ? undefined : sxs.labelNoValue}
        >
          {labelSelectedChoices || options.label}
        </Box>
        {popoverOpen ? <ChevronDown /> : <RemoveButton onClick={onRemove} />}
      </Button>
      {popoverOpen !== undefined && (
        <Popover {...popoverStaticProps} {...popoverProps}>
          {options.onSearch ? (
            <Search value={options?.search ?? ""} onChange={options.onSearch} />
          ) : null}
          {(options?.state ?? "ready") === "ready" && options.choices.length ? (
            <CheckboxesList
              items={options.choices}
              value={value?.choices || {}}
              onChange={(choices) => onChange({ choices })}
            />
          ) : null}
          {(options?.state ?? "ready") === "ready" &&
          !options.choices.length ? (
            <NotFound />
          ) : null}
          {options?.state === "error" ? <Error /> : null}
          {options?.state === "loading" ? <Loading /> : null}
        </Popover>
      )}
    </>
  );
};

const sxs = {
  button: (theme) => ({
    ...sxsCommon.filterAsButton(theme),
  }),
  labelNoValue: () => ({ opacity: 0.3 }),
} satisfies Record<string, SxProps<Theme>>;

const popoverStaticProps: Partial<Popover.Props> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

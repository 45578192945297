import { DataTypeId } from "../DataType/DataType";
import { ISODate } from "../date/ISODate";
import { UUID } from "../UUID";
import { DataSchemaValue } from "../jsonSchema/DataSchemaValue";

// region SupplierId
declare const _supplierId: unique symbol;

export type SupplierId = UUID & { [_supplierId]: "SupplierId" };

export namespace SupplierId {
  export const fromString = UUID.fromString<SupplierId>;
}
// endregion

export interface Supplier {
  id: SupplierId;
  dataTypeId: DataTypeId;
  fields: DataSchemaValue;
  createdAt: ISODate;
  updatedAt: ISODate | undefined;
  deletedAt: ISODate | undefined;
}

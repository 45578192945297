import * as Mui from "@mui/material";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";

export namespace Skeleton {
  export interface Props {
    contentWidth?: number;
  }
}

export const Skeleton = (p: Skeleton.Props) => {
  return <Mui.Skeleton width={toCh(p.contentWidth)} />;
};

const toCh = Fp.flow(
  O.fromNullable<number | undefined>,
  O.map((v) => `${v}ch`),
  O.toUndefined,
);

import { RootState, Selector, SelectorValue, useSelector } from "state-manager";
import { InboundEdit as Main } from "state-manager/states/Ready/states/DataManager/states/Inbounds/states/Edit";
import * as Fp from "fp-ts/function";
import { Loading } from "@Pages/Loading";

import { useTranslation } from "i18n";
import { DeleteModal } from "ui/layouts/Dialogs/DeleteModal";
import { InboundForm, InboundFormProps } from "@Containers/Form/InboundForm";
import { TranslatedStr } from "types/src/TranslatedStr";

const {
  states,
  actions,
  subStates: { suppliers },
} = Main.instance;

export interface ContentProps {
  selector: Selector<Main.State>;
  dispatch: (a: Main.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const formSelector = useSelector(
    Fp.flow(selector, (s) => {
      if (states.loading.is(s) || states.loadError.is(s))
        return { type: "loading" } as const;

      return {
        type: "ready",
        value$: Fp.flow(
          selector,
          (v) => v as typeof s,
          (s): SelectorValue<InboundFormProps<Main.ItemId>["selector"]> => ({
            schema: s.payload.schema,
            items: s.payload.items,
            supplier: s.payload.supplier,
            suppliers: suppliers.states.ready.is(s.payload.suppliers)
              ? s.payload.suppliers.payload.data.map((v) => ({
                  value: v.id,
                  label: v.name as TranslatedStr,
                }))
              : [],
          }),
        ),
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (formSelector.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <>
          <RemoveConfirmHandler selector={selector} dispatch={dispatch} />
          <InboundForm
            selector={formSelector.value$}
            dispatch={{
              items: Fp.flow(actions.items.create, dispatch),
              schema: Fp.flow(actions.schema.create, dispatch),
              onAdd: Fp.flow(actions.addItem.create, dispatch),
              onRemove: Fp.flow(actions.removeItem.create, dispatch),
              onSupplierSearch: Fp.flow(
                suppliers.actions.setQuery.create,
                actions.suppliers.create,
                dispatch,
              ),
              onSupplierChange: Fp.flow(actions.setSupplier.create, dispatch),
            }}
            itemsInstance={Main.instance.subStates.items}
            schemaInstance={Main.instance.subStates.schema}
          />
        </>
      );
  }
}

function RemoveConfirmHandler(p: {
  selector: (s: RootState) => Main.State;
  dispatch: (a: Main.Actions) => void;
}) {
  const { t } = useTranslation();
  const show = useSelector(Fp.flow(p.selector, states.removeConfirm.is));

  return show ? (
    <DeleteModal
      title={t("Delete item")}
      onConfirm={() => p.dispatch(actions.removeConfirm.create())}
      onCancel={() => p.dispatch(actions.removeDecline.create())}
    >
      {t("Do you really want to delete this item?")}
      <br />
      {t("This action cannot be undone.")}
    </DeleteModal>
  ) : null;
}

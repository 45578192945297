import * as Rx from "rxjs";
import type { Action } from "@reduxjs/toolkit";
import * as Notifications from "../states/Notifications/types/Actions";
import { Actions } from "./Actions";
import { State } from "./State";

export declare interface Epic<
  Output extends Action,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  State = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Dependencies = any,
> {
  (
    state$: Rx.Observable<State>,
    dependencies: Dependencies,
  ): Rx.Observable<Output | Notifications.Actions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EpicDeps<T extends Epic<any>> = T extends Epic<any, any, infer D>
  ? D
  : never;

export type RootEpic = Epic<Actions, State>;

export function merge<A extends Action, S, Deps>(
  epics: Epic<A, S, Deps>[],
): Epic<A, S, Deps> {
  return (s$, deps) => Rx.merge(...epics.map((epic) => epic(s$, deps)));
}

export function mergeByGuard<R extends Action, Deps, A extends U, U = A>(
  gs: [[(v: U) => v is A, Epic<R, A, Deps>]],
): Epic<R, U, Deps>;
export function mergeByGuard<
  R1 extends Action,
  R2 extends Action,
  Deps,
  A extends U,
  B extends U,
  U = A | B,
>(
  gs: [
    [(v: U) => v is A, Epic<R1, A, Deps>],
    [(v: U) => v is B, Epic<R2, B, Deps>],
  ],
): Epic<R1 | R2, U, Deps>;
export function mergeByGuard<
  R1 extends Action,
  R2 extends Action,
  R3 extends Action,
  Deps,
  A extends U,
  B extends U,
  C extends U,
  U = A | B | C,
>(
  gs: [
    [(v: U) => v is A, Epic<R1, A, Deps>],
    [(v: U) => v is B, Epic<R2, B, Deps>],
    [(v: U) => v is C, Epic<R3, C, Deps>],
  ],
): Epic<R1 | R2 | R3, U, Deps>;
export function mergeByGuard<
  R1 extends Action,
  R2 extends Action,
  R3 extends Action,
  R4 extends Action,
  Deps,
  A extends U,
  B extends U,
  C extends U,
  D extends U,
  U = A | B | C | D,
>(
  gs: [
    [(v: U) => v is A, Epic<R1, A, Deps>],
    [(v: U) => v is B, Epic<R2, B, Deps>],
    [(v: U) => v is C, Epic<R3, C, Deps>],
    [(v: U) => v is D, Epic<R4, D, Deps>],
  ],
): Epic<R1 | R2 | R3 | R4, U, Deps>;
export function mergeByGuard<
  R1 extends Action,
  R2 extends Action,
  R3 extends Action,
  R4 extends Action,
  R5 extends Action,
  Deps,
  A extends U,
  B extends U,
  C extends U,
  D extends U,
  E extends U,
  U = A | B | C | D | E,
>(
  gs: [
    [(v: U) => v is A, Epic<R1, A, Deps>],
    [(v: U) => v is B, Epic<R2, B, Deps>],
    [(v: U) => v is C, Epic<R3, C, Deps>],
    [(v: U) => v is D, Epic<R4, D, Deps>],
    [(v: U) => v is E, Epic<R5, E, Deps>],
  ],
): Epic<R1 | R2 | R3 | R4 | R5, U, Deps>;
export function mergeByGuard<
  R1 extends Action,
  R2 extends Action,
  R3 extends Action,
  R4 extends Action,
  R5 extends Action,
  R6 extends Action,
  Deps,
  A extends U,
  B extends U,
  C extends U,
  D extends U,
  E extends U,
  F extends U,
  U = A | B | C | D | E | F,
>(
  gs: [
    [(v: U) => v is A, Epic<R1, A, Deps>],
    [(v: U) => v is B, Epic<R2, B, Deps>],
    [(v: U) => v is C, Epic<R3, C, Deps>],
    [(v: U) => v is D, Epic<R4, D, Deps>],
    [(v: U) => v is E, Epic<R5, E, Deps>],
    [(v: U) => v is F, Epic<R6, F, Deps>],
  ],
): Epic<R1 | R2 | R3 | R4 | R5 | R6, U, Deps>;
export function mergeByGuard<
  R1 extends Action,
  R2 extends Action,
  R3 extends Action,
  R4 extends Action,
  R5 extends Action,
  R6 extends Action,
  R7 extends Action,
  Deps,
  A extends U,
  B extends U,
  C extends U,
  D extends U,
  E extends U,
  F extends U,
  G extends U,
  U = A | B | C | D | E | F | G,
>(
  gs: [
    [(v: U) => v is A, Epic<R1, A, Deps>],
    [(v: U) => v is B, Epic<R2, B, Deps>],
    [(v: U) => v is C, Epic<R3, C, Deps>],
    [(v: U) => v is D, Epic<R4, D, Deps>],
    [(v: U) => v is E, Epic<R5, E, Deps>],
    [(v: U) => v is F, Epic<R6, F, Deps>],
    [(v: U) => v is G, Epic<R7, G, Deps>],
  ],
): Epic<R1 | R2 | R3 | R4 | R5 | R6 | R7, U, Deps>;

export function mergeByGuard<
  R1 extends Action,
  R2 extends Action,
  R3 extends Action,
  R4 extends Action,
  R5 extends Action,
  R6 extends Action,
  R7 extends Action,
  R8 extends Action,
  Deps,
  A extends U,
  B extends U,
  C extends U,
  D extends U,
  E extends U,
  F extends U,
  G extends U,
  H extends U,
  U = A | B | C | D | E | F | G | H,
>(
  gs: [
    [(v: U) => v is A, Epic<R1, A, Deps>],
    [(v: U) => v is B, Epic<R2, B, Deps>],
    [(v: U) => v is C, Epic<R3, C, Deps>],
    [(v: U) => v is D, Epic<R4, D, Deps>],
    [(v: U) => v is E, Epic<R5, E, Deps>],
    [(v: U) => v is F, Epic<R6, F, Deps>],
    [(v: U) => v is G, Epic<R7, G, Deps>],
    [(v: U) => v is H, Epic<R8, H, Deps>],
  ],
): Epic<R1 | R2 | R3 | R4 | R5 | R6 | R7 | R8, U, Deps>;

export function mergeByGuard<
  R1 extends Action,
  R2 extends Action,
  R3 extends Action,
  R4 extends Action,
  R5 extends Action,
  R6 extends Action,
  R7 extends Action,
  R8 extends Action,
  R9 extends Action,
  Deps,
  A extends U,
  B extends U,
  C extends U,
  D extends U,
  E extends U,
  F extends U,
  G extends U,
  H extends U,
  I extends U,
  U = A | B | C | D | E | F | G | H | I,
>(
  gs: [
    [(v: U) => v is A, Epic<R1, A, Deps>],
    [(v: U) => v is B, Epic<R2, B, Deps>],
    [(v: U) => v is C, Epic<R3, C, Deps>],
    [(v: U) => v is D, Epic<R4, D, Deps>],
    [(v: U) => v is E, Epic<R5, E, Deps>],
    [(v: U) => v is F, Epic<R6, F, Deps>],
    [(v: U) => v is G, Epic<R7, G, Deps>],
    [(v: U) => v is H, Epic<R8, H, Deps>],
    [(v: U) => v is I, Epic<R9, I, Deps>],
  ],
): Epic<R1 | R2 | R3 | R4 | R5 | R6 | R7 | R8 | R9, U, Deps>;

export function mergeByGuard<
  R1 extends Action,
  R2 extends Action,
  R3 extends Action,
  R4 extends Action,
  R5 extends Action,
  R6 extends Action,
  R7 extends Action,
  R8 extends Action,
  R9 extends Action,
  R10 extends Action,
  Deps,
  A extends U,
  B extends U,
  C extends U,
  D extends U,
  E extends U,
  F extends U,
  G extends U,
  H extends U,
  I extends U,
  J extends U,
  U = A | B | C | D | E | F | G | H | I | J,
>(
  gs: [
    [(v: U) => v is A, Epic<R1, A, Deps>],
    [(v: U) => v is B, Epic<R2, B, Deps>],
    [(v: U) => v is C, Epic<R3, C, Deps>],
    [(v: U) => v is D, Epic<R4, D, Deps>],
    [(v: U) => v is E, Epic<R5, E, Deps>],
    [(v: U) => v is F, Epic<R6, F, Deps>],
    [(v: U) => v is G, Epic<R7, G, Deps>],
    [(v: U) => v is H, Epic<R8, H, Deps>],
    [(v: U) => v is I, Epic<R9, I, Deps>],
    [(v: U) => v is J, Epic<R10, J, Deps>],
  ],
): Epic<R1 | R2 | R3 | R4 | R5 | R6 | R7 | R8 | R9 | R10, U, Deps>;

export function mergeByGuard<
  R1 extends Action,
  R2 extends Action,
  R3 extends Action,
  R4 extends Action,
  R5 extends Action,
  R6 extends Action,
  R7 extends Action,
  R8 extends Action,
  R9 extends Action,
  R10 extends Action,
  R11 extends Action,
  Deps,
  A extends U,
  B extends U,
  C extends U,
  D extends U,
  E extends U,
  F extends U,
  G extends U,
  H extends U,
  I extends U,
  J extends U,
  L extends U,
  U = A | B | C | D | E | F | G | H | I | J | L,
>(
  gs: [
    [(v: U) => v is A, Epic<R1, A, Deps>],
    [(v: U) => v is B, Epic<R2, B, Deps>],
    [(v: U) => v is C, Epic<R3, C, Deps>],
    [(v: U) => v is D, Epic<R4, D, Deps>],
    [(v: U) => v is E, Epic<R5, E, Deps>],
    [(v: U) => v is F, Epic<R6, F, Deps>],
    [(v: U) => v is G, Epic<R7, G, Deps>],
    [(v: U) => v is H, Epic<R8, H, Deps>],
    [(v: U) => v is I, Epic<R9, I, Deps>],
    [(v: U) => v is J, Epic<R10, J, Deps>],
    [(v: U) => v is L, Epic<R11, L, Deps>],
  ],
): Epic<R1 | R2 | R3 | R4 | R5 | R6 | R7 | R8 | R9 | R10 | R11, U, Deps>;

export function mergeByGuard<R extends Action, Deps, T>(
  vs: Array<[(v: T) => v is T, Epic<R, T, Deps>]>,
): Epic<R, T, Deps> {
  return (state$, dependencies) => {
    return state$.pipe(
      Rx.distinctUntilChanged((a, b) =>
        vs.some(([guard]) => guard(a) && guard(b)),
      ),
      Rx.switchMap((s) => {
        const epic = vs.find(([guard]) => guard(s))?.[1];
        return epic ? epic(state$, dependencies) : Rx.NEVER;
      }),
    );
  };
}

import {
  UiSchema,
  UiSchemaElementType,
  UiSchemaTypeRegistry,
} from "types/src/jsonSchema/uiSchema";
import * as R from "rambda";
import { uiElementPathDecode } from "./elementPath";
import { getId } from "./index";

export const findElementByEncodedPath = <Type extends UiSchemaElementType>(
  uiSchema: UiSchema,
  pathEncoded: string,
  elementType: Type,
): UiSchemaTypeRegistry[Type] | undefined => {
  const { path, id } = uiElementPathDecode(pathEncoded);
  const elementUiSchema = R.path<UiSchemaTypeRegistry[Type]>(path, uiSchema);

  if (
    !elementUiSchema ||
    elementUiSchema.type !== elementType ||
    getId(elementUiSchema) !== id
  ) {
    return undefined;
  }

  return elementUiSchema;
};

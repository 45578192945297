import { Box } from "@mui/material";
import React from "react";
import { Sxs } from "../../../../../types/styles";

export namespace Row {
  export type Props = React.PropsWithChildren<{
    mt?: boolean;
    mb?: boolean;
    sx?: Sxs[string];
  }>;
}

export const Row = ({ children, mt, mb, sx }: Row.Props) => {
  const className = [mt ? "mt" : undefined, mb ? "mb" : undefined]
    .filter(Boolean)
    .join(" ");

  return (
    <Box sx={sx ? [sxs.root, sx] : sxs.root} className={className}>
      {children}
    </Box>
  );
};

const sxs = {
  root: (theme) => ({
    padding: theme.spacing(0, 4),

    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: 4,
    "& > *": {
      flex: 1,
    },

    "&.mt": {
      marginTop: theme.spacing(4),
    },
    "&.mb": {
      marginBottom: theme.spacing(4),
    },
  }),
} satisfies Sxs;

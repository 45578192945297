import { silentUnreachableError } from "utils/exceptions";
import { DataTypeId } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import { SchemaFieldsState, SchemaFieldsValidState } from "../utils";

// region State
export type State =
  | Loading
  | LoadError
  | Ready
  | Saving
  | RemoveConfirm
  | Removing;

export const isState = strictGuard((s: State): s is State => {
  switch (s.type) {
    case "Ready:DataManager:Customers:Create:Loading":
    case "Ready:DataManager:Customers:Create:LoadError":
    case "Ready:DataManager:Customers:Create:Ready":
    case "Ready:DataManager:Customers:Create:Saving":
    case "Ready:DataManager:Customers:Create:RemoveConfirm":
    case "Ready:DataManager:Customers:Create:Removing":
      return true;
    default:
      silentUnreachableError(s);
      return false;
  }
});
// endregion

// region Loading
export interface LoadingPayload {
  dataTypeId: DataTypeId;
}

export interface Loading {
  type: "Ready:DataManager:Customers:Create:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:Customers:Create:Loading",
  payload,
});

export const isLoading = (a: State): a is Loading =>
  a.type === "Ready:DataManager:Customers:Create:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:Customers:Create:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:Customers:Create:LoadError",
  payload,
});

export const isLoadError = (a: State): a is LoadError =>
  a.type === "Ready:DataManager:Customers:Create:LoadError";
// endregion

// region Init
export interface ReadyPayload extends LoadingPayload {
  schema: SchemaFieldsState;
  submitted: boolean;
}

export interface Ready {
  type: "Ready:DataManager:Customers:Create:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:Customers:Create:Ready",
  payload,
});

export const isReady = (a: State): a is Ready =>
  a.type === "Ready:DataManager:Customers:Create:Ready";
// endregion

// region Saving
export interface SavingPayload extends ReadyPayload {
  schema: SchemaFieldsValidState;
}

export interface Saving {
  type: "Ready:DataManager:Customers:Create:Saving";
  payload: SavingPayload;
}

export const saving = (payload: Saving["payload"]): Saving => ({
  type: "Ready:DataManager:Customers:Create:Saving",
  payload,
});

export const isSaving = (a: State): a is Saving =>
  a.type === "Ready:DataManager:Customers:Create:Saving";
// endregion

// region RemoveConfirm
export interface RemoveConfirmPayload extends ReadyPayload {}

export interface RemoveConfirm {
  type: "Ready:DataManager:Customers:Create:RemoveConfirm";
  payload: RemoveConfirmPayload;
}

export const removeConfirm = (
  payload: RemoveConfirm["payload"],
): RemoveConfirm => ({
  type: "Ready:DataManager:Customers:Create:RemoveConfirm",
  payload,
});

export const isRemoveConfirm = (s: State): s is RemoveConfirm =>
  s.type === "Ready:DataManager:Customers:Create:RemoveConfirm";
// endregion

// region Removing
export interface RemovingPayload extends ReadyPayload {}

export interface Removing {
  type: "Ready:DataManager:Customers:Create:Removing";
  payload: RemovingPayload;
}

export const removing = (payload: Removing["payload"]): Removing => ({
  type: "Ready:DataManager:Customers:Create:Removing",
  payload,
});

export const isRemoving = (s: State): s is Removing =>
  s.type === "Ready:DataManager:Customers:Create:Removing";

// endregion

export function isLoaded(a: State): a is Exclude<State, Loading | LoadError> {
  return !isLoading(a) && !isLoadError(a);
}

export const init = loading;

import { silentUnreachableError } from "utils/exceptions";
import * as FormValue from "types/src/FormValue";
import { isNoEmptyString, NoEmptyString } from "types/src/NoEmptyString";
import * as E from "fp-ts/Either";
import * as Fp from "fp-ts/function";
import { Typed } from "utils/Typed";
import { isT } from "fp-utilities";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import * as Exits from "./types/Exits";
import { schemaFieldsState } from "./utils";
import { exits } from "./types/Exits";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          id: s.payload.id,
          submitted: s.payload.submitted,
          dataTypeId: s.payload.dataTypeId,
          schema: Fp.pipe(
            schemaFieldsState.reducer(s.payload.schema, a),
            E.getOrElse(() => s.payload.schema),
          ),
          sku: s.payload.sku,
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:InventoryItems:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(State.loadError(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        return E.right(
          State.ready({
            id: a.payload.item.id,
            dataTypeId: a.payload.item.dataTypeId,
            submitted: false,
            sku: FormValue.valid(a.payload.item.sku),
            schema: schemaFieldsState.states.init.create({
              schema: a.payload.fields,
              uiSchema: a.payload.ui,
              values: a.payload.item.fields,
            }),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:SetSku": {
      if (State.isReady(s)) {
        return E.right(
          State.ready({
            ...s.payload,
            sku: isNoEmptyString(a.payload)
              ? FormValue.valid(a.payload)
              : FormValue.invalid("required", a.payload),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:Submit": {
      if (State.isReady(s)) {
        const schema = Fp.pipe(
          schemaFieldsState.reducer(
            s.payload.schema,
            schemaFieldsState.actions.submit.create(),
          ),
          E.getOrElse(() => s.payload.schema),
        );
        const sku = Fp.pipe(
          s.payload.sku,
          E.fromPredicate(FormValue.isInitial, (v) => v),
          E.map((v) => v.value),
          E.filterOrElseW(isT, FormValue.invalid("required" as const)),
          E.filterOrElseW(
            NoEmptyString.isNoEmptyString,
            FormValue.invalid("required" as const),
          ),
          E.map(FormValue.valid),
          E.toUnion,
        );

        if (
          FormValue.isValid(sku) &&
          schemaFieldsState.states.valid.is(schema)
        ) {
          return E.right(
            State.saving({
              ...s.payload,
              sku: sku,
              schema: schema,
              submitted: true,
            }),
          );
        } else {
          return E.right(
            State.ready({
              ...s.payload,
              sku: sku,
              schema: schema,
              submitted: true,
            }),
          );
        }
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:SaveError": {
      if (State.isSaving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.left(Exits.exits.saved.create(a.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:InventoryItems:Edit:Remove": {
      if (State.isReady(s) || State.isSaving(s)) {
        return E.right(State.removeConfirmation(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:InventoryItems:Edit:RemoveDecline": {
      if (State.isRemoveConfirmation(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:InventoryItems:Edit:RemoveConfirm": {
      if (State.isRemoveConfirmation(s)) {
        return E.right(State.removing(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:InventoryItems:Edit:RemoveFail": {
      if (State.isRemoving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:InventoryItems:Edit:RemoveSuccess": {
      if (State.isRemoving(s)) {
        return E.left(exits.removed.create(s.payload.id));
      }

      return E.right(s);
    }

    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}

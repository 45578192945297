import React from "react";
import { useTranslation } from "i18n";
import { FormDataProps } from "../types";
import { Row } from "../../components/Row";
import { FormDataCheckbox } from "../../components/formData/Checkbox";
import { GeneralOptions } from "./GeneralOptions";

export namespace GeneralOptionsText {
  export type Values = GeneralOptions.Values &
    Partial<{
      asTextarea: boolean;
    }>;

  export type Props = FormDataProps<Values>;
}

export const GeneralOptionsText = ({
  formData$,
  onValuesChange,
}: GeneralOptionsText.Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row mt mb>
        <FormDataCheckbox
          formDataProp="asTextarea"
          {...{ formData$, onValuesChange }}
          label={t("Make as a textarea")}
        />
      </Row>

      <GeneralOptions {...{ formData$, onValuesChange }} />
    </>
  );
};

import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue, useBehaviorValueRef } from "react-rx/behaviorValue";
import { useContext, useMemo } from "react";
import { FormEditor as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor";
import { Value } from "types/src/jsonSchema/value";
import { ElementTypesContext } from "../contexts/ElementTypes";
import { uiElementPathEncode } from "../utils/uiSchema/elementPath";
import { getId } from "../utils/uiSchema";
import { useOnDragEnd } from "./hooks/useOnDragEnd";

export namespace FormEditor {
  export type Props = {
    value$: BehaviorValue<Value>;
    onValueChange: (value: Value) => void;
  };
}

export const FormEditor = ({ value$, onValueChange }: FormEditor.Props) => {
  const onDragEnd = useOnDragEnd({
    valueRef: useBehaviorValueRef(value$),
    onValueChange,
  });

  const rootType = useBehaviorValue(
    useMemo(() => value$.map(({ uiSchema: { type } }) => type), [value$]),
  );
  const rootPath = useBehaviorValue(
    useMemo(
      () =>
        value$.map(({ uiSchema }) =>
          uiElementPathEncode({ path: [], id: getId(uiSchema) }),
        ),
      [value$],
    ),
  );

  const Element =
    useContext(ElementTypesContext).elementTypes[rootType].RenderInEditor;

  return (
    <UI {...{ onDragEnd }}>
      <Element uiSchemaPathEncoded={rootPath} />
    </UI>
  );
};

import React from "react";
import { useTranslation } from "i18n";
import { FormDataProps } from "../types";
import { Divider } from "../../components/Divider";
import { Row } from "../../components/Row";
import { FormDataInput } from "../../components/formData/Input";
import { FormDataCheckbox } from "../../components/formData/Checkbox";
import { FormDataNumber } from "../../components/formData/Number";

export namespace GeneralOptions {
  export type Values = Partial<{
    showLabel: boolean;
    label: string;

    required: boolean;
    unique: boolean;
    readonly: boolean;
    hidden: boolean;

    placeholder: string;
    defaultValue: number;
  }>;

  export type Props = FormDataProps<Values>;
}

export const GeneralOptions = ({
  formData$,
  onValuesChange,
}: GeneralOptions.Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider />

      <Row mb>
        <FormDataCheckbox
          formDataProp="showLabel"
          {...{ formData$, onValuesChange }}
          label={t("Show label")}
        />
      </Row>
      <Row>
        <FormDataInput
          formDataProp="label"
          {...{ formData$, onValuesChange }}
          label={t("Label")}
          placeholder={t("e.g. Title")}
        />
      </Row>

      <Divider />

      <Row mb>
        <FormDataCheckbox
          formDataProp="required"
          {...{ formData$, onValuesChange }}
          label={t("Required")}
        />
        <FormDataCheckbox
          formDataProp="unique"
          {...{ formData$, onValuesChange }}
          label={t("Unique")}
        />
        <FormDataCheckbox
          formDataProp="readonly"
          {...{ formData$, onValuesChange }}
          label={t("Read only")}
        />
        <FormDataCheckbox
          formDataProp="hidden"
          {...{ formData$, onValuesChange }}
          label={t("Hidden")}
        />
      </Row>

      <Row>
        <FormDataInput
          formDataProp="placeholder"
          {...{ formData$, onValuesChange }}
          label={t("Placeholder") + " " + t("(optional)")}
          placeholder={t("e.g. Write here")}
        />
        <FormDataNumber
          formDataProp="defaultValue"
          {...{ formData$, onValuesChange }}
          label={t("Default value") + " " + t("(optional)")}
          placeholder={t("e.g. Value")}
        />
      </Row>
    </>
  );
};

import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { ColumnsConfigBase } from "@layouts/ListingTable/types/columns";
import {
  FilterGroup,
  FiltersConfigBase,
} from "@layouts/ListingTable/types/filters";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import {
  Criteria,
  CriteriaChange,
  SortableColumns,
} from "@layouts/ListingTable/types/criteria";
import { Button, SxProps } from "@mui/material";
import { alpha, Theme } from "@mui/material/styles";
import { usePopoverController } from "@layouts/ListingTable/Popover/hooks/usePopoverController";
import { useTranslation } from "i18n";
import React, { useMemo } from "react";
import { sxsCommon } from "@layouts/ListingTable/filters/custom/common/sxs";
import FiltersIcon from "@mui/icons-material/FilterListRounded";
import { stylesIcon } from "@layouts/ListingTable/utils/styles";
import { useBehaviorValue } from "react-rx/behaviorValue";
import * as Rx from "rxjs";
import { isDeepEqual } from "utils/object";
import { Popover } from "@layouts/ListingTable/Popover";
import * as O from "fp-ts/Option";

export namespace AddMore {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
  > = {
    filters: FiltersConfig;
    criteria$: BehaviorValue<
      Criteria<SortableColumns<ColumnsConfig>, FiltersConfig>
    >;
    onCriteriaChange: (
      criteria: CriteriaChange<SortableColumns<ColumnsConfig>, FiltersConfig>,
    ) => void;
  };
}

export const AddMore = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
>({
  filters: { [FilterGroup.custom]: config },
  criteria$,
  onCriteriaChange,
}: AddMore.Props<DataEntry, ColumnsConfig, FiltersConfig>) => {
  type Config = FiltersConfig[FilterGroup.custom];

  const { t } = useTranslation();
  const { openPopover, popoverOpen, popoverProps } = usePopoverController();
  const idsWithCriteria = useBehaviorValue(
    useMemo(
      () =>
        criteria$
          .map((criteria) =>
            Object.keys(criteria?.filters?.[FilterGroup.custom] || {}).reduce(
              (carry, id) => ({ ...carry, [id]: true }),
              {} as Partial<Record<keyof Config, boolean>>,
            ),
          )
          // prevent rerender if ids are the same
          .pipe(Rx.distinctUntilChanged(isDeepEqual)),
      [criteria$],
    ),
  );
  const listItems = useMemo(
    () =>
      Object.keys(config)
        .map((id) => id as keyof Config)
        .map((id) => ({
          id,
          label: config[id].options.label,
          onClick: () =>
            onCriteriaChange({
              filters: {
                // @ts-expect-error fixme
                [FilterGroup.custom]: {
                  [id]: O.some(config[id].value),
                },
              },
            }),
        })),
    [config, onCriteriaChange],
  );

  return (
    <>
      <Button
        startIcon={icon}
        disabled={popoverOpen}
        sx={sxs.button}
        onClick={openPopover}
      >
        {t("More filters")}
      </Button>
      {popoverOpen !== undefined && (
        <Popover {...popoverStaticProps} {...popoverProps}>
          {listItems.map(({ id, label, onClick }) => (
            <Button
              key={id.toString()}
              onClick={onClick}
              sx={sxs.listItemButton}
              disabled={idsWithCriteria[id]}
            >
              {label}
            </Button>
          ))}
        </Popover>
      )}
    </>
  );
};

const icon = <FiltersIcon sx={stylesIcon} />;

const sxs = {
  button: (theme) => ({
    ...sxsCommon.filterAsButton(theme),
    ".MuiButton-icon": {
      margin: 0,
    },
  }),
  listItemButton: (theme) => ({
    ...theme.typography.body2,

    padding: theme.spacing(2.5, 4),
    textAlign: "left",

    display: "block",
    width: ["100%", "fill-available"],

    borderRadius: 0,

    "&:hover:not(.Mui-disabled)": {
      backgroundColor: theme.palette.surface.primary,
    },
    "&.Mui-disabled": {
      color: alpha(theme.palette.common.black, 0.2),
    },
  }),
} satisfies Record<string, SxProps<Theme>>;

const popoverStaticProps: Partial<Popover.Props> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

import * as Fp from "fp-ts/function";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { Selector, useSelector } from "state-manager";
import * as O from "fp-ts/Option";

import { useTranslation } from "i18n";
import { Input } from "@Containers/Form/FormValue/Input";
import { InboundItem } from "state-manager/generic-states/InboundItem";
import { GetGuardType } from "types/src/Utils";
import { Number } from "@Containers/Form/FormValue/Number";
import { Combobox } from "@Containers/Form/Combobox";
import { getEq } from "fp-ts/es6/Array";
import { isDeepEqual } from "utils/object";
import { DataTypeId } from "types/src/DataType/DataType";
import { TranslatedStr } from "types/src/TranslatedStr";
import { MaybeSchemaFieldsJsonEditor } from "@Containers/Form/MaybeSchemaFieldsJsonEditor";

export interface InboundItemFormProps {
  instance: InboundItem.Store;
  selector: Selector<InboundItem.State>;
  dispatch: (a: InboundItem.Actions) => void;
}

export function InboundItemForm(p: InboundItemFormProps) {
  const { t } = useTranslation();
  const { instance } = p;
  const schema = instance.subStates.fields;

  const dataTypes = useSelector(
    Fp.flow(p.selector, (v) =>
      v.payload.dataTypes.map((v) => ({
        value: v.id,
        label: v.name as TranslatedStr,
      })),
    ),
    getEq<{ value: DataTypeId; label: TranslatedStr }>({ equals: isDeepEqual })
      .equals,
  );

  return (
    <FormWrapper>
      <Input
        label={t("Sku")}
        value={Fp.flow(p.selector, (v) => v.payload.sku)}
        onChange={Fp.flow(instance.actions.setSku.create, p.dispatch)}
      />
      <Number
        label={t("Quantity")}
        value={Fp.flow(p.selector, (v) => v.payload.quantity)}
        onChange={Fp.flow(instance.actions.setQuantity.create, p.dispatch)}
      />
      <Combobox
        label={t("Datatype")}
        value$={Fp.flow(p.selector, (v) => v.payload.dataTypeId, O.toUndefined)}
        onChange={Fp.flow(instance.actions.setDataType.create, p.dispatch)}
        options={dataTypes}
      />

      <MaybeSchemaFieldsJsonEditor
        value$={Fp.flow(
          p.selector,
          (v) =>
            v.payload.fields as O.Option<GetGuardType<typeof schema.isState>>,
          O.map((v) => ({
            schema: v.payload.schema,
            uiSchema: v.payload.uiSchema,
            values: v.payload.values,
            submitted: schema.states.invalid.is(v) || schema.states.valid.is(v),
          })),
        )}
        onChange={Fp.flow(
          (v) => v ?? {},
          schema.actions.onChange.create,
          instance.actions.fields.create,
          p.dispatch,
        )}
      />
    </FormWrapper>
  );
}

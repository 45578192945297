import type { UniqueIdentifier } from "@dnd-kit/core/dist/types";
import { ElementRefsContext } from "../elementRefs/Context";
import { ClosestElement } from "../types";

type Coords = { top: number; bottom: number };

export const findClosestElement = (
  draggedId: UniqueIdentifier,
  refs: ElementRefsContext.Value["refs"],
): undefined | ClosestElement => {
  let closest: ClosestElement | undefined;

  const dragged = refs[draggedId];
  if (!dragged) {
    return undefined;
  }

  const draggedElementCoords = getCoords(dragged.el);

  for (const id in refs) {
    const ref = refs[id];
    if (id === draggedId || !ref) {
      continue;
    }

    const distance = getDistance(draggedElementCoords, getCoords(ref.el));

    if (!closest || distance.pixels < closest.distance.pixels) {
      closest = { id, meta: ref.meta, distance };
    }
  }

  return closest;
};

function getCoords(el: HTMLElement): Coords {
  const top = el.getBoundingClientRect().y,
    height = el.offsetHeight;
  return { top, bottom: top + height };
}

function getVerticalCenter(sides: Coords) {
  return sides.top + (sides.bottom - sides.top) / 2;
}

function getDistance(a: Coords, b: Coords): ClosestElement["distance"] {
  const pixels = getVerticalCenter(a) - getVerticalCenter(b);

  return {
    from: pixels < 0 ? "top" : "bottom",
    pixels: Math.abs(pixels),
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Typed } from "utils/Typed";
import * as Filters from "../../Filters";
import { Items } from "./Items";

export function createActions<T extends { id: string }, O>(p: string) {
  return Typed.builder
    .add(
      "loadSuccess",
      (p: { data: Items<T>; visibleColumns: Record<string, boolean> }) => p,
    )
    .add("setColumnsVisibility", (p: Record<string, boolean>) => p)
    .add("fetchSuccess", (p: Items<T>) => p)
    .add("reFetch")
    .add("setPage", (p: "start" | "prev" | "next" | "end") => p)
    .add("select", (p: T["id"][]) => p)
    .add("unselect", (p: T["id"][]) => p)
    .add("removeItem", (p: T["id"]) => p)
    .add("removeSuccess", (p: Array<T["id"]>) => p)
    .add("removeFail", (p: Array<T["id"]>) => p)
    .add("orderBy", (p: O) => p)
    .add("loadFail")
    .add("removeBulk")
    .add("removeConfirm")
    .add("removeDecline")
    .add("create")
    .finish()(p);
}

export type Actions<
  F extends Record<string, unknown>,
  T extends { id: string },
  O,
> = Typed.GetTypes<ReturnType<typeof createActions<T, O>>> | Filters.Actions<F>;

import { useTranslation } from "i18n";
import * as Ready from "state-manager/states/Ready";
import { isSigningOut, signOut } from "state-manager/states/Ready";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import * as BuilderPreview from "state-manager/states/Ready/states/BuilderPreview";
import * as BPMNPreview from "state-manager/states/Ready/states/BPMNPreview";
import * as ZitadelPreview from "state-manager/states/Ready/states/ZitadelPreview";
import * as GraphqlPlayground from "state-manager/states/Ready/states/GraphqlPlayground";
import * as Temporal from "state-manager/states/Ready/states/Temporal";
import { RootState, useSelector } from "state-manager";
import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import { flow } from "fp-ts/function";
import {
  Database,
  GraphQl,
  Logo,
  Paperclip,
  ThreeLayers,
  DataFlow,
} from "icons";
import { MainSidebar as UiMain } from "ui/components/MainSidebar";
import { DataTypesListing } from "../../../../packages/state-manager/src/states/Ready/states/DataTypesListing";
import { DataTypeCreate } from "../../../../packages/state-manager/src/states/Ready/states/DataTypeCreate";
import { DataTypeEdit } from "../../../../packages/state-manager/src/states/Ready/states/DataTypeEdit";

export interface MainSidebarProps {
  selector: (s: RootState) => Ready.State;
  dispatch: (a: Ready.Actions) => void;
}

export function MainSidebar({ selector, dispatch }: MainSidebarProps) {
  const { t } = useTranslation();
  const r = useSelector(
    flow(selector, (s) => {
      if (s.payload.organizations.isActive) return "organizations";
      if (DataManager.isState(s.payload.subState)) return "data-manager";
      if (BuilderPreview.isState(s.payload.subState)) return "builder-preview";
      if (BPMNPreview.isState(s.payload.subState)) return "bpmn-preview";
      if (ZitadelPreview.isState(s.payload.subState)) return "zitadel-preview";
      if (GraphqlPlayground.instance.isState(s.payload.subState))
        return "graphql-playground";
      // @ts-expect-error, fix later
      if (Temporal.instance.isState(s.payload.subState)) return "temporal";
      if (DataTypesListing.instance.isState(s.payload.subState))
        return "data-types";
      if (DataTypeCreate.instance.isState(s.payload.subState))
        return "data-type-create";
      if (DataTypeEdit.instance.isState(s.payload.subState))
        return "data-type-edit";

      if (isSigningOut(s.payload.subState)) return "signOut";

      silentUnreachableError(s.payload.subState);
      return undefined;
    }),
  );

  return (
    <UiMain>
      <UiMain.Item
        isCurrent={false}
        icon={<Logo />}
        title={t("Pyck")}
        onClick={() => dispatch(Ready.goToCustomers())}
      />

      <UiMain.Group>
        <UiMain.Item
          isCurrent={r === "data-manager"}
          icon={<ThreeLayers />}
          title={t("Data Manager")}
          onClick={() => dispatch(Ready.goToCustomers())}
        />
        <UiMain.Item
          isCurrent={
            r === "data-types" ||
            r === "data-type-create" ||
            r === "data-type-edit"
          }
          icon={<Database />}
          title={t("Data Types")}
          onClick={() => dispatch(Ready.goToDatatypes())}
        />
        <UiMain.Item
          isCurrent={r === "temporal"}
          icon={<DataFlow />}
          title={t("Temporal")}
          onClick={() => dispatch(Ready.goToTemporal())}
        />

        <UiMain.Item
          // @ts-expect-error, we will define it later
          isCurrent={r === "attachments"}
          icon={<Paperclip />}
          title={t("Attachments")}
          onClick={() => {}}
        />

        <UiMain.Item
          isCurrent={r === "graphql-playground"}
          icon={<GraphQl />}
          title={t("Playground")}
          onClick={() => dispatch(Ready.goToGraphQlPlayground())}
        />
      </UiMain.Group>

      <UiMain.Separator />

      <UiMain.Divider />
      <CurrentUserNavItem selector={selector} dispatch={dispatch} />
    </UiMain>
  );
}

function CurrentUserNavItem(p: {
  selector: (s: RootState) => Ready.State;
  dispatch: (a: Ready.Actions) => void;
}) {
  const getUser = flow(p.selector, (v) => v.payload.user, E.toUnion);
  const username = useSelector(flow(getUser, (v) => v.username));
  const email = useSelector(flow(getUser, (v) => v.email));
  const avatar = useSelector(flow(getUser, (v) => v.avatar));

  return (
    <UiMain.Avatar
      name={username}
      email={email}
      avatar={avatar}
      onLogout={() => p.dispatch(signOut())}
    />
  );
}

import { DsError } from "ds";
import { DataTypeId } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import {
  PickingOrder,
  PickingOrderId,
} from "types/src/PickingOrder/PickingOrder";
import { PickingOrderItemId } from "types/src/PickingOrder/PickingOrderItem";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import {
  CustomerSearchState,
  CustomerSearchStatesMap,
  PickingOrderItemsState,
  PickingOrderItemsStatesMap,
  SchemaFieldsState,
  SchemaFieldsStatesMap,
} from "../utils";

export type State =
  | Loading
  | LoadError
  | Ready
  | Saving
  | RemoveConfirmation
  | Removing;

export const isState = (p: string) => {
  const _isLoading = isLoading(p);
  const _isLoadError = isLoadError(p);
  const _isReady = isReady(p);
  const _isSaving = isSaving(p);
  const _isRemoveConfirmation = isRemoveConfirmation(p);
  const _isRemoving = isRemoving(p);

  return strictGuard((s: State): s is State => {
    if (
      _isLoading(s) ||
      _isLoadError(s) ||
      _isReady(s) ||
      _isSaving(s) ||
      _isRemoveConfirmation(s) ||
      _isRemoving(s)
    )
      return true;

    silentUnreachableError(s);
    return false;
  });
};

// region Loading
export interface LoadingPayload {
  id: PickingOrderId;
}

export interface Loading {
  type: `${string}:Loading`;
  payload: LoadingPayload;
}

export const loading =
  (p: string) =>
  (payload: Loading["payload"]): Loading => ({
    type: `${p}:Loading`,
    payload,
  });

export const isLoading =
  (p: string) =>
  (s: State): s is Loading =>
    s.type === `${p}:Loading`;
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {
  error: DsError;
}

export interface LoadError {
  type: `${string}:LoadError`;
  payload: LoadErrorPayload;
}

export const loadError =
  (p: string) =>
  (payload: LoadError["payload"]): LoadError => ({
    type: `${p}:LoadError`,
    payload,
  });

export const isLoadError =
  (p: string) =>
  (s: State): s is LoadError =>
    s.type === `${p}:LoadError`;
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  customer: CustomerSearchState;
  dataTypes: Array<{
    title: string;
    id: DataTypeId;
    schema: DataSchema;
    ui: UiSchema;
  }>;
  fields: SchemaFieldsState;
  items: PickingOrderItemsState;
  removedItems: PickingOrderItemId[];
  _initial: PickingOrder;
  submitted: boolean;
}

export interface Ready {
  type: `${string}:Ready`;
  payload: ReadyPayload;
}

export const ready =
  (p: string) =>
  (payload: Ready["payload"]): Ready => ({
    type: `${p}:Ready`,
    payload,
  });

export const isReady =
  (p: string) =>
  (s: State): s is Ready =>
    s.type === `${p}:Ready`;
// endregion

// region Saving
export interface SavingPayload extends ReadyPayload {
  customer: CustomerSearchStatesMap["selected"];
  fields: SchemaFieldsStatesMap["valid"];
  items: PickingOrderItemsStatesMap["valid"];
}

export interface Saving {
  type: `${string}:Saving`;
  payload: SavingPayload;
}

export const saving =
  (p: string) =>
  (payload: Saving["payload"]): Saving => ({
    type: `${p}:Saving`,
    payload,
  });

export const isSaving =
  (p: string) =>
  (s: State): s is Saving =>
    s.type === `${p}:Saving`;
// endregion

// region RemoveConfirmation
export interface RemoveConfirmationPayload extends ReadyPayload {}

export interface RemoveConfirmation {
  type: `${string}:RemoveConfirmation`;
  payload: RemoveConfirmationPayload;
}

export const removeConfirmation =
  (p: string) =>
  (payload: RemoveConfirmation["payload"]): RemoveConfirmation => ({
    type: `${p}:RemoveConfirmation`,
    payload,
  });

export const isRemoveConfirmation =
  (p: string) =>
  (s: State): s is RemoveConfirmation =>
    s.type === `${p}:RemoveConfirmation`;
// endregion

// region Removing
export interface RemovingPayload extends ReadyPayload {}

export interface Removing {
  type: `${string}:Removing`;
  payload: RemovingPayload;
}

export const removing =
  (p: string) =>
  (payload: Removing["payload"]): Removing => ({
    type: `${p}:Removing`,
    payload,
  });

export const isRemoving =
  (p: string) =>
  (s: State): s is Removing =>
    s.type === `${p}:Removing`;
// endregion

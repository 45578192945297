import { gql } from "@apollo/client";

export const inboundItemFragment = gql`
  fragment InboundItemFragment on ReceivingInboundItem {
    id
    createdAt
    data
    dataTypeID
    inboundID
    quantity
    sku
    updatedAt
  }
`;

import { Selector } from "state-manager";
import * as GraphQlPlayground from "state-manager/states/Ready/states/GraphqlPlayground";

export interface HeaderProps {
  selector: Selector<GraphQlPlayground.State>;
  dispatch: (a: GraphQlPlayground.Action) => void;
}

export function Header(p: HeaderProps) {
  return null;
}

import * as O from "fp-ts/Option";
import { unreachableError } from "utils/exceptions";
import * as StateModule from "./types/State";

import { createReducer } from "./reducer";
import { epicCreator } from "./epic";
import * as ActionsModule from "./types/Actions";

export function createState<E, T>(p: string, eq: (a: T, b: T) => boolean) {
  const isSelected = StateModule.isSelected(p)<E, T>;
  const isIdle = StateModule.isIdle(p)<E, T>;
  const isSearching = StateModule.isSearching(p)<E, T>;
  const isItemSearchError = StateModule.isItemSearchError(p)<E, T>;

  return {
    states: {
      idle: {
        create: StateModule.idle(p)<T>,
        is: isIdle,
      },
      selected: {
        create: StateModule.selected(p)<T>,
        is: isSelected,
      },
      searching: {
        create: StateModule.searching(p)<T>,
        is: isSearching,
      },
      itemSearchError: {
        create: StateModule.itemSearchError(p)<E, T>,
        is: isItemSearchError,
      },
    },
    actions: {
      setQuery: {
        create: ActionsModule.setQuery(p),
        is: ActionsModule.isSetQuery(p)<E, T>,
      },
      submitQuery: {
        create: ActionsModule.submitQuery(p),
        is: ActionsModule.isSubmitQuery(p)<E, T>,
      },
      searchError: {
        create: ActionsModule.searchError(p)<E>,
        is: ActionsModule.isSearchError(p)<E, T>,
      },
      searchSuccess: {
        create: ActionsModule.searchSuccess(p)<T>,
        is: ActionsModule.isSearchSuccess(p)<E, T>,
      },
      selectItem: {
        create: ActionsModule.selectItem(p)<T>,
        is: ActionsModule.isSelectItem(p)<E, T>,
      },
      clear: {
        create: ActionsModule.clear(p),
        is: ActionsModule.isClear(p)<E, T>,
      },
    },
    isState: StateModule.isState<E, T>(p),
    isActions: ActionsModule.isActions<E, T>(p),
    reducer: createReducer<E, T>(p, eq),
    epic: epicCreator<E, T>(p),
    init: StateModule.init<T>(p),
    getValue: (s: StateModule.State<E, T>): O.Option<T> => {
      if (isSelected(s)) return O.some(s.payload.item);
      if (isIdle(s)) return O.none;
      if (isSearching(s)) return O.none;
      if (isItemSearchError(s)) return O.none;

      unreachableError(s);
      return O.none;
    },
  };
}

export type StateConstructor<E, T> = ReturnType<typeof createState<E, T>>;

export type StatesMap<E, T> = {
  [k in keyof ReturnType<typeof createState<E, T>>["states"]]: ReturnType<
    ReturnType<typeof createState<E, T>>["states"][k]["create"]
  >;
};

export type State<E, T> = StatesMap<E, T>[keyof StatesMap<E, T>];

export type ActionsMap<E, T> = {
  [k in keyof ReturnType<typeof createState<E, T>>["actions"]]: ReturnType<
    ReturnType<typeof createState<E, T>>["actions"][k]["create"]
  >;
};

export type Actions<E, T> = ActionsMap<E, T>[keyof ActionsMap<E, T>];

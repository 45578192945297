import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Checkbox, FormControlLabel, FormLabel } from "@mui/material";
import { useRandomId } from "react-utils/hooks";
import { useFormDataProp } from "../../hooks/useFormDataProp";

type Value = boolean;

export const FormDataCheckbox = <Prop extends string>({
  formData$,
  formDataProp,
  onValuesChange,

  label,
}: {
  formDataProp: Prop;
  formData$: BehaviorValue<{
    values: Partial<Record<Prop, Value>>;
    errors: Partial<Record<Prop, string>>;
  }>;
  onValuesChange: (changes: Partial<Record<Prop, Value>>) => void;

  label: string;
}) => {
  const { value } = useFormDataProp({
    formData$,
    prop: formDataProp,
    defaultValue: false,
  });
  const randomId = useRandomId();

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            id={randomId}
            checked={value}
            onChange={({ target: { checked } }) => {
              onValuesChange({
                [formDataProp]: checked,
              } as /*why doesn't work without cast?*/ Record<Prop, Value>);
            }}
          />
        }
        label={<FormLabel htmlFor={randomId}>{label}</FormLabel>}
      />
    </div>
  );
};

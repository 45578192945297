import { Selector, useSelector } from "state-manager";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { CustomersListing } from "state-manager/states/Ready/states/DataManager/states/Customers/states/Listing";
import { CustomersListingAll } from "state-manager/states/Ready/states/DataManager/states/Customers/states/ListingAll";
import { SuppliersListing } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/Listing";
import { SuppliersListingAll } from "state-manager/states/Ready/states/DataManager/states/Suppliers/states/ListingAll";
import { RepositoriesListing } from "state-manager/states/Ready/states/DataManager/states/Repositories/states/Listing";
import { RepositoriesListingAll } from "state-manager/states/Ready/states/DataManager/states/Repositories/states/ListingAll";
import { InventoryItemsListing } from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/Listing";
import { InventoryItemsListingAll } from "state-manager/states/Ready/states/DataManager/states/InventoryItems/states/ListingAll";
import { ItemMovementsListing } from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/Listing";
import { ItemMovementsListingAll } from "state-manager/states/Ready/states/DataManager/states/ItemMovements/states/ListingAll";
import { RepositoryMovementsListing } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/Listing";
import { RepositoryMovementsListingAll } from "state-manager/states/Ready/states/DataManager/states/RepositoryMovements/states/ListingAll";
import { StocksListing } from "state-manager/states/Ready/states/DataManager/states/Stocks";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import * as Obj from "utils/object";

import { useTranslation } from "i18n";
import { TranslatedStr } from "types/src/TranslatedStr";
import { ContentLayoutHeader } from "ui/layouts/ContentLayout";
import { ReactElement } from "react";
import { Skeleton } from "ui/components/Skeleton";
import { CollectionsListing } from "../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Collections/states/Listing";
import { CollectionsListingAll } from "../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Collections/states/ListingAll";
import { InboundsListing } from "../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Inbounds/states/Listing";
import { InboundsListingAll } from "../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Inbounds/states/ListingAll";
import { ItemSetsListingAll } from "../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/ItemSets/states/ListingAll";
import { ItemSetsListing } from "../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/ItemSets/states/Listing";
import { CollectionView } from "../../../../../../packages/state-manager/src/states/Ready/states/DataManager/states/Collections/states/View";

export interface HeaderProps {
  selector: Selector<DataManager.State>;
  dispatch: (a: DataManager.Actions) => void;
}

export function Header(p: HeaderProps): ReactElement | null {
  const { t } = useTranslation();

  const r = useSelector(
    flow(
      p.selector,
      (s) => {
        return StocksListing.instance.isState(s.payload.subState)
          ? s.payload.subState
          : s.payload.subState.payload.listing;
      },
      (s) => {
        if (CollectionsListing.instance.isState(s)) {
          const dataType = CollectionsListing.instance.subStates.dataType;
          return {
            type: "listing",
            entity: "Collections",
            dataTypeId: s.payload.id,
            dataTypeTitle:
              dataType.states.loading.is(s.payload.dataType) ||
              dataType.states.loadError.is(s.payload.dataType)
                ? O.none
                : s.payload.dataType.payload.data,
          } as const;
        }
        if (CollectionsListingAll.instance.isState(s)) {
          return { type: "listingAll", entity: "Collections" } as const;
        }
        if (CollectionView.instance.isState(s)) {
          return {
            type: "collectionMovement",
            entity: "CollectionsMovements",
          } as const;
        }
        if (CustomersListing.instance.isState(s)) {
          const dataType = CustomersListing.instance.subStates.dataType;
          return {
            type: "listing",
            entity: "Customers",
            dataTypeId: s.payload.id,
            dataTypeTitle:
              dataType.states.loading.is(s.payload.dataType) ||
              dataType.states.loadError.is(s.payload.dataType)
                ? O.none
                : s.payload.dataType.payload.data,
          } as const;
        }
        if (CustomersListingAll.instance.isState(s)) {
          return { type: "listingAll", entity: "Customers" } as const;
        }
        if (InboundsListing.instance.isState(s)) {
          const dataType = InboundsListing.instance.subStates.dataType;
          return {
            type: "listing",
            entity: "Inbounds",
            dataTypeId: s.payload.id,
            dataTypeTitle:
              dataType.states.loading.is(s.payload.dataType) ||
              dataType.states.loadError.is(s.payload.dataType)
                ? O.none
                : s.payload.dataType.payload.data,
          } as const;
        }
        if (InboundsListingAll.instance.isState(s)) {
          return { type: "listingAll", entity: "Inbounds" } as const;
        }
        if (ItemSetsListing.instance.isState(s)) {
          const dataType = ItemSetsListing.instance.subStates.dataType;
          return {
            type: "listing",
            entity: "ItemSets",
            dataTypeId: s.payload.id,
            dataTypeTitle:
              dataType.states.loading.is(s.payload.dataType) ||
              dataType.states.loadError.is(s.payload.dataType)
                ? O.none
                : s.payload.dataType.payload.data,
          } as const;
        }
        if (ItemSetsListingAll.instance.isState(s)) {
          return { type: "listingAll", entity: "ItemSets" } as const;
        }
        if (SuppliersListing.instance.isState(s)) {
          const dataType = SuppliersListing.instance.subStates.dataType;
          return {
            type: "listing",
            entity: "Suppliers",
            dataTypeId: s.payload.id,
            dataTypeTitle:
              dataType.states.loading.is(s.payload.dataType) ||
              dataType.states.loadError.is(s.payload.dataType)
                ? O.none
                : s.payload.dataType.payload.data,
          } as const;
        }
        if (SuppliersListingAll.instance.isState(s)) {
          return { type: "listingAll", entity: "Suppliers" } as const;
        }
        if (RepositoriesListing.instance.isState(s)) {
          const dataType = RepositoriesListing.instance.subStates.dataType;
          return {
            type: "listing",
            entity: "Repositories",
            dataTypeId: s.payload.id,
            dataTypeTitle:
              dataType.states.loading.is(s.payload.dataType) ||
              dataType.states.loadError.is(s.payload.dataType)
                ? O.none
                : s.payload.dataType.payload.data,
          } as const;
        }
        if (RepositoriesListingAll.instance.isState(s)) {
          return { type: "listingAll", entity: "Repositories" } as const;
        }
        if (InventoryItemsListing.instance.isState(s)) {
          const dataType = InventoryItemsListing.instance.subStates.dataType;
          return {
            type: "listing",
            entity: "InventoryItems",
            dataTypeId: s.payload.id,
            dataTypeTitle:
              dataType.states.loading.is(s.payload.dataType) ||
              dataType.states.loadError.is(s.payload.dataType)
                ? O.none
                : s.payload.dataType.payload.data,
          } as const;
        }
        if (InventoryItemsListingAll.instance.isState(s)) {
          return { type: "listingAll", entity: "InventoryItems" } as const;
        }
        if (ItemMovementsListing.instance.isState(s)) {
          const dataType = ItemMovementsListing.instance.subStates.dataType;
          return {
            type: "listing",
            entity: "ItemMovements",
            dataTypeId: s.payload.id,
            dataTypeTitle:
              dataType.states.loading.is(s.payload.dataType) ||
              dataType.states.loadError.is(s.payload.dataType)
                ? O.none
                : s.payload.dataType.payload.data,
          } as const;
        }
        if (ItemMovementsListingAll.instance.isState(s)) {
          return { type: "listingAll", entity: "ItemMovements" } as const;
        }
        if (RepositoryMovementsListing.instance.isState(s)) {
          const dataType =
            RepositoryMovementsListing.instance.subStates.dataType;
          return {
            type: "listing",
            entity: "RepositoryMovements",
            dataTypeId: s.payload.id,
            dataTypeTitle:
              dataType.states.loading.is(s.payload.dataType) ||
              dataType.states.loadError.is(s.payload.dataType)
                ? O.none
                : s.payload.dataType.payload.data,
          } as const;
        }
        if (RepositoryMovementsListingAll.instance.isState(s)) {
          return { type: "listingAll", entity: "RepositoryMovements" } as const;
        }
        if (DataManager.pickingOrderState.subStates.listing.isState(s)) {
          const dataType =
            DataManager.pickingOrderState.subStates.listing.subStates.dataType;
          return {
            type: "listing",
            entity: "PickingOrders",
            dataTypeId: s.payload.id,
            dataTypeTitle:
              dataType.states.loading.is(s.payload.dataType) ||
              dataType.states.loadError.is(s.payload.dataType)
                ? O.none
                : s.payload.dataType.payload.data,
          } as const;
        }
        if (DataManager.pickingOrderState.subStates.listingAll.isState(s)) {
          return { type: "listingAll", entity: "PickingOrders" } as const;
        }
        if (StocksListing.instance.isState(s)) {
          return { type: "listingAll", entity: "Stocks" } as const;
        }

        silentUnreachableError(s);
        return { type: "unknown", entity: "unknown" } as const;
      },
    ),
    Obj.isDeepEqual,
  );

  switch (r.type) {
    case "collectionMovement":
      return (
        <ContentLayoutHeader
          title={t("Collection movements")}
          parentPage={getEntityTitle()}
        />
      );
    case "listing":
      return (
        <ContentLayoutHeader
          title={Fp.pipe(
            r.dataTypeTitle,
            O.map((v) => v.name),
            O.getOrElseW(() => <Skeleton contentWidth={15} />),
          )}
          parentPage={getEntityTitle()}
        />
      );

    case "listingAll":
      return <ContentLayoutHeader title={getEntityTitle()} />;
    case "unknown":
      return null;
  }

  function getEntityTitle(): TranslatedStr | null {
    switch (r.entity) {
      case "Stocks":
        return t("Stocks");
      case "Customers":
        return t("Customers");
      case "Suppliers":
        return t("Suppliers");
      case "Repositories":
        return t("Repositories");
      case "InventoryItems":
        return t("Inventory items");
      case "ItemMovements":
        return t("Item movements");
      case "RepositoryMovements":
        return t("Repository movements");
      case "PickingOrders":
        return t("Picking Orders");
      case "Collections":
        return t("Collections");
      case "Inbounds":
        return t("Receiving Inbounds");
      case "ItemSets":
        return t("Item Sets");
      case "CollectionsMovements":
        return t("Collections");
      case "unknown":
        return null;
    }
  }
}

import { silentUnreachableError } from "utils/exceptions";
import * as FormValue from "types/src/FormValue";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import * as Fp from "fp-ts/function";
import { Typed } from "utils/Typed";
import { NoEmptyString } from "types/src/NoEmptyString";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import * as Exits from "./types/Exits";
import { parentSearchState, schemaFieldsState } from "./utils";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s) || State.isSubmitted(s)) {
      return E.right({
        ...s,
        payload: {
          ...s.payload,
          schema: Fp.pipe(
            schemaFieldsState.reducer(s.payload.schema, a),
            E.getOrElse(() => s.payload.schema),
          ),
        },
      } as State.State);
    }

    return E.right(s);
  }

  if (parentSearchState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          ...s.payload,
          parent: parentSearchState.reducer(s.payload.parent, a),
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:Repositories:Create:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(
          State.loadError({
            dataTypeId: s.payload.dataTypeId,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Create:LoadSuccess": {
      if (State.isLoading(s)) {
        return E.right(
          State.ready({
            name: FormValue.initial(""),
            type: FormValue.initial(undefined),
            dataTypeId: s.payload.dataTypeId,
            isVirtual: false,
            parent: parentSearchState.states.idle.create({
              query: O.none,
              items: a.payload.repositories,
            }),
            schema: schemaFieldsState.states.init.create({
              schema: a.payload.schema,
              uiSchema: a.payload.ui,
              values: {},
            }),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Create:SetName": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        return E.right(
          State.ready({
            ...s.payload,
            name: NoEmptyString.isNoEmptyString(a.payload)
              ? FormValue.valid(a.payload)
              : FormValue.invalid("required" as const, a.payload),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Create:SetType": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        return E.right(
          State.ready({
            ...s.payload,
            type: FormValue.valid(a.payload),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Create:SetIsVirtual": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        return E.right(
          State.ready({
            ...s.payload,
            isVirtual: a.payload,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Create:Submit": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        const name = NoEmptyString.isNoEmptyString(s.payload.name.value)
          ? FormValue.valid(s.payload.name.value)
          : FormValue.invalid("required" as const, s.payload.name.value);
        const type = s.payload.type.value
          ? FormValue.valid(s.payload.type.value)
          : FormValue.invalid("required" as const, s.payload.type.value);
        const schema = Fp.pipe(
          schemaFieldsState.reducer(
            s.payload.schema,
            schemaFieldsState.actions.submit.create(),
          ),
          E.getOrElse(() => s.payload.schema),
        );

        if (
          FormValue.isValid(name) &&
          FormValue.isValid(type) &&
          schemaFieldsState.states.valid.is(schema)
        ) {
          return E.right(
            State.saving({
              name: name,
              type: type,
              dataTypeId: s.payload.dataTypeId,
              schema: schema,
              isVirtual: s.payload.isVirtual,
              parent: s.payload.parent,
            }),
          );
        } else {
          return E.right(
            State.submitted({
              type: type,
              name: name,
              dataTypeId: s.payload.dataTypeId,
              schema: schema,
              isVirtual: s.payload.isVirtual,
              parent: s.payload.parent,
            }),
          );
        }
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Create:SaveError": {
      if (State.isSaving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Create:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.left(Exits.exits.created.create(a.payload));
      }

      return E.right(s);
    }
    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}

import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import * as Rx from "rxjs";
import { BehaviorSubject } from "rxjs";

export namespace ControlState {
  export interface Props {
    submitted$: BehaviorValue<boolean>;
    focused$: BehaviorValue<Record<string, boolean | undefined>>;
    touched$: BehaviorValue<Record<string, boolean | undefined>>;
    onFocus: (id: string) => void;
    onBlur: (id: string) => void;
  }

  export interface ControlProps {
    focused$: BehaviorValue<boolean>;
    touched$: BehaviorValue<boolean>;
    onFocus: () => void;
    onBlur: () => void;
  }
}

const ControlState = createContext<ControlState.Props>({
  submitted$: new BehaviorValue(false, Rx.NEVER),
  focused$: new BehaviorValue({}, Rx.NEVER),
  touched$: new BehaviorValue({}, Rx.NEVER),
  onBlur: () => undefined,
  onFocus: () => undefined,
});

export function ControlStateProvider(p: {
  children?: ReactNode;
  submitted: boolean;
}): ReactElement {
  // I need `p.submitted` initial value only
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitted$ = useMemo(() => new BehaviorSubject(p.submitted), []);
  const value = useMemo<ControlState.Props>(() => {
    const focused$ = new Rx.BehaviorSubject<
      Record<string, boolean | undefined>
    >({});
    const touched$ = new Rx.BehaviorSubject<
      Record<string, boolean | undefined>
    >({});
    return {
      submitted$: BehaviorValue.fromBehaviorSubject(submitted$),
      focused$: BehaviorValue.fromBehaviorSubject(focused$),
      touched$: BehaviorValue.fromBehaviorSubject(touched$),
      onFocus: (id) => {
        const current = focused$.value[id];

        if (!current) {
          focused$.next({ ...focused$.value, [id]: true });
        }
      },
      onBlur: (id) => {
        const focused = focused$.value[id];
        const touched = touched$.value[id];
        if (focused) focused$.next({ ...focused$.value, [id]: false });
        if (!touched) touched$.next({ ...focused$.value, [id]: true });
      },
    };
  }, [submitted$]);

  useEffect(() => {
    if (p.submitted !== submitted$.value) {
      submitted$.next(p.submitted);
    }
  }, [p.submitted, submitted$]);

  return (
    <ControlState.Provider value={value}>{p.children}</ControlState.Provider>
  );
}

export function useControlState(id: string): ControlState.ControlProps {
  const { submitted$, focused$, touched$, onBlur, onFocus } =
    useContext(ControlState);

  return useMemo<ControlState.ControlProps>(
    () => ({
      focused$: focused$.map((c) => c[id] ?? false),
      touched$: BehaviorValue.combine([
        submitted$,
        touched$.map((c) => c[id] ?? false),
      ]).map(([submitted, touched]) => submitted || touched),
      onBlur: () => onBlur(id),
      onFocus: () => onFocus(id),
    }),
    [id, submitted$, focused$, touched$, onBlur, onFocus],
  );
}

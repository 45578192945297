import { Box } from "@mui/material";
import { FeaturedIcon } from "@layouts/ListingTable/List/FetaturedIcon";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

export namespace State {
  export interface Props {
    icon: ReactNode;
    title: ReactNode;
    subtitle: ReactNode;
    children?: ReactNode;
  }
}

export function State(p: State.Props) {
  return (
    <Box
      sx={(p) => ({
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: p.spacing(8),
      })}
    >
      <FeaturedIcon>{p.icon}</FeaturedIcon>
      <Box sx={(p) => ({ paddingTop: p.spacing(4) })} />
      <Typography
        variant="subtitle1"
        sx={(p) => ({
          textAlign: "center",
          maxWidth: 350,
          marginBottom: p.spacing(1),
        })}
      >
        {p.title}
      </Typography>
      <Typography
        variant="body2"
        sx={(p) => ({
          textAlign: "center",
          maxWidth: 350,
          color: p.palette.text.secondary,
        })}
      >
        {p.subtitle}
      </Typography>
      {p.children}
    </Box>
  );
}

import { useTranslation } from "i18n";
import * as O from "fp-ts/Option";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { DataTypeId } from "types/src/DataType/DataType";
import { OrderBy } from "types/src/OrderBy";
import { EntityListing } from "@Containers/Listing/EntityListing";
import { ListingTable } from "ui/layouts/ListingTable";
import { RepositoryId } from "types/src/Repositories/Repository";
import { useBehaviorValue } from "react-rx/behaviorValue";
import * as statusFilter from "@Containers/Listing/EntityListing/commonFilters/status";
import * as itemsFilter from "@Containers/Listing/EntityListing/commonFilters/items";
import * as parentFilter from "@Containers/Listing/EntityListing/commonFilters/parent-repository";
import * as virtualFilter from "@Containers/Listing/EntityListing/commonFilters/virtual";
import * as typeFilter from "@Containers/Listing/EntityListing/commonFilters/repository-type";

export function RepositoriesListing<
  CustomFiltersConfig extends RepositoriesListing.Filters.ConfigCustomBase = {},
>(p: RepositoriesListing.Props<CustomFiltersConfig>) {
  const { t } = useTranslation();

  const customFiltersConfig = useBehaviorValue(p.customFiltersConfig$);

  return (
    <EntityListing
      title={t("Recent repositories")}
      newItemTitle={t("New repository")}
      columns={
        {
          name: {
            label: t("Name"),
            type: ListingTable.CellType.text,
            renderProps: (v) => ({
              text: v.name,
              onClick: () => p.onItemClick(v.id),
            }),
            canReorder: true,
          },
          type: {
            label: t("Type"),
            type: ListingTable.CellType.text,
            renderProps: (v) => ({
              text: {
                static: t("Static"),
                dynamic: t("Dynamic"),
              }[v.type],
            }),
            canReorder: true,
          },
          virtualRepo: {
            label: t("Virtual"),
            type: ListingTable.CellType.badge,
            renderProps: (v) => ({
              color: v.isVirtual ? "green" : "blue",
              text: v.isVirtual ? t("Virtual") : t("Real"),
            }),
            canReorder: true,
          },
        } satisfies EntityListing.ColumnsType<RepositoriesListing.Item>
      }
      data$={p.data$}
      //region Filters
      customFiltersConfig={customFiltersConfig}
      filters$={p.filters$}
      onFilterChange={p.onFilterChange}
      onResetFilters={p.onResetFilters}
      //endregion
      onItemClick={p.onItemClick}
      onOrderChange={p.onOrderChange}
      orderBy$={p.orderBy$}
      onPageChange={p.onPageChange}
      state$={p.state$}
      onTypeClick={p.onTypeClick}
      onCreateNew={p.onCreateNew}
      bulkSelect={{
        ...p.bulkSelect,
        actions: [{ id: "remove", label: t("Remove") }],
      }}
      remove={{
        ...p.remove,
        getTitle: (c) =>
          c === 1 ? t("Remove repository") : t("Remove repositories"),
        getDescription: (c) =>
          c === 1
            ? t("Do you really want to remove this repository")
            : t("Do you really want to remove this repositories"),
      }}
      entryActions={(v) => [
        { label: t("Remove"), onClick: () => p.onRemove(v.id) },
      ]}
      columnsVisibility$={p.columnsVisibility$}
      onColumnsVisibilityChange={p.onColumnsVisibilityChange}
    />
  );
}

export namespace RepositoriesListing {
  export interface Item extends EntityListing.Item<RepositoryId> {
    name: string;
    type: "dynamic" | "static";
    isVirtual: boolean;
  }

  export namespace Filters {
    export type ConfigCustomBase = EntityListing.Filters.ConfigCustomBase;

    export const typeMap = {
      ...statusFilter.typeMap,
    } satisfies EntityListing.Filters.ListingTypeMapBase;

    export const stateToConfig = (
      state: itemsFilter.StateToConfigPayload &
        parentFilter.StateToConfigPayload,
      { t }: Pick<ReturnType<typeof useTranslation>, "t">,
    ) => ({
      ...statusFilter.stateToConfig({}, { t }),
      ...itemsFilter.stateToConfig(state, { t }),
      ...parentFilter.stateToConfig(state, { t }),
      ...virtualFilter.stateToConfig({}, { t }),
      ...typeFilter.stateToConfig({}, { t }),
    });

    export const converters = {
      ...statusFilter.converters,
      ...itemsFilter.converters,
      ...parentFilter.converters,
      ...virtualFilter.converters,
      ...typeFilter.converters,
    } satisfies EntityListing.Filters.ListingConvertersBase<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<keyof typeof typeMap, any>,
      typeof typeMap
    >;
  }

  export interface Props<
    CustomFiltersConfig extends Filters.ConfigCustomBase = {},
  > {
    data$: BehaviorValue<{
      total: number;
      entries: Item[];
      pagination: {
        hasNext: boolean;
        hasPrev: boolean;
      };
    }>;
    state$: BehaviorValue<"loading" | "fetching" | "ready">;

    //region Filters
    customFiltersConfig$: BehaviorValue<CustomFiltersConfig>;
    filters$: BehaviorValue<
      EntityListing.Filters.Criteria<Item, CustomFiltersConfig>
    >;
    onFilterChange: (
      v: EntityListing.Filters.CriteriaChange<Item, CustomFiltersConfig>,
    ) => void;
    onResetFilters: () => void;
    //endregion

    orderBy$: BehaviorValue<
      O.Option<
        OrderBy<"createdAt" | "updatedAt" | "name" | "type" | "virtualRepo">
      >
    >;
    bulkSelect: Omit<
      EntityListing.Props<Item, "remove", {}>["bulkSelect"],
      "actions"
    >;
    remove: Omit<EntityListing.RemoveProps, "getDescription" | "getTitle">;

    onCreateNew: (() => void) | undefined;
    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onItemClick: (id: RepositoryId) => void;
    onTypeClick: (id: DataTypeId) => void;
    onRemove: (id: RepositoryId) => void;
    onOrderChange: (
      p: "createdAt" | "updatedAt" | "name" | "type" | "virtualRepo",
    ) => void;
    columnsVisibility$: BehaviorValue<
      Record<
        | "id"
        | "dataType"
        | "createdAt"
        | "updatedAt"
        | "name"
        | "type"
        | "virtualRepo",
        boolean
      >
    >;
    onColumnsVisibilityChange: (
      v: Partial<
        Record<
          | "id"
          | "dataType"
          | "createdAt"
          | "updatedAt"
          | "name"
          | "type"
          | "virtualRepo",
          boolean
        >
      >,
    ) => void;
  }
}

import { PageLayout } from "ui/layouts/PageLayout";
import { MainSidebar } from "@Containers/MainSidebar";
import { Selector, useSelector } from "state-manager";
import { Actions, State } from "state-manager/states/Ready";
import { SecondarySidebar } from "@Containers/SecondarySidebar";
import * as ReadyContainer from "@Containers/Ready";
import * as Fp from "fp-ts/function";
import * as Playground from "state-manager/states/Ready/states/GraphqlPlayground";
import * as Temporal from "state-manager/states/Ready/states/Temporal";
import { isOneOf } from "utils/isOneOf";

export interface ReadyProps {
  selector: Selector<State>;
  dispatch: (a: Actions) => void;
}

export function Ready({ selector, dispatch }: ReadyProps) {
  const isFullScreen = useSelector(
    Fp.flow(
      selector,
      (v) => v.payload.subState,
      isOneOf([Playground.instance.isState, Temporal.instance.isState]),
    ),
  );
  return (
    <PageLayout
      fullScreenContent={isFullScreen}
      mainSidebar={<MainSidebar selector={selector} dispatch={dispatch} />}
      secondarySidebar={
        <SecondarySidebar selector={selector} dispatch={dispatch} />
      }
      header={<ReadyContainer.Header selector={selector} dispatch={dispatch} />}
    >
      <ReadyContainer.Content selector={selector} dispatch={dispatch} />
    </PageLayout>
  );
}

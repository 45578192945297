import { DataSchema } from "./dataSchema";
import {
  UiSchema,
  UiSchemaElement,
  UiSchemaElementType,
  UiSchemaElementTypeControl,
} from "./uiSchema";

// fixme: rename - add prefix or rename to `JsonSchemas`?
export type Value = {
  dataSchema: DataSchema;
  uiSchema: UiSchema;
};

export class JsonFormSchema {
  private constructor(
    public readonly schema: DataSchema,
    public readonly uiSchema: UiSchema,
  ) {}

  public static create(schema: DataSchema, uiSchema: UiSchema): JsonFormSchema {
    return new JsonFormSchema(schema, this.normalizeUiSchema(schema, uiSchema));
  }

  public static equals(a: JsonFormSchema, b: JsonFormSchema): boolean {
    return (
      JSON.stringify(a.schema) === JSON.stringify(b.schema) &&
      JSON.stringify(a.uiSchema) === JSON.stringify(b.uiSchema)
    );
  }

  public static normalizeUiSchema(
    schema: object,
    uiSchema: UiSchema,
  ): UiSchema {
    const paths = getLeafPaths(schema, "#");
    const s1 = UiSchema.filter(
      (c) => c.type !== UiSchemaElementType.control || paths.includes(c.scope),
      uiSchema,
    );
    const scopes = UiSchemaElement.traverse(
      (acc: string[], c) => {
        if (c.type === UiSchemaElementType.control) acc.push(c.scope);
        return acc;
      },
      [],
      s1,
    );
    const missing = paths.filter((p) => !scopes.includes(p));

    return missing.length > 0
      ? {
          ...s1,
          elements: [
            ...s1.elements,
            ...missing.map(
              (scope): UiSchemaElementTypeControl => ({
                type: UiSchemaElementType.control,
                scope,
              }),
            ),
          ],
        }
      : s1;
  }
}

function getLeafPaths(schema: DataSchema, prefix: string = ""): string[] {
  let paths: string[] = [];

  if (schema.type === "object" && schema.properties) {
    for (const key in schema.properties) {
      const newPrefix = prefix ? `${prefix}/properties/${key}` : key;
      paths = paths.concat(
        getLeafPaths(schema.properties[key] as DataSchema, newPrefix),
      );
    }
  } else {
    paths.push(prefix);
  }

  return paths;
}

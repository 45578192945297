import { BehaviorValue } from "rx-addons/BehaviorValue";
import React from "react";
import { BulkSelectProps } from "@layouts/ListingTable/types/BulkSelectProps";
import { Box } from "@mui/material";
import { Sxs } from "../../../types/styles";
import {
  DataEntryBase,
  ListData,
  PaginationDestination,
  EntryAction,
} from "../types/data";
import { styleTableBorderColor } from "../utils/styles";
import { ColumnsConfigBase, ColumnsVisibility } from "../types/columns";
import { Criteria, CriteriaChange, SortableColumns } from "../types/criteria";
import { FiltersConfigBase } from "../types/filters";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Table } from "./Table";

export namespace List {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
    Actions extends string,
  > = {
    title: string;
    columns: ColumnsConfig;
    filters: FiltersConfig;

    columnsVisibility$: BehaviorValue<
      ColumnsVisibility<DataEntry, ColumnsConfig>
    >;
    onColumnsVisibilityChange?: (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;

    criteria$: BehaviorValue<
      Criteria<SortableColumns<ColumnsConfig>, FiltersConfig>
    >;
    onCriteriaChange: (
      criteria: CriteriaChange<SortableColumns<ColumnsConfig>, FiltersConfig>,
    ) => void;
    onResetFilters: () => void;

    state$: BehaviorValue<"loading" | "fetching" | "ready">;
    data$: BehaviorValue<ListData<DataEntry>>;
    entryActions?: (entry: DataEntry) => EntryAction[];

    onPageChange?: (page: PaginationDestination) => void;
    bulkSelect: BulkSelectProps<DataEntry["id"], Actions> | undefined;
  };
}

export const List = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
  Actions extends string,
>({
  title,
  columns,
  columnsVisibility$,
  onColumnsVisibilityChange,
  filters,
  criteria$,
  onCriteriaChange,
  data$,
  entryActions,
  onPageChange,
  bulkSelect,
  state$,
  onResetFilters,
}: List.Props<DataEntry, ColumnsConfig, FiltersConfig, Actions>) => (
  <Box sx={sxs.wrapper}>
    <Header<DataEntry, ColumnsConfig, FiltersConfig>
      onResetFilters={onResetFilters}
      {...{
        title,
        filters,
        criteria$,
        onCriteriaChange,
      }}
    />
    <Table
      onResetFilters={onResetFilters}
      state$={state$}
      {...{
        columns,
        columnsVisibility$,
        onColumnsVisibilityChange,
        data$,
        entryActions,
        criteria$,
        onCriteriaChange,
        bulkSelect,
      }}
    />
    <Footer {...{ data$, onPageChange }} />
  </Box>
);

const sxs = {
  wrapper: (theme) => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,

    border: `1px solid ${styleTableBorderColor(theme)}`,
    borderRadius: theme.spacing(3),
  }),
} satisfies Sxs;

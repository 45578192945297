import React from "react";
import {
  JsonFormsRendererRegistryEntry,
  LayoutProps,
  rankWith,
  uiTypeIs,
  VerticalLayout,
} from "@jsonforms/core";
import { withJsonFormsLayoutProps } from "@jsonforms/react";

import { Layout } from "../components/Layout";

export const VerticalLayoutRenderer = ({
  uischema,
  schema,
  path,
  enabled,
  visible,
  renderers,
  cells,
}: LayoutProps) => {
  const verticalLayout = uischema as VerticalLayout;
  const childProps: Layout.Props = {
    elements: verticalLayout.elements,
    schema,
    path,
    enabled,
    direction: "column",
    visible,
  };

  return <Layout {...childProps} renderers={renderers} cells={cells} />;
};

export const entry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(1, uiTypeIs("VerticalLayout")),
  renderer: withJsonFormsLayoutProps(VerticalLayoutRenderer),
};

import { useTranslation } from "i18n";
import { ListingTable } from "ui/layouts/ListingTable";
import { EntityListing } from "@Containers/Listing/EntityListing";

export const typeMap = {
  executed: EntityListing.Filters.Type.select,
} satisfies EntityListing.Filters.ListingTypeMapBase;

export const stateToConfig = (
  state: {},
  { t }: Pick<ReturnType<typeof useTranslation>, "t">,
) => ({
  executed: {
    type: typeMap["executed"],
    options: {
      label: t("Execution status"),
      choices: [
        { id: "executed", label: t("Executed") },
        { id: "un-executed", label: t("Unexecuted") },
      ],
    },
  } satisfies ListingTable.CustomFilterConfig,
});

export const converters = {
  executed: {
    toListing: (id) => ({ id }),
    toState: (v) => v?.id as "executed" | "un-executed",
  },
} satisfies EntityListing.Filters.ListingConvertersBase<
  { executed?: "executed" | "un-executed" },
  typeof typeMap
>;

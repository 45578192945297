import { ListingTable } from "ui/layouts/ListingTable";
import { useTranslation } from "i18n";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as Rx from "rxjs";
import { ISODate } from "types/src/date/ISODate";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { DateRange } from "types/src/date/DateRange";
import { OrderBy } from "types/src/OrderBy";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { RepositoryMovementId } from "types/src/RepositoryMovements/RepositoryMovement";
import { useMemo } from "react";
import { Sku } from "types/src/Sku";
import { RepositoryId } from "types/src/Repositories/Repository";

const filtersConfigPredefined = {
  search: ListingTable.predefinedFilters.search,
  dateRange: ListingTable.predefinedFilters.dateRange,
} satisfies ListingTable.FiltersConfigBase["predefined"];

export function CollectionsMovementsListing(
  p: CollectionsMovementsListing.Props,
) {
  const { t } = useTranslation();

  const filtersConfig = {
    predefined: filtersConfigPredefined,
    custom: {},
  } satisfies ListingTable.FiltersConfigBase;
  type FiltersConfig = typeof filtersConfig;

  const columns = {
    id: {
      type: ListingTable.CellType.id,
      label: t("Id"),
      renderProps: (v) => ({ id: v.id }),
    },
    name: {
      type: ListingTable.CellType.textPrimarySecondary,
      label: t("Name"),
      renderProps: (v) => ({
        primary: v.type === "item" ? v.sku : v.name,
        secondary: { item: t("Item"), repository: t("Repository") }[v.type],
      }),
    },
    executed: {
      type: ListingTable.CellType.badge,
      label: t("Status"),
      renderProps: (v) => ({
        text: v.executed ? t("Executed") : t("Pending"),
        color: v.executed ? "green" : "blue",
      }),
    },
    from: {
      type: ListingTable.CellType.movedFrom,
      label: t("From"),
      renderProps: (v) => ({ text: v.from?.name }),
    },
    to: {
      type: ListingTable.CellType.movedTo,
      label: t("To"),
      renderProps: (v) => ({ text: v.to?.name }),
    },
    quantity: {
      type: ListingTable.CellType.number,
      label: t("Quantity"),
      renderProps: (v) => ({
        number: v.type === "item" ? v.quantity : undefined,
      }),
    },
    createdAt: {
      type: ListingTable.CellType.timeDate,
      label: t("Created at"),
      renderProps: (v) => ({ date: ISODate.toDate(v.createdAt) }),
      canReorder: true,
    },
  } satisfies ListingTable.ColumnsConfigBase<CollectionsMovementsListing.Item>;
  type ColumnsConfig = typeof columns;

  const { columnsVisibility$ } = useMemo(() => {
    return {
      columnsVisibility$: new BehaviorValue({}, Rx.NEVER),
    };
  }, []);

  return (
    <>
      <ListingTable<
        CollectionsMovementsListing.Item,
        ColumnsConfig,
        FiltersConfig,
        never
      >
        title={t("Collection movements")}
        data$={p.data$}
        columns={columns}
        filters={filtersConfig}
        //@ts-expect-error, fix me
        criteria$={BehaviorValue.combine([p.filters$, p.orderBy$]).map(
          ([filters, orderBy]) => ({
            filters: {
              predefined: {
                search: {
                  text: filters.search,
                },
                dateRange: {
                  start: Fp.pipe(
                    filters.createdAt?.[0],
                    O.fromNullable,
                    O.map(ISODate.toDate),
                    O.toUndefined,
                  ),
                  end: Fp.pipe(
                    filters.createdAt?.[1],
                    O.fromNullable,
                    O.map(ISODate.toDate),
                    O.toUndefined,
                  ),
                },
              },
              custom: filters.custom,
            },
            orderBy: O.toUndefined(orderBy),
          }),
        )}
        onResetFilters={p.onResetFilters}
        onCriteriaChange={(c) => {
          p.onFilterChange({
            ...O.getOrElse(() => ({}))(
              Fp.pipe(
                c.filters?.predefined?.search,
                O.fromNullable,
                O.map(
                  Fp.flow(
                    O.chain((v) => O.fromNullable(v?.text)),
                    O.chain(NoEmptyString.fromString),
                  ),
                ),
                O.map((search) => ({ search })),
              ),
            ),
            ...O.getOrElse(() => ({}))(
              Fp.pipe(
                c.filters?.predefined?.dateRange,
                O.fromNullable,
                O.map(
                  O.chain((v) =>
                    O.some<DateRange>([
                      v?.start ? ISODate.fromDate(v?.start) : undefined,
                      v?.end ? ISODate.fromDate(v?.end) : undefined,
                    ]),
                  ),
                ),
                O.map((createdAt) => ({ createdAt })),
              ),
            ),
            ...(c.filters?.custom ? { custom: c.filters?.custom } : {}),
          });
        }}
        onPageChange={p.onPageChange}
        state$={p.state$}
        columnsVisibility$={columnsVisibility$}
      />
    </>
  );
}

export namespace CollectionsMovementsListing {
  interface BaseItem<Id extends string> {
    id: Id;
    createdAt: ISODate;
    from?: {
      id: RepositoryId;
      name: string;
    };
    to?: {
      id: RepositoryId;
      name: string;
    };
    executed: boolean;
  }

  export interface ItemMovement extends BaseItem<ItemMovementId> {
    type: "item";
    sku: Sku;
    quantity: number;
  }

  export interface RepositoryMovement extends BaseItem<RepositoryMovementId> {
    type: "repository";
    name: string;
  }

  export type Item = ItemMovement | RepositoryMovement;

  export namespace Filters {
    export const Type = ListingTable.FilterType;

    export type ConfigCustomBase = ListingTable.FiltersConfigBase["custom"];

    export type Criteria = {
      search?: string;
      createdAt?: DateRange;
      custom: {};
    };

    export type CriteriaChange = Partial<{
      search: O.Option<NoEmptyString>;
      createdAt: O.Option<DateRange>;
      custom: {};
    }>;
  }

  export interface Props {
    data$: BehaviorValue<ListingTable.ListData<Item>>;
    state$: BehaviorValue<"loading" | "fetching" | "ready">;

    filters$: BehaviorValue<Filters.Criteria>;
    orderBy$: BehaviorValue<O.Option<OrderBy<"createdAt" | "updatedAt">>>;

    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onFilterChange: (v: Filters.CriteriaChange) => void;
    onResetFilters: () => void;
  }
}

import { useTranslation } from "i18n";
import { ListingTable } from "ui/layouts/ListingTable";
import { EntityListing } from "@Containers/Listing/EntityListing";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";

export const typeMap = {
  hasItems: EntityListing.Filters.Type.select,
} satisfies EntityListing.Filters.ListingTypeMapBase;

export const stateToConfig = (
  state: {},
  { t }: Pick<ReturnType<typeof useTranslation>, "t">,
) => ({
  hasItems: {
    type: typeMap["hasItems"],
    options: {
      label: t("With items"),
      choices: [
        { id: "has", label: t("Has items") },
        { id: "empty", label: t("Hasn't items") },
      ],
    },
  } satisfies ListingTable.CustomFilterConfig,
});

export const converters = {
  hasItems: {
    toListing: (id) => ({ id: id ? "has" : "empty" }),
    toState: Fp.flow(
      O.fromNullable,
      O.map((v) => v.id),
      O.chain(O.fromNullable),
      O.map((v) => v === "has"),
      O.toUndefined,
    ),
  },
} satisfies EntityListing.Filters.ListingConvertersBase<
  { hasItems?: boolean },
  typeof typeMap
>;

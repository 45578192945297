import { useContext, useMemo } from "react";
import { useBehaviorValue, useBehaviorValueRef } from "react-rx/behaviorValue";
import { Control as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor/elementTypes/Control";
import { UiContext } from "../../../../contexts/Ui";

export const useEditing = ({ id }: { id: string }) => {
  const { ui$, onUiChange } = useContext(UiContext);
  const selected = useBehaviorValue(
    useMemo(
      () =>
        ui$.map(({ editing }) =>
          editing ? !!editing?.selectedElements?.[id] : undefined,
        ),
      [id, ui$],
    ),
  );
  const selectedElementsRef = useBehaviorValueRef(
    useMemo(() => ui$.map(({ editing }) => editing?.selectedElements), [ui$]),
  );

  return useMemo<UI.Props["editing"]>(
    () =>
      selected !== undefined
        ? {
            selected,
            onSelectedChange: (selected) =>
              onUiChange({
                editing: {
                  selectedElements: {
                    ...selectedElementsRef.current,
                    [id]: selected,
                  },
                },
              }),
          }
        : undefined,
    [selected, onUiChange, selectedElementsRef, id],
  );
};

import { ISODate } from "types/src/date/ISODate";
import { Option } from "fp-ts/Option";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import * as O from "fp-ts/Option";
import { Client } from "ds";
import * as Rx from "rxjs";
import {
  deleteInventoryItems,
  getInventoryItems,
  GetInventoryItemsVars,
} from "ds/InventoryItems";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { getDataTypes } from "ds/DataTypes";
import * as NonEmptyArray from "fp-ts/NonEmptyArray";
import { Typed } from "utils/Typed";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { Sku } from "types/src/Sku";
import { RepositoryId } from "types/src/Repositories/Repository";
import { getRepositories } from "ds/Repositories";
import { strictGuard } from "utils/strictGuard";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingWithDataTypes } from "../../../../../../../../generic-states/ListingWithDataTypes";
import { Loading } from "../../../../../../../../generic-states/Loading";

const prefix = "Ready:DataManager:InventoryItems:ListingAll";

interface Repo {
  id: RepositoryId;
  name: string;
}

const createListingState = () => {
  const repositoriesState = Loading.createState<Repo[], string | undefined>(
    `${prefix}:repositories`,
    { equals: (a, b) => a === b },
  );
  const state = ListingWithDataTypes.createState<
    InventoryItemsListingAll.Filter,
    InventoryItemsListingAll.Item,
    "createdAt" | "updatedAt" | "sku",
    {
      repositories: Loading.GetState<typeof repositoriesState>;
    }
  >(prefix, {
    defaultFilters: {},
  });

  type St = ListingWithDataTypes.GetState<typeof state>;
  type Exits = ListingWithDataTypes.GetExits<typeof state>;
  type Ac =
    | ListingWithDataTypes.GetActions<typeof state>
    | Loading.GetActions<typeof repositoriesState>;

  const reducer = (s: St, a: Ac): E.Either<Exits, St> => {
    if (repositoriesState.isAction(a)) {
      const st = repositoriesState.reducer(s.payload.repositories, a);

      return E.right({
        ...s,
        payload: {
          ...s.payload,
          repositories: Fp.pipe(
            st,
            E.getOrElseW(() => s.payload.repositories),
          ),
        },
      } as St);
    }

    return state.reducer(s, a);
  };

  const epic: Epic<Ac, St, InventoryItemsListingAll.Deps> = (state$, deps) => {
    const main$ = state.epic(state$, {
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(getInventoryItems(client, getFetchVars(s))),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: { in: [DataTypeEntity.Item] },
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;
                    if (E.isLeft(v.dataTypes)) return v.dataTypes;

                    return E.right({
                      items: v.items.right,
                      dataTypes: v.dataTypes.right,
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      id: i.id,
                      createdAt: i.createdAt,
                      updatedAt: O.fromNullable(i.updatedAt),
                      sku: i.sku,
                      dataType: O.fromNullable(
                        r.dataTypes.items.find((v) => v.id === i.dataTypeId),
                      ),
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(deleteInventoryItems(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
      fetchDataTypes: () => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            return Rx.from(
              getDataTypes(client, {
                where: { entity: { in: [DataTypeEntity.Item] } },
              }),
            ).pipe(
              Rx.map(
                E.map((r) => r.items.map((i) => ({ id: i.id, name: i.name }))),
              ),
            );
          }),
        );
      },
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
    });

    const repositories$ = repositoriesState.epic(
      state$.pipe(Rx.map((s) => s.payload.repositories)),
      {
        get: (q) => {
          return deps.pyckAdminClient$.pipe(
            Rx.switchMap((client) => {
              return Rx.from(
                getRepositories(client, {
                  where: {
                    or: [{ name: { containsFold: q } }],
                  },
                }),
              );
            }),
            Rx.map(
              E.map((r) => {
                return r.items.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              }),
            ),
          );
        },
      },
    );

    return Rx.merge(main$, repositories$);
  };

  return {
    ...state,
    isAction: strictGuard(
      (a: Ac): a is Ac => state.isAction(a) || repositoriesState.isAction(a),
    ),
    reducer,
    epic,
    init: (): St =>
      state.init({
        repositories: repositoriesState.init(undefined),
      }),
    subStates: {
      ...state.subStates,
      repositories: repositoriesState,
    },
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetInventoryItemsVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetInventoryItemsVars["where"] = {
      and: [
        {
          createdAt: { gte: fields.createdAt?.[0], lte: fields.createdAt?.[1] },
          updatedAt: { gte: fields.updatedAt?.[0], lte: fields.updatedAt?.[1] },
        },
      ],
      or: [
        { sku: { containsFold: fields.search } },
        { data: fields.search ? { contains: ["", fields.search] } : undefined },
      ],
      sku: { containsFold: fields.sku },
      hasStocksWith: [
        {
          hasRepositoryWith: [{ id: { in: fields.repositories } }],
        },
      ],
      dataType: {
        in: Fp.pipe(
          fields.dataTypes,
          O.fromNullable,
          O.chain(NonEmptyArray.fromArray),
          O.toUndefined,
        ),
        isNil: Fp.pipe(
          fields.status,
          O.fromNullable,
          O.map((v) => v === "orphan"),
          O.toUndefined,
        ),
      },
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
};

export namespace InventoryItemsListingAll {
  export type Filter = Partial<{
    createdAt: DateRange;
    updatedAt: DateRange;
    search: string;
    sku: string;
    dataTypes: DataTypeId[];
    repositories: RepositoryId[];
    status: "active" | "orphan";
  }>;

  export const instance = createListingState();

  export type State = ListingWithDataTypes.GetState<typeof instance>;
  export type Actions = ListingWithDataTypes.GetActions<typeof instance>;
  export type Exits = ListingWithDataTypes.GetExits<typeof instance>;

  export interface Item {
    id: InventoryItemId;
    createdAt: ISODate;
    updatedAt: Option<ISODate>;
    sku: Sku;
    dataType: Option<{ id: DataTypeId; name: string }>;
  }

  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: () => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (v: Record<string, boolean>) => void;
  }
}

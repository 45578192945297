import { NoEmptyString } from "types/src/NoEmptyString";
import * as O from "fp-ts/Option";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";

export type State<E, T> =
  | Idle<T>
  | Selected<T>
  | Searching<T>
  | ItemSearchError<E, T>;

export const init =
  <T>(p: string) =>
  (): Idle<T> =>
    idle(p)({ items: [], query: O.none });

export const isState = <E, T>(p: string) =>
  strictGuard((s: State<E, T>): s is State<E, T> => {
    if (
      isIdle(p)(s) ||
      isSelected(p)(s) ||
      isSearching(p)(s) ||
      isItemSearchError(p)(s)
    )
      return true;

    silentUnreachableError(s);
    return false;
  });

// region Idle
export interface IdlePayload<T> {
  query: O.Option<NoEmptyString>;
  items: T[];
}

export interface Idle<T> {
  type: `${string}:Idle`;
  payload: IdlePayload<T>;
}

export const idle =
  (p: string) =>
  <T>(payload: Idle<T>["payload"]): Idle<T> => ({
    type: `${p}:Idle`,
    payload,
  });

export const isIdle =
  (p: string) =>
  <E, T>(a: State<E, T>): a is Idle<T> =>
    a.type === `${p}:Idle`;
// endregion

// region Selected
export interface SelectedPayload<T> extends IdlePayload<T> {
  item: T;
}

export interface Selected<T> {
  type: `${string}:Selected`;
  payload: SelectedPayload<T>;
}

export const selected =
  (p: string) =>
  <T>(payload: Selected<T>["payload"]): Selected<T> => ({
    type: `${p}:Selected`,
    payload,
  });

export const isSelected =
  (p: string) =>
  <E, T>(a: State<E, T>): a is Selected<T> =>
    a.type === `${p}:Selected`;
// endregion

// region Searching
export interface SearchingPayload<T> extends IdlePayload<T> {
  query: O.Some<NoEmptyString>;
}

export interface Searching<T> {
  type: `${string}:Searching`;
  payload: SearchingPayload<T>;
}

export const searching =
  (p: string) =>
  <T>(payload: Searching<T>["payload"]): Searching<T> => ({
    type: `${p}:Searching`,
    payload,
  });

export const isSearching =
  (p: string) =>
  <E, T>(a: State<E, T>): a is Searching<T> =>
    a.type === `${p}:Searching`;
// endregion

// region ItemSearchError
export interface ItemSearchErrorPayload<E, T> extends SearchingPayload<T> {
  error: E;
}

export interface ItemSearchError<E, T> {
  type: `${string}:Error`;
  payload: ItemSearchErrorPayload<E, T>;
}

export const itemSearchError =
  (p: string) =>
  <E, T>(payload: ItemSearchError<E, T>["payload"]): ItemSearchError<E, T> => ({
    type: `${p}:Error`,
    payload,
  });

export const isItemSearchError =
  (p: string) =>
  <E, T>(a: State<E, T>): a is ItemSearchError<E, T> =>
    a.type === `${p}Error`;
// endregion

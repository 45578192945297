import { Option } from "fp-ts/Option";
import { TranslatedStr } from "../TranslatedStr";
import { UUID } from "../UUID";
import { ISODate } from "../date/ISODate";
import { UiSchema } from "../jsonSchema/uiSchema";
import { DataSchema } from "../jsonSchema/dataSchema";

// region DataTypeId
declare const _dataTypeId: unique symbol;

export type DataTypeId = UUID & { [_dataTypeId]: "DataTypeId" };

export namespace DataTypeId {
  export const fromString = UUID.fromString<DataTypeId>;
}
// endregion

export enum DataTypeEntity {
  Attachment = "attachment",
  CollectionMovement = "collection_movement",
  Customer = "customer",
  Inbound = "inbound",
  InboundItem = "inbound_item",
  Item = "item",
  ItemSet = "item_set",
  ItemMovement = "item_movement",
  RepositoryMovement = "repository_movement",
  Order = "order",
  OrderItem = "order_item",
  Other = "other",
  Repository = "repository",
  Supplier = "supplier",
}

export const dataTypeEntities: DataTypeEntity[] = Object.values(DataTypeEntity);

export interface DataType {
  id: DataTypeId;
  name: TranslatedStr;
  description: TranslatedStr;
  entity: DataTypeEntity;
  schema: DataSchema;
  frontendSchema: UiSchema | undefined;
  default: boolean;
  createdAt: ISODate;
  updatedAt: Option<ISODate>;
  deletedAt: Option<ISODate>;
}

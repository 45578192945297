import { BehaviorValue } from "rx-addons/BehaviorValue";
import { BehaviorValueRef, useBehaviorValue } from "react-rx/behaviorValue";
import { useMemo } from "react";
import {
  UiSchemaElementPath,
  UiSchemaElementType,
} from "types/src/jsonSchema/uiSchema";
import { Value } from "types/src/jsonSchema/value";
import { UiState } from "../../types/uiState";
import { uiEditingGetSelectedIds } from "../../utils/ui/editing";
import { findElementPath } from "../../utils/uiSchema/findElementPath";

export const useCanGroup = ({
  ui$,
  valueRef,
}: {
  ui$: BehaviorValue<UiState>;
  valueRef: BehaviorValueRef<Value>;
}) =>
  useBehaviorValue<boolean>(
    useMemo(
      () =>
        ui$.map((ui) => {
          const selectedIds = uiEditingGetSelectedIds(ui);

          if (selectedIds.length < 2) {
            return false;
          }

          for (const id of selectedIds) {
            const path = findElementPath(
              {
                id,
                type: UiSchemaElementType.control,
              },
              valueRef.current.uiSchema,
            );

            if (!path || !isFirstLevel(path)) {
              return false;
            }
          }

          return true;
        }),
      [ui$, valueRef],
    ),
  );

const isFirstLevel = (path: UiSchemaElementPath): boolean =>
  path.length == /*['elements',index]*/ 2;

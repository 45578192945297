import { createContext } from "react";
import { JsonSchemaFormBuilder } from "../index";
import { defaultUiStateBehavior } from "../utils/ui/state";

export namespace UiContext {
  export type Value = Pick<
    Required<JsonSchemaFormBuilder.Props>,
    "ui$" | "onUiChange"
  >;
}

export const defaultValue: UiContext.Value = {
  ui$: defaultUiStateBehavior(),
  onUiChange: () => void 0,
};

export const UiContext = createContext<UiContext.Value>(defaultValue);

import { DataTypeId } from "../DataType/DataType";
import { Repository } from "../Repositories/Repository";
import { NoEmptyString } from "../NoEmptyString";
import { ISODate } from "../date/ISODate";
import { UUID } from "../UUID";
import { DataSchemaValue } from "../jsonSchema/DataSchemaValue";
import { CollectionId } from "../Collections/Collection";

// region CustomerId
declare const _repositoryMovementId: unique symbol;

export type RepositoryMovementId = UUID & {
  [_repositoryMovementId]: "RepositoryMovementId";
};

export namespace RepositoryMovementId {
  export const fromString = UUID.fromString<RepositoryMovementId>;
}
// endregion

export interface RepositoryMovement {
  id: RepositoryMovementId;
  dataTypeId: DataTypeId;
  fields: DataSchemaValue;
  createdAt: ISODate;
  updatedAt: ISODate | undefined;
  repository: Repository;
  from: Repository | undefined;
  to: Repository;
  executed: boolean;
  executedAt: ISODate | undefined;
  handler: NoEmptyString;
  orderId: string;
  collectionId: CollectionId | undefined;
  position: number | undefined;
}

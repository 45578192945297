import { Typed } from "utils/Typed";
import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import * as Rx from "rxjs";
import { GetGuardType } from "types/src/Utils";
import { Epic } from "../../../../types/RootEpic";

function createStore(p: string) {
  const states = Typed.builder.add("idle", (p: {}) => p).finish()(`${p}:state`);
  const actions = Typed.builder.finish()(`${p}:action`);

  const exits = Typed.builder.finish()(`${p}:exit`);

  type State = Typed.GetTypes<typeof states>;
  type Action = Typed.GetTypes<typeof actions>;
  type Exit = Typed.GetTypes<typeof exits>;

  const epic: Epic<Action, State, {}> = () => Rx.NEVER;

  return {
    states,
    actions,
    exits,
    isState: Typed.getGuard(states),
    isAction: Typed.getGuard(actions),
    isExit: Typed.getGuard(exits),
    init: () => states.idle.create({ operations: {} }),
    reducer: (s: State, a: Action): E.Either<Exit, State> => {
      silentUnreachableError(a);
      return E.right(s);
    },
    epic,
  };
}

export const instance = createStore("Ready:Temporal");

export type Store = typeof instance;
export type State = GetGuardType<Store["isState"]>;
export type Action = GetGuardType<Store["isAction"]>;
export type Exit = GetGuardType<Store["isExit"]>;

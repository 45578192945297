import React, { useMemo } from "react";
import { Box, Button } from "@mui/material";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import {
  ListData,
  DataEntryBase,
  PaginationDestination,
} from "@layouts/ListingTable/types/data";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { useTranslation } from "i18n";
import { styleSecondaryTextColor } from "@layouts/ListingTable/utils/styles";
import { Sxs } from "../../../types/styles";

export namespace Footer {
  export type Props<DataEntry extends DataEntryBase> = {
    data$: BehaviorValue<ListData<DataEntry>>;
    onPageChange?: (page: PaginationDestination) => void;
  };
}

export const Footer = <DataEntry extends DataEntryBase>({
  data$,
  onPageChange,
}: Footer.Props<DataEntry>) => {
  const { t } = useTranslation();
  const { hasPrev, hasNext } = useBehaviorValue(
    useMemo(() => data$.map(({ pagination }) => pagination), [data$]),
  );

  return (
    <Box sx={sxs.wrapper}>
      <PaginationButton
        disabled={!hasPrev}
        onClick={() => onPageChange?.("start")}
      >
        <span>⟪</span>
        {t("First")}
      </PaginationButton>
      <PaginationButton
        disabled={!hasPrev}
        onClick={() => onPageChange?.("prev")}
      >
        <span>⟨</span>
        {t("Previous")}
      </PaginationButton>
      <PaginationButton
        disabled={!hasNext}
        onClick={() => onPageChange?.("next")}
      >
        {t("Next")}
        <span>⟩</span>
      </PaginationButton>
      <PaginationButton
        disabled={!hasNext}
        onClick={() => onPageChange?.("end")}
      >
        {t("Last")}
        <span>⟫</span>
      </PaginationButton>
    </Box>
  );
};

const PaginationButton: React.FC<
  React.PropsWithChildren<{ onClick: () => void; disabled: boolean }>
> = ({ onClick, disabled, children }) => (
  <Button
    variant="outlined"
    sx={sxs.paginationButton}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </Button>
);

const sxs = {
  wrapper: (theme) => ({
    display: "flex",
    gap: 3,
    justifyContent: "end",
    alignItems: "center",
    padding: theme.spacing(3, 6, 4),
  }),
  paginationButton: (theme) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2, 3.5),
    gap: "0.7em",
    "&:not(.Mui-disabled) > span": {
      color: styleSecondaryTextColor(theme),
    },
  }),
} satisfies Sxs;

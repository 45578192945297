import * as Fp from "fp-ts/function";
import { reduce } from "fp-ts/ReadonlyArray";
import * as O from "fp-ts/Option";
import { DateRange } from "types/src/date/DateRange";
import { EntityListing } from "../index";

// fixme: add return types and better typing (there are some `any`)

export const stateToListing = <
  State extends Partial<{
    search: string;
    createdAt: DateRange;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [filter: string]: any;
  }>,
  TypeMap extends EntityListing.Filters.ListingTypeMapBase,
  Converters extends EntityListing.Filters.ListingConvertersBase<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<keyof TypeMap, any>,
    TypeMap
  >,
>(
  { search, createdAt, ...custom }: State,
  converters: Converters,
) => ({
  search,
  createdAt,
  custom: Fp.pipe(
    Object.keys(custom), // `fp-ts/Record/keys()` changes order
    reduce({}, (acc, key) => ({
      ...acc,
      [key]:
        custom[key] === undefined
          ? undefined
          : converters[key]?.toListing(custom[key]),
    })),
  ),
});

export const listingToState = <
  CustomFiltersConfig extends EntityListing.Filters.ConfigCustomBase,
  TypeMap extends EntityListing.Filters.ListingTypeMapBase,
  Converters extends EntityListing.Filters.ListingConvertersBase<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<keyof TypeMap, any>,
    TypeMap
  >,
>(
  {
    search,
    createdAt,
    custom,
  }: EntityListing.Filters.CriteriaChange<
    EntityListing.Item<string>,
    CustomFiltersConfig
  >,
  converters: Converters,
) => ({
  ...(undefined !== search ? { search } : {}),
  ...(undefined !== createdAt ? { createdAt } : {}),
  ...(custom
    ? Fp.pipe(
        Object.entries(custom),
        reduce({}, (acc, [key, /*fixme: why this is `any`?*/ opt]) =>
          opt === undefined
            ? { ...acc, [key]: undefined }
            : {
                ...acc,
                [key]: Fp.pipe(
                  O.fromNullable(converters[key]),
                  O.map((v) => v.toState),
                  O.ap(opt),
                ),
              },
        ),
      )
    : {}),
});

import React from "react";
import { createSvgIcon } from "@mui/material";

export const Copy = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_2898_12538)">
      <path
        d="M8.75008 1.66835C8.18754 1.67597 7.84983 1.70854 7.57676 1.84767C7.26316 2.00746 7.00819 2.26243 6.8484 2.57603C6.70927 2.8491 6.6767 3.18681 6.66908 3.74935M16.2501 1.66835C16.8126 1.67597 17.1503 1.70854 17.4234 1.84767C17.737 2.00746 17.992 2.26243 18.1518 2.57603C18.2909 2.8491 18.3235 3.1868 18.3311 3.74934M18.3311 11.2493C18.3235 11.8119 18.2909 12.1496 18.1518 12.4227C17.992 12.7363 17.737 12.9912 17.4234 13.151C17.1503 13.2902 16.8126 13.3227 16.2501 13.3304M18.3334 6.66601V8.33268M11.6668 1.66602H13.3334M4.33341 18.3327H10.6667C11.6002 18.3327 12.0669 18.3327 12.4234 18.151C12.737 17.9912 12.992 17.7363 13.1518 17.4227C13.3334 17.0661 13.3334 16.5994 13.3334 15.666V9.33268C13.3334 8.39926 13.3334 7.93255 13.1518 7.57603C12.992 7.26243 12.737 7.00746 12.4234 6.84767C12.0669 6.66602 11.6002 6.66602 10.6667 6.66602H4.33341C3.39999 6.66602 2.93328 6.66602 2.57676 6.84767C2.26316 7.00746 2.00819 7.26243 1.8484 7.57603C1.66675 7.93255 1.66675 8.39926 1.66675 9.33268V15.666C1.66675 16.5994 1.66675 17.0661 1.8484 17.4227C2.00819 17.7363 2.26316 17.9912 2.57676 18.151C2.93328 18.3327 3.39999 18.3327 4.33341 18.3327Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2898_12538">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "Copy",
);

import * as Rx from "rxjs";
import * as ItemMovementsApi from "ds/ItemMovements";
import * as RepositoryMovementsApi from "ds/RepositoryMovements";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { CollectionId } from "types/src/Collections/Collection";
import { Client } from "ds";
import { ItemMovement } from "types/src/ItemMovements/ItemMovement";
import { RepositoryMovement } from "types/src/RepositoryMovements/RepositoryMovement";
import { sequenceT } from "fp-ts/Apply";
import { sortBy } from "rambda";
import { DateRange } from "types/src/date/DateRange";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingState } from "../../../../../../../../generic-states/Listing";

const prefix = "Ready:DataManager:Collections:View";

const createListingState = () => {
  const state = ListingState.createState<
    CollectionView.Filters,
    CollectionView.Item,
    "createdAt" | "updatedAt",
    {
      id: CollectionId;
    }
  >(prefix, {
    defaultFilters: {},
  });

  type St = ListingState.GetState<typeof state>;
  type Ac = ListingState.GetActions<typeof state>;

  const epic: Epic<Ac, St, CollectionView.Deps> = (state$, deps) => {
    const listing$ = state.epic(state$, {
      fetchItems: (s) => {
        const [items, repo] = getFetchVars(s);
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(ItemMovementsApi.getItemMovements(client, items)),
              repositories: Rx.from(
                RepositoryMovementsApi.getRepositoryMovements(client, repo),
              ),
            }),
          ),
          Rx.map(({ items, repositories }) =>
            sequenceT(E.Apply)(items, repositories),
          ),
          Rx.map(
            E.map(([items, repos]) => ({
              pageInfo: {
                hasNextPage: false,
                hasPreviousPage: false,
                prevCursor: undefined,
                nextCursor: undefined,
                startCursor: undefined,
                endCursor: undefined,
              },
              total: items.totalCount + repos.totalCount,
              items: sortBy(
                (v) => v.position ?? 0,
                [
                  ...items.items.map(
                    (v): CollectionView.ItemWrapped => ({
                      ...v,
                      type: "item",
                    }),
                  ),
                  ...repos.items.map(
                    (v): CollectionView.RepositoryWrapped => ({
                      ...v,
                      type: "repository",
                    }),
                  ),
                ],
              ),
            })),
          ),
        );
      },
      removeItems: (ids) => Rx.of(E.left(ids)),
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
    });

    return listing$;
  };

  return {
    ...state,
    epic,
    init: (id: CollectionId): St => state.init({ id }),
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): [
    ItemMovementsApi.GetItemMovementsVars,
    RepositoryMovementsApi.GetRepositoryMovementsVars,
  ] {
    const item: ItemMovementsApi.GetItemMovementsVars = {
      orderBy: s.payload.order,
      where: {
        collection: { eq: s.payload.id },
      },
    };
    const repo: RepositoryMovementsApi.GetRepositoryMovementsVars = {
      orderBy: s.payload.order,
      where: {
        collection: {
          eq: s.payload.id,
        },
      },
    };

    if (state.states.loading.is(s)) {
      return [
        { ...item, first: s.payload.perPage },
        { ...repo, first: s.payload.perPage },
      ];
    }

    switch (s.payload.page) {
      case "start":
        return [
          { ...item, first: s.payload.perPage },
          { ...repo, first: s.payload.perPage },
        ];
      case "prev":
        return [
          { ...item, last: s.payload.perPage },
          { ...repo, last: s.payload.perPage },
        ];
      case "next":
        return [
          {
            ...item,
            last: s.payload.perPage,
            after: s.payload.pageInfo.nextCursor,
          },
          {
            ...repo,
            last: s.payload.perPage,
            after: s.payload.pageInfo.nextCursor,
          },
        ];
      case "end":
        return [
          { ...item, last: s.payload.perPage },
          { ...repo, last: s.payload.perPage },
        ];
      case "current":
        return [
          { ...item, last: s.payload.perPage },
          { ...repo, last: s.payload.perPage },
        ];
    }
  }
};

export namespace CollectionView {
  export const instance = createListingState();

  export type State = ListingState.GetState<typeof instance>;
  export type Actions = ListingState.GetActions<typeof instance>;
  export type Exits = ListingState.GetExits<typeof instance>;

  export interface ItemWrapped extends ItemMovement {
    type: "item";
  }
  export interface RepositoryWrapped extends RepositoryMovement {
    type: "repository";
  }

  export type Item = ItemWrapped | RepositoryWrapped;

  export type Filters = Partial<{
    search: string;
    createdAt: DateRange;
  }>;
  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: () => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (v: Record<string, boolean>) => void;
  }
}

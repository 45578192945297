import React from "react";
import { createSvgIcon } from "@mui/material";

export const ColumnsSpaced = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.66667 2.5H5.16667C4.23325 2.5 3.76654 2.5 3.41002 2.68166C3.09641 2.84144 2.84144 3.09641 2.68166 3.41002C2.5 3.76654 2.5 4.23325 2.5 5.16667V14.8333C2.5 15.7668 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1586 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5H5.66667C6.60009 17.5 7.0668 17.5 7.42332 17.3183C7.73692 17.1586 7.99189 16.9036 8.15168 16.59C8.33333 16.2335 8.33333 15.7668 8.33333 14.8333V5.16667C8.33333 4.23325 8.33333 3.76654 8.15168 3.41002C7.99189 3.09641 7.73692 2.84144 7.42332 2.68166C7.0668 2.5 6.60009 2.5 5.66667 2.5Z"
      stroke="currentColor"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8333 2.5H14.3333C13.3999 2.5 12.9332 2.5 12.5767 2.68166C12.2631 2.84144 12.0081 3.09641 11.8483 3.41002C11.6667 3.76654 11.6667 4.23325 11.6667 5.16667V14.8333C11.6667 15.7668 11.6667 16.2335 11.8483 16.59C12.0081 16.9036 12.2631 17.1586 12.5767 17.3183C12.9332 17.5 13.3999 17.5 14.3333 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1586 17.1586 16.9036 17.3183 16.59C17.5 16.2335 17.5 15.7668 17.5 14.8333V5.16667C17.5 4.23325 17.5 3.76654 17.3183 3.41002C17.1586 3.09641 16.9036 2.84144 16.59 2.68166C16.2335 2.5 15.7668 2.5 14.8333 2.5Z"
      stroke="currentColor"
      strokeWidth="1.67"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "ColumnsSpaced",
);

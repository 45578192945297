import * as ItemSearch from "state-manager/generic-states/ItemSearch";
import { ReactElement } from "react";
import { SearchCombobox, SelectorProps } from "@Containers/Form/SearchCombobox";
import { Selector } from "state-manager";
import { flow } from "fp-ts/function";
import { TranslatedStr } from "types/src/TranslatedStr";
import * as O from "fp-ts/Option";
import { StateConstructor } from "state-manager/generic-states/ItemSearch";

export interface ItemSearchInputProps<E, T> {
  label: TranslatedStr;
  selector: Selector<ItemSearch.State<E, T>>;
  dispatch: (a: ItemSearch.Actions<E, T>) => void;
  getLabel: (v: T) => TranslatedStr;
  getId: (v: T) => string;
  constructor: StateConstructor<E, T>;
}

export function ItemSearchInput<E, T>(
  p: ItemSearchInputProps<E, T>,
): ReactElement {
  const selector = flow(p.selector, (v): SelectorProps<T> => {
    return {
      validation: p.constructor.states.itemSearchError.is(v)
        ? "error"
        : undefined,
      value: p.constructor.getValue(v),
      options: v.payload.items.map((i) => ({
        value: i,
        label: p.getLabel(i),
      })),
      isLoading: p.constructor.states.searching.is(v),
    };
  });

  return (
    <SearchCombobox
      label={p.label}
      onChange={flow(
        O.map(p.constructor.actions.selectItem.create),
        O.getOrElseW(p.constructor.actions.clear.create),
        p.dispatch,
      )}
      onSearch={flow(p.constructor.actions.setQuery.create, p.dispatch)}
      selector={selector}
      getId={p.getId}
    />
  );
}

import { ISODate } from "types/src/date/ISODate";
import { DataTypeId } from "types/src/DataType/DataType";
import { omitEmpties } from "utils/value";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { PickingOrderItemId } from "types/src/PickingOrder/PickingOrderItem";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Cursor } from "types";
import {
  GetPickingOrderItemsQueryVariables,
  PickingOrderItemOrderField,
} from "../generated/graphql";
import { toApiOrderDirection } from "../transformers/OrderDirection";
import { Where } from "./Where";
import * as PickingOrders from "./PickingOrders";

export type WhereSchema = Where<{
  data: Where.Map;
  createdAt: Where.Ord<ISODate>;
  updatedAt: Where.Ord<ISODate>;
  id: Where.EqIn<PickingOrderItemId>;
  dataTypeId: Where.EqNil<DataTypeId>;
  hasOrder: Where.Value<boolean>;
  hasOrderWith: Where.WithWhereList<PickingOrders.WhereSchema>;
  orderId: Where.EqIn<PickingOrderId>;
  quantity: Where.Ord<number>;
  sku: Where.Text;
}>;

export const whereSchema = Where.create<WhereSchema>({
  data: Where.map("Data"),
  createdAt: Where.ord(),
  updatedAt: Where.ord(),
  id: Where.eqIn(),
  dataTypeId: Where.eqNil("dataTypeID"),
  hasOrder: Where.value(),
  hasOrderWith: Where.withWhereList(() => PickingOrders.whereSchema),
  orderId: Where.eqIn("orderID"),
  quantity: Where.ord(),
  sku: Where.text(),
});

export type PickingOrderItemVarsWhere = Where.GetType<WhereSchema>;

export interface GetPickingOrderItemsVars {
  after?: Cursor;
  first?: number;
  before?: Cursor;
  last?: number;
  orderBy?: {
    by: "createdAt" | "updatedAt" | "orderId" | "quantity" | "sku";
    direction: "asc" | "desc";
  };
  where?: PickingOrderItemVarsWhere;
}

export function getPickingOrderItemsVarsToApiVars(
  vars: GetPickingOrderItemsVars,
): GetPickingOrderItemsQueryVariables {
  return (
    omitEmpties({
      first: vars.first,
      last: vars.last,
      after: vars.after,
      before: vars.before,
      where: omitEmpties(
        pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toNullable,
        ),
      ),
      orderBy: pipe(
        vars.orderBy,
        O.fromNullable,
        O.map((v) => ({
          field: pipe(
            v.by,
            (v) =>
              ({
                createdAt: PickingOrderItemOrderField.CreatedAt,
                updatedAt: PickingOrderItemOrderField.UpdatedAt,
                orderId: PickingOrderItemOrderField.OrderId,
                quantity: PickingOrderItemOrderField.Quantity,
                sku: PickingOrderItemOrderField.Sku,
              })[v],
          ),
          direction: pipe(v.direction, toApiOrderDirection),
        })),
        O.toUndefined,
      ),
    }) ?? {}
  );
}

import { silentUnreachableError } from "utils/exceptions";
import { DataTypeId } from "types/src/DataType/DataType";
import { RepositoryId } from "types/src/Repositories/Repository";
import * as FormValue from "types/src/FormValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { RepositoryType } from "types/src/Repositories/RepositoryType";
import { strictGuard } from "utils/strictGuard";
import {
  ParentSearchState,
  SchemaFieldsState,
  SchemaFieldsStatesMap,
} from "../utils";

// region State
export type State =
  | Loading
  | LoadError
  | Ready
  | Submitted
  | Saving
  | RemoveConfirmation
  | Removing;

export const isState = strictGuard((s: State): s is State => {
  const type = s.type as State["type"];

  switch (type) {
    case "Ready:DataManager:Repositories:Edit:Loading":
    case "Ready:DataManager:Repositories:Edit:LoadError":
    case "Ready:DataManager:Repositories:Edit:Ready":
    case "Ready:DataManager:Repositories:Submitted":
    case "Ready:DataManager:Repositories:Edit:Saving":
    case "Ready:DataManager:Repositories:Edit:RemoveConfirmation":
    case "Ready:DataManager:Repositories:Edit:Removing":
      return true;
    default:
      silentUnreachableError(type);
      return false;
  }
});
// endregion

// region Loading
export interface LoadingPayload {
  id: RepositoryId;
}

export interface Loading {
  type: "Ready:DataManager:Repositories:Edit:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:Repositories:Edit:Loading",
  payload,
});

export const isLoading = (a: State): a is Loading =>
  a.type === "Ready:DataManager:Repositories:Edit:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:Repositories:Edit:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:Repositories:Edit:LoadError",
  payload,
});

export const isLoadError = (a: State): a is LoadError =>
  a.type === "Ready:DataManager:Repositories:Edit:LoadError";
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  dataTypeId: DataTypeId;
  schema: SchemaFieldsState;
  name: FormValue.Value<"required", NoEmptyString, string>;
  type: FormValue.Value<"required", RepositoryType, RepositoryType | undefined>;
  parent: ParentSearchState;
  isVirtual: boolean;
}

export interface Ready {
  type: "Ready:DataManager:Repositories:Edit:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:Repositories:Edit:Ready",
  payload,
});

export const isReady = (a: State): a is Ready =>
  a.type === "Ready:DataManager:Repositories:Edit:Ready";
// endregion

// region Submitted
export interface SubmittedPayload extends ReadyPayload {
  name: FormValue.SubmittedValue<ReadyPayload["name"]>;
  type: FormValue.SubmittedValue<ReadyPayload["type"]>;
}

export interface Submitted {
  type: "Ready:DataManager:Repositories:Submitted";
  payload: SubmittedPayload;
}

export const submitted = (payload: Submitted["payload"]): Submitted => ({
  type: "Ready:DataManager:Repositories:Submitted",
  payload,
});

export const isSubmitted = (a: State): a is Submitted =>
  a.type === "Ready:DataManager:Repositories:Submitted";
// endregion

// region Saving
export interface SavingPayload extends ReadyPayload {
  name: FormValue.Valid<NoEmptyString>;
  type: FormValue.Valid<RepositoryType>;
  schema: SchemaFieldsStatesMap["valid"];
}

export interface Saving {
  type: "Ready:DataManager:Repositories:Edit:Saving";
  payload: SavingPayload;
}

export const saving = (payload: Saving["payload"]): Saving => ({
  type: "Ready:DataManager:Repositories:Edit:Saving",
  payload,
});

export const isSaving = (a: State): a is Saving =>
  a.type === "Ready:DataManager:Repositories:Edit:Saving";
// endregion

// region RemoveConfirmation
export interface RemoveConfirmPayload extends ReadyPayload {}

export interface RemoveConfirmation {
  type: "Ready:DataManager:Repositories:Edit:RemoveConfirmation";
  payload: RemoveConfirmPayload;
}

export const removeConfirmation = (
  payload: RemoveConfirmation["payload"],
): RemoveConfirmation => ({
  type: "Ready:DataManager:Repositories:Edit:RemoveConfirmation",
  payload,
});

export const isRemoveConfirmation = (s: State): s is RemoveConfirmation =>
  s.type === "Ready:DataManager:Repositories:Edit:RemoveConfirmation";
// endregion

// region Removing
export interface RemovingPayload extends ReadyPayload {}

export interface Removing {
  type: "Ready:DataManager:Repositories:Edit:Removing";
  payload: RemovingPayload;
}

export const removing = (payload: Removing["payload"]): Removing => ({
  type: "Ready:DataManager:Repositories:Edit:Removing",
  payload,
});

export const isRemoving = (s: State): s is Removing =>
  s.type === "Ready:DataManager:Repositories:Edit:Removing";

// endregion
export function isLoaded(a: State): a is Exclude<State, Loading | LoadError> {
  return !isLoading(a) && !isLoadError(a);
}

export const init = loading;

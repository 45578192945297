import { ReactElement, ReactNode } from "react";

import { useTranslation } from "i18n";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "icons";
import { alpha } from "@mui/material/styles";

export interface ConfirmationModalProps {
  title: ReactNode;
  confirmButtonText: ReactNode;
  confirmButtonColor: "error" | "primary";
  children?: ReactNode;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  isLoading?: boolean;
  icon: ReactElement;
  iconColor: "error" | "warning" | "success" | "primary";
}

export function ConfirmationModal(p: ConfirmationModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Dialog open onClose={p.onCancel}>
      <Box
        sx={(p) => ({
          display: "grid",
          gridTemplateRows: `auto ${p.spacing(4)} auto ${p.spacing(
            1,
          )} auto ${p.spacing(8)}`,
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Icon color={p.iconColor} icon={p.icon} />
          <IconButton
            onClick={p.onCancel}
            sx={(p) => ({ color: alpha(p.palette.text.primary, 0.1) })}
          >
            <Close />
          </IconButton>
        </Box>
        <span />

        <DialogTitle>{p.title}</DialogTitle>
        <span />
        <DialogContent>
          <DialogContentText>{p.children}</DialogContentText>
        </DialogContent>
        <span />
        <DialogActions>
          <Button variant={"outlined"} onClick={p.onCancel}>
            {t("Cancel")}
          </Button>
          <Button
            variant={"contained"}
            color={p.confirmButtonColor}
            onClick={p.onConfirm}
            disabled={p.isLoading}
          >
            {p.confirmButtonText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

function Icon(p: {
  color: "error" | "success" | "warning" | "primary";
  icon: ReactElement;
}) {
  return (
    <Box
      sx={(t) => ({
        boxSizing: "content-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: t.spacing(3),
        aspectRatio: 1,
        color: t.palette[p.color].main,
        background: alpha(t.palette[p.color].main, 0.3),
        border: `8px solid ${alpha(t.palette[p.color].main, 0.1)}`,
        borderRadius: "50%",
        width: t.spacing(6),
        backgroundClip: "padding-box",
      })}
    >
      {p.icon}
    </Box>
  );
}

import { gql } from "@apollo/client";
import { repositoryFragment } from "./Repository";

export const repositoryMovementFragment = gql`
  ${repositoryFragment}

  fragment RepositoryMovementFragment on RepositoryMovement {
    id
    dataTypeID
    data
    createdAt
    updatedAt
    orderID
    collectionID
    repository {
      ...RepositoryFragment
    }
    from {
      ...RepositoryFragment
    }
    to {
      ...RepositoryFragment
    }
    executed
    executedAt
    handler
    position
  }
`;

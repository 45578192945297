import { Value } from "types/src/jsonSchema/value";
import { UiSchemaElementTypeControl } from "types/src/jsonSchema/uiSchema";
import { getAllElementNames } from "../dataSchema/getAllElementNames";
import { ControlDataSchema, ControlSchemas } from "../../types/control/schema";
import { findControlById } from "./findById";
import { makeControlName } from "./make";
import { controlInsertAtPath } from "./insertAtPath";

export const controlCloneById = ({
  id,
  value,
}: {
  id: string;
  value: Value;
}): Value => {
  const control = findControlById(id, {
    uiSchema: value.uiSchema,
    dataSchema: value.dataSchema,
  });
  if (!control) {
    return value; // maybe log
  }

  const clone = controlClone(control, {
    existingNames: getAllElementNames(value.dataSchema),
  });

  return controlInsertAtPath({
    control: clone,
    uiPath: control.uiPath,
    value,
  });
};

export const controlClone = (
  control: ControlSchemas,
  {
    existingNames,
  }: {
    existingNames: Record<string, unknown>;
  },
): ControlSchemas & { name: string } => {
  const name = makeControlName(existingNames),
    dataSchema: ControlDataSchema = {
      ...control.dataSchema,
    },
    uiSchema: UiSchemaElementTypeControl = {
      ...control.uiSchema,
      scope: `#/properties/${name}`,
      options: {
        ...control.uiSchema.options,
        id: name,
      },
    };

  return {
    name,
    uiSchema,
    dataSchema,
    dataSchemaRequired: control.dataSchemaRequired,
  };
};

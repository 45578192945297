import React from "react";
import {
  ControlProps,
  hasOption,
  optionIs,
  and,
  rankWith,
  JsonFormsRendererRegistryEntry,
  isStringControl,
} from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";

import { Control } from "../components/Control";
import { InputText } from "../components/InputText";

const MaterialTextControl = React.memo((props: ControlProps) => (
  <Control
    {...props}
    config={{ ...props.config, multi: true }}
    input={InputText}
  />
));

export const entry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(
    1,
    and(isStringControl, hasOption("multi"), optionIs("multi", true)),
  ),
  renderer: withJsonFormsControlProps(MaterialTextControl),
};

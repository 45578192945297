import { Typed } from "utils/Typed";
import { GetGuardType } from "types/src/Utils";
import { createStates } from "./types/State";
import { createActions } from "./types/Actions";
import { reducer } from "./reducer";
import { epic } from "./epic";

export function createState(p: string) {
  const states = createStates(p);
  const actions = createActions(p);

  return {
    isState: Typed.getGuard(states),
    states: states,
    isActions: Typed.getGuard(actions),
    actions: actions,
    init: states.init.create,
    reducer: reducer(p),
    epic: epic(p),
  };
}

export type Store = ReturnType<typeof createState>;
export type State = GetGuardType<Store["isState"]>;
export type Actions = GetGuardType<Store["isActions"]>;

import { useTranslation } from "i18n";
import { ListingTable } from "ui/layouts/ListingTable";
import { EntityListing } from "@Containers/Listing/EntityListing";

export const typeMap = {
  type: EntityListing.Filters.Type.select,
} satisfies EntityListing.Filters.ListingTypeMapBase;

export const stateToConfig = (
  state: {},
  { t }: Pick<ReturnType<typeof useTranslation>, "t">,
) => ({
  type: {
    type: typeMap["type"],
    options: {
      label: t("Type"),
      choices: [
        { id: "static", label: t("Static") },
        { id: "dynamic", label: t("Dynamic") },
      ],
    },
  } satisfies ListingTable.CustomFilterConfig,
});

export const converters: EntityListing.Filters.ListingConvertersBase<
  { type?: "static" | "dynamic" },
  typeof typeMap
> = {
  type: {
    toListing: (id) => ({ id }),
    toState: (v) => v?.id as "static" | "dynamic",
  },
};

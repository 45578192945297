import { ListingTable } from "ui/layouts/ListingTable";
import { useTranslation } from "i18n";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { DateRange } from "types/src/date/DateRange";
import { OrderBy } from "types/src/OrderBy";
import { ReactNode } from "react";
import { Sku } from "types/src/Sku";
import { RepositoryId } from "types/src/Repositories/Repository";
import { StockId } from "types/src/Stocks/Stock";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { useBehaviorValue } from "react-rx/behaviorValue";

const filtersConfigPredefined = {
  search: ListingTable.predefinedFilters.search,
  dateRange: ListingTable.predefinedFilters.dateRange,
} satisfies ListingTable.FiltersConfigBase["predefined"];

export function StocksListing(p: StocksListing.Props) {
  const { t } = useTranslation();

  const filtersConf = useBehaviorValue(p.filtersConfig$);
  const filtersConfig = {
    predefined: filtersConfigPredefined,
    custom: {
      repository: {
        type: ListingTable.FilterType.multiChoice,
        options: { ...filtersConf.repository, label: t("By repository") },
      },
      active: {
        type: ListingTable.FilterType.select,
        options: {
          label: t("Show only"),
          choices: [
            { id: "active", label: t("Active") },
            { id: "all", label: t("All") },
          ],
        },
      },
      quantity: {
        type: ListingTable.FilterType.numberRange,
        options: {
          label: t("Quantity"),
        },
      },
      ownQuantity: {
        type: ListingTable.FilterType.numberRange,
        options: {
          label: t("Own quantity"),
        },
      },
      incomingStock: {
        type: ListingTable.FilterType.numberRange,
        options: {
          label: t("Incoming stock"),
        },
      },
      outgoingStock: {
        type: ListingTable.FilterType.numberRange,
        options: {
          label: t("Outgoing stock"),
        },
      },
      ownIncomingStock: {
        type: ListingTable.FilterType.numberRange,
        options: {
          label: t("Own incoming stock"),
        },
      },
      ownOutgoingStock: {
        type: ListingTable.FilterType.numberRange,
        options: {
          label: t("Own outgoing stock"),
        },
      },
    },
  } satisfies ListingTable.FiltersConfigBase;

  type FiltersConfig = typeof filtersConfig;

  type Rt = FiltersConfig["custom"]["repository"];

  const columns = {
    id: {
      type: ListingTable.CellType.id,
      label: t("Id"),
      renderProps: (v) => ({ id: v.id }),
    },
    sku: {
      type: ListingTable.CellType.text,
      label: t("Sku"),
      renderProps: (v) => ({ text: v.item.sku }),
    },
    repository: {
      type: ListingTable.CellType.text,
      label: t("Repository"),
      renderProps: (v) => ({ text: v.repository.name }),
    },
    quantity: {
      type: ListingTable.CellType.number,
      label: t("Quantity"),
      renderProps: (v) => ({
        number: v.quantity,
      }),
      canReorder: true,
    },
    incoming: {
      type: ListingTable.CellType.number,
      label: t("Incoming stock"),
      renderProps: (v) => ({
        number: v.incomingStock,
      }),
      canReorder: true,
    },
    outgoing: {
      type: ListingTable.CellType.number,
      label: t("Outgoing Stock"),
      renderProps: (v) => ({
        number: v.outgoingStock,
      }),
      canReorder: true,
    },
    ownQuantity: {
      type: ListingTable.CellType.number,
      label: t("Own Quantity"),
      renderProps: (v) => ({
        number: v.ownQuantity,
      }),
      canReorder: true,
    },
    ownIncoming: {
      type: ListingTable.CellType.number,
      label: t("Own Incoming stock"),
      renderProps: (v) => ({
        number: v.ownIncomingStock,
      }),
      canReorder: true,
    },
    ownOutgoing: {
      type: ListingTable.CellType.number,
      label: t("Own Outgoing Stock"),
      renderProps: (v) => ({
        number: v.ownOutgoingStock,
      }),
      canReorder: true,
    },
    createdAt: {
      type: ListingTable.CellType.timeDate,
      label: t("Created at"),
      renderProps: (v) => ({ date: ISODate.toDate(v.createdAt) }),
      canReorder: true,
    },
  } satisfies ListingTable.ColumnsConfigBase<StocksListing.Item>;
  type ColumnsConfig = typeof columns;

  return (
    <>
      <ListingTable<StocksListing.Item, ColumnsConfig, FiltersConfig, never>
        title={t("Stock movements")}
        data$={p.data$}
        columns={columns}
        filters={filtersConfig}
        //@ts-expect-error, fix me
        criteria$={BehaviorValue.combine([p.filters$, p.orderBy$]).map(
          ([filters, orderBy]) => {
            return {
              filters: {
                predefined: {
                  search: {
                    text: filters.search,
                  },
                  dateRange: {
                    start: Fp.pipe(
                      filters.createdAt?.[0],
                      O.fromNullable,
                      O.map(ISODate.toDate),
                      O.toUndefined,
                    ),
                    end: Fp.pipe(
                      filters.createdAt?.[1],
                      O.fromNullable,
                      O.map(ISODate.toDate),
                      O.toUndefined,
                    ),
                  },
                },
                custom: filters.custom,
              },
              orderBy: O.toUndefined(orderBy),
            };
          },
        )}
        onResetFilters={p.onResetFilters}
        onCriteriaChange={(c) => {
          p.onFilterChange({
            ...(c.filters?.predefined ?? {}),
            ...(c.filters?.custom ? c.filters?.custom : {}),
          });

          Fp.pipe(
            c.orderBy,
            O.fromNullable,
            O.map((v) => p.onOrderChange(v.by)),
          );
        }}
        onPageChange={p.onPageChange}
        state$={p.state$}
        columnsVisibility$={p.columnsVisibility$}
        onColumnsVisibilityChange={p.onColumnsVisibilityChange}
      />
    </>
  );
}

export namespace StocksListing {
  export type Item = {
    id: StockId;
    createdAt: ISODate;
    quantity: number;
    item: {
      id: InventoryItemId;
      sku: Sku;
    };
    repository: {
      id: RepositoryId;
      name: string;
    };
    incomingStock: number;
    outgoingStock: number;
    ownIncomingStock: number;
    ownOutgoingStock: number;
    ownQuantity: number;
  };

  export namespace Filters {
    export const Type = ListingTable.FilterType;

    export type ConfigCustomBase = ListingTable.FiltersConfigBase["custom"];

    export type Criteria = {
      search?: string;
      createdAt?: DateRange;
      custom: {
        repository?: {
          choices: Record<RepositoryId, boolean>;
        };
        active?: {
          id: "active" | "all";
        };
        quantity?: {
          start?: number;
          end?: number;
        };
        ownQuantity?: {
          start?: number;
          end?: number;
        };
        incomingStock?: {
          start?: number;
          end?: number;
        };
        ownIncomingStock?: {
          start?: number;
          end?: number;
        };
        outgoingStock?: {
          start?: number;
          end?: number;
        };
        ownOutgoingStock?: {
          start?: number;
          end?: number;
        };
      };
    };

    export type CriteriaChange = Partial<{
      search: O.Option<{ text?: string } | undefined>;
      createdAt: O.Option<{ start?: Date; end?: Date } | undefined>;
      repository: O.Option<
        { choices?: Partial<Record<string, boolean>> } | undefined
      >;
      active: O.Option<{ id: string } | undefined>;
      quantity: O.Option<{ start?: number; end?: number } | undefined>;
      ownQuantity: O.Option<{ start?: number; end?: number } | undefined>;
      incomingStock: O.Option<{ start?: number; end?: number } | undefined>;
      outgoingStock: O.Option<{ start?: number; end?: number } | undefined>;
      ownIncomingStock: O.Option<{ start?: number; end?: number } | undefined>;
      ownOutgoingStock: O.Option<{ start?: number; end?: number } | undefined>;
    }>;
  }

  export interface Props {
    filtersConfig$: BehaviorValue<{
      repository: {
        choices: Array<{ id: RepositoryId; label: ReactNode }>;
        onSearch?: (s?: string) => void;
        state?: "loading" | "error" | "ready";
        search?: string;
      };
    }>;

    data$: BehaviorValue<ListingTable.ListData<Item>>;
    state$: BehaviorValue<"loading" | "fetching" | "ready">;

    filters$: BehaviorValue<Filters.Criteria>;
    orderBy$: BehaviorValue<
      O.Option<
        OrderBy<
          | "createdAt"
          | "updatedAt"
          | "quantity"
          | "incoming"
          | "outgoing"
          | "ownQuantity"
          | "ownIncoming"
          | "ownOutgoing"
        >
      >
    >;

    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onFilterChange: (v: Filters.CriteriaChange) => void;
    onOrderChange: (
      p:
        | "createdAt"
        | "updatedAt"
        | "quantity"
        | "incoming"
        | "outgoing"
        | "ownQuantity"
        | "ownIncoming"
        | "ownOutgoing",
    ) => void;
    onResetFilters: () => void;

    columnsVisibility$: BehaviorValue<Record<string, boolean>>;
    onColumnsVisibilityChange: (v: Record<string, boolean>) => void;
  }
}

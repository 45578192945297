import { Typed } from "utils/Typed";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { silentUnreachableError } from "utils/exceptions";
import * as Rx from "rxjs";
import { strictGuard } from "utils/strictGuard";
import { DataTypeId } from "types/src/DataType/DataType";
import { RepositoryMovementId } from "types/src/RepositoryMovements/RepositoryMovement";
import { Epic, mergeByGuard } from "../../../../../../types/RootEpic";
import { EntitiesDeps } from "../../types/EntitiesDeps";
import { RepositoryMovementsListingAll as ListingAll } from "./states/ListingAll";
import { RepositoryMovementsListing as Listing } from "./states/Listing";
import * as Create from "./states/Create";
import * as Edit from "./states/Edit";

const states = Typed.builder
  .add(
    "idle",
    (p: {
      listing: ListingAll.State | Listing.State;
      single: Create.State | Edit.State | undefined;
    }) => p,
  )
  .finish()("Ready:DataManager:RepositoryMovements");

const isState = Typed.getGuard(states);

const actions = Typed.builder
  .add("goToListingAll")
  .add("exitSingle")
  .add("goToCreate", (id: DataTypeId) => id)
  .add("goToListing", (id: DataTypeId) => id)
  .add("goToEdit", (id: RepositoryMovementId) => id)
  .finish()("Ready:DataManager:RepositoryMovements:Actions");
const isActions = Typed.getGuard(actions);

const isAction = strictGuard(
  (v: RepositoryMovements.Actions): v is RepositoryMovements.Actions => {
    return (
      isActions(v) ||
      ListingAll.instance.isAction(v) ||
      Listing.instance.isAction(v) ||
      Create.isActions(v) ||
      Edit.isActions(v)
    );
  },
);

function reducer(
  s: RepositoryMovements.State,
  a: RepositoryMovements.Actions,
): RepositoryMovements.State {
  const listing = s.payload.listing;
  const single = s.payload.single;

  if (actions.goToListingAll.is(a)) {
    if (!ListingAll.instance.isState(s.payload.listing))
      return states.idle.create({
        listing: ListingAll.instance.init(),
        single: undefined,
      });

    if (s.payload.single !== undefined)
      return states.idle.create({
        listing: s.payload.listing,
        single: undefined,
      });

    return s;
  }

  if (actions.goToListing.is(a)) {
    if (
      !Listing.instance.isState(s.payload.listing) ||
      s.payload.listing.payload.id !== a.payload
    )
      return states.idle.create({
        listing: Listing.instance.init(a.payload),
        single: undefined,
      });

    if (s.payload.single !== undefined)
      return states.idle.create({
        listing: s.payload.listing,
        single: undefined,
      });

    return s;
  }

  if (actions.goToCreate.is(a)) {
    if (
      single &&
      Create.isState(single) &&
      single.payload.dataTypeId === a.payload
    ) {
      return s;
    }

    return states.idle.create({
      listing,
      single: Create.init({ dataTypeId: a.payload }),
    });
  }

  if (actions.goToEdit.is(a)) {
    if (single && Edit.isState(single) && single.payload.id === a.payload) {
      return s;
    }

    return states.idle.create({
      listing,
      single: Edit.init({ id: a.payload }),
    });
  }

  if (actions.exitSingle.is(a)) {
    return Fp.pipe(
      O.fromNullable(single),
      O.map(() => states.idle.create({ listing, single: undefined })),
      O.getOrElse(() => s),
    );
  }

  if (ListingAll.instance.isAction(a)) {
    if (ListingAll.instance.isState(listing)) {
      return Fp.pipe(
        ListingAll.instance.reducer(listing, a),
        E.map((r) =>
          states.idle.create({
            listing: r,
            single: s.payload.single,
          }),
        ),
        E.getOrElse(() => s),
      );
    }

    return s;
  }

  if (Listing.instance.isAction(a)) {
    if (Listing.instance.isState(listing)) {
      return Fp.pipe(
        Listing.instance.reducer(listing, a),
        E.map((r) =>
          states.idle.create({
            listing: r,
            single: s.payload.single,
          }),
        ),
        E.getOrElseW((e) => {
          if (Listing.instance.exits.create.is(e))
            return states.idle.create({
              listing: listing,
              single: Create.init({ dataTypeId: listing.payload.id }),
            });

          silentUnreachableError(e);
          return s;
        }),
      );
    }

    return s;
  }

  if (Create.isActions(a)) {
    if (Create.isState(single)) {
      return Fp.pipe(
        Create.reducer(single, a),
        E.mapLeft((e) => {
          if (Create.exits.created.is(e)) {
            return states.idle.create({
              listing:
                Listing.instance.isState(s.payload.listing) &&
                s.payload.listing.payload.id === e.payload.dataTypeId
                  ? Fp.pipe(
                      Listing.instance.reducer(
                        s.payload.listing,
                        Listing.instance.actions.setPage.create("start"),
                      ),
                      E.getOrElseW(() =>
                        Listing.instance.init(e.payload.dataTypeId),
                      ),
                    )
                  : Listing.instance.init(e.payload.dataTypeId),
              single: undefined,
            });
          }

          silentUnreachableError(e);
          return s;
        }),
        E.filterOrElseW(
          (r) => r !== single,
          () => s,
        ),
        E.map((r) =>
          states.idle.create({
            listing: s.payload.listing,
            single: r,
          }),
        ),
        E.toUnion,
      );
    }

    return s;
  }

  if (Edit.isActions(a)) {
    if (Edit.isState(single)) {
      return Fp.pipe(
        Edit.reducer(single, a),
        E.mapLeft((e) => {
          if (Edit.exits.saved.is(e) || Edit.exits.removed.is(e)) {
            return states.idle.create({
              listing: reFetch(listing),
              single: undefined,
            });
          }

          silentUnreachableError(e);
          return s;
        }),
        E.filterOrElseW(
          (r) => r !== single,
          () => s,
        ),
        E.map((r) =>
          states.idle.create({
            listing: s.payload.listing,
            single: r,
          }),
        ),
        E.toUnion,
      );
    }

    return s;
  }

  silentUnreachableError(a);
  return s;
}

const epic: Epic<
  RepositoryMovements.Actions,
  RepositoryMovements.State,
  EntitiesDeps
> = (state$, ds$) => {
  const listing$ = mergeByGuard([
    [
      ListingAll.instance.isState,
      ((s$) =>
        ListingAll.instance.epic(s$, {
          pyckAdminClient$: ds$.pyckAdminClient$,
          getVisibleColumns: () =>
            ds$.getListingVisibleColumns("repositoryMovement"),
          setVisibleColumns: (v) =>
            ds$.setListingVisibleColumns("repositoryMovement", v),
        })) satisfies typeof ListingAll.instance.epic,
    ],
    [
      Listing.instance.isState,
      ((s$) =>
        Listing.instance.epic(s$, {
          pyckAdminClient$: ds$.pyckAdminClient$,
          getVisibleColumns: ds$.getListingVisibleColumns,
          setVisibleColumns: ds$.setListingVisibleColumns,
        })) satisfies typeof Listing.instance.epic,
    ],
  ])(state$.pipe(Rx.map((s) => s.payload.listing)), ds$);
  const single$ = mergeByGuard([
    [
      (v: RepositoryMovements.State["payload"]["single"]): v is undefined =>
        v === undefined,
      () => Rx.NEVER,
    ],
    [
      (
        v: RepositoryMovements.State["payload"]["single"],
      ): v is Exclude<
        RepositoryMovements.State["payload"]["single"],
        undefined
      > => v !== undefined,
      (s$) =>
        mergeByGuard([
          [Create.isState, Create.epic],
          [Edit.isState, Edit.epic],
        ])(s$, ds$),
    ],
  ])(state$.pipe(Rx.map((s) => s.payload.single)), ds$);

  return Rx.merge(listing$, single$);
};

function reFetch(
  s: RepositoryMovements.State["payload"]["listing"],
): RepositoryMovements.State["payload"]["listing"] {
  if (Listing.instance.isState(s)) {
    return Fp.pipe(
      Listing.instance.reducer(s, Listing.instance.actions.reFetch.create()),
      E.getOrElseW(() => Listing.instance.init(s.payload.id)),
    );
  }

  return Fp.pipe(
    ListingAll.instance.reducer(
      s,
      ListingAll.instance.actions.reFetch.create(),
    ),
    E.getOrElseW(() => ListingAll.instance.init()),
  );
}

function createInstance() {
  return {
    states,
    actions,
    isState,
    isAction,
    reducer,
    epic,
    init: () =>
      states.idle.create({
        listing: ListingAll.instance.init(),
        single: undefined,
      }),
    subStates: {
      listingAll: ListingAll.instance,
      listing: Listing.instance,
      create: Create,
      edit: Edit,
    },
  };
}

export namespace RepositoryMovements {
  export type Actions =
    | Typed.GetTypes<typeof actions>
    | ListingAll.Actions
    | Listing.Actions
    | Create.Actions
    | Edit.Actions;

  export type State = Typed.GetTypes<typeof states>;

  export const instance = createInstance();
}

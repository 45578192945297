import { useTranslation } from "i18n";
import { ListingTable } from "ui/layouts/ListingTable";
import { EntityListing } from "@Containers/Listing/EntityListing";
import { RepositoryId } from "types/src/Repositories/Repository";
import * as Obj from "utils/object";

export const typeMap = {
  repositories: EntityListing.Filters.Type.multiChoice,
} satisfies EntityListing.Filters.ListingTypeMapBase;

export type StateToConfigPayload = {
  repositories: {
    items: Array<{ id: RepositoryId; label: string }>;
    onSearch: (s?: string) => void;
    search: string | undefined;
    state: "loading" | "error" | "ready";
  };
};

export const stateToConfig = (
  state: StateToConfigPayload,
  { t }: Pick<ReturnType<typeof useTranslation>, "t">,
) => ({
  repositories: {
    type: typeMap["repositories"],
    options: {
      label: t("Repositories"),
      choices: state.repositories.items,
      state: state.repositories.state,
      search: state.repositories.search,
      onSearch: state.repositories.onSearch,
    },
  } satisfies ListingTable.CustomFilterConfig,
});

export const converters = {
  repositories: {
    toListing: (v) => ({
      choices: v?.reduce((acc: Record<RepositoryId, boolean>, i) => {
        acc[i] = true;
        return acc;
      }, {}),
    }),
    toState: (v) =>
      Obj.keys(Obj.filter((v) => !!v, v?.choices ?? {})) as RepositoryId[],
  },
} satisfies EntityListing.Filters.ListingConvertersBase<
  {
    repositories?: RepositoryId[];
  },
  typeof typeMap
>;

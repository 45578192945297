import { Typed } from "utils/Typed";
import {
  ItemMovement,
  ItemMovementId,
} from "types/src/ItemMovements/ItemMovement";

export const exits = Typed.builder
  .add("saved", (v: ItemMovement) => v)
  .add("removed", (v: ItemMovementId) => v)
  .finish()("Ready:DataManager:ItemMovements:Edit:Exits");

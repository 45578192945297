import { matchPath } from "react-router-dom";
import { silentUnreachableError } from "utils/exceptions";
import { DataTypeId } from "types/src/DataType/DataType";
import * as Ready from "state-manager/states/Ready";
import { CustomerId } from "types/src/Customers/Customer";
import { SupplierId } from "types/src/Supplier/Supplier";
import { RepositoryId } from "types/src/Repositories/Repository";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { RepositoryMovementId } from "types/src/RepositoryMovements/RepositoryMovement";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { InboundId } from "types/src/Inbounds/Inbound";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { ItemSetId } from "types/src/ItemSets/ItemSet";
import { CollectionId } from "types/src/Collections/Collection";

type EntityPattern<T extends string> =
  | `/${T}`
  | `/${T}/edit/:id`
  | `/${T}/:dataTypeId`
  | `/${T}/:dataTypeId/add`;

type RoutePattern =
  | "/callback"
  | "/login"
  | EntityPattern<
      | "inbounds"
      | "item-sets"
      | "customers"
      | "suppliers"
      | "repositories"
      | "inventory-items"
      | "item-movements"
      | "repository-movements"
      | "picking-orders"
    >
  | "/collections"
  | "/collections/:dataTypeId"
  | "/collections/view/:id"
  | "/data-types"
  | "/data-types/add"
  | "/data-types/:id"
  | "/warehouse-builder"
  | "/bpmn-builder"
  | "/zitadel-app"
  | "/graphql-playground"
  | "/temporal"
  | "/stocks";

export const routes = {
  "/callback": {
    type: "/callback",
    create: () => "/callback" as const,
  } as const,
  "/login": {
    type: "/login",
    create: () => "/login" as const,
  } as const,

  "/data-types": {
    type: "/data-types",
    create: () => "/data-types" as const,
  } as const,
  "/data-types/add": {
    type: "/data-types/add",
    create: () => "/data-types/add" as const,
  } as const,
  "/data-types/:id": {
    type: "/data-types/:id",
    create: (p) => `/data-types/${p.id}` as const,
  } as const,

  "/collections": {
    type: "/collections",
    create: () => "/collections" as const,
  } as const,
  "/collections/:dataTypeId": {
    type: "/collections/:dataTypeId",
    create: (p) => `/collections/${p.dataTypeId}` as const,
  } as const,
  "/collections/view/:id": {
    type: "/collections/view/:id",
    create: (p) => `/collections/view/${p.id}` as const,
  } as const,

  "/customers": {
    type: "/customers",
    create: () => "/customers" as const,
  } as const,
  "/customers/:dataTypeId": {
    type: "/customers/:dataTypeId",
    create: (p) => `/customers/${p.dataTypeId}` as const,
  } as const,
  "/customers/:dataTypeId/add": {
    type: "/customers/:dataTypeId/add",
    create: (p) => `/customers/${p.dataTypeId}/add` as const,
  } as const,
  "/customers/edit/:id": {
    type: "/customers/edit/:id",
    create: (p) => `/customers/edit/${p.id}` as const,
  } as const,

  "/inbounds": {
    type: "/inbounds",
    create: () => "/inbounds" as const,
  } as const,
  "/inbounds/:dataTypeId": {
    type: "/inbounds/:dataTypeId",
    create: (p) => `/inbounds/${p.dataTypeId}` as const,
  } as const,
  "/inbounds/:dataTypeId/add": {
    type: "/inbounds/:dataTypeId/add",
    create: (p) => `/inbounds/${p.dataTypeId}/add` as const,
  } as const,
  "/inbounds/edit/:id": {
    type: "/inbounds/edit/:id",
    create: (p) => `/inbounds/edit/${p.id}` as const,
  } as const,

  "/item-sets": {
    type: "/item-sets",
    create: () => "/item-sets" as const,
  } as const,
  "/item-sets/:dataTypeId": {
    type: "/item-sets/:dataTypeId",
    create: (p) => `/item-sets/${p.dataTypeId}` as const,
  } as const,
  "/item-sets/:dataTypeId/add": {
    type: "/item-sets/:dataTypeId/add",
    create: (p) => `/item-sets/${p.dataTypeId}/add` as const,
  } as const,
  "/item-sets/edit/:id": {
    type: "/item-sets/edit/:id",
    create: (p) => `/item-sets/edit/${p.id}` as const,
  } as const,

  "/suppliers": {
    type: "/suppliers",
    create: () => "/suppliers" as const,
  } as const,
  "/suppliers/:dataTypeId": {
    type: "/suppliers/:dataTypeId",
    create: (p) => `/suppliers/${p.dataTypeId}` as const,
  } as const,
  "/suppliers/:dataTypeId/add": {
    type: "/suppliers/:dataTypeId/add",
    create: (p) => `/suppliers/${p.dataTypeId}/add` as const,
  } as const,
  "/suppliers/edit/:id": {
    type: "/suppliers/edit/:id",
    create: (p) => `/suppliers/edit/${p.id}` as const,
  } as const,

  "/warehouse-builder": {
    type: "/warehouse-builder",
    create: () => `/warehouse-builder` as const,
  } as const,
  "/bpmn-builder": {
    type: "/bpmn-builder",
    create: () => `/bpmn-builder` as const,
  } as const,
  "/zitadel-app": {
    type: "/zitadel-app",
    create: () => `/zitadel-app` as const,
  } as const,
  "/graphql-playground": {
    type: "/graphql-playground",
    create: () => `/graphql-playground` as const,
  } as const,
  "/temporal": {
    type: "/temporal",
    create: () => `/temporal` as const,
  } as const,

  "/repositories": {
    type: "/repositories",
    create: () => "/repositories" as const,
  } as const,
  "/repositories/:dataTypeId": {
    type: "/repositories/:dataTypeId",
    create: (p) => `/repositories/${p.dataTypeId}` as const,
  } as const,
  "/repositories/:dataTypeId/add": {
    type: "/repositories/:dataTypeId/add",
    create: (p) => `/repositories/${p.dataTypeId}/add` as const,
  } as const,
  "/repositories/edit/:id": {
    type: "/repositories/edit/:id",
    create: (p) => `/repositories/edit/${p.id}` as const,
  } as const,

  "/inventory-items": {
    type: "/inventory-items",
    create: () => "/inventory-items" as const,
  } as const,
  "/inventory-items/:dataTypeId": {
    type: "/inventory-items/:dataTypeId",
    create: (p) => `/inventory-items/${p.dataTypeId}` as const,
  } as const,
  "/inventory-items/:dataTypeId/add": {
    type: "/inventory-items/:dataTypeId/add",
    create: (p) => `/inventory-items/${p.dataTypeId}/add` as const,
  } as const,
  "/inventory-items/edit/:id": {
    type: "/inventory-items/edit/:id",
    create: (p) => `/inventory-items/edit/${p.id}` as const,
  } as const,

  "/item-movements": {
    type: "/item-movements",
    create: () => "/item-movements" as const,
  } as const,
  "/item-movements/:dataTypeId": {
    type: "/item-movements/:dataTypeId",
    create: (p) => `/item-movements/${p.dataTypeId}` as const,
  } as const,
  "/item-movements/:dataTypeId/add": {
    type: "/item-movements/:dataTypeId/add",
    create: (p) => `/item-movements/${p.dataTypeId}/add` as const,
  } as const,
  "/item-movements/edit/:id": {
    type: "/item-movements/edit/:id",
    create: (p) => `/item-movements/edit/${p.id}` as const,
  } as const,

  "/repository-movements": {
    type: "/repository-movements",
    create: () => "/repository-movements" as const,
  } as const,
  "/repository-movements/:dataTypeId": {
    type: "/repository-movements/:dataTypeId",
    create: (p) => `/repository-movements/${p.dataTypeId}` as const,
  } as const,
  "/repository-movements/:dataTypeId/add": {
    type: "/repository-movements/:dataTypeId/add",
    create: (p) => `/repository-movements/${p.dataTypeId}/add` as const,
  } as const,
  "/repository-movements/edit/:id": {
    type: "/repository-movements/edit/:id",
    create: (p) => `/repository-movements/edit/${p.id}` as const,
  } as const,

  "/picking-orders": {
    type: "/picking-orders",
    create: () => "/picking-orders" as const,
  } as const,
  "/picking-orders/:dataTypeId": {
    type: "/picking-orders/:dataTypeId",
    create: (p) => `/picking-orders/${p.dataTypeId}` as const,
  } as const,
  "/picking-orders/:dataTypeId/add": {
    type: "/picking-orders/:dataTypeId/add",
    create: (p) => `/picking-orders/${p.dataTypeId}/add` as const,
  } as const,
  "/picking-orders/edit/:id": {
    type: "/picking-orders/edit/:id",
    create: (p) => `/picking-orders/edit/${p.id}` as const,
  } as const,

  "/stocks": {
    type: "/stocks",
    create: () => "/stocks" as const,
  } as const,
} satisfies {
  [K in RoutePattern]: {
    create: (params: ExtractParams<K>) => string;
    type: K;
  };
};

type ExtractParam<Path, NextPart> = Path extends `:${infer Param}`
  ? { [k in Param]: string } & NextPart
  : NextPart;

type ExtractParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? ExtractParam<Segment, ExtractParams<Rest>>
  : ExtractParam<Path, {}>;

type R = typeof routes;

export type RouteType = R[keyof R];
export type Route = ReturnType<RouteType["create"]>;

function isExactRoute<P extends RoutePattern>(p: P, path: string): path is P {
  return !!matchPath(p, path);
}
export function getRouteParams<T extends RoutePattern>(
  pattern: T,
  path: ReturnType<RouteType["create"]>,
): ExtractParams<T>;
export function getRouteParams<T extends RoutePattern>(
  pattern: T,
  path: string,
): ExtractParams<T> | null;
export function getRouteParams<T extends RoutePattern>(
  pattern: T,
  path: string,
): ExtractParams<T> | null {
  return matchPath(pattern, path)?.params as ExtractParams<T> | null;
}

export function getRedirectAction(path: string): Ready.Goto | undefined {
  const p = path as RoutePattern;

  if (isExactRoute("/callback", p)) {
    return undefined;
  }

  if (isExactRoute("/login", p)) {
    return undefined;
  }

  if (isExactRoute("/collections", p)) {
    return Ready.goToCollections();
  }
  if (isExactRoute("/collections/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/collections/:dataTypeId", p);

    return Fp.pipe(
      O.of(dataTypeId),
      O.chain(Fp.flow(DataTypeId.fromString, O.fromNullable)),
      O.map(Ready.goToCollectionsByDataType),
      O.getOrElseW(Ready.goToCollections),
    );
  }
  if (isExactRoute("/collections/view/:id", p)) {
    const { id } = getRouteParams("/collections/view/:id", p);

    return Fp.pipe(
      O.of(id),
      O.chain(Fp.flow(CollectionId.fromString, O.fromNullable)),
      O.map(Ready.goToCollectionsView),
      O.getOrElseW(Ready.goToCollections),
    );
  }

  if (isExactRoute("/customers", p)) {
    return Ready.goToCustomers();
  }
  if (isExactRoute("/customers/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/customers/:dataTypeId", p);

    return Ready.goToCustomersByDataType(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/customers/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/customers/:dataTypeId/add", p);

    return Ready.goToCustomersCreate(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/customers/edit/:id", p)) {
    const { id } = getRouteParams("/customers/edit/:id", p);

    return Ready.goToCustomersEdit(id as CustomerId);
  }

  if (isExactRoute("/inbounds", p)) {
    return Ready.goToInbounds();
  }
  if (isExactRoute("/inbounds/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/inbounds/:dataTypeId", p);

    return Ready.goToInboundsByDataType(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/inbounds/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/inbounds/:dataTypeId/add", p);

    return Fp.pipe(
      O.of(dataTypeId),
      O.chain(Fp.flow(DataTypeId.fromString, O.fromNullable)),
      O.map(Ready.goToInboundsByDataType),
      O.getOrElseW(Ready.goToInbounds),
    );
  }
  if (isExactRoute("/inbounds/edit/:id", p)) {
    const { id } = getRouteParams("/inbounds/edit/:id", p);

    return Fp.pipe(
      O.of(id),
      O.chain(Fp.flow(InboundId.fromString, O.fromNullable)),
      O.map(Ready.goToInboundsEdit),
      O.getOrElseW(Ready.goToInbounds),
    );
  }

  if (isExactRoute("/item-sets", p)) {
    return Ready.goToItemSets();
  }
  if (isExactRoute("/item-sets/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/item-sets/:dataTypeId", p);

    return Ready.goToItemSetsByDataType(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/item-sets/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/item-sets/:dataTypeId/add", p);

    return Ready.goToItemSetsCreate(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/item-sets/edit/:id", p)) {
    const { id } = getRouteParams("/item-sets/edit/:id", p);

    return Fp.pipe(
      id,
      O.fromNullable,
      O.chain(Fp.flow(ItemSetId.fromString, O.fromNullable)),
      O.map(Ready.goToItemSetsEdit),
      O.getOrElseW(Ready.goToItemSets),
    );
  }

  if (isExactRoute("/suppliers", p)) {
    return Ready.goToSuppliers();
  }

  if (isExactRoute("/suppliers/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/suppliers/:dataTypeId", p);

    return Ready.goToSuppliersByDataType(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/suppliers/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/suppliers/:dataTypeId/add", p);

    return Ready.goToSuppliersCreate(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/suppliers/edit/:id", p)) {
    const { id } = getRouteParams("/suppliers/edit/:id", p);

    return Ready.goToSuppliersEdit(id as SupplierId);
  }

  if (isExactRoute("/warehouse-builder", p)) {
    return Ready.goToWarehouseBuilder();
  }
  if (isExactRoute("/zitadel-app", p)) {
    return Ready.goToZitadelApp();
  }
  if (isExactRoute("/bpmn-builder", p)) {
    return Ready.goToBPMNBuilder();
  }

  if (isExactRoute("/repositories", p)) {
    return Ready.goToRepositories();
  }

  if (isExactRoute("/repositories/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/repositories/:dataTypeId", p);

    return Ready.goToRepositoriesByDataType(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/repositories/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/repositories/:dataTypeId/add", p);

    return Ready.goToRepositoriesCreate(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/repositories/edit/:id", p)) {
    const { id } = getRouteParams("/repositories/edit/:id", p);

    return Ready.goToRepositoriesEdit(id as RepositoryId);
  }

  if (isExactRoute("/inventory-items", p)) {
    return Ready.goToInventoryItems();
  }

  if (isExactRoute("/inventory-items/edit/:id", p)) {
    const { id } = getRouteParams("/inventory-items/edit/:id", p);

    return Ready.goToInventoryItemsEdit(id as InventoryItemId);
  }
  if (isExactRoute("/inventory-items/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/inventory-items/:dataTypeId", p);

    return Ready.goToInventoryItemsByDataType(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/inventory-items/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams(
      "/inventory-items/:dataTypeId/add",
      p,
    );

    return Ready.goToInventoryItemsCreate(dataTypeId as DataTypeId);
  }

  if (isExactRoute("/item-movements", p)) {
    return Ready.goToItemMovements();
  }

  if (isExactRoute("/item-movements/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/item-movements/:dataTypeId/add", p);
    return Ready.goToItemMovementsCreate(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/item-movements/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/item-movements/:dataTypeId", p);

    return Ready.goToItemMovementsByDataType(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/item-movements/edit/:id", p)) {
    const { id } = getRouteParams("/item-movements/edit/:id", p);

    return Ready.goToItemMovementsEdit(id as ItemMovementId);
  }

  if (isExactRoute("/repository-movements", p)) {
    return Ready.goToRepositoryMovements();
  }

  if (isExactRoute("/repository-movements/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams(
      "/repository-movements/:dataTypeId/add",
      p,
    );
    return Ready.goToRepositoryMovementsCreate(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/repository-movements/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams(
      "/repository-movements/:dataTypeId",
      p,
    );

    return Ready.goToRepositoryMovementsByDataType(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/repository-movements/edit/:id", p)) {
    const { id } = getRouteParams("/repository-movements/edit/:id", p);
    return Ready.goToRepositoryMovementsEdit(id as RepositoryMovementId);
  }

  if (isExactRoute("/picking-orders", p)) {
    return Ready.goToPickingOrders();
  }

  if (isExactRoute("/picking-orders/:dataTypeId/add", p)) {
    const { dataTypeId } = getRouteParams("/picking-orders/:dataTypeId/add", p);
    return Ready.goToPickingOrdersCreate(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/picking-orders/:dataTypeId", p)) {
    const { dataTypeId } = getRouteParams("/picking-orders/:dataTypeId", p);
    return Ready.goToPickingOrdersByDataType(dataTypeId as DataTypeId);
  }
  if (isExactRoute("/picking-orders/edit/:id", p)) {
    const { id } = getRouteParams("/picking-orders/edit/:id", p);
    return Ready.goToPickingOrdersEdit(id as PickingOrderId);
  }

  if (isExactRoute("/stocks", p)) {
    return Ready.goToStocks();
  }

  if (isExactRoute("/data-types", p)) {
    return Ready.goToDatatypes();
  }

  if (isExactRoute("/data-types/add", p)) {
    return Ready.goToDataTypesCreate();
  }

  if (isExactRoute("/data-types/:id", p)) {
    const { id } = getRouteParams("/data-types/:id", p);
    return Ready.goToDataTypesEdit(id as DataTypeId);
  }

  if (isExactRoute("/graphql-playground", p)) {
    return Ready.goToGraphQlPlayground();
  }

  if (isExactRoute("/temporal", p)) {
    return Ready.goToTemporal();
  }

  silentUnreachableError(p);
  return undefined;
}

import { Customer } from "types/src/Customers/Customer";
import { InboundId } from "types/src/Inbounds/Inbound";
import {
  InboundItem,
  InboundItemCreate,
  InboundItemUpdate,
} from "types/src/Inbounds/InboundItem";
import { Sku } from "types/src/Sku";
import {
  CreateReceivingInboundItemInput,
  InboundItemFragmentFragment,
  UpdateReceivingInboundItemInput,
} from "../generated/graphql";

export function inboundItemFragmentToInboundItem(
  fragment: InboundItemFragmentFragment,
): InboundItem {
  return {
    id: fragment.id as InboundItem["id"],
    fields: (fragment.data ?? {}) as InboundItem["fields"],
    createdAt: fragment.createdAt,
    updatedAt: fragment.updatedAt ?? undefined,
    dataTypeId: fragment.dataTypeID as Customer["dataTypeId"],
    inboundId: fragment.inboundID as InboundId,
    sku: fragment.sku as Sku,
    quantity: fragment.quantity,
  };
}

export function inboundItemCreateToApiInboundItemCreate(
  v: InboundItemCreate,
): CreateReceivingInboundItemInput {
  return {
    data: v.fields,
    inboundID: v.inboundId,
    dataTypeID: v.dataTypeId,
    sku: v.sku,
    quantity: v.quantity,
  };
}
export function inboundItemUpdateToApiInboundItemUpdate(
  v: InboundItemUpdate,
): UpdateReceivingInboundItemInput {
  return {
    data: v.fields ?? undefined,
    inboundID: v.inboundId,
    dataTypeID: v.dataTypeId,
    sku: v.sku,
    quantity: v.quantity,
    clearData: v.fields === null,
    clearDataTypeID: v.dataTypeId === null,
  };
}

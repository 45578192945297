import { UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { VerticalLayout as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor/elementTypes/VerticalLayout";
import { ElementTypes } from "../../../types/element";
import { ListLayout } from "./Common";

const elementType = UiSchemaElementType.verticalLayout;
type Component = ElementTypes[typeof elementType]["RenderInEditor"];

export const VerticalLayout: Component = ({ uiSchemaPathEncoded }) => (
  <ListLayout<typeof elementType, UI.Props>
    {...{ elementType, uiSchemaPathEncoded, UI, uiProps }}
  />
);

const uiProps = {};

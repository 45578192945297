import {
  catchError,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { Client, DsError, unknownError } from "ds";
import { deleteSuppliers, getSupplier, updateSupplier } from "ds/Suppliers";
import { getDataType } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { Epic } from "../../../../../../../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { schemaFieldsState } from "./utils";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) => {
  const fieldsSchema$ = schemaFieldsState.epic(
    state$.pipe(
      filter(State.isLoaded),
      map((s) => s.payload.schema),
    ),
    pyckAdminClient$,
  );

  const loading$ = state$.pipe(
    filter(State.isLoading),
    map((s) => s.payload.id),
    withLatestFrom(pyckAdminClient$),
    switchMap(([id, client]) =>
      from(getSupplier(client, id)).pipe(
        switchMap(
          flow(
            E.mapLeft(Actions.loadFail),
            E.chain(E.fromNullable(Actions.loadFail())),
            E.map((item) => {
              return from(getDataType(client, item.dataTypeId)).pipe(
                map(
                  flow(
                    E.map((dt) =>
                      Actions.loadSuccess({
                        item: item,
                        schema: dt.schema,
                        ui: dt.frontendSchema ?? {
                          type: UiSchemaElementType.verticalLayout,
                          elements: [],
                        },
                      }),
                    ),
                    E.getOrElse<DsError, Actions.Actions>(() =>
                      Actions.loadFail(),
                    ),
                  ),
                ),
              );
            }),
            E.getOrElse<Actions.Actions, Observable<Actions.Actions>>(of),
          ),
        ),
      ),
    ),
  );

  const update$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isSaving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(
        updateSupplier(client, {
          id: s.id,
          dataTypeId: s.dataTypeId,
          fields: s.schema.payload.values,
        }),
      ).pipe(
        map(
          flow(
            E.map(Actions.saveSuccess),
            E.getOrElse<DsError, Actions.Actions>(Actions.saveError),
          ),
        ),
        catchError(() => of(Actions.saveError())),
      ),
    ),
  );
  const remove$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isRemoving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(deleteSuppliers(client, [s.id])).pipe(
        map(
          flow(E.map(Actions.removeSuccess), E.getOrElseW(Actions.removeFail)),
        ),
        catchError(() => of(Actions.removeFail(unknownError()))),
      ),
    ),
  );
  return merge(loading$, update$, fieldsSchema$, remove$);
};

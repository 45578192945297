import { useCallback, useContext } from "react";
import { ControlPathCacheContext } from "../contexts/ControlPathCache";
import { UiContext } from "../contexts/Ui";

// call only when updating element positions (add, move, delete), not values
export const useClearPositionCaches = <Params extends unknown[]>(
  updateSchema: (...params: Params) => void,
): typeof updateSchema => {
  const controlPathCache = useContext(ControlPathCacheContext);
  // maybe later will be other caches

  const { onUiChange } = useContext(UiContext);

  return useCallback(
    (...params: Params) => {
      controlPathCache.current = {};

      updateSchema(...params);

      onUiChange({}); // update UI related to selected ids (enable/disable action buttons)
    },
    [controlPathCache, onUiChange, updateSchema],
  );
};

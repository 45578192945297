import { InputAdornment } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { langToDateFnsLocale } from "@layouts/ListingTable/constants/date";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { Clock } from "icons";
import React, { useState } from "react";
import { useTranslation } from "i18n";

export namespace TimePickerInput {
  export interface Props {
    className?: string;
    label?: string;
    value: Date | undefined;
    onChange?: (value: Date | undefined) => void;
    disabled?: boolean;
    placeholder?: string;
    error?: boolean;
  }
}

export function TimePickerInput(p: TimePickerInput.Props) {
  const { language } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={langToDateFnsLocale[language]}
    >
      <TimePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={p.value}
        className={p.className}
        onAccept={(v) => p.onChange?.(v ?? undefined)}
        format="HH:mm"
        views={["hours", "minutes"]}
        disabled={p.disabled}
        slotProps={{
          actionBar: ({ wrapperVariant }) => ({
            actions:
              wrapperVariant === "desktop" ? [] : ["clear", "cancel", "accept"],
          }),
          textField: {
            error: !!p.error,
            inputProps: {
              type: "text",
              placeholder: p.placeholder,
            },
            InputProps: {
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={(p) => ({ width: p.spacing(5), cursor: "pointer" })}
                  onClick={() => setOpen((v) => !v)}
                >
                  <Clock />
                </InputAdornment>
              ),
              endAdornment: null,
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}

import { UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { HorizontalLayout as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor/elementTypes/HorizontalLayout";
import { useCallback, useContext, useMemo } from "react";
import { useBehaviorValueRef } from "react-rx/behaviorValue";
import * as Fp from "fp-ts/function";
import { uiElementPathDecode } from "../../../utils/uiSchema/elementPath";
import { ElementTypes } from "../../../types/element";
import { ValueContext } from "../../../contexts/Value";
import { findElementPath } from "../../../utils/uiSchema/findElementPath";
import { addControl } from "../../../utils/value/addControl";
import { fixValue } from "../../../utils/value/fix";
import { useClearPositionCaches } from "../../../hooks/useClearPositionCaches";
import { uiSchemaUngroup } from "../../../utils/uiSchema/ungroup";
import { ListLayout } from "./Common";

const elementType = UiSchemaElementType.horizontalLayout;
type Component = ElementTypes[typeof elementType]["RenderInEditor"];

export const HorizontalLayout: Component = ({ uiSchemaPathEncoded }) => {
  const id = useMemo(
    () => uiElementPathDecode(uiSchemaPathEncoded).id,
    [uiSchemaPathEncoded],
  );

  const { value$, onValueChange: _onValueChange } = useContext(ValueContext);
  const onValueChange = useClearPositionCaches(_onValueChange);
  const valueRef = useBehaviorValueRef(value$);
  const findPath = useCallback(
    () =>
      /**
       * I know path is contained in `uiSchemaPathEncoded`
       * but there is a plan to try to migrate to `id` prop only
       */
      findElementPath(
        { id, type: UiSchemaElementType.horizontalLayout },
        valueRef.current.uiSchema,
      ),
    [id, valueRef],
  );
  const uiProps = useMemo(
    () => ({
      onAddSubfield: () => {
        const path = findPath();
        if (!path) {
          return; // maybe log
        }

        onValueChange(
          Fp.pipe(
            addControl(valueRef.current, { uiParentPath: path, at: "end" }),
            fixValue,
          ),
        );
      },
      onUngroup: () => {
        const path = findPath();
        if (!path) {
          return; // maybe log
        }

        onValueChange({
          ...valueRef.current,
          uiSchema: uiSchemaUngroup(path, valueRef.current.uiSchema),
        });
      },
    }),
    [findPath, onValueChange, valueRef],
  );

  return (
    <ListLayout<typeof elementType, UI.Props>
      {...{
        elementType,
        uiSchemaPathEncoded,
        uiProps,
        UI,
      }}
    />
  );
};

import React, { ReactNode } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { BulkSelectProps } from "@layouts/ListingTable/types/BulkSelectProps";
import { Box } from "@mui/material";
import { Suggestions } from "@layouts/ListingTable/Suggestions";
import { Sxs } from "../../types/styles";
import {
  CellType as CellType_,
  ColumnsConfigBase as ColumnsConfigBase_,
  ColumnsVisibility as ColumnsVisibility_,
} from "./types/columns";
import {
  DataEntryBase,
  EntryAction as EntryAction_,
  ListData as ListData_,
  PaginationDestination,
} from "./types/data";
import {
  CustomFilterConfig as CustomFilterConfig_,
  FiltersConfigBase as FiltersConfigBase_,
  FilterType as FilterType_,
} from "./types/filters";
import {
  Criteria as Criteria_,
  CriteriaChange as CriteriaChange_,
  FilterTypeCriteriaMap as FilterTypeCriteriaMap_,
  FilterTypeCriteriaChangeMap as FilterTypeCriteriaChangeMap_,
  SortableColumns as SortableColumns_,
} from "./types/criteria";
import { List } from "./List";
import { predefinedFilters as predefinedFilters_ } from "./constants/filters";
import { HeaderFilters } from "./HeaderFilters";
import { mergeCriteriaChanges as mergeCriteriaChanges_ } from "./utils/criteria";

export function ListingTable<
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase_<DataEntry>,
  FiltersConfig extends FiltersConfigBase_,
  Actions extends string,
>({
  title,
  columns,
  columnsVisibility$,
  onColumnsVisibilityChange,
  state$,
  data$,
  entryActions,
  filters,
  criteria$,
  onCriteriaChange,
  onPageChange,
  headerButtons,
  bulkSelect,
  onResetFilters,
}: ListingTable.Props<DataEntry, ColumnsConfig, FiltersConfig, Actions>) {
  return (
    <Box sx={sxs.wrapper}>
      <HeaderFilters<DataEntry, ColumnsConfig, FiltersConfig>
        {...{
          filters,
          criteria$,
          onCriteriaChange,
          customButtons: headerButtons,
        }}
      />
      <List<DataEntry, ColumnsConfig, FiltersConfig, Actions>
        state$={state$}
        onResetFilters={onResetFilters}
        {...{
          title,
          columns,
          columnsVisibility$,
          onColumnsVisibilityChange,
          filters,
          criteria$,
          onCriteriaChange,
          data$,
          entryActions,
          onPageChange,
          bulkSelect,
        }}
      />
    </Box>
  );
}

export namespace ListingTable {
  export const CellType = CellType_;

  export type ColumnsConfigBase<DataEntry extends DataEntryBase> =
    ColumnsConfigBase_<DataEntry>;

  export type ColumnsVisibility<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  > = ColumnsVisibility_<DataEntry, ColumnsConfig>;

  export const FilterType = FilterType_;

  export type FiltersConfigBase<CustomKeys extends string = string> =
    FiltersConfigBase_<CustomKeys>;

  export type CustomFilterConfig = CustomFilterConfig_;

  export type Criteria<
    Columns,
    FiltersConfig extends FiltersConfigBase,
  > = Criteria_<Columns, FiltersConfig>;

  export type CriteriaChange<
    Columns,
    FiltersConfig extends FiltersConfigBase,
  > = CriteriaChange_<Columns, FiltersConfig>;

  export type FilterTypeCriteriaMap = FilterTypeCriteriaMap_;
  export type FilterTypeCriteriaChangeMap = FilterTypeCriteriaChangeMap_;

  export const predefinedFilters = predefinedFilters_;

  export type ListData<DataEntry extends DataEntryBase> = ListData_<DataEntry>;

  export type EntryAction = EntryAction_;

  export const mergeCriteriaChanges = mergeCriteriaChanges_;

  export type SuggestionsData<ID extends PropertyKey = string> =
    Suggestions.Data<ID>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export type SortableColumns<T extends ColumnsConfigBase<any>> =
    SortableColumns_<T>;

  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
    Actions extends string,
  > = {
    columns: ColumnsConfig;
    filters: FiltersConfig;

    title: string;
    headerButtons?: ReactNode;

    columnsVisibility$: BehaviorValue<
      ColumnsVisibility<DataEntry, ColumnsConfig>
    >;
    onColumnsVisibilityChange? /*renders Visible Columns picker*/ : (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;

    criteria$: BehaviorValue<
      Criteria<SortableColumns_<ColumnsConfig>, FiltersConfig>
    >;
    onCriteriaChange: (
      criteria: CriteriaChange<SortableColumns_<ColumnsConfig>, FiltersConfig>,
    ) => void;
    onResetFilters: () => void;

    state$: BehaviorValue<"loading" | "fetching" | "ready">;
    data$: BehaviorValue<ListData<DataEntry>>;
    entryActions?: (entry: DataEntry) => EntryAction[];

    onPageChange: (page: PaginationDestination) => void;

    bulkSelect?: BulkSelectProps<DataEntry["id"], Actions>;
  };
}

const sxs = {
  wrapper: (theme) => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,

    "&:not(:first-of-type)": {
      marginTop: 8,
    },
  }),
} satisfies Sxs;

import { silentUnreachableError } from "utils/exceptions";
import * as FormValue from "types/src/FormValue";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import { isNoEmptyString, NoEmptyString } from "types/src/NoEmptyString";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { toOptionGuard } from "utils/Option";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { schemaFieldsState } from "./utils";
import * as Exits from "./types/Exits";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          ...s.payload,
          schema: Fp.pipe(
            s.payload.schema,
            O.map((s) =>
              Fp.pipe(
                schemaFieldsState.reducer(s, a),
                E.getOrElse((e) => {
                  silentUnreachableError(e);
                  return s;
                }),
              ),
            ),
          ),
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:ItemMovements:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(
          State.loadError({
            id: s.payload.id,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:ItemMovements:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        return E.right(
          State.ready({
            submitted: false,
            dataTypeId: a.payload.dataTypeId,
            item: FormValue.valid({
              items: a.payload.inventoryItems,
              search: O.none,
              selected: a.payload.itemMovement.item,
            }),
            from: FormValue.valid({
              items: a.payload.repositories,
              search: O.none,
              selected: a.payload.itemMovement.from,
            }),
            to: FormValue.valid({
              items: a.payload.repositories,
              search: O.none,
              selected: a.payload.itemMovement.to,
            }),
            quantity: FormValue.valid(a.payload.itemMovement.quantity),
            handler: FormValue.valid(a.payload.itemMovement.handler),
            schema: Fp.pipe(
              a.payload.schema,
              O.fromNullable,
              O.map((schema) => {
                return schemaFieldsState.states.init.create({
                  schema: schema,
                  uiSchema: a.payload.ui,
                  values: a.payload.itemMovement.fields,
                });
              }),
            ),
            isExecuted: a.payload.itemMovement.executed,
            id: s.payload.id,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:ItemMovements:Edit:Submit": {
      if (State.isEditable(s)) {
        const schema = Fp.pipe(
          s.payload.schema,
          O.map((s) =>
            Fp.pipe(
              schemaFieldsState.reducer(
                s,
                schemaFieldsState.actions.submit.create(),
              ),
              E.getOrElse((e) => {
                silentUnreachableError(e);
                return s;
              }),
            ),
          ),
        );

        if (
          FormValue.isValid(s.payload.item) &&
          FormValue.isValid(s.payload.from) &&
          FormValue.isValid(s.payload.to) &&
          FormValue.isValid(s.payload.quantity) &&
          FormValue.isValid(s.payload.handler) &&
          toOptionGuard(schemaFieldsState.states.valid.is)(schema)
        ) {
          return E.right(
            State.saving({
              dataTypeId: s.payload.dataTypeId,
              schema: schema,
              item: s.payload.item,
              from: s.payload.from,
              to: s.payload.to,
              quantity: s.payload.quantity,
              handler: s.payload.handler,
              isExecuted: s.payload.isExecuted,
              id: s.payload.id,
              submitted: true,
            }),
          );
        } else {
          return E.right(
            State.ready({
              id: s.payload.id,
              dataTypeId: s.payload.dataTypeId,
              item: FormValue.isValid(s.payload.item)
                ? s.payload.item
                : FormValue.invalid("required", s.payload.item.value),
              from: FormValue.isValid(s.payload.from)
                ? s.payload.from
                : FormValue.invalid("required", s.payload.from.value),
              to: FormValue.isValid(s.payload.to)
                ? s.payload.to
                : FormValue.invalid("required", s.payload.to.value),
              quantity: FormValue.isValid(s.payload.quantity)
                ? s.payload.quantity
                : FormValue.invalid("required", s.payload.quantity.value),
              handler: FormValue.isValid(s.payload.handler)
                ? s.payload.handler
                : FormValue.invalid("required", s.payload.handler.value),
              schema: schema,
              isExecuted: s.payload.isExecuted,
              submitted: true,
            }),
          );
        }
      }

      return E.right(s);
    }
    case "Ready:DataManager:ItemMovements:Edit:SaveError": {
      if (State.isSaving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:ItemMovements:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:ItemMovements:Edit:InventoryItemsSearchResult": {
      return Fp.pipe(
        s,
        O.some,
        O.filter(State.isEditable),
        O.map((s) =>
          Fp.pipe(
            s.payload.item,
            O.some,
            O.filter(FormValue.isVerifying),
            O.map(
              (v) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    item: v.value.selected
                      ? FormValue.valid({
                          search: v.value.search,
                          items: a.payload,
                          selected: v.value.selected,
                        })
                      : FormValue.initial({
                          search: v.value.search,
                          items: a.payload,
                          selected: undefined,
                        }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse<State.State>(() => s),
          ),
        ),
        O.getOrElse<State.State>(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:RepositoriesFromSearchResult": {
      return Fp.pipe(
        s,
        O.some,
        O.filter(State.isEditable),
        O.map((s) =>
          Fp.pipe(
            s.payload.from,
            O.some,
            O.filter(FormValue.isVerifying),
            O.map(
              (v) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    from: FormValue.initial({
                      ...v.value,
                      items: a.payload,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse<State.State>(() => s),
          ),
        ),
        O.getOrElse<State.State>(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:RepositoriesToSearchResult": {
      return Fp.pipe(
        s,
        O.some,
        O.filter(State.isEditable),
        O.map((s) =>
          Fp.pipe(
            s.payload.to,
            O.some,
            O.filter(FormValue.isVerifying),
            O.map(
              (v) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    to: FormValue.initial({
                      ...v.value,
                      items: a.payload,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse<State.State>(() => s),
          ),
        ),
        O.getOrElse<State.State>(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SetItem": {
      return Fp.pipe(
        s,
        O.some,
        O.filter(State.isEditable),
        O.map((s) => {
          return Fp.pipe(
            a.payload,
            O.some,
            O.chain((id) =>
              O.fromNullable(
                s.payload.item.value.items.find((i) => i.id === id),
              ),
            ),
            O.map(
              (item) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    item: FormValue.valid({
                      search: s.payload.item.value.search,
                      items: s.payload.item.value.items,
                      selected: item,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse(() => s),
          );
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SetFrom": {
      return Fp.pipe(
        s,
        O.some,
        O.filter(State.isEditable),
        O.map((s) => {
          return Fp.pipe(
            a.payload,
            O.some,
            O.chain((id) =>
              O.fromNullable(
                s.payload.from.value.items.find((i) => i.id === id),
              ),
            ),
            O.filter((v) => {
              const to = s.payload.to;

              return !FormValue.isValid(to) || to.value.selected.id !== v.id;
            }),
            O.map(
              (item) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    from: FormValue.valid({
                      search: s.payload.item.value.search,
                      items: s.payload.from.value.items,
                      selected: item,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse(() => s),
          );
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SetTo": {
      return Fp.pipe(
        s,
        O.some,
        O.filter(State.isEditable),

        O.map((s) => {
          return Fp.pipe(
            a.payload,
            O.some,
            O.chain((id) =>
              O.fromNullable(s.payload.to.value.items.find((i) => i.id === id)),
            ),
            O.filter((v) => {
              const from = s.payload.from;

              return (
                !FormValue.isValid(from) || from.value.selected.id !== v.id
              );
            }),
            O.map(
              (item) =>
                ({
                  ...s,
                  payload: {
                    ...s.payload,
                    to: FormValue.valid({
                      search: s.payload.item.value.search,
                      items: s.payload.to.value.items,
                      selected: item,
                    }),
                  },
                }) satisfies typeof s,
            ),
            O.getOrElse(() => s),
          );
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SearchItem": {
      return Fp.pipe(
        O.some(s),
        O.filter(State.isEditable),
        O.filter(
          (s) =>
            O.toUndefined(s.payload.item.value.search) !==
            (a.payload || undefined),
        ),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              item: FormValue.verifying({
                ...s.payload.item.value,
                search: O.fromPredicate(isNoEmptyString)(a.payload),
              }),
            },
          } satisfies typeof s;
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SearchFrom": {
      return Fp.pipe(
        O.some(s),
        O.filter(State.isEditable),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              from: FormValue.verifying(s.payload.from.value),
            },
          } satisfies typeof s;
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SearchTo": {
      return Fp.pipe(
        O.some(s),
        O.filter(State.isEditable),
        O.map((s) => {
          return {
            ...s,
            payload: {
              ...s.payload,
              to: FormValue.verifying(s.payload.to.value),
            },
          } satisfies typeof s;
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SetQuantity": {
      return Fp.pipe(
        O.some(s),
        O.filter(State.isEditable),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                quantity: Fp.pipe(
                  a.payload,
                  O.map(FormValue.valid),
                  O.getOrElse<
                    FormValue.SubmittedValue<
                      FormValue.Value<"required", number, number | undefined>
                    >
                  >(() => FormValue.invalid("required" as const, undefined)),
                ),
              },
            }) satisfies typeof s,
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:SetHandler": {
      return Fp.pipe(
        O.some(s),
        O.filter(State.isEditable),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                handler: Fp.pipe(
                  a.payload,
                  O.fromPredicate(isNoEmptyString),
                  O.map(FormValue.valid),
                  O.getOrElse<
                    FormValue.SubmittedValue<
                      FormValue.Value<"required", NoEmptyString, string>
                    >
                  >(() => FormValue.invalid("required", a.payload)),
                ),
              },
            }) satisfies typeof s,
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }
    case "Ready:DataManager:ItemMovements:Edit:Remove": {
      if (State.isReady(s) || State.isSaving(s)) {
        return E.right(State.removeConfirmation(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:ItemMovements:Edit:RemoveDecline": {
      if (State.isRemoveConfirmation(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:ItemMovements:Edit:RemoveConfirm": {
      if (State.isRemoveConfirmation(s)) {
        return E.right(State.removing(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:ItemMovements:Edit:RemoveFail": {
      if (State.isRemoving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:ItemMovements:Edit:RemoveSuccess": {
      if (State.isRemoving(s)) {
        return E.left(Exits.exits.removed.create(s.payload.id));
      }

      return E.right(s);
    }

    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}

import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { sxsCommon } from "@layouts/ListingTable/filters/custom/common/sxs";
import { styleCustomFilterInputWidth } from "@layouts/ListingTable/utils/styles";
import { useTranslation } from "i18n";

export namespace Search {
  export interface Props {
    value: string;
    onChange: (value: string) => void;
  }
}

export const Search = ({ value, onChange }: Search.Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={sxs.wrapper}>
      <OutlinedInput
        sx={sxs.input}
        placeholder={t("Type to search")}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </Box>
  );
};

const sxs = {
  wrapper: (theme) => ({
    position: "relative",
    width: styleCustomFilterInputWidth(theme),
    margin: (theme) => theme.spacing(1.5),
  }),
  input: (theme) => ({
    ...sxsCommon.input(theme),
    ...sxsCommon.inputWithRemove(theme),
    height: "30px",
    px: theme.spacing(3),
    py: theme.spacing(0),
  }),
  removeButton: (theme) => ({
    ...sxsCommon.inputRemoveButton(theme),
  }),
} satisfies Record<string, SxProps<Theme>>;

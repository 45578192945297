import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import * as Obj from "utils/object";
import { ReactElement, useState } from "react";
import { GlobalSheet } from "ui/components/Sheet";
import { Typography } from "ui/components/Typography";
import { useTranslation } from "i18n";
import { FormWrapper } from "ui/layouts/FormWrapper";
import * as PickingOrderItemsState from "state-manager/generic-states/PickingOrderItems";
import { GetContructorType, GetGuardType } from "types/src/Utils";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Form } from "../PickingOrderItem/Form";
import { Item } from "./Item";
import { Add } from "./Add";

export interface PickingOrderItemsProps<Id extends string> {
  label?: TranslatedStr;
  value$: Selector<PickingOrderItemsState.State<Id>>;
  dispatch: (a: PickingOrderItemsState.Actions<Id>) => void;
  constructor: PickingOrderItemsState.StateConstructor<Id>;
}

export function PickingOrderItems<Id extends string>(
  p: PickingOrderItemsProps<Id>,
): ReactElement {
  const { t } = useTranslation();
  const [settingsId, setId] = useState<Id | undefined>();
  const ids = useSelector(
    flow(p.value$, (v) => v.payload.items, Obj.keys),
    (a, b) => a.join(",") === b.join(","),
  );
  return (
    <FormWrapper>
      <Typography>{p.label}</Typography>
      {ids.map((id) => {
        const state = p.constructor.createPickingOrderItemState(id);
        return (
          <Item
            key={id}
            selector={flow(p.value$, (v) => v.payload.items[id].payload)}
            onSetSku={flow(state.actions.setSku.create, p.dispatch)}
            onSetQuantity={flow(state.actions.setQuantity.create, p.dispatch)}
            onRemove={() => p.dispatch(p.constructor.actions.remove.create(id))}
            onAdvanced={() => setId(id)}
          />
        );
      })}
      <Add onAdd={flow(p.constructor.actions.addNew.create, p.dispatch)} />
      <GlobalSheet
        isOpen={!!settingsId}
        header={<Typography>{t("Item settings")}</Typography>}
        onClose={() => setId(undefined)}
      >
        {settingsId && (
          <ItemForm
            id={settingsId}
            constructor={p.constructor.createPickingOrderItemState}
            dispatch={p.dispatch}
            value$={flow(p.value$, (v) => v.payload.items[settingsId])}
          />
        )}
      </GlobalSheet>
    </FormWrapper>
  );
}

type ItemStates<Id extends string> = ReturnType<
  PickingOrderItemsState.StateConstructor<Id>["createPickingOrderItemState"]
>;

type ItemActions<Id extends string> = GetGuardType<ItemStates<Id>["isActions"]>;

type ItemState<Id extends string> = GetContructorType<ItemStates<Id>["states"]>;

interface ItemFormProps<Id extends string> {
  id: Id;
  value$: Selector<ItemState<Id>>;
  dispatch: (a: ItemActions<Id>) => void;
  constructor: (id: Id) => ItemStates<Id>;
}
function ItemForm<Id extends string>(p: ItemFormProps<Id>) {
  return (
    <Form
      value$={p.value$}
      dispatch={p.dispatch}
      constructor={p.constructor(p.id)}
    />
  );
}

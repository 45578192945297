import { useTranslation } from "i18n";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import { ISODate } from "types/src/date/ISODate";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { DataTypeId } from "types/src/DataType/DataType";
import { OrderBy } from "types/src/OrderBy";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { ListingTable } from "ui/layouts/ListingTable";
import { RepositoryId } from "types/src/Repositories/Repository";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { ExecuteModal } from "ui/layouts/Dialogs/ExecuteModal";
import { isT } from "fp-utilities";
import { EntityListing } from "./EntityListing";
import * as statusFilter from "./EntityListing/commonFilters/status";
import * as executedFilter from "./EntityListing/commonFilters/executed";
import * as repositoriesFromTo from "./EntityListing/commonFilters/repositoriesFromTo";
import * as itemsFilter from "./EntityListing/commonFilters/items";

export function ItemMovementsListing<
  CustomFiltersConfig extends
    ItemMovementsListing.Filters.ConfigCustomBase = {},
>(p: ItemMovementsListing.Props<CustomFiltersConfig>) {
  const { t } = useTranslation();

  const customFiltersConfig = useBehaviorValue(p.customFiltersConfig$);

  return (
    <>
      <EntityListing
        title={t("Recent item movements")}
        newItemTitle={t("New movement")}
        columns={
          {
            from: {
              label: t("From"),
              type: ListingTable.CellType.movedFrom,
              renderProps: (v) => ({ text: v.from.name }),
            },
            to: {
              label: t("To"),
              type: ListingTable.CellType.movedTo,
              renderProps: (v) => ({ text: v.to.name }),
            },
            quantity: {
              label: t("Quantity"),
              type: ListingTable.CellType.number,
              renderProps: (v) => ({ number: v.quantity }),
              canReorder: true,
            },
            executed: {
              label: t("Executed"),
              type: ListingTable.CellType.badge,
              renderProps: (v) => ({
                color: v.executed ? "green" : "blue",
                text: v.executed ? "Executed" : "Pending",
              }),
              canReorder: true,
            },
            executedAt: {
              label: t("Executed at"),
              type: ListingTable.CellType.timeDate,
              renderProps: (v) => ({
                date: Fp.pipe(
                  v.executedAt,
                  O.map(ISODate.toDate),
                  O.toUndefined,
                ),
              }),
              canReorder: true,
            },
            handler: {
              label: t("Handler"),
              type: ListingTable.CellType.text,
              renderProps: (v) => ({
                text: v.handler,
              }),
              canReorder: true,
            },
          } satisfies EntityListing.ColumnsType<ItemMovementsListing.Item>
        }
        data$={p.data$}
        //region Filters
        customFiltersConfig={customFiltersConfig}
        filters$={p.filters$}
        onFilterChange={p.onFilterChange}
        onResetFilters={p.onResetFilters}
        //endregion
        onItemClick={p.onItemClick}
        onOrderChange={p.onOrderChange}
        orderBy$={p.orderBy$}
        onPageChange={p.onPageChange}
        state$={p.state$}
        onTypeClick={p.onTypeClick}
        onCreateNew={p.onCreateNew}
        bulkSelect={{
          ...p.bulkSelect,
          actions: [
            { id: "remove", label: t("Remove") },
            { id: "execute", label: t("Execute") },
          ],
        }}
        remove={{
          ...p.remove,
          getTitle: (c) =>
            c === 1 ? t("Remove movement") : t("Remove movements"),
          getDescription: (c) =>
            c === 1
              ? t("Do you really want to remove this movement")
              : t("Do you really want to remove this movements"),
        }}
        entryActions={(v) =>
          [
            { label: t("Remove"), onClick: () => p.onRemove(v.id) },
            !v.executed
              ? { label: t("Execute"), onClick: () => p.onExecute(v.id) }
              : undefined,
          ].filter(isT)
        }
        columnsVisibility$={p.columnsVisibility$}
        onColumnsVisibilityChange={p.onColumnsVisibilityChange}
      />
      <Execute {...p.execute} />
    </>
  );
}

export namespace ItemMovementsListing {
  export interface Item extends EntityListing.Item<ItemMovementId> {
    from: { id: RepositoryId; name: string };
    to: { id: RepositoryId; name: string };
    executed: boolean;
    executedAt: O.Option<ISODate>;
    quantity: number;
    handler: string;
  }

  export namespace Filters {
    export type ConfigCustomBase = EntityListing.Filters.ConfigCustomBase;

    export const typeMap = {
      ...statusFilter.typeMap,
      ...executedFilter.typeMap,
      ...repositoriesFromTo.typeMap,
      ...itemsFilter.typeMap,
    } satisfies EntityListing.Filters.ListingTypeMapBase;

    export const stateToConfig = (
      state: repositoriesFromTo.StateToConfigPayload &
        itemsFilter.StateToConfigPayload,
      { t }: Pick<ReturnType<typeof useTranslation>, "t">,
    ) => ({
      ...statusFilter.stateToConfig({}, { t }),
      ...executedFilter.stateToConfig({}, { t }),
      ...repositoriesFromTo.stateToConfig(state, { t }),
      ...itemsFilter.stateToConfig(state, { t }),
    });

    export const converters = {
      ...statusFilter.converters,
      ...executedFilter.converters,
      ...repositoriesFromTo.converters,
      ...itemsFilter.converters,
    } satisfies EntityListing.Filters.ListingConvertersBase<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<keyof typeof typeMap, any>,
      typeof typeMap
    >;
  }

  export interface Props<
    CustomFiltersConfig extends Filters.ConfigCustomBase = {},
  > {
    data$: BehaviorValue<{
      total: number;
      entries: Item[];
      pagination: {
        hasNext: boolean;
        hasPrev: boolean;
      };
    }>;
    state$: BehaviorValue<"loading" | "fetching" | "ready">;

    //region Filters
    customFiltersConfig$: BehaviorValue<CustomFiltersConfig>;
    filters$: BehaviorValue<
      EntityListing.Filters.Criteria<Item, CustomFiltersConfig>
    >;
    onFilterChange: (
      v: EntityListing.Filters.CriteriaChange<Item, CustomFiltersConfig>,
    ) => void;
    onResetFilters: () => void;
    //endregion

    orderBy$: BehaviorValue<
      O.Option<
        OrderBy<
          | "createdAt"
          | "updatedAt"
          | "executed"
          | "executedAt"
          | "handler"
          | "quantity"
        >
      >
    >;
    bulkSelect: Omit<
      EntityListing.Props<Item, "remove" | "execute", {}>["bulkSelect"],
      "actions"
    >;
    remove: Omit<EntityListing.RemoveProps, "getDescription" | "getTitle">;
    execute: {
      confirmation$: BehaviorValue<false | number>;
      onConfirm: () => void;
      onDecline: () => void;
    };

    onCreateNew: (() => void) | undefined;
    onRemove: (id: ItemMovementId) => void;
    onExecute: (id: ItemMovementId) => void;
    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onItemClick: (id: ItemMovementId) => void;
    onTypeClick: (id: DataTypeId) => void;
    onOrderChange: (
      p:
        | "createdAt"
        | "updatedAt"
        | "executed"
        | "executedAt"
        | "handler"
        | "quantity",
    ) => void;
    columnsVisibility$: BehaviorValue<
      Record<
        | "id"
        | "dataType"
        | "createdAt"
        | "updatedAt"
        | "from"
        | "to"
        | "quantity"
        | "executed"
        | "executedAt"
        | "handler",
        boolean
      >
    >;
    onColumnsVisibilityChange: (
      v: Partial<
        Record<
          | "id"
          | "dataType"
          | "createdAt"
          | "updatedAt"
          | "from"
          | "to"
          | "quantity"
          | "executed"
          | "executedAt"
          | "handler",
          boolean
        >
      >,
    ) => void;
  }
}

function Execute(p: {
  confirmation$: BehaviorValue<false | number>;
  onConfirm: () => void;
  onDecline: () => void;
}) {
  const { t } = useTranslation();
  const confirm = useBehaviorValue(p.confirmation$);

  return confirm !== false ? (
    <ExecuteModal
      title={confirm === 1 ? t("Execute movement") : t("Execute movements")}
      onConfirm={p.onConfirm}
      onCancel={p.onDecline}
    >
      {confirm === 1
        ? t("Please confirm that you want to execute this movement")
        : t("Please confirm that you want to execute this movements")}
    </ExecuteModal>
  ) : null;
}

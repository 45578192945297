import { gql } from "@apollo/client";
import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import { isT } from "fp-utilities";
import { Cursor } from "types";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { ISODate } from "types/src/date/ISODate";
import { pipe } from "fp-ts/function";
import { dataTypeFragment } from "../../fragments/DataType";
import { dataTypeFragmentToDataType } from "../../transformers/DataType";
import { Client } from "../../index";
import {
  DataTypeOrderField,
  GetDataTypesQuery,
  GetDataTypesQueryVariables,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResult,
} from "../../type/QueryResponse";
import { notFoundError } from "../../type/DsError";
import { Where } from "../../type/Where";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";
import { Query } from "../../type/Query";
import { toApiOrderDirection } from "../../transformers/OrderDirection";

const whereSchema = Where.create({
  createdAt: Where.ord<ISODate>(),
  updatedAt: Where.eqIn<ISODate>(),
  default: Where.eq<boolean>(),
  description: Where.text(),
  entity: Where.eqIn<DataTypeEntity>(),
  frontendSchema: Where.text(),
  jsonSchema: Where.text(),
  name: Where.text(),
  slug: Where.text(),
  id: Where.eqIn(),
});

export interface GetDataTypesVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
  where?: Where.GetType<typeof whereSchema>;
}

export function getDataTypesQuery(
  vars: GetDataTypesVars,
): Query<
  GetDataTypesQueryVariables,
  GetDataTypesQuery,
  PaginatedQueryResult<DataType>
> {
  const query = gql`
    ${pageInfoFragment}
    ${dataTypeFragment}

    query GetDataTypes(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: DataTypeWhereInput
      $orderBy: DataTypeOrder
    ) {
      dataTypes(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...DataTypeFragment
          }
        }
      }
    }
  `;

  return Query.create(
    {
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: Fp.pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toUndefined,
        ),
        orderBy: pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: DataTypeOrderField.CreatedAt,
              updatedAt: DataTypeOrderField.UpdatedAt,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    },
    Fp.flow(
      E.map((v) => v.dataTypes),
      E.map(getPaginatedQueryResult(dataTypeFragmentToDataType)),
    ),
  );
}

export function getDataTypes(client: Client, vars: GetDataTypesVars) {
  return client.fetchQuery(getDataTypesQuery(vars));
}

export function getDataTypeQuery(id: DataTypeId) {
  return Query.map(
    Fp.flow(
      E.map((v) => v.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
    getDataTypesQuery({ where: { and: [{ id: { eq: id } }] } }),
  );
}

export const getDataType = (client: Client, id: DataTypeId) =>
  client.fetchQuery(getDataTypeQuery(id));

import { DsError } from "ds";
import { DataTypeId } from "types/src/DataType/DataType";
import { PickingOrder } from "types/src/PickingOrder/PickingOrder";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import { NoEmptyArr } from "types/src/NoEmptyArr";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import {
  CustomerSearchActions,
  createCustomerSearchState,
  PickingOrderItemsActions,
  SchemaFieldsActions,
  createSchemaFieldsState,
  createPickingOrderItemsState,
} from "../utils";

export type Actions =
  | LoadFail
  | LoadSuccess
  | Submit
  | SaveFail
  | SaveSuccess
  | CustomerSearchActions
  | SchemaFieldsActions
  | PickingOrderItemsActions
  | Remove
  | RemoveConfirm
  | RemoveDecline
  | RemoveFail
  | RemoveSuccess;

export const isActions = (p: string) => {
  const schemaFieldsState = createSchemaFieldsState(p);
  const customerSearchState = createCustomerSearchState(p);
  const pickingOrderItemsState = createPickingOrderItemsState(p);
  const _isLoadFail = isLoadFail(p);
  const _isLoadSuccess = isLoadSuccess(p);
  const _isSubmit = isSubmit(p);
  const _isSaveFail = isSaveFail(p);
  const _isSaveSuccess = isSaveSuccess(p);
  const _isRemove = isRemove(p);
  const _isRemoveConfirm = isRemoveConfirm(p);
  const _isRemoveDecline = isRemoveDecline(p);
  const _isRemoveFail = isRemoveFail(p);
  const _isRemoveSuccess = isRemoveSuccess(p);

  return strictGuard((a: Actions): a is Actions => {
    if (
      schemaFieldsState.isActions(a) ||
      _isLoadFail(a) ||
      _isLoadSuccess(a) ||
      _isSubmit(a) ||
      _isSaveFail(a) ||
      _isSaveSuccess(a) ||
      _isRemove(a) ||
      _isRemoveConfirm(a) ||
      _isRemoveDecline(a) ||
      _isRemoveFail(a) ||
      _isRemoveSuccess(a) ||
      customerSearchState.isActions(a) ||
      pickingOrderItemsState.isActions(a)
    ) {
      return true;
    }

    silentUnreachableError(a);
    return false;
  });
};

// region LoadFail
export interface LoadFail {
  type: `${string}:LoadFail`;
  payload: DsError;
}

export const loadFail =
  (p: string) =>
  (payload: LoadFail["payload"]): LoadFail => ({
    type: `${p}:LoadFail`,
    payload,
  });

export const isLoadFail =
  (p: string) =>
  (s: Actions): s is LoadFail =>
    s.type === `${p}:LoadFail`;
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: `${string}:LoadSuccess`;
  payload: {
    dataType: {
      title: string;
      id: DataTypeId;
      schema: DataSchema;
      ui: UiSchema;
    };
    dataTypes: NoEmptyArr<{
      title: string;
      id: DataTypeId;
      schema: DataSchema;
      ui: UiSchema;
      isDefault: boolean;
    }>;
    order: PickingOrder;
  };
}

export const loadSuccess =
  (p: string) =>
  (payload: LoadSuccess["payload"]): LoadSuccess => ({
    type: `${p}:LoadSuccess`,
    payload,
  });

export const isLoadSuccess =
  (p: string) =>
  (s: Actions): s is LoadSuccess =>
    s.type === `${p}:LoadSuccess`;
// endregion

// region Submit
export interface Submit {
  type: `${string}:Submit`;
}

export const submit = (p: string) => (): Submit => ({
  type: `${p}:Submit`,
});

export const isSubmit =
  (p: string) =>
  (s: Actions): s is Submit =>
    s.type === `${p}:Submit`;
// endregion

// region SaveFail
export interface SaveFail {
  type: `${string}:SaveFail`;
  payload: DsError;
}

export const saveFail =
  (p: string) =>
  (payload: SaveFail["payload"]): SaveFail => ({
    type: `${p}:SaveFail`,
    payload,
  });

export const isSaveFail =
  (p: string) =>
  (s: Actions): s is SaveFail =>
    s.type === `${p}:SaveFail`;
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: `${string}:SaveSuccess`;
  payload: PickingOrder;
}

export const saveSuccess =
  (p: string) =>
  (payload: SaveSuccess["payload"]): SaveSuccess => ({
    type: `${p}:SaveSuccess`,
    payload,
  });

export const isSaveSuccess =
  (p: string) =>
  (s: Actions): s is SaveSuccess =>
    s.type === `${p}:SaveSuccess`;
// endregion

// region Remove
export interface Remove {
  type: `${string}:Remove`;
}

export const remove = (p: string) => (): Remove => ({
  type: `${p}:Remove`,
});

export const isRemove =
  (p: string) =>
  (s: Actions): s is Remove =>
    s.type === `${p}:Remove`;
// endregion

// region RemoveConfirm
export interface RemoveConfirm {
  type: `${string}:RemoveConfirm`;
}

export const removeConfirm = (p: string) => (): RemoveConfirm => ({
  type: `${p}:RemoveConfirm`,
});

export const isRemoveConfirm =
  (p: string) =>
  (s: Actions): s is RemoveConfirm =>
    s.type === `${p}:RemoveConfirm`;
// endregion

// region RemoveDecline
export interface RemoveDecline {
  type: `${string}:RemoveDecline`;
}

export const removeDecline = (p: string) => (): RemoveDecline => ({
  type: `${p}:RemoveDecline`,
});

export const isRemoveDecline =
  (p: string) =>
  (s: Actions): s is RemoveDecline =>
    s.type === `${p}:RemoveDecline`;
// endregion

// region RemoveFail
export interface RemoveFail {
  type: `${string}:RemoveFail`;
  payload: DsError;
}

export const removeFail =
  (p: string) =>
  (payload: RemoveFail["payload"]): RemoveFail => ({
    type: `${p}:RemoveFail`,
    payload,
  });

export const isRemoveFail =
  (p: string) =>
  (s: Actions): s is RemoveFail =>
    s.type === `${p}:RemoveFail`;
// endregion

// region RemoveSuccess
export interface RemoveSuccess {
  type: `${string}:RemoveSuccess`;
}

export const removeSuccess = (p: string) => (): RemoveSuccess => ({
  type: `${p}:RemoveSuccess`,
});

export const isRemoveSuccess =
  (p: string) =>
  (s: Actions): s is RemoveSuccess =>
    s.type === `${p}:RemoveSuccess`;
// endregion

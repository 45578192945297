import { useCallback } from "react";
import { Value } from "types/src/jsonSchema/value";
import { BehaviorValueRef } from "react-rx/behaviorValue";
import * as Fp from "fp-ts/function";
import { ControlType } from "../../types/control/type";
import { ControlTypeConfig } from "../../types/control";
import { controlReset } from "../../utils/control/reset";
import { fixValue } from "../../utils/value/fix";

type Props<Type extends ControlType> = {
  id: string;
  typeRef: BehaviorValueRef<ControlTypeConfig<Type>>;
  valueRef: BehaviorValueRef<Value>;
  onValueChange: (value: Value) => void;
};

export const useControlReset = <Type extends ControlType>({
  id,
  typeRef,
  valueRef,
  onValueChange,
}: Props<Type>) =>
  useCallback(
    () =>
      onValueChange(
        Fp.pipe(
          valueRef.current,
          (schemas) => controlReset({ id, type: typeRef.current, schemas }),
          fixValue,
        ),
      ),
    [id, onValueChange, typeRef, valueRef],
  );

import { UiSchemaTypeRegistry } from "types/src/jsonSchema/uiSchema";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { useMemo } from "react";
import { getId } from "../../../../utils/uiSchema";
import { Elements } from "../../../Elements";
import { ListLayout } from "./index";

export const Inner = <
  ElementType extends ListLayout.SupportedElementTypes,
  UiProps extends ListLayout.UiCommonProps,
>({
  elementType,
  uiSchemaPathEncoded: pathEncoded,
  uiSchema$,
  UI,
  uiProps,
}: ListLayout.Props<ElementType, UiProps> & {
  uiSchema$: BehaviorValue<UiSchemaTypeRegistry[ElementType]>;
}) => {
  const id = useBehaviorValue(useMemo(() => uiSchema$.map(getId), [uiSchema$]));
  const elements = useBehaviorValue(
    useMemo(() => uiSchema$.map(({ elements }) => elements), [uiSchema$]),
  );
  const dndMeta = useMemo<Parameters<typeof UI>[0]["dndMeta"]>(
    () => ({ elementType, childIds: elements.map(getId) }),
    [elementType, elements],
  );

  return (
    <UI {...uiProps} {...{ id, dndMeta }}>
      <Elements elements={elements} parentUiSchemaPathEncoded={pathEncoded} />
    </UI>
  );
};

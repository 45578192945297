import React from "react";
import { createSvgIcon } from "@mui/material";

export const QuestionCircle = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_3018_2111)">
      <path
        d="M6.05992 6.00065C6.21665 5.5551 6.52602 5.17939 6.93322 4.94007C7.34042 4.70076 7.81918 4.61328 8.2847 4.69313C8.75022 4.77297 9.17246 5.015 9.47664 5.37634C9.78081 5.73767 9.94729 6.195 9.94659 6.66732C9.94659 8.00065 7.94659 8.66732 7.94659 8.66732M7.99992 11.334H8.00659M14.6666 8.00065C14.6666 11.6826 11.6818 14.6673 7.99992 14.6673C4.31802 14.6673 1.33325 11.6826 1.33325 8.00065C1.33325 4.31875 4.31802 1.33398 7.99992 1.33398C11.6818 1.33398 14.6666 4.31875 14.6666 8.00065Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3018_2111">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "QuestionCircle",
);

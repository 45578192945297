import { createContext } from "react";
import { DndMeta } from "../types";

export namespace ElementRefsContext {
  export type Value = {
    refs: Record<string, { el: HTMLElement; meta: DndMeta.Common }>;
  };
}

export const makeElementRefsContextValue = (): ElementRefsContext.Value => ({
  refs: {},
});

export const ElementRefsContext = createContext<ElementRefsContext.Value>(
  makeElementRefsContextValue(),
);

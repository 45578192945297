import { Typed } from "utils/Typed";
import { Client, DsError, unknownError } from "ds";
import { GetGuardType } from "types/src/Utils";
import { SupplierId } from "types/src/Supplier/Supplier";
import { Inbound, InboundId } from "types/src/Inbounds/Inbound";
import { DataType, DataTypeEntity } from "types/src/DataType/DataType";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import * as Rx from "rxjs";
import * as E from "fp-ts/Either";
import * as SuppliersApi from "ds/Suppliers";
import * as InboundsApi from "ds/Inbounds";
import * as InboundItemsApi from "ds/InboundItems";
import * as DataTypesApi from "ds/DataTypes";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { isOneOf } from "utils/isOneOf";
import { silentUnreachableError } from "utils/exceptions";
import { Tuple } from "types/src/Tuple";
import { isT } from "fp-utilities";
import { InboundItem, InboundItemId } from "types/src/Inbounds/InboundItem";
import { not } from "fp-ts/Refinement";
import * as SchemaFields from "../../../../../../../../generic-states/SchemaFields";
import { InboundItem as InboundItemState } from "../../../../../../../../generic-states/InboundItem";
import { Loading } from "../../../../../../../../generic-states/Loading";
import { Epic } from "../../../../../../../../types/RootEpic";
import { isNewItemId } from "../../../../../../../../generic-states/PickingOrderItems/types/NewItemId";
import { NewItemId } from "./types/NewItemId";
import { isItemId, nextItemId } from "./utils";

function createStore<P extends string>(p: P) {
  interface Ready {
    id: InboundId;
    dataTypes: DataType[];
    suppliers: GetGuardType<typeof suppliers.isState>;
    schema: O.Option<GetGuardType<typeof schema.isState>>;
    items: Array<
      [NewItemId | InboundItemId, GetGuardType<typeof items.isState>]
    >;
    supplier: O.Option<SupplierId>;
    _toRemove: InboundItemId[];
  }

  interface Saving extends Ready {
    schema: O.Option<GetGuardType<typeof schema.states.valid.is>>;
    items: Array<[NewItemId, Typed.GetType<typeof items.states.valid>]>;
    supplier: O.Some<SupplierId>;
  }

  const schema = SchemaFields.createState(`${p}:SchemaFields`);
  const items = InboundItemState.createState(`${p}:items`);
  const suppliers = Loading.createState<
    Array<{ id: SupplierId; name: string }>,
    string | undefined
  >(`${p}:suppliers`, { equals: (a, b) => a === b });
  const states = Typed.builder
    .add("loading", (p: { id: InboundId }) => p)
    .add("loadError", (p: { id: InboundId; error: DsError }) => p)
    .add("ready", (p: Ready) => p)
    .add("removeConfirm", (p: Ready) => p)
    .add("removing", (p: Ready) => p)
    .add("saving", (p: Saving) => p)
    .finish()(`${p}:states`);
  const actions = Typed.builder
    .add("loadError", (p: DsError) => p)
    .add("saveError", (p: DsError) => p)
    .add("saveSuccess", (p: Inbound) => p)
    .add("submit")
    .add("remove")
    .add("removeConfirm")
    .add("removeDecline")
    .add("removeSuccess")
    .add("removeError", (p: DsError) => p)
    .add("setSupplier", (p: SupplierId | undefined) => p)
    .add("addItem")
    .add("removeItem", (p: InboundEdit.ItemId) => p)
    .add("schema", (p: GetGuardType<typeof schema.isActions>) => p)
    .add("suppliers", (p: GetGuardType<typeof suppliers.isAction>) => p)
    .add(
      "loadSuccess",
      (p: {
        inbound: Inbound;
        schema: DataSchema | undefined;
        uiSchema: UiSchema | undefined;
        dataTypes: DataType[];
      }) => p,
    )
    .add(
      "items",
      (
        id: NewItemId | InboundItemId,
        action: GetGuardType<typeof items.isAction>,
      ) => ({
        id,
        action,
      }),
    )

    .finish()(`${p}:actions`);

  const exits = Typed.builder
    .add("saved", (p: Inbound) => p)
    .add("removed", (p: InboundId) => p)
    .finish()(`${p}:exits`);

  type State = Typed.GetTypes<typeof states>;
  type Actions = Typed.GetTypes<typeof actions>;
  type Exits = Typed.GetTypes<typeof exits>;

  const isEditable = isOneOf([states.ready.is, states.saving.is]);

  const epic: Epic<
    Actions,
    State,
    {
      pyckAdminClient$: Rx.Observable<Client>;
    }
  > = (s$, d) => {
    const loading$ = Rx.combineLatest([
      d.pyckAdminClient$,
      s$.pipe(
        Rx.distinctUntilChanged(states.loading.is),
        Rx.filter(states.loading.is),
      ),
    ]).pipe(
      Rx.switchMap(([client, s]) => {
        return Rx.forkJoin({
          inbound: Rx.from(
            client.fetchQuery(InboundsApi.getInboundQuery(s.payload.id)),
          ).pipe(
            Rx.switchMap(
              (
                inbound,
              ): Rx.Observable<
                E.Either<DsError, Tuple<Inbound, DataType | undefined>>
              > => {
                if (E.isLeft(inbound)) return Rx.of(inbound);

                const preTuple = Tuple.create<Inbound, DataType | undefined>(
                  inbound.right,
                );

                if (!inbound.right.dataTypeId)
                  return Rx.of(E.right(preTuple(undefined)));

                return Rx.from(
                  DataTypesApi.getDataType(client, inbound.right.dataTypeId),
                ).pipe(
                  Rx.map(
                    Fp.flow(
                      E.mapLeft(() => preTuple(undefined)),
                      E.map(preTuple),
                      E.toUnion,
                      E.right,
                    ),
                  ),
                );
              },
            ),
          ),
          dataTypes: Rx.from(
            client.fetchQuery(
              DataTypesApi.getDataTypesQuery({
                where: { entity: { eq: DataTypeEntity.InboundItem } },
              }),
            ),
          ),
        });
      }),
      Rx.map(({ inbound, dataTypes }) => {
        return Fp.pipe(
          inbound,
          E.map(([inbound, dataType]) =>
            actions.loadSuccess.create({
              inbound,
              dataTypes: Fp.pipe(
                dataTypes,
                E.map((v) => v.items),
                E.getOrElse((): DataType[] => []),
              ),
              schema: dataType?.schema,
              uiSchema: dataType?.frontendSchema,
            }),
          ),
        );
      }),
      Rx.map(E.getOrElseW(actions.loadError.create)),
    );

    const remove$ = Rx.combineLatest([
      d.pyckAdminClient$,
      s$.pipe(
        Rx.distinctUntilChanged(states.removing.is),
        Rx.filter(states.removing.is),
      ),
    ]).pipe(
      Rx.switchMap(([client, s]) =>
        Rx.from(InboundsApi.removeInbound(client, s.payload.id)),
      ),
      Rx.map(
        Fp.flow(
          E.map(actions.removeSuccess.create),
          E.getOrElseW(actions.removeError.create),
        ),
      ),
    );

    const update$ = Rx.combineLatest([
      d.pyckAdminClient$,
      s$.pipe(
        Rx.distinctUntilChanged(states.saving.is),
        Rx.filter(states.saving.is),
      ),
    ]).pipe(
      Rx.switchMap(([client, s]) => {
        const toAdd$ = Rx.of(
          s.payload.items.filter(([id]) => isNewItemId(id)),
        ).pipe(
          Rx.switchMap((items) => {
            if (!items.length) return Rx.of(undefined);

            const vs = items.map(([id, i]) => {
              const item = InboundItemsApi.createInboundItem(client, {
                inboundId: s.payload.id,
                sku: i.payload.sku.value,
                quantity: i.payload.quantity.value,
                fields: Fp.pipe(
                  i.payload.fields,
                  O.map((v) => v.payload.values),
                  O.toUndefined,
                ),
                dataTypeId: O.toUndefined(i.payload.dataTypeId),
              });

              return Rx.from(item).pipe(
                Rx.catchError(() =>
                  Rx.of(E.left<DsError, InboundItem>(unknownError())),
                ),
                Rx.map(
                  Fp.flow(
                    E.map((v) => v.id),
                    E.mapLeft(Tuple.create(id)),
                  ),
                ),
              );
            });

            return Rx.forkJoin(vs);
          }),
        );

        return toAdd$.pipe(
          Rx.switchMap((toAdd) => {
            return Rx.from(
              InboundsApi.updateInbound(client, {
                id: s.payload.id,
                fields: Fp.pipe(
                  s.payload.schema,
                  O.map((v) => v.payload.values),
                  O.toUndefined,
                ),
                supplierId: s.payload.supplier.value,
                addInboundItemIds: Fp.pipe(
                  toAdd,
                  O.fromNullable,
                  O.map((vs) => vs.map(Fp.flow(O.fromEither, O.toUndefined))),
                  O.map((vs) => vs.filter(isT)),
                  O.filter((v) => v.length > 0),
                  O.toUndefined,
                ),
                removeInboundItemIds: s.payload._toRemove,
              }),
            ).pipe(
              Rx.map(
                Fp.flow(
                  E.map(actions.saveSuccess.create),
                  E.mapLeft(actions.saveError.create),
                  E.toUnion,
                ),
              ),
            );
          }),
        );
      }),
    );

    const schema$ = schema
      .epic(
        s$.pipe(
          Rx.filter(isEditable),
          Rx.map((v) => v.payload.schema),
          Rx.filter(O.isSome),
          Rx.map((v) => v.value),
        ),
        {},
      )
      .pipe(
        Rx.map((a) => (schema.isActions(a) ? actions.schema.create(a) : a)),
      );

    const suppliers$ = suppliers
      .epic(
        s$.pipe(
          Rx.filter(isEditable),
          Rx.map((v) => v.payload.suppliers),
        ),
        {
          get: (q) =>
            d.pyckAdminClient$
              .pipe(
                Rx.switchMap((client) =>
                  SuppliersApi.getSuppliers(client, {
                    where: {
                      id: {
                        eq: Fp.pipe(
                          q,
                          O.fromNullable,
                          O.map(SupplierId.fromString),
                          O.toUndefined,
                        ),
                      },
                    },
                  }),
                ),
              )
              .pipe(
                Rx.map(
                  Fp.flow(
                    E.map((r) =>
                      r.items.map((i) => ({ id: i.id, name: i.id.toString() })),
                    ),
                  ),
                ),
              ),
        },
      )
      .pipe(
        Rx.map((a) =>
          suppliers.isAction(a) ? actions.suppliers.create(a) : a,
        ),
      );

    const items$ = s$.pipe(
      Rx.filter(isEditable),
      Rx.map((s) => s.payload.items),
      Rx.map((is) => is.map(([id]) => id)),
      Rx.mergeMap((ids) => Rx.from(ids)),
      Rx.distinct(),
      Rx.mergeMap((id) => {
        return items
          .epic(
            s$.pipe(
              Rx.filter(isEditable),
              Rx.map((s) => s.payload.items.find((v) => v[0] === id)),
              Rx.filter(isT),
              Rx.map(([, v]) => v),
            ),
            {},
          )
          .pipe(
            Rx.map((a) =>
              items.isAction(a) ? actions.items.create(id, a) : a,
            ),
          );
      }),
    );

    return Rx.merge(loading$, update$, schema$, suppliers$, items$, remove$);
  };

  return {
    states,
    actions,
    exits,
    init: (id: InboundId) => states.loading.create({ id }),
    isState: Typed.getGuard(states),
    isAction: Typed.getGuard(actions),
    isExit: Typed.getGuard(exits),
    reducer,
    epic,
    subStates: {
      schema,
      items,
      suppliers,
    },
  };

  function reducer(s: State, a: Actions): E.Either<Exits, State> {
    if (actions.loadError.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.loading.is),
        O.map((s) =>
          states.loadError.create({ ...s.payload, error: a.payload }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.loadSuccess.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.loading.is),
        O.map((s) =>
          states.ready.create({
            ...s.payload,

            suppliers: suppliers.init(undefined),
            supplier: O.fromNullable(a.payload.inbound.supplierId),
            schema: Fp.pipe(
              a.payload.schema,
              O.fromNullable,
              O.map((s) =>
                schema.init({
                  schema: s,
                  uiSchema: a.payload.uiSchema,
                  values: a.payload.inbound.fields,
                }),
              ),
            ),
            dataTypes: a.payload.dataTypes,
            items: a.payload.inbound.items.map((i) => {
              return Tuple.create(
                i.id,
                items.init({
                  dataTypes: a.payload.dataTypes.map((i) => ({
                    id: i.id,
                    schema: i.schema,
                    uiSchema: i.frontendSchema,
                    name: i.name,
                  })),
                  sku: i.sku,
                  dataTypeId: i.dataTypeId,
                  fields: i.fields,
                  quantity: i.quantity,
                }),
              );
            }),
            _toRemove: [],
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.setSupplier.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.filter((s) => O.toUndefined(s.payload.supplier) !== a.payload),
        O.map((s) =>
          states.ready.create({
            ...s.payload,
            supplier: O.fromNullable(a.payload),
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.addItem.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.map((s) =>
          states.ready.create({
            ...s.payload,
            items: [
              ...s.payload.items,
              [
                nextItemId(
                  s.payload.items.map((v) => Tuple.fst(v)).filter(isItemId),
                ),
                items.init({
                  dataTypes: s.payload.dataTypes.map((d) => ({
                    id: d.id,
                    name: d.name,
                    schema: d.schema,
                    uiSchema: d.frontendSchema,
                  })),
                }),
              ],
            ],
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeItem.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.filter((s) => s.payload.items.map(Tuple.fst).includes(a.payload)),
        O.map((s) =>
          states.ready.create({
            ...s.payload,
            items: s.payload.items.filter(([k]) => k !== a.payload),
            _toRemove: !not(isNewItemId)(a.payload)
              ? [...s.payload._toRemove, a.payload]
              : s.payload._toRemove,
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.schema.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.map((s) =>
          states.ready.create({
            ...s.payload,
            schema: Fp.pipe(
              s.payload.schema as O.Option<GetGuardType<typeof schema.isState>>,
              O.map((v) =>
                Fp.pipe(
                  schema.reducer(v, a.payload),
                  E.getOrElseW((e) => {
                    silentUnreachableError(e);
                    return v;
                  }),
                ),
              ),
            ),
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.suppliers.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.map((s) =>
          states.ready.create({
            ...s.payload,
            suppliers: Fp.pipe(
              suppliers.reducer(s.payload.suppliers, a.payload),
              E.getOrElse((e) => {
                silentUnreachableError(e);
                return s.payload.suppliers;
              }),
            ),
          }),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.items.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(isEditable),
        O.map((s) =>
          Fp.pipe(
            s.payload.items,
            O.of,
            O.map((vs) =>
              vs.map(
                (item) =>
                  Fp.pipe(
                    item,
                    O.fromPredicate((v) => v[0] === a.payload.id),
                    O.map((v) => v[1]),
                    O.map((v) =>
                      Fp.pipe(
                        items.reducer(v, a.payload.action),
                        E.getOrElse((e) => {
                          silentUnreachableError(e);
                          return v;
                        }),
                      ),
                    ),
                    O.map((v) => Tuple.create(item[0], v)),
                    O.getOrElse(() => item),
                  ),
                vs,
              ),
            ),
            O.map((v) => states.ready.create({ ...s.payload, items: v })),
            O.getOrElse(() => s),
          ),
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.submit.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.ready.is),
        O.map((s) => {
          const _schema = Fp.pipe(
            s.payload.schema,
            O.map((s) =>
              Fp.pipe(
                schema.reducer(s, schema.actions.submit.create()),
                E.getOrElse((e) => {
                  silentUnreachableError(e);
                  return s;
                }),
              ),
            ),
          );
          const _items = Fp.pipe(s.payload.items, (is) =>
            is.map(([id, s]) =>
              Fp.pipe(
                items.reducer(s, items.actions.submit.create()),
                E.getOrElse((e) => {
                  silentUnreachableError(e);
                  return s;
                }),
                Tuple.create(id),
              ),
            ),
          );

          if (
            O.isSome(s.payload.supplier) &&
            toOptionGuard(schema.states.valid.is)(_schema) &&
            _items.every(
              (v): v is [NewItemId, Typed.GetType<typeof items.states.valid>] =>
                items.states.valid.is(v[1]),
            )
          ) {
            return states.saving.create({
              ...s.payload,
              supplier: s.payload.supplier,
              schema: _schema,
              items: _items,
            });
          }

          return states.ready.create({
            ...s.payload,
            schema: _schema,
            items: _items,
          });
        }),
        O.getOrElseW(() => s),
        E.right,
      );
    }

    if (actions.remove.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.ready.is),
        O.map((s) => states.removeConfirm.create(s.payload)),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeConfirm.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.removeConfirm.is),
        O.map((s) => states.removing.create(s.payload)),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeDecline.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.removeConfirm.is),
        O.map((s) => states.ready.create(s.payload)),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeError.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.removing.is),
        O.map((s) => states.ready.create(s.payload)),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.removeSuccess.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.removing.is),
        O.map((s) => exits.removed.create(s.payload.id)),
        O.map(E.left),
        O.getOrElse(() => E.right(s)),
      );
    }

    if (actions.saveError.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.saving.is),
        O.map((s) => states.ready.create(s.payload)),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (actions.saveSuccess.is(a)) {
      return Fp.pipe(
        s,
        O.fromPredicate(states.saving.is),
        O.map(() => exits.saved.create(a.payload)),
        O.map(E.left),
        O.getOrElseW(() => E.right(s)),
      );
    }

    silentUnreachableError(a);
    return E.right(s);
  }
}

export namespace InboundEdit {
  export const instance = createStore("Ready:DataManager:Inbounds:Edit");

  export type State = GetGuardType<typeof instance.isState>;
  export type Actions = GetGuardType<typeof instance.isAction>;
  export type Exits = GetGuardType<typeof instance.isExit>;

  export type ItemId = NewItemId | InboundItemId;
}

const toOptionGuard =
  <A, B extends A>(p: (a: A) => a is B) =>
  (v: O.Option<A>): v is O.Option<B> =>
    Fp.pipe(
      v,
      O.map(p),
      O.getOrElse(() => true),
    );

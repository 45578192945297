import React from "react";
import { ControlTypeRegistry } from "../types/control";

export namespace ControlTypesContext {
  export type Value =
    /**
     * At the moment it's static - then only `useMemo()` dependency is `t()`.
     * If it will have more dependencies, switch to `BehaviorValue<ControlTypes>`
     */
    ControlTypeRegistry;
}

export const ControlTypesContext =
  React.createContext<ControlTypesContext.Value>(
    // @ts-expect-error no default
    {},
  );

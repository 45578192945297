import { Value } from "types/src/jsonSchema/value";
import {
  UiSchema,
  UiSchemaElementType,
  UiSchemaElementTypeControl,
} from "types/src/jsonSchema/uiSchema";
import * as R from "rambda";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import * as Fp from "fp-ts/function";
import { findElementPath } from "../uiSchema/findElementPath";
import { scopeToPath } from "../uiSchema/scope";
import { updateRequiredByScope } from "../dataSchema/updateRequired";
import { uiSchemaFindControls } from "../uiSchema/findControls";

type Props = {
  id: string;
  schemas: Value;
};

export const controlDelete = ({ id, schemas }: Props): Value => {
  const uiSchemaPath = findElementPath(
    { id, type: UiSchemaElementType.control },
    schemas.uiSchema,
  );
  if (!uiSchemaPath) {
    return schemas; // maybe log
  }

  const uiSchemaElement = R.path<UiSchemaElementTypeControl>(
    uiSchemaPath,
    schemas.uiSchema,
  );
  if (!uiSchemaElement) {
    return schemas; // maybe log
  }

  const scope = uiSchemaElement.scope;
  const uiSchema = R.dissocPath<UiSchema>(uiSchemaPath, schemas.uiSchema);
  const areControlsWithSameScope = Fp.pipe(
    uiSchemaFindControls(uiSchema, (c) => c.scope === scope, true),
    (controls) => !!controls.length,
  );

  return {
    uiSchema,
    dataSchema: areControlsWithSameScope
      ? schemas.dataSchema
      : Fp.pipe(
          schemas.dataSchema,
          R.dissocPath<DataSchema>(scopeToPath(scope)),
          (dataSchema) => updateRequiredByScope(scope, false, dataSchema),
        ),
  };
};

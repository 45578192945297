import { UserId } from "types";
import * as Rx from "rxjs";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as Obj from "utils/object";

export class UserSettingsLocalStorage {
  private static getKey(id: UserId) {
    return `pyck-admin:user-settings:${id}`;
  }

  static get(id: UserId): Rx.Observable<object> {
    return Fp.pipe(
      UserSettingsLocalStorage.getKey(id),
      (v) => localStorage.getItem(v),
      O.fromNullable,
      O.chain(Fp.flow(Obj.jsonParse, O.fromNullable)),
      O.filter((v): v is object => !!v && typeof v === "object"),
      O.getOrElse(() => ({})),
      (v) => Rx.of(v),
    );
  }

  static set(id: UserId, v: object): void {
    localStorage.setItem(
      UserSettingsLocalStorage.getKey(id),
      JSON.stringify(v),
    );
  }
}

import { Collections as Main } from "state-manager/states/Ready/states/DataManager/states/Collections";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import * as Fp from "fp-ts/function";

import { DataTypeId } from "types/src/DataType/DataType";
import { ReactElement } from "react";
import * as ListingAllContainer from "./ListingAll";
import * as ListingContainer from "./Listing";
import * as ViewContainer from "./View";

const { listingAll, listing, view } = Main.instance.subStates;

export interface ContentProps {
  selector: Selector<Main.State>;
  dispatch: (a: Main.Actions) => void;
  onType: (id: DataTypeId) => void;
}

export function Content(p: ContentProps): ReactElement | null {
  const content = useSelector(
    flow(
      p.selector,
      (s) => s.payload.listing,
      (s) => {
        if (listingAll.isState(s))
          return {
            type: "listing-all",
            selector: flow(
              p.selector,
              (s) => s.payload.listing,
              (st) => st as typeof s,
            ),
          } as const;

        if (listing.isState(s))
          return {
            type: "listing",
            selector: flow(
              p.selector,
              (s) => s.payload.listing,
              (st) => st as typeof s,
            ),
          } as const;

        if (view.isState(s))
          return {
            type: "view",
            selector: flow(
              p.selector,
              (s) => s.payload.listing,
              (st) => st as typeof s,
            ),
          } as const;

        silentUnreachableError(s);
        return {
          type: "unknown",
        } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );

  switch (content.type) {
    case "listing-all":
      return (
        <ListingAllContainer.Content
          selector={content.selector}
          dispatch={p.dispatch}
          onItemClick={Fp.flow(
            Main.instance.actions.goToView.create,
            p.dispatch,
          )}
          onTypeClick={p.onType}
        />
      );
    case "listing":
      return (
        <ListingContainer.Content
          selector={content.selector}
          dispatch={p.dispatch}
          onItemClick={Fp.flow(
            Main.instance.actions.goToView.create,
            p.dispatch,
          )}
          onTypeClick={p.onType}
        />
      );
    case "view":
      return (
        <ViewContainer.Content
          selector={content.selector}
          dispatch={p.dispatch}
          onItemClick={() => {}}
        />
      );
    case "unknown":
      return null;
  }
}

import { catchError, filter, map, merge, NEVER } from "rxjs";
import * as Rx from "rxjs";
import * as Auth from "./states/Auth";
import * as Ready from "./states/Ready";
import * as Notifications from "./states/Notifications";
import { RootEpic } from "./types/RootEpic";
import { DataSource } from "./types/DataSource";

export const rootEpic: RootEpic = (state$, deps: DataSource) => {
  const content$ = state$.pipe(map((s) => s.content));
  const auth$ = Auth.epic(content$.pipe(filter(Auth.isState)), {
    getUser: () => Rx.from(deps.userManager.getUser()),
    signIn: () => Rx.from(deps.userManager.signIn()),
    signInConfirm: () => Rx.from(deps.userManager.signInConfirm()),
    getUserSettings: deps.userSettings.get,
  });
  const ready$ = Ready.epic(content$.pipe(filter(Ready.isState)), deps);
  const notifications$ = Notifications.epic(
    state$.pipe(
      map((s) => s.notifications),
      filter(Notifications.isState),
    ),
    {},
  );

  return merge(auth$, ready$, notifications$).pipe(
    catchError((e) => {
      deps.logger(e);
      return NEVER;
    }),
  );
};

import { useTranslation } from "i18n";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { Sxs } from "../../../types/styles";

export namespace SelectedCount {
  export type Props = {
    selectedCount: number;
  };
}
export const SelectedCount = ({ selectedCount }: SelectedCount.Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={sxs.root}>
      <FormControlLabel
        control={<Checkbox checked={!!selectedCount} disabled />}
        label={
          <Box component={"span"} sx={sxs.label}>
            {t("{selectedCount} Fields selected", { selectedCount })}
          </Box>
        }
      />
    </Box>
  );
};

const sxs = {
  root: (theme) => ({
    backgroundColor: theme.palette.surface.primary,
    padding: theme.spacing(4, 6),
    display: "flex",
  }),
  label: (theme) => ({
    fontStyle: "normal",
    color: theme.palette.common.black,
    whiteSpace: "nowrap",
  }),
} satisfies Sxs;

import * as O from "fp-ts/Option";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import * as Rx from "rxjs";
import { Client, isNotFoundError } from "ds";
import * as Collections from "ds/Collections";
import * as DataTypes from "ds/DataTypes";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { Tuple } from "types/src/Tuple";
import { NoEmptyString } from "types/src/NoEmptyString";
import { CollectionId } from "types/src/Collections/Collection";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingWithDataType } from "../../../../../../../../generic-states/ListingWithDataType";
import { CollectionItem } from "../../common/CollectionItem";
import { CollectionFilters } from "../../common/CollectionFilters";

const prefix = "Ready:DataManager:Collections:Listing" as const;

function createState() {
  const state = ListingWithDataType.createState<
    CollectionsListing.Filters,
    CollectionsListing.Item,
    "createdAt" | "updatedAt",
    { id: DataTypeId }
  >(prefix, {
    defaultFilters: {},
  });

  const epic: Epic<
    ListingWithDataType.GetActions<typeof state>,
    ListingWithDataType.GetState<typeof state>,
    CollectionsListing.Deps
  > = (state$, deps) => {
    return state.epic(state$, {
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(
                client.fetchQuery(
                  Collections.getCollectionsQuery(getFetchVars(s)),
                ),
              ),
              dataTypes: Rx.from(
                client.fetchQuery(
                  DataTypes.getDataTypesQuery({
                    where: {
                      entity: { in: [DataTypeEntity.CollectionMovement] },
                    },
                  }),
                ),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;
                    if (E.isLeft(v.dataTypes)) return v.dataTypes;

                    return E.right({
                      items: v.items.right,
                      dataTypes: v.dataTypes.right,
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      ...i,
                      updatedAt: O.fromNullable(i.updatedAt),
                      handler: O.fromNullable(i.handler),
                      assignee: O.fromNullable(i.assignee),
                      assignmentDate: O.fromNullable(i.assignmentDate),
                      dataType: O.fromNullable(
                        r.dataTypes.items.find((v) => v.id === i.dataTypeId),
                      ),
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => Rx.of(E.left(ids)),
      fetchDataType: (id) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            return Rx.from(
              client.fetchQuery(DataTypes.getDataTypeQuery(id)),
            ).pipe(
              Rx.map(E.map(O.some)),
              Rx.map(
                E.orElse((e) => {
                  if (isNotFoundError(e)) return E.right(O.none);

                  return E.left(e);
                }),
              ),
            );
          }),
        );
      },
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
    });
  };

  return { ...state, epic };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): Collections.GetCollectionsVars {
    const fields = s.payload.filters.payload.fields;
    const where: Collections.GetCollectionsVars["where"] = {
      dataType: {
        eq: s.payload.id,
        isNil: Fp.pipe(
          fields.status,
          O.fromNullable,
          O.map((v) => v === "orphan"),
          O.toUndefined,
        ),
      },
      and: [
        {
          createdAt: {
            gte: fields.createdAt?.[0],
            lte: fields.createdAt?.[1],
          },
          updatedAt: {
            gte: fields.updatedAt?.[0],
            lte: fields.updatedAt?.[1],
          },
        },
      ],
      or: [
        {
          id: {
            eq: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.map(CollectionId.fromString),
              O.toUndefined,
            ),
          },
        },
        {
          data: {
            contains: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.chain(NoEmptyString.fromString),
              O.map(Tuple.create("")),
              O.toUndefined,
            ),
          },
        },
      ],
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
}

export namespace CollectionsListing {
  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: (
      id: DataTypeId,
    ) => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (id: DataTypeId, v: Record<string, boolean>) => void;
  }
  export type Filters = CollectionFilters;
  export type Item = CollectionItem;

  export const instance = createState();

  export type State = ListingWithDataType.GetState<typeof instance>;
  export type Actions = ListingWithDataType.GetActions<typeof instance>;
  export type Exits = ListingWithDataType.GetExits<typeof instance>;
}

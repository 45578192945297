import * as Fp from "fp-ts/function";
import * as Arr from "fp-ts/Array";
import { NewItemId } from "./types/NewItemId";

export const nextItemId: (items: Array<[NewItemId, unknown]>) => NewItemId =
  Fp.flow(
    Arr.map(([id]) => id),
    Arr.map(NewItemId.getCount),
    (vs) => Math.max(1, ...vs),
    (v) => v + 1,
    NewItemId.fromNumber,
  );

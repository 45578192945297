import { gql } from "@apollo/client";
import { Customer, CustomerId } from "types/src/Customers/Customer";
import { DataTypeId } from "types/src/DataType/DataType";
import { flow } from "fp-ts/function";
import * as E from "fp-ts/Either";
import { isT } from "fp-utilities";
import { print } from "graphql";
import { DataSchemaValue } from "types/src/jsonSchema/DataSchemaValue";
import { customerFragment } from "../../fragments/Customer";
import {
  CreateCustomerMutation,
  CreateCustomerMutationVariables,
  CustomerFragmentFragment,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
} from "../../generated/graphql";
import { Client, notFoundError } from "../../index";
import { customerFragmentToCustomer } from "../../transformers/Customers";
import { DsError } from "../../type/DsError";
import { QueryResponse } from "../../type/QueryResponse";

export const createCustomer = (
  client: Client,
  vars: {
    dataTypeId: DataTypeId;
    fields: DataSchemaValue;
  },
): Promise<QueryResponse<Customer>> => {
  const mutation = gql`
    ${customerFragment}
    mutation CreateCustomer($input: CreateCustomerInput!) {
      createCustomer(input: $input) {
        ...CustomerFragment
      }
    }
  `;

  return client
    .mutate<CreateCustomerMutation, CreateCustomerMutationVariables>({
      mutation,
      variables: {
        input: {
          data: vars.fields,
          dataTypeID: vars.dataTypeId,
        },
      },
    })
    .then(
      flow(
        E.map((v) => v.createCustomer),
        E.filterOrElseW(isT, notFoundError),
        E.map(customerFragmentToCustomer),
      ),
    );
};

export const createCustomers = async (
  client: Client,
  vars: {
    dataTypeId: DataTypeId;
    fields: DataSchemaValue[];
  },
): Promise<E.Either<DsError, void>> => {
  const template = `
    data_customer_id: createCustomer(input: [CUSTOMER_INPUT]) {
      ...CustomerFragment
    }
  `;

  const fakeMutations: string[] = [];
  const fakeTypes: string[] = [];
  const fakeVariables: Record<
    string,
    {
      data: DataSchemaValue;
      dataTypeID: DataTypeId;
    }
  > = {};
  vars.fields.forEach((fields, i) => {
    const name = `data_customer_input_${i}`;

    fakeMutations.push(
      template
        .replace("[CUSTOMER_INPUT]", `$${name}`)
        .replace("data_customer_id", `data_customer_${i}`),
    );

    fakeVariables[name] = {
      data: { fields },
      dataTypeID: vars.dataTypeId,
    };

    fakeTypes.push(`$${name}: CreateCustomerInput!`);
  });

  const query = `
    mutation CreateCustomers(${fakeTypes.join(", ")}) {
        ${fakeMutations.join("\n")}
    }
  `;

  return client
    .mutate({
      mutation: gql(`${print(customerFragment)}${query}`),
      variables: fakeVariables,
    })
    .then(E.map(() => undefined));
};

export const updateCustomer = (
  client: Client,
  vars: {
    id: CustomerId;
    dataTypeId: DataTypeId;
    fields: DataSchemaValue;
  },
): Promise<QueryResponse<Customer>> => {
  const mutation = gql`
    ${customerFragment}
    mutation UpdateCustomer($id: ID!, $input: UpdateCustomerInput!) {
      updateCustomer(id: $id, input: $input) {
        ...CustomerFragment
      }
    }
  `;

  return client
    .mutate<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
      mutation,
      variables: {
        id: vars.id,
        input: {
          dataTypeID: vars.dataTypeId,
          data: vars.fields,
        },
      },
    })
    .then(
      flow(
        E.map((node) =>
          customerFragmentToCustomer(
            node.updateCustomer as CustomerFragmentFragment,
          ),
        ),
      ),
    );
};

export const deleteCustomers = (
  client: Client,
  ids: CustomerId[],
): Promise<E.Either<DsError, void>> => {
  const template = `
    data_customer_id: deleteCustomer(id: "[CUSTOMER_ID]") {
      deletedID
    }
  `;
  const fakeMutation = ids.map((id, i) =>
    template
      .replace("[CUSTOMER_ID]", id)
      .replace("data_customer_id", `data_customer_${i}`),
  );
  const query = `
        mutation DeleteCustomers {
            ${fakeMutation.join("\n")}
        }
      `;

  return client
    .mutate({
      mutation: gql(query),
    })
    .then(E.map(() => undefined));
};

import { Box } from "@mui/material";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import {
  ChevronDown,
  Text as IconTypeText,
  Hash as IconTypeNumber,
  QuestionCircle as IconTypeBoolean,
  Calendar as IconTypeDate,
} from "icons";
import React, { useCallback } from "react";
import { BoxProps } from "@mui/system/Box/Box";
import { useTranslation } from "i18n";
import { Sxs } from "../../../../../../types/styles";
import { sxDragHandle } from "../../../../common/sxs";

export namespace Header {
  export type Props = {
    name: string;
    type: string;
    dndHandleProps: BoxProps;

    expanded: boolean;
    onExpandedChange: (expanded: boolean) => void;

    editing:
      | undefined
      | {
          selected: boolean;
          onSelectedChange: (selected: boolean) => void;
        };
  };
}

export const Header = ({
  name,
  type,
  expanded,
  onExpandedChange,
  dndHandleProps,
  editing,
}: Header.Props) => {
  const toggleExpanded = useCallback<React.MouseEventHandler>(
    (event) => {
      event.preventDefault();
      onExpandedChange(!expanded);
    },
    [expanded, onExpandedChange],
  );

  return (
    <Box sx={sxs.root} className={expanded ? classNames.expanded : undefined}>
      <Box sx={sxs.dragHandle} {...dndHandleProps}>
        <DragIndicatorRoundedIcon />
      </Box>
      <Typography
        variant="body2"
        sx={sxs.details}
        component="a"
        href="#"
        onClick={toggleExpanded}
      >
        {name}
        <br />
        <Box component="span" sx={sxs.type}>
          <Type type={type} />
        </Box>
      </Typography>
      {editing && (
        <Box sx={sxs.editMode}>
          <Checkbox
            checked={editing.selected}
            onChange={(e, selected) => editing.onSelectedChange(selected)}
          />
        </Box>
      )}
      <Box
        sx={sxs.openIndicator}
        component="a"
        href="#"
        onClick={toggleExpanded}
      >
        <ChevronDown />
      </Box>
    </Box>
  );
};

const Type: React.FC<{ type: string }> = ({ type }) => {
  const { t } = useTranslation();

  let name = type,
    Icon: React.FC<{ sx: Sxs[string] }> | undefined;

  switch (type) {
    case "string": {
      name = t("Text");
      Icon = IconTypeText;
      break;
    }
    case "number": {
      name = t("Number");
      Icon = IconTypeNumber;
      break;
    }
    case "boolean": {
      name = t("Boolean");
      Icon = IconTypeBoolean;
      break;
    }
    case "date": {
      name = t("Date");
      Icon = IconTypeDate;
      break;
    }
  }

  return (
    <>
      {!!Icon && <Icon sx={sxs.typeIcon} />}
      {name}
    </>
  );
};

const classNames = {
  expanded: "expanded",
} as const;

const sxControlIcon: Sxs[string] = (theme) => ({
  color: theme.palette.action.active,

  "& > :first-of-type": {
    verticalAlign: "middle",
  },
});

const sxs = {
  root: (theme) => ({
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3, 4),

    display: "flex",
    gap: 3,
    alignItems: "center",

    borderRadius: 2,
    [`&.${classNames.expanded}`]: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  }),
  dragHandle: (theme) => ({
    ...sxControlIcon(theme),
    ...sxDragHandle(theme),
  }),
  details: (theme) => ({
    flex: 1,
    cursor: "pointer",
    textDecoration: "none",
  }),
  type: (theme) => ({
    color: theme.palette.text.secondary,

    display: "flex",
    gap: 1,
    alignItems: "center",
  }),
  typeIcon: (theme) => ({
    color: theme.palette.action.active,
    fontSize: theme.spacing(4),
  }),
  openIndicator: (theme) => ({
    ...sxControlIcon(theme),

    svg: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  }),
  editMode: (theme) => ({
    ...sxControlIcon(theme),

    svg: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  }),
  expanded: (theme) => ({
    backgroundColor: theme.palette.surface.primary,
    pt: 4,
    borderRadius: theme.spacing(0, 0, 2, 2),
  }),
} satisfies Sxs;

import { Header as Header_ } from "ui/packages/json-schema-form-builder/Right/Header";
import { useContext, useMemo } from "react";
import { useBehaviorValue, useBehaviorValueRef } from "react-rx/behaviorValue";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Value } from "types/src/jsonSchema/value";
import { UiState } from "../types/uiState";
import { ClipboardContext } from "../contexts/Clipboard";
import { schemaToCode } from "../utils/format";

export namespace Header {
  export type Props = {
    value$: BehaviorValue<Value>;
    ui$: BehaviorValue<UiState>;
    onUiChange: (ui: Partial<UiState>) => void;
  };
}

export const Header = ({ value$, ui$, onUiChange }: Header.Props) => {
  const previewMode = useBehaviorValue(
    useMemo(() => ui$.map((ui) => ui.previewMode), [ui$]),
  );
  const hasFormValues = useBehaviorValue(
    useMemo(
      () => ui$.map((ui) => !!Object.keys(ui.previewFormValues).length),
      [ui$],
    ),
  );
  const { copyToClipboard } = useContext(ClipboardContext);

  const schemaRef = useBehaviorValueRef(
    useMemo(() => value$.map(({ dataSchema }) => dataSchema), [value$]),
  );

  return (
    <>
      <Header_
        previewMode={previewMode}
        onPreviewModeChange={(previewMode) => onUiChange({ previewMode })}
        onCopy={() => copyToClipboard(schemaToCode(schemaRef.current))}
        onReset={
          hasFormValues
            ? () => onUiChange({ previewFormValues: {} })
            : undefined
        }
      />
    </>
  );
};

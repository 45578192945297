import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import * as O from "fp-ts/Option";
import { Client, isNotFoundError } from "ds";
import * as Rx from "rxjs";

import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { getDataType, getDataTypes } from "ds/DataTypes";
import { Typed } from "utils/Typed";
import { silentUnreachableError } from "utils/exceptions";
import * as Arr from "fp-ts/Array";
import * as Obj from "utils/object";
import * as Str from "fp-ts/string";
import { Eq } from "fp-ts/Eq";
import { strictGuard } from "utils/strictGuard";
import { RepositoryId } from "types/src/Repositories/Repository";
import {
  deleteRepositoryMovements,
  executeRepositoryMovements,
  getRepositoryMovements,
  GetRepositoryMovementsVars,
} from "ds/RepositoryMovements";
import { RepositoryMovementId } from "types/src/RepositoryMovements/RepositoryMovement";
import { ISODate } from "types/src/date/ISODate";
import { NoEmptyString } from "types/src/NoEmptyString";
import { Tuple } from "types/src/Tuple";
import { getRepositories } from "ds/Repositories";
import { CollectionId } from "types/src/Collections/Collection";
import { Epic } from "../../../../../../../../types/RootEpic";
import { ListingWithDataType } from "../../../../../../../../generic-states/ListingWithDataType";
import { Loading } from "../../../../../../../../generic-states/Loading";

const prefix = "Ready:DataManager:RepositoryMovements:Listing";

const createExecuteActions = Typed.builder
  .add("executeItem", (id: RepositoryMovementId) => id)
  .add("executeBulk")
  .add("executeConfirm")
  .add("executeDecline")
  .add("executeSuccess", (ids: RepositoryMovementId[]) => ids)
  .add("executeFail", (ids: RepositoryMovementId[]) => ids)
  .finish();

const createListingState = () => {
  const executeActions = createExecuteActions(prefix);
  const itemsState = Loading.createState<
    Array<{ id: RepositoryId; name: string }>,
    string | undefined
  >(`${prefix}:items`, { equals: (a, b) => a === b });
  const fromState = Loading.createState<
    Array<{ id: RepositoryId; name: string }>,
    string | undefined
  >(`${prefix}:from`, { equals: (a, b) => a === b });
  const toState = Loading.createState<
    Array<{ id: RepositoryId; name: string }>,
    string | undefined
  >(`${prefix}:to`, { equals: (a, b) => a === b });
  const state = ListingWithDataType.createState<
    RepositoryMovementsListing.Filter,
    RepositoryMovementsListing.Item,
    "createdAt" | "updatedAt" | "executed" | "executedAt" | "handler",
    {
      id: DataTypeId;
      executing: Record<
        RepositoryMovementId,
        undefined | "confirmation" | "executing"
      >;
      _filters: {
        items: Loading.GetState<typeof itemsState>;
        from: Loading.GetState<typeof fromState>;
        to: Loading.GetState<typeof toState>;
      };
    }
  >(prefix, {
    defaultFilters: {},
  });

  type St = ListingWithDataType.GetState<typeof state>;
  type Ac =
    | ListingWithDataType.GetActions<typeof state>
    | Loading.GetActions<typeof itemsState>
    | Loading.GetActions<typeof fromState>
    | Loading.GetActions<typeof toState>
    | Typed.GetTypes<typeof executeActions>;
  type Ex = ListingWithDataType.GetExits<typeof state>;

  const reducer = (s: St, a: Ac): E.Either<Ex, St> => {
    if (executeActions.executeItem.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((s) => !s.payload.removing[a.payload]),
        O.filter((s) => !s.payload.executing[a.payload]),
        O.chain((s) => {
          const item = s.payload.items.find((v) => v.id === a.payload);

          if (!item) return O.none;

          return O.some({
            ...s,
            payload: {
              ...s.payload,
              executing: {
                ...s.payload.executing,
                [a.payload]: "confirmation",
              },
            },
          } as typeof s);
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (executeActions.executeBulk.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((s) => !!s.payload.selected.length),
        O.chain((s) => {
          const ids = Arr.intersection<RepositoryMovementId>({
            equals: (a, b) => a === b,
          })(
            s.payload.selected,
            s.payload.items.filter((i) => !i.executed).map((i) => i.id),
          ).filter((id) => !s.payload.executing[id]);
          if (!ids.length) return O.none;

          return O.some({
            ...s,
            payload: {
              ...s.payload,
              executing: {
                ...s.payload.executing,
                ...ids.reduce(
                  (acc: Record<RepositoryMovementId, "confirmation">, id) => {
                    acc[id] = "confirmation";
                    return acc;
                  },
                  {},
                ),
              },
            },
          });
        }),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (executeActions.executeConfirm.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((v) =>
          Obj.some((v) => v === "confirmation", v.payload.executing),
        ),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                executing: Obj.map(
                  (v) => (v === "confirmation" ? "executing" : v),
                  s.payload.executing,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (executeActions.executeDecline.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((v) =>
          Obj.some((v) => v === "confirmation", v.payload.executing),
        ),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                executing: Obj.filter(
                  (v) => v !== "confirmation",
                  s.payload.executing,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (executeActions.executeSuccess.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((v) =>
          Obj.some(
            (v, k) => v === "executing" && a.payload.includes(k),
            v.payload.executing,
          ),
        ),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                items: s.payload.items.map((v) =>
                  a.payload.includes(v.id) ? { ...v, executed: true } : v,
                ),
                executing: Obj.filter(
                  (_, k) => a.payload.includes(k),
                  s.payload.executing,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (executeActions.executeFail.is(a)) {
      return Fp.pipe(
        O.of(s),
        O.filter(state.states.ready.is),
        O.filter((v) =>
          Obj.some(
            (v, k) => v === "executing" && a.payload.includes(k),
            v.payload.executing,
          ),
        ),
        O.map(
          (s) =>
            ({
              ...s,
              payload: {
                ...s.payload,
                executing: Obj.filter(
                  (_, k) => a.payload.includes(k),
                  s.payload.executing,
                ),
              },
            }) as typeof s,
        ),
        O.getOrElse(() => s),
        E.right,
      );
    }

    if (itemsState.isAction(a)) {
      const st = itemsState.reducer(s.payload._filters.items, a);

      return E.right({
        ...s,
        payload: {
          ...s.payload,
          _filters: {
            ...s.payload._filters,
            items: Fp.pipe(
              st,
              E.getOrElseW(() => s.payload._filters.items),
            ),
          },
        },
      } as St);
    }

    if (
      fromState.isAction(
        // @ts-expect-error, fix later
        a,
      )
    ) {
      const st = fromState.reducer(s.payload._filters.from, a);

      return E.right({
        ...s,
        payload: {
          ...s.payload,
          _filters: {
            ...s.payload._filters,
            from: Fp.pipe(
              st,
              E.getOrElseW(() => s.payload._filters.from),
            ),
          },
        },
      } as St);
    }

    if (
      toState.isAction(
        // @ts-expect-error, fix later
        a,
      )
    ) {
      const st = toState.reducer(s.payload._filters.to, a);

      return E.right({
        ...s,
        payload: {
          ...s.payload,
          _filters: {
            ...s.payload._filters,
            to: Fp.pipe(
              st,
              E.getOrElseW(() => s.payload._filters.to),
            ),
          },
        },
      } as St);
    }

    if (state.isAction(a)) return state.reducer(s, a);

    silentUnreachableError(a);
    return E.right(s);
  };

  const epic: Epic<Ac, St, RepositoryMovementsListing.Deps> = (
    state$,
    deps,
  ) => {
    const diff = Arr.difference(Str.Eq as Eq<RepositoryMovementId>);
    const listing$ = state.epic(state$, {
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(getRepositoryMovements(client, getFetchVars(s))),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: { in: [DataTypeEntity.RepositoryMovement] },
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;

                    return E.right({
                      items: v.items.right,
                      dataTypes: Fp.pipe(
                        v.dataTypes,
                        E.map((v) => v.items),
                        E.getOrElse(() => [] as DataType[]),
                      ),
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      id: i.id,
                      createdAt: i.createdAt,
                      updatedAt: O.fromNullable(i.updatedAt),
                      executedAt: O.fromNullable(i.executedAt),
                      executed: i.executed,
                      repository: i.repository,
                      dataType: O.fromNullable(
                        r.dataTypes.find((v) => v.id === i.dataTypeId),
                      ),
                      orderId: i.orderId,
                      collectionId: i.collectionId,
                      handler: i.handler,
                      from: O.fromNullable(i.from),
                      to: i.to,
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(deleteRepositoryMovements(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
      fetchDataType: (id) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            return Rx.from(getDataType(client, id)).pipe(
              Rx.map(E.map(O.some)),
              Rx.map(
                E.orElse((e) => {
                  if (isNotFoundError(e)) return E.right(O.none);

                  return E.left(e);
                }),
              ),
            );
          }),
        );
      },
    });

    const execute$ = deps.pyckAdminClient$.pipe(
      Rx.switchMap((client) => {
        return state$.pipe(
          Rx.filter(state.states.ready.is),
          Rx.map((s) =>
            Obj.entries(s.payload.executing)
              .filter(([, v]) => v === "executing")
              .map(([k]) => k),
          ),
          Rx.scan(
            ([pendingItems], removingItems) => {
              return [removingItems, diff(pendingItems, removingItems)] as [
                RepositoryMovementId[],
                RepositoryMovementId[],
              ];
            },
            [[], []] as [RepositoryMovementId[], RepositoryMovementId[]],
          ),
          Rx.map(([, toRemove]) => toRemove),
          Rx.filter((i) => i.length > 0),
          Rx.mergeMap((ids) => {
            return Rx.from(executeRepositoryMovements(client, ids)).pipe(
              Rx.map(E.map(() => ids)),
              Rx.map(E.mapLeft(() => ids)),
              Rx.map(E.map(executeActions.executeSuccess.create)),
              Rx.map(E.mapLeft(state.actions.removeFail.create)),
              Rx.map(E.getOrElseW((v) => v)),
            );
          }),
        );
      }),
    );

    const items$ = itemsState.epic(
      state$.pipe(Rx.map((s) => s.payload._filters.items)),
      {
        get: (q) => {
          return deps.pyckAdminClient$.pipe(
            Rx.switchMap((client) => {
              return Rx.from(
                getRepositories(client, {
                  where: { or: [{ name: { containsFold: q } }] },
                }),
              );
            }),
            Rx.map(
              E.map((r) => {
                return r.items.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              }),
            ),
          );
        },
      },
    );
    const from$ = fromState.epic(
      state$.pipe(Rx.map((s) => s.payload._filters.from)),
      {
        get: (q) => {
          return deps.pyckAdminClient$.pipe(
            Rx.switchMap((client) => {
              return Rx.from(
                getRepositories(client, {
                  where: {
                    or: [{ name: { containsFold: q } }],
                  },
                }),
              );
            }),
            Rx.map(
              E.map((r) => {
                return r.items.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              }),
            ),
          );
        },
      },
    );
    const to$ = toState.epic(
      state$.pipe(Rx.map((s) => s.payload._filters.to)),
      {
        get: (q) => {
          return deps.pyckAdminClient$.pipe(
            Rx.switchMap((client) => {
              return Rx.from(
                getRepositories(client, {
                  where: {
                    or: [{ name: { containsFold: q } }],
                  },
                }),
              );
            }),
            Rx.map(
              E.map((r) => {
                return r.items.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              }),
            ),
          );
        },
      },
    );

    return Rx.merge(listing$, execute$, items$, from$, to$);
  };

  return {
    ...state,
    actions: { ...state.actions, ...executeActions },
    isAction: strictGuard(
      (a: Ac): a is Ac =>
        state.isAction(a) || Typed.getGuard(executeActions)(a),
    ),
    reducer,
    epic,
    init: (id: DataTypeId): St =>
      state.init({
        id: id,
        executing: {},
        _filters: {
          items: itemsState.init(undefined),
          from: fromState.init(undefined),
          to: toState.init(undefined),
        },
      }),
    subStates: {
      ...state.subStates,
      items: itemsState,
      from: fromState,
      to: toState,
    },
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetRepositoryMovementsVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetRepositoryMovementsVars["where"] = {
      dataType: {
        in: [s.payload.id],
        isNil: Fp.pipe(
          fields.status,
          O.fromNullable,
          O.map((v) => v === "orphan"),
          O.toUndefined,
        ),
      },
      and: [
        {
          createdAt: {
            gte: fields.createdAt?.[0],
            lte: fields.createdAt?.[1],
          },
          updatedAt: {
            gte: fields.updatedAt?.[0],
            lte: fields.updatedAt?.[1],
          },
        },
      ],
      or: [
        {
          id: {
            eq: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.map(RepositoryMovementId.fromString),
              O.toUndefined,
            ),
          },
        },
        {
          data: {
            contains: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.chain(NoEmptyString.fromString),
              O.map(Tuple.create("")),
              O.toUndefined,
            ),
          },
        },
      ],
      executed: Fp.pipe(
        fields.executed,
        O.fromNullable,
        O.map((v) => v === "executed"),
        O.toUndefined,
      ),
      hasRepositoryWith: [{ id: { in: fields.repositories } }],
      hasFromWith: [{ id: { in: fields.from } }],
      hasToWith: [{ id: { in: fields.to } }],
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
};

export namespace RepositoryMovementsListing {
  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: (
      id: DataTypeId,
    ) => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (id: DataTypeId, v: Record<string, boolean>) => void;
  }

  export type Filter = Partial<{
    executed: "executed" | "un-executed";
    createdAt: DateRange;
    updatedAt: DateRange;
    search: string;
    status: "active" | "orphan";
    repositories: RepositoryId[];
    from: RepositoryId[];
    to: RepositoryId[];
  }>;

  export interface Item {
    id: RepositoryMovementId;
    orderId: string;
    collectionId: CollectionId | undefined;
    createdAt: ISODate;
    updatedAt: O.Option<ISODate>;
    from: O.Option<{
      id: RepositoryId;
      name: string;
    }>;
    to: {
      id: RepositoryId;
      name: string;
    };
    dataType: O.Option<{ id: DataTypeId; name: string }>;
    repository: { id: RepositoryId; name: string };
    handler: string;
    executed: boolean;
    executedAt: O.Option<ISODate>;
  }

  export const instance = createListingState();

  export type State = ListingWithDataType.GetState<typeof instance>;
  export type Actions = ListingWithDataType.GetActions<typeof instance>;
  export type Exits = ListingWithDataType.GetExits<typeof instance>;
}

import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Control as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor/elementTypes/Control";
import { useBehaviorValue, useBehaviorValueRef } from "react-rx/behaviorValue";
import { useContext, useMemo } from "react";
import { UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { ControlTypeAndSchemas } from "../../../types/control";
import { getId } from "../../../utils/uiSchema";
import { scopeToName } from "../../../utils/uiSchema/scope";
import { useElementExpanded } from "../../../hooks/element/expanded";
import { ValueContext } from "../../../contexts/Value";
import { ControlType } from "../../../types/control/type";
import { useControlReset } from "../../../hooks/control/reset";
import { useControlDelete } from "../../../hooks/control/delete";
import { UiContext } from "../../../contexts/Ui";
import { Content } from "./Content";
import { useEditing } from "./hooks/useEditing";

export namespace Inner {
  export type Props = {
    typeAndSchemas$: BehaviorValue<ControlTypeAndSchemas<ControlType>>;
  };
}

const elementType = UiSchemaElementType.control;

export const Inner = ({ typeAndSchemas$ }: Inner.Props) => {
  const controlUiSchema$ = useMemo(
    () => typeAndSchemas$.map((v) => v.uiSchema),
    [typeAndSchemas$],
  );
  const id = useBehaviorValue(
    useMemo(() => controlUiSchema$.map(getId), [controlUiSchema$]),
  );
  const name = useBehaviorValue(
    useMemo(
      () => controlUiSchema$.map(({ scope }) => scopeToName(scope)),
      [controlUiSchema$],
    ),
  );

  const type = useBehaviorValue(
    useMemo(
      () => typeAndSchemas$.map(({ type: { type } }) => type),
      [typeAndSchemas$],
    ),
  );

  const editing = useEditing({ id });
  const { expanded, onExpandedChange } = useElementExpanded({ id });

  const { value$, onValueChange } = useContext(ValueContext);
  const valueRef = useBehaviorValueRef(value$);

  const { ui$, onUiChange } = useContext(UiContext);
  const uiRef = useBehaviorValueRef(ui$);

  const onReset = useControlReset({
    id,
    typeRef: useBehaviorValueRef(
      useMemo(() => typeAndSchemas$.map(({ type }) => type), [typeAndSchemas$]),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any,
    valueRef,
    onValueChange,
  });
  const onDelete = useControlDelete({
    id,
    valueRef,
    onValueChange,
    uiRef,
    onUiChange,
  });

  return (
    <UI
      {...uiStaticProps}
      {...{
        id,
        name,
        type,
        editing,
        expanded,
        onExpandedChange,
        onReset,
        onDelete,
      }}
    >
      {expanded && <Content {...{ typeAndSchemas$ }} />}
    </UI>
  );
};

const uiStaticProps: Pick<UI.Props, "dndMeta"> = {
  dndMeta: { elementType },
};

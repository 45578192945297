import { Tabs as MuiTabs, Tab } from "@mui/material";
import { useTranslation } from "i18n";
import { Sxs } from "../../../../../../../types/styles";

export function Tabs<CustomTabId extends string>({
  customTabs,
  id,
  onChange,
}: Tabs.Props<CustomTabId>) {
  const { t } = useTranslation();

  return (
    <MuiTabs
      sx={sxs.root}
      value={id}
      onChange={(e, id: Tabs.TabId<CustomTabId>) => onChange(id)}
    >
      <Tab value={Tabs.tabIdGeneral} label={t("General")} />
      {customTabs.map(({ id: value, label }) => (
        <Tab key={value} {...{ value, label }} />
      ))}
    </MuiTabs>
  );
}

export namespace Tabs {
  export type GeneralTabId = "general";
  export const tabIdGeneral: GeneralTabId = "general";

  export type TabId<CustomTabId extends string = string> =
    | GeneralTabId
    | CustomTabId;
  export type CustomTab = { id: string; label: string };

  export type Props<CustomTabId extends string> = {
    customTabs: CustomTab[];
    id: string;
    onChange: (id: TabId<CustomTabId>) => void;
  };
}

const sxs = {
  root: (theme) => ({
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(4),
  }),
} satisfies Sxs;

import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as Num from "fp-ts/number";
import { InputAdornment } from "@mui/material";
import { ChevronVertical } from "icons";

interface BaseInputProps {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  label?: string;
  error?: boolean;
  helpText?: string;
}

export namespace Input {
  export interface Props extends BaseInputProps {
    value?: string;
    onChange: (value: string) => void;
  }
}

export function Input(p: Input.Props) {
  return (
    <TextField
      placeholder={p.placeholder}
      disabled={p.disabled}
      label={p.label}
      helperText={p.helpText}
      error={p.error}
      value={p.value}
      slotProps={{
        input: {
          readOnly: p.readOnly,
        },
      }}
      onChange={(e) => p.onChange?.(e.target.value)}
      size={"small"}
    />
  );
}

export namespace NumberInput {
  export interface Props extends BaseInputProps {
    min?: number;
    max?: number;
    value?: number;
    onChange: (value: number | undefined) => void;
  }
}

export function NumberInput(p: NumberInput.Props) {
  return (
    <TextField
      className={p.className}
      placeholder={p.placeholder}
      disabled={p.disabled}
      label={p.label}
      helperText={p.helpText}
      error={p.error}
      value={p.value}
      autoComplete="off"
      slotProps={{
        htmlInput: {
          min: p.min,
          max: p.max,
        },
        input: {
          readOnly: p.readOnly,
          endAdornment: (
            <InputAdornment
              position="end"
              sx={(p) => ({
                width: p.spacing(4),
                height: p.spacing(4),
                margin: 0,
              })}
            >
              <ChevronVertical />
            </InputAdornment>
          ),
        },
      }}
      onChange={Fp.flow(
        (e) => e.target.value,
        Number,
        O.fromPredicate(Num.isNumber),
        O.toUndefined,
        p.onChange,
      )}
      type="number"
      size="small"
      sx={{
        "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button":
          {
            "-webkit-appearance": "none",
            margin: 0,
          },
      }}
    />
  );
}

export namespace Search {
  export interface Props extends Omit<Input.Props, "helpText" | "error"> {}
}

export function Search(p: Search.Props) {
  return (
    <OutlinedInput
      placeholder={p.placeholder}
      disabled={p.disabled}
      label={p.label}
      value={p.value}
      slotProps={{
        input: {
          readOnly: p.readOnly,
        },
      }}
      onChange={(e) => p.onChange?.(e.target.value)}
      endAdornment={<SearchRoundedIcon />}
      sx={{ flex: 1 }}
      size={"small"}
    />
  );
}

import * as Fp from "fp-ts/function";
import * as Arr from "fp-ts/Array";
import { InboundItemId } from "types/src/Inbounds/InboundItem";
import { NewItemId } from "./types/NewItemId";

export const nextItemId: (items: NewItemId[]) => NewItemId = Fp.flow(
  Arr.map(NewItemId.getCount),
  (vs) => Math.max(1, ...vs),
  (v) => v + 1,
  NewItemId.fromNumber,
);

export const isItemId = (id: NewItemId | InboundItemId): id is NewItemId =>
  id.startsWith("new-item-");

import { Header as Header_ } from "ui/packages/json-schema-form-builder/Left/Header";
import { useCallback, useMemo } from "react";
import { useBehaviorValue, useBehaviorValueRef } from "react-rx/behaviorValue";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Value } from "types/src/jsonSchema/value";
import * as Fp from "fp-ts/function";
import { UiState } from "../types/uiState";
import { defaultUiStateEditing } from "../utils/ui/state";
import { addControl } from "../utils/value/addControl";
import { useClearPositionCaches } from "../hooks/useClearPositionCaches";
import { fixValue } from "../utils/value/fix";

export namespace Header {
  export type Props = {
    value$: BehaviorValue<Value>;
    onValueChange: (value: Value) => void;
    ui$: BehaviorValue<UiState>;
    onUiChange: (ui: Partial<UiState>) => void;
  };
}

export const Header = ({
  value$,
  onValueChange: _onValueChange,
  ui$,
  onUiChange,
}: Header.Props) => {
  const valueRef = useBehaviorValueRef(value$);
  const isEditMode = useBehaviorValue(
    useMemo(() => ui$.map(({ editing }) => editing !== undefined), [ui$]),
  );
  const onValueChange = useClearPositionCaches(_onValueChange);
  const onAddField = useCallback<Header_.Props["onAddField"]>(() => {
    if (valueRef.current) {
      onValueChange(Fp.pipe(valueRef.current, addControl, fixValue));
    }
  }, [onValueChange, valueRef]);

  return (
    <>
      <Header_
        {...{ isEditMode, onAddField }}
        onEditModeChange={(isEditMode) =>
          onUiChange({
            editing: isEditMode ? defaultUiStateEditing() : undefined,
          })
        }
      />
    </>
  );
};

import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import * as Fp from "fp-ts/function";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import * as Exits from "./types/Exits";
import { schemaFieldsState } from "./utils";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          dataTypeId: s.payload.dataTypeId,
          schema: Fp.pipe(
            schemaFieldsState.reducer(s.payload.schema, a),
            E.getOrElse(() => s.payload.schema),
          ),
          submitted: s.payload.submitted,
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:Suppliers:Create:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(
          State.loadError({
            dataTypeId: s.payload.dataTypeId,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Create:LoadSuccess": {
      if (State.isLoading(s)) {
        return E.right(
          State.ready({
            dataTypeId: s.payload.dataTypeId,
            submitted: false,
            schema: schemaFieldsState.states.init.create({
              schema: a.payload.schema,
              uiSchema: a.payload.ui,
              values: {},
            }),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Create:Submit": {
      if (State.isReady(s)) {
        const schema = Fp.pipe(
          schemaFieldsState.reducer(
            s.payload.schema,
            schemaFieldsState.actions.submit.create(),
          ),
          E.getOrElse(() => s.payload.schema),
        );
        if (schemaFieldsState.states.valid.is(schema)) {
          return E.right(
            State.saving({
              dataTypeId: s.payload.dataTypeId,
              schema: schema,
              submitted: true,
            }),
          );
        }

        return E.right(
          State.ready({
            dataTypeId: s.payload.dataTypeId,
            schema: schema,
            submitted: true,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Create:SaveError": {
      if (State.isSaving(s)) {
        return E.right(
          State.ready({
            dataTypeId: s.payload.dataTypeId,
            schema: s.payload.schema,
            submitted: s.payload.submitted,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Suppliers:Create:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.left(Exits.exits.created.create(a.payload));
      }

      return E.right(s);
    }
    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}

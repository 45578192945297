import { DataTypeId } from "types/src/DataType/DataType";
import { NoEmptyArr } from "types/src/NoEmptyArr";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import * as PickingOrderItem from "../../PickingOrderItem/types/State";

export type State<Id extends string> = Ready<Id> | Valid<Id>;

export const isState = <Id extends string>(p: string) => {
  const _isReady = isReady<Id>(p);
  const _isValid = isValid<Id>(p);

  return strictGuard((s: State<Id>): s is State<Id> => {
    if (_isReady(s) || _isValid(s)) return true;

    silentUnreachableError(s);
    return false;
  });
};

// region Ready
export interface ReadyPayload<Id extends string> {
  dataTypes: NoEmptyArr<{
    title: string;
    id: DataTypeId;
    schema: DataSchema;
    ui: UiSchema;
    isDefault: boolean;
  }>;
  items: Record<Id, PickingOrderItem.State>;
}

export interface Ready<Id extends string> {
  type: "Ready";
  payload: ReadyPayload<Id>;
}

export const ready =
  <Id extends string>(p: string) =>
  (payload: Ready<Id>["payload"]): Ready<Id> => ({
    type: "Ready",
    payload,
  });

export const isReady =
  <Id extends string>(p: string) =>
  (s: State<Id>): s is Ready<Id> =>
    s.type === "Ready";
// endregion

// region Valid
export interface ValidPayload<Id extends string> extends ReadyPayload<Id> {
  items: Record<Id, PickingOrderItem.Valid>;
}

export interface Valid<Id extends string> {
  type: "Valid";
  payload: ValidPayload<Id>;
}

export const valid =
  <Id extends string>(p: string) =>
  (payload: Valid<Id>["payload"]): Valid<Id> => ({
    type: "Valid",
    payload,
  });

export const isValid =
  <Id extends string>(p: string) =>
  (s: State<Id>): s is Valid<Id> =>
    s.type === "Valid";
// endregion

export const init = <Id extends string>(p: string) => ready<Id>(p);

import { silentUnreachableError } from "utils/exceptions";
import * as FormValue from "types/src/FormValue";
import { isNoEmptyString } from "types/src/NoEmptyString";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import * as Fp from "fp-ts/function";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import * as Exits from "./types/Exits";
import { parentSearchState, schemaFieldsState } from "./utils";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof Exits.exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s) || State.isSubmitted(s)) {
      return E.right({
        ...s,
        payload: {
          ...s.payload,
          schema: Fp.pipe(
            schemaFieldsState.reducer(s.payload.schema, a),
            E.getOrElse(() => s.payload.schema),
          ),
        },
      } as State.State);
    }

    return E.right(s);
  }

  if (parentSearchState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          ...s.payload,
          parent: parentSearchState.reducer(s.payload.parent, a),
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:Repositories:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(State.loadError(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        const repositories = a.payload.repositories.filter(
          (v) => v.id !== a.payload.item.id,
        );

        return E.right(
          State.ready({
            id: a.payload.item.id,
            dataTypeId: a.payload.item.dataTypeId,
            name: FormValue.valid(a.payload.item.name),
            type: FormValue.valid(a.payload.item.type),
            isVirtual: a.payload.item.isVirtual,
            parent: a.payload.item.parentId
              ? parentSearchState.states.selected.create({
                  query: O.none,
                  items: repositories,
                  item: repositories.find(
                    (r) => r.id === a.payload.item.parentId,
                  ),
                })
              : parentSearchState.states.idle.create({
                  query: O.none,
                  items: repositories,
                }),
            schema: schemaFieldsState.states.init.create({
              schema: a.payload.schema,
              uiSchema: a.payload.ui,
              values: a.payload.item.fields,
            }),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Edit:SetName": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        return E.right(
          State.ready({
            ...s.payload,
            name: isNoEmptyString(a.payload)
              ? FormValue.valid(a.payload)
              : FormValue.invalid("required" as const, a.payload),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Edit:SetType": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        return E.right(
          State.ready({
            ...s.payload,
            type: FormValue.valid(a.payload),
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Edit:Submit": {
      if (State.isReady(s) || State.isSubmitted(s)) {
        const name = isNoEmptyString(s.payload.name.value)
          ? FormValue.valid(s.payload.name.value)
          : FormValue.invalid("required" as const, s.payload.name.value);
        const type = s.payload.type.value
          ? FormValue.valid(s.payload.type.value)
          : FormValue.invalid("required" as const, s.payload.type.value);
        const schema = Fp.pipe(
          schemaFieldsState.reducer(
            s.payload.schema,
            schemaFieldsState.actions.submit.create(),
          ),
          E.getOrElse(() => s.payload.schema),
        );

        if (
          FormValue.isValid(name) &&
          FormValue.isValid(type) &&
          schemaFieldsState.states.valid.is(schema)
        ) {
          return E.right(
            State.saving({
              ...s.payload,
              name: name,
              type: type,
              schema: schema,
            }),
          );
        } else {
          return E.right(
            State.ready({
              ...s.payload,
              name: name,
              type: type,
              schema: schema,
            }),
          );
        }
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Edit:SaveError": {
      if (State.isSaving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.left(Exits.exits.saved.create(a.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Repositories:Edit:Remove": {
      if (State.isReady(s) || State.isSaving(s)) {
        return E.right(State.removeConfirmation(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Repositories:Edit:RemoveDecline": {
      if (State.isRemoveConfirmation(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Repositories:Edit:RemoveConfirm": {
      if (State.isRemoveConfirmation(s)) {
        return E.right(State.removing(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Repositories:Edit:RemoveFail": {
      if (State.isRemoving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Repositories:Edit:RemoveSuccess": {
      if (State.isRemoving(s)) {
        return E.left(Exits.exits.removed.create(s.payload.id));
      }

      return E.right(s);
    }

    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}

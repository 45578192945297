import { Value } from "types/src/jsonSchema/value";
import {
  UiSchemaElement,
  UiSchemaElementPath,
} from "types/src/jsonSchema/uiSchema";
import * as R from "rambda";
import { makeControl } from "../control/make";
import { controlInsertAtPath } from "../control/insertAtPath";

export const addControl = (
  value: Value,
  {
    uiParentPath = [],
    at = "start",
  }: {
    uiParentPath?: UiSchemaElementPath;
    at?: "start" | "end";
  } = {},
): Value =>
  controlInsertAtPath({
    control: makeControl({ dataSchema: value.dataSchema }),
    uiPath: [
      ...uiParentPath,
      "elements",
      at === "start"
        ? 0
        : lastIndex(value.uiSchema, [...uiParentPath, "elements"]),
    ],
    value,
    before: at === "start",
  });

const lastIndex = (
  uiSchema: Value["uiSchema"],
  elementsPath: UiSchemaElementPath,
): number =>
  (R.path<UiSchemaElement[]>(elementsPath, uiSchema)?.length ?? 1) - 1;

import { createContext } from "react";
import * as Rx from "rxjs";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Value } from "types/src/jsonSchema/value";
import { UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { JsonSchemaFormBuilder } from "../index";

export namespace ValueContext {
  export type Value = Pick<
    JsonSchemaFormBuilder.Props,
    "value$" | "onValueChange"
  >;
}

export const defaultValue: ValueContext.Value = {
  value$: new BehaviorValue<Value>(
    {
      dataSchema: {},
      uiSchema: {
        type: UiSchemaElementType.verticalLayout,
        elements: [],
      },
    },
    Rx.NEVER,
  ),
  onValueChange: () => void 0,
};

export const ValueContext = createContext<ValueContext.Value>(defaultValue);

import { Typed } from "utils/Typed";
import { createState } from "../../../../../../../../generic-states/SchemaFields";

export const schemaFieldsState = createState(
  "Ready:DataManager:Customers:Create:SchemaFields",
);

export type SchemaFieldsActions = Typed.GetTypes<
  typeof schemaFieldsState.actions
>;

export type SchemaFieldsState = Typed.GetTypes<typeof schemaFieldsState.states>;

export type SchemaFieldsValidState = Typed.GetType<
  typeof schemaFieldsState.states.valid
>;

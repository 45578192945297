import { UiSchemaElementPath } from "types/src/jsonSchema/uiSchema";

export const uiElementPathEncode = ({
  path,
  id,
}: {
  path: UiSchemaElementPath;
  id: string; // specify exact item, prevents glitches after drag-n-drop
}): string => JSON.stringify({ path, id });

export const uiElementPathDecode = (
  path: string,
): Parameters<typeof uiElementPathEncode>[0] => JSON.parse(path);

import { useTranslation } from "i18n";
import * as O from "fp-ts/Option";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { DataTypeId } from "types/src/DataType/DataType";
import { OrderBy } from "types/src/OrderBy";
import { EntityListing } from "@Containers/Listing/EntityListing";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { useBehaviorValue } from "react-rx/behaviorValue";
import * as statusFilter from "@Containers/Listing/EntityListing/commonFilters/status";

export function PickingOrdersListing<
  CustomFiltersConfig extends
    PickingOrdersListing.Filters.ConfigCustomBase = {},
>(p: PickingOrdersListing.Props<CustomFiltersConfig>) {
  const { t } = useTranslation();

  const customFiltersConfig = useBehaviorValue(p.customFiltersConfig$);

  return (
    <EntityListing
      columns={{}}
      title={t("Recent orders")}
      newItemTitle={t("New order")}
      data$={p.data$}
      //region Filters
      customFiltersConfig={customFiltersConfig}
      filters$={p.filters$}
      onFilterChange={p.onFilterChange}
      onResetFilters={p.onResetFilters}
      //endregion
      onItemClick={p.onItemClick}
      onOrderChange={p.onOrderChange}
      orderBy$={p.orderBy$}
      onPageChange={p.onPageChange}
      state$={p.state$}
      onTypeClick={p.onTypeClick}
      onCreateNew={p.onCreateNew}
      bulkSelect={{
        ...p.bulkSelect,
        actions: [{ id: "remove", label: t("Remove") }],
      }}
      remove={{
        ...p.remove,
        getTitle: (c) => (c === 1 ? t("Remove order") : t("Remove orders")),
        getDescription: (c) =>
          c === 1
            ? t("Do you really want to remove this order")
            : t("Do you really want to remove this orders"),
      }}
      entryActions={(v) => [
        { label: t("Remove"), onClick: () => p.onRemove(v.id) },
      ]}
      columnsVisibility$={p.columnsVisibility$}
      onColumnsVisibilityChange={p.onColumnsVisibilityChange}
    />
  );
}

export namespace PickingOrdersListing {
  export interface Item extends EntityListing.Item<PickingOrderId> {}

  export namespace Filters {
    export type ConfigCustomBase = EntityListing.Filters.ConfigCustomBase;

    export const typeMap = {
      ...statusFilter.typeMap,
    } satisfies EntityListing.Filters.ListingTypeMapBase;

    export const stateToConfig = (
      state: {},
      { t }: Pick<ReturnType<typeof useTranslation>, "t">,
    ) => ({
      ...statusFilter.stateToConfig({}, { t }),
    });

    export const converters = {
      ...statusFilter.converters,
    } satisfies EntityListing.Filters.ListingConvertersBase<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<keyof typeof typeMap, any>,
      typeof typeMap
    >;
  }

  export interface Props<
    CustomFiltersConfig extends Filters.ConfigCustomBase = {},
  > {
    data$: BehaviorValue<{
      total: number;
      entries: Item[];
      pagination: {
        hasNext: boolean;
        hasPrev: boolean;
      };
    }>;
    state$: BehaviorValue<"loading" | "fetching" | "ready">;

    //region Filters
    customFiltersConfig$: BehaviorValue<CustomFiltersConfig>;
    filters$: BehaviorValue<
      EntityListing.Filters.Criteria<Item, CustomFiltersConfig>
    >;
    onFilterChange: (
      v: EntityListing.Filters.CriteriaChange<Item, CustomFiltersConfig>,
    ) => void;
    onResetFilters: () => void;
    //endregion

    orderBy$: BehaviorValue<O.Option<OrderBy<"createdAt" | "updatedAt">>>;
    bulkSelect: Omit<
      EntityListing.Props<Item, "remove", {}>["bulkSelect"],
      "actions"
    >;
    remove: Omit<EntityListing.RemoveProps, "getDescription" | "getTitle">;

    onCreateNew: (() => void) | undefined;
    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onItemClick: (id: PickingOrderId) => void;
    onRemove: (id: PickingOrderId) => void;
    onTypeClick: (id: DataTypeId) => void;
    onOrderChange: (p: "createdAt" | "updatedAt") => void;
    columnsVisibility$: BehaviorValue<
      Record<"id" | "dataType" | "createdAt" | "updatedAt", boolean>
    >;
    onColumnsVisibilityChange: (
      v: Partial<
        Record<"id" | "dataType" | "createdAt" | "updatedAt", boolean>
      >,
    ) => void;
  }
}

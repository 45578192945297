import { silentUnreachableError } from "utils/exceptions";
import * as E from "fp-ts/Either";
import * as Fp from "fp-ts/function";
import { Typed } from "utils/Typed";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { SchemaFieldsState, schemaFieldsState } from "./utils";
import { exits } from "./types/Exits";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          ...s.payload,
          schema: Fp.pipe(
            schemaFieldsState.reducer(s.payload.schema, a),
            E.getOrElse(() => s.payload.schema),
          ),
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:Customers:Create:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(
          State.loadError({
            dataTypeId: s.payload.dataTypeId,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Customers:Create:LoadSuccess": {
      if (State.isLoading(s)) {
        return E.right(
          State.ready({
            dataTypeId: s.payload.dataTypeId,
            schema: schemaFieldsState.states.init.create({
              schema: a.payload.schema,
              uiSchema: a.payload.ui,
              values: undefined,
            }),
            submitted: false,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Customers:Create:Submit": {
      if (State.isReady(s)) {
        return Fp.pipe(
          schemaFieldsState.reducer(
            s.payload.schema,
            schemaFieldsState.actions.submit.create(),
          ),
          E.getOrElse(() => s.payload.schema),
          E.fromPredicate(
            schemaFieldsState.states.valid.is,
            (v: SchemaFieldsState) => v,
          ),
          E.mapLeft((schema) =>
            State.ready({ ...s.payload, schema, submitted: true }),
          ),
          E.map((schema) =>
            State.saving({ ...s.payload, schema, submitted: true }),
          ),
          E.getOrElseW((v) => v),
          E.right,
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Customers:Create:SaveError": {
      if (State.isSaving(s)) {
        return E.right(
          State.ready({
            submitted: s.payload.submitted,
            dataTypeId: s.payload.dataTypeId,
            schema: s.payload.schema,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Customers:Create:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.left(exits.created.create(a.payload));
      }

      return E.right(s);
    }
    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}

import { OwnPropsOfRenderer, type UISchemaElement } from "@jsonforms/core";
import { Grid2 } from "@mui/material";
import React from "react";
import { renderLayoutElements } from "@jsonforms/material-renderers";
import { isEmpty } from "rambda";

export namespace Layout {
  export interface Props extends OwnPropsOfRenderer {
    elements: UISchemaElement[];
    direction: "row" | "column";
  }
}

export function Layout({
  visible,
  elements,
  schema,
  path,
  enabled,
  direction,
  renderers,
  cells,
}: Layout.Props) {
  if (isEmpty(elements) || !visible || !schema) {
    return null;
  } else {
    return (
      <Grid2 container direction={direction} spacing={6}>
        {renderLayoutElements(
          elements,
          schema,
          path ?? "",
          !!enabled,
          renderers,
          cells,
        )}
      </Grid2>
    );
  }
}

import { silentUnreachableError } from "utils/exceptions";
import { DataTypeId } from "types/src/DataType/DataType";
import * as FormValue from "types/src/FormValue";
import { NoEmptyString } from "types/src/NoEmptyString";
import { InventoryItem } from "types/src/InventoryItems/InventoryItem";
import { Repository } from "types/src/Repositories/Repository";
import { ItemMovementId } from "types/src/ItemMovements/ItemMovement";
import { strictGuard } from "utils/strictGuard";
import * as O from "fp-ts/Option";
import { SearchItem, SearchItemValid } from "../../../types/SearchItem";
import { SchemaFieldsState, SchemaFieldsStatesMap } from "../utils";

// region State
export type State =
  | Loading
  | LoadError
  | Ready
  | Saving
  | RemoveConfirmation
  | Removing;

export const isState = strictGuard((s: State): s is State => {
  const type = s.type as State["type"];

  switch (type) {
    case "Ready:DataManager:ItemMovements:Edit:Loading":
    case "Ready:DataManager:ItemMovements:Edit:LoadError":
    case "Ready:DataManager:ItemMovements:Edit:Ready":
    case "Ready:DataManager:ItemMovements:Edit:Saving":
    case "Ready:DataManager:ItemMovements:Edit:RemoveConfirmation":
    case "Ready:DataManager:ItemMovements:Edit:Removing":
      return true;
    default:
      silentUnreachableError(type);
      return false;
  }
});
// endregion

// region Loading
export interface LoadingPayload {
  id: ItemMovementId;
}

export interface Loading {
  type: "Ready:DataManager:ItemMovements:Edit:Loading";
  payload: LoadingPayload;
}

export const loading = (payload: Loading["payload"]): Loading => ({
  type: "Ready:DataManager:ItemMovements:Edit:Loading",
  payload,
});

export const isLoading = (a: State): a is Loading =>
  a.type === "Ready:DataManager:ItemMovements:Edit:Loading";
// endregion

// region LoadError
export interface LoadErrorPayload extends LoadingPayload {}

export interface LoadError {
  type: "Ready:DataManager:ItemMovements:Edit:LoadError";
  payload: LoadErrorPayload;
}

export const loadError = (payload: LoadError["payload"]): LoadError => ({
  type: "Ready:DataManager:ItemMovements:Edit:LoadError",
  payload,
});

export const isLoadError = (a: State): a is LoadError =>
  a.type === "Ready:DataManager:ItemMovements:Edit:LoadError";
// endregion

// region Ready
export interface ReadyPayload extends LoadingPayload {
  dataTypeId?: DataTypeId;
  schema: O.Option<SchemaFieldsState>;
  handler:
    | FormValue.Valid<NoEmptyString>
    | FormValue.Invalid<"required", string | undefined>;
  quantity:
    | FormValue.Valid<number>
    | FormValue.Invalid<"required", number | undefined>;
  item: FormValue.AsyncValue<
    "required",
    SearchItemValid<InventoryItem>,
    SearchItem<InventoryItem>
  >;
  from: FormValue.AsyncValue<
    "required",
    SearchItemValid<Repository>,
    SearchItem<Repository>
  >;
  to: FormValue.AsyncValue<
    "required",
    SearchItemValid<Repository>,
    SearchItem<Repository>
  >;
  isExecuted: boolean;
  submitted: boolean;
}

export interface Ready {
  type: "Ready:DataManager:ItemMovements:Edit:Ready";
  payload: ReadyPayload;
}

export const ready = (payload: Ready["payload"]): Ready => ({
  type: "Ready:DataManager:ItemMovements:Edit:Ready",
  payload,
});

export const isReady = (a: State): a is Ready =>
  a.type === "Ready:DataManager:ItemMovements:Edit:Ready";
// endregion

// region Saving
export interface SavingPayload
  extends FormValue.ExtractStructValid<ReadyPayload> {
  schema: O.Option<SchemaFieldsStatesMap["valid"]>;
}

export interface Saving {
  type: "Ready:DataManager:ItemMovements:Edit:Saving";
  payload: SavingPayload;
}

export const saving = (payload: Saving["payload"]): Saving => ({
  type: "Ready:DataManager:ItemMovements:Edit:Saving",
  payload,
});

export const isSaving = (a: State): a is Saving =>
  a.type === "Ready:DataManager:ItemMovements:Edit:Saving";
// endregion

// region RemoveConfirmation
export interface RemoveConfirmPayload extends ReadyPayload {}

export interface RemoveConfirmation {
  type: "Ready:DataManager:ItemMovements:Edit:RemoveConfirmation";
  payload: RemoveConfirmPayload;
}

export const removeConfirmation = (
  payload: RemoveConfirmation["payload"],
): RemoveConfirmation => ({
  type: "Ready:DataManager:ItemMovements:Edit:RemoveConfirmation",
  payload,
});

export const isRemoveConfirmation = (s: State): s is RemoveConfirmation =>
  s.type === "Ready:DataManager:ItemMovements:Edit:RemoveConfirmation";
// endregion

// region Removing
export interface RemovingPayload extends ReadyPayload {}

export interface Removing {
  type: "Ready:DataManager:ItemMovements:Edit:Removing";
  payload: RemovingPayload;
}

export const removing = (payload: Removing["payload"]): Removing => ({
  type: "Ready:DataManager:ItemMovements:Edit:Removing",
  payload,
});

export const isRemoving = (s: State): s is Removing =>
  s.type === "Ready:DataManager:ItemMovements:Edit:Removing";

// endregion
export function isLoaded(a: State): a is Exclude<State, Loading | LoadError> {
  return !isLoading(a) && !isLoadError(a);
}

export const isEditable = isReady;

export const init = loading;

import React from "react";
import { useTranslation } from "i18n";
import { GeneralFormDataProps } from "../types";
import { Row } from "../../../../components/Row";
import { Divider } from "../../../../components/Divider";
import { FormDataInput } from "../../../../components/formData/Input";
import { Type } from "./Type";
import { Format } from "./Format";

export namespace General {
  export type Props = React.PropsWithChildren<GeneralFormDataProps>;
}

export const General = ({
  formData$,
  onValuesChange,
  children,
}: General.Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <FormDataInput
          formDataProp="name"
          {...{ formData$, onValuesChange }}
          label={t("Name in schema")}
        />
      </Row>

      <Divider />

      <Row>
        <Type {...{ formData$, onValuesChange }} />
        <Format {...{ formData$, onValuesChange }} />
      </Row>

      {children}
    </>
  );
};

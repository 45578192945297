import { Selector } from "state-manager";
import { CollectionView as Main } from "state-manager/states/Ready/states/DataManager/states/Collections/states/View";
import { useStateBehavior } from "@Hooks/useStateBehavior";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { CollectionsMovementsListing } from "@Containers/Listing/CollectionsMovementsListing";
import { match } from "fp-utilities";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryMovementId } from "types/src/RepositoryMovements/RepositoryMovement";

export namespace Content {
  export interface Props {
    selector: Selector<Main.State>;
    dispatch: (a: Main.Actions) => void;
    onItemClick: (id: InventoryItemId | RepositoryMovementId) => void;
  }
}

export function Content(p: Content.Props) {
  const state$ = useStateBehavior().map(p.selector);
  const data$ = state$.map(
    Fp.flow((s) => {
      if (
        Main.instance.states.loading.is(s) ||
        Main.instance.states.loadError.is(s)
      )
        return {
          entries: [],
          total: 0,
          pagination: {
            hasNext: false,
            hasPrev: false,
          },
        };

      return {
        entries: s.payload.items.map((i): CollectionsMovementsListing.Item => {
          switch (i.type) {
            case "item":
              return {
                type: "item",
                id: i.id,
                createdAt: i.createdAt,
                sku: i.item.sku,
                from: {
                  id: i.from.id,
                  name: i.from.name,
                },
                to: {
                  id: i.to.id,
                  name: i.to.name,
                },
                executed: i.executed,
                quantity: i.quantity,
              };
            case "repository":
              return {
                type: "repository",
                id: i.id,
                createdAt: i.createdAt,
                name: i.repository.name,
                from: i.from
                  ? {
                      id: i.from.id,
                      name: i.from.name,
                    }
                  : undefined,
                to: {
                  id: i.to.id,
                  name: i.to.name,
                },
                executed: i.executed,
              };
          }
        }),
        total: s.payload.total,
        pagination: {
          hasPrev: s.payload.pageInfo.hasPreviousPage,
          hasNext: s.payload.pageInfo.hasNextPage,
        },
      };
    }),
  );

  return (
    <CollectionsMovementsListing
      state$={state$.map(
        match(
          [Main.instance.states.loading.is, () => "loading"],
          [Main.instance.states.loadError.is, () => "loading"],
          [Main.instance.states.ready.is, () => "ready"],
          [Main.instance.states.fetching.is, () => "fetching"],
        ),
      )}
      data$={data$}
      //region Filters
      filters$={state$.map(
        Fp.flow(
          (s) => s.payload.filters.payload.fields,
          (s) => ({
            search: s.search,
            createdAt: s.createdAt,
            custom: {},
          }),
        ),
      )}
      onFilterChange={Fp.flow(
        Main.instance.subStates.filters.actions.setValue.create,
        p.dispatch,
      )}
      onResetFilters={Fp.flow(
        Main.instance.subStates.filters.actions.reset.create,
        p.dispatch,
      )}
      //endregion
      orderBy$={state$.map((v) => O.fromNullable(v.payload.order))}
      onPageChange={Fp.flow(Main.instance.actions.setPage.create, p.dispatch)}
    />
  );
}

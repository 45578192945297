import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";

export const toOptionGuard =
  <A, B extends A>(p: (a: A) => a is B) =>
  (v: O.Option<A>): v is O.Option<B> =>
    Fp.pipe(
      v,
      O.map(p),
      O.getOrElse(() => true),
    );

import {
  ApolloClient,
  ApolloQueryResult,
  FetchResult,
  InMemoryCache,
  NormalizedCacheObject,
  OperationVariables,
} from "@apollo/client";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import * as Rx from "rxjs";
import * as DsError from "./type/DsError";
import { graphQLErrorToDsError } from "./transformers/Errors";
import { FetchOptions, Query } from "./type/Query";
import { Mutation } from "./type/Mutation";

export function getClient(uri: string, token: string, orgId: string): Client {
  return new _Client(
    new ApolloClient({
      uri,
      cache: new InMemoryCache(),
      headers: { authorization: `Bearer ${token}`, "pyck-owner-id": orgId },
    }),
  );
}

export interface QueryFnOptions<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TVariables extends Record<string, any> = Record<string, any>,
> {
  query: TypedDocumentNode<T>;
  variables?: TVariables;
  options?: FetchOptions;
}

export type QueryFn = <
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TVariables extends Record<string, any> = Record<string, any>,
>(
  options: QueryFnOptions<T, TVariables>,
) => Promise<E.Either<DsError.DsError, T>>;

export type MutationFn = <
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TVariables extends Record<string, any> = Record<string, any>,
>(options: {
  mutation: TypedDocumentNode<T>;
  variables?: TVariables;
}) => Promise<E.Either<DsError.DsError, T>>;

export interface Client {
  query: QueryFn;
  mutate: MutationFn;

  fetchQuery<TVariables extends OperationVariables, TData, Output>(
    q: Query<TVariables, TData, Output>,
  ): Promise<E.Either<DsError.DsError, Output>>;

  doMutation<TData, TVariables extends OperationVariables, Output>(
    q: Mutation<TData, TVariables, Output>,
  ): Promise<E.Either<DsError.DsError, Output>>;

  watchQuery<TVariables extends OperationVariables, TData, Output>(
    q: Query<TVariables, TData, Output>,
  ): Rx.Observable<E.Either<DsError.DsError, Output>>;
}

class _Client implements Client {
  constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

  async query<
    T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TVariables extends Record<string, any> = Record<string, any>,
  >(
    options: QueryFnOptions<T, TVariables>,
  ): Promise<E.Either<DsError.DsError, T>> {
    return this.client
      .query({
        ...options,
        errorPolicy: "all",
        context: {
          headers: {
            "pyck-show-deleted": options.options?.includeDeleted || undefined,
          },
        },
      })
      .then(convertQuery((v) => v));
  }

  fetchQuery<TVariables extends OperationVariables, TData, Output>(
    q: Query<TVariables, TData, Output>,
  ): Promise<E.Either<DsError.DsError, Output>> {
    return this.client
      .query({
        ...q.input,
        errorPolicy: "all",
        context: {
          headers: {
            "pyck-show-deleted": q.input.options?.includeDeleted || undefined,
          },
        },
      })
      .then(convertQuery(q.output));
  }

  watchQuery<TVariables extends OperationVariables, TData, Output>(
    q: Query<TVariables, TData, Output>,
  ): Rx.Observable<E.Either<DsError.DsError, Output>> {
    return new Rx.Observable((s) => {
      const sub = this.client
        .watchQuery({
          ...q.input,
          errorPolicy: "all",
          context: {
            headers: {
              "pyck-show-deleted": q.input.options?.includeDeleted || undefined,
            },
          },
        })
        .subscribe(Fp.flow(convertQuery(q.output), (v) => s.next(v)));

      return () => {
        sub.unsubscribe();
      };
    });
  }

  async mutate<T, TVariables extends OperationVariables>(options: {
    mutation: TypedDocumentNode<T>;
    variables?: TVariables;
  }): Promise<E.Either<DsError.DsError, T>> {
    return this.client
      .mutate<T, TVariables>({ ...options, errorPolicy: "all" })
      .then(convertMutation((v) => v))
      .then(
        E.map((v) => {
          this.client.resetStore();
          return v;
        }),
      );
  }

  doMutation<TData, TVariables extends OperationVariables, Output>(
    m: Mutation<TData, TVariables, Output>,
  ): Promise<E.Either<DsError.DsError, Output>> {
    return this.mutate<TData, TVariables>(
      // @ts-expect-error, fix later
      m.input,
    ).then(m.output);
  }
}

export * from "./type/DsError";
export type { QueryResponse } from "./type/QueryResponse";

const convertQuery: <T, R>(
  fn: (v: E.Either<DsError.DsError, T>) => E.Either<DsError.DsError, R>,
) => (v: ApolloQueryResult<T>) => E.Either<DsError.DsError, R> = (fn) =>
  Fp.flow(
    E.fromPredicate(
      (v) => v.errors?.[0] === undefined,
      (v) => v.errors?.[0],
    ),
    E.map((v) => v.data),
    E.mapLeft(
      Fp.flow(
        O.fromNullable,
        O.map(graphQLErrorToDsError),
        O.getOrElseW(DsError.unknownError),
      ),
    ),
    fn,
  );
const convertMutation: <T, R>(
  fn: (v: E.Either<DsError.DsError, T>) => E.Either<DsError.DsError, R>,
) => (v: FetchResult<T>) => E.Either<DsError.DsError, R> = (fn) =>
  Fp.flow(
    E.fromPredicate(
      (v) => v.data !== null && v.data !== undefined,
      (v) => v.errors?.[0],
    ),
    E.map((v) => v.data as Exclude<typeof v.data, null | undefined>),
    E.mapLeft(
      Fp.flow(
        O.fromNullable,
        O.map(graphQLErrorToDsError),
        O.getOrElseW(() => DsError.unknownError()),
      ),
    ),
    fn,
  );

export type { Query } from "./type/Query";

import React, { ChangeEventHandler } from "react";
import { CellProps, WithClassname } from "@jsonforms/core";
import {
  useDebouncedChange,
  WithInputProps,
} from "@jsonforms/material-renderers";
import { InputProps } from "@mui/material";
import { mergeWith } from "rambda";
import TextField from "@mui/material/TextField";

export namespace InputText {
  export interface Props extends WithClassname, CellProps, WithInputProps {
    muiInputProps?: InputProps["inputProps"];
    inputComponent?: InputProps["inputComponent"];
  }
}

export function InputText(props: InputText.Props) {
  const {
    data,
    config,
    className,
    id,
    enabled,
    uischema,
    isValid,
    path,
    handleChange,
    schema,
  } = props;
  const maxLength = schema.maxLength;
  const options = merge(config ?? {}, uischema.options) as Partial<{
    placeholder?: string;
    multi?: boolean;
    focus?: boolean;
    trim?: boolean;
    restrict?: boolean;
  }>;

  const [inputText, onChange] = useDebouncedChange(
    handleChange,
    "",
    data,
    path,
    eventToValue,
  );

  return (
    <TextField
      type={"text"}
      value={inputText}
      onChange={onChange}
      className={className}
      id={id}
      disabled={!enabled}
      autoFocus={options.focus}
      multiline={options.multi}
      fullWidth={!options.trim || maxLength === undefined}
      error={!isValid}
      rows={options.multi ? 4 : undefined}
      placeholder={options.placeholder}
    />
  );
}

const eventToValue: ChangeEventHandler<HTMLTextAreaElement> = (ev) =>
  ev.target.value === "" ? undefined : ev.target.value;

const merge = mergeWith((a, b) => b ?? a);

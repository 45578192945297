import { Customer } from "types/src/Customers/Customer";
import { Collection } from "types/src/Collections/Collection";
import { UUID } from "types/src/UUID";
import { ISODate } from "types/src/date/ISODate";
import { CollectionFragmentFragment } from "../generated/graphql";

export function collectionFragmentToCollection(
  customerFragment: CollectionFragmentFragment,
): Collection {
  return {
    id: customerFragment.id as Collection["id"],
    fields: (customerFragment.data ?? {}) as Collection["fields"],
    createdAt: customerFragment.createdAt,
    updatedAt: customerFragment.updatedAt ?? undefined,
    dataTypeId: customerFragment.dataTypeID as Customer["dataTypeId"],
    handler: customerFragment.handler ?? undefined,
    assignee: (customerFragment.assignee as UUID) ?? undefined,
    assignmentDate: (customerFragment.assignee as ISODate) ?? undefined,
  };
}

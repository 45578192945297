import * as Fp from "fp-ts/function";

type Props = Partial<{
  length: number;
  prefix: string;
  existingIds: Record<string, unknown>;
}>;

export const randomId = (p: Props = {}) => {
  const length = p.length ?? 7,
    prefix = p.prefix ?? "_";

  let id: string = "";
  do {
    const randomNumber = Fp.pipe(
      Math.random() * Math.pow(10, length),
      Math.trunc,
      String,
      (v) => v.padStart(length, "0"),
    );

    id = `${prefix}${randomNumber}`;
  } while (Object.hasOwn(p.existingIds ?? {}, id));

  return id;
};

import { useTranslation } from "i18n";
import * as O from "fp-ts/Option";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { DataTypeId } from "types/src/DataType/DataType";
import { OrderBy } from "types/src/OrderBy";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { ListingTable } from "ui/layouts/ListingTable";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { RepositoryId } from "types/src/Repositories/Repository";
import { EntityListing } from "./EntityListing";
import * as statusFilter from "./EntityListing/commonFilters/status";
import * as repositoriesFilter from "./EntityListing/commonFilters/repositories";

export function InventoryItemsListing<
  CustomFiltersConfig extends
    InventoryItemsListing.Filters.ConfigCustomBase = {},
>(p: InventoryItemsListing.Props<CustomFiltersConfig>) {
  const { t } = useTranslation();

  const customFiltersConfig = useBehaviorValue(p.customFiltersConfig$);

  return (
    <EntityListing
      title={t("Recent items")}
      newItemTitle={t("New item")}
      columns={
        {
          sku: {
            label: t("Sku"),
            type: ListingTable.CellType.text,
            renderProps: (v) => ({ text: v.sku }),
            canReorder: true,
          },
        } satisfies EntityListing.ColumnsType<InventoryItemsListing.Item>
      }
      data$={p.data$}
      //region Filters
      customFiltersConfig={customFiltersConfig}
      filters$={p.filters$}
      onFilterChange={p.onFilterChange}
      onResetFilters={p.onResetFilters}
      //endregion
      onItemClick={p.onItemClick}
      onOrderChange={p.onOrderChange}
      orderBy$={p.orderBy$}
      onPageChange={p.onPageChange}
      state$={p.state$}
      onTypeClick={p.onTypeClick}
      onCreateNew={p.onCreateNew}
      bulkSelect={{
        ...p.bulkSelect,
        actions: [{ id: "remove", label: t("Remove") }],
      }}
      remove={{
        ...p.remove,
        getTitle: (c) => (c === 1 ? t("Remove item") : t("Remove items")),
        getDescription: (c) =>
          c === 1
            ? t("Do you really want to remove this item")
            : t("Do you really want to remove this items"),
      }}
      entryActions={(v) => [
        { label: t("Remove"), onClick: () => p.onRemove(v.id) },
      ]}
      columnsVisibility$={p.columnsVisibility$}
      onColumnsVisibilityChange={p.onColumnsVisibilityChange}
    />
  );
}

export namespace InventoryItemsListing {
  export interface Item extends EntityListing.Item<InventoryItemId> {
    sku: string;
  }

  export namespace Filters {
    export type Filters = Partial<{
      status: "active" | "orphan";
      sku: string | undefined;
      repositories: RepositoryId[];
    }>;

    export type ConfigCustomBase = EntityListing.Filters.ConfigCustomBase;

    export const typeMap = {
      ...statusFilter.typeMap,
      sku: EntityListing.Filters.Type.text,
      repositories: EntityListing.Filters.Type.multiChoice,
    } satisfies EntityListing.Filters.ListingTypeMapBase;

    export const stateToConfig = (
      state: repositoriesFilter.StateToConfigPayload & {
        sku: string | undefined;
      },
      { t }: Pick<ReturnType<typeof useTranslation>, "t">,
    ) =>
      ({
        ...statusFilter.stateToConfig({}, { t }),
        ...repositoriesFilter.stateToConfig(state, { t }),
        sku: {
          type: EntityListing.Filters.Type.text,
          options: {
            label: t("Sku"),
          },
        },
      }) satisfies InventoryItemsListing.Filters.ConfigCustomBase;

    export const converters = {
      ...statusFilter.converters,
      ...repositoriesFilter.converters,
      sku: {
        toListing: (v) => ({ text: v }),
        toState: (v) => v?.text,
      },
    } satisfies EntityListing.Filters.ListingConvertersBase<
      Filters,
      typeof typeMap
    >;
  }

  export interface Props<
    CustomFiltersConfig extends Filters.ConfigCustomBase = {},
  > {
    data$: BehaviorValue<{
      total: number;
      entries: Item[];
      pagination: {
        hasNext: boolean;
        hasPrev: boolean;
      };
    }>;
    state$: BehaviorValue<"loading" | "fetching" | "ready">;

    //region Filters
    customFiltersConfig$: BehaviorValue<CustomFiltersConfig>;
    filters$: BehaviorValue<
      EntityListing.Filters.Criteria<Item, CustomFiltersConfig>
    >;
    onFilterChange: (
      v: EntityListing.Filters.CriteriaChange<Item, CustomFiltersConfig>,
    ) => void;
    onResetFilters: () => void;
    //endregion

    orderBy$: BehaviorValue<
      O.Option<OrderBy<"createdAt" | "updatedAt" | "sku">>
    >;

    onCreateNew: (() => void) | undefined;
    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onItemClick: (id: InventoryItemId) => void;
    onRemove: (id: InventoryItemId) => void;
    onTypeClick: (id: DataTypeId) => void;
    onOrderChange: (p: "createdAt" | "updatedAt" | "sku") => void;
    bulkSelect: Omit<
      EntityListing.Props<Item, "remove", {}>["bulkSelect"],
      "actions"
    >;
    remove: Omit<EntityListing.RemoveProps, "getDescription" | "getTitle">;
    columnsVisibility$: BehaviorValue<
      Record<"id" | "dataType" | "createdAt" | "updatedAt" | "sku", boolean>
    >;
    onColumnsVisibilityChange: (
      v: Partial<
        Record<"id" | "dataType" | "createdAt" | "updatedAt" | "sku", boolean>
      >,
    ) => void;
  }
}

import { DataTypeId } from "../DataType/DataType";
import { InventoryItem } from "../InventoryItems/InventoryItem";
import { Repository } from "../Repositories/Repository";
import { NoEmptyString } from "../NoEmptyString";
import { ISODate } from "../date/ISODate";
import { UUID } from "../UUID";
import { DataSchemaValue } from "../jsonSchema/DataSchemaValue";
import { CollectionId } from "../Collections/Collection";

// region CustomerId
declare const _itemMovementId: unique symbol;

export type ItemMovementId = UUID & {
  [_itemMovementId]: "ItemMovementId";
};

export namespace ItemMovementId {
  export const fromString = UUID.fromString<ItemMovementId>;
}
// endregion

export interface ItemMovement {
  id: ItemMovementId;
  dataTypeId: DataTypeId;
  fields: DataSchemaValue;
  createdAt: ISODate;
  updatedAt: ISODate | undefined;
  item: InventoryItem;
  from: Repository;
  to: Repository;
  quantity: number;
  executed: boolean;
  executedAt: ISODate | undefined;
  handler: NoEmptyString;
  orderId: string;
  collectionId: CollectionId | undefined;
  position: number | undefined;
}

import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { Typography } from "ui/components/Typography";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { ItemSetItem } from "state-manager/generic-states/ItemSetItem";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Tuple } from "types/src/Tuple";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Item } from "./Item";
import { Add } from "./Add";

export interface ItemSetItemsProps<
  Store extends ItemSetItem.Store,
  Id extends string,
> {
  label?: TranslatedStr;
  value$: Selector<Array<[Id, ItemSetItem.State]>>;
  dispatch: (id: Id, a: ItemSetItem.Actions) => void;
  constructor: ItemSetItem.Store;
  onAdd: () => void;
  onRemove: (id: Id) => void;
}

export function ItemSetItems<
  Store extends ItemSetItem.Store,
  Id extends string,
>(p: ItemSetItemsProps<Store, Id>): ReactElement {
  const ids = useSelector(
    Fp.flow(p.value$, (v) => v.map(Tuple.fst)),
    (a, b) => a.join(",") === b.join(","),
  );
  return (
    <FormWrapper>
      <Typography>{p.label}</Typography>
      {ids.map((id) => {
        return (
          <Item
            key={id}
            selector={Fp.flow(
              p.value$,
              (vs) => vs.find(([_id]) => _id === id) as [Id, ItemSetItem.State],
              Tuple.snd,
              (v) => ({
                isSearching: p.constructor.subStates.items.states.loading.is(
                  v.payload.items,
                ),
                item: v.payload.itemId,
                items: Fp.pipe(
                  v.payload.items,
                  O.fromPredicate(
                    p.constructor.subStates.items.states.ready.is,
                  ),
                  O.map((v) => v.payload.data),
                  O.toUndefined,
                  (v) => v ?? [],
                ),
              }),
            )}
            onSearch={Fp.flow(
              (search) => ({ search }),
              p.constructor.subStates.items.actions.setQuery.create,
              p.constructor.actions.items.create,
              (a) => p.dispatch(id, a),
            )}
            onSelectItem={Fp.flow(p.constructor.actions.setItem.create, (a) =>
              p.dispatch(id, a),
            )}
            onRemove={() => p.onRemove(id)}
          />
        );
      })}
      <Add onAdd={p.onAdd} />
    </FormWrapper>
  );
}

import React from "react";
import {
  ControlProps,
  rankWith,
  JsonFormsRendererRegistryEntry,
  isDateTimeControl,
} from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";

import { InputDateTime } from "@layouts/JsonForm/components/InputDateTime";
import { Control } from "../components/Control";

const MaterialControl = React.memo((props: ControlProps) => (
  <Control {...props} input={InputDateTime} />
));

export const entry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(2, isDateTimeControl),
  renderer: withJsonFormsControlProps(MaterialControl),
};

import * as Fp from "fp-ts/function";
import { UiState } from "../../types/uiState";

export const uiEditingRemoveSelected = (
  uiState: Pick<UiState, "editing">,
  ids: string[],
): Pick<UiState, "editing"> => ({
  editing: {
    ...uiState.editing,
    selectedElements: Fp.pipe(
      uiState.editing?.selectedElements || {},
      (selected) => ({ ...selected }),
      (selected) => {
        ids.forEach((id) => delete selected[id]);
        return selected;
      },
    ),
  },
});

export const uiEditingGetSelectedIds = ({ editing }: UiState): string[] =>
  Object.entries(editing?.selectedElements ?? {})
    .filter(([, selected]) => selected)
    .map(([id]) => id);

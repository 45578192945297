import { Value } from "types/src/jsonSchema/value";
import { ControlType } from "../../types/control/type";
import { ControlTypeConfig } from "../../types/control";
import { scopeToName } from "../uiSchema/scope";
import { mergeControlValuesIntoSchemas } from "./mergeValuesIntoSchemas";
import { findControlById } from "./findById";

type Props<Type extends ControlType> = {
  id: string;
  type: ControlTypeConfig<Type>;
  schemas: Value;
};

export const controlReset = <Type extends ControlType>({
  id,
  type,
  schemas,
}: Props<Type>): Value => {
  const control = findControlById(id, { uiSchema: schemas.uiSchema });
  if (!control) {
    return schemas; // maybe log
  }

  const defaultValues = {
    ...type.values.default,
    name: scopeToName(control.uiSchema.scope),
  };

  return mergeControlValuesIntoSchemas(defaultValues, schemas, {
    type: type,
    elementId: id,
    scope: control.uiSchema.scope,
    elementUiPath: control.uiPath,
  });
};

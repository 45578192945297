import {
  UiSchema,
  UiSchemaElement,
  UiSchemaElementPath,
  UiSchemaElementType,
} from "types/src/jsonSchema/uiSchema";
import { getId } from "./index";

// todo: test
export const findElementPath = (
  search: { id: string; type: UiSchemaElementType },
  schema: UiSchema,
): UiSchemaElementPath | undefined => {
  function findRecursive(
    elements: UiSchemaElement[],
    path: UiSchemaElementPath,
  ): UiSchemaElementPath | undefined {
    for (let index = 0, length = elements.length; index < length; ++index) {
      const element = elements[index];
      if (!element) {
        continue;
      }

      if (element.type === search.type && getId(element) === search.id) {
        return [...path, index];
      }

      if (
        element.type === UiSchemaElementType.group ||
        element.type === UiSchemaElementType.horizontalLayout ||
        element.type === UiSchemaElementType.verticalLayout
      ) {
        const foundPath = findRecursive(element.elements, [
          ...path,
          index,
          "elements",
        ]);
        if (foundPath) {
          return foundPath;
        }
      }
    }
  }

  return findRecursive(schema.elements, ["elements"]);
};

import { ReactElement, ReactNode } from "react";

import { useTranslation } from "i18n";
import { ConfirmationModal } from "@layouts/Dialogs/ConfirmationModal";
import { Trash } from "icons";

export interface DeleteModalProps {
  title: ReactNode;
  children?: ReactNode;
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  isLoading?: boolean;
}

export function DeleteModal(p: DeleteModalProps): ReactElement {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      title={p.title}
      confirmButtonText={t("Delete")}
      confirmButtonColor={"error"}
      onConfirm={p.onConfirm}
      onCancel={p.onCancel}
      icon={<Trash />}
      iconColor={"error"}
      isLoading={p.isLoading}
    >
      {p.children}
    </ConfirmationModal>
  );
}

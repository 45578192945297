import { useCallback } from "react";
import { useTranslation } from "i18n";
import { DataTypeEntity } from "types/src/DataType/DataType";
import { TranslatedStr } from "types/src/TranslatedStr";

export function useDataTypeEntityTitle() {
  const { t } = useTranslation();
  return useCallback(
    (e: DataTypeEntity): TranslatedStr => {
      switch (e) {
        case DataTypeEntity.Attachment:
          return t("Attachment");
        case DataTypeEntity.CollectionMovement:
          return t("Collection movement");
        case DataTypeEntity.Customer:
          return t("Customer");
        case DataTypeEntity.Inbound:
          return t("Inbound");
        case DataTypeEntity.InboundItem:
          return t("Inbound Item");
        case DataTypeEntity.Item:
          return t("Inventory item");
        case DataTypeEntity.ItemMovement:
          return t("Item movement");
        case DataTypeEntity.ItemSet:
          return t("Item set");
        case DataTypeEntity.Order:
          return t("Order");
        case DataTypeEntity.OrderItem:
          return t("Order item");
        case DataTypeEntity.Other:
          return t("Other");
        case DataTypeEntity.Repository:
          return t("Repository");
        case DataTypeEntity.RepositoryMovement:
          return t("Repository movement");
        case DataTypeEntity.Supplier:
          return t("Supplier");
      }
    },
    [t],
  );
}

import { silentUnreachableError } from "utils/exceptions";
import {
  UiSchemaElement,
  UiSchemaElementType,
} from "types/src/jsonSchema/uiSchema";

export const getId = (el: UiSchemaElement): string => {
  switch (el.type) {
    case UiSchemaElementType.verticalLayout: {
      return el.options?.id ?? el.type /*not usable default, maybe throw?*/;
    }
    case UiSchemaElementType.horizontalLayout: {
      return el.options?.id ?? el.type;
    }
    case UiSchemaElementType.group: {
      return el.options?.id ?? el.type;
    }
    case UiSchemaElementType.control: {
      return el.options?.id ?? el.scope;
    }
  }

  silentUnreachableError(el);
  return "?";
};

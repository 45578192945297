import { useCallback } from "react";
import { Value } from "types/src/jsonSchema/value";
import { BehaviorValueRef } from "react-rx/behaviorValue";
import * as Fp from "fp-ts/function";
import { controlDelete } from "../../utils/control/delete";
import { UiState } from "../../types/uiState";
import {
  uiEditingGetSelectedIds,
  uiEditingRemoveSelected,
} from "../../utils/ui/editing";
import { useClearPositionCaches } from "../useClearPositionCaches";
import { fixValue } from "../../utils/value/fix";

export const useControlDelete = ({
  id,
  valueRef,
  onValueChange: _onValueChange,
  uiRef,
  onUiChange,
}: {
  id: string;

  valueRef: BehaviorValueRef<Value>;
  onValueChange: (value: Value) => void;

  uiRef: BehaviorValueRef<UiState>;
  onUiChange: (ui: Partial<UiState>) => void;
}) => {
  const onValueChange = useClearPositionCaches(_onValueChange);

  return useCallback(
    () =>
      applyDelete([id], {
        valueRef,
        onValueChange,
        uiRef,
        onUiChange,
      }),
    [id, onUiChange, onValueChange, uiRef, valueRef],
  );
};

export const useControlDeleteSelected = ({
  valueRef,
  onValueChange: _onValueChange,
  uiRef,
  onUiChange,
}: {
  valueRef: BehaviorValueRef<Value>;
  onValueChange: (value: Value) => void;

  uiRef: BehaviorValueRef<UiState>;
  onUiChange: (ui: Partial<UiState>) => void;
}) => {
  const onValueChange = useClearPositionCaches(_onValueChange);

  return useCallback(
    () =>
      applyDelete(uiEditingGetSelectedIds(uiRef.current), {
        valueRef,
        onValueChange,
        uiRef,
        onUiChange,
      }),
    [uiRef, onValueChange, valueRef, onUiChange],
  );
};

const applyDelete = (
  ids: string[],
  {
    valueRef,
    onValueChange,
    uiRef,
    onUiChange,
  }: {
    valueRef: BehaviorValueRef<Value>;
    onValueChange: (value: Value) => void;

    uiRef: BehaviorValueRef<UiState>;
    onUiChange: (ui: Partial<UiState>) => void;
  },
): void => {
  onValueChange(
    ids.reduce(
      (schemas, id) =>
        Fp.pipe(schemas, (schemas) => controlDelete({ id, schemas }), fixValue),
      { ...valueRef.current },
    ),
  );

  onUiChange(uiEditingRemoveSelected(uiRef.current, ids));
};

import { Button } from "@mui/material";
import { useTranslation } from "i18n";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Search } from "icons";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { State } from "./State";

export namespace NoResults {
  export interface Props {
    hasAppliedFilters$: BehaviorValue<boolean>;
    onReset: () => void;
  }
}

export function NoResults(p: NoResults.Props) {
  const { t } = useTranslation();
  const showReset = useBehaviorValue(p.hasAppliedFilters$);

  return (
    <State
      icon={<Search />}
      title={t("No results")}
      subtitle={t(
        "Please try resetting your filters or search for a new query.",
      )}
    >
      <Button
        onClick={p.onReset}
        variant={"outlined"}
        disabled={!showReset}
        sx={(p) => ({
          marginTop: p.spacing(6),
          visibility: !showReset ? "hidden" : undefined,
        })}
      >
        {t("Reset filters")}
      </Button>
    </State>
  );
}

import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue } from "react-rx/behaviorValue";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { useTranslation } from "i18n";
import { ChevronVertical } from "icons";
import { Box } from "@mui/material";
import Menu from "@mui/material/Menu";
import { useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Sxs } from "../../../../../types/styles";

export namespace BulkSelect {
  export interface Action<Id extends string> {
    id: Id;
    label: TranslatedStr;
  }

  export interface Props<Id extends string> {
    value$: BehaviorValue<{ total: number; selected: number }>;
    onSelect: () => void;
    actions: Action<Id>[];
    onAction: (id: Id) => void;
  }
}

export function BulkSelect<Id extends string>(p: BulkSelect.Props<Id>) {
  const status = useBehaviorValue(
    p.value$.map((v) => {
      if (v.total === 0 || v.selected === 0) return "unchecked";

      if (v.total > v.selected) return "intermediate";

      return "checked";
    }),
  );
  const count$ = p.value$.map((v) => v.selected);
  const selected = useBehaviorValue(count$.map(Boolean));

  return (
    <TableCell padding={"checkbox"}>
      <Box component="span" sx={sxs.wrapper}>
        <Checkbox
          checked={status !== "unchecked"}
          indeterminate={status === "intermediate"}
          onClick={p.onSelect}
        />
        {selected ? (
          <Selected items={p.actions} onSelect={p.onAction} count$={count$} />
        ) : null}
      </Box>
    </TableCell>
  );
}

function Selected<Id extends string>(p: {
  count$: BehaviorValue<number>;
  items: Array<{ id: Id; label: string }>;
  onSelect: (id: Id) => void;
}) {
  const { t } = useTranslation();
  const ref = useRef<HTMLSpanElement>(null);
  const [open, setOpen] = useState(false);
  const count = useBehaviorValue(p.count$);

  return (
    <Box
      sx={{
        whiteSpace: "nowrap",
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        component={Typography}
        variant="caption"
        role="button"
        sx={sxs.absolute}
        onClick={() => !open && setOpen(true)}
        ref={ref}
      >
        {/* @ts-expect-error, review later */}
        {t("{count} selected", { count })} <ChevronVertical />
      </Box>
      <Menu
        open={open}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {p.items.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => {
              p.onSelect(item.id);
              setOpen(false);
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

const sxs = {
  wrapper: (theme) => ({
    display: "flex",
    alignItems: "center",
  }),
  absolute: (theme) => ({
    position: "absolute",
    left: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    gap: 1,
    cursor: "pointer",
    color: theme.palette.primary.dark,
  }),
} satisfies Sxs;

import * as Rx from "rxjs";
import * as Obj from "utils/object";
import { Epic } from "../../types/RootEpic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { createPickingOrderItemState } from "./utils";

export const epic = <Id extends string>(
  p: string,
): Epic<Actions.Actions<Id>, State.State<Id>> => {
  const pickingOrderItemState = createPickingOrderItemState<Id>(p);
  return (s$) =>
    s$.pipe(
      Rx.map((s) => s.payload.items),
      Rx.map(Obj.keys),
      Rx.mergeMap((ids) => Rx.from(ids)),
      Rx.distinct(),
      Rx.mergeMap((id) => {
        const epic = pickingOrderItemState(id).epic;

        return epic(s$.pipe(Rx.map((s) => s.payload.items[id])), {});
      }),
    );
};

import { useRef } from "react";
import { randomId } from "utils/randomId";

type Props = Partial<{
  length: number;
  prefix: string;
  existingIds: Record<string, never>;
}>;

export const useRandomId = (p: Props = {}): string => {
  const id = useRef("");

  if (!id.current.length) {
    id.current = randomId(p);
  }

  return id.current;
};

import { useTranslation } from "i18n";
import { ListingTable } from "ui/layouts/ListingTable";
import { EntityListing } from "@Containers/Listing/EntityListing";
import * as Obj from "utils/object";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";

export const typeMap = {
  items: EntityListing.Filters.Type.multiChoice,
} satisfies EntityListing.Filters.ListingTypeMapBase;

export type StateToConfigPayload = {
  items: {
    items: Array<{ id: InventoryItemId; label: string }>;
    onSearch: (s?: string) => void;
    search: string | undefined;
    state: "loading" | "error" | "ready";
  };
};

export const stateToConfig = (
  state: StateToConfigPayload,
  { t }: Pick<ReturnType<typeof useTranslation>, "t">,
) => ({
  items: {
    type: typeMap["items"],
    options: {
      label: t("Items"),
      choices: state.items.items,
      state: state.items.state,
      search: state.items.search,
      onSearch: state.items.onSearch,
    },
  } satisfies ListingTable.CustomFilterConfig,
});

export const converters = {
  items: {
    toListing: (v) => ({
      choices: v?.reduce((acc: Record<InventoryItemId, boolean>, i) => {
        acc[i] = true;
        return acc;
      }, {}),
    }),
    toState: (v) =>
      Obj.keys(Obj.filter((v) => !!v, v?.choices ?? {})) as InventoryItemId[],
  },
} satisfies EntityListing.Filters.ListingConvertersBase<
  {
    items?: InventoryItemId[];
  },
  typeof typeMap
>;

import { useBehaviorValueRef } from "react-rx/behaviorValue";
import { useCallback, useContext, useEffect, useRef } from "react";
import { UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { ControlSchemas } from "../../../../../types/control/schema";
import { ValueContext } from "../../../../../contexts/Value";
import { ControlTypesContext } from "../../../../../contexts/ControlTypes";
import { getId } from "../../../../../utils/uiSchema";
import { findElementPath } from "../../../../../utils/uiSchema/findElementPath";
import { ControlType } from "../../../../../types/control/type";
import { ControlTypeValues } from "../../../../../types/control/values";
import { mergeControlValuesIntoSchemas } from "../../../../../utils/control/mergeValuesIntoSchemas";

export namespace useUpdateSchemas {
  export type Transform<Values extends ControlTypeValues> = (
    data: { dataSchema: DataSchema; scope: string },
    meta: { values: Values },
  ) => typeof data;
}

export const useUpdateSchemas = <
  Values extends ControlTypeValues & { name: string },
>({
  controlSchemas$,
}: {
  controlSchemas$: BehaviorValue<Pick<ControlSchemas, "uiSchema">>;
}) => {
  const controlSchemasRef = useBehaviorValueRef(controlSchemas$);

  const { value$, onValueChange } = useContext(ValueContext);
  const valueRef = useBehaviorValueRef(value$);

  const controlTypes = useContext(ControlTypesContext);

  const updateSchemas = useCallback(
    (values: Values): void => {
      const type = controlTypes[values.type as ControlType],
        elementId = getId(controlSchemasRef.current.uiSchema),
        elementUiPath = findElementPath(
          { id: elementId, type: UiSchemaElementType.control },
          valueRef.current.uiSchema,
        );
      if (!elementUiPath) {
        return; // log maybe
      }

      onValueChange(
        mergeControlValuesIntoSchemas<ControlType>(values, valueRef.current, {
          /**
           * todo: change function generic to `Type extends ControlType`
           * then this will become compatible
           */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          type: type as any,
          scope: controlSchemasRef.current.uiSchema.scope,
          elementId,
          elementUiPath,
        }),
      );
    },
    [controlSchemasRef, valueRef, controlTypes, onValueChange],
  );

  const updateSchemasDebounceTimeout = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => () => clearTimeout(updateSchemasDebounceTimeout.current), []);
  return useCallback(
    (values: Values): void => {
      clearTimeout(updateSchemasDebounceTimeout.current);
      updateSchemasDebounceTimeout.current = setTimeout(
        () => updateSchemas(values),
        100,
      );
    },
    [updateSchemas],
  );
};

import React, { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { sxsCommon } from "@layouts/ListingTable/filters/custom/common/sxs";
import { styleCustomFilterInputWidth } from "@layouts/ListingTable/utils/styles";
import { FilterPropsCustom, FilterTypeText } from "../../../types/filters";
import { RemoveButton } from "../common/RemoveButton";

export const Text = ({
  config: { options },
  value,
  onChange,
  onRemove,
}: FilterPropsCustom<FilterTypeText>) => {
  const [hasFocus, setHasFocus] = useState(false);

  const { text } = value || {};

  return (
    <Box sx={sxs.wrapper}>
      <OutlinedInput
        sx={sxs.input}
        placeholder={options.placeholder || options.label}
        value={text}
        onChange={({ target: { value: text } }) => onChange({ text })}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
      />
      {!hasFocus && (
        <Box sx={sxs.removeButton}>
          <RemoveButton onClick={onRemove}></RemoveButton>
        </Box>
      )}
    </Box>
  );
};

const sxs = {
  wrapper: (theme) => ({
    position: "relative",
    width: styleCustomFilterInputWidth(theme),
  }),
  input: (theme) => ({
    ...sxsCommon.input(theme),
    ...sxsCommon.inputWithRemove(theme),
  }),
  removeButton: (theme) => ({
    ...sxsCommon.inputRemoveButton(theme),
  }),
} satisfies Record<string, SxProps<Theme>>;

import * as O from "fp-ts/Option";
import { DateRange } from "types/src/date/DateRange";
import { NoEmptyString } from "types/src/NoEmptyString";
import {
  DataTypeEntity,
  DataTypeId,
  DataType,
} from "types/src/DataType/DataType";
import { ISODate } from "types/src/date/ISODate";
import { Option } from "fp-ts/Option";
import * as Rx from "rxjs";
import { Client, isNotFoundError } from "ds";
import { getDataType, getDataTypes } from "ds/DataTypes";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { RepositoryId } from "types/src/Repositories/Repository";
import {
  deleteRepositories,
  getRepositories,
  GetRepositoryVars,
} from "ds/Repositories";
import { Tuple } from "types/src/Tuple";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { strictGuard } from "utils/strictGuard";
import { getInventoryItems } from "ds/InventoryItems";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "../../../../../../../../generic-states/Loading";
import { ListingWithDataType } from "../../../../../../../../generic-states/ListingWithDataType";
import { Epic } from "../../../../../../../../types/RootEpic";

const prefix = "Ready:DataManager:Repositories:Listing";

function createState() {
  const itemsState = Loading.createState<
    Array<{ id: InventoryItemId; sku: string }>,
    string | undefined
  >(`${prefix}:items`, { equals: (a, b) => a === b });
  const parentState = Loading.createState<
    Array<{ id: RepositoryId; name: string }>,
    string | undefined
  >(`${prefix}:parent`, { equals: (a, b) => a === b });
  const state = ListingWithDataType.createState<
    RepositoriesListing.Filter,
    RepositoriesListing.Item,
    "createdAt" | "updatedAt" | "name" | "type" | "virtualRepo",
    {
      id: DataTypeId;
      inventoryItems: Loading.GetState<typeof itemsState>;
      parent: Loading.GetState<typeof parentState>;
    }
  >(prefix, {
    defaultFilters: {},
  });

  type State = ListingWithDataType.GetState<typeof state>;
  type Actions =
    | ListingWithDataType.GetActions<typeof state>
    | Loading.GetActions<typeof itemsState>
    | Loading.GetActions<typeof parentState>;
  type Ex = ListingWithDataType.GetExits<typeof state>;

  const reducer = (s: State, a: Actions): E.Either<Ex, State> => {
    if (itemsState.isAction(a)) {
      const st = itemsState.reducer(s.payload.inventoryItems, a);

      return E.right({
        ...s,
        payload: {
          ...s.payload,
          inventoryItems: Fp.pipe(
            st,
            E.getOrElseW(() => s.payload.inventoryItems),
          ),
        },
      } as State);
    }

    if (parentState.isAction(a)) {
      const st = parentState.reducer(s.payload.parent, a);

      return E.right({
        ...s,
        payload: {
          ...s.payload,
          parent: Fp.pipe(
            st,
            E.getOrElseW(() => s.payload.parent),
          ),
        },
      } as State);
    }

    if (state.isAction(a)) return state.reducer(s, a);

    silentUnreachableError(a);
    return E.right(s);
  };

  const epic: Epic<Actions, State, RepositoriesListing.Deps> = (
    state$,
    deps,
  ) => {
    const main$ = state.epic(state$, {
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(getRepositories(client, getFetchVars(s))),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: { in: [DataTypeEntity.Repository] },
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;

                    return E.right({
                      items: v.items.right,
                      dataTypes: Fp.pipe(
                        v.dataTypes,
                        E.map((v) => v.items),
                        E.getOrElse(() => [] as DataType[]),
                      ),
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      id: i.id,
                      createdAt: i.createdAt,
                      name: i.name,
                      updatedAt: O.fromNullable(i.updatedAt),
                      type: i.type,
                      isVirtual: i.isVirtual,
                      dataType: O.fromNullable(
                        r.dataTypes.find((v) => v.id === i.dataTypeId),
                      ),
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(deleteRepositories(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
      fetchDataType: (id) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            return Rx.from(getDataType(client, id)).pipe(
              Rx.map(E.map(O.some)),
              Rx.map(
                E.orElse((e) => {
                  if (isNotFoundError(e)) return E.right(O.none);

                  return E.left(e);
                }),
              ),
            );
          }),
        );
      },
    });

    const items$ = itemsState.epic(
      state$.pipe(Rx.map((s) => s.payload.inventoryItems)),
      {
        get: (q) => {
          return deps.pyckAdminClient$.pipe(
            Rx.switchMap((client) => {
              return Rx.from(
                getInventoryItems(client, {
                  where: {
                    or: [
                      { sku: { containsFold: q } },
                      { data: { contains: q ? ["", q] : undefined } },
                    ],
                  },
                }),
              );
            }),
            Rx.map(
              E.map((r) => {
                return r.items.map((v) => ({
                  id: v.id,
                  sku: v.sku,
                }));
              }),
            ),
          );
        },
      },
    );
    const parent$ = parentState.epic(
      state$.pipe(Rx.map((s) => s.payload.parent)),
      {
        get: (q) => {
          return deps.pyckAdminClient$.pipe(
            Rx.switchMap((client) => {
              return Rx.from(
                getRepositories(client, {
                  where: {
                    or: [{ name: { containsFold: q } }],
                  },
                }),
              );
            }),
            Rx.map(
              E.map((r) => {
                return r.items.map((v) => ({
                  id: v.id,
                  name: v.name,
                }));
              }),
            ),
          );
        },
      },
    );

    return Rx.merge(main$, items$, parent$);
  };

  return {
    ...state,
    isAction: strictGuard(
      (a: Actions): a is Actions =>
        state.isAction(a) || itemsState.isAction(a) || parentState.isAction(a),
    ),
    epic,
    reducer,
    init: ({ id }: { id: DataTypeId }): State => {
      return state.init({
        id: id,
        inventoryItems: itemsState.init(undefined),
        parent: parentState.init(undefined),
      });
    },
    subStates: {
      ...state.subStates,
      items: itemsState,
      parent: parentState,
    },
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetRepositoryVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetRepositoryVars["where"] = {
      dataType: {
        in: [s.payload.id],
        isNil: Fp.pipe(
          fields.status,
          O.fromNullable,
          O.map((v) => v === "orphan"),
          O.toUndefined,
        ),
      },
      and: [
        {
          createdAt: {
            gte: fields.createdAt?.[0],
            lte: fields.createdAt?.[1],
          },
          updatedAt: {
            gte: fields.updatedAt?.[0],
            lte: fields.updatedAt?.[1],
          },
        },
      ],
      or: [
        {
          id: {
            eq: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.map(RepositoryId.fromString),
              O.toUndefined,
            ),
          },
        },
        { name: { containsFold: fields.search } },
        {
          data: {
            contains: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.chain(NoEmptyString.fromString),
              O.map(Tuple.create("")),
              O.toUndefined,
            ),
          },
        },
      ],
      name: { containsFold: fields.name },
      virtual: fields.isVirtual,
      type: fields.type,
      hasStocksWith: [{ hasItemWith: [{ id: { in: fields.items } }] }],
      parent: { in: fields.parent },
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
}

export namespace RepositoriesListing {
  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: (
      id: DataTypeId,
    ) => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (id: DataTypeId, v: Record<string, boolean>) => void;
  }

  export type Filter = Partial<{
    createdAt: DateRange;
    updatedAt: DateRange;
    name: string;
    search: string;
    status: "active" | "orphan";
    type: "dynamic" | "static";
    isVirtual: boolean;
    items: InventoryItemId[];
    parent: RepositoryId[];
  }>;

  export interface Item {
    id: RepositoryId;
    name: NoEmptyString;
    type: "dynamic" | "static";
    isVirtual: boolean;
    createdAt: ISODate;
    updatedAt: Option<ISODate>;
    dataType: Option<{ id: DataTypeId; name: string }>;
  }

  export const instance = createState();

  export type State = ListingWithDataType.GetState<typeof instance>;
  export type Actions = ListingWithDataType.GetActions<typeof instance>;
  export type Exits = ListingWithDataType.GetExits<typeof instance>;
}

import { Selector, SelectorValue, useSelector } from "state-manager";
import { ItemSetCreate as Main } from "state-manager/states/Ready/states/DataManager/states/ItemSets/states/Create";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Loading } from "@Pages/Loading";

import { ItemSetForm, ItemSetFormProps } from "@Containers/Form/ItemSetForm";

const { states, actions } = Main.instance;

export interface ContentProps {
  selector: Selector<Main.State>;
  dispatch: (a: Main.Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const formSelector = useSelector(
    Fp.flow(selector, (s) => {
      if (states.loading.is(s) || states.loadError.is(s))
        return { type: "loading" } as const;

      return {
        type: "ready",
        value$: Fp.flow(
          selector,
          (v) => v as typeof s,
          (s): SelectorValue<ItemSetFormProps<Main.ItemId>["selector"]> => ({
            schema: O.some(s.payload.schema),
            items: s.payload.items,
            sku: s.payload.sku,
          }),
        ),
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (formSelector.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <ItemSetForm
          selector={formSelector.value$}
          dispatch={{
            setSku: Fp.flow(actions.setSku.create, dispatch),
            items: Fp.flow(actions.items.create, dispatch),
            schema: Fp.flow(actions.schema.create, dispatch),
            onAdd: Fp.flow(actions.addItem.create, dispatch),
            onRemove: Fp.flow(actions.removeItem.create, dispatch),
          }}
          itemsInstance={Main.instance.subStates.items}
          schemaInstance={Main.instance.subStates.schema}
        />
      );
  }
}

import { Typed } from "utils/Typed";
import { createState } from "../../../../../../../../generic-states/SchemaFields";

export const schemaFieldsState = createState(
  "Ready:DataManager:InventoryItems:Create:SchemaFields",
);

export type SchemaFieldsActions = Typed.GetTypes<
  typeof schemaFieldsState.actions
>;

export type SchemaFieldsStatesMap = {
  [k in keyof typeof schemaFieldsState.states]: Typed.GetType<
    (typeof schemaFieldsState.states)[k]
  >;
};
export type SchemaFieldsState = Typed.GetTypes<typeof schemaFieldsState.states>;

import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { useTranslation } from "i18n";
import * as DataManager from "state-manager/states/Ready/states/DataManager";
import { GetContructorType, GetGuardType } from "types/src/Utils";
import { PickingOrderItems } from "@Containers/PickingOrderItems";
import { TranslatedStr } from "types/src/TranslatedStr";
import { ItemSearchInput } from "@Containers/Form/ItemSearchInput";
import { SchemaFieldsJsonEditor } from "@Containers/SchemaFieldsJsonEditor";
import { Typography } from "ui/components/Typography";
import { DeleteModal } from "ui/layouts/Dialogs/DeleteModal";

const {
  states,
  actions,
  isActions,
  schemaFieldsState,
  customerSearchState,
  pickingOrderItemsState,
} = DataManager.pickingOrderState.subStates.edit;
type State = GetContructorType<typeof states>;
type Actions = GetGuardType<typeof isActions>;

export interface ContentProps {
  selector: Selector<State>;
  dispatch: (a: Actions) => void;
}

export function Content({ selector, dispatch }: ContentProps) {
  const { t } = useTranslation();

  const r = useSelector(
    flow(selector, (s) => {
      if (states.loading.is(s) || states.loadError.is(s)) {
        return {
          type: "loading",
        } as const;
      }

      if (
        states.ready.is(s) ||
        states.saving.is(s) ||
        states.removeConfirmation.is(s) ||
        states.removing.is(s)
      ) {
        const schemaSelector = flow(
          selector,
          (a) => a as typeof s,
          (s) => ({
            schema: s.payload.fields.payload.schema,
            uiSchema: s.payload.fields.payload.uiSchema,
            values: s.payload.fields.payload.values,
            submitted: s.payload.submitted,
          }),
        );
        const customerSelector = flow(
          selector,
          (a) => a as typeof s,
          (s) => s.payload.customer,
        );
        const itemsSelector = flow(
          selector,
          (a) => a as typeof s,
          (s) => s.payload.items,
        );

        return {
          type: "ready",
          schemaSelector,
          customerSelector,
          itemsSelector,
        } as const;
      }

      silentUnreachableError(s);
      return {
        type: "loading",
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (r.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <>
          <RemoveConfirmHandler selector={selector} dispatch={dispatch} />
          <FormWrapper>
            <ItemSearchInput
              label={t("Customer")}
              selector={r.customerSelector}
              dispatch={dispatch}
              getLabel={(v) => v.title as TranslatedStr}
              getId={(v) => v.id}
              constructor={customerSearchState}
            />
            <SchemaFieldsJsonEditor
              value$={r.schemaSelector}
              onChange={flow(
                (v) => v ?? {},
                schemaFieldsState.actions.onChange.create,
                dispatch,
              )}
            />
            <Typography>{t("Order items")}</Typography>
            <PickingOrderItems
              constructor={pickingOrderItemsState}
              value$={r.itemsSelector}
              dispatch={dispatch}
            />
          </FormWrapper>
        </>
      );
  }
}
function RemoveConfirmHandler(p: {
  selector: Selector<State>;
  dispatch: (a: Actions) => void;
}) {
  const { t } = useTranslation();
  const show = useSelector(flow(p.selector, states.removeConfirmation.is));

  return show ? (
    <DeleteModal
      title={t("Delete order")}
      onConfirm={() => p.dispatch(actions.removeConfirm.create())}
      onCancel={() => p.dispatch(actions.removeDecline.create())}
    >
      {t("Do you really want to delete this order?")}
      <br />
      {t("This action cannot be undone.")}
    </DeleteModal>
  ) : null;
}

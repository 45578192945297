import { Typed } from "utils/Typed";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { silentUnreachableError } from "utils/exceptions";
import * as Rx from "rxjs";
import { strictGuard } from "utils/strictGuard";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { CollectionId } from "types/src/Collections/Collection";
import { Epic, mergeByGuard } from "../../../../../../types/RootEpic";
import { EntitiesDeps } from "../../types/EntitiesDeps";
import { CollectionsListingAll as ListingAll } from "./states/ListingAll";
import { CollectionsListing as Listing } from "./states/Listing";
import { CollectionView as View } from "./states/View";

const states = Typed.builder
  .add(
    "idle",
    (p: { listing: ListingAll.State | Listing.State | View.State }) => p,
  )
  .finish()("Ready:DataManager:Collections");

const isState = Typed.getGuard(states);

const actions = Typed.builder
  .add("goToListingAll")
  .add("goToListing", (id: DataTypeId) => id)
  .add("goToView", (id: CollectionId) => id)
  .finish()("Ready:DataManager:Collections:Actions");
const isActions = Typed.getGuard(actions);

const isAction = strictGuard(
  (v: Collections.Actions): v is Collections.Actions => {
    return (
      isActions(v) ||
      ListingAll.instance.isAction(v) ||
      Listing.instance.isAction(v) ||
      View.instance.isAction(v)
    );
  },
);

function reducer(
  s: Collections.State,
  a: Collections.Actions,
): Collections.State {
  const listing = s.payload.listing;

  if (actions.goToListingAll.is(a)) {
    if (!ListingAll.instance.isState(s.payload.listing)) {
      return states.idle.create({
        listing: ListingAll.instance.init(),
      });
    }

    return states.idle.create({
      listing: s.payload.listing,
    });
  }

  if (actions.goToListing.is(a)) {
    if (
      !Listing.instance.isState(s.payload.listing) ||
      s.payload.listing.payload.id !== a.payload
    ) {
      return states.idle.create({
        listing: Listing.instance.init({ id: a.payload }),
      });
    }

    return states.idle.create({
      listing: s.payload.listing,
    });
  }

  if (actions.goToView.is(a)) {
    if (View.instance.isState(listing) && listing.payload.id === a.payload) {
      return s;
    }

    return states.idle.create({
      listing: View.instance.init(a.payload),
    });
  }

  if (ListingAll.instance.isAction(a)) {
    if (ListingAll.instance.isState(listing)) {
      return Fp.pipe(
        ListingAll.instance.reducer(listing, a),
        E.map((r) => states.idle.create({ listing: r })),
        E.getOrElse(() => s),
      );
    }

    return s;
  }

  if (Listing.instance.isAction(a)) {
    if (Listing.instance.isState(listing)) {
      return Fp.pipe(
        Listing.instance.reducer(listing, a),
        E.map((r) =>
          states.idle.create({
            listing: r,
          }),
        ),
        E.getOrElseW((e) => {
          if (Listing.instance.exits.create.is(e)) return s;

          silentUnreachableError(e);
          return s;
        }),
      );
    }

    return s;
  }

  if (View.instance.isAction(a)) {
    if (View.instance.isState(listing)) {
      return Fp.pipe(
        View.instance.reducer(listing, a),
        E.mapLeft(() => s),
        E.filterOrElseW(
          (r) => r !== listing,
          () => s,
        ),
        E.map((r) => states.idle.create({ listing: r })),
        E.toUnion,
      );
    }

    return s;
  }

  silentUnreachableError(a);
  return s;
}

const epic: Epic<Collections.Actions, Collections.State, EntitiesDeps> = (
  state$,
  ds$,
) => {
  const listing$ = mergeByGuard([
    [
      View.instance.isState,
      ((s$) =>
        View.instance.epic(s$, {
          pyckAdminClient$: ds$.pyckAdminClient$,
          getVisibleColumns: () =>
            ds$.getListingVisibleColumns(
              `${DataTypeEntity.CollectionMovement}:movements`,
            ),
          setVisibleColumns: (v) =>
            ds$.setListingVisibleColumns(
              `${DataTypeEntity.CollectionMovement}:movements`,
              v,
            ),
        })) satisfies typeof View.instance.epic,
    ],
    [
      ListingAll.instance.isState,
      ((s$) =>
        ListingAll.instance.epic(s$, {
          pyckAdminClient$: ds$.pyckAdminClient$,
          getVisibleColumns: () =>
            ds$.getListingVisibleColumns(DataTypeEntity.CollectionMovement),
          setVisibleColumns: (v) =>
            ds$.setListingVisibleColumns(DataTypeEntity.CollectionMovement, v),
        })) satisfies typeof ListingAll.instance.epic,
    ],
    [
      Listing.instance.isState,
      ((s$) =>
        Listing.instance.epic(s$, {
          pyckAdminClient$: ds$.pyckAdminClient$,
          getVisibleColumns: ds$.getListingVisibleColumns,
          setVisibleColumns: ds$.setListingVisibleColumns,
        })) satisfies typeof Listing.instance.epic,
    ],
  ])(state$.pipe(Rx.map((s) => s.payload.listing)), ds$);

  return listing$;
};

function createInstance() {
  return {
    states,
    actions,
    isState,
    isAction,
    reducer,
    epic,
    init: () =>
      states.idle.create({
        listing: ListingAll.instance.init(),
      }),
    subStates: {
      listingAll: ListingAll.instance,
      listing: Listing.instance,
      view: View.instance,
    },
  };
}

export namespace Collections {
  export type Actions =
    | Typed.GetTypes<typeof actions>
    | ListingAll.Actions
    | Listing.Actions
    | View.Actions;

  export type State = Typed.GetTypes<typeof states>;

  export const instance = createInstance();
}

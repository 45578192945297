import { useCallback, useContext, useEffect } from "react";
import { DndMeta } from "../types";
import { ElementRefsContext } from "./Context";

type Props = {
  id: string;
  meta: DndMeta.Common;
};

export const useElementRefs = ({ id, meta }: Props) => {
  const storage = useContext(ElementRefsContext);

  const set = useCallback(
    (el: HTMLElement | null) => {
      if (el) {
        storage.refs[id] = { el, meta };
      } else {
        delete storage.refs[id];
      }
    },
    [storage, id, meta],
  );

  useEffect(
    () => () => {
      delete storage.refs[id];
    },
    [id, storage],
  );

  return { set };
};

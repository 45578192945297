import * as R from "rambda";

export const pick = <
  O extends Record<string, unknown>,
  // both `R.pick` and `utils/object/pick` have `K extends string`
  K extends keyof O,
>(
  keys: K[],
  obj: O,
) => R.pick(keys, obj);

import { useTranslation } from "i18n";
import { isNumber } from "fp-ts/number";
import { GeneralOptions } from "../../../FormEditor/controlTypes/number/GeneralOptions";
import { Validation } from "../../../FormEditor/controlTypes/number/Validation";
import { castNumberMaybe, castStringMaybe } from "../../../utils/cast";
import { ControlTypeValuesErrors } from "../../../types/control/values";
import { ControlTypeRegistry } from "../../../types/control";
import { ControlType } from "../../../types/control/type";
import { ControlUiSchemaOptionsRegistry } from "../../../types/control/uiSchemaOptions";
import { pick } from "../../../utils/object";

type Props = Pick<ReturnType<typeof useTranslation>, "t">;

const type = ControlType.number;

export const makeTypeNumber = ({
  t,
}: Props): ControlTypeRegistry[typeof type] => ({
  type,
  label: t("number"),

  formats: {
    integer: { label: t("integer") },
    float: { label: t("float") },
  },

  detect: ({ dataSchema }) =>
    dataSchema.type === "number" || dataSchema.type === "integer",

  values: {
    default: {
      type,
      format: "integer",
      showLabel: true,
      required: true,
    },
    validate: (v) => {
      const errors: ControlTypeValuesErrors<typeof type> = {};

      if (isNumber(v.min) && isNumber(v.max)) {
        if (v.min > v.max) {
          errors.min = t("Can't be greater than max");
        }
        if (v.max < v.min) {
          errors.max = t("Can't be lower than min");
        }
      }

      return errors;
    },

    fromSchema: ({
      dataSchema,
      dataSchemaRequired,
      uiSchema: { label, options: o = {} },
    }) => ({
      type,

      format: dataSchema.type === "integer" ? "integer" : "float",

      showLabel: !!o.showLabel,
      label: castStringMaybe(label),

      required: dataSchemaRequired,
      unique: !!o.unique,
      readonly: !!o.readonly,
      hidden: !!o.hidden,

      placeholder: castStringMaybe(o.placeholder),
      defaultValue: castNumberMaybe(o.defaultValue),

      min: castNumberMaybe(o.min),
      max: castNumberMaybe(o.max),

      showError: !!o.showError,
      errorMessage: castStringMaybe(o.errorMessage),
    }),
    toSchema: (v) => ({
      dataSchema: {
        type: v.format === "integer" ? "integer" : "number",
      },
      dataSchemaRequired: !!v.required,
      uiSchema: {
        label: v.label,
        options: {
          ...pick(
            [
              "showLabel",

              "unique",
              "readonly",
              "hidden",

              "placeholder",
              "defaultValue",

              "min",
              "max",

              "showError",
              "errorMessage",
            ],
            v,
          ),
        } satisfies ControlUiSchemaOptionsRegistry[typeof type],
      },
    }),
  },

  UI: {
    getAffectingValues: (v) => ({}),
    get: () => ({
      GeneralOptions,
      tabs: {
        validation: {
          label: t("Validation"),
          Content: Validation,
        },
      },
    }),
  },
});

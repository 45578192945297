import { useBehaviorValue } from "react-rx/behaviorValue";
import { useMemo } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { UiState } from "../../types/uiState";

export const useSelectedCount = ({ ui$ }: { ui$: BehaviorValue<UiState> }) =>
  useBehaviorValue<number>(
    useMemo(
      () =>
        ui$.map(({ editing }) => {
          const selectedElements = editing?.selectedElements ?? {};

          return Object.values(selectedElements).filter(Boolean).length;
        }),
      [ui$],
    ),
  );

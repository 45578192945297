import React from "react";
import { useTranslation } from "i18n";
import { FormDataProps } from "../types";
import { Row } from "../../components/Row";
import { FormDataInput } from "../../components/formData/Input";
import { FormDataCheckbox } from "../../components/formData/Checkbox";
import { FormDataNumber } from "../../components/formData/Number";

export namespace Validation {
  export type Values = Partial<{
    min: number;
    max: number;

    showError: boolean;
    errorMessage: string;
  }>;

  export type Props = FormDataProps<Values>;
}

export const Validation = ({ formData$, onValuesChange }: Validation.Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row mb>
        <FormDataNumber
          formDataProp="min"
          {...{ formData$, onValuesChange }}
          label={t("Minimum")}
          placeholder={t("e.g. Value")}
        />
        <FormDataNumber
          formDataProp="max"
          {...{ formData$, onValuesChange }}
          label={t("Maximum")}
          placeholder={t("e.g. Value")}
        />
      </Row>
      <Row mb>
        <FormDataCheckbox
          formDataProp="showError"
          {...{ formData$, onValuesChange }}
          label={t("Show error")}
        />
      </Row>
      <Row>
        <FormDataInput
          formDataProp="errorMessage"
          {...{ formData$, onValuesChange }}
          label={t("Error message")}
          placeholder={t("e.g. This title is too long")}
        />
      </Row>
    </>
  );
};

import { Cursor } from "types";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { TransactionId } from "types/src/Transactions/Transaction";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { RepositoryId } from "types/src/Repositories/Repository";
import { omitEmpties } from "utils/value";
import {
  GetTransactionsQueryVariables,
  TransactionOrderField,
} from "../generated/graphql";
import { toApiOrderDirection } from "../transformers/OrderDirection";
import * as InventoryItems from "./InventoryItems";
import * as Repositories from "./Repositories";
import { Where } from "./Where";

export type WhereSchema = Where<{
  createdAt: Where.Ord<ISODate>;
  updatedAt: Where.Ord<ISODate>;
  hasItem: Where.Value<boolean>;
  hasItemWith: Where.WithWhereList<InventoryItems.WhereSchema>;
  hasRepository: Where.Value<boolean>;
  hasRepositoryWith: Where.WithWhereList<Repositories.WhereSchema>;
  id: Where.EqIn<TransactionId>;
  item: Where.EqIn<InventoryItemId>;
  quantity: Where.Ord<number>;
  repository: Where.EqIn<RepositoryId>;
}>;

export const whereSchema = Where.create<WhereSchema>({
  createdAt: Where.ord(),
  updatedAt: Where.ord(),
  hasItem: Where.value(),
  hasItemWith: Where.withWhereList(
    (): InventoryItems.WhereSchema => InventoryItems.whereSchema,
  ),
  hasRepository: Where.value(),
  hasRepositoryWith: Where.withWhereList(
    (): Repositories.WhereSchema => Repositories.whereSchema,
  ),
  id: Where.eqIn(),
  item: Where.eqIn("itemID"),
  quantity: Where.ord(),
  repository: Where.eqIn("repositoryID"),
});

export type GetTransactionsVarsWhere = Where.GetType<WhereSchema>;

export interface GetTransactionsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: GetTransactionsVarsWhere;
  orderBy?: {
    by: "createdAt" | "updatedAt" | "quantity" | "type";
    direction: "asc" | "desc";
  };
}

export function getTransactionsVarsToApiVars(
  vars: GetTransactionsVars,
): GetTransactionsQueryVariables {
  return (
    omitEmpties({
      first: vars.first,
      last: vars.last,
      after: vars.after,
      before: vars.before,
      where: omitEmpties(
        pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toNullable,
        ),
      ),
      orderBy: pipe(
        vars.orderBy,
        O.fromNullable,
        O.map((v) => ({
          field: pipe(
            v.by,
            (v) =>
              ({
                createdAt: TransactionOrderField.CreatedAt,
                updatedAt: TransactionOrderField.UpdatedAt,
                quantity: TransactionOrderField.Quantity,
                type: TransactionOrderField.Type,
              })[v],
          ),
          direction: pipe(v.direction, toApiOrderDirection),
        })),
        O.toUndefined,
      ),
    }) ?? {}
  );
}

import { ReactElement } from "react";
import { Selector, SelectorValue } from "state-manager";
import * as FormValue from "types/src/FormValue";
import { IconButton } from "ui/components/IconButton";
import { TrashIcon } from "icons/Trash";
import { Inline } from "ui/components/Inline";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import {
  SearchCombobox,
  SearchComboboxProps,
} from "@Containers/Form/SearchCombobox";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import { TranslatedStr } from "types/src/TranslatedStr";

export interface ItemProps {
  selector: Selector<{
    item: FormValue.Value<
      unknown,
      InventoryItemId,
      InventoryItemId | undefined
    >;
    items: Array<{ id: InventoryItemId; name: string }>;
    isSearching: boolean;
  }>;
  onRemove: () => void;
  onSearch: (s?: string) => void;
  onSelectItem: (id: InventoryItemId) => void;
}

export function Item(p: ItemProps): ReactElement {
  return (
    <Inline gap={"m"}>
      <SearchCombobox<InventoryItemId>
        getId={(v) => v}
        selector={Fp.flow(
          p.selector,
          (
            v,
          ): SelectorValue<
            SearchComboboxProps<InventoryItemId>["selector"]
          > => {
            return {
              value: O.fromNullable(v.item.value),
              options: v.items.map((v) => ({
                value: v.id,
                label: v.name as TranslatedStr,
              })),
              isLoading: v.isSearching,
            };
          },
        )}
        clearable={false}
        onSearch={p.onSearch}
        onChange={Fp.flow(O.toUndefined, (v) => v && p.onSelectItem(v))}
      />
      <IconButton onClick={p.onRemove}>
        <TrashIcon />
      </IconButton>
    </Inline>
  );
}

import { DataTypeId } from "types/src/DataType/DataType";
import { Cursor } from "types";
import { omitEmpties } from "utils/value";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { RepositoryId } from "types/src/Repositories/Repository";
import {
  GetRepositoriesQueryVariables,
  RepositoryOrderField,
} from "../generated/graphql";
import { toApiOrderDirection } from "../transformers/OrderDirection";
import * as ItemMovements from "../entities/ItemMovements";
import * as RepositoryMovements from "../entities/RepositoryMovements";
import * as Stocks from "../type/Stocks";
import * as Transactions from "../type/Transactions";
import { Where } from "./Where";

export type WhereSchema = Where<{
  data: Where.Map;
  createdAt: Where.Ord<ISODate>;
  updatedAt: Where.Ord<ISODate>;
  dataType: Where.EqNil<DataTypeId>;
  id: Where.EqNil<RepositoryId>;
  parent: Where.EqNil<RepositoryId>;
  virtual: Where.Value<boolean>;
  type: Where.Value<"dynamic" | "static">;
  name: Where.Text;
  hasItemMovementFrom: Where.Value<boolean>;
  hasItemMovementFromWith: Where.WithWhereList<ItemMovements.WhereSchema>;
  hasItemMovementTo: Where.Value<boolean>;
  hasItemMovementToWith: Where.WithWhereList<ItemMovements.WhereSchema>;
  hasRepositoryMovementFrom: Where.Value<boolean>;
  hasRepositoryMovementFromWith: Where.WithWhereList<RepositoryMovements.WhereSchema>;
  hasRepositoryMovement: Where.Value<boolean>;
  hasRepositoryMovementWith: Where.WithWhereList<RepositoryMovements.WhereSchema>;
  hasRepositoryMovementTo: Where.Value<boolean>;
  hasRepositoryMovementToWith: Where.WithWhereList<RepositoryMovements.WhereSchema>;
  hasStocks: Where.Value<boolean>;
  hasStocksWith: Where.WithWhereList<Stocks.WhereSchema>;
  hasTransactions: Where.Value<boolean>;
  hasTransactionsWith: Where.WithWhereList<Transactions.WhereSchema>;
}>;

export const whereSchema = Where.create<WhereSchema>({
  data: Where.map("Data"),
  createdAt: Where.ord(),
  updatedAt: Where.ord(),
  dataType: Where.eqNil("dataTypeID"),
  id: Where.eqNil(),
  parent: Where.eqNil("parentID"),
  virtual: Where.value("virtualRepo"),
  type: Where.value(),
  name: Where.text(),
  hasItemMovementFrom: Where.value("hasItemMovementFromRepositories"),
  hasItemMovementFromWith: Where.withWhereList(
    (): ItemMovements.WhereSchema => ItemMovements.whereSchema,
    "hasItemMovementFromRepositoriesWith",
  ),
  hasItemMovementTo: Where.value("hasItemMovementToRepositories"),
  hasItemMovementToWith: Where.withWhereList(
    (): ItemMovements.WhereSchema => ItemMovements.whereSchema,
    "hasItemMovementToRepositoriesWith",
  ),
  hasRepositoryMovementFrom: Where.value(
    "hasRepositoryMovementFromRepositories",
  ),
  hasRepositoryMovementFromWith: Where.withWhereList(
    (): RepositoryMovements.WhereSchema => RepositoryMovements.whereSchema,
    "hasRepositoryMovementFromRepositoriesWith",
  ),
  hasRepositoryMovement: Where.value("hasRepositoryMovementRepositories"),
  hasRepositoryMovementWith: Where.withWhereList(
    (): RepositoryMovements.WhereSchema => RepositoryMovements.whereSchema,
    "hasRepositoryMovementRepositoriesWith",
  ),
  hasRepositoryMovementTo: Where.value("hasRepositoryMovementToRepositories"),
  hasRepositoryMovementToWith: Where.withWhereList(
    (): RepositoryMovements.WhereSchema => RepositoryMovements.whereSchema,
    "hasRepositoryMovementToRepositoriesWith",
  ),
  hasStocks: Where.value("hasRepositoryStocks"),
  hasStocksWith: Where.withWhereList(
    (): Stocks.WhereSchema => Stocks.whereSchema,
    "hasRepositoryStocksWith",
  ),
  hasTransactions: Where.value("hasRepositoryTransactions"),
  hasTransactionsWith: Where.withWhereList(
    (): Transactions.WhereSchema => Transactions.whereSchema,
    "hasRepositoryTransactionsWith",
  ),
});

export type RepositoryWhere = Where.GetType<WhereSchema>;

export interface GetRepositoryVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: RepositoryWhere;
  orderBy?: {
    by: "createdAt" | "updatedAt" | "name" | "type" | "virtualRepo";
    direction: "asc" | "desc";
  };
}

export function repositoryWhereToApi(
  vars: GetRepositoryVars,
): GetRepositoriesQueryVariables | undefined {
  return omitEmpties({
    first: vars.first,
    last: vars.last,
    after: vars.after,
    before: vars.before,
    where: pipe(
      vars.where,
      O.fromNullable,
      O.map(Where.toApiWhere(whereSchema)),
      O.toUndefined,
    ),
    orderBy: pipe(
      vars.orderBy,
      O.fromNullable,
      O.map((o) => ({
        direction: toApiOrderDirection(o.direction),
        field: {
          createdAt: RepositoryOrderField.CreatedAt,
          updatedAt: RepositoryOrderField.UpdatedAt,
          name: RepositoryOrderField.Name,
          type: RepositoryOrderField.Type,
          virtualRepo: RepositoryOrderField.VirtualRepo,
        }[o.by],
      })),
      O.toUndefined,
    ),
  });
}

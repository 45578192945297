import { Footer as FooterLayout } from "ui/layouts/Create/Footer";
import { Selector, useSelector } from "state-manager";
import { flow } from "fp-ts/function";
import { ItemSetCreate as Main } from "state-manager/states/Ready/states/DataManager/states/ItemSets/states/Create";
import { useTranslation } from "i18n";

export interface FooterProps {
  selector: Selector<Main.State>;
  dispatch: (a: Main.Actions) => void;
  onCancel: () => void;
}

export function Footer({ selector, dispatch, onCancel }: FooterProps) {
  const { t } = useTranslation();
  const isSaving = useSelector(flow(selector, Main.instance.states.saving.is));

  return (
    <FooterLayout
      disabled={isSaving}
      onSubmit={() => dispatch(Main.instance.actions.submit.create())}
      onCancel={onCancel}
      submitTitle={t("Add item set")}
    />
  );
}

import { BehaviorValue } from "rx-addons/BehaviorValue";
import { TextField } from "@mui/material";
import { useRandomId } from "react-utils/hooks";
import { useFormDataProp } from "../../hooks/useFormDataProp";
import { InputWithLabel } from "../InputWithLabel";
import { sxFullWidth } from "../../../../common/sxs";

type Value = string;

export const FormDataInput = <Prop extends string>({
  formData$,
  formDataProp,
  onValuesChange,

  label,
  placeholder,
}: {
  formDataProp: Prop;
  formData$: BehaviorValue<{
    values: Partial<Record<Prop, Value>>;
    errors: Partial<Record<Prop, string>>;
  }>;
  onValuesChange: (changes: Partial<Record<Prop, Value>>) => void;

  label: string;
  placeholder?: string;
}) => {
  const { value, error } = useFormDataProp({
    formData$,
    prop: formDataProp,
    defaultValue: "",
  });
  const randomId = useRandomId();

  return (
    <InputWithLabel label={label} id={randomId}>
      <TextField
        id={randomId}
        sx={sxFullWidth}
        placeholder={placeholder}
        value={value}
        onChange={({ target: { value } }) =>
          onValuesChange({
            [formDataProp]: value,
          } as /*why doesn't work without cast?*/ Record<Prop, Value>)
        }
        error={!!error?.length}
      />
    </InputWithLabel>
  );
};

import { useCallback, useContext, useMemo } from "react";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { UiContext } from "../../contexts/Ui";

type Props = { id: string };

type Return = {
  expanded: boolean;
  onExpandedChange: (expanded: boolean) => void;
};

export const useElementExpanded = ({ id }: Props): Return => {
  const { ui$, onUiChange } = useContext(UiContext);

  const expandedElements = useBehaviorValue(
    useMemo(() => ui$.map(({ expandedElements }) => expandedElements), [ui$]),
  );

  const onExpandedChange = useCallback<Return["onExpandedChange"]>(
    (expanded) =>
      onUiChange({
        expandedElements: {
          ...expandedElements,
          [id]: expanded,
        },
      }),
    [id, expandedElements, onUiChange],
  );

  const expanded = expandedElements[id] ?? false;

  return { expanded, onExpandedChange };
};

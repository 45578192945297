import React from "react";
import { UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { ElementTypes } from "../types/element";
import { Control as ControlInEditor } from "../FormEditor/elementTypes/Control";
import { VerticalLayout as VerticalLayoutInEditor } from "../FormEditor/elementTypes/ListLayout/Vertical";
import { HorizontalLayout as HorizontalLayoutInEditor } from "../FormEditor/elementTypes/ListLayout/Horizontal";

export const defaultElementTypes: ElementTypes = {
  [UiSchemaElementType.control]: {
    type: UiSchemaElementType.control,
    RenderInEditor: ControlInEditor,
  },
  [UiSchemaElementType.group]: {
    type: UiSchemaElementType.group,
    RenderInEditor: ({ uiSchemaPathEncoded }) =>
      React.createElement("div", {
        children: `todo: Group (path=${uiSchemaPathEncoded})`,
      }),
  },
  [UiSchemaElementType.horizontalLayout]: {
    type: UiSchemaElementType.horizontalLayout,
    RenderInEditor: HorizontalLayoutInEditor,
  },
  [UiSchemaElementType.verticalLayout]: {
    type: UiSchemaElementType.verticalLayout,
    RenderInEditor: VerticalLayoutInEditor,
  },
};

import { RootState, useSelector } from "state-manager";
import { ReactNode } from "react";
import { flow } from "fp-ts/function";
import { DataTypeEdit as Main } from "state-manager/states/Ready/states/DataTypeEdit";
import { Skeleton } from "ui/components/Skeleton";
import { ContentLayoutHeader } from "ui/layouts/ContentLayout";
import { Button } from "ui/components/Button";
import * as Fp from "fp-ts/function";
import { useTranslation } from "i18n";

export interface HeaderProps {
  selector: (s: RootState) => Main.State;
  dispatch: (a: Main.Actions) => void;
}

export function Header(p: HeaderProps): ReactNode {
  const { t } = useTranslation();
  const name = useSelector(
    flow(p.selector, (s) => {
      if (
        Main.instance.states.loading.is(s) ||
        Main.instance.states.loadError.is(s)
      )
        return null;

      return s.payload.name;
    }),
  );

  return (
    <ContentLayoutHeader
      title={name ?? <Skeleton contentWidth={5} />}
      actions={
        <>
          <Button
            variant={"outlined"}
            onClick={Fp.flow(Main.instance.actions.cancel.create, p.dispatch)}
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={Fp.flow(Main.instance.actions.submit.create, p.dispatch)}
          >
            {t("Save")}
          </Button>
        </>
      }
    />
  );
}

import { silentUnreachableError } from "utils/exceptions";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import * as Obj from "utils/object";
import * as Fp from "fp-ts/function";
import { Typed } from "utils/Typed";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import {
  createCustomerSearchState,
  createPickingOrderItemsState,
  createSchemaFieldsState,
} from "./utils";
import * as Exits from "./types/Exits";

export const reducer = (p: string) => {
  const customerSearch = createCustomerSearchState(p);
  const schemaFields = createSchemaFieldsState(p);
  const pickingOrderItemsState = createPickingOrderItemsState(p);
  const isLoadFail = Actions.isLoadFail(p);
  const isLoadSuccess = Actions.isLoadSuccess(p);
  const isSubmit = Actions.isSubmit(p);
  const isSaveFail = Actions.isSaveFail(p);
  const isSaveSuccess = Actions.isSaveSuccess(p);
  const isRemove = Actions.isRemove(p);
  const isRemoveConfirm = Actions.isRemoveConfirm(p);
  const isRemoveDecline = Actions.isRemoveDecline(p);
  const isRemoveFail = Actions.isRemoveFail(p);
  const isRemoveSuccess = Actions.isRemoveSuccess(p);
  const isLoadError = State.isLoadError(p);
  const isLoading = State.isLoading(p);
  const loadError = State.loadError(p);
  const saving = State.saving(p);
  const removeConfirmation = State.removeConfirmation(p);
  const removing = State.removing(p);
  const isSaving = State.isSaving(p);
  const ready = State.ready(p);
  const isReady = State.isReady(p);
  const isRemoveConfirmation = State.isRemoveConfirmation(p);
  const isRemoving = State.isRemoving(p);
  const exits = Exits.exits(`${p}:exits`);

  return (
    s: State.State,
    a: Actions.Actions,
  ): E.Either<Typed.GetTypes<typeof exits>, State.State> => {
    if (isLoadFail(a)) {
      if (!isLoading(s)) return E.right(s);

      return E.right(
        loadError({
          ...s.payload,
          error: a.payload,
        }),
      );
    }
    if (isLoadSuccess(a)) {
      if (!isLoading(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          submitted: false,
          fields: schemaFields.init({
            schema: a.payload.dataType.schema,
            uiSchema: a.payload.dataType.ui,
            values: a.payload.order.fields,
          }),
          customer: customerSearch.states.selected.create({
            item: {
              id: a.payload.order.customerId,
              title: a.payload.order.customerId,
            },
            items: [
              {
                id: a.payload.order.customerId,
                title: a.payload.order.customerId,
              },
            ],
            query: O.none,
          }),
          dataTypes: a.payload.dataTypes,
          removedItems: [],
          _initial: a.payload.order,
          items: pickingOrderItemsState.init({
            items: Fp.pipe(
              a.payload.order.items.map((v) => {
                const state =
                  pickingOrderItemsState.createPickingOrderItemState(v.id);
                const dataType =
                  a.payload.dataTypes.find((d) => d.id === v.dataTypeId) ??
                  a.payload.dataTypes.find((v) => v.isDefault) ??
                  a.payload.dataTypes[0];

                return [
                  v.id,
                  state.init({
                    fields: state.schemaFieldsState.init({
                      schema: dataType.schema,
                      uiSchema: dataType.ui,
                      values: v.fields,
                    }),
                    dataTypes: a.payload.dataTypes,
                    dataTypeId: v.dataTypeId,
                    sku: v.sku,
                    quantity: v.quantity,
                  }),
                ] satisfies [string, unknown];
              }),
              Obj.fromEntries,
            ),
            dataTypes: a.payload.dataTypes,
          }),
        }),
      );
    }
    if (customerSearch.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          customer: customerSearch.reducer(s.payload.customer, a),
        }),
      );
    }
    if (schemaFields.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          fields: Fp.pipe(
            schemaFields.reducer(s.payload.fields, a),
            E.getOrElse(() => s.payload.fields),
          ),
        }),
      );
    }
    if (pickingOrderItemsState.isActions(a)) {
      if (isLoading(s) || isLoadError(s)) return E.right(s);

      return E.right(
        ready({
          ...s.payload,
          items: Fp.pipe(
            pickingOrderItemsState.reducer(s.payload.items, a),
            E.getOrElse(() => s.payload.items),
          ),
        }),
      );
    }
    if (isSaveFail(a)) {
      return isSaving(s) ? E.right(ready(s.payload)) : E.right(s);
    }
    if (isSaveSuccess(a)) {
      return isSaving(s) ? E.left(exits.saved.create(a.payload)) : E.right(s);
    }

    if (isSubmit(a)) {
      if (!isReady(s)) return E.right(s);

      const customer = s.payload.customer;
      const items = Fp.pipe(
        pickingOrderItemsState.reducer(
          s.payload.items,
          pickingOrderItemsState.actions.submit.create(),
        ),
        E.getOrElse(() => s.payload.items),
      );
      const fields = Fp.pipe(
        schemaFields.reducer(
          s.payload.fields,
          schemaFields.actions.submit.create(),
        ),
        E.getOrElse(() => s.payload.fields),
      );

      if (
        customerSearch.states.selected.is(customer) &&
        pickingOrderItemsState.states.valid.is(items) &&
        schemaFields.states.valid.is(fields)
      ) {
        return E.right(
          saving({
            ...s.payload,
            fields,
            customer,
            items,
            submitted: true,
          }),
        );
      }

      return E.right(
        ready({
          ...s.payload,
          fields,
          customer,
          items,
          submitted: true,
        }),
      );
    }

    if (isRemove(a)) {
      if (isReady(s) || isSaving(s)) {
        return E.right(removeConfirmation(s.payload));
      }

      return E.right(s);
    }

    if (isRemoveDecline(a)) {
      if (isRemoveConfirmation(s)) {
        return E.right(ready(s.payload));
      }

      return E.right(s);
    }

    if (isRemoveConfirm(a)) {
      if (isRemoveConfirmation(s)) {
        return E.right(removing(s.payload));
      }

      return E.right(s);
    }

    if (isRemoveFail(a)) {
      if (isRemoving(s)) {
        return E.right(ready(s.payload));
      }

      return E.right(s);
    }

    if (isRemoveSuccess(a)) {
      if (isRemoving(s)) {
        return E.left(exits.removed.create(s.payload.id));
      }

      return E.right(s);
    }

    silentUnreachableError(a);
    return E.right(s);
  };
};

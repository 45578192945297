import { FormEditor as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor";
import * as R from "rambda";
import {
  UiSchema,
  UiSchemaElement,
  UiSchemaElementType,
} from "types/src/jsonSchema/uiSchema";
import { findElementPath } from "../../utils/uiSchema/findElementPath";

export namespace movedElements {
  export type Props = {
    uiSchema: UiSchema;
  } & Pick<
    Parameters<UI.Props["onDragEnd"]>[0],
    "droppable" | "draggable" | "closest"
  >;
}

// todo: test
export const movedElements = ({
  uiSchema: oldSchema,
  droppable,
  draggable,
  closest,
}: movedElements.Props): UiSchema | undefined => {
  const elementPath = findElementPath(
    {
      id: draggable.id,
      type: draggable.meta.elementType as UiSchemaElementType,
    },
    oldSchema,
  );
  if (!elementPath) {
    return;
  }

  const element = R.path<UiSchemaElement>(elementPath, oldSchema);
  if (!element) {
    return;
  }

  const schema = R.dissocPath<UiSchema>(elementPath, oldSchema);

  return closest
    ? moveNextToClosest({ schema, element, closest })
    : moveIntoDroppable({ schema, element, droppable });
};

const moveNextToClosest = ({
  schema,
  closest,
  element,
}: {
  schema: UiSchema;
  element: UiSchemaElement;
  closest: Exclude<movedElements.Props["closest"], undefined>;
}): UiSchema | undefined => {
  const destPath = findElementPath(
    {
      id: closest.id,
      type: closest.meta.elementType as UiSchemaElementType,
    },
    schema,
  );
  if (!destPath) {
    return;
  }

  const destIndex = destPath.slice(-1)[0];
  if (typeof destIndex !== "number") {
    return;
  }

  const destElementsPath = destPath.slice(0, destPath.length - 1),
    destElements = R.path<UiSchemaElement[]>(destElementsPath, schema);
  if (!destElements) {
    return;
  }

  const newDestElements: UiSchemaElement[] = [
    ...destElements.slice(
      0,
      destIndex + (closest.distance.from === "top" ? 0 : 1),
    ),
    element,
    ...destElements.slice(
      destIndex + (closest.distance.from === "top" ? 0 : 1),
    ),
  ];

  return R.assocPath(destElementsPath, newDestElements, schema);
};

const moveIntoDroppable = ({
  schema,
  element,
  droppable,
}: {
  schema: UiSchema;
  element: UiSchemaElement;
  droppable: movedElements.Props["droppable"];
}): UiSchema | undefined => {
  const destPath = findElementPath(
    {
      id: droppable.id,
      type: droppable.meta.elementType as UiSchemaElementType,
    },
    schema,
  );
  if (!destPath) {
    return;
  }

  const destElementsPath: typeof destPath = [...destPath, "elements"],
    destElements = R.path<UiSchemaElement[]>(destElementsPath, schema);
  if (!destElements) {
    return;
  }

  const newDestElements: UiSchemaElement[] = [...destElements, element];

  return R.assocPath(destElementsPath, newDestElements, schema);
};

import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/AddRounded";
import React from "react";
import { useTranslation } from "i18n";
import { Sxs } from "../../../../../../types/styles";

export namespace FooterButtons {
  export type Props = {
    onAddSubfield: () => void;
    onUngroup: () => void;
  };
}

export const FooterButtons = ({
  onAddSubfield,
  onUngroup,
}: FooterButtons.Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={sxs.root}>
      <Button
        onClick={onAddSubfield}
        variant="text"
        sx={sxs.btnAdd}
        startIcon={<AddIcon />}
      >
        {t("Add subfield")}
      </Button>
      <Button onClick={onUngroup} variant="text" sx={sxs.btnUngroup}>
        {t("Ungroup")}
      </Button>
    </Box>
  );
};

const sxs = {
  root: (theme) => ({
    mt: 4,
    display: "flex",
    justifyContent: "space-between",

    ".MuiButton-root": {
      padding: 0,
    },
  }),
  btnAdd: (theme) => ({
    color: theme.palette.primary.dark,
  }),
  btnUngroup: (theme) => ({
    color: theme.palette.error.dark,
  }),
} satisfies Sxs;

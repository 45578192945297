import { NEVER } from "rxjs";
import { Typed } from "utils/Typed";
import { Epic } from "../../types/RootEpic";
import { createActions } from "./types/Actions";
import { createStates } from "./types/State";

export const epic =
  (
    p: string,
  ): Epic<
    Typed.GetTypes<ReturnType<typeof createActions>>,
    Typed.GetTypes<ReturnType<typeof createStates>>
  > =>
  () =>
    NEVER;

import { useTranslation } from "i18n";
import { silentUnreachableError } from "utils/exceptions";
import { isNumber } from "fp-ts/number";
import { ControlTypeRegistry } from "../../../types/control";
import { ControlType } from "../../../types/control/type";
import { castNumberMaybe, castStringMaybe } from "../../../utils/cast";
import { pick } from "../../../utils/object";
import { ControlUiSchemaOptionsRegistry } from "../../../types/control/uiSchemaOptions";
import { Validation } from "../../../FormEditor/controlTypes/string/Validation";
import { SelectOptions } from "../../../FormEditor/controlTypes/string/SelectOptions";
import { GeneralOptions } from "../../../FormEditor/controlTypes/string/GeneralOptions";
import { ControlTypeValuesErrors } from "../../../types/control/values";

type Props = Pick<ReturnType<typeof useTranslation>, "t">;

type SelectOption = { label: string; value: string };

const type = ControlType.string;

export const makeTypeString = ({
  t,
}: Props): ControlTypeRegistry[typeof type] => ({
  type,
  label: t("string"),

  formats: {
    text: { label: t("text") },
    select: { label: t("select") },
  },

  detect: ({ dataSchema }) =>
    dataSchema.type === "string" && dataSchema.format === undefined,

  values: {
    default: {
      type,
      format: "text",
      showLabel: true,
      required: true,
    },
    validate: (v) => {
      const errors: ControlTypeValuesErrors<typeof type> = {};

      if (v.format === "text") {
        if (isNumber(v.minLength) && isNumber(v.maxLength)) {
          if (v.minLength > v.maxLength) {
            errors.minLength = t("Can't be greater than max");
          }
          if (v.maxLength < v.minLength) {
            errors.maxLength = t("Can't be lower than min");
          }
        }
      }

      return errors;
    },

    fromSchema: ({
      dataSchemaRequired,
      uiSchema: { label, options: o = {} },
    }) => {
      const format_ = o.format,
        format =
          format_ && ("text" === format_ || "select" === format_)
            ? format_
            : "text";

      const common = {
        type,

        showLabel: !!o.showLabel,
        label: castStringMaybe(label),

        required: dataSchemaRequired,
        unique: !!o.unique,
        readonly: !!o.readonly,
        hidden: !!o.hidden,

        placeholder: castStringMaybe(o.placeholder),
        defaultValue: castStringMaybe(o.defaultValue),
      } as const;

      switch (format) {
        case "text": {
          return {
            ...common,
            format,

            asTextarea: !!o.asTextarea,

            minLength: castNumberMaybe(o.minLength),
            maxLength: castNumberMaybe(o.maxLength),
            pattern: castStringMaybe(o.pattern),

            showError: !!o.showError,
            errorMessage: castStringMaybe(o.errorMessage),
          };
        }
        case "select": {
          return {
            ...common,
            format,
            // todo: better validation+cast
            options: (o.options ?? []) as SelectOption[],
          };
        }
      }

      silentUnreachableError(format);
    },
    toSchema: (v) => {
      const commonOptions = pick(
        [
          "showLabel",

          "unique",
          "readonly",
          "hidden",

          "placeholder",
          "defaultValue",
        ],
        v,
      );

      return {
        dataSchema: {
          type: "string",
        },
        dataSchemaRequired: !!v.required,
        uiSchema: {
          label: v.label,
          options: (v.format === "text"
            ? {
                ...commonOptions,
                ...pick(
                  [
                    "format",

                    "asTextarea",

                    "minLength",
                    "maxLength",
                    "pattern",

                    "showError",
                    "errorMessage",
                  ],
                  v,
                ),
              }
            : {
                ...commonOptions,
                ...pick(["format", "options"], v),
              }) satisfies ControlUiSchemaOptionsRegistry[typeof type],
        },
      };
    },
  },

  UI: {
    getAffectingValues: ({ format }) => ({ format }),
    get: ({ format }) => ({
      GeneralOptions,
      tabs: {
        ...(format === "text"
          ? {
              validation: {
                label: t("Validation"),
                Content: Validation,
              },
            }
          : {}),
        ...(format === "select"
          ? {
              options: {
                label: t("Options"),
                Content: SelectOptions,
              },
            }
          : {}),
      },
    }),
  },
});

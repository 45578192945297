import React from "react";
import {
  ControlProps,
  rankWith,
  JsonFormsRendererRegistryEntry,
  isNumberControl,
  or,
  isIntegerControl,
} from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";

import { InputNumber } from "../components/InputNumber";
import { Control } from "../components/Control";

const MaterialTextControl = React.memo((props: ControlProps) => (
  <Control {...props} input={InputNumber} />
));

export const entry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(3, or(isNumberControl, isIntegerControl)),
  renderer: withJsonFormsControlProps(MaterialTextControl),
};

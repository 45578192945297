import * as StateModule from "./types/State";
import * as ActionsModule from "./types/Actions";
import { reducer } from "./reducer";
import { epic } from "./epic";
import { createSchemaFieldsState } from "./utils";
import { init } from "./types/State";

export function createState(p: string) {
  return {
    states: {
      ready: {
        create: StateModule.ready(p),
        is: StateModule.isReady(p),
      },
      valid: {
        create: StateModule.valid(p),
        is: StateModule.isValid(p),
      },
    },
    actions: {
      setDataType: {
        create: ActionsModule.setDataType(p),
        is: ActionsModule.isSetDataType(p),
      },
      setSku: {
        create: ActionsModule.setSku(p),
        is: ActionsModule.isSetSku(p),
      },
      setQuantity: {
        create: ActionsModule.setQuantity(p),
        is: ActionsModule.isSetQuantity(p),
      },
      submit: {
        create: ActionsModule.submit(p),
        is: ActionsModule.isSubmit(p),
      },
    },
    isState: StateModule.isState(p),
    isActions: ActionsModule.isActions(p),
    reducer: reducer(p),
    epic: epic(p),
    schemaFieldsState: createSchemaFieldsState(p),
    init: init(p),
  };
}

export type Actions = ActionsModule.Actions;
export type State = StateModule.State;

export type StateConstructor = ReturnType<typeof createState>;

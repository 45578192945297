/* eslint-disable @typescript-eslint/no-explicit-any */

import * as Obj from "utils/object";
import { silentUnreachableError } from "utils/exceptions";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { omitEmpties } from "utils/value";

// region WhereEq
interface WhereEq<T> {
  eq?: T;
  neq?: T;
}

type ApiWhereEq<T, K extends string> = {
  [k in K | `${K}NEQ`]?: T;
};
// endregion

// region WhereEqIn
interface WhereEqIn<T> extends WhereEq<T> {
  in?: T[];
  notIn?: T[];
}

type ApiWhereEqIn<T, K extends string> = ApiWhereEq<T, K> & {
  [k in `${K}In` | `${K}NotIn`]?: T[];
};
// endregion

// region WhereOrd
interface WhereOrd<T> extends WhereEqIn<T> {
  gt?: T;
  gte?: T;
  lt?: T;
  lte?: T;
}

type ApiWhereOrd<T, K extends string> = ApiWhereEqIn<T, K> & {
  [k in `${K}GT` | `${K}GTE` | `${K}LT` | `${K}LE`]?: T;
};
// endregion

// region WhereOrdNil
interface WhereOrdNil<T> extends WhereEqInNil<T> {
  gt?: T;
  gte?: T;
  lt?: T;
  lte?: T;
}

type ApiWhereOrdNil<T, K extends string> = ApiWhereEqInNil<T, K> & {
  [k in `${K}GT` | `${K}GTE` | `${K}LT` | `${K}LE`]?: T;
};
// endregion

// region WhereNil
interface WhereNil {
  isNil?: boolean;
}

type ApiWhereNil<K extends string> = {
  [k in `${K}IsNil` | `${K}NotNil`]?: boolean;
};
// endregion

// region WhereMap
interface WhereMap {
  eq?: [string, string];
  hasKey?: string;
  in?: [string, string, ...string[]];
  contains?: [string, string];
}

type ApiWhereMap<K extends string> = {
  [k in K | `${K}In` | `${K}Contains` | `${K}HasKey`]?: k extends `${K}HasKey`
    ? string
    : [string, string];
};
// endregion

// region WhereEqInNil
interface WhereEqInNil<T> extends WhereEqIn<T>, WhereNil {}

type ApiWhereEqInNil<T, K extends string> = ApiWhereEqIn<T, K> & ApiWhereNil<K>;
// endregion

// region WhereText
interface WhereText extends WhereOrd<string> {
  contains?: string;
  containsFold?: string;
  equalsFold?: string;
  prefix?: string;
  suffix?: string;
}

type ApiWhereText<K extends string> = ApiWhereOrd<string, K> & {
  [k in
    | `${K}Contains`
    | `${K}ContainsFold`
    | `${K}EqualFold`
    | `${K}HasPrefix`
    | `${K}HasSuffix`]?: string;
};
// endregion

// region WhereValue
type WhereValue<T> = T;

type ApiWhereValue<K extends string, T> = {
  [k in K]?: T;
};
// endregion

type WhereBase = {
  [k in string]:
    | Where.WithWhereList<any>
    | Where.Eq<any>
    | Where.WithWhere<any>
    | Where.EqIn<any>
    | Where.EqNil<any>
    | Where.Ord<any>
    | Where.OrdNil<any>
    | Where.Map
    | Where.Text
    | Where.Value<any>;
};

export type Where<T extends WhereBase> = T;

export namespace Where {
  export function create<W extends WhereBase>(w: W): W {
    return w;
  }

  export function merge<W extends WhereBase>(
    a: Where.GetType<W>,
    b: Where.GetType<W>,
  ): Where.GetType<W> {
    return {
      ...a,
      ...b,
      and: [...(a?.and ?? []), ...(b.and ?? [])],
      or: [...(a?.and ?? []), ...(b.and ?? [])],
      not: a.not && b.not ? merge(a.not, b.not) : a.not ? a.not : b.not,
    };
  }

  export function toApiWhere<W extends WhereBase>(
    schema: W,
  ): (where: Where.GetType<W>) => ToApi<W>;
  export function toApiWhere<W extends WhereBase>(
    schema: W,
    where: Where.GetType<W>,
  ): ToApi<W>;
  export function toApiWhere<W extends WhereBase>(
    ...a: [W, Where.GetType<W>] | [W]
  ):
    | ((where: Where.GetType<W>) => ToApi<W> | undefined)
    | ToApi<W>
    | undefined {
    const [schema] = a;

    return a[1] !== undefined ? cb(a[1]) : cb;

    function cb(where: Where.GetType<W>): Record<string, unknown> | undefined {
      return omitEmpties(
        Obj.reduce(
          (acc, i, k) => {
            if (i === undefined) return acc;

            switch (k) {
              case "and": {
                acc["and"] = (i as Where.GetType<W>["and"])?.map(
                  toApiWhere(schema),
                );
                return acc;
              }
              case "or": {
                acc["or"] = (i as Where.GetType<W>["or"])?.map(
                  toApiWhere(schema),
                );
                return acc;
              }
              case "not": {
                acc["not"] = Fp.pipe(
                  i as Where.GetType<W>,
                  O.fromNullable,
                  O.map(toApiWhere(schema)),
                  O.toUndefined,
                );
                return acc;
              }
              default: {
                if (!schema[k]) return acc;

                const type = schema[k].type;
                const key = schema[k].key ?? k;

                switch (type) {
                  case "value":
                    return {
                      ...acc,
                      ...schema[k].schema(i as WhereText, key as string),
                    };
                  case "eq":
                    return {
                      ...acc,
                      ...schema[k].schema(i as WhereEq<any>, key as string),
                    };
                  case "eqIn":
                    return {
                      ...acc,
                      ...schema[k].schema(i as WhereEqIn<any>, key as string),
                    };
                  case "eqNil":
                    return {
                      ...acc,
                      ...schema[k].schema(
                        i as WhereEqInNil<any>,
                        key as string,
                      ),
                    };
                  case "ord":
                    return {
                      ...acc,
                      ...schema[k].schema(i as WhereOrd<any>, key as string),
                    };
                  case "ordNil":
                    return {
                      ...acc,
                      ...schema[k].schema(i as WhereOrd<any>, key as string),
                    };
                  case "map":
                    return {
                      ...acc,
                      ...schema[k].schema(i as WhereMap, key as string),
                    };
                  case "text":
                    return {
                      ...acc,
                      ...schema[k].schema(i as WhereText, key as string),
                    };
                  case "withWhere":
                    return {
                      ...acc,
                      [key]: schema[k].schema(i),
                    };
                  case "withWhereList":
                    return {
                      ...acc,
                      [key]: schema[k].schema(i),
                    };

                  default: {
                    silentUnreachableError(type);
                    return acc;
                  }
                }
              }
            }
          },
          {} as Record<string, unknown>,
          where,
        ),
      );
    }
  }

  export interface Eq<T> {
    type: "eq";
    key: string | undefined;
    schema: <K extends string>(v: WhereEq<T>, k: K) => ApiWhereEq<T, K>;
  }
  export interface WithWhere<T extends WhereBase> {
    type: "withWhere";
    key: string | undefined;
    schema: (v: GetType<T>) => ToApi<T>;
  }
  export interface WithWhereList<T extends WhereBase> {
    type: "withWhereList";
    key: string | undefined;
    schema: (v: GetType<T>[]) => ToApi<T>[];
  }
  export interface EqIn<T> {
    type: "eqIn";
    key: string | undefined;
    schema: <K extends string>(v: WhereEqIn<T>, k: K) => ApiWhereEqIn<T, K>;
  }
  export interface EqNil<T> {
    type: "eqNil";
    key: string | undefined;
    schema: <K extends string>(
      v: WhereEqInNil<T>,
      k: K,
    ) => ApiWhereEqInNil<T, K>;
  }
  export interface Ord<T> {
    type: "ord";
    key: string | undefined;
    schema: <K extends string>(v: WhereOrd<T>, k: K) => ApiWhereOrd<T, K>;
  }
  export interface OrdNil<T> {
    type: "ordNil";
    key: string | undefined;
    schema: <K extends string>(v: WhereOrdNil<T>, k: K) => ApiWhereOrdNil<T, K>;
  }
  export interface Map {
    type: "map";
    key: string | undefined;
    schema: <K extends string>(v: WhereMap, k: K) => ApiWhereMap<K>;
  }
  export interface Text {
    type: "text";
    key: string | undefined;
    schema: <K extends string>(v: WhereText, k: K) => ApiWhereText<K>;
  }
  export interface Value<T> {
    type: "value";
    key: string | undefined;
    schema: <K extends string>(v: WhereValue<T>, k: K) => ApiWhereValue<K, T>;
  }

  export const eq = <T>(key?: string): Eq<T> => ({
    type: "eq",
    key,
    schema: <K extends string>(v: WhereEq<T>, k: K) => {
      return {
        [k]: v.eq,
        [`${k}NEQ`]: v.neq,
      } as ApiWhereEq<T, K>;
    },
  });
  export const eqIn = <T>(key?: string): EqIn<T> => ({
    type: "eqIn",
    key,
    schema: <K extends string>(v: WhereEqIn<T>, k: K) =>
      ({
        ...eq(k).schema(v, k),
        [`${k}In`]: v.in,
        [`${k}NotIn`]: v.notIn,
      }) as ApiWhereEqIn<T, K>,
  });
  export const eqNil = <T>(key?: string): EqNil<T> => ({
    type: "eqNil",
    key,
    schema: <K extends string>(v: WhereEqInNil<T>, k: K) =>
      ({
        ...eqIn(k).schema(v, k),
        [`${k}IsNil`]: v.isNil === true ? true : undefined,
        [`${k}NotNil`]: v.isNil === false ? true : undefined,
      }) as ApiWhereEqInNil<T, K>,
  });
  export const withWhere = <T extends WhereBase>(
    t: () => T,
    key?: string,
  ): WithWhere<T> => ({
    type: "withWhere",
    key,
    schema: toApiWhere(t()),
  });
  export const withWhereList = <T extends WhereBase>(
    t: () => T,
    key?: string,
  ): WithWhereList<T> => ({
    type: "withWhereList",
    key,
    schema: (v) => v.map(toApiWhere(t())),
  });
  export const ord = <T>(key?: string): Ord<T> => ({
    type: "ord",
    key,
    schema: <K extends string>(v: WhereOrd<T>, k: K) =>
      ({
        ...eqIn().schema(v, k),
        [`${k}GT`]: v.gt,
        [`${k}GTE`]: v.gte,
        [`${k}LT`]: v.lt,
        [`${k}LTE`]: v.lte,
      }) as ApiWhereOrd<T, K>,
  });
  export const ordNil = <T>(key?: string): OrdNil<T> => ({
    type: "ordNil",
    key,
    schema: <K extends string>(v: WhereOrdNil<T>, k: K) =>
      ({
        ...eqNil().schema(v, k),
        ...ord().schema(v, k),
      }) as ApiWhereOrdNil<T, K>,
  });
  export const map = (key?: string): Map => ({
    type: "map",
    key,
    schema: <K extends string>(v: WhereMap, k: K) =>
      ({
        [k]: v.eq,
        [`${k}Contains`]: v.contains,
        [`${k}HasKey`]: v.hasKey,
        [`${k}In`]: v.in,
      }) as ApiWhereMap<K>,
  });
  export const text = (key?: string): Text => ({
    type: "text",
    key,
    schema: <K extends string>(v: WhereText, k: K) =>
      ({
        ...eqIn(k).schema(v, k),
        [`${k}Contains`]: v.contains,
        [`${k}ContainsFold`]: v.containsFold,
        [`${k}EqualFold`]: v.equalsFold,
        [`${k}HasPrefix`]: v.prefix,
        [`${k}HasSuffix`]: v.suffix,
      }) as ApiWhereText<K>,
  });
  export const value = <T>(key?: string): Value<T> => ({
    type: "value",
    key,
    schema: <K extends string>(v: WhereValue<T>, k: K) =>
      ({ [k]: v }) as ApiWhereValue<K, T>,
  });

  export type GetType<W extends WhereBase> = {
    [k in keyof W]?: Parameters<W[k]["schema"]>[0];
  } & {
    and?: GetType<W>[];
    or?: GetType<W>[];
    not?: GetType<W>;
  };

  type ToApi<T extends WhereBase> = Record<string, unknown>;
}

import { Content as UI } from "ui/packages/json-schema-form-builder/Left/FormEditor/elementTypes/Control/Content";
import React, { useMemo, useState } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { ControlTypeAndSchemas } from "../../../../types/control";
import { ControlType } from "../../../../types/control/type";
import { useUi } from "./hooks/useUi";
import { useGeneralFormData } from "./hooks/useGeneralFormData";
import { useFormDataController } from "./hooks/useFormDataController";

export namespace Content {
  export type Props = {
    typeAndSchemas$: BehaviorValue<ControlTypeAndSchemas<ControlType>>;
  };
}

export const Content = ({ typeAndSchemas$ }: Content.Props) => {
  const { formData$, onValuesChange } = useFormDataController({
    typeAndSchemas$,
  });
  const generalFormData = useGeneralFormData({ formData$ });

  const ui = useUi({
    typeAndSchemas$,
    values$: useMemo(() => formData$.map(({ values }) => values), [formData$]),
  });
  const customTabs = useMemo<UI.CustomTab[]>(
    () => Object.entries(ui.tabs).map(([id, { label }]) => ({ id, label })),
    [ui.tabs],
  );
  const [activeTabId, setActiveTabId] = useState<UI.TabId>(UI.tabIdGeneral);

  const TabContent =
    activeTabId === UI.tabIdGeneral
      ? ui.GeneralOptions
      : ui.tabs[activeTabId]?.Content ?? TabNotFound;

  return (
    <>
      <UI
        {...{
          customTabs,
          activeTabId,
          onActiveTabChange: setActiveTabId,
          ...generalFormData,
          onValuesChange,
        }}
      >
        <TabContent {...{ formData$, onValuesChange }} />
      </UI>
    </>
  );
};

const TabNotFound = () => "Ops, can't detect tab component";

import React from "react";
import { useTranslation } from "i18n";
import { FormDataProps } from "../types";
import { Row } from "../../components/Row";
import { FormDataInput } from "../../components/formData/Input";
import { FormDataCheckbox } from "../../components/formData/Checkbox";
import { FormDataNumber } from "../../components/formData/Number";

export namespace Validation {
  export type Values = Partial<{
    minLength: number;
    maxLength: number;
    pattern: string;

    showError: boolean;
    errorMessage: string;
  }>;

  export type Props = FormDataProps<Values>;
}

export const Validation = ({ formData$, onValuesChange }: Validation.Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row mb>
        <FormDataNumber
          formDataProp="minLength"
          {...{ formData$, onValuesChange }}
          label={t("Min Length")}
          placeholder={t("e.g. Value")}
          min={0}
        />
        <FormDataNumber
          formDataProp="maxLength"
          {...{ formData$, onValuesChange }}
          label={t("Max Length")}
          placeholder={t("e.g. Value")}
          min={0}
        />
      </Row>
      <Row mb>
        <FormDataInput
          formDataProp="pattern"
          {...{ formData$, onValuesChange }}
          label={t("Pattern")}
          placeholder={t("e.g. =a+b+c")}
        />
      </Row>
      <Row mb>
        <FormDataCheckbox
          formDataProp="showError"
          {...{ formData$, onValuesChange }}
          label={t("Show error")}
        />
      </Row>
      <Row>
        <FormDataInput
          formDataProp="errorMessage"
          {...{ formData$, onValuesChange }}
          label={t("Error message")}
          placeholder={t("e.g. This title is too long")}
        />
      </Row>
    </>
  );
};

import { gql } from "@apollo/client";
import { DataTypeId } from "types/src/DataType/DataType";
import { Inbound, InboundId } from "types/src/Inbounds/Inbound";
import { isT } from "fp-utilities";
import { Cursor } from "types";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { ISODate } from "types/src/date/ISODate";
import { pipe } from "fp-ts/function";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { SupplierId } from "types/src/Supplier/Supplier";
import {
  GetInboundsQuery,
  GetInboundsQueryVariables,
  ReceivingInboundOrderField,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResult,
} from "../../type/QueryResponse";
import { notFoundError } from "../../type/DsError";
import { Where } from "../../type/Where";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";
import { Query } from "../../type/Query";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import { inboundFragment } from "../../fragments/Inbound";
import { inboundFragmentToInbound } from "../../transformers/Inbound";
import * as InboundItems from "../InboundItems/queries";

export type WhereSchema = Where<{
  id: Where.EqIn<InboundId>;
  orderId: Where.EqIn<PickingOrderId>;
  supplierId: Where.EqIn<SupplierId>;
  createdAt: Where.Ord<ISODate>;
  updatedAt: Where.Ord<ISODate>;
  data: Where.Map;
  dataType: Where.EqNil<DataTypeId>;
  dataTypeSlug: Where.Text;
  hasItems: Where.Value<boolean>;
  hasItemsWith: Where.WithWhereList<InboundItems.WhereSchema>;
}>;

export const whereSchema = Where.create<WhereSchema>({
  id: Where.eqIn<InboundId>(),
  orderId: Where.eqIn("orderID"),
  supplierId: Where.eqIn("supplierID"),
  createdAt: Where.ord<ISODate>(),
  updatedAt: Where.ord<ISODate>(),
  data: Where.map("Data"),
  dataType: Where.eqNil<DataTypeId>("dataTypeID"),
  dataTypeSlug: Where.text("dataTypeSlug"),
  hasItems: Where.value<boolean>("hasInboundItems"),
  hasItemsWith: Where.withWhereList(
    (): InboundItems.WhereSchema => InboundItems.whereSchema,
    "hasInboundItemsWith",
  ),
});

export interface GetInboundsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
  where?: Where.GetType<typeof whereSchema>;
}

export function getInboundsQuery(
  vars: GetInboundsVars,
): Query<
  GetInboundsQueryVariables,
  GetInboundsQuery,
  PaginatedQueryResult<Inbound>
> {
  const query = gql`
    ${pageInfoFragment}
    ${inboundFragment}

    query GetInbounds(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: ReceivingInboundWhereInput
      $orderBy: ReceivingInboundOrder
    ) {
      receivingInbounds(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...InboundFragment
          }
        }
      }
    }
  `;

  return Query.create(
    {
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: Fp.pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toUndefined,
        ),
        orderBy: pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: ReceivingInboundOrderField.CreatedAt,
              updatedAt: ReceivingInboundOrderField.UpdatedAt,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    },
    Fp.flow(
      E.map((v) => v.receivingInbounds),
      E.map(getPaginatedQueryResult(inboundFragmentToInbound)),
    ),
  );
}

export function getInboundQuery(id: InboundId) {
  return Query.map(
    Fp.flow(
      E.map((v) => v.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
    getInboundsQuery({ where: { and: [{ id: { eq: id } }] } }),
  );
}

import { useTranslation } from "i18n";
import { Selector } from "state-manager";
import * as Ready from "state-manager/states/Ready";
import { DataTypesListing as Listing } from "state-manager/states/Ready/states/DataTypesListing";
import { ContentLayoutHeader } from "ui/layouts/ContentLayout";
import { Button } from "ui/components/Button";
import { ReactElement } from "react";

export interface HeaderProps {
  selector: Selector<Listing.State>;
  dispatch: (a: Listing.Actions | Ready.GoToDataTypesCreate) => void;
}

export function Header(p: HeaderProps): ReactElement {
  const { t } = useTranslation();
  return (
    <ContentLayoutHeader
      title={t("Data types")}
      actions={
        <Button onClick={() => p.dispatch(Ready.goToDataTypesCreate())}>
          {t("Add new datatype")}
        </Button>
      }
    />
  );
}

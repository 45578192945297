import { Selector } from "state-manager";
import { CollectionsListingAll as Main } from "state-manager/states/Ready/states/DataManager/states/Collections/states/ListingAll";
import { useStateBehavior } from "@Hooks/useStateBehavior";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { CollectionId } from "types/src/Collections/Collection";
import { DataTypeId } from "types/src/DataType/DataType";
import { CollectionsListing } from "@Containers/Listing/CollectionsListing";
import * as Obj from "utils/object";
import { useTranslation } from "i18n";
import { useMemo } from "react";
import { EntityListing } from "@Containers/Listing/EntityListing";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import * as dataTypesFilter from "@Containers/Listing/EntityListing/commonFilters/dataTypes";
import { match } from "fp-utilities";

export namespace Content {
  export interface Props {
    selector: Selector<Main.State>;
    dispatch: (a: Main.Actions) => void;
    onItemClick: (id: CollectionId) => void;
    onTypeClick: (id: DataTypeId) => void;
  }
}

export function Content(p: Content.Props) {
  const state$ = useStateBehavior().map(p.selector);
  const data$ = state$.map(
    Fp.flow((s) => {
      if (
        Main.instance.states.loading.is(s) ||
        Main.instance.states.loadError.is(s)
      )
        return {
          entries: [],
          total: 0,
          pagination: {
            hasNext: false,
            hasPrev: false,
          },
        };

      return {
        entries: s.payload.items,
        total: s.payload.total,
        pagination: {
          hasPrev: s.payload.pageInfo.hasPreviousPage,
          hasNext: s.payload.pageInfo.hasNextPage,
        },
      };
    }),
  );
  const selected$ = state$.map((s) => {
    if (
      Main.instance.states.loading.is(s) ||
      Main.instance.states.loadError.is(s)
    )
      return [];

    return s.payload.selected;
  });
  const unselectable$ = state$.map((s) => {
    if (
      Main.instance.states.loading.is(s) ||
      Main.instance.states.loadError.is(s)
    )
      return [];

    return Obj.entries(s.payload.removing)
      .filter(([, v]) => v === "removing")
      .map((v) => v[0]);
  });

  const customFiltersConfig$ = Filters.useCustomConfig$({ state$ });
  const visibleColumns$ = state$.map((s) => {
    if (
      Main.instance.states.loading.is(s) ||
      Main.instance.states.loadError.is(s)
    )
      return {};

    return s.payload.visibleColumns;
  });

  return (
    <CollectionsListing
      state$={state$.map(
        match(
          [Main.instance.states.loading.is, () => "loading"],
          [Main.instance.states.loadError.is, () => "loading"],
          [Main.instance.states.ready.is, () => "ready"],
          [Main.instance.states.fetching.is, () => "fetching"],
        ),
      )}
      data$={data$}
      //region Filters
      customFiltersConfig$={customFiltersConfig$}
      filters$={state$.map(
        Fp.flow(
          (s) => s.payload.filters.payload.fields,
          Filters.stateToListing,
        ),
      )}
      onFilterChange={Fp.flow(
        Filters.listingToState,
        Main.instance.subStates.filters.actions.setValue.create,
        p.dispatch,
      )}
      onResetFilters={Fp.flow(
        Main.instance.subStates.filters.actions.reset.create,
        p.dispatch,
      )}
      //endregion
      bulkSelect={{
        selected$,
        unselectable$,
        onSelect: Fp.flow(Main.instance.actions.select.create, p.dispatch),
        onUnselect: Fp.flow(Main.instance.actions.unselect.create, p.dispatch),
        onAction: Fp.flow((v) => {
          switch (v) {
            case "remove":
              return Main.instance.actions.removeBulk.create();
          }
        }, p.dispatch),
      }}
      orderBy$={state$.map((v) => O.fromNullable(v.payload.order))}
      onCreateNew={undefined}
      onItemClick={p.onItemClick}
      onTypeClick={p.onTypeClick}
      onPageChange={Fp.flow(Main.instance.actions.setPage.create, p.dispatch)}
      onOrderChange={Fp.flow(Main.instance.actions.orderBy.create, p.dispatch)}
      remove={{
        confirmation$: state$.map((s) => {
          if (
            Main.instance.states.loading.is(s) ||
            Main.instance.states.loadError.is(s)
          )
            return false;

          return (
            Obj.values(s.payload.removing).filter((v) => v === "confirm")
              .length || false
          );
        }),
        onConfirm: Fp.flow(
          Main.instance.actions.removeConfirm.create,
          p.dispatch,
        ),
        onDecline: Fp.flow(
          Main.instance.actions.removeDecline.create,
          p.dispatch,
        ),
      }}
      onRemove={Fp.flow(Main.instance.actions.removeItem.create, p.dispatch)}
      columnsVisibility$={visibleColumns$}
      onColumnsVisibilityChange={Fp.flow(
        Main.instance.actions.setColumnsVisibility.create,
        p.dispatch,
      )}
    />
  );
}

namespace Filters {
  export const useCustomConfig$ = ({
    state$,
  }: {
    state$: BehaviorValue<Main.State>;
  }) => {
    const { t } = useTranslation();

    return useMemo(
      () =>
        state$.map(
          (s) =>
            ({
              ...dataTypesFilter.stateToConfig(
                Main.instance.dataTypes.states.ready.is(s.payload.dataTypes)
                  ? s.payload.dataTypes.payload.data
                  : [],
                { t },
              ),
              ...CollectionsListing.Filters.stateToConfig({}, { t }),
            }) satisfies CollectionsListing.Filters.ConfigCustomBase,
        ),
      [t, state$],
    );
  };

  type ConfigCustom = ReturnType<typeof useCustomConfig$>["value"];

  const typeMap = {
    ...dataTypesFilter.typeMap,
    ...CollectionsListing.Filters.typeMap,
  } satisfies EntityListing.Filters.ListingTypeMapBase;

  const converters = {
    ...dataTypesFilter.converters,
    ...CollectionsListing.Filters.converters,
  } satisfies EntityListing.Filters.ListingConvertersBase<
    Main.Filters,
    typeof typeMap
  >;

  export const listingToState = (
    criteria: EntityListing.Filters.CriteriaChange<
      CollectionsListing.Item,
      ConfigCustom
    >,
  ) =>
    EntityListing.Filters.Converters.listingToState<
      ConfigCustom,
      typeof typeMap,
      typeof converters
    >(criteria, converters);

  export const stateToListing = (
    state: Main.State["payload"]["filters"]["payload"]["fields"],
  ) =>
    EntityListing.Filters.Converters.stateToListing<
      typeof state,
      typeof typeMap,
      typeof converters
    >(state, converters);
}

import {
  UiSchemaElement,
  UiSchemaElementPath,
  UiSchema,
} from "types/src/jsonSchema/uiSchema";
import * as R from "rambda";

export const uiSchemaInsertAtPath = ({
  uiPath,
  element,
  uiSchema,
  before,
}: {
  uiPath: UiSchemaElementPath;
  element: UiSchemaElement;
  uiSchema: UiSchema;
  before?: boolean;
}): UiSchema => {
  const elementsPath = uiPath.slice(0, uiPath.length - 1),
    elementIndex = uiPath.slice(-1).pop(),
    elements = R.path<UiSchemaElement[]>(elementsPath, uiSchema);

  if (!Array.isArray(elements)) {
    return uiSchema; // maybe log
  }
  if (typeof elementIndex !== "number") {
    return uiSchema; // maybe log
  }

  const indexShift = before ? 0 : 1;

  return R.assocPath<UiSchema>(
    elementsPath,
    [
      ...elements.slice(0, elementIndex + indexShift),
      element,
      ...elements.slice(elementIndex + indexShift),
    ],
    uiSchema,
  );
};

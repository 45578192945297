import React from "react";
import { CellProps, WithClassname } from "@jsonforms/core";
import { WithInputProps } from "@jsonforms/material-renderers";
import { InputProps } from "@mui/material";
import { mergeWith } from "rambda";
import { NumberInput } from "@components/Input";

export namespace InputNumber {
  export interface Props extends WithClassname, CellProps, WithInputProps {
    muiInputProps?: InputProps["inputProps"];
    inputComponent?: InputProps["inputComponent"];
  }
}

export function InputNumber(props: InputNumber.Props) {
  const {
    data,
    config,
    className,
    enabled,
    uischema,
    isValid,
    path,
    schema,
    handleChange,
  } = props;
  const options = merge(config ?? {}, uischema.options) as Partial<{
    placeholder?: string;
  }>;

  return (
    <NumberInput
      value={data}
      onChange={(v) => handleChange(path, v)}
      className={className}
      disabled={!enabled}
      error={!isValid}
      placeholder={options.placeholder}
      min={schema?.minimum}
      max={schema?.maximum}
    />
  );
}

const merge = mergeWith((a, b) => b ?? a);

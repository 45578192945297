import { Layout } from "ui/packages/json-schema-form-builder/Layout";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useMemo, useRef } from "react";
import { Value as Value_ } from "types/src/jsonSchema/value";
import {
  UiSchema as UiSchema_,
  UiSchemaElementType,
} from "types/src/jsonSchema/uiSchema";
import { DataSchema as DataSchema_ } from "types/src/jsonSchema/dataSchema";
import { useTranslation } from "i18n";
import { makeControlTypes } from "src/utils/control/typesFactory";
import { Left } from "./Left";
import { Right } from "./Right";
import {
  UiState as UiState_,
  PreviewMode as PreviewMode_,
} from "./types/uiState";
import { defaultUiStateBehavior } from "./utils/ui/state";
import {
  ClipboardContext,
  defaultValue as clipboardContextDefaultValue,
} from "./contexts/Clipboard";
import {
  ElementTypesContext,
  defaultValue as elementTypesContextValue,
} from "./contexts/ElementTypes";
import { UiContext } from "./contexts/Ui";
import { ValueContext } from "./contexts/Value";
import { ControlTypesContext } from "./contexts/ControlTypes";
import { ControlPathCacheContext } from "./contexts/ControlPathCache";
import { ActionsBar } from "./ActionsBar";

export function JsonSchemaFormBuilder({
  value$,
  onValueChange,
  ui$: _ui$,
  onUiChange,
  copyToClipboard,
}: JsonSchemaFormBuilder.Props) {
  const ui$ = useMemo(() => _ui$ ?? defaultUiStateBehavior(), [_ui$]);

  const { elementTypes, controlTypes, controlPathCache, ...contextValues } =
    useContextValues({
      value$,
      onValueChange,
      ui$,
      onUiChange,
      copyToClipboard,
    });

  return (
    <Layout
      left={
        <ElementTypesContext.Provider value={elementTypes}>
          <ControlTypesContext.Provider value={controlTypes}>
            <ControlPathCacheContext.Provider value={controlPathCache}>
              <ValueContext.Provider value={contextValues.value}>
                <UiContext.Provider value={contextValues.ui}>
                  <Left {...{ value$, onValueChange, ui$, onUiChange }} />
                </UiContext.Provider>
              </ValueContext.Provider>
            </ControlPathCacheContext.Provider>
          </ControlTypesContext.Provider>
        </ElementTypesContext.Provider>
      }
      right={
        <ClipboardContext.Provider value={contextValues.clipboard}>
          <Right {...{ value$, ui$, onUiChange }} />
        </ClipboardContext.Provider>
      }
      actionsBar={
        <ControlPathCacheContext.Provider value={controlPathCache}>
          <ValueContext.Provider value={contextValues.value}>
            <UiContext.Provider value={contextValues.ui}>
              <ActionsBar {...{ value$, onValueChange, ui$, onUiChange }} />
            </UiContext.Provider>
          </ValueContext.Provider>
        </ControlPathCacheContext.Provider>
      }
    />
  );
}

export namespace JsonSchemaFormBuilder {
  export type DataSchema = DataSchema_;
  export type UiSchema = UiSchema_;

  export const UiElementType = UiSchemaElementType;

  export type Value = Value_;
  export type UiState = UiState_;
  export const PreviewMode = PreviewMode_;

  export type Props = {
    value$: BehaviorValue<Value>;
    onValueChange: (value: Value) => void;

    ui$?: BehaviorValue<UiState>;
    onUiChange: (ui: Partial<UiState>) => void;

    copyToClipboard?: (text: string) => void;
  };
}

const useContextValues = ({
  value$,
  onValueChange,
  ui$,
  onUiChange,
  copyToClipboard,
}: Pick<Required<JsonSchemaFormBuilder.Props>, "ui$"> &
  Pick<
    JsonSchemaFormBuilder.Props,
    "value$" | "onValueChange" | "onUiChange" | "copyToClipboard"
  >) => {
  const { t } = useTranslation();

  const value = useMemo<ValueContext.Value>(
    () => ({ value$, onValueChange }),
    [value$, onValueChange],
  );

  const ui = useMemo<UiContext.Value>(
    () => ({ ui$, onUiChange }),
    [ui$, onUiChange],
  );

  const clipboard = useMemo<ClipboardContext.Value>(
    () => ({
      ...clipboardContextDefaultValue,
      copyToClipboard:
        copyToClipboard ?? clipboardContextDefaultValue.copyToClipboard,
    }),
    [copyToClipboard],
  );

  const controlTypes: ControlTypesContext.Value = useMemo(
    () => makeControlTypes({ t }),
    [t],
  );

  const controlPathCache: ControlPathCacheContext.Value = useRef({});

  return {
    value,
    ui,
    clipboard,
    elementTypes: elementTypesContextValue,
    controlTypes,
    controlPathCache,
  };
};

import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "i18n";
import { Sxs } from "../../../../../../types/styles";

export namespace Footer {
  export type Props = {
    onReset: () => void;
    onDelete: () => void;
  };
}

export const Footer = ({ onReset, onDelete }: Footer.Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={sxs.root}>
      <Button variant="outlined" onClick={onReset}>
        {t("Reset")}
      </Button>
      <Button variant="contained" color="error" onClick={onDelete}>
        {t("Delete")}
      </Button>
    </Box>
  );
};

const sxs = {
  root: () => ({
    p: 4,

    display: "flex",
    gap: 4,

    ".MuiButton-root": {
      flex: 1,
    },
  }),
} satisfies Sxs;

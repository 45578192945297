import * as MUI from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";

export namespace Autocomplete {
  export interface Props<T> {
    label?: string;
    search?: string;
    onSearch?: (s: string) => void;
    activeIndex?: number;
    placeholder?: string;
    options?: T[];
    onChange?: (value: T | undefined) => void;
    getLabel: (t: T) => string;
    error?: string | boolean;
    isLoading?: boolean;
    clearable?: boolean;
  }
}

export function Autocomplete<T>(p: Autocomplete.Props<T>) {
  return (
    <MUI.FormControl error={!!p.error} sx={{ flex: 1 }}>
      {p.label ? <InputLabel>{p.label}</InputLabel> : null}
      <MUI.Autocomplete<T, false, boolean>
        renderInput={(props) => {
          return <TextField {...props} placeholder={p.placeholder} />;
        }}
        options={p.options ?? []}
        onChange={(_, v) => p.onChange?.(v ?? undefined)}
        onInputChange={(_, v, r) => {
          if (r === "reset") return;
          p.onSearch?.(v);
        }}
        getOptionLabel={p.getLabel}
        size={"small"}
        value={Fp.pipe(
          p.activeIndex,
          O.fromNullable,
          O.map((v) => p.options?.[v]),
          O.chain(O.fromNullable),
          O.toNullable,
        )}
        disableClearable={!p.clearable ? true : undefined}
      />
      {typeof p.error === "string" ? (
        <MUI.FormHelperText>{p.error}</MUI.FormHelperText>
      ) : undefined}
    </MUI.FormControl>
  );
}

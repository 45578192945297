import { gql } from "@apollo/client";
import {
  ItemMovement,
  ItemMovementId,
} from "types/src/ItemMovements/ItemMovement";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { Cursor } from "types";
import { isT } from "fp-utilities";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { ISODate } from "types/src/date/ISODate";
import { RepositoryId } from "types/src/Repositories/Repository";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import { DataTypeId } from "types/src/DataType/DataType";
import { CollectionId } from "types/src/Collections/Collection";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import { itemMovementFragmentToItemMovement } from "../../transformers/ItemMovements";
import { itemMovementFragment } from "../../fragments/ItemMovement";
import { Client, notFoundError, QueryResponse } from "../../index";
import {
  ItemMovementOrderField,
  GetItemMovementsQuery,
  GetItemMovementsQueryVariables,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResponse,
} from "../../type/QueryResponse";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";
import { Where } from "../../type/Where";
import * as InventoryItems from "../../type/InventoryItems";
import * as RepositoryMovements from "../RepositoryMovements";

export type WhereSchema = Where<{
  data: Where.Map;
  createdAt: Where.Ord<ISODate>;
  updatedAt: Where.Ord<ISODate>;
  dataType: Where.EqNil<DataTypeId>;
  executed: Where.Value<boolean>;
  executedAt: Where.Ord<ISODate>;
  from: Where.EqIn<RepositoryId>;
  to: Where.EqIn<RepositoryId>;
  handler: Where.Text;
  hasFrom: Where.Value<boolean>;
  hasFromWidth: Where.WithWhereList<RepositoryMovements.WhereSchema>;
  hasItem: Where.Value<boolean>;
  hasItemWidth: Where.WithWhereList<InventoryItems.WhereSchema>;
  hasTo: Where.Value<boolean>;
  hasToWidth: Where.WithWhereList<RepositoryMovements.WhereSchema>;
  id: Where.EqIn<ItemMovementId>;
  itemId: Where.EqIn<InventoryItemId>;
  orderId: Where.EqIn<PickingOrderId>;
  position: Where.EqIn<number>;
  quantity: Where.EqIn<number>;
  collection: Where.EqNil<CollectionId>;
}>;
export const whereSchema = Where.create<WhereSchema>({
  data: Where.map("Data"),
  createdAt: Where.ord(),
  updatedAt: Where.ord(),
  dataType: Where.eqNil("dataTypeID"),
  executed: Where.value(),
  executedAt: Where.ord(),
  from: Where.eqIn(),
  to: Where.eqIn(),
  handler: Where.text(),
  hasFrom: Where.value(),
  hasFromWidth: Where.withWhereList(() => RepositoryMovements.whereSchema),
  hasItem: Where.value(),
  hasItemWidth: Where.withWhereList(
    (): InventoryItems.WhereSchema => InventoryItems.whereSchema,
  ),
  hasTo: Where.value(),
  hasToWidth: Where.withWhereList(() => RepositoryMovements.whereSchema),
  id: Where.eqIn(),
  itemId: Where.eqIn(),
  orderId: Where.eqIn(),
  position: Where.eqIn(),
  quantity: Where.eqIn(),
  collection: Where.eqNil("collectionID"),
});

export interface GetItemMovementsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  where?: Where.GetType<typeof whereSchema>;
  orderBy?: {
    by:
      | "createdAt"
      | "updatedAt"
      | "executed"
      | "executedAt"
      | "handler"
      | "quantity";
    direction: "asc" | "desc";
  };
}

export function getItemMovements(
  client: Client,
  vars: GetItemMovementsVars,
): Promise<PaginatedQueryResponse<ItemMovement>> {
  const query = gql`
    ${pageInfoFragment}
    ${itemMovementFragment}

    query GetItemMovements(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: ItemMovementWhereInput
      $orderBy: ItemMovementOrder
    ) {
      itemMovements(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...ItemMovementFragment
          }
        }
      }
    }
  `;

  return client
    .query<GetItemMovementsQuery, GetItemMovementsQueryVariables>({
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: Fp.pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toUndefined,
        ),
        orderBy: Fp.pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: ItemMovementOrderField.CreatedAt,
              updatedAt: ItemMovementOrderField.UpdatedAt,
              executed: ItemMovementOrderField.Executed,
              executedAt: ItemMovementOrderField.ExecutedAt,
              handler: ItemMovementOrderField.Handler,
              collectionId: ItemMovementOrderField.CollectionId,
              quantity: ItemMovementOrderField.Quantity,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    })
    .then(
      Fp.flow(
        E.map((v) => v.itemMovements),
        E.map(getPaginatedQueryResult(itemMovementFragmentToItemMovement)),
      ),
    );
}

export type GetItemMovementQueryResult = ItemMovement;
export function getItemMovement(
  client: Client,
  id: ItemMovementId,
): Promise<QueryResponse<GetItemMovementQueryResult>> {
  return getItemMovements(client, { where: { id: { eq: id } } }).then(
    Fp.flow(
      E.map((r) => r.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
  );
}

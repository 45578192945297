import * as O from "fp-ts/Option";
import { DateRange } from "types/src/date/DateRange";
import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { ISODate } from "types/src/date/ISODate";
import * as Rx from "rxjs";
import { Client, isNotFoundError } from "ds";
import * as Inbounds from "ds/Inbounds";
import { getDataType, getDataTypes } from "ds/DataTypes";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { Tuple } from "types/src/Tuple";
import { NoEmptyString } from "types/src/NoEmptyString";
import { InboundId } from "types/src/Inbounds/Inbound";
import { Option } from "fp-ts/Option";
import { SupplierId } from "types/src/Supplier/Supplier";
import { InboundItem } from "types/src/Inbounds/InboundItem";
import { Sku } from "types/src/Sku";
import { ListingWithDataType } from "../../../../../../../../generic-states/ListingWithDataType";
import { Epic } from "../../../../../../../../types/RootEpic";

const prefix = "Ready:DataManager:Inbounds:Listing" as const;

function createState() {
  const state = ListingWithDataType.createState<
    InboundsListing.Filters,
    InboundsListing.Item,
    "createdAt" | "updatedAt",
    { id: DataTypeId }
  >(prefix, {
    defaultFilters: {},
  });

  const epic: Epic<
    ListingWithDataType.GetActions<typeof state>,
    ListingWithDataType.GetState<typeof state>,
    InboundsListing.Deps
  > = (state$, deps) => {
    return state.epic(state$, {
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.forkJoin({
              items: Rx.from(
                client.fetchQuery(Inbounds.getInboundsQuery(getFetchVars(s))),
              ),
              dataTypes: Rx.from(
                getDataTypes(client, {
                  where: {
                    entity: { in: [DataTypeEntity.Inbound] },
                  },
                }),
              ),
            }).pipe(
              Rx.map(
                Fp.flow(
                  (v) => {
                    if (E.isLeft(v.items)) return v.items;
                    if (E.isLeft(v.dataTypes)) return v.dataTypes;

                    return E.right({
                      items: v.items.right,
                      dataTypes: v.dataTypes.right,
                    });
                  },
                  E.map((r) => ({
                    items: r.items.items.map((i) => ({
                      id: i.id,
                      createdAt: i.createdAt,
                      updatedAt: O.fromNullable(i.updatedAt),
                      supplierId: O.fromNullable(i.supplierId),
                      items: i.items,
                      dataType: O.fromNullable(
                        r.dataTypes.items.find((v) => v.id === i.dataTypeId),
                      ),
                    })),
                    total: r.items.totalCount,
                    pageInfo: r.items.pageInfo,
                  })),
                  (v) => v,
                ),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(Inbounds.removeInbounds(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
      fetchDataType: (id) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) => {
            return Rx.from(getDataType(client, id)).pipe(
              Rx.map(E.map(O.some)),
              Rx.map(
                E.orElse((e) => {
                  if (isNotFoundError(e)) return E.right(O.none);

                  return E.left(e);
                }),
              ),
            );
          }),
        );
      },
      getVisibleColumns: deps.getVisibleColumns,
      setVisibleColumns: deps.setVisibleColumns,
    });
  };

  return { ...state, epic };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): Inbounds.GetInboundsVars {
    const fields = s.payload.filters.payload.fields;
    const where: Inbounds.GetInboundsVars["where"] = {
      and: [
        {
          createdAt: Fp.pipe(
            fields.createdAt,
            O.fromNullable,
            O.chain(Fp.flow(DateRange.from, O.fromNullable)),
            O.map((v) => ({ gte: v })),
            O.toUndefined,
          ),
          updatedAt: Fp.pipe(
            fields.updatedAt,
            O.fromNullable,
            O.chain(Fp.flow(DateRange.from, O.fromNullable)),
            O.map((v) => ({ gte: v })),
            O.toUndefined,
          ),
        },
        {
          createdAt: Fp.pipe(
            fields.createdAt,
            O.fromNullable,
            O.chain(Fp.flow(DateRange.to, O.fromNullable)),
            O.map((v) => ({ lte: v })),
            O.toUndefined,
          ),
          updatedAt: Fp.pipe(
            fields.updatedAt,
            O.fromNullable,
            O.chain(Fp.flow(DateRange.to, O.fromNullable)),
            O.map((v) => ({ lte: v })),
            O.toUndefined,
          ),
        },
      ],
      or: [
        {
          data: {
            contains: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.chain(NoEmptyString.fromString),
              O.map(Tuple.create("")),
              O.toUndefined,
            ),
          },
        },
        {
          id: {
            eq: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.map(InboundId.fromString),
              O.toUndefined,
            ),
          },
        },
        {
          hasItemsWith: [
            {
              sku: {
                containsFold: Fp.pipe(
                  fields.search,
                  O.fromNullable,
                  O.map(InboundId.fromString),
                  O.toUndefined,
                ),
              },
            },
          ],
        },
      ],
      dataType: { eq: s.payload.id },
      hasItems: fields.hasItems,
      hasItemsWith: [{ sku: { in: fields.withItems } }],
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
}

export namespace InboundsListing {
  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
    getVisibleColumns: (
      id: DataTypeId,
    ) => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (id: DataTypeId, v: Record<string, boolean>) => void;
  }

  export type Filters = Partial<{
    createdAt: DateRange;
    updatedAt: DateRange;
    search: string;
    hasItems: boolean;
    withItems: Sku[];
  }>;

  export interface Item {
    id: InboundId;
    createdAt: ISODate;
    updatedAt: Option<ISODate>;
    dataType: Option<{ id: DataTypeId; name: string }>;
    supplierId: Option<SupplierId>;
    items: InboundItem[];
  }

  export const instance = createState();

  export type State = ListingWithDataType.GetState<typeof instance>;
  export type Actions = ListingWithDataType.GetActions<typeof instance>;
  export type Exits = ListingWithDataType.GetExits<typeof instance>;
}

import { Typed } from "utils/Typed";
import * as E from "fp-ts/Either";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import { silentUnreachableError } from "utils/exceptions";

export namespace DataTypeSchema {
  export function createState<P extends string>(p: P) {
    const states = Typed.builder
      .add("idle", (p: { schema: DataSchema; uiSchema: UiSchema }) => p)
      .finish()(p);
    const actions = Typed.builder
      .add("reset", (p: { schema: DataSchema; uiSchema: UiSchema }) => p)
      .add("resetSchema", (p: DataSchema) => p)
      .add("resetUi", (p: UiSchema) => p)
      .finish()(p);

    type State = Typed.GetTypes<typeof states>;
    type Actions = Typed.GetTypes<typeof actions>;

    return {
      states,
      actions,
      isState: Typed.getGuard(states),
      isAction: Typed.getGuard(actions),
      init: states.idle.create,
      reducer: (s: State, a: Actions): E.Either<never, State> => {
        if (actions.resetSchema.is(a)) {
          return E.right(
            states.idle.create({
              schema: a.payload,
              uiSchema: s.payload.uiSchema,
            }),
          );
        }
        if (actions.resetUi.is(a)) {
          return E.right(
            states.idle.create({
              schema: s.payload.schema,
              uiSchema: a.payload,
            }),
          );
        }
        if (actions.reset.is(a)) {
          return E.right(states.idle.create(a.payload));
        }

        silentUnreachableError(a);
        return E.right(s);
      },
    };
  }
}

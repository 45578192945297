import React from "react";
import TableContainer from "@mui/material/TableContainer";
import MuiTable from "@mui/material/Table";
import Paper, { PaperProps } from "@mui/material/Paper";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { BulkSelectProps } from "@layouts/ListingTable/types/BulkSelectProps";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { Loading } from "@layouts/ListingTable/List/Loading";
import { NoResults } from "@layouts/ListingTable/List/NoResults";
import { ColumnsConfigBase, ColumnsVisibility } from "../../types/columns";
import { DataEntryBase, EntryAction, ListData } from "../../types/data";
import { FiltersConfigBase } from "../../types/filters";
import {
  Criteria,
  CriteriaChange,
  SortableColumns,
} from "../../types/criteria";
import { createBodyBulkSelectProp, createHeaderBulkSelectProp } from "./utils";
import { Body } from "./Body";
import { Header } from "./Header";

export namespace Table {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
    Actions extends string,
  > = {
    columns: ColumnsConfig;

    columnsVisibility$: BehaviorValue<
      ColumnsVisibility<DataEntry, ColumnsConfig>
    >;
    onColumnsVisibilityChange?: (
      visibility: ColumnsVisibility<DataEntry, ColumnsConfig>,
    ) => void;

    criteria$: BehaviorValue<
      Criteria<SortableColumns<ColumnsConfig>, FiltersConfig>
    >;
    onCriteriaChange: (
      criteria: CriteriaChange<SortableColumns<ColumnsConfig>, FiltersConfig>,
    ) => void;
    onResetFilters: () => void;

    state$: BehaviorValue<"loading" | "fetching" | "ready">;
    data$: BehaviorValue<ListData<DataEntry>>;
    bulkSelect: BulkSelectProps<DataEntry["id"], Actions> | undefined;
    entryActions?: (entry: DataEntry) => EntryAction[];
  };
}

export const Table = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
  Actions extends string,
>({
  columns,
  columnsVisibility$,
  onColumnsVisibilityChange,
  criteria$,
  onCriteriaChange,
  data$,
  entryActions,
  bulkSelect,
  state$,
  onResetFilters,
}: Table.Props<DataEntry, ColumnsConfig, FiltersConfig, Actions>) => {
  const state = useBehaviorValue(state$);
  const hasItems = useBehaviorValue(data$.map((v) => v.entries.length > 0));

  return (
    <TableContainer component={Container}>
      <MuiTable size="small">
        <Header<DataEntry, ColumnsConfig, FiltersConfig, Actions>
          columns={columns}
          columnsVisibility$={columnsVisibility$}
          onColumnsVisibilityChange={onColumnsVisibilityChange}
          criteria$={criteria$}
          onCriteriaChange={onCriteriaChange}
          entryActions={entryActions}
          bulkSelect={createHeaderBulkSelectProp({ bulkSelect, data$ })}
        />
        {state !== "loading" && hasItems ? (
          <Body<DataEntry, ColumnsConfig>
            columns={columns}
            columnsVisibility$={columnsVisibility$}
            onColumnsVisibilityChange={onColumnsVisibilityChange}
            data$={data$}
            entryActions={entryActions}
            bulkSelect={createBodyBulkSelectProp({ bulkSelect })}
          />
        ) : null}
      </MuiTable>
      {state === "loading" || (state === "fetching" && !hasItems) ? (
        <Loading />
      ) : null}
      {state === "ready" && !hasItems ? (
        <NoResults
          onReset={onResetFilters}
          hasAppliedFilters$={criteria$.map(
            (v) =>
              !!(
                (v.filters?.predefined &&
                  (v.filters.predefined.search?.text ||
                    v.filters.predefined.dateRange?.end ||
                    v.filters.predefined.dateRange?.start)) ||
                Object.keys(v.filters?.custom ?? {}).length > 0
              ),
          )}
        />
      ) : null}
    </TableContainer>
  );
};

const Container: React.FC<PaperProps> = ({ children, ...p }) => (
  <Paper
    {...p}
    sx={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
      borderRadius: 0,
      boxShadow: "none",
      backgroundColor: "transparent",
      border: "none",
    }}
  >
    {children}
  </Paper>
);

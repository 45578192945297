import {
  UiSchema,
  UiSchemaElement,
  UiSchemaElementType,
  UiSchemaElementTypeControl,
} from "types/src/jsonSchema/uiSchema";

export const uiSchemaFindControls = (
  schema: UiSchema,
  condition: (c: UiSchemaElementTypeControl) => boolean,
  stopOnFirstFound?: boolean, // optimization: when only need to check if exists
): UiSchemaElementTypeControl[] => {
  let found = false;

  const findRecursive = (
    elements: UiSchemaElement[],
  ): UiSchemaElementTypeControl[] =>
    elements.reduce((controls, element) => {
      if (stopOnFirstFound && found) {
        return controls;
      }
      if (element.type !== UiSchemaElementType.control) {
        return [
          ...controls,
          ...("elements" in element ? findRecursive(element.elements) : []),
        ];
      }
      if (!condition(element)) {
        return controls;
      }

      found = true;

      return [...controls, element];
    }, [] as UiSchemaElementTypeControl[]);

  return findRecursive([schema]);
};

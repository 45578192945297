import { DataTypeId } from "../DataType/DataType";
import { ISODate } from "../date/ISODate";
import { UUID } from "../UUID";
import { DataSchemaValue } from "../jsonSchema/DataSchemaValue";

// region CollectionId
declare const _collectionId: unique symbol;

export type CollectionId = UUID & {
  [_collectionId]: "CollectionId";
};

export namespace CollectionId {
  export const fromString = UUID.fromString<CollectionId>;
}
// endregion

export interface Collection {
  id: CollectionId;
  dataTypeId: DataTypeId;
  fields: DataSchemaValue;
  createdAt: ISODate;
  updatedAt: ISODate | undefined;
  handler: string | undefined;
  assignee: UUID | undefined;
  assignmentDate: ISODate | undefined;
}

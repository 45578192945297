import {
  UiSchema,
  UiSchemaElement,
  UiSchemaElementType,
} from "types/src/jsonSchema/uiSchema";

// Remove/ungroup horizontal/vertical layout with one/no elements
export const uiSchemaRemoveRedundantGroups = (uiSchema: UiSchema): UiSchema => {
  const ungroupRecursive = <Element extends UiSchemaElement>(
    elements: Element[],
  ): Element[] =>
    elements.reduce((elements, element) => {
      if (
        element.type === UiSchemaElementType.horizontalLayout ||
        element.type === UiSchemaElementType.verticalLayout
      ) {
        if (element.elements.length < 2) {
          return [
            ...elements,
            ...ungroupRecursive(element.elements as Element[]),
          ];
        }
        return [
          ...elements,
          {
            ...element,
            elements: ungroupRecursive(element.elements as Element[]),
          },
        ];
      }
      return [...elements, element];
    }, [] as Element[]);

  return {
    ...uiSchema,
    elements: ungroupRecursive(uiSchema.elements),
  };
};

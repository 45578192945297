import React, { ReactNode } from "react";
import { Box, Grid2 } from "@mui/material";
import { Title } from "./Title";

export namespace ContentLayout {
  export type Props = {
    header: ReactNode;
    children: ReactNode;
    fullScreen?: boolean;
  };
}

export const ContentLayout: React.FC<ContentLayout.Props> = ({
  children,
  ...p
}) => (
  <Grid2
    size="grow"
    sx={{
      p: p.fullScreen ? 0 : 8,
      minHeight: 0,
      maxHeight: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    }}
  >
    {p.header}
    <Grid2 sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      {children}
    </Grid2>
  </Grid2>
);

export function ContentLayoutHeader(p: {
  title: ReactNode;
  parentPage?: ReactNode;
  actions?: ReactNode;
}) {
  return (
    <Grid2
      sx={{
        gap: 4,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        mb: 8,
      }}
    >
      <Title title={p.title} parentPage={p.parentPage} />
      <Box sx={{ display: "flex", gap: 4 }}>{p.actions}</Box>
    </Grid2>
  );
}

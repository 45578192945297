import { gql } from "@apollo/client";
import { isT } from "fp-utilities";
import { Cursor } from "types";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { omitEmpties } from "utils/value";
import * as E from "fp-ts/Either";
import { ISODate } from "types/src/date/ISODate";
import { pipe } from "fp-ts/function";
import { Collection, CollectionId } from "types/src/Collections/Collection";
import { UUID } from "types/src/UUID";
import { DataTypeId } from "types/src/DataType/DataType";
import {
  GetCollectionsQuery,
  GetCollectionsQueryVariables,
  InventoryCollectionOrderField,
} from "../../generated/graphql";
import {
  getPaginatedQueryResult,
  PaginatedQueryResult,
} from "../../type/QueryResponse";
import { notFoundError } from "../../type/DsError";
import { Where } from "../../type/Where";
import { pageInfoFragment } from "../../fragments/pageInfoFragment";
import { Query } from "../../type/Query";
import { toApiOrderDirection } from "../../transformers/OrderDirection";
import { collectionFragmentToCollection } from "../../transformers/Collections";
import { collectionFragment } from "../../fragments/Collection";

const whereSchema = Where.create({
  id: Where.eq<CollectionId>(),
  data: Where.map("Data"),
  createdAt: Where.ord<ISODate>(),
  updatedAt: Where.ord<ISODate>(),
  dataType: Where.eqNil<DataTypeId>("dataTypeID"),
  dataTypeSlug: Where.text("dataTypeSlug"),
  handler: Where.text("handler"),
  assignee: Where.eqIn<UUID>("assignee"),
  assignmentDate: Where.ordNil("assignmentDate"),
});

export interface GetCollectionsVars {
  first?: number;
  last?: number;
  after?: Cursor;
  before?: Cursor;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
  where?: Where.GetType<typeof whereSchema>;
}

export function getCollectionsQuery(
  vars: GetCollectionsVars,
): Query<
  GetCollectionsQueryVariables,
  GetCollectionsQuery,
  PaginatedQueryResult<Collection>
> {
  const query = gql`
    ${pageInfoFragment}
    ${collectionFragment}

    query GetCollections(
      $first: Int
      $last: Int
      $after: Cursor
      $before: Cursor
      $where: InventoryCollectionWhereInput
      $orderBy: InventoryCollectionOrder
    ) {
      inventoryCollections(
        first: $first
        last: $last
        after: $after
        before: $before
        where: $where
        orderBy: $orderBy
      ) {
        totalCount
        pageInfo {
          ...PageInfoFragment
        }
        edges {
          cursor
          node {
            ...CollectionFragment
          }
        }
      }
    }
  `;

  return Query.create(
    {
      query,
      variables: omitEmpties({
        first: vars.first,
        last: vars.last,
        after: vars.after,
        before: vars.before,
        where: Fp.pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toUndefined,
        ),
        orderBy: pipe(
          vars.orderBy,
          O.fromNullable,
          O.map((o) => ({
            direction: toApiOrderDirection(o.direction),
            field: {
              createdAt: InventoryCollectionOrderField.CreatedAt,
              updatedAt: InventoryCollectionOrderField.UpdatedAt,
            }[o.by],
          })),
          O.toUndefined,
        ),
      }),
    },
    Fp.flow(
      E.map((v) => v.inventoryCollections),
      E.map(getPaginatedQueryResult(collectionFragmentToCollection)),
    ),
  );
}

export function getCollectionQuery(id: CollectionId) {
  return Query.map(
    Fp.flow(
      E.map((v) => v.items[0]),
      E.filterOrElseW(isT, notFoundError),
    ),
    getCollectionsQuery({ where: { and: [{ id: { eq: id } }] } }),
  );
}

import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  forkJoin,
  from,
  map,
  merge,
  NEVER,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import * as E from "fp-ts/Either";
import { Client, DsError, notFoundError, unknownError } from "ds";
import {
  deleteItemMovements,
  getItemMovement,
  updateItemMovement,
} from "ds/ItemMovements";
import { getDataType } from "ds/DataTypes";
import { flow } from "fp-ts/function";
import { getInventoryItems } from "ds/InventoryItems";
import {
  InventoryItem,
  InventoryItemId,
} from "types/src/InventoryItems/InventoryItem";
import { getRepositories } from "ds/Repositories";
import { Repository, RepositoryId } from "types/src/Repositories/Repository";
import * as FormValue from "types/src/FormValue";
import * as O from "fp-ts/Option";
import { DataTypeEntity } from "types/src/DataType/DataType";
import * as Fp from "fp-ts/function";
import { Epic } from "../../../../../../../../types/RootEpic";
import { SearchItem, SearchItemValid } from "../../types/SearchItem";
import { dsErrorNotification } from "../../../../../../../Notifications/epic";
import * as State from "./types/State";
import * as Actions from "./types/Actions";
import { schemaFieldsState } from "./utils";

export const epic: Epic<
  Actions.Actions,
  State.State,
  { pyckAdminClient$: Observable<Client> }
> = (state$, { pyckAdminClient$ }) => {
  const fieldsSchema$ = schemaFieldsState.epic(
    state$.pipe(
      filter(State.isLoaded),
      map((s) => s.payload.schema),
      filter(O.isSome),
      map((v) => v.value),
    ),
    pyckAdminClient$,
  );

  const loading$ = state$.pipe(
    filter(State.isLoading),
    map((s) => s.payload.id),
    withLatestFrom(pyckAdminClient$),
    switchMap(([id, client]) => {
      return from(getItemMovement(client, id)).pipe(
        switchMap(
          flow(
            E.map((i) => {
              return forkJoin({
                dataType: from(getDataType(client, i.dataTypeId)).pipe(
                  map(E.chain(E.fromNullable<DsError>(notFoundError()))),
                  map(
                    E.filterOrElse(
                      (v) => v.entity === DataTypeEntity.ItemMovement,
                      (): DsError => notFoundError(),
                    ),
                  ),
                  map(O.fromEither),
                ),
                inventoryItems: from(getInventoryItems(client, {})).pipe(
                  map(E.map((v) => v.items)),
                  map(E.getOrElse((): InventoryItem[] => [])),
                  catchError(() => of<InventoryItem[]>([])),
                ),
                repositories: from(getRepositories(client, {})).pipe(
                  map(E.map((v) => v.items)),
                  map(E.getOrElse((): Repository[] => [])),
                  catchError(() => of<Repository[]>([])),
                ),
              }).pipe(
                map((r) => {
                  return Actions.loadSuccess({
                    dataTypeId: i.dataTypeId,
                    itemMovement: i,
                    schema: Fp.pipe(
                      r.dataType,
                      O.map((v) => v.schema),
                      O.toUndefined,
                    ),
                    repositories: r.repositories,
                    inventoryItems: r.inventoryItems,
                    ui: Fp.pipe(
                      r.dataType,
                      O.map((v) => v.frontendSchema),
                      O.toUndefined,
                    ),
                  });
                }),
              );
            }),
            E.getOrElseW(() => of(Actions.loadFail())),
          ),
        ),
      );
    }),
  );

  const update$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isSaving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(
        updateItemMovement(client, {
          id: s.id,
          dataTypeId: s.dataTypeId,
          fields: Fp.pipe(
            s.schema,
            O.map((v) => v.payload.values),
            O.toUndefined,
          ),
        }),
      ).pipe(
        dsErrorNotification(
          flow(E.map(Actions.saveSuccess), E.getOrElseW(Actions.saveError)),
        ),
      ),
    ),
  );

  function searchT<T>(
    get: (
      client: Client,
      s: { where: { search: string | undefined } },
    ) => Promise<E.Either<DsError, { items: T[] }>>,
    fa: (t: T[]) => Actions.Actions,
  ) {
    return (
      v$: Observable<
        FormValue.AsyncValue<unknown, SearchItemValid<T>, SearchItem<T>>
      >,
    ) =>
      v$.pipe(
        debounceTime(500),
        switchMap((v) => {
          return FormValue.isVerifying(v)
            ? of(v).pipe(
                map((v) => O.toUndefined(v.value.search)),
                distinctUntilChanged(),
                withLatestFrom(pyckAdminClient$),
                switchMap(([search, client]) => {
                  return from(get(client, { where: { search } })).pipe(
                    map(E.map((v) => v.items)),
                    map(E.getOrElse((): T[] => [])),
                    catchError(() => of<T[]>([])),
                    map(fa),
                  );
                }),
              )
            : NEVER;
        }),
      );
  }

  const searchItem$ = state$.pipe(
    filter(State.isReady),
    map((s) => s.payload.item),
    searchT(
      (c, w) =>
        getInventoryItems(c, {
          where: {
            or: [
              {
                id: {
                  eq: Fp.pipe(
                    w.where.search,
                    O.fromNullable,
                    O.map(InventoryItemId.fromString),
                    O.toUndefined,
                  ),
                },
              },
              { sku: { containsFold: w.where.search } },
              {
                data: w.where.search
                  ? { contains: ["", w.where.search] }
                  : undefined,
              },
            ],
          },
        }),
      Actions.inventoryItemsSearchResult,
    ),
  );

  const searchReposFrom$ = state$.pipe(
    filter(State.isReady),
    map((s) => s.payload.from),
    searchT(
      (c, w) =>
        getRepositories(c, {
          where: {
            or: [
              {
                id: {
                  eq: Fp.pipe(
                    w.where.search,
                    O.fromNullable,
                    O.map(RepositoryId.fromString),
                    O.toUndefined,
                  ),
                },
              },
              { name: { containsFold: w.where.search } },
              {
                data: w.where.search
                  ? { contains: ["", w.where.search] }
                  : undefined,
              },
            ],
          },
        }),
      Actions.repositoriesFromSearchResult,
    ),
  );

  const searchReposTo$ = state$.pipe(
    filter(State.isReady),
    map((s) => s.payload.to),
    searchT(
      (c, w) =>
        getRepositories(c, {
          where: {
            or: [
              {
                id: {
                  eq: Fp.pipe(
                    w.where.search,
                    O.fromNullable,
                    O.map(RepositoryId.fromString),
                    O.toUndefined,
                  ),
                },
              },
              { name: { containsFold: w.where.search } },
              {
                data: w.where.search
                  ? { contains: ["", w.where.search] }
                  : undefined,
              },
            ],
          },
        }),
      Actions.repositoriesFromSearchResult,
    ),
  );
  const remove$ = state$.pipe(
    distinctUntilKeyChanged("type"),
    filter(State.isRemoving),
    map((s) => s.payload),
    withLatestFrom(pyckAdminClient$),
    switchMap(([s, client]) =>
      from(deleteItemMovements(client, [s.id])).pipe(
        map(
          flow(E.map(Actions.removeSuccess), E.getOrElseW(Actions.removeFail)),
        ),
        catchError(() => of(Actions.removeFail(unknownError()))),
      ),
    ),
  );

  return merge(
    loading$,
    update$,
    fieldsSchema$,
    searchItem$,
    searchReposFrom$,
    searchReposTo$,
    remove$,
  );
};

import { DataEntryBase, EntryAction } from "@layouts/ListingTable/types/data";
import { usePopoverController } from "@layouts/ListingTable/Popover/hooks/usePopoverController";
import Icon from "@mui/icons-material/MoreVertRounded";
import React from "react";
import { Box } from "@mui/material";
import { Popover } from "@layouts/ListingTable/Popover";
import { Sxs } from "../../../../../types/styles";

export namespace EntryActions {
  export type Props<DataEntry extends DataEntryBase> = {
    actions: EntryAction[];
  };
}

export const EntryActions = <DataEntry extends DataEntryBase>({
  actions,
}: EntryActions.Props<DataEntry>) => {
  const { openPopover, popoverOpen, popoverProps } = usePopoverController();

  return (
    <>
      <Icon sx={sxs.icon} onClick={openPopover} />
      {popoverOpen !== undefined && (
        <Popover {...popoverStaticProps} {...popoverProps}>
          {actions.map(({ label, onClick }, i) => (
            <Box
              key={i}
              component="button"
              type="button"
              sx={sxs.action}
              onClick={onClick}
            >
              {label}
            </Box>
          ))}
        </Popover>
      )}
    </>
  );
};

const sxs = {
  action: (theme) => ({
    all: "unset",

    ...theme.typography.body1,
    fontSize: theme.spacing(3.5),
    lineHeight: theme.spacing(5),

    cursor: "pointer",
    display: "block",
    width: ["100%", "fill-available"],
    padding: theme.spacing(2.5, 4),

    "&:hover": {
      backgroundColor: theme.palette.surface.primary,
    },
  }),
  icon: (theme) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
    cursor: "pointer",
    color: theme.palette.action.active,
  }),
} satisfies Sxs;

const popoverStaticProps: Partial<Popover.Props> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
};

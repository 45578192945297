import { gql } from "@apollo/client";

export const dataTypeFragment = gql`
  fragment DataTypeFragment on DataType {
    id
    name
    description
    entity
    default
    jsonSchema
    frontendSchema
    createdAt
    updatedAt
    deletedAt
  }
`;

import { RootState, useSelector } from "state-manager";
import { DataTypeForm, DataTypeFormProps } from "@Containers/Form/DataTypeForm";
import { silentUnreachableError } from "utils/exceptions";
import { Loading } from "@Pages/Loading";
import { useTranslation } from "i18n";
import { flow } from "fp-ts/function";
import { DeleteModal } from "ui/layouts/Dialogs/DeleteModal";
import { DataTypeEdit as Edit } from "state-manager/states/Ready/states/DataTypeEdit";

export interface ContentProps {
  selector: (s: RootState) => Edit.State;
  dispatch: (a: Edit.Actions) => void;
}

export function Content(p: ContentProps) {
  const selectors = useSelector(
    flow(p.selector, (s) => {
      if (
        Edit.instance.states.loading.is(s) ||
        Edit.instance.states.loadError.is(s)
      )
        return { type: "loading" } as const;

      if (
        Edit.instance.states.ready.is(s) ||
        Edit.instance.states.saving.is(s) ||
        Edit.instance.states.removeConfirmation.is(s) ||
        Edit.instance.states.removing.is(s)
      ) {
        const dispatcher: DataTypeFormProps["dispatch"] = {
          setEntity: flow(Edit.instance.actions.setEntity.create, p.dispatch),
          setName: flow(Edit.instance.actions.setName.create, p.dispatch),
          setDescription: flow(
            Edit.instance.actions.setDescription.create,
            p.dispatch,
          ),
          setDefault: flow(Edit.instance.actions.setDefault.create, p.dispatch),
          setSchemas: flow(
            Edit.instance.subStates.schema.actions.reset.create,
            p.dispatch,
          ),
        };

        return {
          type: "ready",
          formSelector: flow(
            p.selector,
            (st) => st as typeof s,
            (s) => ({
              default: s.payload.default,
              description: s.payload.description || "",
              entity: s.payload.entity,
              name: s.payload.name,
              schema: s.payload.schema.payload.schema,
              uiSchema: s.payload.schema.payload.uiSchema,
            }),
          ),
          dispatcher,
        } as const;
      }

      silentUnreachableError(s);
      return {
        type: "loading",
      } as const;
    }),
    (a, b) => a.type === b.type,
  );

  switch (selectors.type) {
    case "loading":
      return <Loading />;
    case "ready":
      return (
        <>
          <RemoveConfirmHandler selector={p.selector} dispatch={p.dispatch} />
          <DataTypeForm
            selector={selectors.formSelector}
            dispatch={selectors.dispatcher}
          />
        </>
      );
  }
}

function RemoveConfirmHandler(p: {
  selector: (s: RootState) => Edit.State;
  dispatch: (a: Edit.Actions) => void;
}) {
  const { t } = useTranslation();
  const show = useSelector(
    flow(p.selector, Edit.instance.states.removeConfirmation.is),
  );

  return show ? (
    <DeleteModal
      title={t("Delete data type")}
      onConfirm={() => p.dispatch(Edit.instance.actions.removeConfirm.create())}
      onCancel={() => p.dispatch(Edit.instance.actions.removeDecline.create())}
    >
      {t("Do you really want to delete this data type?")}
      <br />
      {t("This action cannot be undone.")}
    </DeleteModal>
  ) : null;
}

// fixme: this is dumb component, maybe move to central place for reuse

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import React, { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/material";

export namespace CheckboxesList {
  export type Item<ID extends PropertyKey> = {
    id: ID;
    label: React.ReactNode;
  };

  export type Value<ID extends PropertyKey> = Partial<Record<ID, boolean>>;

  export type Props<ID extends PropertyKey> = {
    items: Array<Item<ID>>;
    value: Value<ID>;
    onChange: (value: Value<ID>) => void;
  };
}

export const CheckboxesList = <ID extends PropertyKey>({
  items,
  value,
  onChange,
}: CheckboxesList.Props<ID>) => {
  type ChangeHandlers = Record<
    ID,
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  >;

  const changeHandlers = useMemo(
    () =>
      items.reduce(
        (carry, { id }) => ({
          ...carry,
          [id]: ((_, checked) =>
            onChange({ ...value, [id]: checked })) as ChangeHandlers[ID],
        }),
        {} as ChangeHandlers,
      ),
    [items, onChange, value],
  );

  return (
    <FormGroup>
      {items.map(({ id, label }) => (
        <FormControlLabel
          key={id.toString()}
          label={label}
          sx={sxFormControl}
          control={
            <Checkbox
              checked={value[id] ?? false}
              onChange={changeHandlers[id]}
            />
          }
        />
      ))}
    </FormGroup>
  );
};

const sxFormControl: SxProps<Theme> = (theme) => ({
  display: "flex",
  gap: theme.spacing(3),

  margin: 0,
  padding: theme.spacing(2.5, 4),
  "&:hover": {
    backgroundColor: theme.palette.surface.primary,
  },

  ".MuiFormControlLabel-label": {
    fontSize: theme.spacing(3.5),
    lineHeight: theme.spacing(5),
  },
});

import { Box } from "@mui/material";
import Slide from "@mui/material/Slide";
import { Sxs } from "../../../types/styles";
import { SelectedCount } from "./SelectedCount";
import { Actions } from "./Actions";

export namespace ActionsBar {
  export type Props = SelectedCount.Props & Actions.Props;
}

export const ActionsBar = ({
  selectedCount,
  onGroupVertically,
  onGroupHorizontally,
  onMakeAsLegend,
  onClone,
  onDelete,
  onClose,
}: ActionsBar.Props) => {
  return (
    <Slide direction="up" in={!!selectedCount} mountOnEnter unmountOnExit>
      <Box sx={sxs.root}>
        <SelectedCount {...{ selectedCount }} />
        <Actions
          {...{
            onGroupVertically,
            onGroupHorizontally,
            onMakeAsLegend,
            onClone,
            onDelete,
            onClose,
          }}
        />
      </Box>
    </Slide>
  );
};

const sxs = {
  root: (theme) => ({
    backgroundColor: theme.palette.surface.secondary,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
    boxShadow: `0px 8px 8px -4px rgba(10, 13, 18, 0.03), 0px 3px 3px -1.5px rgba(10, 13, 18, 0.04)`, // todo: theme.shadows[?]

    display: "flex",
    gap: 0,
    alignItems: "stretch",
  }),
} satisfies Sxs;

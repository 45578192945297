import {
  GeneralOptions as UI,
  GeneralOptionsText as UIText,
} from "ui/packages/json-schema-form-builder/Left/FormEditor/controlTypes/string";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { useMemo } from "react";
import { ControlTypeUI } from "../../../types/control/ui";
import { ControlType } from "../../../types/control/type";

export const GeneralOptions: ControlTypeUI<ControlType.string>["GeneralOptions"] =
  ({ formData$, onValuesChange }) => {
    const format = useBehaviorValue(
      useMemo(() => formData$.map((d) => d.values.format), [formData$]),
    );

    return format === "text" ? (
      <UIText {...{ formData$, onValuesChange }} />
    ) : (
      <UI {...{ formData$, onValuesChange }} />
    );
  };

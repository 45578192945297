import { Typed } from "utils/Typed";
import {
  InventoryItem,
  InventoryItemId,
} from "types/src/InventoryItems/InventoryItem";

export const exits = Typed.builder
  .add("saved", (v: InventoryItem) => v)
  .add("removed", (v: InventoryItemId) => v)
  .finish()("Ready:DataManager:InventoryItems:Edit:Exits");

import { DataTypeId } from "types/src/DataType/DataType";
import { Sku } from "types/src/Sku";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import * as FormValue from "types/src/FormValue";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { SchemaFieldsState, SchemaFieldsStatesMap } from "../utils";

export type State = Ready | Valid;

export const isState = (p: string) =>
  strictGuard((s: State): s is State => {
    if (isReady(p)(s) || isValid(p)(s)) return true;

    silentUnreachableError(s);
    return false;
  });

// region Ready
export interface ReadyPayload {
  dataTypeId: DataTypeId;
  fields: SchemaFieldsState;
  dataTypes: Array<{
    title: string;
    id: DataTypeId;
    schema: DataSchema;
    ui: UiSchema;
  }>;
  sku: FormValue.Value<"required" | "invalid", Sku, string | undefined>;
  quantity: FormValue.Value<"required" | "invalid", number, number | undefined>;
}

export interface Ready {
  type: `${string}:Ready`;
  payload: ReadyPayload;
}

export const ready =
  (p: string) =>
  (payload: Ready["payload"]): Ready => ({
    type: `${p}:Ready`,
    payload,
  });

export const isReady =
  (p: string) =>
  (s: State): s is Ready =>
    s.type === `${p}:Ready`;
// endregion

// region Valid
export interface ValidPayload extends ReadyPayload {
  sku: FormValue.Valid<Sku>;
  quantity: FormValue.Valid<number>;
  fields: SchemaFieldsStatesMap["valid"];
}

export interface Valid {
  type: `${string}:Valid`;
  payload: ValidPayload;
}

export const valid =
  (p: string) =>
  (payload: Valid["payload"]): Valid => ({
    type: `${p}:Valid`,
    payload,
  });

export const isValid =
  (p: string) =>
  (s: State): s is Valid =>
    s.type === `${p}:Valid`;
// endregion

export const init =
  (p: string) =>
  (payload: {
    dataTypeId: DataTypeId;
    fields: SchemaFieldsState;
    dataTypes: Array<{
      title: string;
      id: DataTypeId;
      schema: DataSchema;
      ui: UiSchema;
    }>;
    sku: Sku | undefined;
    quantity: number | undefined;
  }): State => {
    return ready(p)({
      sku: FormValue.initial(payload.sku),
      quantity: FormValue.initial(payload.quantity),
      dataTypeId: payload.dataTypeId,
      dataTypes: payload.dataTypes,
      fields: payload.fields,
    });
  };

// region NewItemId
declare const _newItemId: unique symbol;

export type NewItemId = `new-item-${number}` & { [_newItemId]: "NewItemId" };

export namespace NewItemId {
  export const getCount = (id: NewItemId): number =>
    Number(id.split("new-item-")[1]);

  export const fromNumber = (n: number): NewItemId =>
    `new-item-${n}` as NewItemId;
}
// endregion

// region Uuid
declare const _uuid: unique symbol;

export type UUID = string & { [_uuid]: "UUID" };
// endregion

export namespace UUID {
  export const isUUID = (s: string): s is UUID =>
    s.match(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
    ) !== null;

  /**
   * Try to convert string to UUID
   * We use T generic parameter as there may be types that derive from UUID,
   * so they could use this function as convertor
   * @param s
   */
  export const fromString = <T extends UUID = UUID>(
    s: string,
  ): T | undefined => (isUUID(s) ? (s as T) : undefined);
}

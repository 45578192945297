import { Value } from "types/src/jsonSchema/value";
import { BehaviorValueRef } from "react-rx/behaviorValue";
import { useCallback } from "react";
import * as Fp from "fp-ts/function";
import { uiEditingGetSelectedIds } from "../../utils/ui/editing";
import { controlCloneById } from "../../utils/control/clone";
import { useClearPositionCaches } from "../useClearPositionCaches";
import { UiState } from "../../types/uiState";
import { fixValue } from "../../utils/value/fix";

export const useControlCloneSelected = ({
  valueRef,
  onValueChange: _onValueChange,
  uiRef,
}: {
  valueRef: BehaviorValueRef<Value>;
  onValueChange: (value: Value) => void;

  uiRef: BehaviorValueRef<UiState>;
}) => {
  const onValueChange = useClearPositionCaches(_onValueChange);

  return useCallback(
    () =>
      applyClone(uiEditingGetSelectedIds(uiRef.current), {
        valueRef,
        onValueChange,
      }),
    [uiRef, onValueChange, valueRef],
  );
};

const applyClone = (
  ids: string[],
  {
    valueRef,
    onValueChange,
  }: {
    valueRef: BehaviorValueRef<Value>;
    onValueChange: (value: Value) => void;
  },
): void => {
  onValueChange(
    ids.reduce(
      (value, id) =>
        Fp.pipe(value, (value) => controlCloneById({ id, value }), fixValue),
      { ...valueRef.current },
    ),
  );
};

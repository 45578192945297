import React, { useCallback, useMemo } from "react";
import { FormDataProps } from "../../types";
import { SelectOption } from "./types";
import { Editor } from "./Editor";

export namespace SelectOptions {
  export type Values = Partial<{ options: SelectOption[] }>;

  export type Props = FormDataProps<Values>;
}

export const SelectOptions = ({
  formData$,
  onValuesChange,
}: SelectOptions.Props) => {
  const options$ = useMemo(
    () => formData$.map(({ values: { options = [] } /*errors?*/ }) => options),
    [formData$],
  );
  const onOptionsChange = useCallback(
    (options: SelectOption[]) => onValuesChange({ options }),
    [onValuesChange],
  );

  return <Editor {...{ options$, onOptionsChange }} />;
};

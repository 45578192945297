import { Typed } from "utils/Typed";
import {
  RepositoryMovement,
  RepositoryMovementId,
} from "types/src/RepositoryMovements/RepositoryMovement";

export const exits = Typed.builder
  .add("saved", (v: RepositoryMovement) => v)
  .add("removed", (v: RepositoryMovementId) => v)
  .finish()("Ready:DataManager:RepositoryMovements:Edit:Exits");

import { ListingTable } from "ui/layouts/ListingTable";
import { useTranslation } from "i18n";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { Button } from "ui/components/Button";
import { NoEmptyString } from "types/src/NoEmptyString";
import { DataTypeId } from "types/src/DataType/DataType";
import { DateRange } from "types/src/date/DateRange";
import { OrderBy } from "types/src/OrderBy";
import { TranslatedStr } from "types/src/TranslatedStr";
import { ReactNode } from "react";
import * as filterConverters from "./utils/filterConverters";
import { Delete } from "./Delete";

const filtersConfigPredefined = {
  search: ListingTable.predefinedFilters.search,
  dateRange: ListingTable.predefinedFilters.dateRange,
} satisfies ListingTable.FiltersConfigBase["predefined"];

export function EntityListing<
  I extends EntityListing.Item<string>,
  Actions extends string,
  Columns extends EntityListing.ColumnsType<I>,
  CustomFiltersConfig extends EntityListing.Filters.ConfigCustomBase,
>(p: EntityListing.Props<I, Actions, Columns, CustomFiltersConfig>) {
  const { t } = useTranslation();

  const filtersConfig = {
    predefined: filtersConfigPredefined,
    custom: p.customFiltersConfig ?? ({} as CustomFiltersConfig),
  } satisfies ListingTable.FiltersConfigBase;
  type FiltersConfig = typeof filtersConfig;

  const columns = {
    id: {
      type: ListingTable.CellType.id,
      label: t("Id"),
      renderProps: (v) => ({
        id: v.id,
        onClick: () => p.onItemClick(v.id),
      }),
    },
    dataType: {
      type: ListingTable.CellType.text,
      label: t("Data type"),
      renderProps: (v) => ({
        text: O.toUndefined(v.dataType)?.name,
        onClick: () =>
          Fp.pipe(
            v.dataType,
            O.map((v) => v.id),
            O.map(p.onTypeClick),
          ),
      }),
    },
    ...(p.columns ?? {}),
    createdAt: {
      type: ListingTable.CellType.timeDate,
      label: t("Created at"),
      renderProps: (v) => ({ date: ISODate.toDate(v.createdAt) }),
      canReorder: true,
    },
    updatedAt: {
      type: ListingTable.CellType.timeDate,
      label: t("Last update"),
      canReorder: true,
      renderProps: Fp.flow(
        (v) => v.updatedAt,
        O.map(ISODate.toDate),
        O.toUndefined,
        (date) => ({ date }),
      ),
    },
  } satisfies ListingTable.ColumnsConfigBase<I>;
  type ColumnsConfig = typeof columns;

  return (
    <>
      <ListingTable<I, ColumnsConfig, FiltersConfig, Actions>
        entryActions={p.entryActions}
        title={p.title}
        data$={p.data$}
        columns={columns}
        filters={filtersConfig}
        //@ts-expect-error, fix me
        criteria$={BehaviorValue.combine([p.filters$, p.orderBy$]).map(
          ([filters, orderBy]) => ({
            filters: {
              predefined: {
                search: {
                  text: filters.search,
                },
                dateRange: {
                  start: Fp.pipe(
                    filters.createdAt?.[0],
                    O.fromNullable,
                    O.map(ISODate.toDate),
                    O.toUndefined,
                  ),
                  end: Fp.pipe(
                    filters.createdAt?.[1],
                    O.fromNullable,
                    O.map(ISODate.toDate),
                    O.toUndefined,
                  ),
                },
              },
              custom: filters.custom,
            },
            orderBy: O.toUndefined(orderBy),
          }),
        )}
        onResetFilters={p.onResetFilters}
        onCriteriaChange={(c) => {
          p.onFilterChange({
            ...O.getOrElse(() => ({}))(
              Fp.pipe(
                c.filters?.predefined?.search,
                O.fromNullable,
                O.map(
                  Fp.flow(
                    O.chain((v) => O.fromNullable(v?.text)),
                    O.chain(NoEmptyString.fromString),
                  ),
                ),
                O.map((search) => ({ search })),
              ),
            ),
            ...O.getOrElse(() => ({}))(
              Fp.pipe(
                c.filters?.predefined?.dateRange,
                O.fromNullable,
                O.map(
                  O.chain((v) =>
                    O.some<DateRange>([
                      v?.start ? ISODate.fromDate(v?.start) : undefined,
                      v?.end ? ISODate.fromDate(v?.end) : undefined,
                    ]),
                  ),
                ),
                O.map((createdAt) => ({ createdAt })),
              ),
            ),
            ...(c.filters?.custom ? { custom: c.filters?.custom } : {}),
          });

          Fp.pipe(
            c.orderBy,
            O.fromNullable,
            O.map((v) => v.by),
            O.map(p.onOrderChange),
          );
        }}
        onPageChange={p.onPageChange}
        state$={p.state$}
        columnsVisibility$={p.columnsVisibility$}
        onColumnsVisibilityChange={p.onColumnsVisibilityChange}
        bulkSelect={p.bulkSelect}
        headerButtons={
          p.onCreateNew ? (
            <Button variant={"outlined"} onClick={p.onCreateNew}>
              {p.newItemTitle}
            </Button>
          ) : null
        }
      />
      {p.remove ? <Delete {...p.remove} /> : null}
    </>
  );
}

export namespace EntityListing {
  export interface Item<Id extends string> {
    id: Id;
    createdAt: ISODate;
    updatedAt: O.Option<ISODate>;
    dataType: O.Option<{ id: DataTypeId; name: string }>;
  }

  export type ColumnsType<I extends Item<string>> =
    ListingTable.ColumnsConfigBase<I>;

  export namespace Filters {
    export const Type = ListingTable.FilterType;

    export type TypeCriteriaMap = ListingTable.FilterTypeCriteriaMap;
    export type TypeCriteriaChangeMap =
      ListingTable.FilterTypeCriteriaChangeMap;

    export type ConfigCustomBase = ListingTable.FiltersConfigBase["custom"];

    export type Config<ConfigCustom extends ConfigCustomBase> = {
      predefined: typeof filtersConfigPredefined;
      custom: ConfigCustom;
    };

    export type CriteriaCustom<
      I extends Item<string>,
      ConfigCustom extends ConfigCustomBase,
    > = Required<
      Required<
        ListingTable.Criteria<
          ListingTable.ColumnsConfigBase<I>,
          Config<ConfigCustom>
        >
      >["filters"]
    >["custom"];

    export type Criteria<
      I extends Item<string>,
      ConfigCustom extends ConfigCustomBase,
    > = {
      search?: string;
      createdAt?: DateRange;
      custom: CriteriaCustom<I, ConfigCustom>;
    };

    export type CriteriaCustomChange<
      I extends Item<string>,
      ConfigCustom extends ConfigCustomBase,
    > = Required<
      Required<
        ListingTable.CriteriaChange<
          ListingTable.ColumnsConfigBase<I>,
          Config<ConfigCustom>
        >
      >["filters"]
    >["custom"];

    export type CriteriaChange<
      I extends Item<string>,
      ConfigCustom extends ConfigCustomBase,
    > = Partial<{
      search: O.Option<NoEmptyString>;
      createdAt: O.Option<DateRange>;
      custom: Partial<CriteriaCustomChange<I, ConfigCustom>>;
    }>;

    export type ListingTypeMapBase = Record<
      string,
      (typeof Type)[keyof typeof Type] // don't know how else
    >;

    export type ListingValue<
      FilterListingTypeMap extends ListingTypeMapBase,
      Filter extends keyof FilterListingTypeMap,
    > = TypeCriteriaMap[FilterListingTypeMap[Filter]];

    export type ListingChangeValue<
      FilterListingTypeMap extends ListingTypeMapBase,
      Filter extends keyof FilterListingTypeMap,
    > = TypeCriteriaChangeMap[FilterListingTypeMap[Filter]] extends O.Option<
      infer V
    >
      ? V
      : TypeCriteriaChangeMap[FilterListingTypeMap[Filter]];

    export type ListingConvertersBase<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      State extends { [k in keyof FilterListingTypeMap]?: any },
      FilterListingTypeMap extends ListingTypeMapBase,
    > = {
      [Filter in keyof FilterListingTypeMap]: {
        toListing: (
          v: State[Filter],
        ) => ListingValue<FilterListingTypeMap, Filter>;
        toState: (
          v: ListingChangeValue<FilterListingTypeMap, Filter>,
        ) => State[Filter];
      };
    };

    export namespace Converters {
      export const stateToListing = filterConverters.stateToListing;
      export const listingToState = filterConverters.listingToState;
    }
  }

  export type RemoveProps = Delete.Props;

  export interface Props<
    I extends Item<string>,
    Actions extends string,
    Columns extends ColumnsType<I>,
    CustomFiltersConfig extends Filters.ConfigCustomBase = {},
  > {
    title: TranslatedStr;
    newItemTitle: TranslatedStr;

    data$: BehaviorValue<ListingTable.ListData<I>>;
    state$: BehaviorValue<"loading" | "fetching" | "ready">;

    customFiltersConfig: CustomFiltersConfig;
    filters$: BehaviorValue<Filters.Criteria<I, CustomFiltersConfig>>;
    orderBy$: BehaviorValue<
      O.Option<
        OrderBy<
          ListingTable.SortableColumns<Columns> | "createdAt" | "updatedAt"
        >
      >
    >;
    columns: Columns;

    onCreateNew: (() => void) | undefined;
    onPageChange: (p: "start" | "prev" | "next" | "end") => void;
    onItemClick: (id: I["id"]) => void;
    onTypeClick: (id: DataTypeId) => void;
    onFilterChange: (v: Filters.CriteriaChange<I, CustomFiltersConfig>) => void;
    onResetFilters: () => void;
    onOrderChange: (
      p: ListingTable.SortableColumns<Columns> | "createdAt" | "updatedAt",
    ) => void;

    bulkSelect: {
      actions: { id: Actions; label: TranslatedStr }[];
      selected$: BehaviorValue<I["id"][]>;
      unselectable$: BehaviorValue<I["id"][]>;
      onSelect: (id: I["id"][]) => void;
      onUnselect: (id: I["id"][]) => void;
      onAction: (a: Actions) => void;
    };
    entryActions: (item: I) => Array<{
      label: ReactNode;
      onClick: () => void;
    }>;
    remove?: RemoveProps;
    columnsVisibility$: BehaviorValue<
      Record<
        keyof Columns | "id" | "dataType" | "createdAt" | "updatedAt",
        boolean
      >
    >;
    onColumnsVisibilityChange: (
      v: Partial<
        Record<
          keyof Columns | "id" | "dataType" | "createdAt" | "updatedAt",
          boolean
        >
      >,
    ) => void;
  }
}

import {
  UiSchema,
  UiSchemaElement,
  UiSchemaElementPath,
} from "types/src/jsonSchema/uiSchema";
import * as R from "rambda";

export const uiSchemaUngroup = (
  groupPath: UiSchemaElementPath,
  uiSchema: UiSchema,
): UiSchema => {
  const groupIndex = groupPath.slice(-1)[0];
  if (typeof groupIndex !== "number") {
    return uiSchema; // maybe log
  }

  const parentPath = groupPath.slice(0, groupPath.length - 1),
    parentElements = R.path<UiSchemaElement[]>(parentPath, uiSchema);
  if (!parentElements) {
    return uiSchema; // maybe log
  }

  const group = parentElements[groupIndex];
  if (!group || !("elements" in group)) {
    return uiSchema; // maybe log
  }

  return R.assocPath<UiSchema>(
    parentPath,
    [
      ...parentElements.slice(0, groupIndex),
      ...group.elements,
      ...parentElements.slice(groupIndex + 1),
    ],
    uiSchema,
  );
};

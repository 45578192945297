import { Typed } from "utils/Typed";
import * as FormValue from "types/src/FormValue";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { GetGuardType } from "types/src/Utils";
import * as Rx from "rxjs";
import { silentUnreachableError } from "utils/exceptions";
import { InventoryItemId } from "types/src/InventoryItems/InventoryItem";
import { isDeepEqual } from "utils/object";
import { Epic, EpicDeps } from "../../types/RootEpic";
import { Loading } from "../Loading";

export namespace ItemSetItem {
  export function createState(p: string) {
    interface Ready {
      items: ItemsState;
      itemId: FormValue.Value<
        "required",
        InventoryItemId,
        InventoryItemId | undefined
      >;
    }

    interface Submitted extends Ready {
      itemId: FormValue.SubmittedValue<Ready["itemId"]>;
    }

    interface Valid extends Ready {
      itemId: FormValue.Valid<InventoryItemId>;
    }

    const items = Loading.createState<
      Array<{ name: string; id: InventoryItemId }>,
      {
        search?: string;
        id?: InventoryItemId | undefined;
      }
    >(`${p}:items`, { equals: isDeepEqual });

    const states = Typed.builder
      .add("ready", (p: Ready) => p)
      .add("submitted", (p: Submitted) => p)
      .add("valid", (p: Valid) => p)
      .finish()(`${p}:states`);
    const actions = Typed.builder
      .add("setItem", (p: InventoryItemId) => p)
      .add("items", (p: ItemsActions) => p)
      .add("submit")
      .finish()(`${p}:actions`);
    const exits = Typed.builder.finish()(`${p}:exits`);

    type State = Typed.GetTypes<typeof states>;
    type Actions = Typed.GetTypes<typeof actions>;
    type Exits = Typed.GetTypes<typeof exits>;
    type ItemsActions = GetGuardType<typeof items.isAction>;
    type ItemsState = GetGuardType<typeof items.isState>;

    const epic: Epic<Actions, State, EpicDeps<typeof items.epic>> = (
      s$,
      deps,
    ) => {
      return items.epic(s$.pipe(Rx.map((s) => s.payload.items)), deps).pipe(
        Rx.map(
          Fp.flow(
            E.fromPredicate(
              items.isAction,
              (a) => a as Exclude<typeof a, ItemsActions>,
            ),
            E.map(actions.items.create),
            E.getOrElseW((a) => a),
          ),
        ),
      );
    };

    return {
      states,
      isState: Typed.getGuard(states),
      actions,
      isAction: Typed.getGuard(actions),
      exits,
      isExit: Typed.getGuard(exits),
      epic,
      reducer,
      subStates: { items },
      init: (id?: InventoryItemId) =>
        states.ready.create({
          items: items.init({ id }),
          itemId: FormValue.initial(id),
        }),
    };

    function reducer(s: State, a: Actions): E.Either<Exits, State> {
      if (actions.items.is(a)) {
        return Fp.pipe(
          items.reducer(s.payload.items, a.payload),
          E.mapLeft((e) => {
            silentUnreachableError(e);
            return s;
          }),
          E.filterOrElse(
            (v) => v !== s.payload.items,
            () => s,
          ),
          E.map(
            (items) => ({ ...s, payload: { ...s.payload, items } }) as State,
          ),
          E.getOrElse(() => s),
          E.right,
        );
      }

      if (actions.setItem.is(a)) {
        return E.right({
          ...s,
          payload: { ...s.payload, itemId: FormValue.valid(a.payload) },
        });
      }

      if (actions.submit.is(a)) {
        if (states.ready.is(s)) {
          const itemId = FormValue.isInitial(s.payload.itemId)
            ? validateFormValue(s.payload.itemId.value)
            : s.payload.itemId;

          return FormValue.isValid(itemId)
            ? E.right(states.valid.create({ ...s.payload, itemId }))
            : E.right(states.submitted.create({ ...s.payload, itemId }));
        }

        return E.right(s);
      }

      silentUnreachableError(a);
      return E.right(s);
    }
  }

  export type Store = ReturnType<typeof createState>;
  export type State = GetGuardType<Store["isState"]>;
  export type Actions = GetGuardType<Store["isAction"]>;
}

function validateFormValue<I>(
  v: I | undefined,
): FormValue.SubmittedValue<FormValue.Value<"required", I, I | undefined>> {
  return Fp.pipe(
    v,
    E.fromNullable(FormValue.invalid("required" as const, undefined)),
    E.map(FormValue.valid),
    E.toUnion,
  );
}

import React from "react";
import { CellProps, WithClassname } from "@jsonforms/core";
import { WithInputProps } from "@jsonforms/material-renderers";
import { mergeWith } from "rambda";
import { format, parseISO } from "date-fns/fp";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { TimePickerInput } from "@components/TimePicker";

export namespace InputTime {
  export interface Props extends WithClassname, CellProps, WithInputProps {}
}

export function InputTime(props: InputTime.Props) {
  const {
    data,
    config,
    className,
    enabled,
    uischema,
    isValid,
    path,
    handleChange,
  } = props;
  const options = merge(config ?? {}, uischema.options) as Partial<{
    placeholder?: string;
  }>;

  return (
    <TimePickerInput
      value={Fp.pipe(data, O.fromNullable, O.map(parseISO), O.toUndefined)}
      className={className}
      onChange={Fp.flow(
        O.fromNullable,
        O.map(format("HH:mm:ss")),
        O.toUndefined,
        (v) => handleChange(path, v),
      )}
      disabled={!enabled}
      placeholder={options.placeholder}
      error={!isValid}
    />
  );
}

const merge = mergeWith((a, b) => b ?? a);

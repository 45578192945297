import { JSONSchema7 } from "json-schema";
import {
  DataType,
  DataTypeEntity,
  DataTypeId,
} from "types/src/DataType/DataType";
import { isObject, jsonParse } from "utils/object";
import * as Fp from "fp-ts/function";
import * as O from "fp-ts/Option";
import { TranslatedStr } from "types/src/TranslatedStr";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { isT, parse } from "fp-utilities";
import {
  UiSchema,
  UiSchemaElementType,
  UiSchemaTypeRegistry,
} from "types/src/jsonSchema/uiSchema";
import { JsonFormSchema } from "types/src/jsonSchema/value";
import { DataTypeFragmentFragment } from "../generated/graphql";

export function dataTypeToJsonSchema(schema: DataSchema): JSONSchema7 {
  return schema as JSONSchema7;
}

export function apiEntityToDataTypeEntity(entity: string): DataTypeEntity {
  switch (entity) {
    case "attachment":
      return DataTypeEntity.Attachment;
    case "collection_movement":
      return DataTypeEntity.CollectionMovement;
    case "customer":
      return DataTypeEntity.Customer;
    case "inbound":
      return DataTypeEntity.Inbound;
    case "inbound_item":
      return DataTypeEntity.InboundItem;
    case "item":
      return DataTypeEntity.Item;
    case "item_set":
      return DataTypeEntity.ItemSet;
    case "item_movement":
      return DataTypeEntity.ItemMovement;
    case "repository_movement":
      return DataTypeEntity.RepositoryMovement;
    case "order":
      return DataTypeEntity.OrderItem;
    case "order_item":
      return DataTypeEntity.Other;
    case "repository":
      return DataTypeEntity.Repository;
    case "supplier":
      return DataTypeEntity.Supplier;
    default:
      return DataTypeEntity.Other;
  }
}

export function dataTypeEntityToApiEntity(entity: DataTypeEntity): string {
  switch (entity) {
    case DataTypeEntity.Attachment:
    case DataTypeEntity.CollectionMovement:
    case DataTypeEntity.Customer:
    case DataTypeEntity.Inbound:
    case DataTypeEntity.InboundItem:
    case DataTypeEntity.Item:
    case DataTypeEntity.ItemSet:
    case DataTypeEntity.ItemMovement:
    case DataTypeEntity.RepositoryMovement:
    case DataTypeEntity.Order:
    case DataTypeEntity.OrderItem:
    case DataTypeEntity.Other:
    case DataTypeEntity.Repository:
    case DataTypeEntity.Supplier:
      return entity;
  }
}

export function jsonSchemaToDataTypeSchema(schema: JSONSchema7): DataSchema {
  return schema as DataSchema;
}

export function dataTypeFragmentToDataType(
  fragment: DataTypeFragmentFragment,
): DataType {
  const schema = jsonSchemaToDataTypeSchema(
    jsonParse(fragment.jsonSchema) as JSONSchema7,
  );

  return {
    id: fragment.id as DataTypeId,
    name: fragment.name as TranslatedStr,
    description: fragment.description as TranslatedStr,
    entity: apiEntityToDataTypeEntity(fragment.entity),
    default: fragment.default,
    schema,
    frontendSchema: Fp.pipe(
      fragment.frontendSchema,
      O.fromNullable,
      O.map(jsonParse),
      O.filter(isObject),
      O.map(
        parse<object, UiSchema>({
          type: Fp.flow(
            O.fromPredicate((v) => "type" in v),
            O.map((v) => v.type),
            O.filter(
              (v): v is UiSchemaElementType.verticalLayout =>
                v === UiSchemaElementType.verticalLayout,
            ),
            O.toUndefined,
          ),
          elements: Fp.flow(
            O.fromPredicate((v) => "elements" in v),
            O.map((v) => v.elements),
            O.filter(
              (
                v,
              ): v is UiSchemaTypeRegistry[UiSchemaElementType.verticalLayout]["elements"] =>
                Array.isArray(v),
            ),
            O.toUndefined,
          ),
        }),
      ),
      O.filter(isT),
      O.getOrElseW(
        (): UiSchema => ({
          type: UiSchemaElementType.verticalLayout,
          elements: [],
        }),
      ),
      (v) => JsonFormSchema.normalizeUiSchema(schema, v),
    ),
    createdAt: fragment.createdAt,
    updatedAt: O.fromNullable(fragment.updatedAt),
    deletedAt: O.fromNullable(fragment.deletedAt),
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Typed } from "utils/Typed";
import { silentUnreachableError } from "utils/exceptions";
import { DsError } from "ds";
import * as Rx from "rxjs";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import { Eq } from "fp-ts/Eq";
import { match } from "fp-utilities";
import { Epic } from "../../types/RootEpic";

function createStates<T, Q>(p: string) {
  return Typed.builder
    .add("loading", (query: Q) => ({ query }))
    .add("loadError", (p: { error: DsError; query: Q }) => p)
    .add("ready", (p: { data: T; query: Q }) => p)
    .finish()(p);
}
function createActions<T, Q>(p: string) {
  return Typed.builder
    .add("loadSuccess", (p: T) => p)
    .add("loadError", (e: DsError) => e)
    .add("retry")
    .add("setQuery", (q: Q) => q)
    .finish()(p);
}

export namespace Loading {
  export type State<T, Q> = Typed.GetTypes<
    ReturnType<typeof createStates<T, Q>>
  >;
  export type Actions<T, Q> = Typed.GetTypes<
    ReturnType<typeof createActions<T, Q>>
  >;

  export const createState = <T, Q>(p: string, options: Eq<Q>) => {
    const states = createStates<T, Q>(p);
    const actions = createActions<T, Q>(p);

    type State = Typed.GetTypes<typeof states>;
    type Actions = Typed.GetTypes<typeof actions>;

    const reducer = (s: State, a: Actions): E.Either<never, State> => {
      if (actions.loadError.is(a)) {
        return states.loading.is(s)
          ? E.right(
              states.loadError.create({
                error: a.payload,
                query: s.payload.query,
              }),
            )
          : E.right(s);
      }

      if (actions.loadSuccess.is(a)) {
        return states.loading.is(s)
          ? E.right(
              states.ready.create({
                data: a.payload,
                query: s.payload.query,
              }),
            )
          : E.right(s);
      }

      if (actions.retry.is(a)) {
        return states.loadError.is(s)
          ? E.right(states.loading.create(s.payload.query))
          : E.right(s);
      }

      if (actions.setQuery.is(a)) {
        return Fp.pipe(
          a.payload,
          O.of,
          O.filter((v) => !options.equals(v, s.payload.query)),
          O.map((v) => states.loading.create(v)),
          O.getOrElse(() => s),
          E.right,
        );
      }

      silentUnreachableError(a);
      return E.right(s);
    };

    const epic: Epic<Actions, State, Dependencies<Q, T>> = (state$, deps) => {
      return state$.pipe(
        Rx.filter(states.loading.is),
        Rx.map((s) => s.payload.query),
        Rx.distinctUntilChanged(options.equals),
        Rx.switchMap((s) =>
          deps
            .get(s)
            .pipe(
              Rx.map(E.map(actions.loadSuccess.create)),
              Rx.map(E.mapLeft(actions.loadError.create)),
              Rx.map(E.getOrElseW((v) => v)),
            ),
        ),
      );
    };

    return {
      prefix: p,
      isState: Typed.getGuard(states),
      isAction: Typed.getGuard(actions),
      states,
      actions,
      reducer,
      epic,
      init: (q: Q) => states.loading.create(q),
      selectors: {
        getData: (s: State): O.Option<T> =>
          match(
            [states.loading.is, () => O.none],
            [states.loadError.is, () => O.none],
            [states.ready.is, (s) => O.some(s.payload.data)],
          )(s),
      },
    };
  };

  export function mapData<T, Q, R>(
    i: ReturnType<typeof createState<T, Q>>,
    fn: (v: T) => R,
    s: State<T, Q>,
  ): State<R, Q> {
    if (i.states.loading.is(s)) return s;
    if (i.states.loadError.is(s)) return s;

    return {
      ...s,
      payload: {
        ...s.payload,
        data: fn(s.payload.data),
      },
    };
  }

  export type GetState<T extends { isState: (v: any) => v is any }> =
    T extends { isState: (v: any) => v is infer T } ? T : never;
  export type GetActions<T extends { isAction: (v: any) => v is any }> =
    T extends { isAction: (v: any) => v is infer T } ? T : never;

  export interface Dependencies<Q, T> {
    get: (q: Q) => Rx.Observable<E.Either<DsError, T>>;
  }

  export type Store<T, Q> = ReturnType<typeof createState<T, Q>>;
}

import { useBehaviorValue } from "react-rx/behaviorValue";
import { useMemo } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";

export const useFormDataProp = <
  Values extends Record<string, unknown>,
  Prop extends keyof Values,
>({
  formData$,
  prop,
  defaultValue,
}: {
  formData$: BehaviorValue<{
    values: Values;
    errors: Partial<Record<keyof Values, string>>;
  }>;
  prop: Prop;
  defaultValue?: Values[Prop];
}) => {
  const value = useBehaviorValue(
    useMemo(
      () => formData$.map((v) => v.values[prop] ?? defaultValue),
      [defaultValue, formData$, prop],
    ),
  );
  const error = useBehaviorValue(
    useMemo(() => formData$.map((v) => v.errors[prop]), [formData$, prop]),
  );

  return { value, error };
};

import { silentUnreachableError } from "utils/exceptions";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import * as E from "fp-ts/Either";
import * as Fp from "fp-ts/function";
import * as Actions from "./types/Actions";
import * as State from "./types/State";

import { Customers } from "./states/Customers";
import { Suppliers } from "./states/Suppliers";
import { Repositories } from "./states/Repositories";
import { InventoryItems } from "./states/InventoryItems";
import { ItemMovements } from "./states/ItemMovements";
import { RepositoryMovements } from "./states/RepositoryMovements";
import { pickingOrderState } from "./utils";
import { Collections } from "./states/Collections";
import { Inbounds } from "./states/Inbounds";
import { ItemSets } from "./states/ItemSets";
import { StocksListing } from "./states/Stocks";

export function reducer(s: State.State, a: Actions.Actions): State.State {
  if (StocksListing.instance.isState(s.payload.subState)) {
    if (StocksListing.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Fp.pipe(
            StocksListing.instance.reducer(s.payload.subState, a),
            E.getOrElse((e) => {
              if (StocksListing.instance.exits.create.is(e))
                return s.payload.subState;

              silentUnreachableError(e);
              return s.payload.subState;
            }),
          ),
        },
      } as typeof s;
    }
  }

  if (Collections.instance.isState(s.payload.subState)) {
    if (Collections.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Collections.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Customers.instance.isState(s.payload.subState)) {
    if (Customers.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Customers.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Inbounds.instance.isState(s.payload.subState)) {
    if (Inbounds.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Inbounds.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (ItemSets.instance.isState(s.payload.subState)) {
    if (ItemSets.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: ItemSets.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Suppliers.instance.isState(s.payload.subState)) {
    if (Suppliers.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Suppliers.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Repositories.instance.isState(s.payload.subState)) {
    if (Repositories.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: Repositories.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (InventoryItems.instance.isState(s.payload.subState)) {
    if (InventoryItems.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: InventoryItems.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (ItemMovements.instance.isState(s.payload.subState)) {
    if (ItemMovements.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: ItemMovements.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (RepositoryMovements.instance.isState(s.payload.subState)) {
    if (RepositoryMovements.instance.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: RepositoryMovements.instance.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (pickingOrderState.isState(s.payload.subState)) {
    if (pickingOrderState.isAction(a)) {
      return {
        ...s,
        payload: {
          ...s.payload,
          subState: pickingOrderState.reducer(s.payload.subState, a),
        },
      } as typeof s;
    }
  }

  if (Actions.actions.loadFail.is(a)) {
    return pipe(
      s,
      O.fromPredicate(State.isLoading),
      O.map((s) => State.loadError(s.payload)),
      O.getOrElse(() => s),
    );
  }

  if (Actions.actions.loadSuccess.is(a)) {
    return pipe(
      s,
      O.fromPredicate(State.isLoading),
      O.map((s) =>
        State.ready({
          ...s.payload,
          dataTypes: a.payload.dataTypes,
        }),
      ),
      O.getOrElse(() => s),
    );
  }

  if (Actions.actions.dataTypesUpdated.is(a)) {
    return pipe(
      s,
      O.fromPredicate(State.isLoading),
      O.map((s) =>
        State.ready({
          ...s.payload,
          dataTypes: a.payload,
        }),
      ),
      O.getOrElse(() => s),
    );
  }

  /*
  This is a hack have a type safe reducer
   */
  if (StocksListing.instance.isAction(a)) return s;
  if (ItemSets.instance.isAction(a)) return s;
  if (Inbounds.instance.isAction(a)) return s;
  if (Collections.instance.isAction(a)) return s;
  if (Customers.instance.isAction(a)) return s;
  if (Suppliers.instance.isAction(a)) return s;
  if (Repositories.instance.isAction(a)) return s;
  if (InventoryItems.instance.isAction(a)) return s;
  if (ItemMovements.instance.isAction(a)) return s;
  if (RepositoryMovements.instance.isAction(a)) return s;
  if (pickingOrderState.isAction(a)) return s;

  silentUnreachableError(a);
  return s;
}

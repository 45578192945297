import React, { useCallback, useMemo } from "react";
import { Theme } from "@mui/material/styles";
import { useTranslation } from "i18n";
import { DataEntryBase } from "@layouts/ListingTable/types/data";
import { ColumnsConfigBase } from "@layouts/ListingTable/types/columns";
import {
  FilterGroup,
  FiltersConfigBase,
} from "@layouts/ListingTable/types/filters";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import {
  Criteria,
  CriteriaChange,
  SortableColumns,
} from "@layouts/ListingTable/types/criteria";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { Box, Button, SxProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FilterButton } from "@layouts/ListingTable/FilterButton";
import FiltersIcon from "@mui/icons-material/FilterListRounded";
import * as O from "fp-ts/Option";
import { styleTableBorderColor } from "../../utils/styles";
import { CustomFilters } from "./CustomFilters";

export namespace Header {
  export type Props<
    DataEntry extends DataEntryBase,
    ColumnsConfig extends ColumnsConfigBase<DataEntry>,
    FiltersConfig extends FiltersConfigBase,
  > = {
    title: string;
    filters: FiltersConfig;

    criteria$: BehaviorValue<
      Criteria<SortableColumns<ColumnsConfig>, FiltersConfig>
    >;
    onCriteriaChange: (
      criteria: CriteriaChange<SortableColumns<ColumnsConfig>, FiltersConfig>,
    ) => void;
    onResetFilters: () => void;
  };
}

export const Header = <
  DataEntry extends DataEntryBase,
  ColumnsConfig extends ColumnsConfigBase<DataEntry>,
  FiltersConfig extends FiltersConfigBase,
>({
  title,
  filters,
  criteria$,
  onCriteriaChange,
  onResetFilters,
}: Header.Props<DataEntry, ColumnsConfig, FiltersConfig>) => {
  const { t } = useTranslation();
  const criteriaHasCustomFilters = useBehaviorValue(
    useMemo(
      () =>
        criteria$.map(({ filters }) =>
          Boolean(filters?.custom && Object.keys(filters.custom).length),
        ),
      [criteria$],
    ),
  );
  const addAllFiltersWithDefaults = useCallback(() => {
    onCriteriaChange({
      filters: {
        custom: Object.keys(filters[FilterGroup.custom])
          .map((id) => id as keyof FiltersConfig[FilterGroup.custom])
          .reduce(
            (carry, id) => ({
              ...carry,
              [id]: O.some(filters[FilterGroup.custom][id].value),
            }),
            {},
          ),
      },
    });
  }, [filters, onCriteriaChange]);
  const hasCustomFilters = useMemo(
    () => !!Object.keys(filters[FilterGroup.custom]).length,
    [filters],
  );

  return (
    <Box sx={sxs.wrapper}>
      <Box sx={sxs.titleAndFiltersBtn}>
        <Typography variant="h6">{title}</Typography>
        {hasCustomFilters && (
          <FilterButton
            Icon={FiltersIcon}
            active={criteriaHasCustomFilters}
            onClick={
              criteriaHasCustomFilters ? undefined : addAllFiltersWithDefaults
            }
          >
            {t("Filters")}
          </FilterButton>
        )}
      </Box>
      {criteriaHasCustomFilters && (
        <Box sx={sxs.filters}>
          <CustomFilters<DataEntry, ColumnsConfig, FiltersConfig>
            {...{ filters, criteria$, onCriteriaChange }}
          />
          <Button onClick={onResetFilters} sx={sxs.resetBtn}>
            {t("Reset filters")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const sxs = {
  wrapper: (theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: 4,
    padding: theme.spacing(5, 6),
    borderBottom: `1px solid ${styleTableBorderColor(theme)}`,
  }),
  titleAndFiltersBtn: () => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
  }),
  filters: (theme) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 4,
  }),
  resetBtn: (theme) => ({
    color: theme.palette.error.dark,
    padding: 0,
    "&:hover": {
      backgroundColor: "unset",
    },
  }),
} satisfies Record<string, SxProps<Theme>>;

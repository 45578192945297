import { useTranslation } from "i18n";
import { ControlTypeRegistry } from "../../../types/control";
import { ControlType } from "../../../types/control/type";
import { GeneralOptions } from "../../../FormEditor/controlTypes/boolean/GeneralOptions";
import { ControlUiSchemaOptionsRegistry } from "../../../types/control/uiSchemaOptions";
import { pick } from "../../../utils/object";
import { castStringMaybe } from "../../../utils/cast";

type Props = Pick<ReturnType<typeof useTranslation>, "t">;

const type = ControlType.boolean;

export const makeTypeBoolean = ({
  t,
}: Props): ControlTypeRegistry[typeof type] => ({
  type,
  label: t("boolean"),

  formats: {
    checkbox: { label: t("checkbox") },
  },

  detect: ({ dataSchema }) => dataSchema.type === "boolean",

  values: {
    default: {
      type,
      format: "checkbox",
      required: true,
    },
    validate: (v) => ({}),

    fromSchema: ({
      dataSchemaRequired,
      uiSchema: { label, options: o = {} },
    }) => ({
      type,
      format: "checkbox",

      label: castStringMaybe(label),

      required: dataSchemaRequired,
      unique: !!o.unique,
      readonly: !!o.readonly,
      hidden: !!o.hidden,
    }),
    toSchema: (v) => ({
      dataSchema: { type: "boolean" },
      dataSchemaRequired: !!v.required,
      uiSchema: {
        label: v.label,
        options: {
          ...pick(["unique", "readonly", "hidden"], v),
        } satisfies ControlUiSchemaOptionsRegistry[typeof type],
      },
    }),
  },

  UI: {
    getAffectingValues: (v) => ({}),
    get: () => ({
      GeneralOptions,
      tabs: {},
    }),
  },
});

import { Box } from "@mui/material";
import React from "react";
import { Sxs } from "../../../../types/styles";

export namespace FormPreview {
  export type Props = React.PropsWithChildren<{}>;
}

export function FormPreview({ children }: FormPreview.Props) {
  return <Box sx={sxs.root}>{children}</Box>;
}

const sxs = {
  root: (theme) => ({
    padding: theme.spacing(0, 6, 6, 6),
  }),
} satisfies Sxs;

import { useTranslation } from "i18n";
import { Header as Layout } from "ui/layouts/Single/Header";
import { ItemSetEdit as Main } from "state-manager/states/Ready/states/DataManager/states/ItemSets/states/Edit";

import { Selector } from "state-manager";

export interface HeaderProps {
  selector: Selector<Main.State>;
  dispatch: (a: Main.Actions) => void;
}

export function Header(p: HeaderProps) {
  const { t } = useTranslation();

  return <Layout>{t("Edit item set")}</Layout>;
}

import { DataTypeId } from "types/src/DataType/DataType";
import { useTranslation } from "i18n";
import { ListingTable } from "ui/layouts/ListingTable";
import { EntityListing } from "@Containers/Listing/EntityListing";

export const typeMap = {
  dataTypes: EntityListing.Filters.Type.multiChoice,
} satisfies EntityListing.Filters.ListingTypeMapBase;

export const stateToConfig = (
  state: Array<{ id: DataTypeId; name: string }>,
  { t }: Pick<ReturnType<typeof useTranslation>, "t">,
) => ({
  dataTypes: {
    type: typeMap["dataTypes"],
    options: {
      label: t("Data types"),
      choices: state.map(({ id, name: label }) => ({
        id,
        label,
      })),
    },
  } satisfies ListingTable.CustomFilterConfig,
});

export const converters = {
  dataTypes: {
    toListing: (v) => ({
      choices: v?.reduce((carry, id) => ({ ...carry, [id]: true }), {}),
    }),
    toState: (v) =>
      (v?.choices
        ? Object.entries(v.choices)
            .filter(([, checkbox]) => !!checkbox)
            .map(([id]) => id)
        : []) as DataTypeId[],
  },
} satisfies EntityListing.Filters.ListingConvertersBase<
  { dataTypes?: DataTypeId[] },
  typeof typeMap
>;

import { useBehaviorValueRef } from "react-rx/behaviorValue";
import { useTranslation } from "i18n";
import { useCallback, useContext } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { ControlValues, ControlErrors } from "../types";
import { ControlType } from "../../../../../types/control/type";
import { ControlTypesContext } from "../../../../../contexts/ControlTypes";
import { ControlTypeAndSchemas } from "../../../../../types/control";
import { useRestrictedNames } from "./useRestrictedNames";

export const useValidate = ({
  typeAndSchemas$,
}: {
  typeAndSchemas$: BehaviorValue<ControlTypeAndSchemas<ControlType>>;
}) => {
  const { t } = useTranslation();
  const controlTypes = useContext(ControlTypesContext);
  const restrictedNamesRef = useBehaviorValueRef(
    useRestrictedNames({
      controlSchemas$: typeAndSchemas$,
    }),
  );

  return useCallback(
    (
      values: /*maybe more correct is `Record<string, unknown>`*/ ControlValues,
    ): ControlErrors => {
      let errors: ControlErrors = {};

      {
        const name = values.name.trim();

        if (!name.length) {
          errors.name = t("Name is required");
        } else if (restrictedNamesRef.current[name]) {
          errors.name = t("Name must be unique");
        }
      }

      {
        const type = values.type as ControlType;

        if (!controlTypes[type]) {
          errors.type = t("Unknown type");
        } else {
          errors = {
            ...errors,
            ...controlTypes[type].values.validate(
              // @ts-expect-error maybe fix
              values,
            ),
          };
        }
      }

      return errors;
    },
    [controlTypes, restrictedNamesRef, t],
  );
};

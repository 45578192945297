/* eslint-disable @typescript-eslint/no-explicit-any */

import { DataTypeId } from "types/src/DataType/DataType";
import { DsError } from "ds";
import * as Rx from "rxjs";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { strictGuard } from "utils/strictGuard";
import { Option } from "fp-ts/Option";
import { Loading } from "../Loading";
import { ListingState } from "../Listing";
import { Epic } from "../../types/RootEpic";

export namespace ListingWithDataType {
  export interface DataType {
    id: DataTypeId;
    name: string;
  }

  export interface EpicDeps<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    F extends Record<string, any>,
    T extends { id: string },
    O,
    A extends { id: DataTypeId },
  > extends Omit<
      ListingState.EpicDeps<F, T, O, A>,
      "getVisibleColumns" | "setVisibleColumns"
    > {
    fetchDataType: (
      id: DataTypeId,
    ) => Rx.Observable<E.Either<DsError, Option<DataType>>>;
    getVisibleColumns: (
      id: DataTypeId,
    ) => Rx.Observable<Record<string, boolean>>;
    setVisibleColumns: (id: DataTypeId, v: Record<string, boolean>) => void;
  }

  export const createState = <
    F extends Record<string, unknown>,
    T extends { id: string },
    O,
    A extends { id: DataTypeId },
  >(
    p: string,
    config: {
      defaultFilters: F;
    },
  ) => {
    type Extend<T extends { id: DataTypeId }> = T & {
      dataType: Loading.GetState<typeof dataTypeState>;
    };

    const dataTypeState = Loading.createState<Option<DataType>, DataTypeId>(
      `${p}:data-type`,
      {
        equals: (a, b) => a === b,
      },
    );
    const state = ListingState.createState<F, T, O, Extend<A>>(p, config);

    type St = ListingState.GetState<typeof state>;
    type Ac =
      | ListingState.GetActions<typeof state>
      | Loading.GetActions<typeof dataTypeState>;
    type Exits = ListingState.GetExits<typeof state>;

    const epic: Epic<Ac, St, EpicDeps<F, T, O, Extend<A>>> = (state$, deps) => {
      const dataTypeId$ = state$.pipe(
        Rx.map((s) => s.payload.id),
        Rx.distinctUntilChanged(),
        Rx.shareReplay(1),
      );

      const listing$ = state.epic(state$, {
        fetchItems: deps.fetchItems,
        removeItems: deps.removeItems,
        getVisibleColumns: () =>
          dataTypeId$.pipe(Rx.switchMap((id) => deps.getVisibleColumns(id))),
        setVisibleColumns: (v) => {
          dataTypeId$.pipe(Rx.take(1)).subscribe((id) => {
            deps.setVisibleColumns(id, v);
          });
        },
      });

      const dataTypes$ = dataTypeState.epic(
        state$.pipe(Rx.map((v) => v.payload.dataType)),
        {
          get: deps.fetchDataType,
        },
      );

      return Rx.merge(
        listing$,
        dataTypes$,
        dataTypeId$.pipe(Rx.mergeMap(() => Rx.NEVER)),
      );
    };

    const reducer = (s: St, a: Ac): E.Either<Exits, St> => {
      if (dataTypeState.isAction(a)) {
        return Fp.pipe(
          dataTypeState.reducer(s.payload.dataType, a),
          E.map(
            (dataType) =>
              ({ ...s, payload: { ...s.payload, dataType } }) as typeof s,
          ),
        );
      }

      return state.reducer(s, a);
    };

    return {
      ...state,
      isAction: strictGuard(
        (a: Ac): a is Ac => state.isAction(a) || dataTypeState.isAction(a),
      ),
      reducer,
      epic,
      init: (a: A): St =>
        state.init({
          ...a,
          dataType: dataTypeState.init(a.id),
        }),
      subStates: {
        ...state.subStates,
        dataType: dataTypeState,
      },
    };
  };

  export type GetState<T extends { reducer: (s: any, a: any) => any }> =
    ListingState.GetState<T>;

  export type GetActions<T extends { reducer: (s: any, a: any) => any }> =
    ListingState.GetActions<T>;

  export type GetExits<
    T extends { reducer: (s: any, a: any) => E.Either<any, any> },
  > = ListingState.GetExits<T>;
}

import * as O from "fp-ts/Option";
import * as Fp from "fp-ts/function";
import * as Obj from "utils/object";

interface UserSettingsBase<Version extends `${number}`> {
  type: "UserSettings";
  version: Version;
  payload: object;
}

interface UserSettingsV1 extends UserSettingsBase<"1"> {
  payload: {
    listingVisibleColumns: {
      [k in string]?: Record<string, boolean>;
    };
  };
}

namespace UserSettingsV1 {
  export function fromObject(v: object): O.Option<UserSettingsV1> {
    return Fp.pipe(
      v,
      O.fromNullable,
      O.filter(
        (v): v is UserSettingsBase<"1"> =>
          "type" in v &&
          v.type === "UserSettings" &&
          "version" in v &&
          v.version === "1" &&
          "payload" in v &&
          typeof v.payload === "object" &&
          v.payload !== null,
      ),
      O.map((v) => {
        return {
          ...v,
          payload: {
            listingVisibleColumns: Fp.pipe(
              v.payload,
              O.fromNullable,
              O.filter((v) => "listingVisibleColumns" in v),
              O.map((v) => v.listingVisibleColumns),
              O.chain(O.fromNullable),
              O.filter((v) => typeof v === "object"),
              O.map((v) => Obj.filter((v) => !!v && typeof v === "object", v)),
              O.map((v) => Obj.map((v) => Obj.map((v2) => !!v2, v), v)),
              O.getOrElse(() => ({})),
            ),
          },
        };
      }),
    );
  }

  export function create(payload: UserSettingsV1["payload"]): UserSettingsV1 {
    return {
      type: "UserSettings",
      version: "1",
      payload,
    };
  }
}

export type UserSettings = UserSettingsV1;

export namespace UserSettings {
  export const fromObject = UserSettingsV1.fromObject;
  export const create = UserSettingsV1.create;
}

import { Box } from "@mui/material";
import { ReactNode } from "react";

export namespace FeaturedIcon {
  export interface Props {
    children: ReactNode;
  }
}

export function FeaturedIcon(p: FeaturedIcon.Props) {
  return (
    <Box
      sx={(p) => ({
        border: `1px solid ${p.palette.surface.primary}`,
        background: p.palette.surface.secondary,
        borderRadius: p.spacing(2.5),
        width: p.spacing(11),
        height: p.spacing(11),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        color: p.palette.action.active,
      })}
    >
      {p.children}
    </Box>
  );
}

import { useContext, useMemo } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import * as O from "fp-ts/Option";
import { ControlTypeAndSchemas } from "../../types/control";
import { ControlTypesContext } from "../../contexts/ControlTypes";
import { ValueContext } from "../../contexts/Value";
import { ControlType } from "../../types/control/type";
import { controlDetectType } from "../../utils/control/detectType";
import { ControlPathCacheContext } from "../../contexts/ControlPathCache";
import { findControlById } from "../../utils/control/findById";

export const useControlTypeAndSchemasById = (
  id: string,
): BehaviorValue<O.Option<ControlTypeAndSchemas<ControlType>>> => {
  const { value$ } = useContext(ValueContext);
  const controlTypes = useContext(ControlTypesContext);
  const pathCache = useContext(ControlPathCacheContext);

  return useMemo(
    () =>
      value$.map(({ uiSchema, dataSchema }) => {
        const control = findControlById(id, {
          uiSchema,
          pathCache,
          dataSchema,
        });
        if (!control) {
          return O.none;
        }

        const type = controlDetectType(control, controlTypes);
        if (!type) {
          return O.none;
        }

        return O.some<ControlTypeAndSchemas<ControlType>>({
          type,
          uiSchema: control.uiSchema,
          dataSchema: control.dataSchema,
          dataSchemaRequired: control.dataSchemaRequired,
        });
      }),
    [value$, pathCache, id, controlTypes],
  );
};

import { useContext, useMemo } from "react";
import {
  UiSchemaElementType,
  UiSchemaTypeRegistry,
} from "types/src/jsonSchema/uiSchema";
import * as O from "fp-ts/Option";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { ValueContext } from "../../contexts/Value";
import { findElementByEncodedPath } from "../../utils/uiSchema/findElementByPath";

export const useElementUiSchemaFromPath$ = <Type extends UiSchemaElementType>({
  elementType,
  pathEncoded,
}: {
  elementType: Type;
  pathEncoded: string;
}): BehaviorValue<O.Option<UiSchemaTypeRegistry[Type]>> => {
  const { value$ } = useContext(ValueContext);

  return useMemo(
    () =>
      value$.map(({ uiSchema }) =>
        O.fromNullable(
          findElementByEncodedPath(uiSchema, pathEncoded, elementType),
        ),
      ),
    [value$, elementType, pathEncoded],
  );
};

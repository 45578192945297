import { DndContext } from "@dnd-kit/core";
import React, { useMemo } from "react";
import { Sxs } from "../../../../types/styles";
import {
  ElementRefsContext,
  makeElementRefsContextValue,
} from "./elementRefs/Context";
import { DndMeta as DndMeta_, ClosestElement as Closest_ } from "./types";
import { useOnDragEnd } from "./hooks/useOnDragEnd";

export namespace FormEditor {
  export namespace DndMeta {
    export type Common = DndMeta_.Common;

    export type Draggable = DndMeta_.Draggable;
    export type Droppable = DndMeta_.Droppable;
  }

  export type Closest = Closest_;

  export type Props = React.PropsWithChildren<{
    onDragEnd: (info: {
      droppable: { id: string; meta: DndMeta.Droppable };
      draggable: { id: string; meta: DndMeta.Draggable };
      closest?: Closest;
    }) => void;
  }>;
}

export const FormEditor = ({
  children,
  onDragEnd: handleDragEnd,
}: FormEditor.Props) => {
  const elementRefs = useMemo<ElementRefsContext.Value>(
    makeElementRefsContextValue,
    [],
  );
  const onDragEnd = useOnDragEnd({ elementRefs, handler: handleDragEnd });

  return (
    <ElementRefsContext.Provider value={elementRefs}>
      <DndContext onDragEnd={onDragEnd}>{children}</DndContext>
    </ElementRefsContext.Provider>
  );
};

const sxs = {} satisfies Sxs;

import { useMemo } from "react";
import { Selector } from "state-manager";
import * as Ready from "state-manager/states/Ready";
import { DataTypesListing as Main } from "state-manager/states/Ready/states/DataTypesListing";
import * as Fp from "fp-ts/function";
import { useTranslation } from "i18n";
import { ListingTable } from "ui/layouts/ListingTable";
import { useStateBehavior } from "@Hooks/useStateBehavior";
import * as O from "fp-ts/Option";
import { ISODate } from "types/src/date/ISODate";
import { useDataTypeEntityTitle } from "@Hooks/useDataTypeEntityTitle";
import { match } from "fp-utilities";
import { SymmetricTuple } from "types/src/Tuple";
import * as Obj from "utils/object";
import { DataTypeEntity } from "types/src/DataType/DataType";
import { Delete } from "@Containers/Listing/EntityListing/Delete";

export interface ContentProps {
  selector: Selector<Main.State>;
  dispatch: (a: Main.Actions | Ready.GoToDataTypesEdit) => void;
}

export function Content(p: ContentProps) {
  const { t } = useTranslation();
  const entityT = useDataTypeEntityTitle();
  const state$ = useStateBehavior().map(p.selector);

  const visibleColumns$ = state$.map((s) => {
    if (
      Main.instance.states.loading.is(s) ||
      Main.instance.states.loadError.is(s)
    )
      return {};

    return s.payload.visibleColumns;
  });
  const columns = {
    id: {
      type: ListingTable.CellType.id,
      label: t("Id"),
      renderProps: (v) => ({
        id: v.id,
        onClick: () => p.dispatch(Ready.goToDataTypesEdit(v.id)),
      }),
    },
    name: {
      type: ListingTable.CellType.text,
      label: t("Name"),
      renderProps: (v) => ({
        text: v.name,
        onClick: () => p.dispatch(Ready.goToDataTypesEdit(v.id)),
      }),
    },
    entity: {
      type: ListingTable.CellType.text,
      label: t("Entity"),
      renderProps: (v) => ({ text: entityT(v.entity) }),
    },
    createdAt: {
      type: ListingTable.CellType.timeDate,
      label: t("Created at"),
      renderProps: (v) => ({ date: ISODate.toDate(v.createdAt) }),
      canReorder: true,
    },
    updatedAt: {
      type: ListingTable.CellType.timeDate,
      label: t("Last update"),
      canReorder: true,
      renderProps: Fp.flow(
        (v) => v.updatedAt,
        O.fromNullable,
        O.map(ISODate.toDate),
        O.toUndefined,
        (date) => ({ date }),
      ),
    },
  } satisfies ListingTable.ColumnsConfigBase<Main.Item>;

  const data$ = state$.map(
    Fp.flow((s) => {
      if (
        Main.instance.states.loading.is(s) ||
        Main.instance.states.loadError.is(s)
      )
        return {
          entries: [],
          total: 0,
          pagination: {
            hasNext: false,
            hasPrev: false,
          },
        };

      return {
        entries: s.payload.items,
        total: s.payload.total,
        pagination: {
          hasPrev: s.payload.pageInfo.hasPreviousPage,
          hasNext: s.payload.pageInfo.hasNextPage,
        },
      };
    }),
  );
  const entitiesList = useMemo(
    () => Obj.values(DataTypeEntity).map((v) => ({ id: v, label: entityT(v) })),
    [entityT],
  );
  const selected$ = state$.map((s) => {
    if (
      Main.instance.states.loading.is(s) ||
      Main.instance.states.loadError.is(s)
    )
      return [];

    return s.payload.selected;
  });
  const unselectable$ = state$.map((s) => {
    if (
      Main.instance.states.loading.is(s) ||
      Main.instance.states.loadError.is(s)
    )
      return [];

    return Obj.entries(s.payload.removing)
      .filter(([, v]) => v === "removing")
      .map((v) => v[0]);
  });

  const filters = {
    predefined: {
      search: ListingTable.predefinedFilters.search,
      dateRange: ListingTable.predefinedFilters.dateRange,
    },
    custom: {
      entities: {
        type: ListingTable.FilterType.multiChoice,
        options: {
          label: t("Entity"),
          choices: entitiesList,
        },
      },
    },
  } satisfies ListingTable.FiltersConfigBase;

  return (
    <>
      <ListingTable<Main.Item, typeof columns, typeof filters, "remove">
        columns={columns}
        filters={filters}
        title={t("Data types")}
        columnsVisibility$={visibleColumns$}
        criteria$={state$.map((s) => {
          const fields = s.payload.filters.payload.fields;
          const dateRange = Fp.pipe(
            fields.createdAt,
            O.fromNullable,
            O.map((v) =>
              SymmetricTuple.map(
                Fp.flow(O.fromNullable, O.map(ISODate.toDate), O.toUndefined),
                v,
              ),
            ),
            O.map(([start, end]) => ({ start, end })),
            O.toUndefined,
          );

          return {
            filters: {
              predefined: {
                search: { text: fields.search },
                dateRange,
              },
              custom: Fp.pipe(
                fields,
                O.fromPredicate((v) => "entities" in v),
                O.map((v) => ({
                  entities: {
                    choices: v.entities?.reduce(
                      (acc: Record<string, boolean>, e) => {
                        acc[e] = true;
                        return acc;
                      },
                      {},
                    ),
                  },
                })),
                O.toUndefined,
              ),
            },
            orderBy: s.payload.order?.by
              ? {
                  by: s.payload.order.by,
                  direction: s.payload.order.direction,
                }
              : undefined,
          };
        })}
        onCriteriaChange={(c) => {
          Fp.pipe(
            c.filters?.predefined,
            O.fromNullable,
            O.map((v) => ({
              search: Fp.pipe(
                v.search,
                O.fromNullable,
                O.map(O.chain((v) => O.fromNullable(v?.text))),
                O.toUndefined,
              ),
              createdAt: Fp.pipe(
                v.dateRange,
                O.fromNullable,
                O.map(O.map((v) => SymmetricTuple.create(v?.start, v?.end))),
                O.map(
                  O.map((v) =>
                    SymmetricTuple.map(
                      Fp.flow(
                        O.fromNullable,
                        O.map(ISODate.fromDate),
                        O.toUndefined,
                      ),
                      v,
                    ),
                  ),
                ),
                O.toUndefined,
              ),
            })),
            O.map(Main.instance.subStates.filters.actions.setValue.create),
            O.map(p.dispatch),
          );
          Fp.pipe(
            c.filters?.custom,
            O.fromNullable,
            O.map((v) => ({
              entities: Fp.pipe(
                v.entities,
                O.fromNullable,
                O.map(
                  Fp.flow(
                    O.map(
                      (v) =>
                        (
                          v as
                            | { choices?: Record<DataTypeEntity, boolean> }
                            | undefined
                        )?.choices,
                    ),
                    O.map(
                      Fp.flow(
                        O.fromNullable,
                        O.map((vs) => Obj.filter((v) => v, vs)),
                        O.map(Obj.keys),
                        O.toUndefined,
                      ),
                    ),
                  ),
                ),
                O.toUndefined,
              ),
            })),
            O.map(Main.instance.subStates.filters.actions.setValue.create),
            O.map(p.dispatch),
          );
          Fp.pipe(
            c.orderBy,
            O.fromNullable,
            O.map((v) => v.by),
            O.map(Main.instance.actions.orderBy.create),
            O.map(p.dispatch),
          );
        }}
        onResetFilters={Fp.flow(
          Main.instance.subStates.filters.actions.reset.create,
          p.dispatch,
        )}
        state$={state$.map(
          match(
            [Main.instance.states.loading.is, () => "loading"],
            [Main.instance.states.loadError.is, () => "loading"],
            [Main.instance.states.ready.is, () => "ready"],
            [Main.instance.states.fetching.is, () => "fetching"],
          ),
        )}
        data$={data$}
        onPageChange={Fp.flow(Main.instance.actions.setPage.create, p.dispatch)}
        bulkSelect={{
          actions: [{ id: "remove", label: t("Remove") }],
          selected$,
          unselectable$,
          onSelect: Fp.flow(Main.instance.actions.select.create, p.dispatch),
          onUnselect: Fp.flow(
            Main.instance.actions.unselect.create,
            p.dispatch,
          ),
          onAction: Fp.flow((v) => {
            switch (v) {
              case "remove":
                return Main.instance.actions.removeBulk.create();
            }
          }, p.dispatch),
        }}
        entryActions={(v) => [
          {
            label: t("Remove"),
            onClick: Fp.flow(
              () => v.id,
              Main.instance.actions.removeItem.create,
              p.dispatch,
            ),
          },
        ]}
      />
      <Remove selector={p.selector} dispatch={p.dispatch} />
    </>
  );
}
function Remove(p: {
  selector: Selector<Main.State>;
  dispatch: (a: Main.Actions) => void;
}) {
  const { t } = useTranslation();
  const state$ = useStateBehavior();
  const confirmation$ = state$.map(
    Fp.flow(p.selector, (s) => {
      if (
        Main.instance.states.loading.is(s) ||
        Main.instance.states.loadError.is(s)
      )
        return false;

      return (
        Obj.values(s.payload.removing).filter((v) => v === "confirm").length ||
        false
      );
    }),
  );

  return (
    <Delete
      confirmation$={confirmation$}
      getTitle={(c) => (c === 1 ? t("Remove datatype") : t("Remove datatypes"))}
      getDescription={(c) =>
        c === 1
          ? t("Do you really want to remove this datatypes")
          : t("Do you really want to remove this datatype")
      }
      onConfirm={Fp.flow(
        Main.instance.actions.removeConfirm.create,
        p.dispatch,
      )}
      onDecline={Fp.flow(
        Main.instance.actions.removeDecline.create,
        p.dispatch,
      )}
    />
  );
}

import { gql } from "@apollo/client";

export const collectionFragment = gql`
  fragment CollectionFragment on InventoryCollection {
    id
    assignee
    assignmentDate
    createdAt
    data
    dataTypeID
    deletedAt
    handler
    updatedAt
  }
`;

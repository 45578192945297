import React, { useCallback } from "react";
import { useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "i18n";
import { Sxs } from "../../../../../../types/styles";
import { DndMeta } from "../../types";
import { useElementRefs } from "../../elementRefs/hooks";
import { FooterButtons } from "./FooterButtons";

export function HorizontalLayout({
  id,
  dndMeta,
  children,
  onAddSubfield,
  onUngroup,
}: HorizontalLayout.Props) {
  const { t } = useTranslation();
  const { setNodeRef } = useDroppable({ id, data: dndMeta });

  const elementRef = useElementRefs({ id, meta: dndMeta });
  const setElementRef = useCallback(
    (el: HTMLElement | null) => elementRef.set(el),
    [elementRef],
  );

  return (
    <Box sx={sxs.root} ref={setElementRef}>
      <Typography sx={sxs.label} variant="caption">
        {t("Horizontal group")}
      </Typography>
      <Box sx={sxs.elements} ref={setNodeRef}>
        {children}
      </Box>
      <FooterButtons {...{ onAddSubfield, onUngroup }} />
    </Box>
  );
}

export namespace HorizontalLayout {
  export type Props = React.PropsWithChildren<
    {
      id: string;
      dndMeta: DndMeta.Droppable;
    } & FooterButtons.Props
  >;
}

const sxs = {
  root: (theme) => ({
    padding: 4,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
    position: "relative",
  }),
  label: (theme) => ({
    position: "absolute",
    left: theme.spacing(4),
    top: `calc(0px - 1em - ${theme.spacing(1)})`,
    padding: theme.spacing(1, 2.5),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.action.active,
  }),
  elements: (theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: 4,
    minHeight: "2rem",
  }),
} satisfies Sxs;

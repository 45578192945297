import { RootState, useDispatch, useSelector } from "state-manager";
import * as AuthState from "state-manager/states/Auth";
import * as ReadyState from "state-manager/states/Ready";
import { silentUnreachableError, unreachableError } from "utils/exceptions";
import { NotFound } from "@Pages/NotFound";
import { LogIn } from "@Pages/LogIn";
import { Callback } from "@Pages/Callback";
import { Ready } from "@Pages/Ready";
import { Loading } from "@Pages/Loading";
import { flow } from "fp-ts/function";

export function Pages() {
  const page = useSelector(
    flow(
      (s) => s.content,
      (s) => {
        if (AuthState.isState(s)) {
          if (AuthState.states.idle.is(s))
            return {
              type: "loading",
            } as const;
          if (
            AuthState.states.login.is(s) ||
            AuthState.states.authentication.is(s) ||
            AuthState.states.authError.is(s)
          )
            return {
              type: "signIn",
              selector: (st: RootState) => {
                const _s = st.content as typeof s;

                if (AuthState.states.login.is(_s)) return "normal";
                if (AuthState.states.authentication.is(_s)) return "loading";
                if (AuthState.states.authError.is(_s)) return "error";

                silentUnreachableError(_s);
                return "normal";
              },
            } as const;
          if (AuthState.states.authConfirmation.is(s))
            return { type: "authConfirm" } as const;
        }

        if (ReadyState.isState(s)) {
          return {
            type: "ready",
            selector: (st: RootState) => st.content as typeof s,
          } as const;
        }

        unreachableError(s);
        return { type: "notFound" } as const;
      },
    ),
    (a, b) => a.type === b.type,
  );
  const dispatch = useDispatch();

  switch (page.type) {
    case "notFound":
      return <NotFound />;
    case "loading":
      return <Loading />;
    case "signIn":
      return <LogIn selector={page.selector} />;
    case "authConfirm":
      return <Callback />;
    case "ready":
      return <Ready selector={page.selector} dispatch={dispatch} />;

    default:
      silentUnreachableError(page);
      return <NotFound />;
  }
}

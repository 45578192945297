import { TranslatedStr } from "types/src/TranslatedStr";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { DeleteModal } from "ui/layouts/Dialogs/DeleteModal";

export namespace Delete {
  export type Props = {
    getTitle: (count: number) => TranslatedStr;
    getDescription: (count: number) => TranslatedStr;
    confirmation$: BehaviorValue<false | number>;
    onConfirm: () => void;
    onDecline: () => void;
  };
}

export function Delete(p: Delete.Props) {
  const confirm = useBehaviorValue(p.confirmation$);

  return confirm !== false ? (
    <DeleteModal
      title={p.getTitle(confirm)}
      onConfirm={p.onConfirm}
      onCancel={p.onDecline}
    >
      {p.getDescription(confirm)}
    </DeleteModal>
  ) : null;
}

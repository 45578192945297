import { Typed } from "utils/Typed";

import * as Rx from "rxjs";
import * as O from "fp-ts/Option";
import * as Obj from "utils/object";
import { Epic } from "../../types/RootEpic";
import { createReducer } from "./reducer";

export const createStates = <Fields extends { [k in string]?: unknown }>(
  p: string,
) => {
  return Typed.builder
    .add("idle", (fields: Fields) => ({ fields }))
    .add("submitted", (fields: Fields) => ({ fields }))
    .finish()(p);
};

export const createActions = <Fields extends { [k in string]?: unknown }>(
  p: string,
) => {
  return Typed.builder
    .add("setValue", (p: { [k in keyof Fields]: O.Option<Fields[k]> }) => p)
    .add("submit")
    .add("reset")
    .finish()(p);
};

export function createState<Fields extends { [k in string]: unknown }>(
  p: string,
  defaultValue: Fields,
) {
  const states = createStates<Fields>(p);
  const actions = createActions<Fields>(p);
  const epic: Epic<Actions<Fields>, State<Fields>> = (state$) => {
    return state$.pipe(
      Rx.filter(states.idle.is),
      Rx.map((s) => s.payload.fields),
      Rx.distinctUntilChanged(Obj.isDeepEqual),
      Rx.debounceTime(500),
      Rx.map(actions.submit.create),
    );
  };

  return {
    states,
    actions,
    isState: Typed.getGuard(states),
    isAction: Typed.getGuard(actions),
    reducer: createReducer({ states, actions }),
    epic,
    init: () => states.idle.create(defaultValue),
  };
}

export type State<Fields extends { [k in string]: unknown }> = Typed.GetTypes<
  ReturnType<typeof createStates<Fields>>
>;

export type Actions<Fields extends { [k in string]: unknown }> = Typed.GetTypes<
  ReturnType<typeof createActions<Fields>>
>;

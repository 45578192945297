/* eslint-disable @typescript-eslint/no-explicit-any */

import { PageInfo } from "types";
import { Typed } from "utils/Typed";
import * as Filters from "../../Filters";

type BasePayload<F extends Record<string, unknown>, OrderBy> = {
  filters: Filters.State<F>;
  perPage: number;
  order:
    | {
        by: OrderBy;
        direction: "asc" | "desc";
      }
    | undefined;
};

type ReadyPayload<
  F extends Record<string, unknown>,
  T extends { id: string },
  OrderBy,
> = BasePayload<F, OrderBy> & {
  items: T[];
  pageInfo: PageInfo;
  total: number;
  selected: Array<T["id"]>;
  removing: { [k in T["id"]]?: "removing" | "confirm" | undefined };
  visibleColumns: Record<string, boolean>;
};

type FetchingPayload<
  F extends Record<string, unknown>,
  T extends { id: string },
  OrderBy,
> = ReadyPayload<F, T, OrderBy> & {
  page: "current" | "start" | "prev" | "next" | "end";
};

export const createState = <
  F extends Record<string, unknown>,
  T extends { id: string },
  O,
  A extends {},
>(
  p: string,
) => {
  return Typed.builder
    .add("loading", (p: A & BasePayload<F, O>) => p)
    .add("loadError", (p: A & BasePayload<F, O>) => p)
    .add("ready", (p: A & ReadyPayload<F, T, O>) => p)
    .add("fetching", (p: A & FetchingPayload<F, T, O>) => p)
    .finish()(p);
};

export type State<
  F extends Record<string, unknown>,
  T extends { id: string },
  O,
  A extends {},
> = Typed.GetTypes<ReturnType<typeof createState<F, T, O, A>>>;

import { gql } from "@apollo/client";
import { inventoryItemFragment } from "./InventoryItem";

export const itemSetFragment = gql`
  ${inventoryItemFragment}
  fragment ItemSetFragment on InventoryItemSet {
    id
    dataTypeID
    data
    createdAt
    updatedAt
    sku
    items {
      ...InventoryItemFragment
    }
  }
`;

import React from "react";
import {
  ControlProps,
  rankWith,
  JsonFormsRendererRegistryEntry,
  isTimeControl,
} from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";

import { InputTime } from "../components/InputTime";
import { Control } from "../components/Control";

const MaterialControl = React.memo((props: ControlProps) => (
  <Control {...props} input={InputTime} />
));

export const entry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(4, isTimeControl),
  renderer: withJsonFormsControlProps(MaterialControl),
};

/* eslint-disable @typescript-eslint/no-explicit-any */

import * as Obj from "./object";
import { strictGuard } from "./strictGuard";

export interface Typed<T extends string> {
  type: T;
}

export interface TypedWithPayload<T extends string, P> extends Typed<T> {
  payload: P;
}

class TypedBuilder<T extends { [k in string]: () => any }>
  implements Builder<T>
{
  constructor(private items: T) {}

  add<K extends string>(k: K): Builder<T & { [k in K]: () => void }>;
  add<K extends string, P extends (...a: any[]) => any>(
    k: K,
    p: P,
  ): Builder<T & { [k in K]: P }>;
  add<K extends string, P extends (...a: any[]) => any>(
    k: K,
    p?: P,
  ): Builder<T & { [k in K]: P }> {
    return new TypedBuilder({ ...this.items, [k]: p });
  }

  finish(): <P extends string>(
    p: P,
  ) => {
    [k in keyof T]: k extends string
      ? T[k] extends (...a: any[]) => any
        ? Typed.Constructor<`${P}:${k}`, T[k]>
        : Typed.Constructor<`${P}:${k}`, void>
      : never;
  } {
    // @ts-expect-error, fix later
    return <P extends string>(p: P) => {
      return Obj.map((v, k) => {
        return v
          ? Typed.createWithPayload(`${p}:${k as string}`, v)
          : Typed.create(`${p}:${k as string}`);
      }, this.items);
    };
  }
}

export namespace Typed {
  export function create<T extends string>(type: T): Constructor<T, void> {
    return {
      create: () => ({ type: type }),
      is: (t): t is Typed<T> => t.type === type,
    };
  }

  export function createWithPayload<
    T extends string,
    C extends (...a: any[]) => any,
  >(type: T, c: C): Constructor<T, C> {
    return {
      create: (...a) => ({
        type,
        payload: c(...a),
      }),
      is: (t): t is TypedWithPayload<T, ReturnType<C>> => t.type === type,
    } as Constructor<T, C>;
  }

  export const builder = new TypedBuilder({});

  export type Constructor<
    T extends string,
    C extends void | ((...a: any[]) => any),
  > = C extends (...a: any[]) => infer R
    ? {
        create: (...a: Parameters<C>) => TypedWithPayload<T, R>;
        is: (
          t: Typed<any> | TypedWithPayload<any, any>,
        ) => t is TypedWithPayload<T, R>;
      }
    : {
        create: () => Typed<T>;
        is: (t: Typed<any> | TypedWithPayload<any, any>) => t is Typed<T>;
      };

  export type GetType<
    T extends Constructor<any, void> | Constructor<any, (...a: any[]) => any>,
  > = T extends Constructor<infer Type, (...a: any[]) => infer R>
    ? TypedWithPayload<Type, R>
    : T extends Constructor<infer Type, void>
    ? Typed<Type>
    : never;

  export type GetCollectionType<
    T extends {
      [k in string]: Constructor<any, void | ((...a: any[]) => any)>;
    },
  > = {
    [k in keyof T]: GetType<T[k]>;
  };

  export type GetTypes<
    T extends {
      [k in string]: Constructor<any, void | ((...a: any[]) => any)>;
    },
  > = GetCollectionType<T>[keyof T];

  export const getGuard = <
    T extends {
      [k in string]: Constructor<any, void | ((...a: any[]) => any)>;
    },
  >(
    t: T,
  ) =>
    strictGuard((v: GetTypes<T>): v is GetTypes<T> =>
      Object.values(t).some((x) => x.is(v)),
    );
}

interface Builder<T extends { [k in string]: () => any }> {
  add<K extends string>(k: K): Builder<T & { [k in K]: undefined }>;
  add<K extends string, P extends (...a: any[]) => any>(
    k: K,
    p: P,
  ): Builder<T & { [k in K]: P }>;

  finish(): (p: string) => {
    [k in keyof T]: k extends string
      ? T[k] extends (...a: any[]) => any
        ? Typed.Constructor<`${string}:${k}`, T[k]>
        : Typed.Constructor<`${string}:${k}`, void>
      : never;
  };
}

import { Box } from "@mui/material";
import React from "react";
import { Sxs } from "../../../../types/styles";

export namespace CodeEditor {
  export type Props = {
    code: string;
  };
}

export function CodeEditor({ code }: CodeEditor.Props) {
  return (
    <Box sx={sxs.root}>
      {/*todo: Suspense a code editor/highlighter*/}
      <Box component="pre" sx={sxs.code}>
        {code}
      </Box>
    </Box>
  );
}

const sxs = {
  root: (theme) => ({
    backgroundColor: theme.palette.common.black,
    padding: theme.spacing(6),
    borderBottomRightRadius: theme.spacing(2),
  }),
  code: (theme) => ({
    color: theme.palette.common.white,
    margin: 0,
  }),
} satisfies Sxs;

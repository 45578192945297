import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useTranslation } from "i18n";
import { useBehaviorValue } from "react-rx/behaviorValue";
import { Box, Button, TextField } from "@mui/material";
import PlusIcon from "@mui/icons-material/AddRounded";
import * as R from "rambda";
import { Row } from "../../../components/Row";
import { Label } from "../../../components/Label";
import { Sxs } from "../../../../../../../types/styles";
import { sxFullWidth } from "../../../../../common/sxs";
import { SelectOption } from "./types";

export namespace Editor {
  export type Props = {
    options$: BehaviorValue<SelectOption[]>;
    onOptionsChange: (options: SelectOption[]) => void;
  };
}

export const Editor = ({ options$, onOptionsChange }: Editor.Props) => {
  const { t } = useTranslation();
  const options = useBehaviorValue(options$);

  const addOption = () =>
    onOptionsChange([...options, { label: "", value: "" }]);

  return (
    <>
      <Row sx={sxs.header}>
        <Label>{t("Option")}</Label>
        <Label>{t("Value")}</Label>
      </Row>
      {options.map((option, index) => (
        <Row key={index} mb>
          <Option
            option={option}
            onChange={(option) =>
              onOptionsChange(R.assocPath([index], option, options))
            }
          />
        </Row>
      ))}
      <Row>
        <Box sx={sxs.addBtnWrap}>
          <Button startIcon={<PlusIcon />} onClick={addOption} sx={sxs.addBtn}>
            {t("Add new option")}
          </Button>
        </Box>
      </Row>
    </>
  );
};

const Option = ({
  option,
  onChange,
}: {
  option: SelectOption;
  onChange: (option: SelectOption) => void;
}) => (
  <>
    <TextField
      sx={sxFullWidth}
      value={option.label}
      onChange={({ target: { value: label } }) =>
        onChange({ ...option, label })
      }
    />
    <TextField
      sx={sxFullWidth}
      value={option.value}
      onChange={({ target: { value } }) => onChange({ ...option, value })}
    />
  </>
);

const sxs = {
  header: (theme) => ({
    marginBottom: theme.spacing(1.5),
  }),
  addBtnWrap: (theme) => ({ display: "inline-block" }),
  addBtn: (theme) => ({
    color: theme.palette.primary.dark,
    backgroundColor: "transparent",
    p: 0,
  }),
} satisfies Sxs;

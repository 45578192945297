import { ItemMovement } from "types/src/ItemMovements/ItemMovement";
import { silentUnreachableError } from "utils/exceptions";
import {
  InventoryItem,
  InventoryItemId,
} from "types/src/InventoryItems/InventoryItem";
import { Repository, RepositoryId } from "types/src/Repositories/Repository";
import * as O from "fp-ts/Option";
import { strictGuard } from "utils/strictGuard";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema } from "types/src/jsonSchema/uiSchema";
import { SchemaFieldsActions, schemaFieldsState } from "../utils";

// region Actions
export type Actions =
  | LoadSuccess
  | LoadFail
  | Submit
  | SaveError
  | SaveSuccess
  | InventoryItemsSearchResult
  | RepositoriesFromSearchResult
  | RepositoriesToSearchResult
  | SchemaFieldsActions
  | SetItem
  | SetFrom
  | SetTo
  | SearchItem
  | SearchFrom
  | SearchTo
  | SetQuantity
  | SetHandler;

export const isActions = strictGuard((a: Actions): a is Actions => {
  if (schemaFieldsState.isActions(a)) return true;

  switch (a.type) {
    case "Ready:DataManager:ItemMovements:Create:LoadFail":
    case "Ready:DataManager:ItemMovements:Create:LoadSuccess":
    case "Ready:DataManager:ItemMovements:Create:SaveError":
    case "Ready:DataManager:ItemMovements:Create:SaveSuccess":
    case "Ready:DataManager:ItemMovements:Create:Submit":
    case "Ready:DataManager:ItemMovements:Create:InventoryItemsSearchResult":
    case "Ready:DataManager:ItemMovements:Create:RepositoriesFromSearchResult":
    case "Ready:DataManager:ItemMovements:Create:RepositoriesToSearchResult":
    case "Ready:DataManager:ItemMovements:Create:SetFrom":
    case "Ready:DataManager:ItemMovements:Create:SetItem":
    case "Ready:DataManager:ItemMovements:Create:SetTo":
    case "Ready:DataManager:ItemMovements:Create:SearchFrom":
    case "Ready:DataManager:ItemMovements:Create:SearchTo":
    case "Ready:DataManager:ItemMovements:Create:SearchItem":
    case "Ready:DataManager:ItemMovements:Create:SetHandler":
    case "Ready:DataManager:ItemMovements:Create:SetQuantity":
      return true;
    default:
      silentUnreachableError(a);
      return false;
  }
});
// endregion

// region LoadFail
export interface LoadFail {
  type: "Ready:DataManager:ItemMovements:Create:LoadFail";
}

export const loadFail = (): LoadFail => ({
  type: "Ready:DataManager:ItemMovements:Create:LoadFail",
});
// endregion

// region LoadSuccess
export interface LoadSuccess {
  type: "Ready:DataManager:ItemMovements:Create:LoadSuccess";
  payload: {
    schema: DataSchema;
    ui: UiSchema;
    inventoryItems: InventoryItem[];
    repositories: Repository[];
  };
}

export const loadSuccess = (payload: LoadSuccess["payload"]): LoadSuccess => ({
  type: "Ready:DataManager:ItemMovements:Create:LoadSuccess",
  payload,
});
// endregion

// region Submit
export interface Submit {
  type: "Ready:DataManager:ItemMovements:Create:Submit";
}

export const submit = (): Submit => ({
  type: "Ready:DataManager:ItemMovements:Create:Submit",
});
// endregion

// region SaveError
export interface SaveError {
  type: "Ready:DataManager:ItemMovements:Create:SaveError";
}

export const saveError = (): SaveError => ({
  type: "Ready:DataManager:ItemMovements:Create:SaveError",
});
// endregion

// region SaveSuccess
export interface SaveSuccess {
  type: "Ready:DataManager:ItemMovements:Create:SaveSuccess";
  payload: ItemMovement;
}

export const saveSuccess = (payload: SaveSuccess["payload"]): SaveSuccess => ({
  type: "Ready:DataManager:ItemMovements:Create:SaveSuccess",
  payload,
});
// endregion

// region InventoryItemsSearchResult
export interface InventoryItemsSearchResult {
  type: "Ready:DataManager:ItemMovements:Create:InventoryItemsSearchResult";
  payload: InventoryItem[];
}

export const inventoryItemsSearchResult = (
  payload: InventoryItemsSearchResult["payload"],
): InventoryItemsSearchResult => ({
  type: "Ready:DataManager:ItemMovements:Create:InventoryItemsSearchResult",
  payload,
});
// endregion

// region RepositoriesFromSearchResult
export interface RepositoriesFromSearchResult {
  type: "Ready:DataManager:ItemMovements:Create:RepositoriesFromSearchResult";
  payload: Repository[];
}

export const repositoriesFromSearchResult = (
  payload: RepositoriesFromSearchResult["payload"],
): RepositoriesFromSearchResult => ({
  type: "Ready:DataManager:ItemMovements:Create:RepositoriesFromSearchResult",
  payload,
});
// endregion

// region RepositoriesToSearchResult
export interface RepositoriesToSearchResult {
  type: "Ready:DataManager:ItemMovements:Create:RepositoriesToSearchResult";
  payload: Repository[];
}

export const repositoriesToSearchResult = (
  payload: RepositoriesToSearchResult["payload"],
): RepositoriesToSearchResult => ({
  type: "Ready:DataManager:ItemMovements:Create:RepositoriesToSearchResult",
  payload,
});
// endregion

// region SetItem
export interface SetItem {
  type: "Ready:DataManager:ItemMovements:Create:SetItem";
  payload: InventoryItemId;
}

export const setItem = (payload: SetItem["payload"]): SetItem => ({
  type: "Ready:DataManager:ItemMovements:Create:SetItem",
  payload,
});
// endregion

// region SetFrom
export interface SetFrom {
  type: "Ready:DataManager:ItemMovements:Create:SetFrom";
  payload: RepositoryId;
}

export const setFrom = (payload: SetFrom["payload"]): SetFrom => ({
  type: "Ready:DataManager:ItemMovements:Create:SetFrom",
  payload,
});
// endregion

// region SetTo
export interface SetTo {
  type: "Ready:DataManager:ItemMovements:Create:SetTo";
  payload: RepositoryId;
}

export const setTo = (payload: SetTo["payload"]): SetTo => ({
  type: "Ready:DataManager:ItemMovements:Create:SetTo",
  payload,
});
// endregion

// region SearchItem
export interface SearchItem {
  type: "Ready:DataManager:ItemMovements:Create:SearchItem";
  payload: string;
}

export const searchItem = (payload: SearchItem["payload"]): SearchItem => ({
  type: "Ready:DataManager:ItemMovements:Create:SearchItem",
  payload,
});
// endregion

// region SearchFrom
export interface SearchFrom {
  type: "Ready:DataManager:ItemMovements:Create:SearchFrom";
  payload: string;
}

export const searchFrom = (payload: SearchFrom["payload"]): SearchFrom => ({
  type: "Ready:DataManager:ItemMovements:Create:SearchFrom",
  payload,
});
// endregion

// region SearchTo
export interface SearchTo {
  type: "Ready:DataManager:ItemMovements:Create:SearchTo";
  payload: string;
}

export const searchTo = (payload: SearchTo["payload"]): SearchTo => ({
  type: "Ready:DataManager:ItemMovements:Create:SearchTo",
  payload,
});
// endregion

// region SetQuantity
export interface SetQuantity {
  type: "Ready:DataManager:ItemMovements:Create:SetQuantity";
  payload: O.Option<number>;
}

export const setQuantity = (payload: SetQuantity["payload"]): SetQuantity => ({
  type: "Ready:DataManager:ItemMovements:Create:SetQuantity",
  payload,
});
// endregion

// region SetHandler
export interface SetHandler {
  type: "Ready:DataManager:ItemMovements:Create:SetHandler";
  payload: string;
}

export const setHandler = (payload: SetHandler["payload"]): SetHandler => ({
  type: "Ready:DataManager:ItemMovements:Create:SetHandler",
  payload,
});
// endregion

import { Box } from "@mui/material";
import React from "react";
import { GeneralForm as GeneralForm_ } from "../types/form";
import { General } from "./General";
import { GeneralFormDataProps } from "./types";
import { Tabs } from "./Tabs";

export function Content<CustomTabId extends string>({
  formData$,
  onValuesChange,

  customTabs,
  activeTabId,
  onActiveTabChange,

  children,
}: Content.Props<CustomTabId>) {
  return (
    <>
      <Tabs
        id={activeTabId}
        onChange={onActiveTabChange}
        customTabs={customTabs}
      />
      <Box>
        {activeTabId === Tabs.tabIdGeneral ? (
          <General
            {...{
              formData$,
              onValuesChange,
              children,
            }}
          />
        ) : (
          children
        )}
      </Box>
    </>
  );
}

export namespace Content {
  export namespace GeneralForm {
    export type Values = GeneralForm_.Values;
    export type Errors = GeneralForm_.Errors;
    export type Data = GeneralForm_.Data;
  }

  export type TabId<CustomTabId extends string = string> =
    Tabs.TabId<CustomTabId>;
  export const tabIdGeneral = Tabs.tabIdGeneral;
  export type CustomTab = Tabs.CustomTab;

  export type Props<CustomTabId extends string> = React.PropsWithChildren<
    GeneralFormDataProps & {
      customTabs: Tabs.CustomTab[];
      activeTabId: Tabs.TabId<CustomTabId>;
      onActiveTabChange: (id: Tabs.TabId<CustomTabId>) => void;
    }
  >;
}

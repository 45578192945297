import { silentUnreachableError } from "utils/exceptions";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import { Typed } from "utils/Typed";
import { SchemaFieldsState, schemaFieldsState } from "./utils";
import * as Actions from "./types/Actions";
import * as State from "./types/State";
import { exits } from "./types/Exits";

export function reducer(
  s: State.State,
  a: Actions.Actions,
): E.Either<Typed.GetTypes<typeof exits>, State.State> {
  if (schemaFieldsState.isActions(a)) {
    if (State.isReady(s)) {
      return E.right(
        State.ready({
          customerId: s.payload.customerId,
          dataTypeId: s.payload.dataTypeId,
          submitted: s.payload.submitted,
          schema: Fp.pipe(
            schemaFieldsState.reducer(s.payload.schema, a),
            E.getOrElse(() => s.payload.schema),
          ),
        }),
      );
    }

    return E.right(s);
  }

  switch (a.type) {
    case "Ready:DataManager:Customers:Edit:LoadFail": {
      if (State.isLoading(s)) {
        return E.right(State.loadError(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Customers:Edit:LoadSuccess": {
      if (State.isLoading(s)) {
        return E.right(
          State.ready({
            customerId: a.payload.customer.id,
            dataTypeId: a.payload.customer.dataTypeId,
            schema: schemaFieldsState.states.init.create({
              schema: a.payload.schema,
              uiSchema: a.payload.ui,
              values: a.payload.customer.fields,
            }),
            submitted: false,
          }),
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Customers:Edit:Submit": {
      if (State.isReady(s)) {
        return Fp.pipe(
          schemaFieldsState.reducer(
            s.payload.schema,
            schemaFieldsState.actions.submit.create(),
          ),
          E.getOrElse(() => s.payload.schema),
          E.fromPredicate(
            schemaFieldsState.states.valid.is,
            (v: SchemaFieldsState) => v,
          ),
          E.mapLeft((schema) =>
            State.ready({ ...s.payload, schema, submitted: true }),
          ),
          E.map((schema) =>
            State.saving({ ...s.payload, schema, submitted: true }),
          ),
          E.getOrElseW((v) => v),
          E.right,
        );
      }

      return E.right(s);
    }
    case "Ready:DataManager:Customers:Edit:SaveError": {
      if (State.isSaving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }
    case "Ready:DataManager:Customers:Edit:SaveSuccess": {
      if (State.isSaving(s)) {
        return E.left(exits.saved.create(a.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Customers:Edit:Remove": {
      if (State.isReady(s) || State.isSaving(s)) {
        return E.right(State.removeConfirmation(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Customers:Edit:RemoveDecline": {
      if (State.isRemoveConfirmation(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Customers:Edit:RemoveConfirm": {
      if (State.isRemoveConfirmation(s)) {
        return E.right(State.removing(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Customers:Edit:RemoveFail": {
      if (State.isRemoving(s)) {
        return E.right(State.ready(s.payload));
      }

      return E.right(s);
    }

    case "Ready:DataManager:Customers:Edit:RemoveSuccess": {
      if (State.isRemoving(s)) {
        return E.left(exits.removed.create(s.payload.customerId));
      }

      return E.right(s);
    }

    default: {
      silentUnreachableError(a);
      return E.right(s);
    }
  }
}

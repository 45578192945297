import { ISODate } from "types/src/date/ISODate";
import { DataTypeId } from "types/src/DataType/DataType";
import { Cursor } from "types";
import { omitEmpties } from "utils/value";
import { CustomerId } from "types/src/Customers/Customer";
import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { PickingOrderId } from "types/src/PickingOrder/PickingOrder";
import {
  GetPickingOrdersQueryVariables,
  PickingOrderOrderField,
} from "../generated/graphql";
import { toApiOrderDirection } from "../transformers/OrderDirection";
import * as PickingOrderItems from "./PickingOrderItems";
import { Where } from "./Where";

export type WhereSchema = Where<{
  data: Where.Map;
  customerId: Where.EqIn<CustomerId>;
  createdAt: Where.Ord<ISODate>;
  updatedAt: Where.Ord<ISODate>;
  id: Where.EqIn<PickingOrderId>;
  dataTypeId: Where.EqNil<DataTypeId>;
  hasOrderItems: Where.Value<boolean>;
  hasOrderItemWith: Where.WithWhereList<PickingOrderItems.WhereSchema>;
}>;

export const whereSchema = Where.create<WhereSchema>({
  data: Where.map("Data"),
  customerId: Where.eqIn("customerID"),
  createdAt: Where.ord(),
  updatedAt: Where.ord(),
  id: Where.eqIn(),
  dataTypeId: Where.eqNil("dataTypeID"),
  hasOrderItems: Where.value("hasOrderItems"),
  hasOrderItemWith: Where.withWhereList(
    (): PickingOrderItems.WhereSchema => PickingOrderItems.whereSchema,
    "hasOrderItemsWith",
  ),
});

export type PickingOrderVarsWhere = Where.GetType<WhereSchema>;

export interface GetPickingOrdersVars {
  after?: Cursor;
  first?: number;
  before?: Cursor;
  last?: number;
  orderBy?: {
    by: "createdAt" | "updatedAt";
    direction: "asc" | "desc";
  };
  where?: PickingOrderVarsWhere;
}

export function getPickingOrdersVarsToApiVars(
  vars: GetPickingOrdersVars,
): GetPickingOrdersQueryVariables {
  return (
    omitEmpties({
      first: vars.first,
      last: vars.last,
      after: vars.after,
      before: vars.before,
      where: omitEmpties(
        pipe(
          vars.where,
          O.fromNullable,
          O.map(Where.toApiWhere(whereSchema)),
          O.toNullable,
        ),
      ),
      orderBy: pipe(
        vars.orderBy,
        O.fromNullable,
        O.map((v) => ({
          field: pipe(
            v.by,
            (v) =>
              ({
                createdAt: PickingOrderOrderField.CreatedAt,
                updatedAt: PickingOrderOrderField.UpdatedAt,
              })[v],
          ),
          direction: pipe(v.direction, toApiOrderDirection),
        })),
        O.toUndefined,
      ),
    }) ?? {}
  );
}

import { ReactNode } from "react";
import { Grid2 } from "@mui/material";

import { ContentLayout } from "@layouts/ContentLayout";

export interface PageLayoutProps {
  mainSidebar: ReactNode;
  secondarySidebar?: ReactNode;
  header?: ReactNode;
  children: ReactNode;
  className?: string;
  fullScreenContent?: boolean;
}

export function PageLayout({
  mainSidebar,
  secondarySidebar,
  header,
  children,
  className,
  fullScreenContent,
}: PageLayoutProps) {
  return (
    <Grid2
      container
      className={className}
      flexGrow={1}
      sx={{ height: "100vh" }}
    >
      <GridContainer row>
        {mainSidebar}
        {secondarySidebar}
      </GridContainer>

      <ContentLayout header={header} fullScreen={fullScreenContent}>
        {children}
      </ContentLayout>
    </Grid2>
  );
}

function GridContainer(p: {
  children?: ReactNode;
  grow?: boolean;
  row?: boolean;
}) {
  return (
    <Grid2
      container
      sx={{
        maxHeight: "100%",
        minHeight: 0,
        display: "flex",
        flexDirection: p.row ? "row" : "column",
        overflowY: "auto",
      }}
      size={p.grow ? "grow" : "auto"}
    >
      {p.children}
    </Grid2>
  );
}

import { silentUnreachableError } from "utils/exceptions";
import { Typed } from "utils/Typed";
import * as E from "fp-ts/Either";
import { Ajv } from "ajv";
import addFormats from "ajv-formats";
import { createStates } from "./types/State";
import { createActions } from "./types/Actions";

export const reducer = <P extends string>(p: P) => {
  const ajv = new Ajv();
  addFormats(ajv);

  const states = createStates(p);
  const actions = createActions(p);

  type State = Typed.GetTypes<typeof states>;
  type Actions = Typed.GetTypes<typeof actions>;

  return (s: State, a: Actions): E.Either<never, State> => {
    if (actions.onChange.is(a)) {
      if (states.init.is(s) || states.edited.is(s)) {
        return E.right(
          states.edited.create({
            ...s.payload,
            values: a.payload,
          }),
        );
      }

      if (states.valid.is(s) || states.invalid.is(s)) {
        const valid = ajv.validate(s.payload.schema, a.payload);

        return E.right(
          valid
            ? states.valid.create({ ...s.payload, values: a.payload })
            : states.invalid.create({ ...s.payload, values: a.payload }),
        );
      }

      silentUnreachableError(s);
      return E.right(s);
    }

    if (actions.submit.is(a)) {
      if (states.edited.is(s)) {
        const valid = ajv.validate(s.payload.schema, s.payload.values);

        return E.right(
          valid
            ? states.valid.create(s.payload)
            : states.invalid.create(s.payload),
        );
      }

      return E.right(s);
    }

    silentUnreachableError(a);
    return E.right(s);
  };
};

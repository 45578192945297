import { RootState } from "state-manager";
import { useTranslation } from "i18n";
import { FlexFormWrapper } from "ui/layouts/FormWrapper";
import { Input } from "@Containers/Form/Input";
import { Textarea } from "@Containers/Form/Textarea";
import { Toggle } from "@Containers/Form/Toggle";
import { DataTypeEntity } from "types/src/DataType/DataType";
import * as Fp from "fp-ts/function";
import { DataSchema } from "types/src/jsonSchema/dataSchema";
import { UiSchema, UiSchemaElementType } from "types/src/jsonSchema/uiSchema";
import { Combobox } from "@Containers/Form/Combobox";
import * as Obj from "utils/object";
import { useDataTypeEntityTitle } from "@Hooks/useDataTypeEntityTitle";
import { JsonSchemaFormBuilder } from "json-schema-form-builder";
import { useStateBehavior } from "@Hooks/useStateBehavior";
import { Value } from "types/src/jsonSchema/value";
import { useMemo } from "react";
import { BehaviorSubject } from "rxjs";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { FormColumns } from "ui/layouts/FormColumns";

interface FormProps {
  name: string;
  description: string;
  default: boolean;
  entity: DataTypeEntity;
  schema: DataSchema | undefined;
  uiSchema: UiSchema | undefined;
}

interface Dispatcher {
  setName: (v: string) => void;
  setDescription: (v: string) => void;
  setDefault: (v: boolean) => void;
  setEntity: (v: DataTypeEntity) => void;
  setSchemas: (v: { schema: DataSchema; uiSchema: UiSchema }) => void;
}

export interface DataTypeFormProps {
  selector: (s: RootState) => FormProps;
  dispatch: Dispatcher;
}

export function DataTypeForm(p: DataTypeFormProps) {
  const { t } = useTranslation();
  const state$ = useStateBehavior();
  const [uiState$, setUiState] = useMemo(() => {
    const b$ = new BehaviorSubject<JsonSchemaFormBuilder.UiState>({
      previewMode: JsonSchemaFormBuilder.PreviewMode.form,
      previewFormValues: {},
      editing: undefined,
      expandedElements: {},
    });
    return [
      BehaviorValue.fromBehaviorSubject(b$),
      (v: Partial<JsonSchemaFormBuilder.UiState>) =>
        b$.next({ ...b$.value, ...v }),
    ];
  }, []);
  const builderValue$ = state$.map(
    Fp.flow(p.selector, (v) => ({
      dataSchema: v.schema ?? ({} as Value["dataSchema"]),
      uiSchema: v.uiSchema ?? {
        type: UiSchemaElementType.verticalLayout,
        elements: [],
      },
    })),
  );
  const entityT = useDataTypeEntityTitle();
  const name$ = Fp.flow(p.selector, nameSelector);
  const desc$ = Fp.flow(p.selector, descSelector);
  const default$ = Fp.flow(p.selector, defaultSelector);

  return (
    <FlexFormWrapper>
      <FormColumns>
        <FlexFormWrapper>
          <Input
            value$={name$}
            onChange={p.dispatch.setName}
            label={t("Name")}
          />
          <Combobox
            value$={Fp.flow(p.selector, (v) => v.entity)}
            onChange={p.dispatch.setEntity}
            options={Fp.pipe(DataTypeEntity, Obj.values, (vs) =>
              vs.map((v) => ({ value: v, label: entityT(v) })),
            )}
            label={t("Entity")}
          />
          <Toggle value$={default$} onChange={p.dispatch.setDefault}>
            {t("Make default")}
          </Toggle>
        </FlexFormWrapper>
        <FlexFormWrapper>
          <Textarea
            rows={6}
            label={t("Description")}
            value$={desc$}
            onChange={p.dispatch.setDescription}
          />
        </FlexFormWrapper>
      </FormColumns>
      <JsonSchemaFormBuilder
        value$={builderValue$}
        onValueChange={(v) =>
          p.dispatch.setSchemas({ uiSchema: v.uiSchema, schema: v.dataSchema })
        }
        ui$={uiState$}
        onUiChange={setUiState}
      />
    </FlexFormWrapper>
  );
}

const nameSelector = (s: FormProps) => s.name;
const descSelector = (s: FormProps) => s.description;
const defaultSelector = (s: FormProps) => s.default;

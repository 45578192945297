import { Selector, useSelector } from "state-manager";
import { ReactElement } from "react";
import { Typography } from "ui/components/Typography";
import { FormWrapper } from "ui/layouts/FormWrapper";
import { InboundItem } from "state-manager/generic-states/InboundItem";
import { TranslatedStr } from "types/src/TranslatedStr";
import { Tuple } from "types/src/Tuple";
import * as Fp from "fp-ts/function";
import { Item } from "./Item";
import { Add } from "./Add";

export interface InboundItemsProps<Id extends string> {
  label?: TranslatedStr;
  value$: Selector<Array<[Id, InboundItem.State]>>;
  dispatch: (id: Id, a: InboundItem.Actions) => void;
  onAdd: () => void;
  onRemove: (id: Id) => void;
  instance: InboundItem.Store;
  onAdvanced: (id: Id) => void;
}

export function InboundItems<Id extends string>(
  p: InboundItemsProps<Id>,
): ReactElement {
  const ids = useSelector(
    Fp.flow(p.value$, (v) => v.map(Tuple.fst)),
    (a, b) => a.join(",") === b.join(","),
  );
  return (
    <FormWrapper>
      <Typography>{p.label}</Typography>
      {ids.map((id) => {
        return (
          <Item
            key={id}
            selector={Fp.flow(
              p.value$,
              (vs) => vs.find(([_id]) => _id === id) as [Id, InboundItem.State],
              Tuple.snd,
            )}
            onRemove={() => p.onRemove(id)}
            onAdvanced={() => p.onAdvanced(id)}
            dispatch={(a) => p.dispatch(id, a)}
            instance={p.instance}
          />
        );
      })}
      <Add onAdd={p.onAdd} />
    </FormWrapper>
  );
}

import { DataTypeId } from "types/src/DataType/DataType";
import { strictGuard } from "utils/strictGuard";
import { silentUnreachableError } from "utils/exceptions";
import { createSchemaFieldsState, SchemaFieldsAction } from "../utils";

export type Actions =
  | SchemaFieldsAction
  | SetDataType
  | SetSku
  | SetQuantity
  | Submit;

export const isActions = (p: string) => {
  const isSchemaFieldsActions = createSchemaFieldsState(p).isActions;

  return strictGuard((a: Actions): a is Actions => {
    if (
      isSchemaFieldsActions(a) ||
      isSetDataType(p)(a) ||
      isSetSku(p)(a) ||
      isSetQuantity(p)(a) ||
      isSubmit(p)(a)
    )
      return true;

    silentUnreachableError(a);
    return false;
  });
};

// region SetDataType
export interface SetDataType {
  type: `${string}:SetDataType`;
  payload: DataTypeId;
}

export const setDataType =
  (p: string) =>
  (payload: SetDataType["payload"]): SetDataType => ({
    type: `${p}:SetDataType`,
    payload,
  });

export const isSetDataType =
  (p: string) =>
  (s: Actions): s is SetDataType =>
    s.type === `${p}:SetDataType`;
// endregion

// region SetSku
export interface SetSku {
  type: `${string}:SetSku`;
  payload: string | undefined;
}

export const setSku =
  (p: string) =>
  (payload: SetSku["payload"]): SetSku => ({
    type: `${p}:SetSku`,
    payload,
  });

export const isSetSku =
  (p: string) =>
  (s: Actions): s is SetSku =>
    s.type === `${p}:SetSku`;
// endregion

// region SetQuantity
export interface SetQuantity {
  type: `${string}:SetQuantity`;
  payload: number | undefined;
}

export const setQuantity =
  (p: string) =>
  (payload: SetQuantity["payload"]): SetQuantity => ({
    type: `${p}:SetQuantity`,
    payload,
  });

export const isSetQuantity =
  (p: string) =>
  (s: Actions): s is SetQuantity =>
    s.type === `${p}:SetQuantity`;
// endregion

// region Submit
export interface Submit {
  type: `${string}:Submit`;
}

export const submit = (p: string) => (): Submit => ({
  type: `${p}:Submit`,
});

export const isSubmit =
  (p: string) =>
  (s: Actions): s is Submit =>
    s.type === `${p}:Submit`;
// endregion

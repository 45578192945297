import React from "react";
import {
  ControlProps,
  rankWith,
  JsonFormsRendererRegistryEntry,
  isOneOfEnumControl,
} from "@jsonforms/core";
import { withJsonFormsOneOfEnumProps } from "@jsonforms/react";

import { Control } from "../components/Control";
import { Select } from "../components/Select";

export const entry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(5, isOneOfEnumControl),
  renderer: withJsonFormsOneOfEnumProps(
    React.memo((props: ControlProps) => <Control {...props} input={Select} />),
  ),
};

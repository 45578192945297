import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { BehaviorValueRef, useBehaviorValueRef } from "react-rx/behaviorValue";
import { BehaviorSubject } from "rxjs";
import { ControlValues, ControlErrors } from "../types";
import { ControlTypeAndSchemas } from "../../../../../types/control";
import { scopeToName } from "../../../../../utils/uiSchema/scope";
import { ControlTypesContext } from "../../../../../contexts/ControlTypes";
import { ControlType } from "../../../../../types/control/type";
import { useUpdateSchemas } from "./useUpdateSchemas";
import { useValidate } from "./useValidate";

type Props = {
  typeAndSchemas$: BehaviorValue<ControlTypeAndSchemas<ControlType>>;
};

export const useFormDataController = ({ typeAndSchemas$ }: Props) => {
  const { schemaValues$ } = useSchemaValues({
    typeAndSchemas$,
  });
  const { values$, valuesRef, valuesSubject$ } = useValues({ schemaValues$ });
  const { errors$, errorsSubject$ } = useErrors();

  const formData$ = useMemo(
    () =>
      BehaviorValue.combine([values$, errors$]).map(([values, errors]) => ({
        values,
        errors,
      })),
    [values$, errors$],
  );

  const onValuesChange = useOnValuesChange({
    typeAndSchemas$,
    valuesRef,
    valuesSubject$,
    errorsSubject$,
  });

  return { formData$, onValuesChange };
};

const useSchemaValues = <Type extends ControlType>({
  typeAndSchemas$,
}: {
  typeAndSchemas$: BehaviorValue<ControlTypeAndSchemas<Type>>;
}) => {
  const schemaValues$ = useMemo<BehaviorValue<ControlValues<Type>>>(
    () =>
      typeAndSchemas$.map(
        ({ type, dataSchema, dataSchemaRequired, uiSchema }) => ({
          ...type.values.default,
          ...type.values.fromSchema({
            dataSchema,
            dataSchemaRequired,
            uiSchema,
          }),
          name: scopeToName(uiSchema.scope),
        }),
      ),
    [typeAndSchemas$],
  );

  return { schemaValues$ };
};

const useValues = ({
  schemaValues$,
}: {
  schemaValues$: BehaviorValue<ControlValues>;
}) => {
  const valuesSubject$ = useMemo(
    () => new BehaviorSubject(schemaValues$.value),
    [schemaValues$],
  );
  const values$ = useMemo(
    () => BehaviorValue.fromBehaviorSubject(valuesSubject$),
    [valuesSubject$],
  );
  const valuesRef = useBehaviorValueRef(values$);

  useEffect(() => {
    // sync: schema updates -> local values
    const subscription = schemaValues$.subscribe((values) => {
      // todo: keep changed values (that have errors and were not sent to schema)
      //       but only if they are compatible new values structure
      valuesSubject$.next(values);
    });
    return () => subscription.unsubscribe();
  }, [schemaValues$, valuesSubject$]);

  return { values$, valuesRef, valuesSubject$ };
};

const useErrors = () => {
  const errorsSubject$ = useMemo(
    () => new BehaviorSubject<ControlErrors>({}),
    [],
  );
  const errors$ = useMemo(
    () => BehaviorValue.fromBehaviorSubject(errorsSubject$),
    [errorsSubject$],
  );

  return { errors$, errorsSubject$ };
};

const useOnValuesChange = ({
  typeAndSchemas$,
  valuesRef,
  valuesSubject$,
  errorsSubject$,
}: {
  typeAndSchemas$: BehaviorValue<
    ControlTypeAndSchemas</*fixme: generic?*/ ControlType>
  >;
  valuesRef: BehaviorValueRef<ControlValues>;
  valuesSubject$: BehaviorSubject<ControlValues>;
  errorsSubject$: BehaviorSubject<ControlErrors>;
}) => {
  const controlTypes = useContext(ControlTypesContext);
  const validate = useValidate({ typeAndSchemas$ });
  const updateSchemas = useUpdateSchemas({ controlSchemas$: typeAndSchemas$ });

  return useCallback(
    (changes: Partial<Record<string, unknown>>) => {
      let values = {
        ...valuesRef.current,
        ...changes,
      } /*ok?*/ as ControlValues;

      if (changes.type) {
        const type = controlTypes[changes.type as ControlType];
        if (!type) {
          return; // log maybe
        }

        values = { name: values.name, ...type.values.default };
      }

      // todo: `format` also affects values structure
      //       maybe also create `type.values.defaultByFormat`
      // if (changes.format)

      const errors = validate(values);

      valuesSubject$.next(values);
      errorsSubject$.next(errors);

      if (!Object.keys(errors).length) {
        updateSchemas(values);
      }
    },
    [
      controlTypes,
      errorsSubject$,
      updateSchemas,
      validate,
      valuesRef,
      valuesSubject$,
    ],
  );
};

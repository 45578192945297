import { useMemo } from "react";
import { BehaviorValue } from "rx-addons/BehaviorValue";
import { useBehaviorValue } from "react-rx/behaviorValue";
import * as Rx from "rxjs";
import { isDeepEqual } from "utils/object";
import { ControlType } from "../../../../../types/control/type";
import { ControlTypeAndSchemas } from "../../../../../types/control";
import { ControlTypeValues } from "../../../../../types/control/values";
import { ControlTypeUI } from "../../../../../types/control/ui";

type Props<Type extends ControlType> = {
  typeAndSchemas$: BehaviorValue<ControlTypeAndSchemas<Type>>;
  values$: BehaviorValue<ControlTypeValues<Type>>;
};

export const useUi = <Type extends ControlType>({
  typeAndSchemas$,
  values$,
}: Props<Type>): ControlTypeUI<Type> => {
  const typeUI = useBehaviorValue(
    useMemo(
      () => typeAndSchemas$.map(({ type }) => type.UI),
      [typeAndSchemas$],
    ),
  );
  const affectingValues = useBehaviorValue(
    useMemo(
      () =>
        values$
          .map(typeUI.getAffectingValues)
          .pipe(Rx.distinctUntilChanged(isDeepEqual)),
      [typeUI, values$],
    ),
  );

  return useMemo(() => typeUI.get(affectingValues), [affectingValues, typeUI]);
};

import {
  UserManager,
  WebStorageStateStore,
  User as UserClient,
} from "oidc-client-ts";
import {
  OpenAI,
  OpenAIApiKey,
  OpenAIOrgKey,
  OrgId,
  Organization,
  RefreshToken,
  User,
  UserAccessToken,
  UserJWT,
  UserId,
} from "types/src";
import * as O from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";

export { WebStorageStateStore } from "oidc-client-ts";

declare module "oidc-client-ts" {
  export interface IdTokenClaims {
    "urn:zitadel:iam:org:project:roles": {
      reader?: Record<string, string>;
      admin?: Record<string, string>;
      writer?: Record<string, string>;
    };
    "urn:zitadel:iam:user:resourceowner:id": string;
  }
}

interface UserProviderPayload {
  clientId: string;
  authority: string;
  redirectUri: string;
  store: WebStorageStateStore;
}

export class UserProvider {
  private manager: UserManager;

  constructor(payload: UserProviderPayload) {
    this.manager = new UserManager({
      client_id: payload.clientId,
      authority: payload.authority,
      redirect_uri: payload.redirectUri,
      userStore: payload.store,
      response_type: "code",
      scope:
        "openid profile email urn:zitadel:iam:user:resourceowner offline_access urn:zitadel:iam:user:metadata",
    });
  }

  public signIn(): Promise<User> {
    return this.manager.signinPopup().then((user) => {
      type MetaData =
        | {
            openai_api_key?: OpenAIApiKey;
            openai_org_key?: OpenAIOrgKey;
          }
        | undefined;

      const metadata = user.profile[
        "urn:zitadel:iam:user:metadata"
      ] as MetaData;

      user.profile.orgId =
        user.profile["urn:zitadel:iam:user:resourceowner:id"];

      if (metadata) {
        user.profile.openAI = {
          apiKey: metadata?.openai_api_key
            ? atob(metadata.openai_api_key)
            : undefined,
          orgKey: metadata?.openai_org_key
            ? atob(metadata.openai_org_key)
            : undefined,
        };
      }

      return this.manager.storeUser(user).then(
        (): User => ({
          id: user.profile.sub as UserId,
          accessToken: user.access_token as UserAccessToken,
          jwt: user.id_token as UserJWT,
          orgs: getOrgsByUser(user),
          orgId: user.profile["urn:zitadel:iam:user:resourceowner:id"] as OrgId,
          openAI: user.profile.openAI as OpenAI | undefined,
          refreshToken: user.refresh_token as RefreshToken,
          username: user.profile.name as string,
          email: user.profile.email as string,
          avatar: user.profile.picture,
        }),
      );
    });
  }

  public signInConfirm(): Promise<void> {
    return this.manager.signinPopupCallback();
  }

  public signOut(): Promise<void> {
    return this.manager.removeUser();
  }

  public getUser(): Promise<Option<User>> {
    return this.manager
      .getUser()
      .then(O.fromNullable)
      .then(O.filter((v) => !!v.expires_at && v.expires_at * 1000 > Date.now()))
      .then(
        O.map(
          (user): User => ({
            id: user.profile.sub as UserId,
            accessToken: user.access_token as UserAccessToken,
            jwt: user.id_token as UserJWT,
            orgs: getOrgsByUser(user),
            orgId: user.profile.orgId as OrgId,
            openAI: user.profile.openAI as User["openAI"],
            refreshToken: user.refresh_token as RefreshToken,
            username: user.profile.name as string,
            email: user.profile.email as string,
            avatar: user.profile.picture,
          }),
        ),
      );
  }

  public async setActiveOrgId(orgId: OrgId) {
    const user = await this.manager.getUser();

    if (!user) {
      return Promise.resolve();
    }

    user.profile.orgId = orgId;

    return this.manager.storeUser(user);
  }

  public signinSilent(): Promise<Option<User>> {
    return this.manager.signinSilent().then(async (user) => {
      const currentUser = await this.manager.getUser();
      const orgId =
        currentUser?.profile.orgId ??
        user?.profile["urn:zitadel:iam:user:resourceowner:id"];

      if (user) {
        user.profile.orgId = orgId;
      }

      return this.manager.storeUser(user).then(
        (): Option<User> =>
          pipe(
            user,
            O.fromNullable,
            O.map(
              (user): User => ({
                id: user.profile.sub as UserId,
                accessToken: user.access_token as UserAccessToken,
                jwt: user.id_token as UserJWT,
                orgs: getOrgsByUser(user),
                orgId: orgId as OrgId,
                openAI: user.profile.openAI as User["openAI"],
                refreshToken: user.refresh_token as RefreshToken,
                username: user.profile.name as string,
                email: user.profile.email as string,
                avatar: user.profile.picture,
              }),
            ),
          ),
      );
    });
  }

  public signinSilentConfirm(): Promise<void> {
    return this.manager.signinSilentCallback();
  }
}

function getOrgsByUser(user: UserClient): Organization[] {
  const roles = user.profile["urn:zitadel:iam:org:project:roles"];

  return Object.entries(roles)
    .map(([type, role]) =>
      Object.entries(role).map(([id, link]) => ({ id, type, link })),
    )
    .flat() as Organization[];
}

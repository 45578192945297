import { Sxs } from "../../../types/styles";

export const sxDragHandle: Sxs[string] = (theme) => ({
  touchAction: "none",
  cursor: "move",
});

export const sxFullWidth: Sxs[string] = (theme) => ({
  width: "100%",
});

import { DataTypeEntity, DataTypeId } from "types/src/DataType/DataType";
import { ISODate } from "types/src/date/ISODate";
import * as Rx from "rxjs";
import { Client } from "ds";
import { getDataTypes, removeDataTypes, GetDataTypesVars } from "ds/DataTypes";
import * as Fp from "fp-ts/function";
import * as E from "fp-ts/Either";
import * as O from "fp-ts/Option";
import { Typed } from "utils/Typed";
import { DateRange } from "types/src/date/DateRange";
import { GetGuardType } from "types/src/Utils";
import { ListingWithDataType } from "../../../../generic-states/ListingWithDataType";
import { Epic } from "../../../../types/RootEpic";
import { ListingState } from "../../../../generic-states/Listing";

function createState(p: string) {
  const state = ListingState.createState<
    DataTypesListing.Filters,
    DataTypesListing.Item,
    DataTypesListing.OrderBy,
    {}
  >(p, { defaultFilters: {} });

  const epic: Epic<
    ListingWithDataType.GetActions<typeof state>,
    ListingWithDataType.GetState<typeof state>,
    DataTypesListing.Deps
  > = (state$, deps) => {
    return state.epic(state$, {
      fetchItems: (s) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(getDataTypes(client, getFetchVars(s))).pipe(
              Rx.map(
                E.map((r) => ({
                  items: r.items.map((i) => ({
                    id: i.id,
                    name: i.name,
                    entity: i.entity,
                    createdAt: i.createdAt,
                    updatedAt: O.toUndefined(i.updatedAt),
                  })),
                  total: r.totalCount,
                  pageInfo: r.pageInfo,
                })),
              ),
            ),
          ),
        );
      },
      removeItems: (ids) => {
        return deps.pyckAdminClient$.pipe(
          Rx.switchMap((client) =>
            Rx.from(removeDataTypes(client, ids)).pipe(
              Rx.map(
                Fp.flow(
                  E.map(() => ids),
                  E.mapLeft(() => ids),
                ),
              ),
              Rx.catchError(() => Rx.of(E.left(ids))),
            ),
          ),
        );
      },
      // todo: implement
      getVisibleColumns: () => Rx.of({}),
      setVisibleColumns: () => {},
    });
  };

  return {
    ...state,
    epic,
  };

  function getFetchVars(
    s: Typed.GetCollectionType<typeof state.states>["loading" | "fetching"],
  ): GetDataTypesVars {
    const fields = s.payload.filters.payload.fields;
    const where: GetDataTypesVars["where"] = {
      createdAt: {
        gte: fields.createdAt?.[0],
        lte: fields.createdAt?.[1],
      },
      entity: { in: fields.entities },
      or: [
        {
          id: {
            eq: Fp.pipe(
              fields.search,
              O.fromNullable,
              O.map(DataTypeId.fromString),
              O.toUndefined,
            ),
          },
        },
        {
          name: {
            containsFold: fields.search,
          },
        },
      ],
    };

    if (state.states.loading.is(s)) {
      return {
        first: s.payload.perPage,
        orderBy: s.payload.order,
        where,
      };
    }

    switch (s.payload.page) {
      case "start":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "prev":
        return {
          last: s.payload.perPage,
          before: s.payload.pageInfo.prevCursor,
          orderBy: s.payload.order,
          where,
        };
      case "next":
        return {
          first: s.payload.perPage,
          after: s.payload.pageInfo.nextCursor,
          orderBy: s.payload.order,
          where,
        };
      case "end":
        return {
          last: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
      case "current":
        return {
          first: s.payload.perPage,
          orderBy: s.payload.order,
          where,
        };
    }
  }
}

export namespace DataTypesListing {
  export type OrderBy = "createdAt" | "updatedAt";

  export interface Deps {
    pyckAdminClient$: Rx.Observable<Client>;
  }

  export interface Item {
    id: DataTypeId;
    name: string;
    entity: DataTypeEntity;
    createdAt: ISODate;
    updatedAt: ISODate | undefined;
  }

  export type Filters = Partial<{
    entities: DataTypeEntity[];
    search: string;
    createdAt: DateRange;
  }>;

  export type State = GetGuardType<typeof instance.isState>;
  export type Actions = GetGuardType<typeof instance.isAction>;
  export type Exits = GetGuardType<typeof instance.isExit>;

  export const instance = createState("Ready:DataManager:DataTypesListing");
}

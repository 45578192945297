import { createContext } from "react";
import { ElementTypes } from "../types/element";
import { defaultElementTypes } from "../constants/config";

export namespace ElementTypesContext {
  export type Value = {
    elementTypes: ElementTypes;
  };
}

export const defaultValue: ElementTypesContext.Value = {
  elementTypes: defaultElementTypes,
};

export const ElementTypesContext =
  createContext<ElementTypesContext.Value>(defaultValue);

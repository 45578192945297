import { createContext, ReactNode, useContext } from "react";

export interface GlobalEnvProps {
  graphQlClientUri: string;
  temporalUrl: string;
}

const GlobalEnvContext = createContext<GlobalEnvProps>({
  graphQlClientUri: "",
  temporalUrl: "",
});

/**
 * @deprecated, this context exits only for migration period
 */
export function GlobalEnvProvider(p: {
  children?: ReactNode;
  env: GlobalEnvProps;
}) {
  return (
    <GlobalEnvContext.Provider value={p.env}>
      {p.children}
    </GlobalEnvContext.Provider>
  );
}

/**
 * @deprecated, this hook exits only for migration period
 */
export function useGlobalEnv(): GlobalEnvProps {
  return useContext(GlobalEnvContext);
}
